import {
  Circle,
  GoogleApiWrapper,
  InfoWindow,
  Map,
  Marker,
} from 'google-maps-react';
import React, { forwardRef, useEffect, useState } from 'react';
import customFunctions from '../../helper/customFunctions';

const MapCircles = forwardRef((props, ref) => {
  const [showInfoWindow, setInfoWindow] = useState(true);
  const [jobsByPlace, setJobsByPlace] = useState([]);
  const [activeMarker, setactiveMarker] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [allInfo, SetAllInfo] = useState(null);

  const defaultProp = {
    defaultZoom: 12,
    waypoint: true,
    onlyRoute: true,
    travelMode: 'driving',
    showInfoWindow: true,
    infowindowOptions: {},
    routeMarker: [],
    placeMarker: [],
    isMarkerShown: false,
  };

  useEffect(() => {
    const setJobs = (jobsByPlace) => {
      var jobs = [];
      jobsByPlace &&
        jobsByPlace.map((i) => {
          // eslint-disable-line
          if (i.latitude && i.longitude) {
            jobs.push({
              lat: i.latitude && Number(i.latitude) ? Number(i.latitude) : 0,
              lng: i.longitude && Number(i.longitude) ? Number(i.longitude) : 0,
              source: i.source,
              no_of_jobs: i.workordercount * 10000,
              postal_code: i.Zip1,
              color: customFunctions.getMyColor(),
            });
          }
        });
      setJobsByPlace(jobs);
    };
    setJobs(props.jobsByPlace);
  }, [props]);

  const finalProps = { ...defaultProp, ...props };

  const markerClicked = (props, marker) => {
    setInfoWindow(true);
    setactiveMarker(marker);
  };
  const onToggleOpen = (currentPosition) => {
    setactiveMarker(currentPosition);
  };

  return (
    <div className="map-wrapper">
      <Map
        id="map"
        ref={ref}
        zoom={4}
        google={props.google}
        initialCenter={{ lat: 41.878, lng: -87.629 }}
        {...finalProps}
      >
        {jobsByPlace &&
          jobsByPlace.map((city) => {
            return (
              <Circle
                center={{ lat: city.lat, lng: city.lng }}
                strokeColor={city.color}
                strokeOpacity={0.8}
                strokeWeight={2}
                onMouseover={(e) => {
                  setInfoWindow(true);
                  setCurrentPosition({ lat: city.lat, lng: city.lng });
                  SetAllInfo(city);
                }}
                onMouseout={(e) => {
                  setInfoWindow(false);
                  setCurrentPosition({ lat: city.lat, lng: city.lng });
                }}
                // fillColor={city.color}
                fillOpacity={0.35}
                radius={
                  props.fromComponent === 'ahsReport'
                    ? 100000
                    : Math.sqrt(city.no_of_jobs) * 100
                }
              ></Circle>
            );
          })}
        {showInfoWindow && currentPosition && (
          <Marker
            visible={showInfoWindow}
            position={currentPosition}
            opacity={1}
            onMouseover={markerClicked.bind(this)}
          />
        )}
        <InfoWindow
          marker={activeMarker}
          visible={showInfoWindow}
          onClose={() => onToggleOpen(activeMarker)}
        >
          <div>
            <h3>{allInfo && allInfo.no_of_jobs}</h3>
            <div>
              <hr />
              <p>POSTAL CODE: {allInfo && allInfo.postal_code}</p>
              <p>SOURCE: {allInfo && allInfo.source}</p>
              <hr />
              <p>
                {allInfo && allInfo.lat}, {allInfo && allInfo.lng}
              </p>
            </div>
          </div>
        </InfoWindow>
      </Map>
    </div>
  );
});

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY,
})(MapCircles);
