import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddQualityTemplateMsg extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualityId: '',
      qualityTemplateMsg: '',
      qualityTemplateMsgErr: '',
      title: '',
    };
  }

  componentDidMount = async () => {
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      customFunctions.setTitle('Edit Good Better Best Template Msg');
      this.setState({
        title: `Edit Good Better Best Template Msg`,
        qualityTemplateMsg: this.props.location.state.detail.message,
        qualityId: this.props.location.state.detail.id,
      });
    } else {
      customFunctions.setTitle('Add Good Better Best Template Msg');
      this.setState({
        title: `Add Good Better Best Template Msg`,
      });
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { qualityTemplateMsg } = this.state;
    let valid = true;

    if (qualityTemplateMsg === '') {
      valid = false;
      this.setState({
        qualityTemplateMsgErr: 'Please enter message',
      });
    }

    if (valid) {
      this.addQualityTemplateMsg();
    }
  };

  addQualityTemplateMsg = async () => {
    const { qualityTemplateMsg, qualityId } = this.state;
    const postData = {
      message: qualityTemplateMsg,
    };
    const endPoint = `${Api.qualityTemplateMsgUrl}`;
    const callback = qualityId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (qualityId) {
      postData.message_id = parseInt(qualityId);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;

      if (qualityId) {
        swal(
          'Good Better Best template msg updated successfully',
          '',
          'success'
        ).then(() => {
          $this.props.history.push('/quality-template-msg');
        });
      } else {
        swal(
          'Good Better Best template msg added successfully',
          '',
          'success'
        ).then(() => {
          $this.props.history.push('/quality-template-msg');
        });
      }
    } else {
      swal(message, '', 'error');
    }
  };

  render() {
    const { title, qualityTemplateMsg, qualityTemplateMsgErr } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row justify-content-center">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-1 col-form-label"
                        >
                          Message<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <textarea
                            type="text"
                            className="form-control"
                            value={qualityTemplateMsg}
                            placeholder="message"
                            cols={8}
                            rows={8}
                            onChange={(e) => {
                              this.setState({
                                qualityTemplateMsg: e.target.value,
                              });
                            }}
                          />
                          <span id="err">{qualityTemplateMsgErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-2" />
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/quality-template-msg');
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddQualityTemplateMsg);
