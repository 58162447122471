import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import customFunctions from '../../../helper/customFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';

class Complaint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      complaints: [],
      rows: [],
      complaintId: null,
      showDelete: false,
      page: 1,
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      id: null,
      type: null,
      viewId: null,
      technicianId: null,
      roleId: null,
      loginId: null,
      complaintFlag: null,
      workorderId: null,
      customerId: null,
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    if (
      (await this.props) &&
      (await this.props.orderDetails) &&
      (await this.props.orderDetails.ToTime)
    ) {
      var currentDate = Date.now();
      var todate = new Date(this.props.orderDetails.ToTime).getTime();
      const result = currentDate > todate;
      await this.setState({
        complaintFlag: result,
      });
    }

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id, loginId: userdata.login_id });

    var newViewId = customFunctions.getLocalStorage('viewId');
    this.setState({
      viewId: newViewId,
    });

    if (
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.type)
    ) {
      this.setState({
        type: await this.props.location.state.type,
      });
    }

    if (
      (await this.props.match) &&
      (await this.props.match.params) &&
      (await this.props.match.params.customerId)
    ) {
      this.setState({
        id: await this.props.match.params.customerId,
      });
    }

    if (
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.technicianDetails) &&
      (await this.props.location.state.technicianDetails.id)
    ) {
      await this.setState({
        technicianId: await this.props.location.state.technicianDetails.id,
      });
    }

    if (
      (await this.props) &&
      (await this.props.orderDetails) &&
      (await this.props.orderDetails.CustomerID)
    ) {
      await this.setState({
        customerId: await this.props.orderDetails.CustomerID,
      });
    }

    if ((await this.props) && (await this.props.workorderid)) {
      await this.setState({
        workorderId: await this.props.workorderid,
      });
    }

    if (
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.orderDetails) &&
      (await this.props.location.state.orderDetails.CustomerID)
    ) {
      var currDate = Date.now();
      var appointmentdate = new Date(
        this.props.location.state.orderDetails.FirstApptDate
      ).getTime();
      const result = currDate > appointmentdate;
      await this.setState({
        complaintFlag: result,
        id: await this.props.location.state.orderDetails.CustomerID,
      });
    }
    await this.getComplaints();

    const columns = [
      {
        label: 'Complaint #',
        field: 'complaint',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Technician',
        field: 'technician',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Customer',
        field: 'CustomerName',
        sort: 'disabled',
        width: 150,
      },
      // {
      //   label: 'Open Date',
      //   field: 'opendate',
      //   sort: 'disabled',
      //   width: 150,
      // },
      // {
      //   label: 'Closed Date',
      //   field: 'closeddate',
      //   sort: 'disabled',
      //   width: 150,
      // },
      {
        label: 'Complaint Status',
        field: 'complaintstatus',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Complaint Details',
        field: 'complaintdetails',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 150,
      },
    ];

    if (userdata.role_id == 4) {
      columns.splice(2, 1);
    }
    if (userdata.role_id == 5) {
      columns.splice(1, 1);
    }
    this.setState({ columns });
  };

  getComplaints = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const {
      limit,
      keyword,
      id,
      type,
      technicianId,
      roleId,
      loginId,
      workorderId,
      customerId,
    } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = '';
    if ((roleId === 5 || roleId === 6) && loginId) {
      endPoint = `${Api.complaintUrl}/by_technician?technician_id=${loginId}&skip=${skip}&limit=${limit}&work_order_id=${workorderId}`;
    }
    if (roleId === 4) {
      endPoint = `${Api.complaintUrl}?customer_id=${customerId}&skip=${skip}&limit=${limit}&work_order_id=${workorderId}`;
    }
    if (type === 'technician') {
      endPoint = `${Api.complaintUrl}/by_technician?technician_id=${technicianId}&skip=${skip}&limit=${limit}`;
    } else if (type === 'orders') {
      endPoint = `${Api.complaintUrl}/by_customer?customer_id=${id}&work_order_id=${workorderId}`;
    } else {
      endPoint = `${Api.complaintUrl}?skip=${skip}&limit=${limit}&search=${keyword}&work_order_id=${workorderId}`;
    }
    // else if (this.props.fromComponent == "workorder") {
    //   endPoint = `${Api.complaintUrl}/by_customer?customer_id=${this.props.orderDetails.CustomerID}`;
    // }

    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (
      data &&
      data.success !== 'false' &&
      data.data &&
      data.data.data &&
      (roleId == 4 || roleId == 1)
    ) {
      this.setState(
        {
          complaints: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }

    if (
      data &&
      data.success !== 'false' &&
      data.data &&
      (roleId == 5 || roleId == 6)
    ) {
      this.setState(
        {
          complaints: data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.setState({ isShowLoader: false });
  };

  setTableData = () => {
    const { complaints, currentPage, roleId } = this.state;
    const rows = [];
    complaints &&
      complaints.length &&
      complaints.map((complaint, i) => {
        var date = new Date(complaint.ComplaintOpenDate);
        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();

        var complaintdate =
          (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
        this.setState({
          opendate: complaintdate,
        });

        var date = new Date(complaint.ComplaintCloseDate);
        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();

        var complaintclosedate =
          (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
        this.setState({
          closeddate: complaintclosedate,
        });

        rows.push({
          action: (
            <React.Fragment>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/edit-complaint/${complaint.ID}`);
                }}
              >
                {roleId == 5 || roleId == 6 ? (
                  <i className="mdi mdi-eye" title="View Complaint"></i>
                ) : (
                  <i className="mdi mdi-lead-pencil" title="Edit Complaint"></i>
                )}
              </a>
              {roleId !== 5 && roleId !== 6 && roleId !== 4 && (
                <a
                  href="/"
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                      showDelete: true,
                      complaintId: complaint.ID,
                    });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete Complaint"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
          complaint: complaint.ComplaintCode || '-',
          homewarrantycode: complaint.HomeWarrantyCode || '-',
          technician: complaint.Technician || '-',
          CustomerName: complaint.CustomerName || '-',
          opendate: complaintdate || '-',
          closeddate: complaintclosedate || '-',
          complaintstatus: complaint.ComplaintStatus || '-',
          complaintdetails: complaint.ComplaintDetails || '-',
        });
        return complaint;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteComplaint = async () => {
    const { complaintId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.complaintUrl}?complaint_id=${complaintId}`
    );
    if (data) {
      this.setState({ showDelete: false, complaintId: null }, () => {
        swal('Complaint deleted successfully', '', 'success').then(() => {
          this.getComplaints();
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      viewId,
      showDelete,
      roleId,
      columns,
      complaintFlag,
      isShowLoader,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="card m-b-20">
          {showDelete && (
            <div className="sweetalert">
              <SweetAlert
                title="All the complaints raised by the customer will get deleted"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deleteComplaint();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, complaintId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            </div>
          )}
          {viewId && (roleId !== 5 || roleId !== 6) && complaintFlag && (
            <div style={{ paddingRight: '20px', paddingTop: '20px' }}>
              <div className="d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={() => {
                    if (this.props.orderDetails) {
                      this.props.history.push('/add-complaint', {
                        orderDetails: this.props.orderDetails,
                      });
                    } else {
                      this.props.history.push('/add-complaint');
                    }
                  }}
                >
                  Add Complaints
                </button>
              </div>
            </div>
          )}
          <div className="card-body mdbtable">
            <MDBDataTable
              noBottomColumns={true}
              paging={false}
              bordered
              hover
              data={{ columns, rows }}
              searching={false}
              noRecordsFoundLabel="No records found"
              scrollX
              maxHeight="350px"
            />
            {totalCount > 10 && (
              <div className="pagination">
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  limit={limit}
                  getData={this.getComplaints}
                />
              </div>
            )}
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(Complaint);
