import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import AUX from '../../../hoc/Aux_';

class StatusUpdatePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Status Update',
      isShowLoader: false,
      supplyDataLength: null,
      isPartArrived: false,
      statusId: '',
      supplyData: [
        {
          supplyHouse: '',
          supplyNumber: '',
          partOrdered: '',
          partNumber: '',
          poNumber: '',
          partDeliveryAddress: '',
          trackingNumber: '',
          partETA: '',
          speakWith: '',
          note: '',
        },
      ],
      workorderId: null,
      apiFlag: null,
    };
  }

  componentDidMount = async () => {
    if (this.props && this.props.workorderId) {
      await this.setState({ workorderId: this.props.workorderId }, () => {
        this.getSupplyData();
      });
    }
  };

  getSupplyData = async () => {
    const { workorderId } = this.state;
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.getPendingWorkstatus}?work_order_id=${workorderId}`
    );
    const newSupplyData = [];
    if (data && data.success) {
      this.setState({
        apiFlag: true,
      });
      if (data && data.data && data.data.length === 0) return;
      if (data && data.data && data.data[0]) {
        newSupplyData.push({
          supplyHouse: data.data[0].SupplyHouse,
          supplyNumber: data.data[0].SupplyHousePhoneNumber,
          partOrdered: data.data[0].PartOrdered,
          partNumber: data.data[0].PartNumber,
          poNumber: data.data[0].PONumber,
          partDeliveryAddress: data.data[0].PartDeliveryAddress,
          trackingNumber: data.data[0].TrackingNumber,
          partETA: data.data[0].PartETA && new Date(data.data[0].PartETA),
          speakWith: data.data[0].WhoDidYouSpeakWith,
          note: data.data[0].note,
          viewType: true,
        });
      }
      this.setState({
        supplyData: newSupplyData,
        supplyDataLength:
          data && data.data && data.data.length ? data.data.length : 0,
        isPartArrived: data.data[0].IsPartArrived === 1 ? true : false,
        statusId: data.data[0].id,
      });
    } else {
      this.setState({
        apiFlag: true,
      });
      swal(message, '', 'error');
    }
  };

  render() {
    const { supplyData, isShowLoader, supplyDataLength, apiFlag } = this.state;
    return (
      <AUX>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={this.props.popupClose}
          show={this.props.updatesPopup}
        >
          <Modal.Header className="col-12">
            <Modal.Title className="text-secondary">
              <h5>Most Recent Status Update</h5>
            </Modal.Title>
            <i
              className="mdi mdi-close"
              onClick={() => this.props.popupClose()}
            ></i>
          </Modal.Header>
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <Modal.Body style={{ maxHeight: '350px', overflowY: 'scroll' }}>
            {apiFlag && (
              <div className="row">
                <div className="col-12">
                  <div className="card m-b-20">
                    <div className="card-body">
                      <form autoComplete="off">
                        {supplyData &&
                          supplyDataLength > 0 &&
                          supplyData.map((supply, index) => (
                            <div key={`supply-data-${index}`}>
                              <div className="form-group row">
                                <label
                                  htmlFor="supply-house"
                                  className="col-sm-2 col-form-label"
                                >
                                  Supply House
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    disabled
                                    className="form-control"
                                    placeholder="Supply House"
                                    type="text"
                                    value={supply.supplyHouse}
                                    id="supply-house"
                                    onChange={(e) => {
                                      supplyData[index].supplyHouse =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                </div>
                                <label
                                  htmlFor="supply-number"
                                  className="col-sm-2 col-form-label"
                                >
                                  Supply Number
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    disabled
                                    className="form-control"
                                    placeholder="Supply House Phone Number"
                                    type="text"
                                    value={supply.supplyNumber}
                                    id="supply-numbere"
                                    maxLength="10"
                                    onChange={(e) => {
                                      supplyData[index].supplyNumber =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="part-ordered"
                                  className="col-sm-2 col-form-label"
                                >
                                  Part Ordered
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    disabled
                                    className="form-control"
                                    placeholder="Part Name"
                                    type="text"
                                    value={supply.partOrdered}
                                    id="part-ordered"
                                    onChange={(e) => {
                                      supplyData[index].partOrdered =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                  <span id="err">
                                    {supplyData[index].partOrderedErr}
                                  </span>
                                </div>
                                <label
                                  htmlFor="part-number"
                                  className="col-sm-2 col-form-label"
                                >
                                  Part Number
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    disabled
                                    className="form-control"
                                    placeholder="Part Number"
                                    type="text"
                                    value={supply.partNumber}
                                    id="part-numbere"
                                    onChange={(e) => {
                                      supplyData[index].partNumber =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="po-number"
                                  className="col-sm-2 col-form-label"
                                >
                                  PO Number
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    disabled
                                    className="form-control"
                                    placeholder="PO Number"
                                    type="text"
                                    value={supply.poNumber}
                                    id="po-number"
                                    onChange={(e) => {
                                      supplyData[index].poNumber =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                  <span id="err">
                                    {supplyData[index].poNumberErr}
                                  </span>
                                </div>
                                <label
                                  htmlFor="part-address"
                                  className="col-sm-2 col-form-label"
                                >
                                  Part Delivery Address
                                </label>
                                <div className="col-sm-4">
                                  <textarea
                                    disabled
                                    className="form-control"
                                    placeholder="Part Delivery Address"
                                    value={supply.partDeliveryAddress}
                                    id="part-address"
                                    onChange={(e) => {
                                      supplyData[index].partDeliveryAddress =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                  <span id="err">
                                    {supplyData[index].partDeliveryAddressErr}
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="tracking-number"
                                  className="col-sm-2 col-form-label"
                                >
                                  Tracking Number
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    disabled
                                    className="form-control"
                                    placeholder="Tracking Number"
                                    type="text"
                                    value={supply.trackingNumber}
                                    id="tracking-number"
                                    onChange={(e) => {
                                      supplyData[index].trackingNumber =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                  <span id="err">
                                    {supplyData[index].trackingNumberErr}
                                  </span>
                                </div>
                                <label
                                  htmlFor="part-eta"
                                  className="col-sm-2 col-form-label"
                                >
                                  Part ETA
                                </label>
                                <div className="col-sm-4">
                                  <DatePicker
                                    disabled
                                    className="form-control"
                                    selected={supply.partETA}
                                    onChange={(date) => {
                                      supplyData[index].partETA = date;
                                      this.setState({ supplyData });
                                    }}
                                    minDate={new Date()}
                                    name="startDate"
                                    dateFormat="MM/dd/yyyy"
                                    placeholderText="Select date"
                                  />
                                  <span id="err">
                                    {supplyData[index].partETAErr}
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row">
                                <label
                                  htmlFor="speak-with"
                                  className="col-sm-2 col-form-label"
                                >
                                  Who did you speak with
                                </label>
                                <div className="col-sm-4">
                                  <input
                                    disabled
                                    className="form-control"
                                    placeholder="Who did you speak with"
                                    type="text"
                                    value={supply.speakWith}
                                    id="speak-with"
                                    onChange={(e) => {
                                      supplyData[index].speakWith =
                                        e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                </div>
                                <label
                                  htmlFor="note"
                                  className="col-sm-2 col-form-label"
                                >
                                  Note
                                </label>
                                <div className="col-sm-4">
                                  <textarea
                                    disabled
                                    className="form-control"
                                    placeholder="Please add note"
                                    id="note"
                                    value={supply.note}
                                    onChange={(e) => {
                                      supplyData[index].note = e.target.value;
                                      this.setState({ supplyData });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        {supplyDataLength === 0 ||
                          (supplyDataLength === null && (
                            <h6>No recent updates are available!</h6>
                          ))}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default withRouter(StatusUpdatePopup);
