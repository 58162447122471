import React, { Component } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import Loader from '../../../components/Loader';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import Profile from '../Profile/profile';
import CustomerEstimateComponent from './customerEstimate';
import CustomerInvoiceComponent from './customerInvoice';
import CustomerPreMaintenance from './customerPremaintenance';
import HelmetComponent from '../Title';

class CustomerProfileModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowLoader: false,
      roleId: null,
      previousTab: 'My Profile',
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('My Profile');
    let previousTab = await customFunctions.getLocalStorage(
      'activeTabCustomer'
    );
    if (previousTab) this.setState({ previousTab: previousTab });

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });
  };

  handleSelect = (key) => {
    if (key == 1) {
      this.setState({ showRating: true });
    } else {
      this.setState({ showRating: false });
    }
  };

  render() {
    const { isShowLoader, roleId, previousTab } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <HelmetComponent title="My Profile" />
        {roleId && (
          <div className="page-content-wrapper">
            <div className="container-fluid">
              <div className="card m-b-23">
                <div className="card-body">
                  <div
                    className={`container-fluid workorder-tbl  ${
                      roleId !== 4 ? 'col-sm-12' : ''
                    }`}
                  >
                    {previousTab && (
                      <Tab.Container
                        activeKey={previousTab}
                        onSelect={(e) => this.handleSelect(e)}
                      >
                        <div className="row">
                          <div className="col-sm-3">
                            <Nav
                              variant="pills"
                              className="flex-column"
                              onSelect={(selectedKey) => {
                                this.setState({ previousTab: selectedKey });
                                customFunctions.setLocalStorage(
                                  'activeTabCustomer',
                                  selectedKey
                                );
                              }}
                            >
                              <Nav.Item>
                                <Nav.Link eventKey={'My Profile'}>
                                  My Profile
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={'Estimate'}>
                                  Estimate
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={'Invoice'}>
                                  Invoice
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey={'Pre-maintenance'}>
                                  Premaintenance
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                          <div className="col-sm-9">
                            <Tab.Content>
                              <Tab.Pane eventKey={'My Profile'}>
                                <Profile />
                              </Tab.Pane>

                              <Tab.Pane eventKey={'Estimate'}>
                                <CustomerEstimateComponent />
                              </Tab.Pane>

                              <Tab.Pane eventKey={'Invoice'}>
                                <CustomerInvoiceComponent />
                              </Tab.Pane>

                              <Tab.Pane eventKey={'Pre-maintenance'}>
                                <CustomerPreMaintenance />
                              </Tab.Pane>
                            </Tab.Content>
                          </div>
                        </div>
                      </Tab.Container>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </AUX>
    );
  }
}
export default withRouter(CustomerProfileModule);
