import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import Api from '../../../../helper/api';
import AuthApi from '../../../../helper/authApi';
import customFunctions from '../../../../helper/customFunctions';

class EstimateTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      estimates: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      startDate: '',
      endDate: '',
    };
  }
  async componentDidMount() {
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'ESTIMATE NO.',
        field: 'estimateno',
        width: 150,
      },
      {
        label: 'JOB NO.',
        field: 'jobno',
        width: 150,
      },
      {
        label: 'CREATED',
        field: 'created',
        width: 180,
      },
      {
        label: 'STATUS',
        field: 'status',
        width: 150,
      },
      {
        label: 'FIRST NAME',
        field: 'firstname',
        width: 150,
      },
      {
        label: 'LAST NAME',
        field: 'lastname',
        width: 150,
      },
      {
        label: 'JOB TITLE',
        field: 'jobtitle',
        width: 150,
      },
      {
        label: 'SOURCE',
        field: 'source',
        width: 150,
      },
      {
        label: 'SERVICE TYPE',
        field: 'servicetype',
        width: 150,
      },
      {
        label: 'AMOUNT',
        field: 'amount',
        width: 150,
      },
      {
        label: 'TECHNICIAN',
        field: 'technician',
        width: 150,
      },
      {
        label: 'CREATED BY',
        field: 'createdby',
        width: 150,
      },
      {
        label: 'VIEW JOB',
        field: 'actions',
        width: 150,
      },
    ];
    this.setState(
      { columns, startDate: this.props.startDate, endDate: this.props.endDate },
      () => {
        this.getEstimates();
      }
    );
  }
  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      this.setState(
        { startDate: props.startDate, endDate: props.endDate },
        () => {
          this.getEstimates();
        }
      );
    }
  }
  getEstimates = async (currentPage) => {
    let { pagination, startDate, endDate } = this.state;

    this.setState({ currentPage });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.getEstimate}?skip=${skip}&limit=${pagination.itemsPerPage}&start_date=${startDate}&end_date=${endDate}`;

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          estimates: data.data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.props.callback();
  };

  downloadCSVForEstimates = async () => {
    const { startDate, endDate } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.downloadCSVForEstimates}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}`;

    window.open(endPoint, '_blank');
  };

  setTableData = () => {
    const { estimates, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    estimates &&
      estimates.length &&
      estimates.map((estimate, i) => {
        estimate.work_start_date = customFunctions.changeDateFormatMDY(
          estimate.work_start_date
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          estimateno: estimate.estimate_id || '-',
          jobno: estimate.work_order_id || '-',
          created: estimate.work_start_date || '-',
          status: estimate.estimate_type || '-',
          firstname:
            (estimate.customer_name && estimate.customer_name.split(' ')[0]) ||
            '-',
          lastname:
            (estimate.customer_name && estimate.customer_name.split(' ')[1]) ||
            '-',
          jobtitle: estimate.job_title || '-',
          source: estimate.source || '-',
          servicetype: estimate.order_type || '-',
          // servicetype: estimate.service_type || '-',
          amount: estimate.total || '-',
          technician: estimate.technician || '-',
          createdby: estimate.createdby || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(
                    `/estimateDetail/${estimate.estimate_id}`,
                    {
                      orderDetails: estimate,
                      type: 'estimates',
                    }
                  );
                }}
              >
                <i
                  className="mdi mdi-eye"
                  id="viewJob"
                  title="View Estimate"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return estimates;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6> {this.props.title}</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                this.downloadCSVForEstimates();
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>
        {/* <Table
        columns={columns}
        rows={rows}
        showPagination
        paginationProps={{
          ...pagination,
          activePage: pagination.current_page,
          onPageChange: (pageNo) => {
            this.getEstimates(pageNo);
          },
        }}
        tableClass="table-bordered"
        dropdownContainerClass="mb-3"
        emptyRender={() => <p className="text-center mb-0">No record found</p>}
      /> */}
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="350px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getEstimates}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(EstimateTables);
