import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';

export default class ImagePreview extends Component {
  getFileExt(filePath) {
    return filePath.substr(-3, filePath.lastIndexOf('.'));
  }

  render() {
    let src = this.props.imagePreview;
    let ext = this.getFileExt(src);
    return (
      <Modal
        centered
        size="xl"
        show={this.props.imagePreviewShow}
        onHide={this.props.handleClose}
      >
        <Modal.Header>
          <Modal.Title />
          <i
            className="mdi mdi-close"
            onClick={() => this.props.handleClose()}
          ></i>
        </Modal.Header>
        <Modal.Body className="text-center">
          {ext === 'pdf' || ext === 'txt' || ext === 'doc' || ext === 'xls' ? (
            <iframe
              title="iframe"
              id="iframepdf"
              frameborder="0"
              src={
                'https://docs.google.com/viewer?url=' + src + '&embedded=true'
              }
              width="100%"
              height="500"
            ></iframe>
          ) : (
            <img
              alt=""
              src={this.props.imagePreview}
              width="100%"
              height="100%"
            />
          )}
        </Modal.Body>
      </Modal>
    );
  }
}
