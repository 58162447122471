import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
// import ApproveByAdminComponent from './approve.component';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class AdvancePaycheckComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      advanceData: [],
      rows: [],
      columns: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      technicianId: null,
      id: null,
      type: null,
      loanId: null,
      showDelete: false,
      isWriteAllowed: false,
      modalShow: false,
      advanceReq: false,
      filterValue: '',
      techRequestedAmount: null,
      filterdata: [
        {
          value: 'approved',
          label: 'Approved',
        },
        {
          value: 'pending',
          label: 'Pending',
        },
        {
          value: 'canceled',
          label: 'Cancelled',
        },
      ],
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'advancepaycheck-accessadvancepaycheck'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'advancepaycheck-accessadvancepaycheck',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('Advance Paycheck');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          loginId: userdata.login_id,
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        async () => {
          await this.getAdvancePaycheckDetails();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, loginId: userdata.login_id },
        async () => {
          await this.getAdvancePaycheckDetails();
        }
      );
    }

    let columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        sort: 'disabled',
        width: 70,
      },
      {
        label: 'Technician',
        field: 'technician',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Date',
        field: 'date',
        sort: 'disabled',
        width: 140,
      },
      {
        label: 'Requested Amount',
        field: 'reqAmount',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Advanced Balance',
        field: 'advanceBalance',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 140,
      },
      {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
        width: 150,
      },
    ];
    if (userdata.role_id === 5) {
      columns.splice(1, 1);
    }
    this.setState({ columns });
  };

  getAdvancePaycheckDetails = async (currentPage) => {
    const { roleId, loginId, filterValue, navSkip, navCurrentPage } =
      this.state;
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword } = this.state;

    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    let endPoint = `${
      Api.technicianLoanList
    }?skip=${skip}&limit=${limit}&search=${keyword}&status=${
      filterValue ? filterValue.value : ''
    }`;
    if (!filterValue) {
      endPoint = `${Api.technicianLoanList}?skip=${skip}&limit=${limit}&search=${keyword}`;
    }
    if (roleId === 5 || roleId === 6) {
      endPoint += `&techid=${loginId}`;
      endPoint += `&role=${roleId}`;
    }
    if (roleId === 3) {
      endPoint += `&techid=${loginId}`;
      endPoint += `&role=${roleId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          isShowLoader: false,
          advanceData: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { advanceData, limit, roleId } = this.state;
    const page = npage;
    const rows = [];
    advanceData &&
      advanceData.length &&
      advanceData.map((paycheck, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          technician: paycheck.name,
          date: paycheck.date ? customFunctions.getMDYDate(paycheck.date) : '-',
          reqAmount: paycheck.requestedamount,
          advanceBalance: paycheck.advancebalance,
          status: paycheck.status,
          actions: (
            <React.Fragment>
              {(roleId === 5 || roleId === 6 || roleId === 1) &&
                !paycheck.technician_sign &&
                paycheck.status !== 'approved' && (
                  <a
                    href="/"
                    style={{ padding: '0 8px' }}
                    // className={paycheck.status === 'approved' ? 'disabled' : ''}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.history.push('/request-advance-paycheck', {
                        loanId: paycheck.id,
                        technicianId: paycheck.technician_id,
                        technicianName: paycheck.name,
                        advanceReq: true,
                        path: '/advance-paycheck',
                        paginationObj: {
                          navSkip: skip,
                          navCurrentPage: npage,
                        },
                      });
                    }}
                  >
                    <i
                      className="mdi mdi-lead-pencil"
                      title="Edit loan amount"
                    ></i>
                  </a>
                )}
              {(roleId === 5 || roleId === 6 || roleId === 1) &&
                paycheck.technician_sign && (
                  <a
                    href="/"
                    style={{ padding: '0 8px' }}
                    // className={paycheck.status === 'approved' ? 'disabled' : ''}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.history.push('/request-advance-paycheck', {
                        loanId: paycheck.id,
                        technicianId: paycheck.technician_id,
                        technicianName: paycheck.name,
                        advanceReq: true,
                        path: '/advance-paycheck',
                        paginationObj: {
                          navSkip: skip,
                          navCurrentPage: npage,
                        },
                      });
                    }}
                  >
                    <i className="mdi mdi-eye" title="View loan amount"></i>
                  </a>
                )}
              {roleId === 1 &&
                paycheck.technician_sign &&
                paycheck.status !== 'approved' && (
                  <a
                    href="/"
                    style={{ padding: '0 8px' }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.props.history.push('/request-advance-paycheck', {
                        loanId: paycheck.id,
                        technicianId: paycheck.technician_id,
                        technicianName: paycheck.name,
                        isApprove: true,
                        advanceReq: true,
                        path: '/advance-paycheck',
                        paginationObj: {
                          navSkip: skip,
                          navCurrentPage: npage,
                        },
                      });
                    }}
                  >
                    <i className="mdi mdi-check" title="Approve"></i>
                  </a>
                )}
              {roleId === 1 && paycheck.status !== 'approved' && (
                <a
                  href="/"
                  style={{ padding: '0 8px' }}
                  // className={paycheck.status === 'approved' ? 'disabled l-m-10' : 'l-m-10'}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                      showDelete: true,
                      loanId: paycheck.id,
                    });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete loan"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return paycheck;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  cancelRequest = async () => {
    const { loanId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.technicianPaycheck}?id=${loanId}&isactive=0`
    );
    if (data) {
      this.setState({ showDelete: false, loanId: null }, () => {
        this.getAdvancePaycheckDetails();
      });
      swal('Record deleted successfully', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getAdvancePaycheckDetails();
  };

  clearSearch = () => {
    this.setState({ keyword: '', filterValue: '' }, () => {
      this.getAdvancePaycheckDetails();
    });
  };

  downloadCSV = async () => {
    const { keyword, filterValue } = this.state;
    let endPoint = `${
      Api.technicianPaycheck
    }/getTechnicianLoanListCsv?skip=${0}&limit=${10000}&search=${keyword}&status=${
      filterValue ? filterValue.value : ''
    }`;
    // if (roleId === 5 || roleId === 6) {
    //   endPoint += `&techid=${loginId}`;
    //   endPoint += `&role=${roleId}`;
    // }
    // if (roleId === 3) {
    //   endPoint += `&techid=${loginId}`;
    //   endPoint += `&role=${roleId}`;
    // }

    window.open(endPoint, '_blank');
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      columns,
      roleId,
      filterValue,
      filterdata,
      isShowLoader,
      navSkip,
      navCurrentPage,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="Advance Paycheck" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, you want to cancel request ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.cancelRequest();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, loanId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body mdbtable">
                    <div className="form-group row">
                      <div className="col-sm-7">
                        <form onSubmit={this.onSearchSubmit}>
                          {roleId === 1 && (
                            <div className="row">
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  value={keyword}
                                  type="text"
                                  placeholder="Search by technician"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    this.setState({ keyword: e.target.value });
                                  }}
                                  required={true}
                                />
                              </div>
                              <div className="col-sm-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Search
                                </button>
                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.clearSearch();
                                  }}
                                  className="btn btn-secondary waves-effect m-l-10"
                                >
                                  Cancel
                                </button>
                              </div>

                              <div className="col-sm-4 select-wrap">
                                <ReactSelect
                                  value={filterValue}
                                  placeholder="Select"
                                  onChange={(e) => {
                                    if (e) {
                                      let type = {};
                                      type.label = e.label;
                                      type.value = e.value;
                                      this.setState(
                                        { filterValue: type },
                                        () => {
                                          this.getAdvancePaycheckDetails();
                                          this.forceUpdate();
                                        }
                                      );
                                    } else {
                                      this.setState({ filterValue: '' }, () => {
                                        this.getAdvancePaycheckDetails();
                                      });
                                    }
                                  }}
                                  isClearable={true}
                                  options={filterdata}
                                />
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                      {(roleId === 5 || roleId === 6) && (
                        <div className="col-sm-5 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.props.history.push(
                                '/request-advance-paycheck',
                                {
                                  advanceReq: false,
                                }
                              );
                            }}
                          >
                            Request Advance
                          </button>
                        </div>
                      )}
                      {totalCount > 0 && roleId === 1 && (
                        <div className="col-sm-5 text-right">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        </div>
                      )}
                    </div>
                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      responsive
                      data={{ columns, rows }}
                      searching={false}
                      // getData={this.getAdvancePaycheckDetails}
                      noRecordsFoundLabel="No records found"
                      scrollX
                      maxHeight="350px"
                    />
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getAdvancePaycheckDetails
                                )
                              : this.getAdvancePaycheckDetails
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  {modalShow && (
          <ApproveByAdminComponent
            show={true}
            technicianId={technicianId}
            loginId={loginId}
            loanId={loanId}
            advanceReq={advanceReq}
            reqAmount={techRequestedAmount}
            editType={editType}
            handleModalCLose={(e) => this.setState({ modalShow: e })}
            onChanges={async (currentPage) => {
              await this.getAdvancePaycheckDetails(currentPage);
            }}
          />
        )} */}
      </AUX>
    );
  }
}
export default withRouter(AdvancePaycheckComponent);
