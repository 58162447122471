import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';

class Notepopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  componentDidMount = (props) => {
    if (this.props && this.props.recordingDetails) {
      this.setState({
        message: this.props.recordingDetails.note,
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  validateForm = (e) => {
    e.preventDefault();
    const { message } = this.state;
    let messageErr = '',
      isValid = true;

    if (message === '') {
      messageErr = 'Please enter note';
      isValid = false;
    }

    this.setState({ messageErr });
    if (isValid) {
      this.submitMessage();
    }
  };

  submitMessage = async () => {
    const { message } = this.state;
    let recordID = '';
    let payment = '';
    if (this.props && this.props.recordingDetails) {
      recordID = this.props.recordingDetails.id;
      payment = this.props.recordingDetails.ispaymentcollected;
    }
    const endPoint = `${Api.recording}`;
    const callback = AuthApi.putDataToServer;
    const postData = {
      id: recordID,
      ispaymentcollected: payment,
      note: message,
    };
    const payload = customFunctions.cleanObject(postData);
    const { data } = await callback(endPoint, payload);
    if (data && data.success) {
      if (this.props.popupClose) this.props.popupClose();
      if (this.props.callbackfun) this.props.callbackfun();
      swal('Note is updated!', '', 'success');
    }
  };

  render() {
    const { message, messageErr } = this.state;
    return (
      <div>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={this.props.popupClose}
          show={this.props.noteToggle}
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              <span>
                Note<span className="text-danger">*</span>
              </span>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    value={message}
                    onChange={this.handleChange}
                  />
                  <span id="err">{messageErr}</span>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer id="md-filder" className="w-100">
              <div className="float-right">
                <Button
                  variant="primary"
                  type="submit"
                  className="saveBtns l-m-10"
                  onClick={(e) => {
                    this.validateForm(e);
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  className="saveBtns ml-2"
                  onClick={this.props.popupClose}
                >
                  Back
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default Notepopup;
