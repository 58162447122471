import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AppointmentList from './appointmentList';

class AddLeave extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      leaveId: null,
      reason: '',
      leaveTypeData: [
        { label: 'Casual Leave(CL)', value: 'Casual Leave' },
        { label: 'Sick Leave(SL)', value: 'Sick Leave' },
      ],
      durationTypeData: [
        { label: 'Full Day', value: 'Full Day' },
        { label: 'Half Day', value: 'Half Day' },
      ],
      leaveType: { label: '', value: '' },
      durationType: { label: 'Full Day', value: 'Full Day' },
      fromDate: '',
      toDate: '',

      leaveTypeErr: '',
      durationTypeErr: '',
      fromDateErr: '',
      toDateErr: '',
      appointmentData: [],

      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      roleId: null,
      canApply: false,
      status: '',
      hideAptChkbtn: false,
      name: ''
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Leave Application');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'leaves-accessleaves'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'leaves-accessleaves',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }

    this.setState({
      roleId: userdata.role_id,
      loginId: userdata.login_id,
    });
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getLeaveData();
      customFunctions.setTitle('Leave Application');
      this.setState({
        title: `Leave Application`,
      });
    } else {
      customFunctions.setTitle('Leave Application');
      this.setState({
        title: `Leave Application`,
      });
    }
  };

  getLeaveData = async () => {
    const leaveData = this.props.location.state.detail;
    let leave_type = {
      label: leaveData.leave_type,
      value: leaveData.leave_type,
    };
    let duration_type = {
      label: leaveData.duration_type,
      value: leaveData.duration_type,
    };
    this.setState({
      leaveId: leaveData.id,
      reason: leaveData.reason,
      leaveType: leave_type,
      durationType: duration_type,
      fromDate: leaveData.from_date && new Date(leaveData.from_date),
      toDate: leaveData.to_date && new Date(leaveData.to_date),
      disableAll:
        leaveData.status === 'approved' ||
        leaveData.status === 'cancelled' ||
        leaveData.status === 'declined'
          ? true
          : false,
      status: leaveData.status,
      name: leaveData.name
    });
  };

  getAppointments = async (currentPage) => {
    const { limit, roleId, loginId, fromDate, toDate, durationType } =
      this.state;
    this.setState({ currentPage });
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let from_date = fromDate && customFunctions.getDate(fromDate, '-');
    let to_date =
      durationType.value === 'Full Day'
        ? toDate && customFunctions.getDate(toDate, '-')
        : fromDate && customFunctions.getDate(fromDate, '-');

    let endPoint = `${Api.leavesUrl}/getOrderByTechnician?skip=${skip}&limit=${limit}&from_date=${new Date(from_date).toISOString()}&to_date=${new Date(to_date).toISOString()}`;
    if (roleId === 5 || roleId === 6) {
      endPoint += `&tech_id=${loginId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        appointmentData: data.data.data,
        totalCount: data.data.totalCount,
      });
    } else {
      swal(message, '', 'error');
    }
  };

  validate = () => {
    const { leaveType, durationType, fromDate, toDate } = this.state;

    let leaveTypeErr = '',
      durationTypeErr = '',
      fromDateErr = '',
      toDateErr = '';
    let isValid = true;

    if (leaveType && leaveType.value === '') {
      leaveTypeErr = 'The leave type is required';
      isValid = false;
    }
    if (durationType && durationType.value === '') {
      durationTypeErr = 'The duration type is required';
      isValid = false;
    }
    if (fromDate === '') {
      fromDateErr = 'The from date is required';
      isValid = false;
    }
    if (durationType && durationType.value === 'Full Day' && toDate === '') {
      toDateErr = 'The to date is required';
      isValid = false;
    }
    this.setState({
      leaveTypeErr,
      durationTypeErr,
      fromDateErr,
      toDateErr,
    });
    return isValid;
  };

  checkAppointment = async () => {
    let valid = await this.validate();
    if (valid) {
      await this.getAppointments();
      const { appointmentData } = this.state;
      this.setState({
        canApply: appointmentData && appointmentData.length ? false : true,
        hideAptChkbtn: false,
      });
    }
  };

  applyLeave = async () => {
    let valid = await this.validate();
    if (valid) {
      const {
        leaveId,
        leaveType,
        durationType,
        reason,
        fromDate,
        toDate,
        roleId,
        loginId,
      } = this.state;

      // customFunctions.getDate(fromDate, '-')
      // customFunctions.getDate(toDate, '-')

      const postData = {
        leave_type: leaveType.value,
        duration_type: durationType.value,
        reason,
        from_date: fromDate && new Date(fromDate).toISOString(),
        to_date:
          durationType.value === 'Full Day'
            ? toDate && new Date(toDate).toISOString()
            : fromDate && new Date(fromDate).toISOString(),
      };
      if (roleId === 5 || roleId === 6) {
        postData.tech_id = loginId;
      }
      const endPoint = `${Api.leavesUrl}`;
      const callback = leaveId
        ? AuthApi.putDataToServer
        : AuthApi.postDataToServer;
      if (leaveId) {
        postData.id = parseInt(leaveId);
      }
      const { data, message } = await callback(endPoint, postData);
      if (data && data.success !== false) {
        var $this = this;
        if (leaveId) {
          swal('Leave updated successfully', '', 'success').then(() => {
            $this.props.history.goBack();
          });
        } else {
          swal('Leave applied successfully', '', 'success').then(() => {
            $this.props.history.goBack();
          });
        }
      } else {
        if (message === 'Updated') {
          swal('Leave updated successfully', '', 'success').then(() => {
            $this.props.history.goBack();
          });
        }
        swal(message, '', 'error');
      }
    }
  };

  handleOptionChange = (selectedOption, selectedType) => {
    let type = {};
    type.label = selectedOption.label;
    type.value = selectedOption.value;
    if (selectedType === 'leavetype') {
      this.setState({ leaveType: type }, () => {
        this.forceUpdate();
      });
    } else {
      this.setState({ durationType: type, fromDate: '', toDate: '' }, () => {
        this.forceUpdate();
      });
    }
  };

  changeLeaveStatus = async (status) => {
    const { leaveId } = this.state;

    const postData = {
      id: leaveId,
      status,
    };
    const endPoint = `${Api.leavesUrl}/updateLeaveStatus`;
    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      swal(`Leave ${status} successfully`, '', 'success');
      this.props.history.push('/leaves');
    }
  };

  render() {
    const {
      title,
      reason,
      leaveType,
      leaveTypeData,
      durationType,
      durationTypeData,
      leaveTypeErr,
      durationTypeErr,
      toDateErr,
      fromDateErr,
      fromDate,
      toDate,
      appointmentData,
      totalCount,
      currentPage,
      limit,
      roleId,
      canApply,
      leaveId,
      status,
      disableAll,
      hideAptChkbtn,
      name
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper" style={{ height: '100vh' }}>
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                 {leaveId && roleId === 1 ? <h5 style={{marginLeft: '20px'}}>Technician - {name}</h5> : null}
                  <div className="card-body">
                    <form
                      onSubmit={(e) => e.preventDefault()}
                      autoComplete="off"
                    >
                      <div className="form-group row">
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Type<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <ReactSelect
                                isDisabled={disableAll || roleId === 1}
                                value={leaveType}
                                placeholder="Select Leave Type"
                                onChange={(e) =>
                                  this.handleOptionChange(e, 'leavetype')
                                }
                                options={leaveTypeData}
                              />
                              <span id="err">{leaveTypeErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Reason
                            </label>
                            <div className="col-sm-9">
                              <textarea
                                className="form-control"
                                disabled={disableAll || roleId === 1}
                                rows={3}
                                value={reason}
                                onChange={(e) => {
                                  this.setState({
                                    reason: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Duration Type
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <ReactSelect
                                isDisabled={disableAll || roleId === 1}
                                value={durationType}
                                onChange={(e) => this.handleOptionChange(e, '')}
                                options={durationTypeData}
                              />
                              <span id="err">{durationTypeErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              From Date<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <DatePicker
                                disabled={disableAll || roleId === 1}
                                className="form-control"
                                placeholderText="Select Date From"
                                selected={fromDate}
                                onChange={(date) => {
                                  this.setState({
                                    fromDate: date,
                                    toDate: '',
                                    hideAptChkbtn: true,
                                    canApply: false,
                                  });
                                }}
                                minDate={new Date()}
                                name="startDate"
                                dateFormat="MM/dd/yyyy"
                              />
                              <span id="err">{fromDateErr}</span>
                            </div>
                          </div>
                          {durationType && durationType.value === 'Full Day' && (
                            <div className="form-group row">
                              <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                              >
                                To Date<span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-9">
                                <DatePicker
                                  disabled={disableAll || roleId === 1}
                                  className="form-control"
                                  placeholderText="Select Date To"
                                  selected={toDate}
                                  onChange={(date) => {
                                    this.setState({
                                      toDate: date,
                                      hideAptChkbtn: true,
                                      canApply: false,
                                    });
                                  }}
                                  minDate={fromDate}
                                  name="toDate"
                                  dateFormat="MM/dd/yyyy"
                                />
                                <span id="err">{toDateErr}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group text-right">
                        {roleId === 1 && status === 'pending' && leaveId && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light m-l-10"
                            onClick={() => this.changeLeaveStatus('approved')}
                          >
                            Approve Leave
                          </button>
                        )}
                        {roleId === 1 && status === 'pending' && leaveId && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light m-l-10"
                            onClick={() => this.changeLeaveStatus('declined')}
                          >
                            Decline Leave
                          </button>
                        )}
                        {(roleId === 5 || roleId === 6) &&
                          status !== 'cancelled' &&
                          status !== 'declined' &&
                          leaveId && (
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light m-l-10"
                              onClick={() =>
                                this.changeLeaveStatus('cancelled')
                              }
                            >
                              Cancel Leave
                            </button>
                          )}
                        {canApply &&
                          (roleId === 5 || roleId === 6) &&
                          !disableAll && (
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light m-l-10"
                              onClick={this.applyLeave}
                            >
                              {`${leaveId ? 'Update' : 'Apply'} Leave`}
                            </button>
                          )}
                        {!disableAll && roleId !== 1 && hideAptChkbtn && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light m-l-10"
                            onClick={this.checkAppointment}
                          >
                            Check Appointments
                          </button>
                        )}
                        <button
                          type="reset"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Close
                        </button>
                      </div>
                      {appointmentData && appointmentData.length ? (
                        <div className="form-group row card">
                          <div className="card-header">
                            <h5>Appointment List</h5>
                          </div>
                          <AppointmentList
                            appointmentData={appointmentData}
                            appointmentCallback={(current) =>
                              this.getAppointments(current)
                            }
                            limit={limit}
                            currentPage={currentPage}
                            totalCount={totalCount}
                          />
                          <p className="text-danger text-center">
                            Note:The appointments show the high priority. Kindly
                            look into and apply for leave.
                          </p>
                        </div>
                      ) : null}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddLeave);
