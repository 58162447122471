import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddEditVendor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      title: '',
      vendorId: null,
      vendorName: '',
      reference: '',
      vendorNameErr: '',
      referenceErr: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-vendors',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('Edit Vendor');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getVendor();
      customFunctions.setTitle('Edit Vendor');
      this.setState({
        title: `Edit Vendor`,
      });
    } else {
      customFunctions.setTitle('Add Vendor');
      this.setState({
        title: `Add Vendor`,
      });
    }
  };

  getVendor = async () => {
    const vendorData = this.props.location.state.detail;
    this.setState({
      vendorId: vendorData.id,
      vendorName: vendorData.name,
      reference: vendorData.refid,
      status: vendorData.isactive,
    });
  };

  addVendor = async () => {
    const { vendorId, vendorName, reference, status } = this.state;
    const postData = {
      name: vendorName,
      refid: reference,
    };
    const endPoint = `${Api.vendorsUrl}`;
    const callback = vendorId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (vendorId) {
      postData.id = parseInt(vendorId);
      postData.status = status;
    } else {
      postData.status = '1';
    }
    const { data, message } = await callback(endPoint, postData);

    if (data && data.success !== false) {
      var $this = this;
      if (vendorId) {
        swal('Vendor updated successfully', '', 'success').then(() => {
          $this.props.history.push('/vendors', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('Vendor added successfully', '', 'success').then(() => {
          $this.props.history.push('/vendors');
        });
      }
    } else {
      swal(message, '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { vendorName, reference } = this.state;

    let vendorNameErr = '',
      referenceErr = '';

    let isValid = true;
    if (vendorName.trim() === '') {
      vendorNameErr = 'Vendor name is required';
      isValid = false;
    }
    if (reference.trim() === '') {
      referenceErr = 'Reference is required';
      isValid = false;
    }
    this.setState({ vendorNameErr, referenceErr });
    if (isValid) {
      this.addVendor();
    }
  };

  render() {
    const { title, vendorName, vendorNameErr, reference, referenceErr } =
      this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-2 col-form-label" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Vendor Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Vendor name"
                            value={vendorName}
                            onChange={(e) => {
                              this.setState({ vendorName: e.target.value });
                            }}
                          />
                          <span id="err">{vendorNameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-2 col-form-label" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Reference<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Reference"
                            value={reference}
                            onChange={(e) => {
                              this.setState({ reference: e.target.value });
                            }}
                          />
                          <span id="err">{referenceErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-2 col-form-label" />
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/vendors', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddEditVendor);
