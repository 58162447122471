import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import CANCEL from '../../../assets/images/cancel.png';
import DRAFT from '../../../assets/images/draft.png';
import LOST from '../../../assets/images/lost.png';
import SENT from '../../../assets/images/sent.png';
import WON from '../../../assets/images/won.png';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
import CustomDropdown from '../../../components/Dropdown/dropdown.component';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';

const columns = [
  {
    label: 'Status',
    field: 'estimateType',
    sort: 'disabled',
    width: 70,
  },
  // {
  //   label: 'Status',
  //   id: 'status',
  //   sort: 'disabled',
  // },
  {
    label: 'Estimate ID',
    field: 'estimateNo',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Customer Name',
    field: 'customerName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Job Details',
    field: 'jobDetails',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Date',
    field: 'date',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Cost($)',
    field: 'price',
    sort: 'disabled',
    width: 150,
  },
  // {
  //   label: 'Action',
  //   id: 'actions',
  //   sort: 'disabled',
  // },
];

class EstimateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      items: [],
      rows: [],
      lineItemId: null,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      title: 'All Estimates',
      startDate: '',
      endDate: '',
      filterValue: '',
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      filterdata: [
        {
          name: 'All Estimates',
          value: '',
        },
        {
          name: 'Draft',
          value: 'Draft',
          imageUrl: DRAFT,
        },
        {
          name: 'Sent',
          value: 'Sent',
          imageUrl: SENT,
        },
        {
          name: 'Won',
          value: 'Won',
          imageUrl: WON,
        },
        {
          name: 'Lost',
          value: 'Lost',
          imageUrl: LOST,
        },
      ],
      filterDateTypes: [
        {
          name: 'Issue Date',
          value: 'issue_date',
        },
        {
          name: 'Create Date',
          value: 'create_date',
        },
        {
          name: 'Work Start Date',
          value: 'work_start_date',
        },
      ],
      filterDateType: '',
      clearSelectedDropdown: false,
      roleId: null,
      loginId: null,
      fromComponent: '',
      isShowLoader: false,
      isWriteAllowed: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-estimates'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-estimates',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    let fromComponent = this.props.fromComponent || '';
    if (!fromComponent) customFunctions.setTitle(this.state.title);

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          loginId: userdata.login_id,
          fromComponent,
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        async () => {
          await this.getEstimates();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, loginId: userdata.login_id, fromComponent },
        async () => {
          await this.getEstimates();
        }
      );
    }
  };

  getEstimates = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const {
      limit,
      keyword,
      filterValue,
      pagination,
      filterDateType,
      startDate,
      endDate,
      roleId,
      loginId,
      fromComponent,
      navSkip,
      navCurrentPage,
    } = this.state;

    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    let endPoint = `${Api.getEstimate}?skip=${skip}&limit=${
      fromComponent == 'workorder' ? 1000 : pagination.itemsPerPage
    }`;
    endPoint += keyword ? '&search=' + keyword : '';
    if (filterValue) {
      endPoint += filterValue !== 'All' ? '&estimate_type=' + filterValue : '';
    }
    endPoint += filterDateType ? '&date_type=' + filterDateType : '';
    endPoint += startDate
      ? '&start_date=' + new Date(startDate).toISOString()
      : '';
    endPoint += endDate ? '&end_date=' + new Date(endDate).toISOString() : '';
    // endPoint += startDate
    //   ? '&start_date=' + customFunctions.getDate(startDate)
    //   : '';
    // endPoint += endDate ? '&end_date=' + customFunctions.getDate(endDate) : '';
    if (
      (roleId == 5 || roleId == 6) &&
      loginId &&
      fromComponent !== 'workorder'
    ) {
      endPoint += `&tech_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent !== 'workorder') {
      endPoint += `&customer_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent == 'workorder') {
      endPoint += `&customer_id=${loginId}&work_order_id=${this.props.workorderid}`;
    }
    if (roleId !== 4 && fromComponent == 'workorder') {
      endPoint += `&work_order_id=${this.props.workorderid}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          items: data.data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      swal(message, '', 'error');
    }
    this.setState({ isShowLoader: false });
  };

  downloadCSV = async () => {
    const {
      limit,
      keyword,
      filterValue,
      filterDateType,
      startDate,
      endDate,
      roleId,
      loginId,
      fromComponent,
    } = this.state;

    let endPoint = `${Api.downloadEstimateCsvUrl}?search=${keyword}`;
    if (filterValue) {
      endPoint += filterValue !== 'All' ? '&estimate_type=' + filterValue : '';
    }
    endPoint += filterDateType ? '&date_type=' + filterDateType : '';
    endPoint += startDate
      ? '&start_date=' + new Date(startDate).toISOString()
      : '';
    endPoint += endDate ? '&end_date=' + new Date(endDate).toISOString() : '';
    if (
      (roleId == 5 || roleId == 6) &&
      loginId &&
      fromComponent !== 'workorder'
    ) {
      endPoint += `&tech_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent !== 'workorder') {
      endPoint += `&customer_id=${loginId}`;
    }
    if (roleId == 4 && loginId && fromComponent == 'workorder') {
      endPoint += `&customer_id=${loginId}&work_order_id=${this.props.workorderid}`;
    }
    if (roleId !== 4 && fromComponent == 'workorder') {
      endPoint += `&work_order_id=${this.props.workorderid}`;
    }
    window.open(endPoint, '_blank');
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const {
      items,
      currentPage,
      filterDateType,
      roleId,
      isWriteAllowed,
      fromComponent,
    } = this.state;
    const page = npage;
    const rows = [];
    items &&
      items.length > 0 &&
      items.map((item, i) => {
        if (
          (roleId == 4 &&
            item.estimate_type &&
            item.estimate_type !== 'DRAFT') ||
          roleId !== 4
        ) {
          rows.push({
            estimateType: (
              <React.Fragment>
                <img src={this.getImage(item.estimate_type)} />
              </React.Fragment>
            ),
            // status: item.estimate_type ? item.estimate_type : '-',
            estimateNo: (
              <React.Fragment>
                <a
                  href="/"
                  style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (fromComponent === 'workorder') {
                      this.props.history.push(
                        `/estimateDetail/${item.estimate_id}`,
                        {
                          orderDetails: this.props.orderDetails,
                          parentId: item.parent_id,
                          fromComponent: 'workorder',
                        }
                      );
                    } else {
                      this.props.history.push(
                        `/estimateDetail/${item.estimate_id}`,
                        {
                          path: '/estimates',
                          parentId: item.parent_id,
                          paginationObj: {
                            navSkip: skip,
                            navCurrentPage: npage,
                          },
                        }
                      );
                    }
                  }}
                >
                  #{item.estimate_id}
                </a>
              </React.Fragment>
            ),
            // estimateNo: item.estimateNo ? item.estimateNo : '-',
            customerName: item.customer_name ? item.customer_name : '-',
            jobDetails: item.order_type ? item.order_type : '-',
            // date:
            //   filterDateType == 'create_date' || filterDateType == 'issue_date'
            //     ? customFunctions.getMDYDate(item.issue_date)
            //     : customFunctions.getMDYDate(item.work_start_date),
            date:
              filterDateType == 'issue_date' || item.issue_date
                ? customFunctions.getMDYDate(item.issue_date)
                : '-',
            price: item.total ? `$${item.total}` : '-',
            // actions: (
            //   <React.Fragment>
            //     <a
            //       href="/"
            //       className=""
            //       onClick={(e) => {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         this.props.history.push(`/estimateDetail/${item.id}`);
            //       }}
            //     >
            //       <i className="mdi mdi-eye" title="Edit Line item"></i>
            //     </a>
            //     <a
            //       href="/"
            //       className=""
            //       onClick={(e) => {
            //         e.preventDefault();
            //         e.stopPropagation();
            //         this.setState({ showDelete: true, lineItemId: item.id });
            //       }}
            //     >
            //       <i
            //         className="mdi mdi-delete text-danger"
            //         title="Delete Line item"
            //       ></i>
            //     </a>
            //   </React.Fragment>
            // ),
          });
        }
        return item;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteLineItem = async () => {
    const { lineItemId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getLineItem}?item_id=${lineItemId}`
    );
    if (data) {
      this.setState({ showDelete: false, lineItemId: null }, () => {
        this.getEstimates();
      });
      swal('Line item deleted', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getEstimates();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getEstimates();
    });
  };

  clearAllFilter = () => {
    this.setState(
      {
        startDate: '',
        endDate: '',
        filterValue: '',
        filterDateType: '',
        isFilterOn: false,
        clearSelectedDropdown: true,
      },
      () => {
        this.getEstimates();
      }
    );
  };

  getImage = (imgs) => {
    let img = imgs && imgs.toUpperCase();
    switch (img) {
      case 'DRAFT':
        return DRAFT;
        break;
      case 'WON':
        return WON;
        break;
      case 'LOST':
        return LOST;
        break;
      case 'SENT':
        return SENT;
        break;
    }
  };

  handleChangeEstimate = (item) => {
    this.setState(
      {
        filterValue: item.value,
        isFilterOn: true,
        clearSelectedDropdown: false,
      },
      () => {
        this.getEstimates();
      }
    );
  };
  handleChangeDate = (item) => {
    this.setState(
      {
        filterDateType: item.value,
        isFilterOn: true,
        clearSelectedDropdown: false,
      },
      () => {
        this.getEstimates();
      }
    );
  };

  render() {
    const {
      keyword,
      rows,
      showDelete,
      filterdata,
      pagination,
      filterDateType,
      filterDateTypes,
      startDate,
      endDate,
      isFilterOn,
      clearSelectedDropdown,
      roleId,
      fromComponent,
      isShowLoader,
      isWriteAllowed,
      totalCount,
      currentPage,
      limit,
      navSkip,
      navCurrentPage,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div
          className="page-content-wrapper"
          style={{ paddingTop: fromComponent == 'workorder' ? 0 : '80px' }}
        >
          <div className="container-fluid">
            {!fromComponent && <HelmetComponent title={this.state.title} />}
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete line item ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteLineItem();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, lineItemId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    {fromComponent !== 'workorder' && (
                      <div className="table-header table-header-estimation">
                        <div className="left-section col-3 pl-0">
                          <form onSubmit={this.onSearchSubmit}>
                            <div className="form-group d-flex">
                              <div className="input-wrap">
                                <input
                                  className="form-control"
                                  value={keyword}
                                  type="text"
                                  placeholder="Search by customer name"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    this.setState({ keyword: e.target.value });
                                  }}
                                />
                                <div
                                  className="btn-close"
                                  onClick={() => {
                                    this.clearSearch();
                                  }}
                                >
                                  <img src={CANCEL} />
                                </div>
                              </div>

                              <div className="buttons-wrap">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Search
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        <div className="right-section col-9 pr-0">
                          <div className="right-actions col-12 select-box-estimation">
                            <Row>
                              <Col>
                                <div className="filterby">
                                  <CustomDropdown
                                    items={filterdata}
                                    placeholder="Filter by.."
                                    clearAllFilter={this.clearAllFilter}
                                    isFilterOn={isFilterOn}
                                    clearSelectedDropdown={
                                      clearSelectedDropdown
                                    }
                                    handleSelect={this.handleChangeEstimate}
                                  />
                                  {/* {isFilterOn &&
                                <div className="btn-group filter-result" role="group" aria-label="Basic mixed styles example">
                                  <span>
                                  <button type="button"  className="btn btn-secondary active">Filtering is on</button>
                                  </span>
                                  <button type="button" onClick={this.clearAllFilter} className="btn btn-secondary">X</button>
                                </div>} */}
                                </div>
                              </Col>
                              <Col>
                                <div className="filterdate">
                                  <CustomDropdown
                                    items={filterDateTypes}
                                    placeholder="Select Date Type"
                                    clearSelectedDropdown={
                                      clearSelectedDropdown
                                    }
                                    handleSelect={this.handleChangeDate}
                                  />
                                </div>
                              </Col>

                              <Col>
                                <DatePickerComponent
                                  selected={startDate}
                                  placeholderText="Start Date"
                                  disabled={!filterDateType}
                                  onChange={(date) => {
                                    this.setState(
                                      { startDate: date, isFilterOn: true },
                                      () => {
                                        if (endDate) {
                                          this.getEstimates();
                                        }
                                      }
                                    );
                                  }}
                                  maxDate={new Date()}
                                  startDate={startDate}
                                  endDate={endDate}
                                  className="form-control"
                                />
                              </Col>
                              <Col>
                                <DatePickerComponent
                                  selected={endDate}
                                  placeholderText="End Date"
                                  disabled={!filterDateType || !startDate}
                                  onChange={(date) =>
                                    this.setState(
                                      { endDate: date, isFilterOn: true },
                                      () => {
                                        this.getEstimates();
                                      }
                                    )
                                  }
                                  selectsEnd
                                  startDate={startDate}
                                  endDate={endDate}
                                  minDate={startDate}
                                  className="form-control"
                                />
                              </Col>

                              <Col>
                                {isWriteAllowed && [1, 2, 3].includes(roleId) && (
                                  <Button
                                    style={{
                                      float: 'right',
                                      whiteSpace: 'nowrap',
                                    }}
                                    onClick={() =>
                                      this.props.history.push(`/estimateDetail`)
                                    }
                                  >
                                    Add Estimate
                                  </Button>
                                )}
                              </Col>
                              <Col style={{ paddingRight: 0 }}>
                                {fromComponent !== 'workorder' &&
                                  rows &&
                                  rows.length > 0 && (
                                    <button
                                      type="submit"
                                      style={{
                                        fontSize: '15px',
                                        padding: '6px 11px',
                                      }}
                                      className="btn btn-primary waves-effect waves-light"
                                      onClick={() => {
                                        this.downloadCSV();
                                      }}
                                    >
                                      <i className="mdi mdi-download"></i> CSV
                                    </button>
                                  )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>
                    )}
                    {fromComponent === 'workorder' &&
                      [1, 2, 3].includes(roleId) && (
                        <div style={{ textAlign: 'right' }}>
                          <Button
                            style={{
                              whiteSpace: 'nowrap',
                              marginBottom: '15px',
                            }}
                            onClick={() =>
                              this.props.history.push(`/estimateDetail`, {
                                orderDetails: this.props.orderDetails,
                                fromComponent: 'workorder',
                              })
                            }
                          >
                            Add Estimate
                          </Button>
                        </div>
                      )}

                    {/* <Table
                      columns={columns}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.getEstimates(pageNo);
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    /> */}
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getEstimates
                                )
                              : this.getEstimates
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(EstimateComponent);
