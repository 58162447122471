// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import ActiveBillingItemTable from './billingTables/activeBillingItems';
import EstimateTable from './billingTables/estimates';
import InvoiceTable from './billingTables/invoices';
import JobsWithoutInvoiceTable from './billingTables/jobsWithoutInvoice';
import LineItemsTables from './billingTables/lineItems';
import PaidInvoiceTables from './billingTables/paidInvoices';
import PaymentTables from './billingTables/payment';
import PaymentMethodTable from './billingTables/paymentMethods';
import TeamMemberWinRateTables from './billingTables/teamMemberWinRate';
import BarLineChart from './charts/BarLineChart';
import StackedBarChart from './charts/StackedBarChart';

class Billing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Billing',
      dateRangeList: [
        'Current Week',
        'Last Week',
        'Current Month',
        'All Dates',
        '365 Days',
        '180 Days',
        '90 Days',
        '60 Days',
        '30 Days',
        '14 Days',
        '7 Days',
        '3 Days',
        '1 Day',
      ],
      dateRange: '30 Days',
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      filterApplied: false,
      weeklyData: '',
      monthlyData: '',
      quarterlyData: '',
      yearlyData: '',
      noDraftData: '',
      totalPaidData: '',
      avgInvoiceNonDraft: '',
      avgOfInvoiceCreated: [],
      salesBreakdownTime: [],
      paymentTaken: [],
      invoiceCreated: [],
      filterCount: 1,
      initialHide: true,
      isShowLoader: false,
    };
  }

  async componentDidMount() {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'reports-billing'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle(this.state.title);
    await this.getWeeklyData();
    await this.getMonthlyData();
    await this.getQuarterlyData();
    await this.getYearlyData();
    await this.getNoDraftData();
    await this.getTotalPaidData();
    await this.getAvgInvoiceNonDraft();
    await this.getAvgOfInvoiceCreated();
    await this.getInvoiceCreated();
    await this.getPaymentTaken();
    await this.getSalesBreakdown();
  }

  getInvoiceCreated = async () => {
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getInvoiceCreated}?start_date=${startDate}&end_date=${endDate}`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ invoiceCreated: data.data, isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getPaymentTaken = async () => {
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getPaymentTaken}?start_date=${startDate}&end_date=${endDate}`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ paymentTaken: data.data, isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getAvgOfInvoiceCreated = async () => {
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getAvgInvoiceData}?start_date=${startDate}&end_date=${endDate}`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ avgOfInvoiceCreated: data.data, isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getWeeklyData = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getWeeklyData}?start_date=${startDate}&end_date=${endDate}`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        weeklyData: data.data[0].weekly_total,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getMonthlyData = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getMonthlyData}?start_date=${startDate}&end_date=${endDate}`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        monthlyData: data.data[0].monthly_total,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getQuarterlyData = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getQuarterlyData}?start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        quarterlyData: data.data[0].quarterly_total,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getYearlyData = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getYearlyData}?start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        yearlyData: data.data[0].yearly_total,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getNoDraftData = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getNoDraftData}?start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ noDraftData: data.data[0].total, isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getTotalPaidData = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getTotalPaidData}?start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ totalPaidData: data.data[0].total, isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getAvgInvoiceNonDraft = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getAvgInvoiceNonDraft}?start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        avgInvoiceNonDraft: data.data[0].total,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  getSalesBreakdown = async () => {
    this.setState({ isShowLoader: true });
    let { startDate, endDate, dateRange } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getSalesBreakdownUrl}?start_date=${startDate}&end_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ salesBreakdownTime: data.data, isShowLoader: false });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  changeDates = async () => {
    const { dateRange } = this.state;
    var date = new Date();
    let firstday;

    if (dateRange === 'Current Week') {
      var curr = new Date();
      var first = curr.getDate() - curr.getDay();
      var last = first + 6;
      // firstday = new Date(curr.setDate(first));
      firstday = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(first))
      );

      // date = new Date(curr.setDate(last));
      date = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(last))
      );
    }
    if (dateRange === 'Last Week') {
      let dates = customFunctions.getLastWeek(date);
      firstday = dates.firstDayOfLastWeek;
      date = dates.lastDayOfLastWeek;
    }
    if (dateRange === 'Current Month') {
      firstday = new Date(date.getFullYear(), date.getMonth(), 1);
      date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    if (dateRange === '365 Days') {
      firstday = new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '180 Days') {
      firstday = new Date(date.getTime() - 180 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '90 Days') {
      firstday = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '60 Days') {
      firstday = new Date(date.getTime() - 60 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '30 Days') {
      firstday = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '14 Days') {
      firstday = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '7 Days') {
      firstday = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '3 Days') {
      firstday = new Date(date.getTime() - 3 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '1 Day') {
      firstday = new Date(date.getTime() - 24 * 60 * 60 * 1000);
    }
    this.setState({ startDate: firstday, endDate: date, initialHide: false });

    if (dateRange === 'All Dates') {
      this.setState({ startDate: '', endDate: '' });
    }
    this.filterCount();
  };

  filterCount = () => {
    const { dateRange } = this.state;
    let dateLen = dateRange !== '' ? 1 : 0;
    this.setState({ filterCount: dateLen });
  };

  callback = () => {
    this.setState({ filterApplied: false });
  };

  render() {
    const {
      title,
      dateRange,
      dateRangeList,
      startDate,
      endDate,
      filterApplied,
      weeklyData,
      monthlyData,
      quarterlyData,
      yearlyData,
      noDraftData,
      totalPaidData,
      avgInvoiceNonDraft,
      salesBreakdownTime,
      avgOfInvoiceCreated,
      paymentTaken,
      invoiceCreated,
      filterCount,
      initialHide,
      isShowLoader,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12 m-b-20">
                <div id="accordion">
                  <div
                    className="card"
                    style={{
                      position: 'relative',
                      border: '2px solid #CCC',
                      borderBottom: 'none',
                    }}
                  >
                    <div
                      style={{ fontSize: '16px' }}
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      <b>{`FILTERS(${filterCount})`}</b>{' '}
                      <span className="dropdown-toggle"></span>{' '}
                    </div>
                    {dateRange && (
                      <span className="selected-daterange">
                        <b>DateRange</b> {dateRange}
                        {initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                        {!initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse show`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                      </span>
                    )}
                    <div
                      id="collapseOne"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="card-body1">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="accordion" id="accordionExample">
                                <div className="card">
                                  <div className="card-body1" id="headingOne">
                                    <button
                                      className="btn btn-block text-left dropdown-toggle card-header"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne1"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Date Range
                                    </button>
                                  </div>

                                  <div
                                    id="collapseOne1"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                  >
                                    <div className="card-body">
                                      <Radio
                                        checked={dateRange === 'Custom Range'}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              dateRange: e.target.value,
                                            },
                                            () => this.filterCount()
                                          );
                                        }}
                                        id="customrange"
                                        value="Custom Range"
                                        color="default"
                                      />
                                      <label htmlFor="customrange">
                                        Custom Range
                                      </label>

                                      <br />
                                      {dateRange === 'Custom Range' && (
                                        <span>
                                          <DatePickerComponent
                                            selected={startDate}
                                            placeholderText="Start Date"
                                            onChange={(date) => {
                                              this.setState({
                                                startDate: date,
                                              });
                                            }}
                                            startDate={startDate}
                                            endDate={endDate}
                                            className="form-control filter-date"
                                          />
                                          <br />
                                          <DatePickerComponent
                                            selected={endDate}
                                            placeholderText="End Date"
                                            onChange={(date) =>
                                              this.setState({ endDate: date })
                                            }
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            className="form-control filter-date"
                                          />
                                        </span>
                                      )}
                                      {dateRangeList &&
                                        dateRangeList.map((dates, index) => (
                                          <React.Fragment key={index}>
                                            <Radio
                                              checked={dateRange === dates}
                                              onChange={(e) => {
                                                this.setState(
                                                  {
                                                    dateRange: e.target.value,
                                                  },
                                                  () => this.changeDates()
                                                );
                                              }}
                                              id={`dates${index}`}
                                              value={dates}
                                              color="default"
                                            />
                                            <label htmlFor={`dates${index}`}>
                                              {dates}
                                            </label>
                                            <br />
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-4"
                              style={{ paddingLeft: '0px' }}
                            ></div>
                            <div
                              className="col-12"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="card-footer">
                                <div className="row">
                                  <div className="col-4"></div>
                                  <div className="col-4"></div>
                                  <div className="col-4">
                                    <span
                                      onClick={() =>
                                        this.setState(
                                          {
                                            dateRange: '30 Days',
                                            filterApplied: false,
                                            startDate: new Date(
                                              new Date().getTime() -
                                                30 * 24 * 60 * 60 * 1000
                                            ),
                                            endDate: new Date(),
                                          },
                                          () => this.filterCount()
                                        )
                                      }
                                      className="resetFilter f-btns"
                                    >
                                      RESET FILTERS
                                    </span>
                                    <button
                                      className="btn btn-secondary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      className="btn btn-primary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                      onClick={() => {
                                        this.setState(
                                          { filterApplied: true },
                                          async () => {
                                            await this.getWeeklyData();
                                            await this.getMonthlyData();
                                            await this.getQuarterlyData();
                                            await this.getYearlyData();
                                            await this.getNoDraftData();
                                            await this.getTotalPaidData();
                                            await this.getAvgInvoiceNonDraft();
                                            await this.getAvgOfInvoiceCreated();
                                            await this.getInvoiceCreated();
                                            await this.getPaymentTaken();
                                          }
                                        );
                                      }}
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="card col-12">
                        Sales Breakdown Over time
                        <div
                          className="card-body text-center py-5"
                          id="avgTime1"
                        >
                          <BarLineChart
                            chartData={salesBreakdownTime}
                            fromComponent="billing"
                            width="45"
                            height="10"
                          />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            WTD Sales
                          </p>
                          <h1>${weeklyData ? weeklyData.toFixed(1) : 0}k</h1>
                          <p style={{ fontSize: '22px' }}>WTD Sales</p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value : {weeklyData ? weeklyData : 0}
                          </p>
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            MTD Sales
                          </p>
                          <h1>${monthlyData ? monthlyData.toFixed(1) : 0}k</h1>
                          <p style={{ fontSize: '22px' }}>MTD Sales</p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value : {monthlyData ? monthlyData : 0}
                          </p>
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            QTD Sales
                          </p>
                          <h1>
                            ${quarterlyData ? quarterlyData.toFixed(1) : 0}k
                          </h1>
                          <p style={{ fontSize: '22px' }}>QTD Sales</p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value : {quarterlyData ? quarterlyData : 0}
                          </p>
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            YTD Sales
                          </p>
                          <h1>${yearlyData ? yearlyData.toFixed(1) : 0}k</h1>
                          <p style={{ fontSize: '22px' }}>YTD Sales</p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value : {yearlyData ? yearlyData : 0}
                          </p>
                        </div>
                      </div>
                      <div className="card col-4">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Total Invoiced (Non-Draft)
                          </p>
                          <h1>${noDraftData ? noDraftData.toFixed(1) : 0}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Total Invoiced (Non-Draft)
                          </p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value : {noDraftData ? noDraftData : 0}
                          </p>
                        </div>
                      </div>
                      <div className="card col-4">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Total Paid
                          </p>
                          <h1>
                            ${totalPaidData ? totalPaidData.toFixed(1) : 0}
                          </h1>
                          <p style={{ fontSize: '22px' }}>Total Paid</p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value : {totalPaidData ? totalPaidData : 0}
                          </p>
                        </div>
                      </div>
                      <div className="card col-4">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime3"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Invoiced (Non-Draft)
                          </p>
                          <h1>
                            $
                            {avgInvoiceNonDraft
                              ? avgInvoiceNonDraft.toFixed(1)
                              : 0}
                          </h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Invoiced (Non-Draft)
                          </p>
                          <br />
                          <p className="avgClass" style={{ fontSize: '12px' }}>
                            Original Value :{' '}
                            {avgInvoiceNonDraft ? avgInvoiceNonDraft : 0}
                          </p>
                        </div>
                      </div>
                      <div className="card col-4">
                        Invoices Created
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <StackedBarChart
                            chartData={invoiceCreated}
                            fromComponent="billing-invoiceCreated"
                            type="stackedgroup"
                            width="65"
                            height="35"
                          />
                        </div>
                      </div>
                      <div className="card col-4">
                        Payments Taken
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <StackedBarChart
                            chartData={paymentTaken}
                            fromComponent="billing-paymentTaken"
                            type="stackedgroup"
                            width="65"
                            height="35"
                          />
                        </div>
                      </div>
                      <div className="card col-4">
                        Average of Invoices Created
                        <div
                          className="card-body text-center py-3"
                          id="avgTime3"
                        >
                          <StackedBarChart
                            chartData={avgOfInvoiceCreated}
                            fromComponent="billing-avgOfInvoiceCreated"
                            width="65"
                            height="35"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <InvoiceTable
                      title="Invoices Created in the Date Range"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                      csvType="downloadCSVForInvoiceCreatedDate"
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <LineItemsTables
                      title="Line Items used on a Document"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div>
                      <JobsWithoutInvoiceTable
                        title="Jobs without Invoices & Estimates"
                        startDate={startDate}
                        endDate={endDate}
                        filterApplied={filterApplied}
                        callback={this.callback}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <EstimateTable
                      title="Estimates Created in the Date Range"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                    />
                  </div>
                </div>
              </div>
              <div className="col-7">
                <div className="card m-b-20">
                  <div className="card-body">
                    <TeamMemberWinRateTables
                      title="Team Member Win Rate"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                    />
                  </div>
                </div>
              </div>
              <div className="col-5">
                <div className="card m-b-20">
                  <div className="card-body">
                    <PaidInvoiceTables
                      title="Total of Paid Invoices in the Date Range"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <PaymentTables
                      title="Payments Created in the Date Range"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <ActiveBillingItemTable
                      title="Active Billing Items"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                    />
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <InvoiceTable
                      title="Invoices Created in the Date Range - With Postal Code and Phone Number"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      tableType="InvoiceWithPhone"
                      callback={this.callback}
                      csvType="downloadCSVForInvoiceWithPC"
                    />
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="card m-b-20">
                  <div className="card-body">
                    <PaymentMethodTable
                      title="Payment Methods"
                      startDate={startDate}
                      endDate={endDate}
                      filterApplied={filterApplied}
                      callback={this.callback}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(Billing);
