import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../../helper/authApi';
import Api from '../../../../helper/api';
import customFunctions from '../../../../helper/customFunctions';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../../components/Pagination';

class JobsWithoutInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      startDate: '',
      endDate: '',
    };
  }
  async componentDidMount() {
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'JOB ID',
        field: 'jobid',
        width: 80,
      },
      {
        label: 'CREATED',
        field: 'created',
        width: 180,
      },
      {
        label: 'CUSTOMER',
        field: 'customer',
        width: 150,
      },
      {
        label: 'STATUS',
        field: 'status',
        width: 150,
      },
      {
        label: 'TITLE',
        field: 'title',
        width: 150,
      },
      {
        label: 'SOURCE',
        field: 'source',
        width: 150,
      },
      {
        label: 'SERVICE TYPE',
        field: 'servicetype',
        width: 150,
      },
      {
        label: 'VIEW JOB',
        field: 'actions',
        width: 150,
      },
    ];
    this.setState(
      { columns, startDate: this.props.startDate, endDate: this.props.endDate },
      () => {
        this.getJobsWithoutInvoice();
      }
    );
  }
  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      this.setState(
        { startDate: props.startDate, endDate: props.endDate },
        () => {
          this.getJobsWithoutInvoice();
        }
      );
    }
  }
  getJobsWithoutInvoice = async (currentPage) => {
    let { pagination, startDate, endDate } = this.state;

    this.setState({ currentPage });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.getJobsWithoutInvoiceData}?skip=${skip}&limit=${pagination.itemsPerPage}&start_date=${startDate}&end_date=${endDate}`;

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          jobs: data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.props.callback();
  };

  downloadCSVForJobsWithoutInvoice = async () => {
    const { startDate, endDate } = this.state;
    let endPoint = `${Api.downloadCSVForJobsWithoutInvoice}?skip=0&limit=10000&start_date=${startDate}&end_date=${endDate}`;

    window.open(endPoint, '_blank');
  };

  setTableData = () => {
    const { jobs, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    jobs &&
      jobs.length &&
      jobs.map((job, i) => {
        job.WorkOrderDate = customFunctions.changeDateFormatMDY(
          job.WorkOrderDate
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          jobid: job.ID || '-',
          created: job.WorkOrderDate || '-',
          customer: job.CustomerName || '-',
          status: job.WorkStatus || '-',
          title: job.job_title || '-',
          source: job.source || '-',
          servicetype: job.WorkOrderType || '-',
          // servicetype: job.ServiceType || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${job.ID}`, {
                    orderDetails: jobs,
                    type: 'jobs',
                  });
                }}
              >
                <i className="mdi mdi-eye" id="viewJob" title="View Job"></i>
              </a>
            </React.Fragment>
          ),
        });
        return jobs;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6> {this.props.title}</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                this.downloadCSVForJobsWithoutInvoice();
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>

        {/* <Table
        columns={columns}
        rows={rows}
        showPagination
        paginationProps={{
          ...pagination,
          activePage: pagination.current_page,
          onPageChange: (pageNo) => {
            this.getJobsWithoutInvoice(pageNo);
          },
        }}
        tableClass="table-bordered"
        dropdownContainerClass="mb-3"
        emptyRender={() => <p className="text-center mb-0">No record found</p>}
      /> */}
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="350px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getJobsWithoutInvoice}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(JobsWithoutInvoice);
