import React, { Component } from 'react';
import { Button, Card, Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';

class WorkOrderStatusUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowLoader: false,
      orderId: null,
      supplyDataLength: null,
      isPartArrived: false,
      statusId: '',
      supplyData: [
        {
          supplyHouse: '',
          supplyNumber: '',
          partOrdered: '',
          partNumber: '',
          poNumber: '',
          partDeliveryAddress: '',
          trackingNumber: '',
          partETA: '',
          speakWith: '',
          note: '',
        },
      ],
      newIndexArray: [],
    };
  }

  componentDidMount = async () => {
    const id = this.props && this.props.workorderId;
    await this.setState({ orderId: id });
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
      loginId: userdata.login_id,
    });
    this.getSupplyData();
  };

  getSupplyData = async () => {
    const { orderId } = this.state;
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.getPendingWorkstatus}?work_order_id=${orderId}`
    );
    const newSupplyData = [];
    if (data && data.success) {
      if (data && data.data && data.data.length === 0) return;
      data &&
        data.data &&
        data.data.map((data) => {
          newSupplyData.push({
            id: data && data.id,
            supplyHouse: data.SupplyHouse,
            supplyNumber: data.SupplyHousePhoneNumber,
            partOrdered: data.PartOrdered,
            partNumber: data.PartNumber,
            poNumber: data.PONumber,
            partDeliveryAddress: data.PartDeliveryAddress,
            trackingNumber: data.TrackingNumber,
            partETA: data.PartETA ? new Date(data.PartETA) : null,
            speakWith: data.WhoDidYouSpeakWith,
            note: data.note,
            viewType: true,
            isPartArrived: data.IsPartArrived,
          });
          return data;
        });
      this.setState({
        supplyData: newSupplyData,
        supplyDataLength:
          data && data.data && data.data.length ? data.data.length : 0,
        isPartArrived: data.data[0].IsPartArrived === 1 ? true : false,
        statusId: data.data[0].id,
      });
    } else {
      swal(message, '', 'error');
    }
  };

  addSupply = () => {
    const { supplyData } = this.state;
    supplyData.push({
      supplyHouse: '',
      supplyNumber: '',
      partOrdered: '',
      partNumber: '',
      poNumber: '',
      partDeliveryAddress: '',
      trackingNumber: '',
      partETA: '',
      speakWith: '',
      note: '',
    });
    this.setState({ ...supplyData });
  };

  removeSupply = (id) => {
    const { supplyData } = this.state;
    supplyData.splice(id, 1);
    this.setState({ ...supplyData });
  };

  validateForm = (e) => {
    e.preventDefault();
    const { supplyData } = this.state;
    let isValid = true;
    if (supplyData.length > 0) {
      supplyData &&
        supplyData.map((supply, index) => {
          supplyData[index].supplyHouseErr = '';
          supplyData[index].supplyNumberErr = '';
          supplyData[index].partOrderedErr = '';
          supplyData[index].partNumberErr = '';
          supplyData[index].poNumberErr = '';
          supplyData[index].partDeliveryAddressErr = '';
          supplyData[index].trackingNumberErr = '';
          supplyData[index].partETAErr = '';
          supplyData[index].noteErr = '';

          if (supply.partOrdered === '') {
            supplyData[index].partOrderedErr = 'Part name is required';
            isValid = false;
          }

          this.setState({ ...supplyData });
          return supplyData;
        });
    }

    if (isValid) {
      this.updateStatus();
    }
  };

  updateStatus = async () => {
    const { supplyData, orderId, supplyDataLength, newIndexArray } = this.state;
    const actualSupplyData = [];
    if (supplyData && supplyData.length > 0) {
      supplyData.map((data, i) => {
        if (
          newIndexArray &&
          newIndexArray.length > 0 &&
          newIndexArray.includes(i)
        ) {
          actualSupplyData.push({
            id: data.id ? data.id : '',
            SupplyHouse: data.supplyHouse,
            SupplyHousePhoneNumber: data.supplyNumber,
            PartOrdered: data.partOrdered,
            PartNumber: data.partNumber,
            PONumber: data.poNumber,
            PartDeliveryAddress: data.partDeliveryAddress,
            TrackingNumber: data.trackingNumber,
            PartETA: data.partETA && customFunctions.getMDYDate(data.partETA),
            WhoDidYouSpeakWith: data.speakWith,
            note: data.note,
          });
        }
        return data;
      });
    }

    const postData = {
      work_order_id: Number(orderId),
      items: actualSupplyData,
    };

    const payload = await customFunctions.cleanObject(postData);
    if (payload && payload.items && payload.items.length === 0) {
      return;
    }
    const endpoint = supplyDataLength && Api.updateDailyWorkPending;
    const callback = supplyDataLength && AuthApi.putDataToServer;

    const { data, message } = await callback(endpoint, payload);
    if (data) {
      this.setState({ newIndexArray: [] });
      swal('Workorder status updated!', '', 'success');
      if (this.props.popupClose) this.props.callbackfun();
      if (this.props.popupClose) this.props.popupClose();
    } else {
      swal(message, '', 'error');
    }
  };

  updatePartsArrived = async (e, id) => {
    e.preventDefault();
    const { orderId } = this.state;
    const postData = {
      work_order_id: Number(orderId),
      id,
      button: 'partarrived',
    };

    const endPoint = Api.updateStatusAhsButton;
    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);
    if (data) {
      swal('Parts arrived successfully', '', 'success');
      if (this.props.popupClose) this.props.callbackfun();
      if (this.props.popupClose) this.props.popupClose();
    } else {
      swal(message, '', 'error');
    }
  };

  newChanges = (index) => {
    const { newIndexArray } = this.state;
    if (!newIndexArray.includes(index)) {
      newIndexArray.push(index);
      this.setState({
        newIndexArray,
      });
    }
  };

  render() {
    const { supplyData, isShowLoader, roleId } = this.state;
    return (
      <AUX>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={this.props.popupClose}
          show={this.props.dispatherStatusPopup}
        >
          <Modal.Header className="col-12">
            <Modal.Title className="text-secondary">
              <h5>Most Recent Status Update</h5>
            </Modal.Title>
            <i
              className="mdi mdi-close"
              onClick={() => this.props.popupClose()}
            ></i>
          </Modal.Header>
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <Modal.Body style={{ maxHeight: '480px', overflowY: 'scroll' }}>
            <div className="row">
              <div className="col-12">
                <form autoComplete="off" onSubmit={(e) => this.validateForm(e)}>
                  {supplyData &&
                    supplyData.map((supply, index) => (
                      <div key={`supply-data-${index}`}>
                        <Card>
                          <Card.Body>
                            <div className="form-group row">
                              <label
                                htmlFor="supply-house"
                                className="col-sm-2 col-form-label"
                              >
                                Supply House
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  placeholder="Supply House"
                                  type="text"
                                  value={supply.supplyHouse}
                                  id="supply-house"
                                  onChange={async (e) => {
                                    supplyData[index].supplyHouse =
                                      e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                              </div>
                              <label
                                htmlFor="supply-number"
                                className="col-sm-2 col-form-label"
                              >
                                Supply Number
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  placeholder="Supply House Phone Number"
                                  type="text"
                                  value={supply.supplyNumber}
                                  id="supply-numbere"
                                  maxLength="10"
                                  onChange={async (e) => {
                                    supplyData[index].supplyNumber =
                                      e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="part-ordered"
                                className="col-sm-2 col-form-label"
                              >
                                Part Ordered
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  placeholder="Part Name"
                                  type="text"
                                  value={supply.partOrdered}
                                  id="part-ordered"
                                  onChange={async (e) => {
                                    supplyData[index].partOrdered =
                                      e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                                <span id="err">
                                  {supplyData[index].partOrderedErr}
                                </span>
                              </div>
                              <label
                                htmlFor="part-number"
                                className="col-sm-2 col-form-label"
                              >
                                Part Number
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  placeholder="Part Number"
                                  type="text"
                                  value={supply.partNumber}
                                  id="part-numbere"
                                  onChange={async (e) => {
                                    supplyData[index].partNumber =
                                      e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="po-number"
                                className="col-sm-2 col-form-label"
                              >
                                PO Number
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  placeholder="PO Number"
                                  type="text"
                                  value={supply.poNumber}
                                  id="po-number"
                                  onChange={async (e) => {
                                    supplyData[index].poNumber = e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                                <span id="err">
                                  {supplyData[index].poNumberErr}
                                </span>
                              </div>
                              <label
                                htmlFor="part-address"
                                className="col-sm-2 col-form-label"
                              >
                                Part Delivery Address
                              </label>
                              <div className="col-sm-4">
                                <textarea
                                  className="form-control"
                                  placeholder="Part Delivery Address"
                                  value={supply.partDeliveryAddress}
                                  id="part-address"
                                  onChange={async (e) => {
                                    supplyData[index].partDeliveryAddress =
                                      e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                                <span id="err">
                                  {supplyData[index].partDeliveryAddressErr}
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="tracking-number"
                                className="col-sm-2 col-form-label"
                              >
                                Tracking Number
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  placeholder="Tracking Number"
                                  type="text"
                                  value={supply.trackingNumber}
                                  id="tracking-number"
                                  onChange={async (e) => {
                                    supplyData[index].trackingNumber =
                                      e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                                <span id="err">
                                  {supplyData[index].trackingNumberErr}
                                </span>
                              </div>
                              <label
                                htmlFor="part-eta"
                                className="col-sm-2 col-form-label"
                              >
                                Part ETA
                              </label>
                              <div className="col-sm-4">
                                <DatePicker
                                  className="form-control"
                                  selected={
                                    supply && supply.partETA && supply.partETA
                                  }
                                  onChange={async (date) => {
                                    supplyData[index].partETA = date;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                  minDate={new Date()}
                                  name="startDate"
                                  dateFormat="MM/dd/yyyy"
                                  placeholderText="Select date"
                                />
                                <span id="err">
                                  {supplyData[index].partETAErr}
                                </span>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label
                                htmlFor="speak-with"
                                className="col-sm-2 col-form-label"
                              >
                                Who did you speak with
                              </label>
                              <div className="col-sm-4">
                                <input
                                  className="form-control"
                                  placeholder="Who did you speak with"
                                  type="text"
                                  value={supply.speakWith}
                                  id="speak-with"
                                  onChange={async (e) => {
                                    supplyData[index].speakWith =
                                      e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                              </div>
                              <label
                                htmlFor="note"
                                className="col-sm-2 col-form-label"
                              >
                                Note
                              </label>
                              <div className="col-sm-4">
                                <textarea
                                  className="form-control"
                                  placeholder="Please add note"
                                  id="note"
                                  value={supply.note}
                                  onChange={async (e) => {
                                    supplyData[index].note = e.target.value;
                                    this.setState({ supplyData });
                                    await this.newChanges(index);
                                  }}
                                />
                              </div>
                            </div>

                            {roleId !== 5 && (
                              <div className="form-group row d-flex justify-content-center">
                                <div className="col-sm-12 col-md-12 text-right">
                                  {supply && supply.id && (
                                    <Button
                                      disabled={
                                        Number(supply.isPartArrived) === 1
                                      }
                                      variant={
                                        Number(supply.isPartArrived) === 1
                                          ? 'secondary'
                                          : 'primary'
                                      }
                                      onClick={(e) => {
                                        this.updatePartsArrived(e, supply.id);
                                      }}
                                    >
                                      Parts Arrived
                                    </Button>
                                  )}

                                  <Button
                                    className="l-m-10"
                                    type="button"
                                    onClick={() => this.addSupply()}
                                  >
                                    +
                                  </Button>
                                  {index !== 0 && (
                                    <Button
                                      disabled={
                                        supply.viewType ? 'disabled' : ''
                                      }
                                      className="l-m-10"
                                      variant="secondary"
                                      type="button"
                                      onClick={() => this.removeSupply(index)}
                                    >
                                      -
                                    </Button>
                                  )}
                                </div>
                              </div>
                            )}
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  <div className="form-group row d-flex justify-content-center mt-2">
                    <div className="col-sm-12 col-md-12 text-right">
                      <Button
                        className="l-m-10"
                        type="submit"
                        variant="primary"
                      >
                        Submit
                      </Button>

                      <Button
                        className="l-m-10"
                        type="reset"
                        variant="secondary"
                        onClick={() => {
                          this.props.popupClose();
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}

export default withRouter(WorkOrderStatusUpdate);
