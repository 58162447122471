import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
// import Edit from '@material-ui/icons/Edit';
import Edit from '@mui/icons-material/Edit';
import Api from '../../../helper/api';
import SweetAlert from 'react-bootstrap-sweetalert';
import AuthApi from '../../../helper/authApi';
import EditMainAccount from '../Workorder/editMainAccount';
import EditSubAccountData from '../Workorder/editSubAccountData';
import swal from 'sweetalert';
import customFunctions from '../../../helper/customFunctions';
import { withRouter } from 'react-router-dom';
import { uploadFile } from 'react-s3';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';
import ATTACHMENT from '../../../assets/images/attachment.png';
import ImagePreview from '../../../components/ImagePreview';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poId1: '',
      poDate: new Date(),
      mainAccount: '',
      subAccount: '',
      poAmount: '',
      ExpenseDesc: '',
      poId2: '',
      workOrder: '',
      customer: '',
      homeWrntyCode: '',
      pieceEquipInst: '',
      technician: null,
      installerId: '',
      purchaseDesc: '',
      supplyHouse: '',
      // mainAcc: [],
      // subAccList: [],
      customerList: [],
      // wrntyCode: [],
      // technicianList: [],
      // workOrderData: [],
      listid: null,
      cstomer: null,
      code: '',
      equipInvoice: '',
      miscInvoice: '',
      miscPoAmount: null,
      miscPo: '',
      equipPoAmount: null,
      equipPo: '',
      orderId: null,
      poDateErr: '',
      mainAccountErr: '',
      poAmountErr: '',
      po: '',
      expense_type: 0,
      viewId: null,
      editType: false,
      workOrderDetails: {},
      expense_form: true,
      workOrderCode: '',
      addPurchaseId: null,
      workOrderId: null,
      installerCost: '',
      expense_code: '',
      title: '',
      roleId: null,
      isWriteAllowed: false,
      isShowLoader: false,
      // workorder_technicians: [],
      clearSubByMain: false,
      clearTechByJobID: false,
      invoiceNumber: null,
      returnPoAmount: 0,
      finalPoAmount: null,
      returnPO: 0,
      returnInvoiceNumber: null,
      mainAccountName: '',
      subAccountName: '',
      workordername: '',
      technicianName: '',
      technicianFunction: '',
      imgBucket: [],
      showDelete: false,
      imagePreviewShow: false,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addpurchaseorder'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addpurchaseorder',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });
    if (
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.workOrderId)
    ) {
      this.setState({
        workOrderId: this.props.location.state.workOrderId,
      });
      this.getWorkOrderDetailsById(this.props.location.state.workOrderId);
    }

    const addPurchaseId = this.props.match.params.viewId;
    const orderId = this.props.match.params.id;
    if (orderId) {
      this.setState({ orderId: orderId });
    }

    if (addPurchaseId) {
      this.setState({
        addPurchaseId: addPurchaseId,
        workOrder: addPurchaseId,
      });
    }

    if (
      this.props.location.state &&
      this.props.location.state.orderDetails &&
      this.props.location.state.orderDetails.ID
    ) {
      await this.setState({
        viewId: await this.props.location.state.orderDetails.ID,
      });
    }

    if (
      this.props.location.state &&
      this.props.location.state.orderDetails &&
      this.props.location.state.orderDetails.WorkOrder
    ) {
      await this.setState({
        workOrderCode: await this.props.location.state.orderDetails.WorkOrder,
      });
    }

    if (orderId) {
      customFunctions.setTitle('Edit Purchase Order');
      this.setState({
        title: `Edit Purchase Order`,
      });
      await this.getPurchaseOrderData(orderId);
    } else {
      customFunctions.setTitle('Add Purchase Order');
      this.setState({
        title: `Add Purchase Order`,
      });
    }
    // await this.getMainAccountData();
    // await this.getCustomer();
    // await this.getWarrantyCode();
    // await this.getTechnician();
    // await this.getWorkOrder();
    await this.getPoId();
  };

  getPoId = async () => {
    var id = this.props.match.params.id ? this.props.match.params.id : '';
    this.setState({ isShowLoader: true });
    let endPoint;
    if (id === '') {
      endPoint = `${Api.createPurchaseOrder}/po_id`;
    } else {
      endPoint = `${Api.createPurchaseOrder}/by_id?purchase_id=${id}`;
    }

    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState({
        po: data.data.ExpenseCode,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  getPurchaseOrderData = async (orderId) => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.createPurchaseOrder}/by_id?purchase_id=${orderId}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      let buck = [];
      JSON.parse(data.data.attachment) &&
        JSON.parse(data.data.attachment).map((i) => {
          buck.push({ url: i.url });
          return i;
        });

      this.setState({
        imgBucket: buck,
        mainAccount: data.data.MainAccount,
        mainAccountName: { name: data.data.MainAccountName },
        installerCost: data.data.InstallerCost,
        subAccount: data.data.SubAccount,
        subAccountName: { name: data.data.SubAccountName },
        technician: data.data.technician_id,
        technicianName: { name: data.data.Technician },
        cstomer: data.data.Customer_ID,
        poDate:
          data.data.ExpenseDate !== null ? new Date(data.data.ExpenseDate) : '',
        expense_type: data.data.ExpenseType,
        ExpenseDesc: data.data.ExpenseDescription,
        supplyHouse: data.data.SupplyHouse,
        installerId: data.data.InstallerID,
        miscPoAmount: data.data.MiscPOAmount,
        pieceEquipInst: data.data.PiecesOfEquipmentInstall,
        purchaseDesc: data.data.DescriptionofPurchase,
        equipInvoice: data.data.EquipInvoice,
        code: data.data.HomeWarrantyCode,
        poId1: data.data.ExpenseCode,
        poId2: data.data.PO,
        miscInvoice: data.data.MiscInvoice,
        equipPoAmount: data.data.MiscPOAmount1,
        poAmount: data.data.ExpenseAmount,
        miscPo: data.data.MiscPO,
        equipPo: data.data.EquipPO,
        workOrderId: data.data.WorkOrderID,
        invoiceNumber: data.data.invoice_no,
        returnPoAmount: data.data.return_po_amount,
        finalPoAmount: data.data.final_po_amount,
        returnPO: data.data.return_po,
        returnInvoiceNumber: data.data.return_invoice_no,
        isShowLoader: false,
      });

      const { expense_type } = this.state;
      if (expense_type === 0) {
        this.setState({
          expense_form: true,
        });
      } else {
        this.setState({
          expense_form: false,
        });
      }

      // const { mainAccount } = this.state;
      // this.updateData(mainAccount);
    } else {
      this.setState({ isShowLoader: false });
      var $this = this;
      setTimeout(() => {
        $this.props.history.goBack();
      }, 1000);
    }
  };

  // updateData = async (mainAccount) => {
  //   const {orderId} = this.state;
  //   this.setState({ isShowLoader: true });
  //   const endPoint =  orderId ? `${Api.ListItemByType}?type_id=${mainAccount}`:`${Api.ListItemByType}?type_id=${mainAccount}`;
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);

  //   if (data && data.data && data.data.data) {
  //     this.setState({
  //       subAccList: data.data.data,
  //       isShowLoader: false,
  //     });
  //   } else {
  //     this.setState({ isShowLoader: false });
  //     swal(message, '', 'error');
  //   }
  // };

  // getMainAccountData = async (type,searchKeyword) => {
  //   this.setState({ isShowLoader: true });
  //   // const endPoint = `${Api.AddListTypeUrl}`;
  //   const endPoint = searchKeyword
  //     ? `${Api.AddListTypeUrl}?search=${searchKeyword}`
  //     : `${Api.AddListTypeUrl}`;

  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data && data.data && data.data.data) {
  //     data.data.data.map((dt) => dt.name = dt.ListType);
  //     this.setState({
  //       mainAcc: data.data.data,
  //       isShowLoader: type === 'callback' && false,
  //     });
  //   } else {
  //     this.setState({ isShowLoader: type === 'callback' && false });
  //     swal(message, '', 'error');
  //   }
  // };

  // getSubAccountData = async (mainAccount,searchKeyword) => {
  //   // const {orderId} = this.state;
  //   // const url = orderId ? mainAccount.value : mainAccount;
  //   // this.setState({ isShowLoader: true });
  //    const endPoint = searchKeyword
  //     ? `${Api.ListItemByType}?type_id=${mainAccount}&search=${searchKeyword}`
  //     : `${Api.ListItemByType}?type_id=${mainAccount}`;
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data && data.data && data.data.data) {
  //     data.data.data.map((dt) => dt.name = dt.ListItem);
  //     this.setState({
  //       subAccList: data.data.data,
  //       // isShowLoader: false,
  //     },()=> this.forceUpdate());
  //   } else {
  //     // this.setState({ isShowLoader: false });
  //     swal(message, '', 'error');
  //   }
  // };

  // getCustomer = async () => {
  //   // this.setState({ isShowLoader: true });
  //   const endPoint = `${Api.customerUrl}`;
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data) {
  //     this.setState({
  //       customerList: data.data.data,
  //       // isShowLoader: false
  //     });
  //   } else {
  //     // this.setState({ isShowLoader: false });
  //     swal(message, '', 'error');
  //   }
  // };

  // getTechnician = async () => {
  //   this.setState({ isShowLoader: true });
  //   const endPoint = `${Api.AddtechnicianUrl}?skip=0&limit=100000`;
  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data) {
  //     this.setState({
  //       technicianList: data.data.data,
  //       isShowLoader: false,
  //     });
  //   } else {
  //     this.setState({ isShowLoader: false });
  //     swal(message, '', 'error');
  //   }
  // };

  // getWorkOrder = async (searchKeyword) => {
  //   this.setState({ isShowLoader: true });
  //   const endPoint = searchKeyword
  //     ? `${Api.getAhsWorkOrder}?search=${searchKeyword}`
  //     : `${Api.getAhsWorkOrder}`;

  //   const { data, message } = await AuthApi.getDataFromServer(endPoint);
  //   if (data && data.data && data.data.data) {
  //      data.data.data.map((dt) => dt.name = dt.WorkOrder);
  //     this.setState({
  //       workOrderData: data.data.data,
  //       isShowLoader: false,
  //     });
  //   } else {
  //     this.setState({ isShowLoader: false });
  //     swal(message, '', 'error');
  //   }
  // };

  getWorkOrderDetailsById = async (orderId, searchKeyword) => {
    this.setState({ isShowLoader: false });
    const endPoint = searchKeyword
      ? `${Api.orderById}?work_order_id=${orderId}&type=1&search=${searchKeyword}`
      : `${Api.orderById}?work_order_id=${orderId}&type=1`;
    // const endPoint = `${Api.orderById}?work_order_id=${orderId}&type=1`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      // if (data && data.data && data.data[0].multitech) {
      //   await data.data[0].multitech.map((tech) => {
      //     if (tech.tech_function === 'Installation') {
      //       this.setState({ technician: tech.tech_id, technicianName: { name: tech.techname } });
      //     }
      //     return tech;
      //   });
      // }
      this.setState({
        cstomer: data.data[0].CustomerID,
        // workorder_technicians: data.data[0].multitech,
        code: data.data[0].HomeWarrantyCode,
        isShowLoader: false,
        workordername: { name: data.data[0].WorkOrder },
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  createPurchaseOrder = async (e) => {
    const {
      poDate,
      orderId,
      mainAccount,
      subAccount,
      poAmount,
      ExpenseDesc,
      cstomer,
      code,
      pieceEquipInst,
      technician,
      installerId,
      purchaseDesc,
      supplyHouse,
      expense_type,
      installerCost,
      po,
      workOrderId,
      invoiceNumber,
      returnPoAmount,
      finalPoAmount,
      returnPO,
      returnInvoiceNumber,
      imgBucket,
    } = this.state;

    const postData = {
      expense_code: po,
      po_date: poDate ? customFunctions.getDate(poDate, '/') : null,
      main_account: mainAccount,
      sub_account: subAccount,
      po_amount: Number(poAmount) || null,
      work_order: workOrderId,
      customer_id: cstomer,
      description: ExpenseDesc,
      desc_of_purchase: purchaseDesc,

      // PO_no: 1234,
      warranty_code: code,
      pieces_of_equipment: pieceEquipInst || null,
      technician: technician,
      installer_id: installerId,
      supply_house: supplyHouse,
      expense_type,
      installer_cost: installerCost || 0,
      invoice_no: invoiceNumber,
      return_po_amount: returnPoAmount || 0,
      final_po_amount: finalPoAmount || 0,
      return_po: returnPO || 0,
      return_invoice_no: returnInvoiceNumber,
    };

    let urls = [];
    imgBucket &&
      imgBucket.map((i) => {
        urls.push({ url: i.url });
        return i;
      });
    if (urls && urls.length > 0) {
      postData.attachment = urls;
    }
    const callback = orderId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = `${Api.createPurchaseOrder}`;

    if (orderId) {
      postData.id = parseInt(orderId);
      postData.return_po = returnPO;
    }
    const { data } = await callback(endPoint, postData);

    if (data) {
      if (orderId) {
        var $this = this;
        swal('Purchase Order Updated', '', 'success').then(() => {
          $this.props.history.goBack();
          return;
        });
      } else {
        swal('Purchase Order Created', '', 'success').then(() => {
          this.flushAllData();
          return;
        });
      }
    }
  };

  flushAllData = async () => {
    this.setState({
      poDate: '',
      mainAccount: '',
      subAccount: '',
      poAmount: '',
      workOrder: '',
      cstomer: '',
      ExpenseDesc: '',
      poId2: '',
      code: '',
      pieceEquipInst: '',
      technician: '',
      installerId: '',
      supplyHouse: '',
      equipInvoice: '',
      miscInvoice: '',
      miscPoAmount: '',
      equipPo: '',
      equipPoAmount: '',
      miscPo: '',
      purchaseDesc: '',
      workordername: '',
    });
    this.props.history.goBack();
  };

  validate = () => {
    const {
      poDate,
      mainAccount,
      subAccount,
      poAmount,
      workOrder,
      expense_form,
      cstomer,
      pieceEquipInst,
      ExpenseDesc,
    } = this.state;

    let poDateErr = '',
      mainAccountErr = '',
      subAccountErr = '',
      poAmountErr = '',
      WorkOrderErr = '',
      customerErr = '',
      pieceEquipInstErr = '',
      technicianErr = '',
      ExpenseDescErr = '';

    let isValid = true;

    if (expense_form) {
      if (poDate === null) {
        poDateErr = 'Order Date is required';
        isValid = false;
      }

      if (mainAccount === '') {
        mainAccountErr = 'Main Account is required';
        isValid = false;
      }

      if (subAccount === '') {
        subAccountErr = 'Sub Account is required';
        isValid = false;
      }

      if (poAmount === '') {
        poAmountErr = 'PO Amount is required';
        isValid = false;
      }
    } else {
      if (poDate === null) {
        poDateErr = 'Order Date is required';
        isValid = false;
      }

      if (mainAccount === '') {
        mainAccountErr = 'Main Account is required';
        isValid = false;
      }

      if (subAccount === '') {
        subAccountErr = 'Sub Account is required';
        isValid = false;
      }

      if (poAmount === '') {
        poAmountErr = 'PO Amount is required';
        isValid = false;
      }

      if (workOrder === '') {
        WorkOrderErr = 'Work order is required';
        isValid = false;
      }

      if (cstomer === null) {
        customerErr = 'Customer is required';
        isValid = false;
      }

      if (pieceEquipInst === null) {
        pieceEquipInstErr = 'Pieces of Equip install is required';
        isValid = false;
      }

      if (ExpenseDesc === '') {
        ExpenseDescErr = 'Expense description is required';
        isValid = false;
      }
    }

    this.setState({
      poDateErr,
      mainAccountErr,
      subAccountErr,
      poAmountErr,
      WorkOrderErr,
      customerErr,
      pieceEquipInstErr,
      technicianErr,
      ExpenseDescErr,
    });

    if (isValid) {
      this.createPurchaseOrder();
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  onRegularExpense = () => {
    this.setState({
      expense_type: '1',
    });
    var $this = this;
    $this.props.history.push('/add-newexpense');
  };

  onPOExpense = () => {
    this.setState({
      expense_type: '0',
    });
  };

  calculateFinal = () => {
    const { poAmount, returnPoAmount } = this.state;
    let final = Number(poAmount) - Number(returnPoAmount);
    this.setState({ finalPoAmount: final });
  };

  handleImageFileUpload = async (e) => {
    const { imgBucket } = this.state;
    let addedFiles = e.target.files;
    this.setState({ addedFiles });

    let uploadedImages = imgBucket || [];
    let keys = Object.keys(addedFiles);
    if (!keys.length) return;
    this.setState({ uploadComplete: false });
    for (let i = 0; i < keys.length; i++) {
      const fileRes = await this.uploadImage(addedFiles[keys[i]]);
      if (!fileRes) {
        continue;
      }
      uploadedImages.push({ url: fileRes });
    }
    this.setState({ imgBucket: uploadedImages, uploadComplete: true });
    this.fileInput.value = '';
  };

  handelImageChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      imageFile: e.target.files[0],
      imageUrl: fileUrl,
    });
  };

  uploadImage = async (imageFile) => {
    if (!imageFile) return null;
    const newImage = imageFile;
    const todayDate = new Date().getTime();
    const file = new File(
      [newImage],
      ` dispatchers/${newImage.name.split('.')[0]}${todayDate}.${
        newImage.name.split('.')[1]
      }`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    return imgData;
  };

  onDeleteHandler = () => {
    const { imgBucket, index } = this.state;
    const buck = [];
    imgBucket.splice(index, 1);
    imgBucket &&
      imgBucket.map((bucket) => {
        //eslint-disable-line
        buck.push(bucket);
        return bucket;
      });
    this.setState({
      imgBucket: buck,
      showDelete: false,
      index: null,
      uploadComplete: true,
    });
  };

  render() {
    const {
      poDate,
      mainAccount,
      subAccount,
      poAmount,
      ExpenseDesc,
      // mainAcc,
      // subAccList,
      // code,
      // wrntyCode,
      // workOrderData,
      pieceEquipInst,
      // technicianList,
      technician,
      installerId,
      // purchaseDesc,
      poDateErr,
      mainAccountErr,
      subAccountErr,
      poAmountErr,
      WorkOrderErr,
      technicianErr,
      ExpenseDescErr,
      // supplyHouse,
      title,
      installerCost,
      po,
      workOrderId,
      roleId,
      isShowLoader,
      // workorder_technicians,
      workordername,
      clearSubByMain,
      clearTechByJobID,
      invoiceNumber,
      returnPoAmount,
      finalPoAmount,
      returnPO,
      returnInvoiceNumber,
      orderId,
      mainAccountName,
      subAccountName,
      technicianName,
      technicianFunction,
      imgBucket,
      showDelete,
      imagePreview,
      imagePreviewShow,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <SweetAlert
                title="Are you sure, you want to delete attachment?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.onDeleteHandler();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false });
                }}
              >
                You will not be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.handleSubmit} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-6 ">
                          <h6>PO Expenses</h6>
                        </div>
                        {/* <div className="col-sm-3">
                          
                            <button
                              onClick={this.onRegularExpense}
                              style={{
                                backgroundColor:
                                  this.state.expense_type === 1 ? 'black' : '',
                              }}
                              className="btn btn-outline-secondary"
                            >
                              Regular Expenses
                            </button>
                        
                        </div> */}
                        {/* <div className="col-sm-3">
                          <button
                            onClick={this.onPOExpense}
                            style={{
                              backgroundColor:
                                this.state.expense_type === 0 ? 'black' : '',
                            }}
                            className="btn btn-outline-secondary"
                          >
                            PO Expenses
                          </button>
                        </div> */}
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="po#"
                          className="col-sm-2 col-form-label"
                        >
                          PO #
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={po}
                            type="text"
                            id="po#"
                            onChange={(e) => {
                              this.setState({ po: e.target.value });
                            }}
                            readOnly
                          />
                        </div>
                        <label
                          htmlFor="podate"
                          className="col-sm-2 col-form-label"
                        >
                          PO Date
                        </label>
                        <div className="col-sm-4">
                          <DatePicker
                            className="form-control"
                            selected={poDate}
                            onChange={(e) => {
                              this.setState({ poDate: e });
                            }}
                            name="startDate"
                            dateFormat="MM/dd/yyyy"
                          />
                          <span id="err">{poDateErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="poamount"
                          className="col-sm-2 col-form-label"
                        >
                          Invoice Number
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <input
                              className="form-control"
                              value={invoiceNumber}
                              placeholder="Invoice Number"
                              type="text"
                              id="poamount"
                              onChange={(e) => {
                                this.setState({
                                  invoiceNumber: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="mainAccount"
                          className="col-sm-2 col-form-label"
                        >
                          Main Account<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <div className="d-flex">
                            <SelectAsyncPaginate
                              onSelectChange={(e) => {
                                this.setState({
                                  mainAccount: e ? e.ID : null,
                                  clearSubByMain: true,
                                  mainAccountName: e ? { name: e.name } : '',
                                });
                                if (e) {
                                  // this.getSubAccountData(e.ID,'');
                                }
                              }}
                              className="w-100"
                              isSearch={true}
                              isClearable={mainAccount}
                              value={mainAccountName}
                              apiname="listtype"
                              endpoint={Api.AddListTypeUrl + `?search=`}
                              selectPlaceholder="Select Main Account"
                            />

                            {/*  <select
                              className="form-control"
                              value={mainAccount}
                              onChange={(e) => {
                                this.setState({ mainAccount: e.target.value });
                                this.getSubAccountData(e.target.value);
                              }}
                            >
                              <option value="">Select Main Account </option>
                              {mainAcc.map((name) => (
                                <option
                                  value={name.ID}
                                >{`${name.ListType}`}</option>
                              ))}
                            </select>*/}
                            {(roleId !== 5 || roleId !== 6) && (
                              <div className="ml-3">
                                <button
                                  className="btn btn-light"
                                  data-toggle="modal"
                                  data-target="#mainaccount"
                                >
                                  <a
                                    href="/"
                                    className=""
                                    data-toggle="modal"
                                    data-target="#mainaccount"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Edit />
                                  </a>
                                </button>
                              </div>
                            )}
                          </div>

                          <span id="err">{mainAccountErr}</span>
                        </div>
                        <label
                          htmlFor="subAccount"
                          className="col-sm-2 col-form-label"
                        >
                          Sub Account<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <div className="d-flex">
                            <SelectAsyncPaginate
                              onSelectChange={(e) => {
                                this.setState({
                                  subAccount: e ? e.ID : null,
                                  clearSubByMain: false,
                                  subAccountName: e ? { name: e.name } : '',
                                });
                              }}
                              className="w-100"
                              isSearch={true}
                              isClearable={subAccount && !clearSubByMain}
                              value={subAccountName}
                              clearCacheOnID={mainAccount}
                              clearSelectedByParent={clearSubByMain}
                              apiname="listitem"
                              endpoint={
                                Api.ListItemByType +
                                `?type_id=${mainAccount}&search=`
                              }
                              selectPlaceholder="Select subaccount"
                            />
                            {/* <select
                              className="form-control"
                              value={subAccount}
                              onChange={(e) => {
                                this.setState({ subAccount: e.target.value });
                              }}
                            >
                              <option value="">Select subaccount</option>
                              {subAccList &&
                                subAccList.map((name) => (
                                  <option
                                    value={name.ID}
                                  >{`${name.ListItem}`}</option>
                                ))}
                            </select> */}

                            {(roleId !== 5 || roleId !== 6) && (
                              <div className="ml-3">
                                <button
                                  className="btn btn-light"
                                  data-toggle="modal"
                                  data-target="#subaccount"
                                >
                                  <a
                                    href="/"
                                    className=""
                                    data-toggle="modal"
                                    data-target="#subaccount"
                                    onClick={(e) => {
                                      e.preventDefault();
                                    }}
                                  >
                                    <Edit />
                                  </a>
                                </button>
                              </div>
                            )}
                          </div>
                          <span id="err">{subAccountErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="poamount"
                          className="col-sm-2 col-form-label"
                        >
                          PO Amount<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={poAmount}
                              placeholder="$0.00"
                              type="text"
                              id="poamount"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState(
                                    { poAmount: e.target.value },
                                    () => {
                                      this.calculateFinal();
                                    }
                                  );
                                }
                              }}
                            />
                          </div>
                          <span id="err">{poAmountErr}</span>
                        </div>
                        <label
                          htmlFor="installercost"
                          className="col-sm-2 col-form-label"
                        >
                          Installer Cost
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={installerCost}
                              placeholder="$0.00"
                              type="text"
                              id="installercost"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({
                                    installerCost: e.target.value,
                                  });
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="reinvoice"
                          className="col-sm-2 col-form-label"
                        >
                          Return Invoice Number
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <input
                              className="form-control"
                              value={returnInvoiceNumber}
                              placeholder="Return invoice number"
                              type="text"
                              id="reinvoice"
                              onChange={(e) => {
                                this.setState({
                                  returnInvoiceNumber: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="returnpoamount"
                          className="col-sm-2 col-form-label"
                        >
                          Return PO Amount
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={returnPoAmount}
                              placeholder="$0.00"
                              type="text"
                              id="returnpoamount"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState(
                                    { returnPoAmount: e.target.value },
                                    () => {
                                      this.calculateFinal();
                                    }
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="finalpoamount"
                          className="col-sm-2 col-form-label"
                        >
                          Final PO Amount
                        </label>
                        <div className="col-sm-4">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              disabled
                              className="form-control"
                              value={finalPoAmount}
                              placeholder="$0.00"
                              type="text"
                              id="finalpoamount"
                            />
                          </div>
                        </div>
                      </div>
                      {[1, 3, 6].includes(roleId) && (
                        <div className="form-group row">
                          <label
                            htmlFor="example-search-input"
                            className="col-sm-2 col-form-label"
                          >
                            Attachments
                          </label>
                          <div className="col-sm-4">
                            <div className="input-group">
                              <label
                                htmlFor="imageUpload"
                                className="photo-upload"
                              >
                                <input
                                  id="imageUpload"
                                  ref={(ref) => (this.fileInput = ref)}
                                  type="file"
                                  multiple
                                  className=""
                                  accept=".pdf,.doc,image/jpeg,image/png,"
                                  onChange={(e) => {
                                    this.handleImageFileUpload(e);
                                  }}
                                />
                              </label>
                            </div>
                            <div>
                              {imgBucket &&
                                imgBucket.map((props, index) => {
                                  let ext = props.url.substr(
                                    -3,
                                    props.url.lastIndexOf('.')
                                  );
                                  let image = null;
                                  image =
                                    ext === 'pdf' || ext === 'doc'
                                      ? ATTACHMENT
                                      : null;

                                  return (
                                    <span
                                      className="span-container"
                                      key={index}
                                      style={{ marginRight: '12px' }}
                                    >
                                      <img
                                        className="img-container my-10"
                                        src={image ? image : props.url}
                                        alt=""
                                        style={{
                                          height: '110px',
                                          width: '95px',
                                          marginBottom: '12px',
                                        }}
                                        onClick={() =>
                                          this.setState({
                                            imagePreviewShow: true,
                                            imagePreview: props.url,
                                          })
                                        }
                                        title={props.url}
                                      />
                                      <i
                                        className="icon-container mdi mdi-close"
                                        style={{
                                          position: 'absolute',
                                        }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.setState({
                                            index: index,
                                            showDelete: true,
                                          });
                                        }}
                                      />
                                    </span>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      )}
                      {orderId && (
                        <div className="form-group row">
                          <label
                            htmlFor="repo"
                            className="col-sm-2 col-form-label"
                          >
                            Return PO
                          </label>
                          <div className="col-sm-4">
                            <div className="input-group">
                              <input
                                className="form-control"
                                value={returnPO}
                                placeholder="Return PO"
                                type="text"
                                id="repo"
                                onChange={(e) => {
                                  this.setState({ returnPO: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          htmlFor="expensedesc"
                          className="col-sm-2 col-form-label"
                        >
                          Expense Description
                        </label>
                        <div className="col-10">
                          <textarea
                            className="form-control"
                            type="textarea"
                            style={{ resize: 'none' }}
                            placeholder="expense description goes here"
                            id="expensedesc"
                            value={ExpenseDesc}
                            rows="4"
                            cols="90"
                            onChange={(e) => {
                              this.setState({ ExpenseDesc: e.target.value });
                            }}
                          />
                          <span id="err">{ExpenseDescErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="workorder"
                          className="col-sm-2 col-form-label"
                        >
                          Work Order #
                        </label>
                        <div className="col-sm-4">
                          <SelectAsyncPaginate
                            onSelectChange={(e) => {
                              this.setState({
                                workOrderId: e ? e.ID : null,
                                clearTechByJobID: true,
                                workordername: e ? { name: e.WorkOrder } : '',
                              });
                              if (e) this.getWorkOrderDetailsById(e.ID, '');
                            }}
                            className="w-100"
                            isSearch={true}
                            isClearable={workordername}
                            value={workordername}
                            apiname="workorder"
                            endpoint={Api.getAhsWorkOrder + `?search=`}
                            selectPlaceholder="Select Order"
                          />

                          {/*  <select
                            className="form-control"
                            value={workOrderId}
                            disabled={
                              this.props.location.state &&
                              this.props.location.state.workOrderId
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              this.setState({
                                workOrderId: e.target.value,
                              });
                              this.getWorkOrderDetailsById(e.target.value);
                            }}
                          >
                            <option value="">Select Order</option>
                            {workOrderData &&
                              workOrderData.map((name) => (
                                <option
                                  value={name.ID}
                                >{`${name.WorkOrder}`}</option>
                              ))}
                          </select> */}
                          <span id="err">{WorkOrderErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="technician"
                          className="col-sm-2 col-form-label"
                        >
                          Technician
                        </label>
                        <div className="col-sm-4">
                          <SelectAsyncPaginate
                            onSelectChange={(e) => {
                              this.setState(
                                {
                                  technician: e ? e.tech_id : null,
                                  clearTechByJobID: false,
                                  technicianName: e ? { name: e.name } : '',
                                  technicianFunction: e ? e.tech_function : '',
                                  installerId: e ? e.tech_id : '',
                                },
                                () => {}
                              );
                            }}
                            className="w-100"
                            isSearch={true}
                            isClearable={technician && !clearTechByJobID}
                            value={technicianName}
                            clearCacheOnID={workOrderId}
                            clearSelectedByParent={clearTechByJobID}
                            apiname="job-installer"
                            endpoint={
                              Api.orderById + `?work_order_id=${workOrderId}`
                            }
                            selectPlaceholder="Select Technician"
                          />
                          {/* <select
                            className="form-control"
                            value={technician}
                            onChange={(e) => {
                              this.setState({ technician: e.target.value });
                            }}
                          >
                            <option value="">Select Technician</option>
                            {workorder_technicians
                              ? workorder_technicians.map((name) => (
                                  <option
                                    value={name.tech_id}
                                  >{`${name.techname}`}</option>
                                ))
                              : technicianList &&
                                technicianList.map((name) => (
                                  <option
                                    value={name.id}
                                  >{`${name.name}`}</option>
                                ))}
                          </select> */}
                          {/* <ReactSelect
                              components={this.components}
                              value={object.selectedOption}
                              isDisabled={
                                disabledForCust ||
                                (roleId == 5 &&
                                  object.isPrimary === 1) ||
                                object.isworked ||
                                (!scheduled && orderId)
                              }
                              placeholder="Select Technician"
                              onChange={(values) =>{
                                this.handleTechnicianChange(
                                  values,
                                  index
                                )}
                              }
                              options={techniciandata}
                            /> */}
                          <span id="err">{technicianErr}</span>
                        </div>
                        <label
                          htmlFor="pieceofequip"
                          className="col-sm-2 col-form-label"
                        >
                          Pieces Of Equip Install ?
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={pieceEquipInst}
                            type="text"
                            placeholder="pieces of equip install ?"
                            id="pieceofequ"
                            onChange={(e) => {
                              const re = /^[0-9\b]+$/;
                              if (
                                e.target.value === '' ||
                                re.test(e.target.value)
                              ) {
                                this.setState({
                                  pieceEquipInst: e.target.value,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                      {(technicianFunction === 'Installation' ||
                        installerId) && (
                        <div className="form-group row">
                          <label
                            htmlFor="installerid"
                            className="col-sm-2 col-form-label"
                          >
                            Installer Id
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              value={installerId}
                              type="text"
                              placeholder="installer id"
                              id="installerid"
                              onChange={(e) => {
                                this.setState({ installerId: e.target.value });
                              }}
                              disabled
                            />
                          </div>
                        </div>
                      )}
                      {/* <div className="form-group row">
                        <label
                          htmlFor="purchasedesc"
                          className="col-sm-2 col-form-label"
                        >
                          Description of Purchase
                        </label>
                        <div className="col-10">
                          <textarea
                            className="form-control"
                            type="textarea"
                            placeholder="purchase description goes here"
                            id="purchasedescription"
                            value={purchaseDesc}
                            rows="4"
                            cols="90"
                            onChange={(e) => {
                              this.setState({ purchaseDesc: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="supplyhouse"
                          className="col-sm-2 col-form-label"
                        >
                          Supply House
                        </label>
                        <div className="col-sm-10">
                          <input
                            className="form-control"
                            value={supplyHouse}
                            type="text"
                            placeholder="supply house"
                            id="supplyhouse"
                            onChange={(e) => {
                              this.setState({ supplyHouse: e.target.value });
                            }}
                          />
                        </div>
                      </div>
                      */}
                      <div className="form-group row"></div>
                      <br></br>
                      <div className="form-group row d-flex justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            onClick={this.validate}
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.flushAllData();
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditMainAccount
        // getMainAccountDataCallback={() => {
        //   this.getMainAccountData('callback');
        // }}
        // getSubAccountDataCallback={(id) => {
        //   this.getSubAccountData(id,'');
        // }}
        />
        <EditSubAccountData />
        {imagePreviewShow && imagePreview && (
          <ImagePreview
            imagePreviewShow={imagePreviewShow}
            imagePreview={imagePreview && imagePreview}
            handleClose={() => this.setState({ imagePreviewShow: false })}
          />
        )}
      </AUX>
    );
  }
}
export default withRouter(PurchaseOrder);
