import React, { forwardRef, useEffect, useState } from 'react';
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import customFunctions from '../../helper/customFunctions';

const LocationMap = withScriptjs(
  withGoogleMap(
    forwardRef((props, ref) => {
      const [directions, setDirections] = useState([]);
      const [selectedMarker, setSelectedMarker] = useState('');
      const [sameLatitude, setSameLatitude] = useState([]);
      const defaultProp = {
        defaultZoom: 12,
        defaultCenter: {
          lat: 18.689131,
          lng: 73.589462,
        },
        waypoint: true,
        onlyRoute: true,
        travelMode: 'driving',
        routePolylineOptions: {
          strokeColor: '#ff2343',
          strokeOpacity: 0.8,
          strokeWeight: 4,
          clickable: true,
        },
        routeMarkerOptions: {
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 2,
            strokeColor: '#fff',
            fillColor: '#ff2343',
            fillOpacity: 0.7,
            strokeWeight: 0.5, // eslint-disable-line
          },
          label: { color: 'white', text: ' ' },
        },
        InfoWindow: true,
        infowindowOptions: {},
        markerOptions: {
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 20,
            strokeColor: 'red',
            fillColor: '#fff',
            fillOpacity: 1,
            strokeWeight: 1,
          },
          label: { color: 'black', text: ' ' },
        },
        routeMarker: [],
        placeMarker: [],
        isMarkerShown: false,
        // renderPlaceWindow: () => {},
        // renderRouteWindow: () => { }
      };
      // let delayFactor = 0;
      const finalProps = { ...defaultProp, ...props };
      // const getDirections = async (
      //   startLoc,
      //   destinationLoc,
      //   wayPoints = []
      // ) => {
      //   return new Promise((resolve, reject) => {
      //     let waypts = [];
      //     if (wayPoints.length > 0) {
      //       waypts = wayPoints;
      //     }
      //     const DirectionsService = new window.google.maps.DirectionsService();
      //     DirectionsService.route(
      //       {
      //         origin: startLoc,
      //         destination: destinationLoc,
      //         optimizeWaypoints: true,
      //         travelMode:
      //           finalProps.travelMode.toUpperCase() ||
      //           window.google.maps.TravelMode.DRIVING,
      //         waypoints: finalProps.waypoint ? waypts : [],
      //       },
      //       (result, status) => {
      //         resolve({ status, result, wayPoints });
      //       }
      //     );
      //   });
      // };

      // const getDirectionsPromise = (startLoc, destinationLoc, wayPoints) =>
      //   getDirections(startLoc, destinationLoc, wayPoints).then((response) => {
      //     if (response.status === window.google.maps.DirectionsStatus.OK) {
      //       this.setState({
      //         directions: response.result,
      //       });
      //     } else if (
      //       response.status ===
      //       window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT
      //     ) {
      //       delayFactor += 0.2;
      //       if (delayFactor === 15) {
      //         delayFactor = 0.2;
      //       }
      //       setTimeout(() => {
      //         getDirectionsPromise(
      //           startLoc,
      //           destinationLoc,
      //           response.wayPoints
      //         );
      //       }, delayFactor * 200);
      //     } else {
      //       console.error(`error fetching directions ${response.result}`);
      //     }
      //   });

      const setDirectionsRoute = (places = []) => {
        let directionData = [];
        const directionsService = new window.google.maps.DirectionsService();
        for (let item of places) {
          const waypoints = item.map((p) => ({
            location: { lat: Number(p.latitude), lng: Number(p.longitude) },
            stopover: true,
          }));
          const origin =
            waypoints.length > 1
              ? waypoints.shift().location
              : waypoints[0].location;
          const destination =
            waypoints.length > 1
              ? waypoints.pop().location
              : waypoints[0].location;

          //this.getDirections(origin, destination, waypoints);
          directionsService.route(
            {
              origin: origin,
              destination: destination,
              optimizeWaypoints: true,
              travelMode:
                finalProps.travelMode.toUpperCase() ||
                window.google.maps.TravelMode.DRIVING,
              waypoints: finalProps.waypoint ? waypoints : [],
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                // var path = window.google.maps.geometry.encoding.decodePath(
                //     result.routes[0].overview_polyline,
                // );
                // directionData.push({ color: item[0].color || 'red', route: path });
                directionData.push({
                  color: item[0].color || 'red',
                  route: result,
                });
                setDirections(JSON.parse(JSON.stringify(directionData)));
              } else {
                console.error(result);
              }
            }
          );
        }
      };

      useEffect(() => {
        if (props.places && props.places.length > 0) {
          setDirectionsRoute(props.places);
        } else {
          setDirections([]);
        }
      }, [props.places]);

      const overrideMarkerColor = (option, color) => {
        let ops = option;
        if (Object.keys(ops).length < 1) return ops;

        ops.icon.fillColor = color ? color : ops.icon.fillColor;
        return ops;
      };

      const onToggleOpen = (e, lat = '', lan = '') => {
        if (!e && sameLatitude && sameLatitude.length) {
          var popArray = [...sameLatitude];
          popArray.pop();
          setSameLatitude(popArray);
        }
        try {
          var mulLatitude = [];
          for (let location of finalProps.jobPlaceMarker) {
            if (location.latitude === lat && location.longitude === lan) {
              mulLatitude.push(lat);
              if (mulLatitude && mulLatitude.length > 0) {
                setSameLatitude(mulLatitude);
              }
            }
          }
          setSelectedMarker(e);
        } catch (err) {
          console.log(err);
        }
      };

      const RenderPlaceWindow = ({ data }) => {
        try {
          let html = finalProps.renderPlaceWindow ? (
            finalProps.renderPlaceWindow(data)
          ) : (
            <p>{data.name}</p>
          );
          // return <div dangerouslySetInnerHTML={{ __html: html }} />
          return html;
        } catch (e) {
          return <p>{data.name}</p>;
        }
      };

      const RenderRouteWindow = ({ data }) => {
        try {
          let html = finalProps.renderRouteWindow ? (
            finalProps.renderRouteWindow(data)
          ) : (
            <p>{data.name}</p>
          );
          // html =  <div dangerouslySetInnerHTML={{ __html: html }} />
          return html;
        } catch (e) {
          return <p>{data.name}</p>;
        }
      };

      const getMarkerOption = (dest, src, item) => {
        let pt = '';
        if (!src) return dest;

        dest.label = { ...dest.label, ...src.label };
        pt = src.icon && src.icon.path ? src.icon.path.toUpperCase() : 'CIRCLE';
        src.icon.path = window.google.maps.SymbolPath[pt];
        dest.icon = { ...dest.icon, ...src.icon };
        // dest.icon = item && item.image ? item.image : { ...dest.icon, ...src.icon };
        return dest;
      };

      const getRouteMarkerOption = (dest, src) => {
        let pt = '';
        if (!src) return dest;

        dest.label = { ...dest.label, ...src.label };
        pt = src.icon && src.icon.path ? src.icon.path.toUpperCase() : 'CIRCLE';
        src.icon.path = window.google.maps.SymbolPath[pt];
        dest.icon = { ...dest.icon, ...src.icon };
        return dest;
      };
      return (
        <div className="map-wrapper">
          <GoogleMap
            id="map"
            ref={ref}
            defaultZoom={finalProps.defaultZoom}
            center={finalProps.center}
            defaultCenter={finalProps.defaultCenter}
          >
            {directions &&
              directions.length > 0 &&
              directions.map((item, i) => (
                <DirectionsRenderer
                  key={i}
                  directions={item.route}
                  options={{
                    polylineOptions: {
                      ...finalProps.routePolylineOptions,
                      strokeColor: item.color,
                    },
                    suppressMarkers: finalProps.onlyRoute,
                    preserveViewport: true,
                    markerOptions: overrideMarkerColor(
                      finalProps.routeMarkerOptions,
                      item.color
                    ),
                  }}
                />
              ))}

            {finalProps.isMarkerShown &&
              finalProps.routeMarker &&
              finalProps.routeMarker.map((item, i) => (
                <Marker
                  key={i}
                  label={item.label || ''}
                  name={item.name}
                  title={item.name}
                  position={{
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                  }}
                  onClick={() => onToggleOpen(item.name)}
                  {...getRouteMarkerOption(
                    finalProps.routeMarkerOptions,
                    item.option
                  )}
                  // {...{ ...finalProps.routeMarkerOptions, ...item.option }}
                >
                  {/* {finalProps.InfoWindow && selectedMarker === item.name && (
                    console.log('finalProps.infowindowOptions', finalProps, finalProps.infowindowOptions),
                    <InfoWindow
                      onCloseClick={() => onToggleOpen('')}
                      {...finalProps.infowindowOptions}
                    >
                      <div className="info-window">
                        <RenderRouteWindow data={item} />
                      </div>
                    </InfoWindow>
                  )} */}
                </Marker>
              ))}
            {finalProps.isMarkerShown &&
              finalProps.placeMarker &&
              finalProps.placeMarker.map((item, i) => (
                <Marker
                  key={i}
                  label={item.label || ''}
                  name={item.name}
                  title={item.name}
                  position={{
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                  }}
                  // icon={{ ...finalProps.routeMarkerOptions.icon, ...(item.option && item.option.icon || null) }}
                  onClick={() => onToggleOpen(item.name)}
                  {...getMarkerOption(
                    finalProps.markerOptions,
                    item.option,
                    item
                  )}
                  // {...{ ...finalProps.markerOptions, ...item.option }}
                >
                  {finalProps.InfoWindow && selectedMarker === item.name && (
                    <InfoWindow
                      onCloseClick={() => onToggleOpen('')}
                      {...finalProps.infowindowOptions}
                    >
                      <div className="info-window">
                        <RenderPlaceWindow data={item} />
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}

            {finalProps.isMarkerShown &&
              finalProps.jobPlaceMarker &&
              finalProps.jobPlaceMarker.map((item, i) => (
                <Marker
                  key={`job` + i}
                  label={item.label || ''}
                  name={item.name}
                  title={item.WorkOrder}
                  position={{
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                  }}
                  icon={customFunctions.workorderimage(item.WorkStatus)}
                  onClick={() => {
                    onToggleOpen(item.WorkOrder, item.latitude, item.longitude);
                  }}
                >
                  {finalProps.InfoWindow &&
                    (selectedMarker === item.WorkOrder ||
                      sameLatitude.includes(item.latitude)) && (
                      <InfoWindow
                        onCloseClick={async () => {
                          await onToggleOpen('');
                          await setSelectedMarker('');
                        }}
                        {...finalProps.infowindowOptions}
                      >
                        <div className="info-window">
                          <RenderPlaceWindow data={item} />
                        </div>
                      </InfoWindow>
                    )}
                </Marker>
              ))}
            {finalProps.children && finalProps.children}
          </GoogleMap>
        </div>
      );
    })
  )
);

export default LocationMap;
