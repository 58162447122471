export const SetTechnician = (technician) => {
  return {
    type: 'SETTECHNICIAN',
    technician,
  };
};
export const SetVendor = (vendorid) => {
  return {
    type: 'SETVENDOR',
    vendorid,
  };
};

export const SetWarrantyCode = (warantyCode) => {
  return {
    type: 'SETWARRANTYCODE',
    warantyCode,
  };
};

export const SetCustomer = (customer) => {
  return {
    type: 'SETCUSTOMER',
    customer,
  };
};

export const SetOrderType = (orderType) => {
  return {
    type: 'SETORDERTYPE',
    orderType,
  };
};

export const SetPaymentType = (paymentType) => {
  return {
    type: 'SETPAYMENTYPE',
    paymentType,
  };
};

export const SetWorkComplete = (WorkCompleteValue) => {
  return {
    type: 'SETWORKCOMPLETE',
    WorkCompleteValue,
  };
};

export const SetFromDate = (fromDate) => {
  return {
    type: 'SETFROMDATE',
    fromDate,
  };
};

export const SetToDate = (toDate) => {
  return {
    type: 'SETODATE',
    toDate,
  };
};

export const SetMonth = (month) => {
  return {
    type: 'SETMONTH',
    month,
  };
};

export const SetMonthYear = (monthyear) => {
  return {
    type: 'SETMONTHYEAR',
    monthyear,
  };
};

export const SetYear = (year) => {
  return {
    type: 'SETYEAR',
    year,
  };
};

export const SetComplaintTechnician = (complaintTechnician) => {
  return {
    type: 'SETCOMPLAINTECHNICIAN',
    complaintTechnician,
  };
};

export const SetComplaintWarrantyCode = (complaintWarantyCode) => {
  return {
    type: 'SETCOMPLAINTWRNTYCODE',
    complaintWarantyCode,
  };
};

export const SetComplaintCustomer = (complaintCustomer) => {
  return {
    type: 'SETCOMPLAINTCUSTOMER',
    complaintCustomer,
  };
};

export const SetComplaintCategory = (complaintCategory) => {
  return {
    type: 'SETCOMPLAINTCATEGORY',
    complaintCategory,
  };
};

export const SetComplaintStatus = (complaintStatus) => {
  return {
    type: 'SETCOMPLAINTSTATUS',
    complaintStatus,
  };
};

export const SetComplaintFromDate = (complaintFromDate) => {
  return {
    type: 'SETCOMPLAINTFROMDATE',
    complaintFromDate,
  };
};

export const SetComplaintToDate = (complaintToDate) => {
  return {
    type: 'SETCOMPLAINTODATE',
    complaintToDate,
  };
};

export const SetComplaintMonth = (complaintMonth) => {
  return {
    type: 'SETCOMPLAINTMONTH',
    complaintMonth,
  };
};

export const SetComplaintMonthYear = (complaintMonthYear) => {
  return {
    type: 'SETCOMPLAINTMONTHYEAR',
    complaintMonthYear,
  };
};

export const SetComplaintYear = (complaintYear) => {
  return {
    type: 'SETCOMPLAINTYEAR',
    complaintYear,
  };
};

export const SetFilterType = (filtertype) => {
  return {
    type: 'SETFILTERTYPE',
    filtertype,
  };
};
export const SetWeekType = (weektype) => {
  return {
    type: 'SETWEEKTYPE',
    weektype,
  };
};
export const SetToggle = (toggle) => {
  return {
    type: 'SETTOGGLE',
    toggle,
  };
};
export const SetUserData = (userdata) => {
  localStorage.removeItem('userdata');
  localStorage.setItem('userdata', JSON.stringify(userdata));
  return {
    type: 'SETUSERDATA',
    userdata,
  };
};
export const SetSearchType = (searchType) => {
  return {
    type: 'SETSEARCHTYPE',
    searchType,
  };
};
export const SetNotificationToggle = (noteToggle) => {
  return {
    type: 'SETNOTIFICATIONTOGGLE',
    noteToggle,
  };
};
export const SetNotificationCount = (count) => {
  return {
    type: 'SETNOTIFICATIONCOUNT',
    count,
  };
};
export const SetChatNotificationCount = (chatCount) => {
  return {
    type: 'SETCHATNOTIFICATIONCOUNT',
    chatCount,
  };
};
export const SetChatBoxToggle = (chatBoxToggle) => {
  return {
    type: 'SETCHATTOGGLE',
    chatBoxToggle,
  };
};
export const SetMessageBoxToggle = (messageBoxToggle) => {
  return {
    type: 'SETMESSAGETOGGLE',
    messageBoxToggle,
  };
};
// export const SetChatTechDetails = (techdata) => {
//   return {
//     type: 'SETCHATTECH',
//     techdata,
//   };
// };
// export const SetChatCustDetails = (custdata) => {
//   return {
//     type: 'SETCHATCUST',
//     custdata,
//   };
// };
export const SetChatRecieverData = (recieverData) => {
  return {
    type: 'SETCHATRECIEVERDATA',
    recieverData,
  };
};
export const SetMessageRecieverData = (messageRecieverData) => {
  return {
    type: 'SETMESSAGERECIEVERDATA',
    messageRecieverData,
  };
};
export const SetCalenderDate = (calDate) => {
  return {
    type: 'SETCALENDARDATE',
    calDate,
  };
};
export const SetNotification = (notification) => {
  return {
    type: 'SETNOTIFICATION',
    notification,
  };
};
