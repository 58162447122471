import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import HelmetComponent from '../Title/index';
import AUX from '../../../hoc/Aux_';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import Filter from '../Workorder/AddFilter';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import { Modal, Button } from 'react-bootstrap';

import {
  SetTechnician,
  SetWarrantyCode,
  SetCustomer,
  SetOrderType,
  SetPaymentType,
  SetWorkComplete,
  SetFromDate,
  SetToDate,
  SetMonth,
  SetMonthYear,
  SetYear,
  SetSearchType,
  SetFilterType,
} from '../../../action/user.action';
import Loader from '../../../components/Loader';

class Paysheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      techniciandata: [],
      paysheetData: [],
      rows: [],
      columns: [],
      showFilter: false,
      page: 1,
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      isWriteAllowed: false,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      isShowLoader: false,
      popupOpen: false,
      popupClose: false,
      transactionId: '',
      manualtid: '',
      cancelPaysheet: false,
      cancelPaysheetData: {
        commission: null,
        transactionid: null,
        technicianid: null,
        objectstoverify: [],
        is_technician: null,
      },
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Paysheet');

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id, login_id: userdata.login_id });
    const columns = [
      {
        label: 'Sr. No.',
        field: 'id',
        width: 70,
      },
      {
        label: 'Paysheet #',
        field: 'paysheet',
        width: 180,
      },
      {
        label: 'Technician',
        field: 'technician',
        width: 150,
      },
      {
        label: 'Amount',
        field: 'amount',
        width: 150,
      },
      {
        label: 'Paid Amount',
        field: 'paidamount',
        width: 150,
      },
      // {
      //   label: 'Pending Amount',
      //   field: 'remainingamount',
      //   width: 150,
      // },
      {
        label: 'Pay Period',
        field: 'payperiod',
        width: 180,
      },
      {
        label: 'Is Paid',
        field: 'ispaid',
        width: 150,
      },
      {
        label: 'Approved Status',
        field: 'approvedstatus',
        width: 180,
      },
      {
        label: 'Payment Proof',
        field: 'paymentproof',
        width: 180,
      },
      {
        label: 'Action',
        field: 'action',
        width: 150,
      },
    ];
    if (userdata.role_id === 5 || userdata.role_id === 6) {
      delete columns[1];
    }
    if (userdata.role_id !== 1) {
      delete columns[7];
    }
    this.setState({ columns });

    await this.getTechnician();
    await this.getPaysheet();
  };

  getTechnician = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.technicianUrl}?skip=0&limit=10000`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        isShowLoader: false,
        techniciandata: data.data.data,
      });
    } else {
      this.setState({ isShowLoader: false });
    }
  };

  getPaysheet = async (currentPage) => {
    this.setState({ currentPage, selectedWorkOrderId: [], isShowLoader: true });
    const { pagination, limit, roleId, login_id, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.technicianTransaction}`;
    const params = {
      skip: skip,
      limit: pagination.itemsPerPage,
      search: keyword,
    };
    if (
      this.props.technicianId &&
      this.props.fromComponent === 'viewTechnician'
    ) {
      params.technicianid = this.props.technicianId;
    }
    if ((roleId === 5 || roleId === 6) && login_id) {
      params.technicianid = login_id;
      params.role = roleId;
    }
    if (roleId === 3 && login_id) {
      params.technicianid = login_id;
      params.role = roleId;
    }
    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.success !== false) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          paysheetData: data.data.data,
          pagination: newPagination,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  onFilterSearch = async (limits = '') => {
    this.setState({ showBulkAction: true });
    const {
      technician,
      warantyCode,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
    } = this.props;
    if (limits === 'clear') {
      this.setState({ limit: 10, currentPage: 1 });
      var { keyword, pagination } = this.state;
      var limit = 10;
      var currentPage = 1;
    } else {
      var { keyword, pagination, currentPage, limit } = this.state;
    }
    const page = currentPage || 1;
    const skip = page * limit - limit;

    const params = {
      skip: skip,
      limit: pagination.itemsPerPage,
      search: keyword,
    };

    var endPoint = `${Api.technicianTransaction}?skip=${params.skip}&limit=${params.limit}&`;
    var str = '';
    str += technician ? `&technician=${technician}` : '';
    str += customer ? `&customer=${customer}` : '';
    str += warantyCode ? `&home_code=${warantyCode}` : '';
    str += orderType ? `&order_type=${orderType}` : '';
    str += paymentType ? `&payment_type=${paymentType}` : '';
    str += WorkCompleteValue ? `&work_complete=${WorkCompleteValue}` : '';
    str += fromDate ? `&from_date=${fromDate}` : '';
    str += toDate ? `&to_date=${toDate}` : '';
    str += month ? `&month=${monthyear}-${month}` : '';
    str += year ? `&year=${year}` : '';
    str = str.substring(1);
    const { data } = await AuthApi.getDataFromServer(endPoint + str);
    if (data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          paysheetData: data.data.data,
          totalCount: data.data.total_count,
          pagination: newPagination,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
  };
  getTechName = (id) => {
    const { techniciandata } = this.state;
    let techdata = techniciandata.filter((i) => i.id === id);
    return techdata && techdata.length > 0 && techdata[0].name;
  };
  setTableData = () => {
    const { paysheetData, limit, currentPage, roleId } = this.state;
    const page = currentPage || 1;
    const rows = [];
    paysheetData.map((paysheet, i) => {
      rows.push({
        id: (page - 1) * limit + (i + 1),
        technician: this.getTechName(paysheet.technicianid),
        amount: paysheet.amount || 0,
        paidamount: paysheet.manualtid
          ? paysheet.finalpay
          : paysheet.paidamount || 0,
        remainingamount: paysheet.remainingamount || 0,
        ispaid: paysheet.ispaid,
        approvedstatus: paysheet.approvedstatus,
        notefromtechnician: paysheet.notefromtechnician || '-',
        paysheet: paysheet.paysheet_no || '-',
        payperiod: paysheet.paysheet_period || '-',
        action: (
          <React.Fragment>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(`/techpay`, {
                  techpay: paysheet,
                });
              }}
            >
              <i className="mdi mdi-eye" title="View paysheet"></i>
            </a>
            {paysheet.ispaid === 'unpaid' && (
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const newPaysheet = {
                    commission: paysheet.commission && paysheet.commission,
                    transactionid:
                      paysheet.transactionid && paysheet.transactionid,
                    technicianid:
                      paysheet.technicianid && paysheet.technicianid,
                    objectstoverify:
                      paysheet.objectstoverify && paysheet.objectstoverify,
                    is_technician:
                      paysheet.is_technician && paysheet.is_technician,
                  };
                  this.setState({
                    cancelPaysheet: true,
                    cancelPaysheetData: newPaysheet,
                  });
                }}
                className="text-danger m-l-10"
              >
                <i className="mdi mdi-close" title="Cancel paysheet"></i>
              </a>
            )}
          </React.Fragment>
        ),
        paymentproof:
          !paysheet.manualtid && roleId === 1 ? (
            <React.Fragment>
              {paysheet.approvedstatus === 'approvedbytechnician' && (
                <button
                  onClick={() => {
                    this.setState({
                      popupOpen: true,
                      transactionId: paysheet.transactionid,
                    });
                  }}
                  name="paid"
                  className="btn btn-primary waves-effect m-l-10 paid-btn align-items-center"
                >
                  Paid
                </button>
              )}
            </React.Fragment>
          ) : (
            paysheet.manualtid
          ),
      });
      return paysheet;
    });
    this.setState({ rows });
    this.forceUpdate();
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getPaysheet();
  };

  clearSearch = () => {
    this.onCancelBtn();
    this.setState({ keyword: '' }, () => {
      this.getPaysheet();
    });
  };

  onAdvancedSearch = async () => {
    await this.setState({ showFilter: !this.state.showFilter });
  };

  onCancelBtn = () => {
    this.setState({ showFilter: false, showBulkAction: false });
    const {
      SetTechnician,
      SetWarrantyCode,
      SetCustomer,
      SetOrderType,
      SetPaymentType,
      SetWorkComplete,
      SetFromDate,
      SetToDate,
      SetMonth,
      SetMonthYear,
      SetYear,
      SetFilterType,
    } = this.props;

    SetTechnician('');
    SetWarrantyCode('');
    SetCustomer('');
    SetOrderType('');
    SetPaymentType('');
    SetWorkComplete('');
    SetFromDate('');
    SetToDate('');
    SetMonth('');
    SetMonthYear('');
    SetYear('');
    SetFilterType('');
    this.getPaysheet();
  };

  submitTransactionId = async () => {
    this.setState({ isShowLoader: true });
    const { manualtid, transactionId } = this.state;
    let endPoint = `${Api.updateTransactionID}`;
    const callback = AuthApi.putDataToServer;

    const postData = {
      manualtid: manualtid,
      transactionid: transactionId,
    };
    const payload = customFunctions.cleanObject(postData);
    const { data, message } = await callback(endPoint, payload);

    if (data && data.success !== false) {
      this.setState(
        {
          popupOpen: false,
          isShowLoader: false,
        },
        () => {
          this.getPaysheet();
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  deletePaysheet = async () => {
    this.setState({ cancelPaysheet: false });
    const { cancelPaysheetData } = this.state;
    let endPoint = `${Api.cancelPaysheetUrl}`;
    const callback = AuthApi.putDataToServer;

    const payload = customFunctions.cleanObject(cancelPaysheetData);
    payload.is_technician = 1;
    const { data, message } = await callback(endPoint, payload);
    if (data) {
      this.getPaysheet();
      swal('Paysheet cancelled successfully!', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  downloadCSV = async () => {
    const { keyword, roleId, login_id } = this.state;
    let endPoint = `${Api.technicianTransaction}/paysheetCsv`;

    const params = {
      skip: 0,
      limit: 10000,
      search: keyword,
    };
    if (
      this.props.technicianId &&
      this.props.fromComponent === 'viewTechnician'
    ) {
      params.technicianid = this.props.technicianId;
    }
    if ((roleId === 5 || roleId === 6) && login_id) {
      params.technicianid = login_id;
      params.role = roleId;
    }
    if (roleId === 3 && login_id) {
      params.technicianid = login_id;
      params.role = roleId;
    }
    const url = customFunctions.generateUrl(endPoint, params);

    window.open(url, '_blank');
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      showFilter,
      columns,
      isShowLoader,
      popupOpen,
      cancelPaysheet,
    } = this.state;

    return (
      <AUX>
        <div
          className="page-content-wrapper"
          style={{
            'padding-top':
              this.props.fromComponent === 'viewTechnician' && '0px',
          }}
        >
          <div className="container-fluid">
            <HelmetComponent title="Payroll" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {cancelPaysheet && (
              <SweetAlert
                title="Are you sure, do you want to cancel paysheet ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deletePaysheet();
                }}
                onCancel={() => {
                  this.setState({ cancelPaysheet: false });
                }}
              ></SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="form-group row">
                      {this.props.fromComponent !== 'viewTechnician' && (
                        <div className="col-sm-7">
                          <form onSubmit={this.onSearchSubmit}>
                            <div className="row">
                              <div className="col-sm-6">
                                <input
                                  className="form-control"
                                  value={keyword}
                                  type="text"
                                  placeholder="Search by paysheet,technician"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    this.setState({ keyword: e.target.value });
                                  }}
                                />
                              </div>
                              <div className="col-sm-3">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light btn-block"
                                >
                                  Search
                                </button>
                              </div>
                              <div className="col-sm-3">
                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.clearSearch();
                                  }}
                                  className="btn btn-secondary waves-effect m-l-10"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      )}
                      {/* <div className="col-sm-5">
                        <button
                          type="reset"
                          onClick={async () => {
                            if (searchType !== 'paysheet') {
                              await this.onCancelBtn();
                              await SetSearchType('paysheet');
                            }
                            this.onAdvancedSearch();
                          }}
                          className="btn btn-primary waves-effect m-l-10"
                        >
                          Advance Search
                        </button>
                      </div> */}
                      {totalCount > 0 && (
                        <div
                          className={`${
                            this.props.fromComponent !== 'viewTechnician'
                              ? 'col-sm-5'
                              : 'col-sm-12'
                          }  text-right`}
                        >
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        </div>
                      )}
                    </div>
                    {showFilter && (
                      <Filter
                        onCancelButtonCallback={() => {
                          this.onCancelBtn();
                        }}
                        onFilterSearch={() => this.onFilterSearch('clear')}
                        fromComponent="payroll"
                      />
                    )}

                    <div ref={this.anyRef} className="mdbtable">
                      <MDBDataTable
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getPaysheet}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={!popupOpen}
          show={popupOpen}
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">Transaction ID</Modal.Header>
            <Modal.Body style={{ maxHeight: '350px', overflowY: 'scroll' }}>
              <input
                className="form-control"
                name="manualtid"
                value={this.state.manualtid}
                onChange={(e) => this.setState({ manualtid: e.target.value })}
              />
            </Modal.Body>
            <Modal.Footer id="md-filder" className="w-100">
              <div className="float-right">
                <Button
                  variant="primary"
                  className="saveBtns l-m-10"
                  onClick={() => this.submitTransactionId()}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  className="saveBtns ml-2"
                  onClick={() => {
                    this.setState({ popupOpen: false });
                  }}
                >
                  Back
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => ({
  technician: state.auth.technician,
  warantyCode: state.auth.warantyCode,
  customer: state.auth.customer,
  orderType: state.auth.orderType,
  paymentType: state.auth.paymentType,
  WorkCompleteValue: state.auth.WorkCompleteValue,
  fromDate: state.auth.fromDate,
  toDate: state.auth.toDate,
  month: state.auth.month,
  monthyear: state.auth.monthyear,
  year: state.auth.year,
  searchType: state.auth.searchType,
});

const mapDispatchToProps = (dispatch) => ({
  SetTechnician: (technician) => dispatch(SetTechnician(technician)),
  SetWarrantyCode: (warantyCode) => dispatch(SetWarrantyCode(warantyCode)),
  SetCustomer: (customer) => dispatch(SetCustomer(customer)),
  SetOrderType: (orderType) => dispatch(SetOrderType(orderType)),
  SetPaymentType: (paymentType) => dispatch(SetPaymentType(paymentType)),
  SetWorkComplete: (WorkCompleteValue) =>
    dispatch(SetWorkComplete(WorkCompleteValue)),
  SetFromDate: (fromDate) => dispatch(SetFromDate(fromDate)),
  SetToDate: (toDate) => dispatch(SetToDate(toDate)),
  SetMonth: (month) => dispatch(SetMonth(month)),
  SetMonthYear: (monthyear) => dispatch(SetMonthYear(monthyear)),
  SetYear: (year) => dispatch(SetYear(year)),
  SetSearchType: (searchType) => dispatch(SetSearchType(searchType)),
  SetFilterType: (filtertype) => dispatch(SetFilterType(filtertype)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Paysheet)
);
