import { setHours, setMinutes } from 'date-fns';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-datepicker/dist/react-datepicker.css';
import swal from 'sweetalert';
import { connect } from 'react-redux';
import Loader from '../../../components/Loader';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import CustomerMessagePopup from '../Workorder/CustomerMessagePopup';
import ACKNOWLEDGE from '../../../assets/images/approve.svg';
import ARCHIVE from '../../../assets/images/archive.svg';
import CANCEL from '../../../assets/images/cancel.svg';
import COMPLETE from '../../../assets/images/complete.svg';
import COMPLETED from '../../../assets/images/completed.svg';
import OFFERED from '../../../assets/images/email.svg';
import PAUSED from '../../../assets/images/pause.svg';
import INPROGRESS from '../../../assets/images/play.svg';
import SCHEDULE from '../../../assets/images/schedule.svg';
import SCHEDULED from '../../../assets/images/success.svg';
import UNSCHEDULED from '../../../assets/images/unscheduled.svg';
import CANCELLED from '../../../assets/images/cancelled.svg';
import MESSAGE from '../../../assets/images/message.svg';

import CompleteJobPopup from '../Workorder/completeJobPopup';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';
import SelectStaticDropdown from '../../../components/ReactSelectPagination/SelectStaticDropdown';

import {
  SetMessageRecieverData,
  SetMessageBoxToggle
} from '../../../action/user.action';
import MessageBox from '../../../components/Layout/messageBox.component';


let timeRange = [
  { name: '30min', value: 30 },
  { name: '1hr', value: 60 },
  { name: '1hr 30min', value: 90 },
  { name: '2hr', value: 120 },
  { name: '2hr 30min', value: 150 },
  { name: '3hr', value: 180 },
  { name: '3hr 30min', value: 210 },
  { name: '4hr', value: 240 },
  { name: '4hr 30min', value: 270 },
  { name: '5hr', value: 300 },
  { name: '5hr 30min', value: 330 },
  { name: '6hr', value: 360 },
  { name: '6hr 30min', value: 390 },
  { name: '7hr', value: 420 },
  { name: '7hr 30min', value: 450 },
  { name: '8hr', value: 480 },
  { name: '8hr 30min', value: 510 },
  { name: '9hr', value: 540 },
  { name: '9hr 30min', value: 570 },
];
class WorkOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workOrderCode: '',
      workOrderType: '',
      workOrderDate: new Date(),
      selectedCustomerName: '',
      selectedCustomerNameErr: '',
      wrntyCode: '',
      wrntyErr: '',
      cntctPrsn: '',
      tech: null,
      technicianErr: [],
      jobtitle: '',
      jobDesc: '',
      repairDesc: '',
      customerId: '',
      custmrAddress: '',
      custmrAddressErr: '',
      workCompleteValue: '',
      workcompleteValueErr: '',
      completionDate: '',
      completionDateErr: '',
      paymentAmount: '',
      paymentAmountErr: '',
      ApptDate: '',
      ApptDateErr: '',
      installerId: '',
      installerIdErr: '',
      paymentDate: null,
      workOrderTypeErr: '',
      workOrderDateErr: '',
      technicianId: null,
      wrntyCompany: '',
      wrntyCompanyName: '',
      wrntyCompErr: '',
      jobTotal: '',
      jobTotalErr: '',
      paymentTotal: '',
      paymentTotalErr: '',
      techDeduction: '',
      createdDateTime: '',
      updatedTime: '',
      apptFromTimeErr: '',
      apptToTimeErr: '',
      updatedApptDate: '',
      editType: false,
      updatedCompletionDate: '',
      noteForReason: '',
      ToTime: '',
      FromTime: '',
      title: 'Add Work Order',
      skip: 0,
      limit: 1000,
      page: 1,
      startHours: '',
      startMinutes: '',
      selectedOption: [],
      techFunction: [],
      addToggle: false,
      selecteFunctiondOption: [],
      technicianFunctionErr: [],
      technicianObject: [
        {
          tech_id: '',
          tech_name: '',
          technicianFunction: '',
          selectedToggle: true,
          isPrimary: 0,
          userid: '',
        },
      ],
      disabledForCust: false,
      disabledAll: false,
      isShowLoader: false,
      WorkStatus: '',
      Id: null,
      vendorid: null,
      vendorName: '',
      rangeMinutes: { name: '30min', value: 30 },
      authAmount: null,
      authNumber: null,
      scheduled: false,
      customerMsgBox: false,
      created: null,
      updated: null,
      workCompleteJob: false,
      custId: null,
      showDelete: false,
      swalParams: null,
      confirmType: '',
      techId: null,
      originalTechniciandata: [],
      techniciandata: [],
      minimunDate: new Date(),
      isWorkOrderCompletedHistory: false,
      chatBoxToggle: this.props.chatBoxToggle,
      custdata: {
        userid: null,
        roleid: null,
        image: '',
        name: '',
      },
      status: '',
    };
    this.scheduleRef = React.createRef();
  }

  // addTechnicianObject = (id) => {
  //   const { technicianObject } = this.state;
  //   technicianObject.push({
  //     technician: '',
  //     technicianFunction: '',
  //     selectedToggle: true,
  //     isPrimary: 0,
  //   });
  //   this.setState({ technicianObject });
  // };

  addTechnicianObject = (id) => {
    const { technicianObject, originalTechniciandata } = this.state;
    technicianObject.push({
      tech_id: '',
      tech_name: '',
      technicianFunction: '',
      selectedToggle: true,
      isPrimary: 0,
      userid: '',
    });
    this.setState({ technicianObject }, () => {
      let techarray = [];
      originalTechniciandata &&
        originalTechniciandata.map((tech) => {
          const result = technicianObject.filter(
            (techObject) => techObject.tech_id === tech.id
          );
          if (result && result.length > 0) {
            return false;
          } else {
            techarray.push(tech);
          }
          return false;
        });
      this.setState({ techniciandata: techarray });
    });
  };

  // removeTechnicianObject = (id) => {
  //   const { technicianObject } = this.state;
  //   technicianObject.splice(id, 1);
  //   this.setState({ technicianObject });
  // };

  removeTechnicianObject = (id) => {
    const { technicianObject } = this.state;
    technicianObject.splice(id, 1);

    this.setState({ ...technicianObject }, () => {
      this.forceUpdate();
      this.getInstallerId();
    });
  };

  componentDidMount = async () => {
    // let isAllowed = await customFunctions.isAllowedRoute('submodule', 'addnewrecord-addworkorder');
    // if(!isAllowed){
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    // let writeAllowed = await customFunctions.isAllowedRoute('submodule', 'addnewrecord-addworkorder', 'write');
    // if(writeAllowed){
    //   this.setState({disabledAll: false})
    // }else{
    //   this.setState({disabledAll: true})
    // }

    customFunctions.setTitle(this.state.title);

    const orderId = await this.props.workOrderId;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState(
      { roleId: userdata.role_id, orderId, techId: userdata.login_id },
      () => {
        if (userdata.role_id === 4) {
          this.setState({ disabledForCust: true });
        }
      }
    );
    customFunctions.setLocalStorage('viewId', orderId);
    customFunctions.setLocalStorage('orderId', orderId);

    if (this.props) {
      if (
        (await this.props.location) &&
        (await this.props.location.state) &&
        (await this.props.location.state.scheduleDetails) &&
        (await this.props.location.state.scheduleDetails.technicianId)
      ) {
        const myFutureTime = new Date(
          await this.props.location.state.scheduleDetails.apptDate
        );
        let res = myFutureTime.setMinutes(myFutureTime.getMinutes() + 240);
        let ToTime = new Date(res);
        var selectedtech = {
          value: this.props.location.state.scheduleDetails.technicianId,
          label: this.props.location.state.scheduleDetails.technicianName,
        };
        let technicianObject = [
          {
            tech_id: await this.props.location.state.scheduleDetails
              .technicianId,
            tech_name: await this.props.location.state.scheduleDetails
              .technicianName,
            technicianFunction: '',
            selectedToggle: false,
            isPrimary: 0,
            selectedOption: selectedtech,
          },
        ];
        await this.setState(
          {
            technicianId: await this.props.location.state.scheduleDetails
              .technicianId,
            ApptDate: await this.props.location.state.scheduleDetails.apptDate,
            FromTime: await this.props.location.state.scheduleDetails.apptDate,
            ToTime,
            rangeMinutes: 240,
            technicianObject,
          },
          () => {
            this.getTechnician();
          }
        );
      }
    }

    // const url_segment = this.props && this.props.match.url.split('/');

    // this.setState({ orderId });
    // if (url_segment && url_segment.includes('update-order')) {
    //   this.setState(
    //     {
    //       title: `update order`,
    //       editType: true,
    //     },
    //     () => customFunctions.setTitle(this.state.title)
    //   );
    // }
    if (orderId) {
      this.getOrderData(orderId);
    }
    this.setState({ addToggle: true });
    await this.getTechnicianFunction();
  };

  componentWillReceiveProps = async (props) => {
    if (props.workOrderId) {
      this.setState({ orderId: props.workOrderId });
      this.getOrderData(props.workOrderId);
    }
    if (props && props.chatBoxToggle) {
      this.setState({ chatBoxToggle: props.chatBoxToggle });
    }

    // if (props.type === 'completejob') {
    //   this.setState({ disabledAll: true });
    // }
  };

  getOrderData = async (orderId) => {
    this.setState({ isShowLoader: true });
    const endPoint = await `${Api.orderById}?work_order_id=${orderId}&type=1`;
    if (!this.state.scheduled) {
      this.setState({
        isShowLoader: true,
      });
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      if (data.data && data.data[0]) {
        const myFromTime = new Date(data.data[0].FromTime);
        const myToTime = new Date(data.data[0].ToTime);
        var rangeHour = customFunctions.diff_Minutes(myFromTime, myToTime);

        this.setState(
          {
            Id: data.data[0].ID,
            workOrderCode: data.data[0].WorkOrder,
            workOrderType: { name: data.data[0].WorkOrderType },
            WorkStatus: data.data[0].WorkStatus,
            workOrderDate:
              data.data[0].WorkOrderDate !== null
                ? new Date(data.data[0].WorkOrderDate)
                : '',
            selectedCustomerName: { name: data.data[0].CustomerName },
            repairDesc: data.data[0].RepairDescription,
            custmrAddress: data.data[0].AddressLine1_1,
            equipInst: data.data[0].PiecesOfEquipmentInstall,
            workCompleteValue: {
              id: data.data[0].WorkComplete,
              name:
                data.data[0].WorkComplete && data.data[0].WorkComplete === 1
                  ? 'Yes'
                  : 'No',
            },
            completionDate:
              data.data[0].EstimatedCompletionDate !== null
                ? new Date(data.data[0].EstimatedCompletionDate)
                : '',
            paymentAmount: data.data[0].PaymentAmount,
            ApptDate:
              data.data[0].FirstApptDate !== null
                ? new Date(data.data[0].FirstApptDate)
                : '',
            installerId: data.data[0].InstallerID,
            poField: data.data[0].PO,
            poamount: data.data[0].POAmount,
            jobTotal: data.data[0].JobTotal,
            jobDesc: data.data[0].JobDescription,
            installerCost: data.data[0].InstallerCost,
            paymentType: data.data[0].PaymentType,
            paymentTotal: data.data[0].Total,
            techDeduction: data.data[0].PayrollAmount,
            createdDateTime: data.data[0].Created
              ? customFunctions.getFormattedDate(data.data[0].Created)
              : '',
            created: data.data[0].Created,
            apptTime: data.data[0].ApptTime,
            updatedTime: data.data[0].Updated
              ? customFunctions.getFormattedDate(data.data[0].Updated)
              : '',
            updated: data.data[0].Updated,
            noteForReason: data.data[0].DeductionNote || '',
            FromTime:
              data.data[0].FromTime
                ? new Date(data.data[0].FromTime)
                : '',
            ToTime:
              data.data[0].ToTime ? new Date(data.data[0].ToTime) : '',
            wrntyCompany: data.data[0].sourceid,
            wrntyCompanyName: { name: data.data[0].source },
            vendorid: data.data[0].vendorid,
            vendorName: { name: data.data[0].vendorname },
            authNumber: data.data[0].AuthoNumber,
            authAmount: data.data[0].AuthoAmount,
            jobtitle: data.data[0].job_title,
            rangeMinutes: rangeHour
              ? timeRange.filter((range) => range.value == rangeHour)[0]
              : '',
            disabledAll:
              data.data[0].WorkComplete && data.data[0].WorkComplete == 1
                ? true
                : false,
            isWorkOrderCompletedHistory: data.data[0].isCompleted,
          },
          async () => {
            this.setMinDate(data.data[0].WorkStatus);
            await this.getTechnician();
            //technician should call before below code exec'n bcoz of techniciandata
            if (
              data.data[0] &&
              data.data[0].multitech &&
              data.data[0].multitech.length
            ) {
              const selectedOption = [];
              const selecteFunctiondOption = [];
              const allTechObject = [];
              const multiTechnician = data.data[0].multitech;

              if (multiTechnician && multiTechnician.length) {
                multiTechnician.map((technician) => {
                  const result =
                    this.state.originalTechniciandata &&
                    this.state.originalTechniciandata.length &&
                    this.state.originalTechniciandata.filter(
                      (res, i) => res.id === technician.tech_id
                    );
                  if (result.length) {
                    var selectedtech = {
                      value: result[0].id,
                      label: result[0].name,
                    };
                    selectedOption.push(selectedtech);
                  }
                  var pretechdata = false;
                  if (data.data[0].previoustechnicians) {
                    const pretech = JSON.parse(
                      data.data[0].previoustechnicians
                    );
                    if (pretech && pretech.length > 0) {
                      pretechdata = pretech.includes(technician.tech_id);
                    }
                  }

                  allTechObject.push({
                    isworked: pretechdata,
                    tech_id: technician.tech_id,
                    tech_name: technician.techname,
                    userid: result && result.length && result[0].userid,
                    technicianFunction: technician.tech_function,
                    selectedOption: selectedtech,
                    isPrimary: technician.is_primary,
                    selecteFunctiondOption: {
                      value: technician.tech_function,
                      label: technician.tech_function,
                    },
                  });
                  return technician;
                });
              }
              this.setState({
                technicianObject: allTechObject,
                selecteFunctiondOption: selecteFunctiondOption,
                selectedOption: selectedOption,
              });
            }
          }
        );
        if (data.data[0].CustomerID) {
          this.updateData(data.data[0].CustomerID);
        }
      }
    }
    this.setState({
      isShowLoader: false,
    });
    this.forceUpdate();
  };

  createOrder = async (e) => {
    const {
      orderId,
      workOrderType,
      workOrderDate,
      wrntyCode,
      repairDesc,
      workCompleteValue,
      completionDate,
      ApptDate,
      installerId,
      workOrderCode,
      jobTotal,
      jobDesc,
      jobtitle,
      techDeduction,
      noteForReason,
      FromTime,
      ToTime,
      technicianObject,
      wrntyCompany,
      vendorid,
      authNumber,
      authAmount,
      scheduled,
      WorkStatus,
      customerId,
    } = this.state;

    const MultiTech = [];
    technicianObject &&
      technicianObject.map((tech) => {
        if (tech.selectedOption && tech.selectedOption.value) {
          MultiTech.push({
            tech_id: tech.selectedOption.value,
            tech_function: tech.selecteFunctiondOption.value,
            name: tech.selecteFunctiondOption.value,
          });
        }
        return tech;
      });

    const postData = {
      order_code: workOrderCode,
      customer_id: customerId,
      warranty_code: wrntyCode,
      order_date: workOrderDate,
      order_type: workOrderType && workOrderType.name,
      repair_desc: repairDesc,
      work_complete: workCompleteValue && workCompleteValue.id,
      first_appt_date: ApptDate ? new Date(ApptDate).toISOString() : null,
      installer_id: installerId,
      completion_date: completionDate || '',
      job_total: jobTotal,
      job_title: jobtitle,
      job_description: jobDesc,
      tech_deduction: techDeduction,
      from_time: FromTime ? new Date(FromTime).toISOString() : null,
      to_time: ToTime ? new Date(ToTime).toISOString() : null,
      deduction_note: noteForReason,
      multitech: MultiTech,
      sourceid: (wrntyCompany && parseInt(wrntyCompany)) || null,
      vendorid,
      AuthoNumber: authNumber,
      AuthoAmount: authAmount,
      work_status: WorkStatus,
    };

    if (orderId) {
      postData.id = parseInt(orderId);
    }

    const endPoint = `${Api.workOrderUrl}`;

    const callback = orderId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      this.setState({ isShowLoader: false });
      if (orderId) {
        if (scheduled) {
          if (MultiTech.length === 0) {
            swal('Job unscheduled!', '', 'success');
            this.props.statusChangeCallback(orderId);
          } else {
            swal('Job scheduled!', '', 'success');
            this.props.statusChangeCallback(orderId);
          }
          this.setState({ scheduled: false, isShowLoader: false }, () => {
            this.getOrderData(orderId);
          });
        } else {
          this.setState({ isShowLoader: false });
          swal('Order Updated!', '', 'success');
        }
        return false;
      }
      this.setState({ isShowLoader: false });
      swal('Order Created!', '', 'success');
      setTimeout(() => {
        this.flushAllData();
        // this.props.history.goBack();
      }, 1000);
    } else {
      swal(message, '', 'error');
      setTimeout(() => {
        // this.props.history.push('/work-order');
      }, 1000);
    }

    this.setState({
      isShowLoader: false,
    });
  };

  updateData = async (id) => {
    const endPoint = `${Api.getCustomerById}?customer_id=${id}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      let custNum = data.data.PhoneNumber1 ? data.data.PhoneNumber1 : data.data.PhoneNumber2 ? data.data.PhoneNumber2 : data.data.PhoneNumber3 ? data.data.PhoneNumber3 : data.data.PhoneNumber4 ? data.data.PhoneNumber4 : null;

      this.setState({
        cntctPrsn: data.data.ContactName,
        customerId: data.data.ID,
        custmrAddress: data.data.AddressLine1_1,
        custdata: {
          userid: data.data.userid,
          roleid: 4,
          image: '',
          name: data.data.CustomerName,
          phonenumber: custNum
        },
      });
    }
  };

  updateCompletionDate = (workCompleteValue) => {
    if (workCompleteValue === 1) {
      var currentDate = new Date().toISOString();
      this.setState({
        completionDate: currentDate,
      });
    }
  };

  restrictCompletionDate = async (e) => {
    this.setState({
      updatedApptDate: e,
      ApptDate: '',
      completionDate: '',
    });
  };

  restrictApptDate = async (completionDate) => {
    this.setState({
      updatedCompletionDate: completionDate,
    });
  };

  validateForm = async (e) => {
    e.preventDefault();
    const {
      workOrderDate,
      selectedCustomerName,
      technicianObject,
      ApptDate,
      FromTime,
      ToTime,
      wrntyCompany,
      vendorid,
      jobtitle,
      workOrderType,
      scheduled,
    } = this.state;

    let workOrderTypeErr = '',
      wrntyErr = '',
      technicianErr = [],
      technicianFunctionErr = [],
      workOrderDateErr = '',
      repairDescErr = '',
      custmrAddressErr = '',
      equipInstErr = '',
      workcompleteValueErr = '',
      ApptDateErr = '',
      installerIdErr = '',
      paymentDateErr = '',
      crditCardAuthorErr = '',
      selectedPaymentModeErr = '',
      completionDateErr = '',
      creditErr = '',
      paymentAmountErr = '',
      audioPaymentErr = '',
      paymentTotalErr = '',
      payrollFourmulaErr = '',
      totalCorrespondErr = '',
      jobTotalErr = '',
      jobDescErr = '',
      installerCostErr = '',
      selectedCustomerNameErr = '',
      poamountErr = '',
      poFieldErr = '',
      paymentTypeErr = '',
      apptFromTimeErr = '',
      apptToTimeErr = '',
      isDuplicate = false,
      isValid = true,
      wrntyCompErr = '',
      vendorErr = '',
      jobtitleErr = '';

    // if (ApptDate === '') {
    //   ApptDateErr = 'Appt Date is required';
    //   isValid = false;
    // }
    if (ApptDate && FromTime === '') {
      apptFromTimeErr = 'Appt FromTime is required';
      isValid = false;
    }
    if (ApptDate && FromTime && ToTime === '') {
      apptToTimeErr = 'Please select range';
      isValid = false;
    }
    // if (completionDate === '' || !completionDate) {
    //   completionDateErr = 'Est. Completion Date is required';
    //   isValid = false;
    // }

    if (workOrderDate === '') {
      workOrderDateErr = 'Work Order date is required';
      isValid = false;
    }
    if (!workOrderType) {
      workOrderTypeErr = 'Work Order type is required';
      isValid = false;
    }
    if (jobtitle === '') {
      jobtitleErr = 'Job title is required';
      isValid = false;
    }

    if (selectedCustomerName === '') {
      selectedCustomerNameErr = 'Customer Name is required';
      isValid = false;
    }

    if (!vendorid) {
      vendorErr = 'Vendor is required';
      isValid = false;
    }
    // if (!wrntyCode) {
    //   wrntyErr = 'Home Warranty Code is required';
    //   isValid = false;
    // }
    if (!wrntyCompany) {
      wrntyCompErr = 'Home Warranty Company is required';
      isValid = false;
    }

    technicianObject &&
      technicianObject.map((tech, i) => {
        const result =
          technicianObject &&
          technicianObject.filter(
            (res, index) =>
              res.tech_id === tech.tech_id &&
              res.technicianFunction === tech.technicianFunction
          );
        if (result.length > 1) {
          isDuplicate = true;
          technicianErr[i] = 'Duplicate Entry found';
          isValid = false;
          return false;
        }
        // if (!tech.technician) {
        //   technicianErr[i] = 'Technician is required';
        //   isValid = false;
        //   return;
        // }
        if (tech.tech_id && ApptDate === '') {
          ApptDateErr = 'Appt Date is required';
          isValid = false;
        }

        if (tech.tech_id && !tech.technicianFunction) {
          technicianFunctionErr[i] = 'Technician Function is required';
          isValid = false;
          return false;
        }
        if (isDuplicate) {
          return false;
        }
      });

    if (
      scheduled &&
      technicianObject &&
      technicianObject.length &&
      technicianObject[0].tech_id === ''
    ) {
      technicianErr[0] = 'Please select technician';
      isValid = false;
    }

    this.setState({
      workOrderTypeErr,
      workOrderDateErr,
      completionDateErr,
      workcompleteValueErr,
      selectedCustomerNameErr,
      installerIdErr,
      selectedPaymentModeErr,
      crditCardAuthorErr,
      custmrAddressErr,
      repairDescErr,
      technicianErr,
      paymentAmountErr,
      ApptDateErr,
      paymentDateErr,
      creditErr,
      audioPaymentErr,
      payrollFourmulaErr,
      apptFromTimeErr,
      apptToTimeErr,
      totalCorrespondErr,
      equipInstErr,
      installerCostErr,
      jobTotalErr,
      jobDescErr,
      poamountErr,
      paymentTypeErr,
      paymentTotalErr,
      poFieldErr,
      wrntyErr,
      technicianFunctionErr,
      wrntyCompErr,
      vendorErr,
      jobtitleErr,
    });

    if (isValid) {
      this.createOrder();
    }
  };

  flushAllData = () => {
    this.setState({
      workOrderType: '',
      workOrderDate: '',
      selectedCustomerName: '',
      wrntyCode: '',
      cntctPrsn: '',
      tech: '',
      jobtitle: '',
      jobDesc: '',
      repairDesc: '',
      custmrAddress: '',
      equipInst: '',
      workCompleteValue: '',
      completionDate: '',
      poField: '',
      ApptDate: '',
      poamount: '',
      jobTotal: '',
      installerCost: '',
      noteForReason: '',
      apptTimeTo: '',
      apptTimeFrom: '',
      wrntyCompErr: '',
      wrntyCompany: '',
      authNumber: '',
      authAmount: '',
      vendorid: null,
      vendorName: '',
      wrntyCompanyName: '',
    });
  };

  dateChanged = (d) => {
    this.setState({ workOrderDate: d });
  };

  setMinDate = (status) => {
    var date = new Date();
    if (status === 'Unscheduled') {
      this.setState({
        minimunDate: date,
      });
      this.forceUpdate();
      return;
    }
    if (this.state.ApptDate) {
      this.setState({
        minimunDate: date,
      });
      this.forceUpdate();
      // return new Date();
    } else {
      this.setState({
        minimunDate: this.state.workOrderDate,
      });
      this.forceUpdate();
      // return this.state.workOrderDate;
    }
  };

  setMaxDate = () => {
    let date = new Date(this.state.completionDate);
    return new Date(date.setDate(date.getDate() - 1));
  };

  getTechnician = async (dt) => {
    const { ApptDate } = this.state;
    this.setState({ isShowLoader: true });
    let filterDate = dt
      ? new Date(dt).toISOString()
      : ApptDate
      ? new Date(ApptDate).toISOString()
      : new Date().toISOString();
    let endPoint = `${Api.technicianUrl}?from_date=${filterDate}`;
    // const endPoint = `${Api.technicianUrl}?skip=0&limit=10000&onleave=approved`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const techOptions = [];
      data.data.data &&
        data.data.data.map((tech) => {
          techOptions.push({
            value: tech.id,
            label: `${tech.name}`,
          });
          return tech;
        });

      this.setState({
        originalTechniciandata: data.data.data,
        techniciandata: techOptions,
      });
    }
    this.setState({
      isShowLoader: false,
    });
  };

  handleTechnicianChange = (selectedOption, index) => {
    const { technicianObject } = this.state;
    technicianObject[index].tech_id = selectedOption.value;
    technicianObject[index].tech_name = selectedOption.label;
    technicianObject[index].selectedOption = selectedOption;
    technicianObject[index].selectedToggle = false;
    this.setState({ technicianObject, selectedOption, addToggle: true });
  };

  components = {
    DropdownIndicator: null,
  };

  getTechnicianFunction = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = ` ${Api.getTechFunction}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const functionData = [];

      data &&
        data.data.map((func) => {
          functionData.push({
            value: func,
            label: `${func}`,
          });
          return func;
        });

      this.setState({
        techFunction: functionData,
      });
    }

    this.setState({
      isShowLoader: false,
    });
  };

  handleTechFunctionChange = (selecteFunctiondOption, index) => {
    const { technicianObject } = this.state;
    technicianObject[index].technicianFunction = selecteFunctiondOption.value;
    technicianObject[index].selecteFunctiondOption = selecteFunctiondOption;
    this.setState({ technicianObject, selecteFunctiondOption }, () => {
      this.getInstallerId();
    });
  };

  getInstallerId = () => {
    const { technicianObject } = this.state;
    let newTechArr = [];
    if (technicianObject && technicianObject.length > 0) {
      technicianObject.map((dt, i) => {
        if (i === 0 && dt.technicianFunction === 'Installation') {
          return false;
        } else {
          if (dt.technicianFunction === 'Installation') {
            newTechArr.push(dt);
          }
        }
      });
    }
    this.setState({
      installerId:
        newTechArr && newTechArr.length > 0 && newTechArr[0].selectedOption
          ? newTechArr[0].selectedOption.value
          : '',
    });
  };

  setWorkStatus = async (type = '') => {
    const { Id, confirmType, custId, techId, roleId } = this.state;
    this.setState({ type });
    let payload = {
      work_order_id: Id,
    };
    let api = '';
    if (type === 'schedule') return;
    if (type === 'acknowledge') {
      api = Api.workOrderAcknowledgement;
      payload.status = 'Offered';
    }
    if (confirmType === 'archivejob') {
      api = Api.workOrderAcknowledgement;
      payload.status = 'archive';
    }
    if (type === 'completejob') {
      api = Api.workOrderCompleted;
      payload.status = 'Completed';
    }
    if (confirmType === 'canceljob') {
      api = Api.workOrderCompleted;
      if (roleId === 4) {
        payload.cust_id = custId;
      }
      if (roleId === 5) {
        payload.tech_id = techId;
      }
      payload.status = 'Job Cancelled';
      payload.paused_reason = 'Job Cancelled';
    }
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${api}`;

    const callback = AuthApi.putDataToServer;

    const { data } = await callback(endPoint, payload);
    if (data) {
      if (type === 'completejob') {
        swal('Workorder completed successfully', '', 'success');
      }
      if (confirmType === 'canceljob') {
        swal('Workorder cancelled successfully', '', 'success');
        this.props.callback();
      }
      await this.getOrderData(Id);
      if (confirmType === 'archivejob') {
        swal('Workorder Archived successfully', '', 'success');
        // this.props.history.push('/schedule');
      }
    }
    this.setState({
      isShowLoader: false,
      confirmType: '',
      showDelete: false,
    });
  };

  render() {
    const {
      orderId,
      techniciandata,
      workOrderCode,
      workOrderType,
      workOrderDate,
      selectedCustomerName,
      selectedCustomerNameErr,
      cntctPrsn,
      technicianErr,
      jobtitle,
      jobDesc,
      repairDesc,
      customerId,
      custmrAddress,
      techDeduction,
      ApptDate,
      workCompleteValue,
      title,
      installerId,
      editType,
      workOrderDateErr,
      createdDateTime,
      updatedTime,
      jobTotalErr,
      jobTotal,
      noteForReason,
      ToTime,
      FromTime,
      startHours,
      startMinutes,
      techFunction,
      technicianObject,
      technicianFunctionErr,
      addToggle,
      ApptDateErr,
      apptFromTimeErr,
      apptToTimeErr,
      disabledForCust,
      disabledAll,
      roleId,
      wrntyCompErr,
      isShowLoader,
      WorkStatus,
      vendorid,
      rangeMinutes,
      vendorErr,
      jobtitleErr,
      workOrderTypeErr,
      scheduled,
      customerMsgBox,
      technicianId,
      created,
      updated,
      workCompleteJob,
      custId,
      swalParams,
      showDelete,
      vendorName,
      wrntyCompanyName,
      minimunDate,
      isWorkOrderCompletedHistory,
      messageBoxToggle,
      custdata,
      status,
    } = this.state;

    let imgs = '';
    let color = '';
    if (WorkStatus === 'Completed') {
      imgs = COMPLETED;
      color = 'rgb(202, 212, 221)';
    }
    if (WorkStatus === 'Inprogress') {
      imgs = INPROGRESS;
      color = 'rgb(243 215 211)';
    }
    if (WorkStatus === 'Offered') {
      imgs = OFFERED;
      color = '#50e650';
    }
    if (WorkStatus === 'Scheduled') {
      imgs = SCHEDULED;
      color = 'rgb(23, 149, 204)';
    }
    if (WorkStatus === 'Unscheduled') {
      imgs = UNSCHEDULED;
      color = 'rgb(251, 54, 64)';
    }
    if (WorkStatus === 'Paused') {
      imgs = PAUSED;
      color = 'rgb(255, 219, 40)';
    }
    if (WorkStatus === 'Cancelled') {
      imgs = CANCELLED;
      color = 'rgb(171, 179, 188)';
    }
    const { SetMessageBoxToggle, SetMessageRecieverData, workOrderToggle, callback } =
      this.props;
    return (
      <AUX>
        {workOrderToggle && (
          <>
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className={editType ? '' : ''}>
              <div className={editType ? '' : ''}>
                {showDelete && (
                  <div className="sweetalert">
                    <SweetAlert
                      title={
                        'Are you sure, do you want to ' + swalParams + ' ?'
                      }
                      error
                      showCancel
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        this.setWorkStatus(swalParams);
                      }}
                      onCancel={() => {
                        this.setState({ showDelete: false });
                      }}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  </div>
                )}
                <HelmetComponent title={title} />
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-20">
                      <div
                        className="card-body"
                        style={{ maxHeight: '616px', overflowY: 'scroll' }}
                      >
                        <form onSubmit={this.validateForm} autoComplete="off">
                          <div
                            className="d-flex justify-content-between"
                            style={{
                              backgroundColor: color,
                              padding: '5px 10px',
                            }}
                          >
                            <div className="back-btn-shedule">
                              <span
                                // onClick={() => this.props.history.go()}
                                onClick={() => callback()}
                                className="mdi mdi-arrow-left-bold"
                              ></span>
                            </div>

                            <div className="status-text">
                              <img
                                src={imgs}
                                width="20"
                                height="25"
                                style={{ cursor: 'pointer' }}
                                alt=""
                              />
                              <span
                                style={{ paddingLeft: '7px', fontSize: '16px' }}
                              >
                                {WorkStatus}
                              </span>
                            </div>
                          </div>
                          <div className="form-group row mt-2">
                            <div className="top-actions-wrapper-schedule">
                              <div
                                className={`top-actions-right mb-2 ${
                                  roleId === 4
                                    ? 'icons-hidden'
                                    : 'icons-visible'
                                }`}
                              >
                                {WorkStatus === 'Offered' && (
                                  <div
                                    className=""
                                    onClick={() =>
                                      this.setWorkStatus('acknowledge')
                                    }
                                  >
                                    <img
                                      src={ACKNOWLEDGE}
                                      width="25"
                                      height="30"
                                      style={{ cursor: 'pointer' }}
                                      alt=""
                                    />
                                    <p>Acknowledge</p>
                                  </div>
                                )}
                                {(roleId === 1 || roleId === 2) &&
                                  WorkStatus !== 'Inprogress' && (
                                    <div
                                      className=""
                                      onClick={() => {
                                        this.setWorkStatus('schedule');
                                        this.setState({ scheduled: true });
                                        this.scheduleRef.current.scrollIntoView(
                                          {
                                            behavior: 'smooth',
                                            block: 'center',
                                          }
                                        );
                                      }}
                                    >
                                      <img
                                        src={SCHEDULE}
                                        width="25"
                                        height="30"
                                        style={{ cursor: 'pointer' }}
                                        alt=""
                                      />
                                      {WorkStatus === 'Scheduled' ||
                                      WorkStatus === 'Completed' ? (
                                        <p>Re-schedule</p>
                                      ) : (
                                        <p>Schedule Now</p>
                                      )}
                                    </div>
                                  )}
                                {WorkStatus !== 'Completed' &&
                                  WorkStatus !== 'Offered' && (
                                    <div
                                      className=""
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          workCompleteJob: true,
                                        });
                                        // this.setWorkStatus('completejob')
                                      }}
                                    >
                                      <img
                                        src={COMPLETE}
                                        width="25"
                                        height="30"
                                        style={{ cursor: 'pointer' }}
                                        alt=""
                                      />
                                      <p>Complete Job</p>
                                    </div>
                                  )}
                                {WorkStatus !== 'Scheduled' && (
                                  <div
                                    // className="workorder-icons"
                                    onClick={() => {
                                      this.setState({
                                        swalParams: 'archive this job',
                                        showDelete: true,
                                        confirmType: 'archivejob',
                                      });
                                    }}
                                  >
                                    <img
                                      src={ARCHIVE}
                                      width="25"
                                      height="30"
                                      style={{ cursor: 'pointer' }}
                                      alt=""
                                    />
                                    <p>Archive Job</p>
                                  </div>
                                )}
                                {WorkStatus !== 'Offered' &&
                                  WorkStatus !== 'Cancelled' &&
                                  WorkStatus !== 'Completed' && (
                                    <div
                                      // className="workorder-icons"
                                      onClick={() => {
                                        this.setState({
                                          swalParams: 'cancel this job',
                                          showDelete: true,
                                          confirmType: 'canceljob',
                                        });
                                      }}
                                    >
                                      <img
                                        src={CANCEL}
                                        width="25"
                                        height="30"
                                        style={{ cursor: 'pointer' }}
                                        alt=""
                                      />
                                      <p>Cancel Job</p>
                                    </div>
                                  )}
                                <div
                                  className=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    SetMessageRecieverData(custdata);
                                    SetMessageBoxToggle(true);
                                    this.setState({messageBoxToggle: !messageBoxToggle})
                                  }}
                                  // style={{ pointerEvents: chatBoxToggle ? 'none' : '' }}
                                >
                                  <img
                                    src={MESSAGE}
                                    width="25"
                                    height="30"
                                    style={{ cursor: 'pointer' }}
                                    alt=""
                                  />
                                  <p>Message</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="workorder#"
                              className="col-sm-12 col-form-label"
                            >
                              Dispatch #
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                value={workOrderCode}
                                placeholder="Dispatch ID"
                                disabled
                                type="text"
                                id="example-text-input"
                              />
                            </div>
                            <label
                              htmlFor="workorderdate"
                              className="col-sm-12 col-form-label"
                            >
                              Dispatch Date
                              <span className="text-danger">*</span>{' '}
                            </label>
                            <div className="col-sm-12">
                              <DatePicker
                                className="form-control"
                                selected={workOrderDate}
                                disabled={orderId}
                                onChange={(date) => {
                                  this.setState({ workOrderDate: date });
                                  this.restrictCompletionDate(date);
                                }}
                                minDate={new Date()}
                                name="startDate"
                                dateFormat="MM/dd/yyyy"
                              />
                              <span id="err">{workOrderDateErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="workordertype"
                              className="col-sm-12 col-form-label"
                            >
                              Work Order Type
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-12">
                              <SelectAsyncPaginate
                                onSelectChange={(e) => {
                                  this.setState({
                                    workOrderType: e
                                      ? { name: e.order_type }
                                      : '',
                                  });
                                }}
                                className="w-100"
                                isSearch={true}
                                disabled={
                                  disabledForCust ||
                                  [5, 6].includes(roleId) ||
                                  (disabledAll && !scheduled && orderId)
                                }
                                value={workOrderType}
                                isClearable={workOrderType}
                                apiname="workordertype"
                                endpoint={Api.getOrderType + `?type=1&search=`}
                                selectPlaceholder="Work Order Type"
                              />
                              <span id="err">{workOrderTypeErr}</span>
                            </div>
                            <label
                              htmlFor="vendorid"
                              className="col-sm-12 col-form-label"
                            >
                              Vendor<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-12">
                              <SelectAsyncPaginate
                                onSelectChange={(e) => {
                                  if (e) {
                                    this.setState({
                                      vendorid: e.id,
                                      vendorName: { name: e.name },
                                    });
                                  }
                                }}
                                isClearable={vendorid}
                                className="w-100"
                                isSearch={true}
                                disabled={
                                  disabledForCust ||
                                  roleId === 5 ||
                                  disabledAll ||
                                  scheduled
                                }
                                value={vendorName}
                                apiname="vendor"
                                endpoint={Api.vendorsUrl + `?search=`}
                                selectPlaceholder="Vendor"
                              />
                              <span id="err">{vendorErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="homewarntycode"
                              className="col-sm-12 col-form-label"
                            >
                              Home Warranty Company/Source
                              <span className="text-danger">*</span>{' '}
                            </label>
                            <div
                              className={`${
                                roleId === 4 ||
                                roleId === 5 ||
                                disabledAll ||
                                scheduled
                                  ? 'col-sm-12'
                                  : 'col-sm-12'
                              }`}
                            >
                              <div className="d-flex">
                                <SelectAsyncPaginate
                                  onSelectChange={(e) => {
                                    this.setState({
                                      wrntyCompany: e ? e.id : null,
                                      wrntyCompanyName: e
                                        ? { name: e.name }
                                        : '',
                                    });
                                  }}
                                  isClearable={wrntyCompanyName}
                                  className="w-100"
                                  isSearch={true}
                                  disabled={
                                    disabledForCust ||
                                    roleId === 5 ||
                                    disabledAll ||
                                    scheduled
                                  }
                                  value={wrntyCompanyName}
                                  apiname="homewarranty"
                                  endpoint={Api.AddWarrantyCompany + `?search=`}
                                  selectPlaceholder="Home Warranty Company"
                                />
                              </div>
                              <span id="err">{wrntyCompErr}</span>
                            </div>
                          </div>
                          <div
                            className="card"
                            style={{
                              border: '1px solid #b9b6b6',
                              padding: '10px',
                              marginBottom: '20px',
                            }}
                          >
                            <div className="form-group row">
                              <label
                                htmlFor="customername"
                                className="col-sm-12 col-form-label"
                              >
                                Customer Name
                                <span className="text-danger">*</span>
                              </label>
                              <div
                                className={`${
                                  roleId === 4 ||
                                  roleId === 5 ||
                                  disabledAll ||
                                  (orderId && roleId === 1)
                                    ? 'col-sm-12'
                                    : 'col-sm-12'
                                }`}
                              >
                                <div className="d-flex">
                                  <SelectAsyncPaginate
                                    onSelectChange={(e) => {
                                      this.setState(
                                        {
                                          customerId: e ? e.ID : null,
                                          selectedCustomerName: e
                                            ? { name: e.name }
                                            : '',
                                          cntctPrsn: '',
                                          custmrAddress: '',
                                        },
                                        () => {
                                          if (e) this.updateData(e.ID);
                                        }
                                      );
                                    }}
                                    isClearable={selectedCustomerName}
                                    className="w-100"
                                    isSearch={true}
                                    disabled={
                                      orderId ||
                                      disabledForCust ||
                                      disabledAll ||
                                      roleId === 5
                                    }
                                    value={selectedCustomerName}
                                    apiname="customer"
                                    endpoint={
                                      Api.customerActiveUrl + `?type=1&search=`
                                    }
                                    selectPlaceholder="Customer Name"
                                  />
                                </div>
                                <span id="err">{selectedCustomerNameErr}</span>
                              </div>

                              <label
                                htmlFor="contactperson"
                                className="col-sm-12 col-form-label"
                              >
                                Contact Person
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  placeholder="Contact Person"
                                  disabled
                                  value={cntctPrsn}
                                  type="text"
                                  id="contactperson"
                                  onChange={(e) => {
                                    this.setState({
                                      cntctPrsn: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row">
                              <label
                                htmlFor="customeraddress"
                                className="col-sm-12 col-form-label"
                              >
                                Customer Address
                              </label>
                              <div className="col-sm-12">
                                <textarea
                                  className="form-control"
                                  type="textarea"
                                  id="customeraddress"
                                  disabled
                                  placeholder="Customer Address"
                                  style={{ resize: 'none' }}
                                  rows="2"
                                  cols="80"
                                  value={custmrAddress}
                                  onChange={(e) => {
                                    this.setState({
                                      custmrAddress: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                              <label
                                htmlFor="customer#"
                                className="col-sm-12 col-form-label"
                              >
                                Customer#
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  placeholder="Customer#"
                                  disabled
                                  value={customerId}
                                  type="text"
                                  id="customer#"
                                  onChange={(e) => {
                                    this.setState({
                                      customerId: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            {orderId &&
                              !disabledForCust &&
                              WorkStatus !== 'Completed' &&
                              WorkStatus !== 'Paused' &&
                              !disabledAll && (
                                <div className="form-group row justify-content-right">
                                  <div className="col-sm-12 col-md-12 text-right">
                                    <a
                                      href="/"
                                      type=""
                                      className="btn btn-primary"
                                      style={{ padding: '3px 10px' }}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({
                                          customerMsgBox: true,
                                          status: 'Unable to Contact Customer',
                                        });
                                      }}
                                    >
                                      Left Customer a Message
                                    </a>
                                  </div>
                                </div>
                              )}
                          </div>
                          <div
                            id="technician2"
                            ref={this.scheduleRef}
                            tabIndex="-1"
                          >
                            {((orderId &&
                              ((technicianObject &&
                                technicianObject.length &&
                                technicianObject[0].tech_id !== '') ||
                                scheduled ||
                                (workCompleteValue && workCompleteValue.id))) ||
                              !orderId) && (
                              <div
                                className="card"
                                style={{
                                  border: '1px solid #b9b6b6',
                                  padding: '10px',
                                  marginBottom: '20px',
                                }}
                              >
                                <div className="form-group row">
                                  <label
                                    htmlFor="apptdate"
                                    className="col-sm-12 col-form-label"
                                  >
                                    1st Appt Date
                                  </label>
                                  <div
                                    className="col-sm-12"
                                    style={{ marginBottom: '10px' }}
                                  >
                                    <DatePicker
                                      className="form-control"
                                      disabled={
                                        disabledForCust ||
                                        (disabledAll &&
                                          !isWorkOrderCompletedHistory) ||
                                        (!scheduled && orderId)
                                      }
                                      placeholderText="Select 1st Appt Date"
                                      selected={ApptDate}
                                      onChange={(e) => {
                                        this.setState({ ApptDate: e }, () => {
                                          this.getTechnician(
                                            customFunctions.addTimeToDate(e)
                                          );
                                        });
                                        this.restrictApptDate(e);
                                      }}
                                      name="startDate"
                                      dateFormat="MM/dd/yyyy"
                                      minDate={minimunDate}
                                      maxDate={this.setMaxDate()}
                                    />
                                    <span id="err">{ApptDateErr}</span>
                                  </div>

                                  <label
                                    htmlFor="appttime"
                                    className="col-sm-12 col-form-label"
                                  >
                                    Appt Time
                                  </label>
                                  <div className="col-sm-12">
                                    <div className="row">
                                      <div className="row w-100 time-picker-input1">
                                        <div className="col-sm-12">
                                          <p>From Time</p>
                                        </div>
                                        <div className="col-sm-12 m-l-10">
                                          <DatePicker
                                            selected={FromTime}
                                            placeholderText="From Time"
                                            className="form-control mb-2"
                                            disabled={
                                              disabledForCust ||
                                              !ApptDate ||
                                              (disabledAll &&
                                                !isWorkOrderCompletedHistory) ||
                                              (!scheduled && orderId)
                                            }
                                            onChange={(date) => {
                                              const myFutureTime = new Date(
                                                date
                                              );
                                              if (
                                                rangeMinutes &&
                                                rangeMinutes.value
                                              ) {
                                                let res =
                                                  myFutureTime.setMinutes(
                                                    myFutureTime.getMinutes() +
                                                      rangeMinutes.value
                                                  );
                                                this.setState({
                                                  ToTime: new Date(res),
                                                });
                                              }
                                              this.setState(
                                                {
                                                  FromTime: date,
                                                  startMinutes:
                                                    date.getMinutes(),
                                                  startHours: date.getHours(),
                                                },
                                                () => {
                                                  this.forceUpdate();
                                                }
                                              );
                                            }}
                                            showTimeSelect
                                            timeIntervals={1}
                                            showTimeSelectOnly
                                            minTime={
                                              ApptDate > new Date()
                                                ? undefined
                                                : setMinutes(
                                                    new Date(),
                                                    new Date().getMinutes()
                                                  )
                                            }
                                            maxTime={
                                              ApptDate > new Date()
                                                ? undefined
                                                : setHours(
                                                    setMinutes(new Date(), 59),
                                                    23
                                                  )
                                            }
                                            timeCaption="From"
                                            dateFormat="h:mm aa"
                                          />
                                        </div>
                                        <span id="err">{apptFromTimeErr}</span>
                                      </div>
                                      <div className="row w-100 time-picker-input2">
                                        <div className="col-sm-12">
                                          <p>Time Slot</p>
                                        </div>
                                        <div className="col-sm-12 m-l-10">
                                          <SelectStaticDropdown
                                            onSelectChange={(e) => {
                                              if (e) {
                                                const myFutureTime = new Date(
                                                  FromTime
                                                );
                                                let res =
                                                  myFutureTime.setMinutes(
                                                    myFutureTime.getMinutes() +
                                                      Number(e.value)
                                                  );
                                                this.setState(
                                                  {
                                                    ToTime: new Date(res),
                                                    rangeMinutes: e
                                                      ? {
                                                          name: e.name,
                                                          value: Number(
                                                            e.value
                                                          ),
                                                        }
                                                      : '',
                                                  },
                                                  () => {
                                                    this.forceUpdate();
                                                  }
                                                );
                                              }
                                            }}
                                            disabled={
                                              disabledForCust ||
                                              !FromTime ||
                                              (disabledAll &&
                                                !isWorkOrderCompletedHistory) ||
                                              (!scheduled && orderId)
                                            }
                                            className="w-100 pb-2"
                                            isSearch={false}
                                            value={rangeMinutes}
                                            isClearable={rangeMinutes}
                                            dropdownType="timeRange"
                                            selectPlaceholder="Select Range"
                                          />
                                          <span id="err">{apptToTimeErr}</span>
                                        </div>
                                      </div>
                                      <div className="row w-100 time-picker-input1">
                                        <div className="col-sm-12">
                                          <p>To Time</p>
                                        </div>
                                        <div className="col-sm-12 m-l-10">
                                          <DatePicker
                                            selected={ToTime}
                                            placeholderText="To Time"
                                            className="form-control pr-0"
                                            disabled="true"
                                            minDate={new Date(FromTime)}
                                            minTime={setHours(
                                              setMinutes(
                                                new Date(),
                                                startMinutes
                                              ),
                                              startHours
                                            )}
                                            maxTime={setHours(
                                              setMinutes(new Date(), 59),
                                              23
                                            )}
                                            // onChange={(date) => {
                                            //   this.setState({ ToTime: date });
                                            // }}
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={1}
                                            timeCaption="To"
                                            dateFormat="h:mm aa"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div>
                                  {technicianObject &&
                                    technicianObject.map((object, index) => (
                                      <div
                                        className="form-group row"
                                        key={`technician-${index}`}
                                      >
                                        <label
                                          htmlFor="technician"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Technician
                                        </label>

                                        <div
                                          className={`${
                                            roleId === 4 ||
                                            roleId === 5 ||
                                            roleId === 6 ||
                                            disabledAll ||
                                            (!scheduled && orderId)
                                              ? 'col-sm-12'
                                              : 'col-sm-12'
                                          }`}
                                        >
                                          <div className="d-flex wo-technician-block">
                                            <ReactSelect
                                              components={this.components}
                                              value={object.selectedOption}
                                              isDisabled={
                                                disabledForCust ||
                                                ((roleId === 5 ||
                                                  roleId === 6) &&
                                                  object.isPrimary === 1) ||
                                                object.isworked ||
                                                (!scheduled && orderId)
                                              }
                                              placeholder="Select Technician"
                                              onChange={(values) =>
                                                this.handleTechnicianChange(
                                                  values,
                                                  index
                                                )
                                              }
                                              options={techniciandata}
                                            />
                                          </div>
                                          <span id="err">
                                            {technicianErr.length > 0 &&
                                              technicianErr[index]}
                                          </span>
                                        </div>

                                        <label
                                          htmlFor="technicianFunction"
                                          className="col-sm-12 col-form-label"
                                        >
                                          Technician Function
                                        </label>
                                        <div
                                          className={`${
                                            roleId === 4 ||
                                            disabledAll ||
                                            (!scheduled && orderId)
                                              ? 'col-sm-12'
                                              : 'col-sm-11'
                                          }`}
                                        >
                                          <ReactSelect
                                            components={this.components}
                                            placeholder="Select Technician Function"
                                            value={
                                              object.selecteFunctiondOption
                                            }
                                            onChange={(values) =>
                                              this.handleTechFunctionChange(
                                                values,
                                                index
                                              )
                                            }
                                            options={techFunction}
                                            isDisabled={
                                              object.selectedToggle ||
                                              ((roleId === 5 || roleId === 6) &&
                                                object.isPrimary === 1) ||
                                              disabledForCust ||
                                              (disabledAll &&
                                                object.isworked) ||
                                              (!scheduled && orderId)
                                            }
                                          />
                                          <span id="err">
                                            {technicianFunctionErr.length > 0 &&
                                              technicianFunctionErr[index]}
                                          </span>
                                        </div>
                                        {((roleId !== 4 &&
                                          roleId !== 5 &&
                                          !disabledAll) ||
                                          (roleId === 5 &&
                                            object.isPrimary !== 1)) &&
                                          ((scheduled && orderId) ||
                                            (!scheduled && !orderId)) && (
                                            <i
                                              className="icon-container mdi mdi-close"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.removeTechnicianObject(
                                                  index
                                                );
                                              }}
                                            />
                                          )}
                                      </div>
                                    ))}
                                </div>

                                {roleId !== 4 &&
                                  addToggle &&
                                  ((scheduled && orderId) ||
                                    (!scheduled && !orderId)) && (
                                    <div className="form-group row">
                                      <Button
                                        variant="link"
                                        onClick={() =>
                                          this.addTechnicianObject()
                                        }
                                      >
                                        <i
                                          style={{
                                            color: 'blue',
                                          }}
                                          className="mdi mdi-plus-circle"
                                          id="editDriver"
                                          title="View agent"
                                        >
                                          {' '}
                                          Add technician
                                        </i>
                                      </Button>
                                    </div>
                                  )}
                                {scheduled &&
                                  ((disabledAll &&
                                    isWorkOrderCompletedHistory) ||
                                    !disabledAll) && (
                                    <div className="form-group row justify-content-center">
                                      <button
                                        className="btn btn-secondary"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.setState(
                                            {
                                              WorkStatus: 'Scheduled',
                                              scheduled: false,
                                            },
                                            () => {
                                              this.validateForm(e);
                                            }
                                          );
                                        }}
                                      >
                                        Schedule
                                      </button>
                                      <button
                                        className="btn btn-secondary ml-2"
                                        onClick={(e) => {
                                          this.setState({ scheduled: false });
                                          this.getOrderData(orderId);
                                          this.flushAllData();
                                        }}
                                      >
                                        Cancel
                                      </button>
                                    </div>
                                  )}
                              </div>
                            )}
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="jobtitle"
                              className="col-sm-12 col-form-label"
                            >
                              Job Title<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                placeholder="Job Title"
                                value={jobtitle}
                                disabled={
                                  disabledForCust ||
                                  roleId === 5 ||
                                  disabledAll ||
                                  scheduled
                                }
                                type="text"
                                id="jobtitle"
                                onChange={(e) => {
                                  this.setState({ jobtitle: e.target.value });
                                }}
                              />
                              <span id="err">{jobtitleErr}</span>
                            </div>
                            <label
                              htmlFor="jobtotal"
                              className="col-sm-12 col-form-label"
                            >
                              Job Total
                            </label>
                            <div className="col-sm-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="jobTotal"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  value={jobTotal}
                                  type="text"
                                  disabled={
                                    disabledForCust ||
                                    roleId === 5 ||
                                    disabledAll ||
                                    scheduled
                                  }
                                  placeholder="$0.00"
                                  id="jobtotal"
                                  onChange={(e) => {
                                    const re = /^[0-9]*\.?[0-9]*$/;
                                    if (
                                      e.target.value === '' ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState({
                                        jobTotal: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <span id="err">{jobTotalErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-12">
                                  <label htmlFor="jobdesc">
                                    Job Description
                                  </label>
                                </div>
                                <div className="col-12">
                                  <textarea
                                    className="form-control"
                                    disabled={
                                      disabledForCust ||
                                      disabledAll ||
                                      scheduled
                                    }
                                    placeholder="some description goes here"
                                    type="textarea"
                                    id="jobdesc"
                                    style={{
                                      resize: 'none',
                                      minHeight: '140px',
                                    }}
                                    cols="90"
                                    value={jobDesc}
                                    onChange={(e) => {
                                      this.setState({
                                        jobDesc: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-12">
                                  <label htmlFor="repairdesc">
                                    Repair Description
                                  </label>
                                </div>
                                <div className="col-12">
                                  <textarea
                                    className="form-control"
                                    disabled={
                                      disabledForCust ||
                                      disabledAll ||
                                      scheduled
                                    }
                                    placeholder="some description goes here"
                                    type="textarea"
                                    id="repairdesc"
                                    style={{
                                      resize: 'none',
                                      minHeight: '140px',
                                    }}
                                    cols="90"
                                    value={repairDesc}
                                    onChange={(e) => {
                                      this.setState({
                                        repairDesc: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="techdeduction"
                              className="col-sm-12 col-form-label"
                            >
                              Tech Deduction
                            </label>
                            <div className="col-sm-12">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="jobTotal"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  value={techDeduction}
                                  disabled={
                                    disabledForCust ||
                                    roleId === 5 ||
                                    disabledAll ||
                                    scheduled
                                  }
                                  type="text"
                                  placeholder="Tech Deduction"
                                  id="techDeduction"
                                  onChange={(e) => {
                                    const re = /^[0-9]*\.?[0-9]*$/;
                                    if (
                                      e.target.value === '' ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState({
                                        techDeduction: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                            <label
                              htmlFor="techdeduction"
                              className="col-sm-12 col-form-label"
                            >
                              Note For Reason
                            </label>
                            <div className="col-sm-12">
                              <div className="input-group">
                                <textarea
                                  className="form-control"
                                  placeholder="Note for reason"
                                  disabled={
                                    disabledForCust ||
                                    roleId === 5 ||
                                    disabledAll ||
                                    scheduled
                                  }
                                  type="textarea"
                                  id="repairdesc"
                                  style={{ resize: 'none', minHeight: '140px' }}
                                  cols="90"
                                  value={noteForReason}
                                  onChange={(e) => {
                                    this.setState({
                                      noteForReason: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="installerid"
                              className="col-sm-12 col-form-label"
                            >
                              Installer Id
                            </label>
                            <div className="col-sm-12">
                              <input
                                className="form-control"
                                value={installerId}
                                type="text"
                                disabled={
                                  disabledForCust ||
                                  roleId === 5 ||
                                  disabledAll ||
                                  scheduled
                                }
                                placeholder="Installer Id"
                                id="installerid"
                                onChange={(e) => {
                                  this.setState({
                                    installerId: e.target.value,
                                  });
                                }}
                              />
                            </div>
                            {orderId && (
                              <div className="col-sm-12">
                                <div className="row">
                                  <label
                                    htmlFor="workcomplete"
                                    className="col-sm-12 col-form-label"
                                  >
                                    Work Complete
                                  </label>
                                  <div className="col-sm-12">
                                    <SelectStaticDropdown
                                      onSelectChange={(e) => {
                                        this.setState(
                                          {
                                            workCompleteValue: e
                                              ? { id: e.id, name: e.name }
                                              : '',
                                          },
                                          () => {
                                            if (e)
                                              this.updateCompletionDate(e.id);
                                            if (!e)
                                              this.updateCompletionDate('');
                                            this.forceUpdate();
                                          }
                                        );
                                      }}
                                      disabled={true}
                                      className="w-100"
                                      isSearch={false}
                                      value={workCompleteValue}
                                      isClearable={workCompleteValue}
                                      dropdownType="workComplete"
                                      selectPlaceholder="Work Complete"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {orderId && (
                            <div className="form-group row">
                              <label
                                htmlFor="createdatetime"
                                className="col-sm-12 col-form-label"
                              >
                                Created Date Time
                              </label>
                              <div className="col-sm-12">
                                <input
                                  className="form-control"
                                  value={
                                    createdDateTime &&
                                    customFunctions.getMDYDateTime(created)
                                  }
                                  type="text"
                                  id="createdatetime"
                                  onChange={(e) => {
                                    this.setState({
                                      createdDateTime: e.target.value,
                                    });
                                  }}
                                  disabled
                                />
                              </div>

                              <div className="col-sm-12">
                                <div className="form-group row">
                                  {updatedTime && (
                                    <>
                                      <label
                                        htmlFor="updated"
                                        className="col-sm-12 col-form-label"
                                      >
                                        Updated Date Time
                                      </label>
                                      <div className="col-sm-12">
                                        <input
                                          className="form-control"
                                          value={
                                            updatedTime &&
                                            customFunctions.getMDYDateTime(
                                              updated
                                            )
                                          }
                                          type="text"
                                          id="updated"
                                          onChange={(e) => {
                                            this.setState({
                                              updatedTime: e.target.value,
                                            });
                                          }}
                                          disabled
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                          {roleId !== 4 && !disabledAll && !scheduled && (
                            <div className="form-group row d-flex justify-content-center">
                              <div className="col-sm-12 col-md-12 text-right">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Submit
                                </button>
                              </div>
                            </div>
                          )}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomerMessagePopup
              customerMsgBox={customerMsgBox}
              callbackfun={async () => {
                await this.getOrderData(orderId);
                // this.props.statusChangeCallback(orderId);
              }}
              popupClose={() =>
                this.setState({ customerMsgBox: !customerMsgBox })
              }
              customerId={customerId}
              orderId={orderId}
              status={status}
              technicianId={technicianId}
            />
            <CompleteJobPopup
              WorkStatus={this.props.jobStatus}
              workCompleteJob={workCompleteJob}
              callbackfun={() => {
                this.getOrderData(orderId, 'pause');
              }}
              popupClose={() =>
                this.setState({ workCompleteJob: !workCompleteJob })
              }
              workorderId={orderId}
              custId={custId}
            />
            {messageBoxToggle && (
              <MessageBox
                  onCallbackBox={() =>
                    this.setState({ messageBoxToggle: !messageBoxToggle })
                  }
                />
              )}
              </>
            )}
      </AUX>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  SetMessageBoxToggle: (data) => dispatch(SetMessageBoxToggle(data)),
  SetMessageRecieverData: (data) => dispatch(SetMessageRecieverData(data)),
});

const mapStateToProps = (state) => ({
    messageBoxToggle: state.auth.messageBoxToggle,
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderDetails);
