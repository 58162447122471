import React, { Component } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import 'react-notifications/lib/notifications.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import swal from 'sweetalert';
import {
  SetToggle,
  SetNotificationToggle,
  SetNotificationCount,
  SetChatNotificationCount,
  SetChatRecieverData,
} from '../../action/user.action';
import CANCEL from '../../assets/images/cancel.png';
import menu_expand from '../../assets/images/menu-expand.svg';
import menu from '../../assets/images/menu.svg';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import Loader from '../Loader';
import customFunctions from './../../helper/customFunctions';
import ChatBox from './chatBox.component';
import ChatNotification from './chatNotification.component';
import UniversSearchComponent from './UniversSearch';
import { Row, Col } from 'react-bootstrap';
import { deleteFCMToken } from '../../init-firebase';

let inputChangedPromise;

class header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      dropdownOpen1: false,
      dropdownOpenprofile: false,
      dropdownOpenbadge: false,
      now_route: '',
      countries: [],
      cities: [],
      countryid: '',
      cityid: '',
      notificationCnt: 0,
      roleName: null,
      loginUser: '',
      keyword: '',
      universData: [],
      modalShow: false,
      isShowLoader: false,
      roleId: null,
      loginId: null,
      userId: null,
      userName: '',
      notification: [],
      notificationCount: 0,
      notificationData: [],
      chatToggle: false,
      chatFlag: 'customers',
      chatBoxToggle: false,
      universDataEmpty: false,
      recieverData: null,
      messageType: '',
      dropdownOpenNotification: false,
      onBlur: false,
      route: '',
      profilePic: this.props.userdata.profile_pic,
    };

    this.toggle = this.toggle.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggleprofile = this.toggleprofile.bind(this);
    this.togglebadge = this.togglebadge.bind(this);
    this.toggleNotification = this.toggleNotification.bind(this);
    this.onsearchSubmit = this.onsearchSubmit.bind(this);
    this.chatRef = React.createRef();
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }
  toggle1() {
    this.setState((prevState) => ({
      dropdownOpen1: !prevState.dropdownOpen1,
    }));
  }
  toggleprofile() {
    this.setState((prevState) => ({
      dropdownOpenprofile: !prevState.dropdownOpenprofile,
    }));
  }
  togglebadge() {
    this.setState((prevState) => ({
      dropdownOpenbadge: !prevState.dropdownOpenbadge,
    }));
  }
  toggleNotification() {
    this.setState((prevState) => ({
      dropdownOpenNotification: !prevState.dropdownOpenNotification,
    }));
  }

  notificationRedirect(e) {
    e.preventDefault();
    window.location.href = '/orders';
  }

  componentDidMount = async () => {
    let location = window.location.pathname.split('/');
    this.setState({ now_route: this.props.location.pathname });
    const token = await customFunctions.getLocalStorage('token');
    if (!token) {
      this.redirectToLogin();
      return;
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    let fname = userdata.first_name
      ? customFunctions.capitalizeFirstLetter(userdata.first_name)
      : '';
    let lname = userdata.last_name
      ? customFunctions.capitalizeFirstLetter(userdata.last_name)
      : '';
    await this.setState(
      {
        roleName: userdata.rolename,
        loginUser: fname + ' ' + lname,
        roleId: userdata.role_id,
        loginId: userdata.login_id,
        userId: userdata.id,
        userName: userdata.username ? userdata.username : fname + ' ' + lname,
        profilePic: userdata.profile_pic,
        // chatBoxToggle: this.props.chatBoxToggle
      },
      () => {
        if (
          userdata.role_id === 5 ||
          userdata.role_id === 6 ||
          userdata.role_id === 3
        ) {
          this.getTechnician();
        }
      }
    );
    if (
      location[1] !== 'login' &&
      location[1] !== 'job-survey' &&
      location[1] !== 'live-location'
    ) {
      this.getNotificationsData();
      this.getChatNotificationsData();
    }

    var input = document.getElementById('search-text-input');
    input &&
      input.addEventListener('keyup', (event) => {
        if (event.key === 'Enter') {
          this.getGlobalData();
        }
      });
    document.addEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (event) => {
    if (
      this.chatRef &&
      this.chatRef.current &&
      !this.chatRef.current.contains(event.target)
    ) {
      this.setState({ chatToggle: false });
    }
  };

  getTechnician = async () => {
    const { loginId } = this.state;
    const endPoint = `${Api.technicianUrl}?tech_id=${loginId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success !== false && data.data && data.data.data) {
      this.setState(
        {
          technician:
            data.data.data && data.data.data.length && data.data.data[0],
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      alert(message);
    }
  };

  getNotificationsData = async (type) => {
    try {
      const { roleId, loginId } = this.state;
      const { SetNotificationCount } = this.props;
      let endPoint = `${Api.notificationUrl}?`;

      if (type === 'all' || this.props.noteToggle) {
        endPoint += `skip=0&limit=1000`;
      } else {
        endPoint += `skip=0&limit=2`;
      }
      endPoint +=
        [5, 6, 3].includes(roleId) && loginId
          ? `&technician_id=${loginId}`
          : '';
      endPoint += roleId === 4 && loginId ? `&customer_id=${loginId}` : '';
      const { data, message } = await AuthApi.getDataFromServer(endPoint);
      if (data && data.success && data.data) {
        this.setState({
          notificationData: data.data.data,
          // notificationCount: data.data.unreadcount,
        });
        SetNotificationCount(data.data.unreadcount);
      } else {
        swal(message, '', 'error');
      }
    } catch (err) {}
  };

  getChatNotificationsData = async (type) => {
    try {
      const { roleId, loginId } = this.state;
      const { SetChatNotificationCount } = this.props;
      let endPoint = `${Api.getResentMessagesUrl}?`;

      if (type === 'all' || this.props.noteToggle) {
        // endPoint += `skip=0&limit=1000`;
      } else {
        // endPoint += `skip=0&limit=2`;
      }
      endPoint +=
        (roleId === 5 || roleId === 6) && loginId
          ? `&technician_id=${loginId}`
          : '';
      endPoint += roleId === 4 && loginId ? `&customer_id=${loginId}` : '';
      const { data, message } = await AuthApi.getDataFromServer(endPoint);
      if (data && data.success && data.data) {
        SetChatNotificationCount(data.data.count);
      } else {
        swal(message, '', 'error');
      }
    } catch (err) {}
  };

  onMessageClick = async (e, notificatioId) => {
    e.preventDefault();
    e.stopPropagation();
    // await SetNotificationToggle(true);
    const { roleId } = this.state;
    const endPoint = `${Api.notificationUrl}`;

    const postData = {
      id: notificatioId,
    };
    if ([5, 6, 3].includes(roleId)) {
      postData.technician_read = 1;
    }
    if (roleId === 4) {
      postData.customer_read = 1;
    }
    if (roleId === 1) {
      postData.is_read = 1;
    }
    const { data } = await AuthApi.putDataToServer(endPoint, postData);
    if (data && data.success) {
      this.getNotificationsData();
    }
  };

  readAllNotification = async (e, notificatioId) => {
    const { roleId, loginId } = this.state;
    const endPoint = `${Api.readallNotificationUrl}`;
    let postData = {};
    if ([5, 6, 3].includes(roleId)) {
      postData = {
        technician_id: loginId,
      };
    }
    if (roleId === 4) {
      postData = {
        customer_id: loginId,
      };
    }
    if (roleId === 1) {
      postData = {
        is_read: 0,
      };
    }
    const { data } = await AuthApi.putDataToServer(endPoint, postData);
    if (data && data.success) {
      this.getNotificationsData();
    }
  };

  redirectToLogin = async () => {
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('login') > -1) return;
    await customFunctions.removeLocalStorage('token');
    window.location.href = '/login';
  };

  handleChange = (search) => {
    this.setState({ keyword: search });
    this.onsearchSubmit(search);
  };

  onsearchSubmit = async (search) => {
    const { keyword } = this.state;
    let keywordData = '';
    search = search.trim();
    if (search === keyword) {
      return;
    }
    keywordData = search;
    this.setState({ keyword: keywordData });
    if (inputChangedPromise) {
      clearTimeout(inputChangedPromise);
    }
    inputChangedPromise = setTimeout(this.getGlobalData.bind(this), 1000);
  };

  getGlobalData = async () => {
    this.setState({ isShowLoader: true });
    const { keyword, roleId, loginId } = this.state;
    if (keyword === '') {
      this.setState({ isShowLoader: false });
      return;
    }
    let endPoint = `${Api.globalSearchUrl}?search=${keyword}`;
    if (roleId === 6 || roleId === 5 || roleId === 4) {
      endPoint += `&id=${loginId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    const dataToggle =
      data.data.Recent_Jobs.length === 0 &&
      data.data.Customers.length === 0 &&
      data.data.Customers.length === 0
        ? 'true'
        : 'false';
    if (data && data.data) {
      this.setState({
        universData: data.data,
        universDataEmpty: dataToggle,
        modalShow: true,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  handleSelect = (key) => {
    this.setState({ chatFlag: key });
  };

  getBox = () => {
    this.setState({ chatToggle: false, chatBoxToggle: true });
  };

  componentWillReceiveProps(props) {
    if (props && props.chatBoxToggle) {
      this.setState({ chatBoxToggle: props.chatBoxToggle });
    }
    if (props && props.userdata) {
      this.setState({ profilePic: props.userdata.profile_pic }, () => {
        this.forceUpdate();
      });
    }
  }

  render() {
    const {
      loginUser,
      roleName,
      keyword,
      modalShow,
      universData,
      roleId,
      loginId,
      technician,
      chatToggle,
      chatFlag,
      universDataEmpty,
      chatBoxToggle,
      messageType,
      userId,
      userName,
      notificationData,
      profilePic,
      isShowLoader,
    } = this.state;
    const {
      SetToggle,
      toggle,
      SetNotificationToggle,
      noteToggle,
      count,
      SetChatRecieverData,
    } = this.props;
    return (
      <>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="topbar" style={{ left: toggle ? 0 : '240px' }}>
          <nav className="navbar-custom">
            <div className="search-wrap" id="search-wrap">
              <div className="search-bar">
                <input
                  className="search-input"
                  type="search"
                  placeholder="Search"
                />
                <a
                  href="/"
                  className="close-search toggle-search"
                  data-target="#search-wrap"
                >
                  <i className="mdi mdi-close-circle"></i>
                </a>
              </div>
            </div>
            <ul className="list-inline float-right mb-0">
              <li className="list-inline-item dropdown notification-list">
                <Dropdown
                  isOpen={this.state.dropdownOpenprofile}
                  toggle={this.toggleprofile}
                >
                  <DropdownToggle
                    className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user"
                    tag="a"
                  >
                    <img
                      src={
                        profilePic
                          ? profilePic
                          : '/assets/images/users/user.png'
                      }
                      alt=""
                      className="rounded-circle"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        if ([3, 5, 6].includes(roleId)) {
                          this.props.history.push(
                            [5, 6].includes(roleId)
                              ? `/edit-technician/${loginId}`
                              : `/edit-dispatcher/${loginId}`,
                            {
                              technicianDetails: technician,
                              fromComponent: 'profile',
                              detail: technician,
                            }
                          );
                        } else if (roleId === 4) {
                          customFunctions.setLocalStorage(
                            'activeTabCustomer',
                            'My Profile'
                          );
                          this.props.history.push(`/profile-module/${loginId}`);
                        } else {
                          this.props.history.push('/profile');
                        }
                      }}
                    >
                      <i className="mdi mdi-account-circle m-r-5"></i>
                      Profile
                    </DropdownItem>

                    {roleId === 4 && (
                      <DropdownItem
                        onClick={() => {
                          this.props.history.push('/ahs-workorders', {
                            fromComponent: 'profile',
                          });
                        }}
                      >
                        <i className="mdi mdi mdi-book-open"></i> All Jobs
                      </DropdownItem>
                    )}

                    <DropdownItem  onClick={async (e) => {
                          e.preventDefault();
                          this.setState({ isShowLoader: true });
                          await deleteFCMToken();
                          await customFunctions.removeLocalStorage('token');
                          await customFunctions.removeLocalStorage('isLogin');
                          await customFunctions.removeLocalStorage(
                            'callBackOptions'
                          );
                          await customFunctions.clearLocalStorage();
                          this.setState({ isShowLoader: false });
                          window.location.href = '/login';
                        }}>
                      <i className="mdi mdi-power text-danger"></i> Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>

            <ul className="list-inline float-right mb-0">
              <li className="list-inline-item dropdown notification-list">
                <Dropdown
                  isOpen={this.state.dropdownOpenNotification}
                  toggle={this.toggleNotification}
                  size="lg"
                >
                  <DropdownToggle
                    className="nav-link dropdown-toggle droptest arrow-none waves-effect nav-user"
                    tag="a"
                  >
                    <>
                      <img
                        src="/assets/images/users/notification.png"
                        alt=""
                        className="rounded-circle"
                        style={{
                          height: '24px',
                          width: '24px',
                        }}
                        onClick={() => {
                          !this.state.dropdownOpenNotification &&
                            this.getNotificationsData();
                        }}
                      />
                      <span className="badge badge-danger nav-link-badge">
                        {this.props && this.props.count && this.props.count > 0
                          ? this.props.count
                          : ''}
                      </span>
                    </>
                  </DropdownToggle>

                  <DropdownMenu className={noteToggle ? 'menubody' : ''}>
                    <DropdownItem header>
                      <Row>
                        <Col sm={12}>
                          <p className="main-notification-text">
                            You have{' '}
                            {this.props && this.props.count && this.props.count}{' '}
                            unread notifications
                          </p>
                        </Col>
                        <Col>
                          {!noteToggle && count > 0 && (
                            <a
                              href="/"
                              style={{
                                cursor: 'pointer',
                                fontWeight: '400',
                              }}
                              className=""
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                SetNotificationToggle(true);
                                this.getNotificationsData('all');
                              }}
                            >
                              View all
                            </a>
                          )}
                          {count > 0 && (
                            <a
                              href="/"
                              style={{
                                cursor: 'pointer',
                                fontWeight: '400',
                              }}
                              className={!noteToggle ? 'l-m-10' : ''}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.readAllNotification();
                              }}
                            >
                              Read all
                            </a>
                          )}
                        </Col>
                      </Row>
                    </DropdownItem>
                    <DropdownItem>
                      {notificationData.map((notification) => (
                        <>
                          <p
                            onClick={(e) => {
                              this.onMessageClick(e, notification.id);
                            }}
                            className={
                              (roleId === 1 && notification.is_read === 0) ||
                              ([5, 6, 3].includes(roleId) &&
                                notification.technician_read === 0) ||
                              (roleId === 4 && notification.customer_read === 0)
                                ? 'active-media word'
                                : 'word'
                            }
                          >
                            {notification.message}
                          </p>
                        </>
                      ))}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>

            <ul className="list-inline float-right mb-0">
              <li className="list-inline-item notification-list2">
                <div
                  className="nav-link droptest arrow-none waves-effect nav-user"
                  tag="a"
                >
                  <div
                    style={{
                      textAlign: 'right',
                      fontSize: '17px',
                      cursor: 'default',
                    }}
                  >
                    <b>
                      {this.props.userdata
                        ? (this.props.userdata.first_name
                            ? customFunctions.capitalizeFirstLetter(
                                this.props.userdata.first_name
                              )
                            : '') +
                          ' ' +
                          (this.props.userdata.last_name
                            ? customFunctions.capitalizeFirstLetter(
                                this.props.userdata.last_name
                              )
                            : '')
                        : loginUser}
                    </b>
                  </div>
                  <div
                    style={{
                      textAlign: 'right',
                      fontSize: '14px',
                      cursor: 'default',
                    }}
                  >
                    ({roleName})
                  </div>
                </div>
              </li>
            </ul>

            {roleId === 1 && (
              <ul className="list-inline float-right mb-0">
                <li className="list-inline-item notification-list2">
                  <div
                    className="nav-link droptest arrow-none waves-effect nav-user"
                    tag="a"
                  >
                    <div
                      className="d-flex"
                      style={{
                        maxWidth: '170px',
                        position: 'relative',
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light "
                        onClick={() => {
                          if (window.location.pathname !== '/work-order') {
                            this.props.history.push('/work-order');
                          }
                        }}
                        disabled={
                          window.location.pathname === '/work-order' ||
                          keyword !== ''
                            ? true
                            : false
                        }
                        style={{ marginTop: '10%' }}
                      >
                        Create Job
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            )}

            <ul className="list-inline float-right mb-0">
              <li
                className={`list-inline-item dropdown notification-list ${
                  roleId === 1
                    ? 'user-admin'
                    : roleId === 4
                    ? 'user-customer'
                    : roleId === 5
                    ? 'user-technician'
                    : ''
                }`}
              >
                <div className="show" ref={this.chatRef}>
                  <a
                    className="nav-link icon "
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        chatToggle: !chatToggle,
                        chatFlag: roleId !== 4 ? 'customers' : 'technician',
                      });
                    }}
                    style={{ pointerEvents: chatBoxToggle ? 'none' : '' }}
                  >
                    <i
                      style={{ marginTop: '16%' }}
                      className="mdi mdi-comment-multiple-outline"
                    ></i>
                    <span className="badge badge-danger nav-link-badge">
                      {this.props &&
                      this.props.chatCount &&
                      this.props.chatCount > 0
                        ? this.props.chatCount
                        : ''}
                    </span>
                  </a>
                  {chatToggle && (
                    <div className="dropdown-menu chatBox">
                      <Tabs
                        defaultActiveKey={
                          roleId !== 4 ? 'customers' : 'technician'
                        }
                        className="chat"
                        onSelect={this.handleSelect}
                      >
                        {roleId !== 4 && (
                          <Tab eventKey={'customers'} title="CUSTOMERS">
                            {chatFlag === 'customers' && (
                              <ChatNotification
                                onCallbackBox={(data) => {
                                  // this.setState({ recieverData: data });
                                  SetChatRecieverData(data);
                                  this.getBox();
                                }}
                                onCallbackNewMessage={(data) => {
                                  if (data === 'new message') {
                                    this.setState({ messageType: data });
                                  } else {
                                    this.setState({ messageType: '' });
                                  }
                                  this.getBox();
                                }}
                                chatBoxToggle={chatBoxToggle}
                                onSelect={this.state.chatFlag}
                              />
                            )}
                          </Tab>
                        )}
                        <Tab eventKey="technician" title="TECHNICIANS">
                          {chatFlag === 'technician' && (
                            <ChatNotification
                              onCallbackBox={(data) => {
                                // this.setState({ recieverData: data });
                                SetChatRecieverData(data);
                                this.getBox();
                              }}
                              onCallbackNewMessage={(data) => {
                                if (data === 'new message') {
                                  this.setState({ messageType: data });
                                } else {
                                  this.setState({ messageType: '' });
                                }
                                this.getBox();
                              }}
                              chatBoxToggle={chatBoxToggle}
                              onSelect={this.state.chatFlag}
                            />
                          )}
                        </Tab>
                        {roleId !== 1 && (
                          <Tab eventKey="admin" title="ADMIN">
                            {chatFlag === 'admin' && (
                              <ChatNotification
                                onCallbackBox={(data) => {
                                  // this.setState({ recieverData: data });
                                  SetChatRecieverData(data);
                                  this.getBox();
                                }}
                                onCallbackNewMessage={(data) => {
                                  if (data === 'new message') {
                                    this.setState({ messageType: data });
                                  } else {
                                    this.setState({ messageType: '' });
                                  }
                                  this.getBox();
                                }}
                                chatBoxToggle={chatBoxToggle}
                                onSelect={this.state.chatFlag}
                              />
                            )}
                          </Tab>
                        )}
                      </Tabs>
                    </div>
                  )}
                </div>
              </li>
            </ul>

            <ul className="list-inline float-right mb-0">
              <li className="list-inline-item notification-list2">
                <div
                  className="nav-link droptest arrow-none waves-effect nav-user"
                  tag="a"
                >
                  <div
                    className="input-wrap d-flex"
                    style={{
                      maxWidth: '170px',
                      position: 'relative',
                    }}
                  >
                    <input
                      className="form-control"
                      style={{ marginTop: '6%' }}
                      value={keyword}
                      type="text"
                      placeholder="Search here"
                      id="search-text-input"
                      onChange={(e) => this.handleChange(e.target.value)}
                    />
                    {keyword && (
                      <div
                        className="btn-close"
                        style={{ top: '19px' }}
                        onClick={() => {
                          this.setState({ keyword: '' });
                        }}
                      >
                        <img
                          style={{ maxHeight: '10px' }}
                          src={CANCEL}
                          alt=""
                        />
                      </div>
                    )}
                  </div>
                </div>
              </li>
            </ul>

            <ul className="list-inline menu-left mb-0 d-flex">
              <li className="list-inline-item">
                <button
                  type="button"
                  className="button-menu-mobile open-left waves-effect"
                  style={{ marginLeft: '13px' }}
                  onClick={() => SetToggle(!toggle)}
                >
                  <img className="menu-non-expanded" src={menu} alt=""></img>
                  <img className="menu-expanded" src={menu_expand} alt=""></img>
                </button>
              </li>
              <li className="hide-phone list-inline-item app-search">
                <h3 className="page-title" id="now_routing"></h3>
              </li>
            </ul>
            <div className="clearfix"></div>
          </nav>
          {modalShow && keyword && (
            <UniversSearchComponent
              show={true}
              universData={universData}
              universDataEmpty={universDataEmpty}
              handleModalCLose={(e) =>
                this.setState({ modalShow: e, keyword: '' })
              }
            />
          )}
        </div>
        {chatBoxToggle && (
          <ChatBox
            // recieverData={recieverData}
            messageType={messageType}
            roleId={roleId}
            userId={userId}
            userName={userName}
            onCallbackBox={() =>
              this.setState({ chatBoxToggle: !chatBoxToggle })
            }
          />
        )}
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  SetToggle: (data) => dispatch(SetToggle(data)),
  SetNotificationToggle: (data) => dispatch(SetNotificationToggle(data)),
  SetNotificationCount: (data) => dispatch(SetNotificationCount(data)),
  SetChatNotificationCount: (data) => dispatch(SetChatNotificationCount(data)),
  SetChatRecieverData: (data) => dispatch(SetChatRecieverData(data)),
});
const mapStateToProps = (state) => ({
  toggle: state.auth.toggle,
  userdata: state.auth.userdata,
  noteToggle: state.auth.noteToggle,
  count: state.auth.count,
  chatCount: state.auth.chatCount,
  chatBoxToggle: state.auth.chatBoxToggle,
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(header));
