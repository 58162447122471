import React, { forwardRef, useEffect, useState } from 'react';
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';
import customFunctions from '../../helper/customFunctions';

const SurveyMap = withScriptjs(
  withGoogleMap(
    forwardRef((props, ref) => {
      const [directions, setDirections] = useState([]);
      const [selectedMarker, setSelectedMarker] = useState('');
      const [sameLatitude, setSameLatitude] = useState([]);
      const latLngBounds = {
        latLngBounds: {
          north: -10,
          south: -40,
          east: 160,
          west: 100,
        },
      };
      const defaultProp = {
        defaultZoom: 12,
        defaultCenter: {
          lat: 18.689131,
          lng: 73.589462,
        },
        waypoint: true,
        onlyRoute: true,
        travelMode: 'driving',
        routePolylineOptions: {
          strokeColor: '#ff2343',
          strokeOpacity: 0.8,
          strokeWeight: 4,
          clickable: true,
        },
        routeMarkerOptions: {
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 2,
            strokeColor: '#fff',
            fillColor: '#ff2343',
            fillOpacity: 0.7,
            strokeWeight: 0.5, // eslint-disable-line
          },
          label: { color: 'white', text: ' ' },
        },
        InfoWindow: true,
        infowindowOptions: {},
        markerOptions: {
          icon: {
            path: window.google.maps.SymbolPath.Marker,
            scale: 20,
            strokeColor: 'blue',
            fillColor: 'blue',
            fillOpacity: 1,
            strokeWeight: 1,
          },
          label: { color: 'black', text: ' ' },
        },
        routeMarker: [],
        placeMarker: [],
        isMarkerShown: false,
      };
      const finalProps = { ...defaultProp, ...props };

      const setDirectionsRoute = (places = []) => {
        let directionData = [];
        const directionsService = new window.google.maps.DirectionsService();
        for (let item of places) {
          const waypoints = item.map((p) => ({
            location: { lat: Number(p.latitude), lng: Number(p.longitude) },
            stopover: true,
          }));
          const origin =
            waypoints.length > 1
              ? waypoints.shift().location
              : waypoints[0].location;
          const destination =
            waypoints.length > 1
              ? waypoints.pop().location
              : waypoints[0].location;

          directionsService.route(
            {
              origin: origin,
              destination: destination,
              optimizeWaypoints: true,
              travelMode:
                finalProps.travelMode.toUpperCase() ||
                window.google.maps.TravelMode.DRIVING,
              waypoints: finalProps.waypoint ? waypoints : [],
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionData.push({
                  color: item[0].color || 'blue',
                  route: result,
                });
                setDirections(JSON.parse(JSON.stringify(directionData)));
              } else {
                console.error(result);
              }
            }
          );
        }
      };

      useEffect(() => {
        if (props.places && props.places.length > 0) {
          setDirectionsRoute(props.places);
        } else {
          setDirections([]);
        }
      }, [props.places]);

      const overrideMarkerColor = (option, color) => {
        let ops = option;
        if (Object.keys(ops).length < 1) return ops;

        ops.icon.fillColor = color ? color : ops.icon.fillColor;
        return ops;
      };

      const onToggleOpen = (e) => {
        setSelectedMarker(e);
      };

      const RenderPlaceWindow = ({ data }) => {
        try {
          let html = finalProps.renderPlaceWindow ? (
            finalProps.renderPlaceWindow(data)
          ) : (
            <p>{data.name}</p>
          );
          return html;
        } catch (e) {
          return <p>{data.name}</p>;
        }
      };

      return (
        <div className="map-wrapper">
          <GoogleMap
            id="map"
            ref={ref}
            defaultZoom={finalProps.defaultZoom}
            center={finalProps.center}
            defaultCenter={finalProps.defaultCenter}
            minZoom={finalProps.defaultZoom - 3}
            maxZoom={finalProps.defaultZoom + 3}
            restriction={latLngBounds}
          >
            {finalProps.isMarkerShown &&
              finalProps.placeMarker &&
              finalProps.placeMarker.map((item, i) => (
                <Marker
                  key={i}
                  label={item.label || ''}
                  name={item.name}
                  title={item.name}
                  position={{
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                  }}
                  onClick={() => onToggleOpen(item.name)}
                  // {...getMarkerOption(finalProps.markerOptions, item.option)}
                >
                  {finalProps.InfoWindow && selectedMarker === item.name && (
                    <InfoWindow
                      onCloseClick={() => onToggleOpen('')}
                      {...finalProps.infowindowOptions}
                    >
                      <div className="info-window">
                        <RenderPlaceWindow data={item} />
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            {finalProps.children && finalProps.children}
          </GoogleMap>
        </div>
      );
    })
  )
);

export default SurveyMap;
