import React, { Component } from 'react';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';

class EditSubAccount extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      ListData: [],
      ListItemData: [],
      listItem: '',
      listitemerr: '',
      ListTypeId: '',
      ListType: '',
      listypeErr: '',
      subAccList: [],
      mainAcc: [],
    };
  }

  componentDidMount = () => {
    this.getListType();
  };

  addListItem = async () => {
    const newdata = await AuthApi.getDataFromServer(endPoint);

    const { listItem, ListType } = this.state;
    const { getSubAccountDataCallback } = this.props;
    const postData = {
      list_item: listItem,
      type_id: ListType,
    };
    const endPoint = `${Api.AddListItemUrl}`;
    const { data, message } = await AuthApi.postDataToServer(
      endPoint,
      postData
    );
    if (data) {
      swal('List Item added successfully', '', 'success');
      await this.flushAllData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
      if (getSubAccountDataCallback) getSubAccountDataCallback(ListType);
    } else {
      alert(message);
    }
  };

  getListType = async () => {
    const endPoint = `${Api.ListTypeUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        mainAcc: data.data.data,
      });
    }
  };

  getSubAccountData = async (mainAccount) => {
    const endPoint = `${Api.ListItemByType}?type_id=${mainAccount}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        subAccList: data.data.data,
      });
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { ListType, listItem } = this.state;
    let listypeErr = '',
      listitemerr = '';
    let isValid = true;

    if (ListType === '') {
      listypeErr = 'List Type is required';
      isValid = false;
    }

    if (listItem === '') {
      listitemerr = 'List Item is required';
      isValid = false;
    }

    this.setState({ listypeErr, listitemerr });
    if (isValid) {
      this.addListItem();
    }
  };

  flushAllData = () => {
    this.setState({
      ListType: '',
      listItem: '',
    });
  };

  render() {
    const { ListType, listypeErr, listItem, listitemerr, mainAcc } = this.state;
    const { mainAccountData } = this.props;
    return (
      <div
        className="modal fade"
        id="subaccount"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={(modal) => {
          this.modalRef = modal;
        }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add List Item
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                <form onSubmit={this.validate} autoComplete="off">
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-customer-charges"
                      className="col-sm-3 col-form-label"
                    >
                      List type
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-control"
                        value={ListType}
                        onChange={(e) => {
                          this.setState({ ListType: e.target.value });
                        }}
                      >
                        <option value="">Select type</option>
                        {mainAcc &&
                          mainAcc.map((type, id) => (
                            <option key={id} value={type.ID}>
                              {type.ListType}
                            </option>
                          ))}
                      </select>
                      <span id="err">{listypeErr}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-customer-charges"
                      className="col-sm-3 col-form-label"
                    >
                      List item
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        value={listItem}
                        onChange={(e) => {
                          this.setState({ listItem: e.target.value });
                        }}
                      />
                      <span id="err">{listitemerr}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="d-flex justify-content-center w-100">
                      <button
                        type="submit"
                        className="close"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        data-dismiss="modal"
                        onClick={() => this.flushAllData()}
                        className="btn btn-secondary waves-effect m-l-10"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EditSubAccount;
