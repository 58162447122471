import axios from 'axios';
import customFunctions from '../../helper/customFunctions';

export default {
  setupInterceptors: async () => {
    await axios.interceptors.request.use(
      async (config) => {
        let location = window.location.pathname.split('/');
        config.headers['Content-Type'] = 'application/json';
        let token = '';
        if (location[1] === 'job-survey') {
          token = await customFunctions.getLocalStorage('surveyToken');
        } else {
          token = await customFunctions.getLocalStorage('token');
        }
        if (token) config.headers['x-access-token'] = `${token}`;
        return config;
      },
      (error) => {
        Promise.reject(error);
      }
    );
    await axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        const data = await error.response;
        if (data && data.status === 400) {
          return Promise.reject(error.response.data);
        }
        return Promise.reject(error.response);
      }
    );
  },
};
