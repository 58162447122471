import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import {
  SetCustomer,
  SetFromDate,
  SetMonth,
  SetMonthYear,
  SetOrderType,
  SetPaymentType,
  SetTechnician,
  SetToDate,
  SetWarrantyCode,
  SetWorkComplete,
  SetYear,
} from '../../../action/user.action';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import Filter from './AddFilter';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Work Order #',
    field: 'workorder',
    width: 400,
    sort: 'disabled',
  },
  {
    label: 'Work Order Type',
    field: 'workordertype',
    sort: 'disabled',
  },
  {
    label: 'Home Warranty Code',
    field: 'homewarrantycode',
    width: 400,
    sort: 'disabled',
    // width: '550',
  },
  {
    label: 'Source',
    field: 'homewarrantycompany',
    sort: 'disabled',
  },
  {
    label: 'Technician',
    field: 'technician',
    sort: 'disabled',
  },
  {
    label: 'Service Type',
    field: 'servicetype',
    sort: 'disabled',
  },

  {
    label: 'Customer',
    field: 'customer',
    sort: 'disabled',
  },
  {
    label: 'WorkOrder Date',
    field: 'workorderdate',
    sort: 'disabled',
  },
  {
    label: 'Payment Type',
    field: 'paymntype',
    sort: 'disabled',
  },
  {
    label: 'Work Complete?',
    field: 'workcomplete',
    sort: 'disabled',
  },
  {
    label: 'Work Status',
    field: 'workstatus',
    sort: 'disabled',
  },

  {
    label: 'Invoice #',
    field: 'invoice',
    sort: 'disabled',
  },
  {
    label: 'Job Total',
    field: 'jobtotal',
    sort: 'disabled',
  },
  {
    label: 'Installer Id',
    field: 'installerid',
    sort: 'disabled',
  },
  {
    label: 'Payment Amount',
    field: 'paymentamount',
    sort: 'disabled',
  },

  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
    width: 200,
  },
];

class OrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      orders: [],
      rows: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 5,
      currentPage: 1,
      orderId: null,
      showDelete: false,
      id: null,
      type: '',
      viewType: false,
      viewForm: false,
      showFilter: false,
      technician: '',
      warantyCode: '',
      roleId: null,
      isShowLoader: false,
      homewrntycompanydata: [],
    };
  }

  componentDidMount = async () => {
    // let isAllowed = await customFunctions.isAllowedRoute('submodule', 'ahsworkorders-accessahsworkorders', 'write');
    // if(!isAllowed){
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    customFunctions.setTitle('All Work Orders');
    const id = await this.props.match.params.customerId;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState(
      { roleId: userdata.role_id, login_id: userdata.login_id },
      async () => {
        await this.getHomeWarrantyCompany();
        await this.getWorkOrder();
      }
    );
    const url_segment = this.props.match.url.split('/');
    if (url_segment && url_segment.includes('orderDetails')) {
      this.setState({ viewType: true });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.type
    ) {
      this.setState({ type: this.props.location.state.type });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.orderDetails
    ) {
      this.setState({
        orderDetails: await this.props.location.state.orderDetails,
      });
    }
    await this.setState({ id });
  };

  getHomeWarrantyCompany = async () => {
    const endPoint = `${Api.AddWarrantyCompany}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        homewrntycompanydata: data.data.data,
      });
    }
  };

  getHomewrntycompanyName = (id) => {
    const { homewrntycompanydata } = this.state;
    let res =
      homewrntycompanydata && homewrntycompanydata.filter((i) => i.id == id)[0];
    return (res && res.title) || '';
  };

  getWorkOrder = async (currentPage) => {
    const { id, type, roleId, login_id } = this.state;
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.getWorkOrder}?skip=${skip}&limit=${limit}&search=${keyword}&work_status=offered,unscheduled,scheduled,completed,canceled,rejected,inprogress`;
    if ((roleId === 5 || roleId === 6) && login_id) {
      endPoint = `${Api.getTechnicianWorkOrder}?skip=${skip}&limit=${limit}&technician_id=${login_id}`;
    } else if (type === 'technician') {
      const technicianId = await this.props.match.params.technicianId;
      endPoint = `${Api.getWorkOrder}/by_technician?technician_id=${technicianId}&skip=${skip}&limit=${limit}&search=${keyword}`;
    } else if (type === 'customer') {
      endPoint = `${Api.getWorkOrder}/by_customer?customer_id=${id}&skip=${skip}&limit=${limit}`;
    } else if (roleId === 4 && login_id) {
      endPoint = `${Api.getWorkOrder}/by_customer?customer_id=${login_id}&skip=${skip}&limit=${limit}`;
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState(
        {
          orders: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.setState({
      isShowLoader: false,
    });
  };

  onFilterSearch = async (currentPage) => {
    const { limit, roleId, login_id } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    const {
      technician,
      warantyCode,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
    } = this.props;
    var endPoint = `${Api.filterApi}?skip=${skip}&limit=${limit}`;

    endPoint +=
      (roleId == 5 || roleId == 6) && login_id ? `&technician=${login_id}` : '';
    endPoint += roleId == 4 && login_id ? `&customer=${login_id}` : '';

    endPoint += technician ? `&technician=${technician}` : '';
    endPoint += warantyCode ? `&home_code=${warantyCode}` : '';
    endPoint += customer ? `&customer=${customer}` : '';
    endPoint += orderType ? `&order_type=${orderType}` : '';
    endPoint += paymentType ? `&payment_type=${paymentType}` : '';
    endPoint += WorkCompleteValue ? `&work_complete=${WorkCompleteValue}` : '';
    endPoint += fromDate ? `&from_date=${customFunctions.setDateToMidNight(fromDate)}` : '';
    endPoint += toDate ? `&to_date=${customFunctions.setDateToMidNight(toDate)}` : '';
    endPoint += month && monthyear ? `&month=${monthyear}-${month}` : '';
    endPoint += year ? `&year=${year}` : '';
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          orders: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
  };

  setTableData = () => {
    const { orders, limit, currentPage, roleId } = this.state;
    const page = currentPage || 1;
    const rows = [];
    orders &&
      orders.length &&
      orders.map((orders, i) => {
        orders.WorkOrderDate = customFunctions.changeDateFormatMomentMDY(
          orders.WorkOrderDate
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          workorder: (
            <React.Fragment>
              <a
                href="/"
                className=""
                disabled={orders.WorkOrder ? orders.WorkOrder : ''}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                  });
                }}
              >
                {orders.WorkOrder || '-'}
              </a>
            </React.Fragment>
          ),
          workordertype: orders.job_title || '-',
          homewarrantycode: orders.HomeWarrantyCode || '-',
          homewarrantycompany:
            (orders.sourceid &&
              this.getHomewrntycompanyName(orders.sourceid)) ||
            '-',
          servicetype:
            (orders.multitech &&
              orders.multitech[0] &&
              orders.multitech[0].is_primary == 1 &&
              orders.multitech[0].tech_function) ||
            '-',
          technician:
            (orders.multitech &&
              orders.multitech[0] &&
              orders.multitech[0].is_primary == 1 &&
              orders.multitech[0].techname) ||
            '-',
          customer: orders.CustomerName || '-',
          workorderdate: orders.WorkOrderDate || '-',
          paymntype: orders.payment_type || '-',
          invoice: orders.invoice_id || '-',
          jobtotal: orders.JobTotal || '0',
          installerid: orders.InstallerID || '-',
          paymentamount: orders.payment_amount || '-',
          workcomplete: orders.WorkComplete === 1 ? 'Yes' : 'No',
          workstatus: orders.WorkStatus || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                  });
                }}
              >
                <i className="mdi mdi-lead-pencil" title="Edit Order"></i>
              </a>
              {(roleId == 1 || roleId == 2) && (
                <a
                  href="/"
                  className="px-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showDelete: true, orderId: orders.ID });
                  }}
                >
                  <i
                    className="mdi mdi-delete text-danger"
                    title="Delete Order"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return orders;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteOrder = async () => {
    const { orderId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getWorkOrder}?work_order_id=${orderId}`
    );
    if (data) {
      this.setState({ showDelete: false, orderId: null }, () => {
        this.getWorkOrder();
      });
      swal('Order deleted!', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getWorkOrder();
  };

  clearSearch = () => {
    this.setState({ keyword: '', orders: [] }, () => {
      this.getWorkOrder();
    });
  };

  onAdvancedSearch = () => {
    this.setState({ showFilter: true });
    const {
      SetTechnician,
      SetWarrantyCode,
      SetCustomer,
      SetOrderType,
      SetPaymentType,
      SetWorkComplete,
      SetFromDate,
      SetToDate,
      SetMonth,
      SetMonthYear,
      SetYear,
    } = this.props;
    SetTechnician('');
    SetWarrantyCode('');
    SetCustomer('');
    SetOrderType('');
    SetPaymentType('');
    SetWorkComplete('');
    SetFromDate('');
    SetToDate('');
    SetMonth('');
    SetMonthYear('');
    SetYear('');
  };

  onCancelBtn = () => {
    this.setState({ showFilter: false });
  };

  onSubmit = () => {
    this.setState({ showFilter: false });
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      showDelete,
      viewType,
      showFilter,
      isShowLoader,
      roleId,
    } = this.state;

    return (
      <AUX>
        <div className={viewType ? 'page-content-wrapper' : ''}>
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <div className={viewType ? 'container-fluid' : ''}>
            <HelmetComponent title="All Work Orders" />
            {showDelete && (
              <SweetAlert
                title="Are you sure, do you want to delete the order ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deleteOrder();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, orderId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            )}
            <div className={viewType ? 'row' : ''}>
              <div className={viewType ? 'col-12' : ''}>
                <div className="card m-b-20">
                  <div className="card-body">
                    {viewType && (
                      <div className="row">
                        <div className="col-sm-8">
                          <form
                            onSubmit={this.onSearchSubmit}
                            autoComplete="off"
                          >
                            <div className="form-group row">
                              <div className="col-sm-6 col-md-6">
                                <input
                                  className="form-control"
                                  value={keyword}
                                  type="text"
                                  placeholder="Search by Customer, Technician, Home warranty code, Work complete, Work order type"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    this.setState({ keyword: e.target.value });
                                    if (!e.target.value) this.clearSearch();
                                  }}
                                  required={true}
                                />
                              </div>
                              <div className="col-sm-6 col-md-6">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  Search
                                </button>
                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.clearSearch();
                                  }}
                                  className="btn btn-secondary waves-effect m-l-10"
                                >
                                  Cancel
                                </button>

                                <button
                                  type="reset"
                                  onClick={() => {
                                    this.onAdvancedSearch();
                                  }}
                                  className="btn btn-primary waves-effect waves-light m-l-10"
                                >
                                  Advance Search
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                        {(roleId === 1 || roleId === 2) && (
                          <div className="col-sm-4">
                            <div className="right-actions float-right">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={() => {
                                  this.props.history.push('/work-order');
                                }}
                              >
                                Add Order
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {showFilter && (
                      <Filter
                        onSubmitButtonCallback={() => {
                          this.onSubmit();
                        }}
                        onCancelButtonCallback={() => {
                          this.onCancelBtn();
                        }}
                        onFilterSearch={this.onFilterSearch}
                        showFilter={showFilter}
                      />
                    )}

                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getWorkOrder}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => ({
  technician: state.auth.technician,
  warantyCode: state.auth.warantyCode,
  customer: state.auth.customer,
  orderType: state.auth.orderType,
  paymentType: state.auth.paymentType,
  WorkCompleteValue: state.auth.WorkCompleteValue,
  fromDate: state.auth.fromDate,
  toDate: state.auth.toDate,
  month: state.auth.month,
  monthyear: state.auth.monthyear,
  year: state.auth.year,
});

const mapDispatchToProps = (dispatch) => ({
  SetTechnician: (technician) => dispatch(SetTechnician(technician)),
  SetWarrantyCode: (warantyCode) => dispatch(SetWarrantyCode(warantyCode)),
  SetCustomer: (customer) => dispatch(SetCustomer(customer)),
  SetOrderType: (orderType) => dispatch(SetOrderType(orderType)),
  SetPaymentType: (paymentType) => dispatch(SetPaymentType(paymentType)),
  SetWorkComplete: (WorkCompleteValue) =>
    dispatch(SetWorkComplete(WorkCompleteValue)),
  SetFromDate: (fromDate) => dispatch(SetFromDate(fromDate)),
  SetToDate: (toDate) => dispatch(SetToDate(toDate)),
  SetMonth: (month) => dispatch(SetMonth(month)),
  SetMonthYear: (monthyear) => dispatch(SetMonthYear(monthyear)),
  SetYear: (year) => dispatch(SetYear(year)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OrderDetails)
);
