import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Work Order',
    field: 'workorder',
    sort: 'disabled',
  },
  {
    label: 'Work Complete ?',
    field: 'workcomplete',
    sort: 'disabled',
  },
  {
    label: 'Job Total',
    field: 'jobtotal',
    sort: 'disabled',
  },
  {
    label: 'Paid ?',
    field: 'paid',
    sort: 'disabled',
  },
  {
    label: 'Payroll Amount',
    field: 'payrollamount',
    sort: 'disabled',
  },
  {
    label: 'Payment Date',
    field: 'paymentdate',
    sort: 'disabled',
  },
  {
    label: 'Estimated Completion Date',
    field: 'estimatedcompletiondate',
    sort: 'disabled',
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
  },
];

class PaymentListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      payment: [],
      rows: [],
      paymentId: null,
      showDelete: false,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Payment');
    await this.getPayments();
  };

  getPayments = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    const endPoint = `${Api.payment}?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          payment: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      alert(message);
    }
  };

  setTableData = () => {
    const { payment, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    payment &&
      payment.length > 0 &&
      payment.map((item, i) => {
        var date = new Date(item.EstimatedCompletionDate);
        var d = date.getDate();
        var m = date.getMonth() + 1;
        var y = date.getFullYear();
        var EstimationCompletionDate =
          (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;

        var pdate = new Date(item.payment_date);
        var pd = pdate.getDate();
        var pm = pdate.getMonth() + 1;
        var py = pdate.getFullYear();
        var paymentDate =
          (pd <= 9 ? '0' + pd : pd) +
          '/' +
          (pm <= 9 ? '0' + pm : pm) +
          '/' +
          py;

        rows.push({
          index: (page - 1) * limit + (i + 1),
          workorder: item.WorkOrder,
          workcomplete: item.WorkComplete === 0 ? 'No' : 'Yes',
          jobtotal: item.JobTotal,
          paid: item.is_paid === 0 ? 'No' : 'Yes',
          payrollamount: item.payroll_amount,
          paymentdate: paymentDate,
          estimatedcompletiondate: EstimationCompletionDate,
          actions: (
            <React.Fragment>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/payment/edit/${item.id}`);
                }}
              >
                <i className="mdi mdi-lead-pencil" title="Edit"></i>
              </a>

              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, paymentId: item.id });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return item;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  detelePayment = async () => {
    const { paymentId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.payment}?payment_id=${paymentId}`
    );
    if (data) {
      this.setState({ showDelete: false, paymentId: null }, () => {
        this.getPayments();
      });
      alert('Customer deleted');
    } else {
      alert(message);
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getPayments();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getPayments();
    });
  };

  render() {
    const { keyword, rows, limit, totalCount, currentPage, showDelete } =
      this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure you want to delete this payment?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.detelePayment();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, paymentId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6">
                        <div className="col-md-8 offset-md-8">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.props.history.push('/payment/add');
                            }}
                          >
                            Add Payment
                          </button>
                        </div>
                      </div>
                    </div>
                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getPayments}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(PaymentListing);
