import React, { Component } from 'react';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import swal from 'sweetalert';

class AddComplaintCategory extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.state = {
      category: '',
      categoryErr: '',
      isShow: '',
    };
  }

  validateForm = (e) => {
    e.preventDefault();
    let categoryErr = '';
    let isValid = true;
    const { category } = this.state;
    if (category.trim() === '') {
      categoryErr = 'Category is required';
      isValid = false;
    }
    this.setState({ categoryErr });
    if (isValid) {
      this.addCategory();
    }
  };

  addCategory = async () => {
    const { category } = this.state;
    const { getComplaintCategoryCallback } = this.props;
    const postData = {
      category: category,
    };
    const endPoint = `${Api.AddcomplaintCategoryUrl}`;
    const { data, message } = await AuthApi.postDataToServer(
      endPoint,
      postData
    );
    if (data && data.success !== false) {
      swal('Complaint category added successfully', '', 'success');
      if (getComplaintCategoryCallback) getComplaintCategoryCallback();
      await this.flushAllData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
    } else {
      swal(message, '', 'error');
    }
  };

  flushAllData = async () => {
    this.setState({
      category: '',
      categoryErr: '',
    });
  };

  render() {
    const { category, categoryErr } = this.state;
    return (
      <div
        className="modal fade"
        id="complaint-category"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={(modal) => {
          this.modalRef = modal;
        }}
      >
        <div
          className="modal-dialog modal-md modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Complaint Category
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                <form onSubmit={this.validateForm} autoComplete="off">
                  <div className="form-group row justify-content-center">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Category
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-8">
                      <input
                        type="text"
                        className="form-control"
                        value={category}
                        onChange={(e) => {
                          this.setState({ category: e.target.value });
                        }}
                      />
                      <span id="err">{categoryErr}</span>
                    </div>
                    <div className="col-sm-2" />
                  </div>
                  <div className="form-group row justify-content-center">
                    <div className="d-block pt0 mb15">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        onClick={() => this.flushAllData()}
                        data-dismiss="modal"
                        className="btn btn-secondary waves-effect m-l-10"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddComplaintCategory;
