import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';
import HelmetComponent from '../Title/index';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import TechnicianStatisticsTables from './technicianTables/technicianStatistics';
import CompletedApptTables from './technicianTables/completedAppt';
import AllApptTables from './technicianTables/allAppt';
import BarChart from './charts/BarChart';
import PieChart from './charts/PieChart';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';

class Technicians extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Technician',
      avgTimes: [],
      dateRangeList: [
        'Current Week',
        'Last Week',
        'Current Month',
        'All Dates',
        '365 Days',
        '180 Days',
        '90 Days',
        '60 Days',
        '30 Days',
        '14 Days',
        '7 Days',
        '3 Days',
        '1 Day',
      ],
      dateRange: '30 Days',
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      filterApplied: false,
      avgRating: [],
      apptWorkload: [],
      filterCount: 1,
      initialHide: true,
      technicianId: null,
      technician: '',
      technicianName: '',
      technicianIdd: false,
      fromComponent: '',
      roleId: null,
      technicianReport: [],
      technicianDetails: [],
      isShowLoader: false,
    };
  }

  componentDidMount = async (props) => {
    // let isAllowed = await customFunctions.isAllowedRoute(
    //   'submodule',
    //   'reports-technicians'
    // );
    // if (!isAllowed) {
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    this.setState({
      fromComponent: this.props.fromComponent,
      technicianId: this.props.technicianId,
      roleId: userdata.role_id,
    });

    if (this.props.fromComponent === 'technician' && this.props.technicianId) {
      await this.setState(
        {
          technicianId:
            this.props &&
            this.props.match &&
            this.props.match.params &&
            this.props.match.params.technicianId,
          fromComponent: this.props.fromComponent,
          roleId: userdata.role_id,
          technicianDetails: this.props.technicianDetails,
        },
        async () => {
          await this.getWorkOrderAveragetime();
          await this.getRatingbytechnician();
          await this.getTechnicianWorkload();
          await this.getTechnicianReport();
          this.forceUpdate();
        }
      );
    } else {
      await this.getWorkOrderAveragetime();
      await this.getRatingbytechnician();
      await this.getTechnicianWorkload();
    }

    if (this.props.fromComponent === 'profile') {
      await this.getTechnicianReport();
      this.setState({ title: 'My Profile' });
    }
    this.forceUpdate();

    //set to show only for report page, not in technician profile
    if (
      this.props.fromComponent !== 'technician' &&
      this.props.fromComponent !== 'profile'
    ) {
      customFunctions.setTitle('Technicians');
    }
  };

  getTechnicianWorkload = async () => {
    var { startDate, endDate, dateRange, technicianId } = this.state;
    this.setState({ isShowLoader: true });
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getTechnicianWorkload}?from_date=${startDate}&to_date=${endDate}`;
    if (technicianId) {
      endPoint += `&tech_id=${technicianId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success) {
      const apptWorkload = data && data.data;
      this.setState({ isShowLoader: false, apptWorkload });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };
  getRatingbytechnician = async () => {
    var { startDate, endDate, dateRange, technicianId } = this.state;
    this.setState({ isShowLoader: true });
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.getRatingbytechnician}?from_date=${startDate}&to_date=${endDate}`;
    if (technicianId) {
      endPoint += `&tech_id=${technicianId}`;
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success) {
      const avgRating = data && data.data;
      this.setState({ isShowLoader: false, avgRating });
    } else {
      this.setState({ isShowLoader: false });
      // swal(message, '', 'error');
    }
  };

  getWorkOrderAveragetime = async () => {
    var { startDate, endDate, dateRange, technicianId } = this.state;
    this.setState({ isShowLoader: true });
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.technicianaveragetime}?from_date=${startDate}&to_date=${endDate}`;
    if (technicianId) {
      endPoint += `&tech_id=${technicianId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ isShowLoader: false, avgTimes: data.data });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  changeDates = async () => {
    const { dateRange } = this.state;
    var date = new Date();
    let firstday;

    if (dateRange === 'Current Week') {
      var curr = new Date();
      var first = curr.getDate() - curr.getDay();
      var last = first + 6;
      // firstday = new Date(curr.setDate(first));
      firstday = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(first))
      );

      // date = new Date(curr.setDate(last));
      date = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(last))
      );
    }
    if (dateRange === 'Last Week') {
      let dates = customFunctions.getLastWeek(date);
      firstday = dates.firstDayOfLastWeek;
      date = dates.lastDayOfLastWeek;
    }
    if (dateRange === 'Current Month') {
      firstday = new Date(date.getFullYear(), date.getMonth(), 1);
      date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    if (dateRange === '365 Days') {
      firstday = new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '180 Days') {
      firstday = new Date(date.getTime() - 180 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '90 Days') {
      firstday = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '60 Days') {
      firstday = new Date(date.getTime() - 60 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '30 Days') {
      firstday = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '14 Days') {
      firstday = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '7 Days') {
      firstday = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '3 Days') {
      firstday = new Date(date.getTime() - 3 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '1 Day') {
      firstday = new Date(date.getTime() - 24 * 60 * 60 * 1000);
    }
    this.setState({ startDate: firstday, endDate: date, initialHide: false });

    if (dateRange === 'All Dates') {
      this.setState({ startDate: '', endDate: '' });
    }
    this.filterCount();
  };

  filterCount = () => {
    const { dateRange } = this.state;
    let dateLen = dateRange !== '' ? 1 : 0;
    this.setState({ filterCount: dateLen });
  };

  searchStatus = (search_term) => {
    const { statusList } = this.state;
    this.setState({ keyword: search_term });
    var matched_status = [];
    search_term = search_term.toLowerCase();
    statusList &&
      statusList.forEach((item) => {
        if (item.toLowerCase().indexOf(search_term) !== -1) {
          matched_status.push(item);
        }
        this.setState({ searchedStatus: matched_status });
      });
  };

  callback = () => {
    this.setState({ filterApplied: false });
  };

  getTechnicianReport = async () => {
    const { technicianId } = this.state;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.technicianById}?tech_id=${technicianId}`
    );
    if (data && data.data && data.success) {
      this.setState(
        {
          isShowLoader: false,
          technicianReport: data.data.data[0],
        },
        () => this.updateReportPermisssion()
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  updateReportPermisssion = async () => {
    const { technicianReport, technicianId } = this.state;

    if (technicianReport && technicianReport.reportspermission === null) {
      const payload = {
        technician_id: Number(technicianId),
        technician_name: technicianReport && technicianReport.name,
        email: technicianReport.email,
        reportspermission: {
          all_appt_scheduled: true,
          completed_appt_date: true,
          tech_stat_date: true,
          total_time: true,
          avg_work_time: true,
          avg_drive_time: true,
          appointment_workload: true,
          average_rating: true,
        },
      };
      const endpoint = Api.AddtechnicianUrl;
      const { data, message } = await AuthApi.putDataToServer(
        endpoint,
        payload
      );
      if (data) {
        this.getTechnicianReport();
      } else {
        swal(message, '', 'error');
      }
    }
  };

  handleAverageRating = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission:
        technicianReport &&
        technicianReport.reportspermission &&
        technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.average_rating =
      e.target.checked && e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  handleAppointmentWorkload = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission: technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.appointment_workload = e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  handleAverageDriveTime = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission: technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.avg_drive_time = e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  handleAverageWorkTime = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission: technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.avg_work_time = e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  handleAppointmentTime = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission: technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.total_time = e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  handleTechnicianStat = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission: technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.tech_stat_date = e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  handleCompletedApptDate = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission: technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.completed_appt_date = e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  handleAllApptScheduleDate = async (e) => {
    const { technicianId, technicianReport } = this.state;
    const endpoint = Api.AddtechnicianUrl;
    const payload = {
      technician_id: Number(technicianId),
      reportspermission: technicianReport.reportspermission,
      technician_name: technicianReport.name,
    };

    payload.reportspermission.all_appt_scheduled = e.target.checked;

    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.putDataToServer(endpoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      title,
      avgTimes,
      dateRange,
      dateRangeList,
      startDate,
      endDate,
      filterApplied,
      avgRating,
      apptWorkload,
      filterCount,
      initialHide,
      technicianId,
      fromComponent,
      roleId,
      isShowLoader,
      technicianReport,
      technician,
      technicianName,
      technicianIdd,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12 m-b-20">
                <div id="accordion">
                  <div
                    className="card"
                    style={{
                      position: 'relative',
                      border: '2px solid #CCC',
                      borderBottom: 'none',
                    }}
                  >
                    <div
                      style={{ fontSize: '16px' }}
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      <b>{`FILTERS(${filterCount})`}</b>{' '}
                      <span className="dropdown-toggle"></span>{' '}
                    </div>
                    {dateRange && (
                      <span className="selected-daterange">
                        <b>DateRange</b> {dateRange}
                        {initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                        {!initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse show`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                      </span>
                    )}
                    <div
                      id="collapseOne"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="card-body1">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="accordion" id="accordionExample">
                                <div className="card">
                                  <div className="card-body1" id="headingOne">
                                    <button
                                      className="btn btn-block text-left dropdown-toggle card-header"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne1"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Date Range
                                    </button>
                                  </div>

                                  <div
                                    id="collapseOne1"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                  >
                                    <div className="card-body">
                                      <Radio
                                        checked={dateRange === 'Custom Range'}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              dateRange: e.target.value,
                                            },
                                            () => this.filterCount()
                                          );
                                        }}
                                        id="customrange"
                                        value="Custom Range"
                                        color="default"
                                      />
                                      <label htmlFor="customrange">
                                        Custom Range
                                      </label>

                                      <br />
                                      {dateRange === 'Custom Range' && (
                                        <span>
                                          <DatePickerComponent
                                            selected={startDate}
                                            placeholderText="Start Date"
                                            onChange={(date) => {
                                              this.setState({
                                                startDate: date,
                                              });
                                            }}
                                            startDate={startDate}
                                            endDate={endDate}
                                            className="form-control filter-date"
                                          />
                                          <br />
                                          <DatePickerComponent
                                            selected={endDate}
                                            placeholderText="End Date"
                                            onChange={(date) =>
                                              this.setState({ endDate: date })
                                            }
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            className="form-control filter-date"
                                          />
                                        </span>
                                      )}
                                      {dateRangeList &&
                                        dateRangeList.map((dates, index) => (
                                          <React.Fragment key={index}>
                                            <Radio
                                              checked={dateRange === dates}
                                              onChange={(e) => {
                                                this.setState(
                                                  {
                                                    dateRange: e.target.value,
                                                  },
                                                  () => this.changeDates()
                                                );
                                              }}
                                              id={`dates${index}`}
                                              value={dates}
                                              color="default"
                                            />
                                            <label htmlFor={`dates${index}`}>
                                              {dates}
                                            </label>
                                            <br />
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-4"
                              style={{ paddingLeft: '0px' }}
                            >
                              {!fromComponent && (
                                <div
                                  className="accordion"
                                  id="accordionExample1"
                                >
                                  <div className="card">
                                    <div className="card-body1" id="headingOne">
                                      <button
                                        className="btn btn-block text-left dropdown-toggle card-header"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseOne2"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Technician
                                      </button>
                                    </div>
                                    <div
                                      id="collapseOne2"
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordionExample1"
                                    >
                                      <div
                                        className="card-body"
                                        style={{
                                          height: '295px',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        <SelectAsyncPaginate
                                          onSelectChange={(e) => {
                                            this.setState({
                                              technicianIdd: false,
                                              technicianId: e ? e.id : null,
                                              technicianName: e
                                                ? { name: e.name }
                                                : '',
                                            });
                                          }}
                                          className="w-100"
                                          isSearch={true}
                                          isClearable={technician}
                                          value={
                                            technicianName ? technicianName : ''
                                          }
                                          apiname="technician"
                                          clearSelectedByParent={technicianIdd}
                                          endpoint={
                                            Api.technicianUrl +
                                            `?role=5&search=`
                                          }
                                          selectPlaceholder="Select Technician"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className="col-4"
                              style={{ paddingLeft: '0px' }}
                            ></div>
                            <div
                              className="col-12"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="card-footer">
                                <div className="row">
                                  <div className="col-4"></div>
                                  <div className="col-4"></div>
                                  <div className="col-4">
                                    <span
                                      onClick={() =>
                                        this.setState(
                                          {
                                            dateRange: '30 Days',
                                            filterApplied: false,
                                            technicianId: null,
                                            technicianIdd: true,
                                            technicianName: '',
                                            startDate: new Date(
                                              new Date().getTime() -
                                                30 * 24 * 60 * 60 * 1000
                                            ),
                                            endDate: new Date(),
                                          },
                                          () => this.filterCount()
                                        )
                                      }
                                      className="resetFilter f-btns"
                                    >
                                      RESET FILTERS
                                    </span>
                                    <button
                                      className="btn btn-secondary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      className="btn btn-primary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                      onClick={() =>
                                        this.setState(
                                          { filterApplied: true },
                                          async () => {
                                            await this.getWorkOrderAveragetime();
                                            await this.getRatingbytechnician();
                                            await this.getTechnicianWorkload();
                                          }
                                        )
                                      }
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.average_rating ? (
                <div className="col">
                  <div className="card m-b-20 report-card-height">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <span>Average Rating</span>
                      </div>
                    </div>
                    <div className="card-body text-center py-3" id="avgTime2">
                      <BarChart
                        chartData={avgRating}
                        fromComponent="technician"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col-md-4">
                    <div className="card m-b-20 report-card-height">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <span>Average Rating</span>
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="average-rating"
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleAverageRating(event);
                                  }}
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .average_rating
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label
                                  htmlFor="average-rating"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="card-body text-center py-3" id="avgTime2">
                        <BarChart
                          chartData={avgRating}
                          fromComponent="technician"
                        />
                      </div>
                    </div>
                  </div>
                )
              )}

              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.appointment_workload ? (
                <div className="col">
                  <div className="card m-b-20 report-card-height">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <span>Appointment Workload</span>
                      </div>
                      <div className="card-body text-center py-3" id="avgTime2">
                        <PieChart
                          chartData={apptWorkload}
                          fromComponent="technician"
                          width="70"
                          height="30"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col-md-8">
                    <div className="card m-b-20 report-card-height">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <span>Appointment Workload</span>
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="appt-workload"
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .appointment_workload
                                      ? 'checked'
                                      : ''
                                  }
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleAppointmentWorkload(event);
                                  }}
                                />
                                <label
                                  htmlFor="appt-workload"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <PieChart
                            chartData={apptWorkload}
                            fromComponent="technician"
                            width="70"
                            height="30"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="row">
              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.avg_drive_time ? (
                <div className="col">
                  <div className="card m-b-20 report-card-height">
                    <div className="card-body text-center py-3" id="avgTime1">
                      <div className="d-flex justify-content-between">
                        <p
                          className="avgClass"
                          style={{ fontSize: '16px', textAlign: 'left' }}
                        >
                          Avg Drive Time (Minutes)
                        </p>
                      </div>
                      <h1>
                        {avgTimes.drivetime ? avgTimes.drivetime.toFixed(1) : 0}
                      </h1>
                      <p style={{ fontSize: '22px' }}>
                        Avg Drive Time (Minutes)
                      </p>
                      <br />
                      <p className="avgClass" style={{ fontSize: '12px' }}>
                        Original Value :{' '}
                        {avgTimes.drivetime ? avgTimes.drivetime : 0}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col">
                    <div className="card m-b-20 report-card-height">
                      <div className="card-body text-center py-3" id="avgTime1">
                        <div className="d-flex justify-content-between">
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Drive Time (Minutes)
                          </p>
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="avg-drive-time"
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleAverageDriveTime(event);
                                  }}
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .avg_drive_time
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label
                                  htmlFor="avg-drive-time"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                        <h1>
                          {avgTimes.drivetime
                            ? avgTimes.drivetime.toFixed(1)
                            : 0}
                        </h1>
                        <p style={{ fontSize: '22px' }}>
                          Avg Drive Time (Minutes)
                        </p>
                        <br />
                        <p className="avgClass" style={{ fontSize: '12px' }}>
                          Original Value :{' '}
                          {avgTimes.drivetime ? avgTimes.drivetime : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}

              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.avg_work_time ? (
                <div className="col">
                  <div className="card m-b-20 report-card-height">
                    <div className="card-body text-center py-3" id="avgTime2">
                      <div className="d-flex justify-content-between">
                        <p
                          className="avgClass"
                          style={{ fontSize: '16px', textAlign: 'left' }}
                        >
                          Avg Work Time (Minutes)
                        </p>
                      </div>
                      <h1>
                        {avgTimes.worktime ? avgTimes.worktime.toFixed(1) : 0}
                      </h1>
                      <p style={{ fontSize: '22px' }}>
                        Avg Work Time (Minutes)
                      </p>
                      <br />
                      <p className="avgClass" style={{ fontSize: '12px' }}>
                        Original Value :{' '}
                        {avgTimes.worktime ? avgTimes.worktime : 0}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col">
                    <div className="card m-b-20 report-card-height">
                      <div className="card-body text-center py-3" id="avgTime2">
                        <div className="d-flex justify-content-between">
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Work Time (Minutes)
                          </p>
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="avg-work-time"
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleAverageWorkTime(event);
                                  }}
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .avg_work_time
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label
                                  htmlFor="avg-work-time"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                        <h1>
                          {avgTimes.worktime ? avgTimes.worktime.toFixed(1) : 0}
                        </h1>
                        <p style={{ fontSize: '22px' }}>
                          Avg Work Time (Minutes)
                        </p>
                        <br />
                        <p className="avgClass" style={{ fontSize: '12px' }}>
                          Original Value :{' '}
                          {avgTimes.worktime ? avgTimes.worktime : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}

              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.total_time ? (
                <div className="col">
                  <div className="card m-b-20 report-card-height">
                    <div className="card-body text-center py-3" id="avgTime3">
                      <div className="d-flex justify-content-between">
                        <p
                          className="avgClass"
                          style={{ fontSize: '16px', textAlign: 'left' }}
                        >
                          Total Time per Appointment (Hours)
                        </p>
                      </div>
                      <h1>
                        {avgTimes.avgtime ? avgTimes.avgtime.toFixed(1) : 0}
                      </h1>
                      <p style={{ fontSize: '22px' }}>
                        Total Time per Appointment (Hours)
                      </p>
                      <br />
                      <p className="avgClass" style={{ fontSize: '12px' }}>
                        Original Value :{' '}
                        {avgTimes.avgtime ? avgTimes.avgtime : 0}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col">
                    <div className="card m-b-20 report-card-height">
                      <div className="card-body text-center py-3" id="avgTime3">
                        <div className="d-flex justify-content-between">
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Total Time per Appointment (Hours)
                          </p>
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="total-time"
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleAppointmentTime(event);
                                  }}
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .total_time
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label
                                  htmlFor="total-time"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                        <h1>
                          {avgTimes.avgtime ? avgTimes.avgtime.toFixed(1) : 0}
                        </h1>
                        <p style={{ fontSize: '22px' }}>
                          Total Time per Appointment (Hours)
                        </p>
                        <br />
                        <p className="avgClass" style={{ fontSize: '12px' }}>
                          Original Value :{' '}
                          {avgTimes.avgtime ? avgTimes.avgtime : 0}
                        </p>
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="row">
              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.tech_stat_date ? (
                <div className="col">
                  <div className="card m-b-20">
                    <div className="card-body">
                      <div className="d-flex justify-content-between"></div>
                      <TechnicianStatisticsTables
                        title="Technician Statistics for Date Range"
                        technicianId={technicianId && technicianId}
                        fromComponent={fromComponent && fromComponent}
                        startDate={startDate}
                        endDate={endDate}
                        filterApplied={filterApplied}
                        callback={this.callback}
                        csvType="downloadCSVForTechStatistic"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col">
                    <div className="card m-b-20">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="tech-stat-date"
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleTechnicianStat(event);
                                  }}
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .tech_stat_date
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label
                                  htmlFor="tech-stat-date"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                        <TechnicianStatisticsTables
                          title="Technician Statistics for Date Range"
                          technicianId={technicianId && technicianId}
                          fromComponent={fromComponent && fromComponent}
                          startDate={startDate}
                          endDate={endDate}
                          filterApplied={filterApplied}
                          callback={this.callback}
                          csvType="downloadCSVForTechStatistic"
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="row">
              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.completed_appt_date ? (
                <div className="col">
                  <div className="card m-b-20">
                    <div className="card-body">
                      <div className="d-flex justify-content-between"></div>
                      <CompletedApptTables
                        title="Completed Appointments for Date Range"
                        technicianId={technicianId && technicianId}
                        startDate={startDate}
                        endDate={endDate}
                        filterApplied={filterApplied}
                        callback={this.callback}
                        csvType="downloadCSVForCompletedAppt"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col">
                    <div className="card m-b-20">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="completed-appt-date"
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleCompletedApptDate(event);
                                  }}
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .completed_appt_date
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label
                                  htmlFor="completed-appt-date"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                        <CompletedApptTables
                          title="Completed Appointments for Date Range"
                          technicianId={technicianId && technicianId}
                          startDate={startDate}
                          endDate={endDate}
                          filterApplied={filterApplied}
                          callback={this.callback}
                          csvType="downloadCSVForCompletedAppt"
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className="row">
              {roleId === 5 &&
              fromComponent === 'profile' &&
              technicianReport &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission &&
              technicianReport.reportspermission.all_appt_scheduled ? (
                <div className="col-12">
                  <div className="card m-b-20">
                    <div className="card-body">
                      <div className="d-flex justify-content-between"></div>
                      <AllApptTables
                        title="All Appointments Scheduled for Date Range"
                        technicianId={technicianId && technicianId}
                        startDate={startDate}
                        endDate={endDate}
                        filterApplied={filterApplied}
                        callback={this.callback}
                        csvType="downloadCSVForAllAppt"
                      />
                    </div>
                  </div>
                </div>
              ) : (
                roleId !== 5 && (
                  <div className="col-12">
                    <div className="card m-b-20">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          {fromComponent === 'technician' && roleId === 1 && (
                            <span>
                              <React.Fragment>
                                <input
                                  type="checkbox"
                                  id="all-appt-scheduled"
                                  switch="info"
                                  onChange={(event) => {
                                    this.handleAllApptScheduleDate(event);
                                  }}
                                  checked={
                                    technicianReport &&
                                    technicianReport.reportspermission &&
                                    technicianReport.reportspermission
                                      .all_appt_scheduled
                                      ? 'checked'
                                      : ''
                                  }
                                />
                                <label
                                  htmlFor="all-appt-scheduled"
                                  data-on-label=""
                                  data-off-label=""
                                ></label>
                              </React.Fragment>
                            </span>
                          )}
                        </div>
                        <AllApptTables
                          title="All Appointments Scheduled for Date Range"
                          technicianId={technicianId && technicianId}
                          startDate={startDate}
                          endDate={endDate}
                          filterApplied={filterApplied}
                          callback={this.callback}
                          csvType="downloadCSVForAllAppt"
                        />
                      </div>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(Technicians);
