import React from 'react';
import AddDispatcher from '../Technicians/addTechnician';

const addDispatcher = () => {
  return (
    <div>
      <AddDispatcher />
    </div>
  );
};

export default addDispatcher;
