import React from 'react';

const Privacy = () => {
  return (
    <div className='page-content-wrapper'>
      <div className='container-fluid'>
            <h2 className='mx-4'>Privacy Policy</h2>
        <ul className='privacy-policy'>
          <li className='section'>
            <h5>1. Introduction</h5>
            <p>Welcome to Quality Software Solutions. We value your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.</p>
          </li>
          <li className='section'>
            <h5>. Information We Collect</h5>
            <h5>Personal Information:</h5>
            <p>Name, email address, phone number, etc., when you contact us, register on our site, or use our services.</p>
            <h5>Non-Personal Information:</h5>
            <p>Browser type, IP address, usage data, and cookies to enhance our services.</p>
          </li>
          <li className='section'>
            <h5>3. How We Use Your Information</h5>
            <ul>
              <li>To provide and maintain our services.</li>
              <li>To personalize your experience.</li>
              <li>To improve our website based on your feedback.</li>
              <li>To communicate with you, including responding to inquiries and sending promotional materials.</li>
              <li>To process transactions efficiently.</li>
            </ul>
          </li>
          <li className='section'>
            <h5>4. Information Sharing and Disclosure</h5>
            <p>We do not sell, trade, or otherwise transfer your personal information to outside parties without your consent, except:</p>
            <ul>
              <li>To trusted third parties who assist us in operating our website, conducting our business, or servicing you, so long as those parties agree to keep this information confidential.</li>
              <li>When we believe release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property, or safety.</li>
            </ul>
          </li>
          <li className='section'>
            <h5>5. Data Security</h5>
            <p>We implement various security measures to protect your personal information. These measures include physical, electronic, and administrative procedures to safeguard and secure the information we collect online. However, please note that no method of transmission over the internet, or method of electronic storage, is 100% secure.</p>
          </li>
          <li className='section'>
            <h5>6. Cookies and Tracking Technologies</h5>
            <p>Our website uses cookies and other tracking technologies to enhance your experience. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your web browser that enables the site's systems to recognize your browser and capture and remember certain information.</p>
          </li>
          <li className='section'>
            <h5>7. Third-Party Links</h5>
            <p>Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.</p>
          </li>
          <li className='section'>
            <h5>8. Children's Privacy</h5>
            <p>Our services are not directed to individuals under the age of 13. We do not knowingly collect personal information from children under 13. If we become aware that a child under 13 has provided us with personal information, we will take steps to delete such information.</p>
          </li>
          <li className='section'>
            <h5>9. Your Rights</h5>
            <p>You have the right to access, correct, update, or delete your personal information. You can do this by contacting us using the contact information provided below.</p>
          </li>
          <li className='section'>
            <h5>10. Changes to This Privacy Policy</h5>
            <p>We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "Last Updated" date at the top. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting your information.</p>
          </li>
          <li className='section'>
            <h5>11. Contact Us</h5>
            <p>If you have any questions about this Privacy Policy, please contact us at:</p>
            <p>Email: <a href='mailto:superiorhvac16@gmail.com' target='_blank'>superiorhvac16@gmail.com</a></p>
            <p>Address: US</p>
            <p>Phone: -</p>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Privacy;
