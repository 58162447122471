import React, { forwardRef, useEffect, useState } from 'react';
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

const LocationMap = withScriptjs(
  withGoogleMap(
    forwardRef((props, ref) => {
      const [directions, setDirections] = useState([]);
      const [selectedMarker, setSelectedMarker] = useState('');
      const defaultProp = {
        defaultZoom: 12,
        defaultCenter: {
          lat: 18.689131,
          lng: 73.589462,
        },
        waypoint: true,
        onlyRoute: true,
        travelMode: 'driving',
        routePolylineOptions: {
          strokeColor: '#ff2343',
          strokeOpacity: 0.8,
          strokeWeight: 4,
          clickable: true,
        },
        routeMarkerOptions: {
          icon: {
            path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
            scale: 10,
            strokeWeight: 2,
            strokeColor: '#fff',
            fillColor: '#ff2343',
            fillOpacity: 0.7,
            strokeWeight: 0.5, // eslint-disable-line
          },
          label: { color: 'white', text: '' },
        },
        InfoWindow: true,
        infowindowOptions: {},
        markerOptions: {
          icon: {
            path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
            scale: 20,
            strokeColor: 'red',
            fillColor: '#fff',
            fillOpacity: 1,
            strokeWeight: 1,
          },
          label: { color: 'black', text: ' ' },
        },
        routeMarker: [],
        placeMarker: [],
        isMarkerShown: false,
        // renderPlaceWindow: () => {},
        // renderRouteWindow: () => { }
      };
      // let delayFactor = 0;
      const finalProps = { ...defaultProp, ...props };

      // const getDirections = async (
      //   startLoc,
      //   destinationLoc,
      //   wayPoints = []
      // ) => {
      //   return new Promise((resolve, reject) => {
      //     let waypts = [];
      //     if (wayPoints.length > 0) {
      //       waypts = wayPoints;
      //     }
      //     const DirectionsService = new window.google.maps.DirectionsService();
      //     DirectionsService.route(
      //       {
      //         origin: startLoc,
      //         destination: destinationLoc,
      //         optimizeWaypoints: true,
      //         travelMode:
      //           finalProps.travelMode.toUpperCase() ||
      //           window.google.maps.TravelMode.DRIVING,
      //         waypoints: finalProps.waypoint ? waypts : [],
      //       },
      //       (result, status) => {
      //         resolve({ status, result, wayPoints });
      //       }
      //     );
      //   });
      // };

      // const getDirectionsPromise = (startLoc, destinationLoc, wayPoints) =>
      //   getDirections(startLoc, destinationLoc, wayPoints).then((response) => {
      //     if (response.status === window.google.maps.DirectionsStatus.OK) {
      //       this.setState({
      //         directions: response.result,
      //       });
      //     } else if (
      //       response.status ===
      //       window.google.maps.DirectionsStatus.OVER_QUERY_LIMIT
      //     ) {
      //       delayFactor += 0.2;
      //       if (delayFactor === 15) {
      //         delayFactor = 0.2;
      //       }
      //       setTimeout(() => {
      //         getDirectionsPromise(
      //           startLoc,
      //           destinationLoc,
      //           response.wayPoints
      //         );
      //       }, delayFactor * 200);
      //     } else {
      //       console.error(`error fetching directions ${response.result}`);
      //     }
      //   });

      const setDirectionsRoute = (places = []) => {
        let directionData = [];
        const directionsService = new window.google.maps.DirectionsService();
        for (let item of places) {
          const waypoints = item.map((p) => ({
            location: { lat: Number(p.latitude), lng: Number(p.longitude) },
            stopover: true,
          }));
          const origin =
            waypoints.length > 1
              ? waypoints.shift().location
              : waypoints[0].location;
          const destination =
            waypoints.length > 1
              ? waypoints.pop().location
              : waypoints[0].location;

          //this.getDirections(origin, destination, waypoints);
          directionsService.route(
            {
              origin: origin,
              destination: destination,
              optimizeWaypoints: true,
              travelMode:
                finalProps.travelMode.toUpperCase() ||
                window.google.maps.TravelMode.DRIVING,
              waypoints: finalProps.waypoint ? waypoints : [],
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                // var path = window.google.maps.geometry.encoding.decodePath(
                //     result.routes[0].overview_polyline,
                // );
                // directionData.push({ color: item[0].color || 'red', route: path });
                directionData.push({
                  color: item[0].color || 'red',
                  route: result,
                });
                setDirections(JSON.parse(JSON.stringify(directionData)));
              } else {
                console.error(result);
              }
            }
          );
        }
      };

      useEffect(() => {
        if (props.places && props.places.length > 0)
          setDirectionsRoute(props.places);
      }, [props.places]);

      const overrideMarkerColor = (option, color) => {
        let ops = option;
        if (Object.keys(ops).length < 1) return ops;

        ops.icon.fillColor = color ? color : ops.icon.fillColor;
        return ops;
      };

      const onToggleOpen = (e) => {
        setSelectedMarker(e);
      };

      const RenderPlaceWindow = ({ data }) => {
        try {
          let html = finalProps.renderPlaceWindow ? (
            finalProps.renderPlaceWindow(data)
          ) : (
            <p>{data.name}</p>
          );
          // return <div dangerouslySetInnerHTML={{ __html: html }} />
          return html;
        } catch (e) {
          return <p>{data.name}</p>;
        }
      };

      const RenderRouteWindow = ({ data }) => {
        try {
          let html = finalProps.renderRouteWindow ? (
            finalProps.renderRouteWindow(data)
          ) : (
            <p>{data.name}</p>
          );
          // html =  <div dangerouslySetInnerHTML={{ __html: html }} />
          return html;
        } catch (e) {
          return <p>{data.name}</p>;
        }
      };

      // const getMarkerOption = (dest, src) => {
      //   let pt = '';
      //   if (!src) return dest;

      //   dest.label = { ...dest.label, ...src.label };
      //   pt = src.icon && src.icon.path ? src.icon.path.toUpperCase() : 'CIRCLE';
      //   src.icon.path = window.google.maps.SymbolPath[pt];
      //   dest.icon = { ...dest.icon, ...src.icon };
      //   return dest;
      // };

      const getRouteMarkerOption = (dest, src) => {
        let pt = '';
        if (!src) return dest;

        dest.label = { ...dest.label, ...src.label };
        pt = src.icon && src.icon.path ? src.icon.path.toUpperCase() : 'CIRCLE';
        src.icon.path = window.google.maps.SymbolPath[pt];
        dest.icon = { ...dest.icon, ...src.icon };
        return dest;
      };

      return (
        <div className="map-wrapper">
          <GoogleMap
            id="map"
            ref={ref}
            defaultZoom={finalProps.defaultZoom}
            center={finalProps.center}
            defaultCenter={finalProps.defaultCenter}
          >
            {directions &&
              directions.map((item, i) => (
                <DirectionsRenderer
                  key={i}
                  directions={item.route}
                  options={{
                    polylineOptions: {
                      ...finalProps.routePolylineOptions,
                      strokeColor: item.color,
                    },
                    suppressMarkers: finalProps.onlyRoute,
                    preserveViewport: true,
                    markerOptions: overrideMarkerColor(
                      finalProps.routeMarkerOptions,
                      item.color
                    ),
                  }}
                />
              ))}

            {finalProps.isMarkerShown &&
              finalProps.routeMarker &&
              finalProps.routeMarker.map((item, i) => (
                <Marker
                  key={i}
                  label={
                    i === 0
                      ? 'Start'
                      : i > 0 && i !== finalProps.placeMarker.length - 1
                      ? `${i + 0}`
                      : i === finalProps.placeMarker.length
                      ? 'End'
                      : ''
                  }
                  name={item.name}
                  title={item.name}
                  position={{
                    lat:
                      props.fromComponent === 'travelreport'
                        ? Number(item.customerLatitude)
                        : Number(item.latitude),
                    lng:
                      props.fromComponent === 'travelreport'
                        ? Number(item.customerLongitude)
                        : Number(item.longitude),
                  }}
                  icon={{
                    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                    fillColor:
                      item.status === 'start'
                        ? '#21cc1f'
                        : item.status === 'end'
                        ? '#dc3545'
                        : '#17a2b8',
                    fillOpacity: 1,
                    strokeColor:
                      item.status === 'start'
                        ? '#21cc1f'
                        : item.status === 'end'
                        ? '#dc3545'
                        : '#17a2b8',
                    strokeWeight: 2,
                    scale: 2,
                    labelOrigin: new window.google.maps.Point(0, -28),
                  }}
                  onClick={() => onToggleOpen(item.name)}
                  {...getRouteMarkerOption(
                    finalProps.routeMarkerOptions,
                    item.option
                  )}
                  {...{ ...finalProps.routeMarkerOptions, ...item.option }}
                >
                  {props.fromComponent !== 'travelreport' &&
                    finalProps.InfoWindow &&
                    selectedMarker === item.name && (
                      <InfoWindow
                        onCloseClick={() => onToggleOpen('')}
                        {...finalProps.infowindowOptions}
                      >
                        <div className="info-window">
                          <RenderRouteWindow data={item} />
                        </div>
                      </InfoWindow>
                    )}
                </Marker>
              ))}
            {finalProps.isMarkerShown &&
              finalProps.placeMarker &&
              finalProps.placeMarker.map((item, i) => (
                <Marker
                  key={i}
                  label={
                    i === 0
                      ? 'Start'
                      : i > 0 && i !== finalProps.placeMarker.length - 1
                      ? `${i + 0}`
                      : i === finalProps.placeMarker.length - 1
                      ? 'End'
                      : ''
                  }
                  name={item.name}
                  title={item.name}
                  position={{
                    lat:
                      props.fromComponent === 'travelreport' && i === 0
                        ? Number(item.technicianLatitude)
                        : props.fromComponent === 'travelreport' &&
                          i > 0 &&
                          i !== finalProps.placeMarker.length
                        ? Number(item.customerLatitude)
                        : props.fromComponent === 'travelreport' &&
                          i === finalProps.placeMarker.length - 1
                        ? Number(item.technicianLatitude)
                        : Number(item.latitude),
                    lng:
                      props.fromComponent === 'travelreport' && i === 0
                        ? Number(item.technicianLongitude)
                        : props.fromComponent === 'travelreport' &&
                          i > 0 &&
                          i !== finalProps.placeMarker.length
                        ? Number(item.customerLongitude)
                        : props.fromComponent === 'travelreport' &&
                          i === finalProps.placeMarker.length - 1
                        ? Number(item.technicianLongitude)
                        : Number(item.longitude),
                  }}
                  icon={{
                    path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z M -2,-30 a 2,2 0 1,1 4,0 2,2 0 1,1 -4,0',
                    fillColor:
                      i === 0
                        ? '#21cc1f'
                        : i === finalProps.placeMarker.length - 1
                        ? '#dc3545'
                        : '#17a2b8',
                    fillOpacity: 1,
                    strokeColor:
                      i === 0
                        ? '#21cc1f'
                        : i === finalProps.placeMarker.length - 1
                        ? '#dc3545'
                        : '#17a2b8',
                    strokeWeight: 2,
                    scale: 2,
                    labelOrigin: new window.google.maps.Point(0, -28),
                  }}
                  // icon={{ ...finalProps.routeMarkerOptions.icon, ...(item.option && item.option.icon || null) }}
                  onClick={() => onToggleOpen(item.name)}
                  // {...getMarkerOption(finalProps.markerOptions, item.option)}
                  // {...{ ...finalProps.markerOptions, ...item.option }}
                >
                  {props.fromComponent !== 'travelreport' &&
                    finalProps.InfoWindow &&
                    selectedMarker === item.name && (
                      <InfoWindow
                        onCloseClick={() => onToggleOpen('')}
                        {...finalProps.infowindowOptions}
                      >
                        <div className="info-window">
                          <RenderPlaceWindow data={item} />
                        </div>
                      </InfoWindow>
                    )}
                </Marker>
              ))}
            {finalProps.children && finalProps.children}
          </GoogleMap>
        </div>
      );
    })
  )
);

export default LocationMap;
