import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import html2pdf from 'html2pdf.js';
import SelectStaticDropdown from '../../../components/ReactSelectPagination/SelectStaticDropdown';

class MonthlyExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poexpenseData: [],
      year: { name: new Date().getFullYear() },
      totalCount: '',
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    await this.getMonthlyExpense();
    await this.getTotalExpense();
  };

  getMonthlyExpense = async () => {
    const { year } = this.state;
    let endPoint = `${Api.poExpenseUrl}?year=${year.name ? year.name : ''}`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        poexpenseData: data.data,
        totalCount: Object.entries(data.data).length,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  getTotalExpense = async () => {
    const { year } = this.state;
    let endPoint = `${Api.actualIncomeExpense}?year=${
      year.name ? year.name : ''
    }`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        totalexpenseData: data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  showMsg = () => {
    swal('Nothing to open');
  };

  // exporttocsv = () => {
  //   let data = "";
  //   const tableData = [];
  //   const rows = document.querySelectorAll("table tr");
  //   for (const row of rows) {
  //     const rowData = [];
  //     for (const [index, column] of row.querySelectorAll("th, td").entries()) {
  //       // To retain the commas in the "Description" column, we can enclose those fields in quotation marks.
  //       if ((index + 1) % 3 === 0) {
  //         rowData.push('"' + column.innerText + '"');
  //       } else {
  //         rowData.push(column.innerText);
  //       }
  //     }
  //     tableData.push(rowData.join(","));
  //   }
  //   data += tableData.join("\n");
  //   const a = document.createElement("a");
  //   a.href = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
  //   a.setAttribute("download", "data.csv");
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }

  exportTableToCSV = (e, filename) => {
    e.preventDefault();
    var csv = [];
    var rows = document.querySelectorAll('table tr');

    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll('td, th');

      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);

      csv.push(row.join(','));
    }

    // Download CSV file
    this.downloadCSV(csv.join('\n'), filename);
  };

  downloadCSV = (csv, filename) => {
    var csvFile;
    var downloadLink;

    // CSV file
    csvFile = new Blob([csv], { type: 'text/csv' });

    // Download link
    downloadLink = document.createElement('a');

    // File name
    downloadLink.download = filename;

    // Create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Hide download link
    downloadLink.style.display = 'none';

    // Add the link to DOM
    document.body.appendChild(downloadLink);

    // Click download link
    downloadLink.click();
  };

  onClickDownloadPDF = async (id) => {
    var element = document.getElementById(id);

    var opt = {
      margin: 2,
      filename: 'download.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        bottom: 20,
      },
      pagebreak: { mode: 'avoid-all', after: '.targetDiv' },
      jsPDF: {
        unit: 'mm',
        orientation: 'portrait',
      },
    };
    // New Promise-based usage:
    html2pdf().from(element).set(opt).save();
  };

  render() {
    const { poexpenseData, year, totalCount, totalexpenseData, isShowLoader } =
      this.state;
    // let minOffset = 0,
    //   maxOffset = 5;
    // let thisYear = new Date().getFullYear();
    // let allYears = [];
    // for (let x = 0; x <= maxOffset; x++) {
    //   allYears.push(thisYear - x);
    // }
    // const yearList = allYears.map((x) => {
    //   return (
    //     <option key={x} value={x}>
    //       {x}
    //     </option>
    //   );
    // });
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="card m-b-20">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6">
                <form
                  className="year-bulkaction"
                  onSubmit={this.onSearchSubmit}
                >
                  <div className="form-group row">
                    <div className="col-sm-6">
                      {/* <select
                        className="form-control"
                        onChange={(e) =>
                          this.setState({ year: e.target.value }, () =>
                            this.getMonthlyExpense()
                          )
                        }
                        value={year}
                      >
                        {yearList}
                      </select> */}
                      <SelectStaticDropdown
                        onSelectChange={(e) => {
                          this.setState(
                            { year: e ? { name: e.name } : '' },
                            () => {
                              if (e) {
                                this.getMonthlyExpense();
                              }
                              if (!e) {
                                this.getMonthlyExpense();
                              }
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={false}
                        value={year}
                        isClearable={year}
                        dropdownType="years"
                        selectPlaceholder="Select Year"
                      />
                    </div>
                    <button
                      type="submit"
                      style={{ fontSize: '15px', padding: '6px 11px' }}
                      className="btn btn-primary waves-effect waves-light"
                      onClick={(e) => {
                        this.exportTableToCSV(e, 'profit-loss.csv');
                      }}
                    >
                      <i className="mdi mdi-download"></i> CSV
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-sm-6">
                <button
                  className="btn btn-primary float-right"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.onClickDownloadPDF('expPdfDownload');
                  }}
                >
                  Download PDF
                </button>
              </div>
            </div>
            <div className="poexpense">
              <table
                id="expPdfDownload"
                className="table table-bordered targetDiv"
              >
                <thead className="thead-dark">
                  <tr>
                    <th scope="col">Actual Expense</th>
                    <th scope="col">Jan</th>
                    <th scope="col">Feb</th>
                    <th scope="col">Mar</th>
                    <th scope="col">Apr</th>
                    <th scope="col">May</th>
                    <th scope="col">Jun</th>
                    <th scope="col">Jul</th>
                    <th scope="col">Aug</th>
                    <th scope="col">Sep</th>
                    <th scope="col">Oct</th>
                    <th scope="col">Nov</th>
                    <th scope="col">Dec</th>
                    <th scope="col">Year</th>
                  </tr>
                </thead>

                {Object.keys(poexpenseData).map((listitem, i) => (
                  <React.Fragment key={i}>
                    <tr className="thead-light">
                      <td
                        scope="col"
                        colSpan="14"
                        className="text-dark bg-light"
                      >
                        <h6>{listitem}</h6>
                      </td>
                    </tr>
                    {Object.keys(poexpenseData[listitem]).map((listtype, j) => (
                      <tr key={j}>
                        <td scope="col">{listtype}</td>
                        {poexpenseData[listitem][listtype].map((result, k) => (
                          <td key={k}>
                            {result.expense_amount !== 0 &&
                            listtype !== 'Total' &&
                            !listtype.includes('Payroll') ? (
                              <a
                                href="/"
                                onClick={(e) => {
                                  e.preventDefault();
                                  this.props.history.push(`/expense`, {
                                    month: result.month,
                                    year: year.name ? year.name : '',
                                    id: result.id,
                                  });
                                }}
                                style={{ color: 'black' }}
                              >
                                {Math.round(result.expense_amount)}{' '}
                              </a>
                            ) : (
                              <a
                                href="#"
                                onClick={() => {
                                  this.showMsg();
                                }}
                                style={{ color: 'black' }}
                              >
                                {Math.round(result.expense_amount)}
                              </a>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}

                <tr className="thead-light">
                  <td colSpan="14" className="text-dark bg-light">
                    &nbsp;
                  </td>
                </tr>
                {totalexpenseData &&
                  Object.keys(totalexpenseData).map((expenseData, i) => (
                    <tr key={i}>
                      <td scope="col">{expenseData}</td>
                      {totalexpenseData[expenseData].map((data, j) => (
                        <td key={j} scope="col">
                          {Math.round(data.expense_amount)}
                        </td>
                      ))}
                    </tr>
                  ))}
                {totalCount === 0 && (
                  <tr>
                    <td colSpan="14">No Record Found</td>
                  </tr>
                )}
              </table>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(MonthlyExpense);
