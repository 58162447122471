import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';

class CustomerMessagePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
    };
  }

  handleChange = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  submitMessage = async () => {
    const { message } = this.state;
    const endPoint = `${Api.workOrderCompleted}`;
    const callback = AuthApi.putDataToServer;
    const postData = {
      work_order_id: this.props.orderId,
      status: this.props.status,
      note: message && message,
      paused_reason: this.props.status,
      cust_id: this.props.customerId,
      tech_id: this.props.technicianId,
    };
    const payload = customFunctions.cleanObject(postData);
    const { data } = await callback(endPoint, payload);
    if (data && data.success) {
      this.setState({ message: '' });
      if (this.props.popupClose) this.props.popupClose();
      if (this.props.callbackfun) this.props.callbackfun();
      swal('Message sent to customer', '', 'success');
    }
  };

  render() {
    return (
      <div>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={this.props.popupClose}
          show={this.props.customerMsgBox}
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              Optional: Enter a message to the customer.
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    name="message"
                    value={this.state.message}
                    onChange={this.handleChange}
                  />
                </div>
              </>
            </Modal.Body>
            <Modal.Footer id="md-filder" className="w-100">
              <div className="float-right">
                <Button
                  variant="primary"
                  type="submit"
                  className="saveBtns l-m-10"
                  onClick={this.submitMessage}
                >
                  Pause
                </Button>
                <Button
                  variant="secondary"
                  className="saveBtns ml-2"
                  onClick={this.props.popupClose}
                >
                  Back
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CustomerMessagePopup;
