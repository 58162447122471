import React, { Component } from 'react';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import { uploadFile } from 'react-s3';
import swal from 'sweetalert';
import LocationMap from '../../../components/LocationMap/index';
import customFunctions from '../../../helper/customFunctions';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class AddTechnician extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.state = {
      technicianName: '',
      percentage: '',
      dailyRate: '',
      signature: '',
      technicianNameErr: '',
      percentageErr: '',
      dailyRateErr: '',
      signatureErr: '',
      isSubmitted: false,
      technicianId: null,
      imageFile: null,
      imageUrl: '',
      selectedLocation: '',
      latitude: '',
      longitude: '',
      email: '',
      emailErr: '',
      password: '',
      passwordErr: '',
      showPass: false,
    };
  }

  handelImageChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const fileName = e.target.value;
    const addFileName = fileName.split('\\');
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      imageFile: e.target.files[0],
      imageUrl: fileUrl,
      image: e.target.files[0],
      imageName: addFileName[addFileName.length - 1],
    });
  };

  uploadImage = async () => {
    const { imageFile } = this.state;
    if (!imageFile) return null;
    const newImage = imageFile;
    const todayDate = new Date().getTime();
    const file = new File(
      [newImage],
      `technician/${newImage.name.split('.')[0]}${todayDate}.${
        newImage.name.split('.')[1]
      }`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    return imgData;
  };

  validateForm = async (e) => {
    e.preventDefault();

    const { technicianName, email, password, latitude, longitude } = this.state;
    let technicianNameErr = '';
    let isValid = true;
    let locationErr = '',
      emailErr = '',
      passwordErr = '';

    if (
      latitude === '' ||
      longitude === '' ||
      latitude === null ||
      longitude == null
    ) {
      locationErr = 'Location is required';
      isValid = false;
    }
    if (technicianName === '') {
      technicianNameErr = 'Technician name is required';
      isValid = false;
    }
    if (
      email === '' ||
      (email !== '' && !customFunctions.validateEmail(email))
    ) {
      emailErr = 'Email is not valid';
      isValid = false;
    }
    if (password === '') {
      passwordErr = 'Password is required';
      isValid = false;
    }
    this.setState({
      technicianNameErr,
      locationErr,
      emailErr,
      passwordErr,
    });
    if (isValid) {
      this.addTechnician();
    }
  };

  getTechnician = async () => {
    const endPoint = `${Api.technicianUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        techniciandata: data.data.data,
      });
    }
  };

  addTechnician = async (e) => {
    const {
      technicianName,
      percentage,
      dailyRate,
      imageFile,
      latitude,
      longitude,
      email,
      password,
    } = this.state;
    const { getTechnicianCallback } = this.props;
    const postData = {
      technician_name: technicianName,
      percentage: percentage,
      daily_rate: dailyRate,
      latitude,
      longitude,
      email,
      password,
    };
    if (imageFile) {
      const imgUrl = await this.uploadImage();
      if (imgUrl) postData.signature = imgUrl;
    }
    const callback = AuthApi.postDataToServer;

    const endPoint = Api.AddtechnicianUrl;
    const { data } = await callback(endPoint, postData);
    if (data && data.status !== 'Fail') {
      swal('Technician added successfully', '', 'success');
      if (getTechnicianCallback) getTechnicianCallback();
      await this.flushAllData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
    } else {
      swal('please try again later', '', 'error');
    }
  };

  onMapClick = (e, marker, event) => {
    const selectedLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    this.setState({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      selectedLocation,
    });
  };

  flushAllData = async () => {
    document.getElementById('add_technician_form').reset();
    this.setState({
      technicianName: '',
      percentage: '',
      dailyRate: '',
      imageFile: '',
      imageUrl: '',
      image: '',
      imageName: '',
      email: '',
      password: '',
      emailErr: '',
      passwordErr: '',
      selectedLocation: '',
    });
  };

  render() {
    const {
      technicianName,
      percentage,
      dailyRate,
      technicianNameErr,
      imageUrl,
      selectedLocation,
      locationErr,
      email,
      password,
      emailErr,
      passwordErr,
      showPass,
    } = this.state;
    return (
      <div
        className="modal fade"
        id="technician"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={(modal) => {
          this.modalRef = modal;
        }}
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Technician
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                <form
                  onSubmit={this.validateForm}
                  id="add_technician_form"
                  autoComplete="off"
                >
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Technician Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={technicianName}
                        type="text"
                        placeholder="John"
                        id="example-text-input"
                        onChange={(e) => {
                          this.setState({ technicianName: e.target.value });
                        }}
                      />
                      <span id="err">{technicianNameErr}</span>
                    </div>
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Percentage(%){' '}
                    </label>
                    <div className="col-sm-4">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="jobTotal">
                            %
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={percentage}
                          placeholder="%"
                          id="example-text-input"
                          onChange={(e) => {
                            this.setState({ percentage: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row form-group">
                    <label
                      htmlFor="example-search-input1"
                      className="col-sm-2 col-form-label"
                    >
                      Email
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <div className="input-group">
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          autoComplete="new-email"
                          placeholder="Enter email"
                          id="example-text-input1"
                          onChange={(e) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </div>
                      <span id="err">{emailErr}</span>
                    </div>
                    <label
                      htmlFor="example-search-input2"
                      className="col-sm-2 col-form-label"
                    >
                      Password
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-4">
                      <input
                        type={`${showPass ? 'text' : 'password'}`}
                        className="form-control"
                        value={password}
                        autoComplete="new-password"
                        placeholder="Enter password"
                        id="example-text-input2"
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                      />
                      <div
                        className="password__toggle"
                        onClick={() => this.setState({ showPass: !showPass })}
                      >
                        <i
                          className={`${
                            showPass ? 'active' : 'inactive'
                          } icon icon--md icon--eye`}
                        ></i>
                        <i
                          className={`${
                            !showPass ? 'active' : 'inactive'
                          } icon icon--md icon--eye-line-through`}
                        ></i>
                      </div>
                      <span id="err">{passwordErr}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Daily Rate($){' '}
                    </label>
                    <div className="col-sm-4">
                      <div className="input-group">
                        <div className="input-group-prepend">
                          <span className="input-group-text" id="jobTotal">
                            $
                          </span>
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          value={dailyRate}
                          placeholder="$"
                          id="example-text-input"
                          onChange={(e) => {
                            const re = /^[0-9.,\b]+$/;
                            if (
                              e.target.value === '' ||
                              re.test(String(e.target.value))
                            ) {
                              this.setState({ dailyRate: e.target.value });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Signature
                    </label>
                    <div className="col-sm-4">
                      <label htmlFor="imageUpload" className="photo-upload">
                        <img src="/assets/images/camera-icon.png" alt="" />
                        <input
                          id="imageUpload"
                          type="file"
                          className="fullwidth"
                          onChange={(e) => {
                            this.handelImageChange(e);
                          }}
                          accept="image/*"
                          crossOrigin="anonymous"
                        />
                      </label>
                      {imageUrl && (
                        <img
                          id="profileImage"
                          src={imageUrl}
                          alt=""
                          height="100"
                          width="100"
                        />
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Select Location<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <LocationMap
                        location={selectedLocation}
                        onMapClick={this.onMapClick}
                        popupLocation={true}
                        callback={(loc) => {
                          let selectedLocation = {
                            lat: loc.lat,
                            lng: loc.lng,
                          };
                          this.setState({
                            latitude: loc.lat,
                            longitude: loc.lng,
                            selectedLocation,
                          });
                        }}
                      />
                      <br />
                      <span id="err">{locationErr}</span>
                    </div>
                  </div>

                  <div className="form-group row d-flex justify-content-center">
                    <div className="col-sm-4 col-md-6">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        data-dismiss="modal"
                        className="btn btn-secondary waves-effect m-l-10"
                        onClick={() => this.flushAllData()}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddTechnician;
