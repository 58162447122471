import React from 'react';
import { Stage, Layer, Rect } from 'react-konva';

const ColorList = (props) => {
  const { color_code } = props;
  return (
    <div>
      <Stage width="80" height="50">
        <Layer>
          <Rect width="60" height="40" fill={color_code} />
        </Layer>
      </Stage>
    </div>
  );
};

export default ColorList;
