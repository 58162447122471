import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import { Modal } from 'react-bootstrap';

class AddNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      note: '',
      workorderid: null,
      id: null,
      title: 'Notes',
      selectedUser: null,
      users: [],
      loginId: null,
      createdby: null,
      type: '',
      createdById: null,
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
      loginId: userdata.login_id,
      createdById: userdata.id,
    });
    if (this.props) {
      this.setState(
        {
          note:
            this.props.note && this.props.note.notes
              ? this.props.note.notes
              : '',
          noteId:
            this.props.note && this.props.note.id ? this.props.note.id : null,
          selectedUser:
            this.props.note && this.props.note.user_id
              ? this.props.note.user_id
              : null,
          createdby:
            this.props.note && this.props.note.createdby
              ? this.props.note.createdby
              : null,
          workorderid: this.props.workorderid,
          type: this.props.type,
        },
        () => {
          this.getWorkorderUsers();
        }
      );
    }
  };

  componentWillReceiveProps(props) {
    this.setState(
      {
        note: props.note && props.note.notes ? props.note.notes : '',
        noteId: props.note && props.note.id ? props.note.id : null,
        selectedUser:
          props.note && props.note.user_id ? props.note.user_id : null,
        createdby:
          props.note && props.note.createdby ? props.note.createdby : null,
        workorderid: props.workorderid,
        type: props.type,
      },
      () => {
        this.getWorkorderUsers();
      }
    );
  }

  getWorkorderUsers = async () => {
    const { workorderid } = this.state;
    let endPoint = `${Api.getWorkorderUsers}?work_order_id=${workorderid}&isactive=1`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        users: data.data,
      });
    }
  };
  addnotes = async () => {
    const { note, noteId, workorderid } = this.state;
    const postData = {
      note,
      // user_id: parseInt(selectedUser)
    };
    const endPoint = `${Api.addNotes}`;
    const callback = noteId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (noteId) {
      postData.note_id = parseInt(noteId);
      postData.status = 1;
    }
    if (!noteId) {
      postData.work_order_id = parseInt(workorderid);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (noteId) {
        swal('Note edited successfully', '', 'success');
        setTimeout(() => {
          $this.props.handleClose();
          $this.props.handleCallback();
        }, 1000);
      } else {
        swal('Note added successfully', '', 'success');
        setTimeout(() => {
          $this.props.handleClose();
          $this.props.handleCallback();
        }, 1000);
      }
    } else {
      swal(message, '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { note } = this.state;
    let notesErr = '';
    let isValid = true;
    if (note.trim() === '') {
      notesErr = 'Note is required';
      isValid = false;
    }
    this.setState({ notesErr });
    if (isValid) {
      this.addnotes();
    }
  };

  render() {
    const { note, notesErr, title, roleId, createdby, createdById, type } =
      this.state;
    return (
      <AUX>
        <Modal
          centered
          size="md"
          className="rating-popup"
          show={this.props.showNotes}
          onHide={this.props.handleClose}
        >
          <Modal.Header>
            <Modal.Title className="text-secondary">
              <h6>Note</h6>
            </Modal.Title>
            <i
              className="mdi mdi-close"
              onClick={() => this.props.handleClose()}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="page-content-wrapper" style={{ paddingTop: '0px' }}>
              <div className="container-fluid">
                <HelmetComponent title={title} />
                <div className="row">
                  {((roleId == 1 &&
                    (roleId == 5 || roleId == 6) &&
                    type == 'add') ||
                    (type == 'edit' && createdby == createdById)) && (
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Note<span className="text-danger">*</span>
                    </label>
                  )}
                  <div className="col-12">
                    <form onSubmit={this.validate} autoComplete="off">
                      {/* {(roleId == 1 || (roleId == 5 && type == 'add') || (roleId == 5 && type == 'edit' && createdby == createdById)) &&
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-2 col-form-label"
                            >
                              Select User
                            </label>
                            <div className="col-sm-8 p-b-10">
                            <select
                                  className="form-control"
                                  value={selectedUser}
                                  onChange={(e) => {
                                    this.setState({
                                      selectedUser: e.target.value,
                                    },()=>this.forceUpdate());
                                  }}
                                >
                                  <option value="">Select User</option>
                                  {users && users.filter(user =>loginId !== user.profileid).map((data) => 
                                      <option
                                        value={data.userid}
                                      >{`${data.fullname}`}</option>
                                   
                                  )}
                            </select>
                            </div>
                            <div className="col-sm-2" />
                            </div>} */}
                      <div className="form-group row">
                        <div className="col-sm-12">
                          <textarea
                            rows="3"
                            cols="80"
                            disabled={
                              !(
                                type == 'add' ||
                                (type == 'edit' && createdby == createdById)
                              )
                            }
                            className="form-control"
                            value={note}
                            onChange={(e) => {
                              this.setState({ note: e.target.value });
                            }}
                          />
                          <span id="err">{notesErr}</span>
                        </div>
                      </div>
                      {(((roleId == 1 || roleId == 5 || roleId == 6) &&
                        type == 'add') ||
                        (type == 'edit' && createdby == createdById)) && (
                        <div className="form-group row justify-content-center text-center">
                          <div className="col-sm-12">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              onClick={() => {
                                this.props.handleClose();
                              }}
                              className="btn btn-secondary waves-effect m-l-10"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
export default withRouter(AddNote);
