import React, { Component } from 'react';
import './style.css';

class PlaceSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      places: [],
      selectedPlace: '',
    };
    this.timeout = 0;
  }

  map = null;
  service = null;

  componentDidMount = async () => {
    const { google } = this.props;
    if (google && google.maps) {
      this.map = new google.maps.Map(document.getElementById('hvac-map'));
      this.service = new google.maps.places.PlacesService(this.map);
    }
  };

  searchPlace = async (placeText = '') => {
    const { disabled, inText, google } = this.props;
    if (disabled) return;
    if (placeText.trim() === '') {
      this.setState({ places: [] });
      if (this.timeout) clearTimeout(this.timeout);
      return;
    }
    if (inText) placeText = `${placeText} ${inText}`;

    var request = {
      query: placeText,
    };

    if (this.timeout) clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(async () => {
      if (!$this.service) return;
      $this.service.textSearch(request, (result, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          $this.setState({ places: result });
        } else {
          $this.setState({ places: [] });
        }
      });
    }, 200);
  };

  getPlaceDetails = async (place) => {
    const { google } = this.props;
    var request = {
      placeId: place.place_id,
      fields: [
        'name',
        'formatted_phone_number',
        'geometry',
        'address_component',
        'formatted_address',
      ],
    };
    if (!this.service) {
      this.setState({ places: [] });
      return;
    }
    this.service.getDetails(request, (place, status) => {
      if (status === google.maps.places.PlacesServiceStatus.OK) {
        this.generateAddressObject(place);
      }
    });
  };

  generateAddressObject = async (placeDetails) => {
    const { onPlaceSelect, onValueChange } = this.props;
    const formattedAddress = placeDetails.formatted_address;

    const placeAddrObj = {
      formattedAddress,
      geoLocation: placeDetails.geometry.location,
    };

    this.setState({ selectedPlace: formattedAddress, places: [] });
    if (onPlaceSelect) onPlaceSelect(placeAddrObj);
    if (onValueChange)
      onValueChange(formattedAddress, placeDetails.geometry.location);
  };

  render() {
    const { places } = this.state;
    const { classes, placeValue, disabled, onValueChange } = this.props;
    return (
      <React.Fragment>
        <div className="floating-label-feild">
          <div id="hvac-map"></div>
          <input
            value={placeValue}
            className={classes || ''}
            placeholder="Search place"
            onChange={(e) => {
              if (onValueChange) onValueChange(e.target.value);
              this.setState({ selectedPlace: e.target.value });
              this.searchPlace(e.target.value);
            }}
            disabled={disabled}
            id="hvac-place-search"
          />
          {/* <label className="form-control-placeholder" htmlFor="">{placeholder}</label> */}
          {places.length > 0 && (
            <ul className="places-container">
              {places.map((place) => (
                <li
                  className="cursor-pointer"
                  onClick={(e) => {
                    this.getPlaceDetails(place);
                  }}
                >
                  <p className="mb-0">
                    <strong>{place.name}</strong>
                  </p>
                  <small>{place.formatted_address}</small>
                  <hr />
                </li>
              ))}
            </ul>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default PlaceSearch;
