import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ReactNotificationComponent = ({ title, body, id }) => {
  let hideNotif = title === '';

  if (!hideNotif) {
    toast.info(<Display />, {
      toastId: id,
    });
  }

  function Display() {
    return (
      <div className="fcm-notify-body">
        <div>
          <h4>{title}</h4>
          <p>{body}</p>
        </div>
        <div>
          <img src="assets/images/logo2.png" width={30} height={30} alt="" />
        </div>
      </div>
    );
  }

  return (
    <ToastContainer
      autoClose={5000}
      hideProgressBar
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss={false}
      pauseOnHover={false}
      limit="1"
    />
  );
};

ReactNotificationComponent.defaultProps = {
  title: 'This is title',
  body: 'Some body',
};

ReactNotificationComponent.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
};

export default ReactNotificationComponent;
