import React, { useState } from 'react';
import { onMessageListener } from '../../init-firebase';
import ReactNotification from './ReactNotification';
import { connect } from 'react-redux';
import { SetNotification } from '../../action/user.action';

const ShowNotifications = ({ notification, setNotification }) => {
  const [show, setShow] = useState(false);
  // const [notifications, setNotification] = useState({ ...notification });

  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
        id: payload.fcmMessageId,
      });
    })
    .catch((err) => console.log('failed: ', err));

  //   const firebaseConfiguration = () =>{
  //     console.log('called');
  //         }
  // useEffect(()=>{
  //   // if(notification.body) setShow(true);
  //   setInterval(() => {
  //     firebaseConfiguration();
  //   }, 2000);
  // },[])

  return (
    <>
      {show ? (
        <ReactNotification
          title={notification.title}
          body={notification.body}
          id={notification.id}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const mapStatetoProps = (state) => ({
  notification: state.auth.notification,
});

const mapDispatchtoProps = (dispatch) => ({
  setNotification: (data) => dispatch(SetNotification(data)),
});

export default connect(mapStatetoProps, mapDispatchtoProps)(ShowNotifications);
