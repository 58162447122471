import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import AuthApi from '../../../../helper/authApi';
import Api from '../../../../helper/api';
import swal from 'sweetalert';
import customFunctions from '../../../../helper/customFunctions';
import AUX from '../../../../hoc/Aux_';
import HelmetComponent from '../../Title';
import CANCEL from '../../../../assets/images/cancel.png';
import TechnicianTravelDetails from './technicianTravelDetails';
import Loader from '../../../../components/Loader';
import { connect } from 'react-redux';
import LocationRGMap from '../../../../components/Map/reactGMapReport';

let inputChangedPromise;

class TravelReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicianData: [],
      isWriteAllowed: false,
      roleId: null,
      togglebtn: false,
      keyword: '',
      isShowLoader: false,
      technician: null,
      directions: [],
      routeMarker: [],
      placeMarker: [],
      newDirectionRoute: [],
      defaultCenter: {
        lat: 40.689167,
        lng: -111.993889,
      },
      techniciansColor: {},
    };
    this.onsearchSubmit = this.onsearchSubmit.bind(this);
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'payroll-accesspayroll'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'payroll-accesspayroll',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('Travel Report');

    this.getTechnician();

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });
  };

  getTechnician = async () => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.technicianUrl}?skip=0&limit=100`
    );
    if (data && data.success) {
      this.setState({
        technicianData: data.data.data,
      });
    } else {
      swal(message, '', 'error');
    }
  };

  handleChange = (search) => {
    this.setState({ keyword: search });
    this.onsearchSubmit(search);
  };

  onsearchSubmit = async (search) => {
    let keywordData = '';
    search = search.trim();
    if (search === this.state.keyword) {
      return;
    }
    keywordData = search;
    this.setState({ keyword: keywordData });

    if (inputChangedPromise) {
      clearTimeout(inputChangedPromise);
    }
    inputChangedPromise = setTimeout(this.getGlobalData.bind(this), 1000);
  };

  getGlobalData = async () => {
    this.setState({ isShowLoader: true });

    if (this.state.keyword === '') {
      this.setState({ isShowLoader: false }, () => this.getTechnician());
      return;
    }
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.technicianUrl}?search=${this.state.keyword}`
    );
    if (data && data.data) {
      this.setState({
        technicianData: data.data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  getColorCode = async (data = []) => {
    let code = customFunctions.getMyColor();
    if (data.includes(code)) {
      code = await this.getColorCode(data);
    }
    return code;
  };

  setDirections = async (res) => {
    // let directions = [];
    // let routeMarkerData = [];
    // let placeMarkerData = [];

    // let techLocation = res && res.length && res[0];

    // directions.push({latitude: techLocation.technicianLatitude, longitude: techLocation.technicianLongitude});

    // res && res.map(i => {
    //   directions.push({ latitude: i.customerLatitude, longitude: i.customerLongitude });
    // })
    const { techniciansColor } = this.state;
    let techniciansRoute = {};
    let routeMarkerData = [];
    let placeMarkerData = [];

    for (let item of res) {
      if (!techniciansColor[item.work_order_id]) {
        // techniciansColor[item.work_order_id] = await this.getColorCode(
        //   Object.values(techniciansColor)
        // );
        techniciansColor[item.work_order_id] = item.techcolor
          ? item.techcolor
          : '#67a8e4';
      }

      // item.shorttechname = await customFunctions.getShortName(
      //   item.technicianName
      // );
      item.color = techniciansColor[item.work_order_id];
      if (!techniciansRoute[item.work_order_id]) {
        techniciansRoute[item.work_order_id] = [];
        if (item.technicianLatitude && item.technicianLongitude) {
          techniciansRoute[item.work_order_id].push({
            latitude: item.technicianLatitude,
            longitude: item.technicianLongitude,
            color: techniciansColor[item.work_order_id],
          });

          placeMarkerData.push({
            ...item,
            latitude: item.customerLatitude,
            longitude: item.customerLongitude,
            option: {
              // label: { color: 'black', text: item.shorttechname },
              icon: {
                path: 'circle',
                strokeColor: techniciansColor[item.work_order_id],
              },
            },
          });
        }
        let markLength =
          techniciansRoute[item.work_order_id].length > 1
            ? techniciansRoute[item.work_order_id].length - 1
            : 1;
        techniciansRoute[item.work_order_id].push({
          latitude: item.customerLatitude,
          longitude: item.customerLongitude,
          color: techniciansColor[item.work_order_id],
        });
        routeMarkerData.push({
          ...item,
          option: {
            label: { color: 'white', text: markLength.toString() },
            icon: { fillColor: techniciansColor[item.work_order_id] },
          },
        });
      } else {
        techniciansRoute[item.work_order_id].push({
          latitude: item.customerLatitude,
          longitude: item.customerLongitude,
          // color: techniciansColor[item.work_order_id],
          color: item.techcolor,
        });
        let markLength =
          techniciansRoute[item.work_order_id].length > 1
            ? techniciansRoute[item.work_order_id].length - 1
            : 1;
        routeMarkerData.push({
          ...item,
          option: {
            label: { color: 'white', text: markLength.toString() },
            icon: { fillColor: techniciansColor[item.work_order_id] },
          },
        });
      }
      item = null;
    }

    res &&
      res.map((item, i) => {
        if (i === 0) {
          placeMarkerData.unshift({
            ...item,
            latitude: item.technicianLatitude,
            longitude: item.technicianLongitude,
            option: {
              // label: { color: 'black', text: item.shorttechname },
              icon: {
                path: 'circle',
                strokeColor: techniciansColor[item.work_order_id],
              },
            },
          });
        }
        return item;
      });

    // let samplePlaceData = [
    //   {
    //     CustomerName: 'Tokyo Heist',
    //     WorkOrder: 'HVAC-WO-660',
    //     WorkOrderType: 'Install',
    //     color: '#259991',
    //     customerLatitude: '32.7354787',
    //     customerLongitude: '-97.10711169999999',
    //     date_time: '1635242640000',
    //     distance: '6.51',
    //     end_time: '10:13 AM',
    //     externalDispatchId: null,
    //     id: 712,
    //     jobTitle: 'Install AC',
    //     latitude: '32.7343115',
    //     longitude: '-96.99508499999999',
    //     option: {
    //       icon: {
    //         path: 'circle',
    //         strokeColor: '#ea9958',
    //       },
    //     },
    //     start_time: '10:04 AM',
    //     status: 'start',
    //     tech_id: 41,
    //     technicianLatitude: '32.7343115',
    //     technicianLongitude: '-96.99508499999999',
    //     time: 9,
    //     work_order_id: 660,
    //   },
    //   {
    //     CustomerName: 'Tokyo Heist',
    //     WorkOrder: 'HVAC-WO-660',
    //     WorkOrderType: 'Install',
    //     color: '#259991',
    //     customerLatitude: '34.7354787',
    //     customerLongitude: '-96.10711169999999',
    //     date_time: '1635242640000',
    //     distance: '6.51',
    //     end_time: '10:13 AM',
    //     externalDispatchId: null,
    //     id: 712,
    //     jobTitle: 'Install AC',
    //     latitude: '32.7343115',
    //     longitude: '-96.99508499999999',
    //     option: {
    //       icon: {
    //         path: 'circle',
    //         strokeColor: '#ea9958',
    //       },
    //     },
    //     start_time: '10:04 AM',
    //     status: '1',
    //     tech_id: 41,
    //     technicianLatitude: '32.7343115',
    //     technicianLongitude: '-96.99508499999999',
    //     time: 9,
    //     work_order_id: 660,
    //   },
    //   {
    //     CustomerName: 'Tokyo Heist',
    //     WorkOrder: 'HVAC-WO-660',
    //     WorkOrderType: 'Install',
    //     color: '#259991',
    //     customerLatitude: '34.7354787',
    //     customerLongitude: '-95.10711169999999',
    //     date_time: '1635242640000',
    //     distance: '6.51',
    //     end_time: '10:13 AM',
    //     externalDispatchId: null,
    //     id: 712,
    //     jobTitle: 'Install AC',
    //     latitude: '32.7343115',
    //     longitude: '-96.99508499999999',
    //     option: {
    //       icon: {
    //         path: 'circle',
    //         strokeColor: '#ea9958',
    //       },
    //     },
    //     start_time: '10:04 AM',
    //     status: '2',
    //     tech_id: 41,
    //     technicianLatitude: '32.7343115',
    //     technicianLongitude: '-96.99508499999999',
    //     time: 9,
    //     work_order_id: 660,
    //   },
    //   {
    //     CustomerName: 'Tokyo Heist',
    //     WorkOrder: 'HVAC-WO-660',
    //     WorkOrderType: 'Install',
    //     color: '#259991',
    //     customerLatitude: '38.7354787',
    //     customerLongitude: '-91.10711169999999',
    //     date_time: '1635242640000',
    //     distance: '6.51',
    //     end_time: '10:13 AM',
    //     externalDispatchId: null,
    //     id: 712,
    //     jobTitle: 'Install AC',
    //     latitude: '32.7343115',
    //     longitude: '-96.99508499999999',
    //     option: {
    //       icon: {
    //         path: 'circle',
    //         strokeColor: '#ea9958',
    //       },
    //     },
    //     start_time: '10:04 AM',
    //     status: '3',
    //     tech_id: 41,
    //     technicianLatitude: '32.7343115',
    //     technicianLongitude: '-96.99508499999999',
    //     time: 9,
    //     work_order_id: 660,
    //   },
    //   {
    //     CustomerName: 'Tokyo Heist',
    //     WorkOrder: 'HVAC-WO-660',
    //     WorkOrderType: 'Install',
    //     customerLatitude: '36.7354787',
    //     customerLongitude: '-38.10711169999999',
    //     color: '#259991',
    //     date_time: '1635242640000',
    //     distance: '6.51',
    //     end_time: '10:13 AM',
    //     externalDispatchId: null,
    //     id: 712,
    //     jobTitle: 'Install AC',
    //     option: {
    //       icon: {
    //         path: 'circle',
    //         strokeColor: '#ea9958',
    //       },
    //     },
    //     start_time: '10:04 AM',
    //     status: '4',
    //     tech_id: 41,
    //     technicianLatitude: '39.7343115',
    //     technicianLongitude: '-107.99508499999999',
    //     time: 9,
    //     work_order_id: 660,
    //   },
    //   {
    //     CustomerName: 'Tokyo Heist',
    //     WorkOrder: 'HVAC-WO-660',
    //     WorkOrderType: 'Install',
    //     color: '#259991',
    //     customerLatitude: '40.7354787',
    //     customerLongitude: '-105.10711169999999',
    //     date_time: '1635242640000',
    //     distance: '6.51',
    //     end_time: '10:13 AM',
    //     externalDispatchId: null,
    //     id: 712,
    //     jobTitle: 'Install AC',
    //     latitude: '32.7343115',
    //     longitude: '-96.99508499999999',
    //     option: {
    //       icon: {
    //         path: 'circle',
    //         strokeColor: '#ea9958',
    //       },
    //     },
    //     start_time: '10:04 AM',
    //     status: 'end',
    //     tech_id: 41,
    //     technicianLatitude: '32.7343115',
    //     technicianLongitude: '-96.99508499999999',
    //     time: 9,
    //     work_order_id: 660,
    //   },
    // ];

    // let sampleDirections = [
    //   [
    //     {
    //       color: '#3309c9',
    //       latitude: '32.7343115',
    //       longitude: '-96.99508499999999',
    //     },
    //     {
    //       color: '#3309c9',
    //       latitude: '34.7354787',
    //       longitude: '-96.10711169999999',
    //     },
    //   ],
    //   [
    //     {
    //       color: '#3309c9',
    //       latitude: '34.7354787',
    //       longitude: '-96.10711169999999',
    //     },
    //     {
    //       color: '#259991',
    //       latitude: '34.7354787',
    //       longitude: '-95.10711169999999',
    //     },
    //   ],
    //   [
    //     {
    //       color: '#259991',
    //       latitude: '34.7354787',
    //       longitude: '-95.10711169999999',
    //     },
    //     {
    //       color: '#259991',
    //       latitude: '38.7354787',
    //       longitude: '-91.10711169999999',
    //     },
    //   ],
    //   [
    //     {
    //       color: '#259991',
    //       latitude: '38.7354787',
    //       longitude: '-91.10711169999999',
    //     },
    //     {
    //       color: '#259991',
    //       latitude: '36.7354787',
    //       longitude: '-90.10721169999999',
    //     },
    //   ],
    //   [
    //     {
    //       color: '#259991',
    //       latitude: '36.7354787',
    //       longitude: '-90.10721169999999',
    //     },
    //     {
    //       color: '#259991',
    //       latitude: '40.7354787',
    //       longitude: '-105.10711169999999',
    //     },
    //   ],
    // ];
    this.setState({
      directions: [...Object.values(techniciansRoute)],
      routeMarker: routeMarkerData,
      placeMarker: placeMarkerData,
    });
  };

  // setRouteDirection = async (res) => {
  //   const { techniciansColor, newDirectionRoute } = this.state;
  //   res && res.map(async (item, i) => {
  //     return item.map(async (route) => {
  //       if (!techniciansColor[i]) {
  //         techniciansColor[i] = await this.getColorCode(
  //           Object.values(techniciansColor)
  //         );
  //       }
  //       // latitude = route.start_customerLatitude
  //       // longitude = route.start_customerLongitude;
  //       route.color = techniciansColor[i];
  //     })
  //   })
  //   this.setState({ newDirectionRoute: res })
  // }

  render() {
    const {
      keyword,
      togglebtn,
      technician,
      technicianData,
      isShowLoader,
      defaultCenter,
      placeMarker,
      routeMarker,
      directions,
      activeTech,
    } = this.state;
    const { toggle } = this.props;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="Travel Report" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-md-3">
                <div className="row">
                  <div className="col-sm-12">
                    <Card>
                      <Card.Body className="tech-cardBody">
                        <div
                          className="input-wrap d-flex"
                          style={{
                            maxWidth: '200px',
                            position: 'relative',
                          }}
                        >
                          <input
                            className="form-control"
                            style={{ marginTop: '6%' }}
                            value={keyword}
                            type="text"
                            placeholder="Search here"
                            id="search-text-input"
                            onChange={(e) => this.handleChange(e.target.value)}
                          />
                          {keyword && (
                            <div
                              className="btn-close"
                              style={{ top: '21px' }}
                              onClick={() => {
                                this.setState({ keyword: '' }, () =>
                                  this.getTechnician()
                                );
                              }}
                            >
                              <img
                                style={{ maxHeight: '10px' }}
                                src={CANCEL}
                                alt=""
                              />
                            </div>
                          )}
                        </div>

                       
                        {technicianData &&
                          technicianData.map((tech, index) => (
                            <div
                              style={{
                                backgroundColor:
                                  tech.name === activeTech ? '#949191' : '#fff',
                              }}
                              key={index}
                            >
                              <ul
                                className="selector nobull"
                                style={{
                                  padding: '5px',
                                  margin: 'auto',
                                  marginTop: '5px',
                                }}
                              >
                                <li
                                  className="ul-list"
                                  onClick={() => {
                                    this.setState({
                                      togglebtn: true,
                                      technician: tech,
                                      activeTech: tech.name,
                                    });
                                  }}
                                >
                                  {tech.name}
                                  <span className="float-right">
                                    <i className="mdi mdi-chevron-right"></i>
                                  </span>
                                </li>
                              </ul>
                            </div>
                          ))}
                      </Card.Body>
                    </Card>
                 
                  </div>

                    <div
                      className="internal-sidebar"
                      style={{ padding: '0px' }}
                      >
                      {togglebtn && (
                      <TechnicianTravelDetails
                        technician={technician}
                        callback={(res) => this.setDirections(res)}
                        // routeCallbackData={(res) => this.setRouteDirection(res)}
                      />
                      )}

                      {togglebtn ? (
                      <button
                        style={{ left: toggle ? '0' : '550px' }}
                        onClick={() => this.setState({ togglebtn: false })}
                        className="btn btn-light position-absolute list-togglebtn"
                      >
                        <span className="mdi mdi-chevron-double-left"></span>
                      </button>
                    ) : (
                      <button
                        style={{ left: !toggle ? '0' : '550px' }}
                        disabled={!technician}
                        onClick={() => this.setState({ togglebtn: true })}
                        className={
                          !technician
                            ? 'btn btn-light position-absolute list-togglebtn not-cursor'
                            : 'btn btn-light position-absolute list-togglebtn'
                        }
                      >
                        <span className="mdi mdi-chevron-double-right"></span>
                      </button>
                    )}
                    </div>
                </div>
              </div>
              <div className="col-md-9 custom-map-view">
                <LocationRGMap
                  googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                  loadingElement={<div style={{ height: `100%` }} />}
                  containerElement={<div style={{ height: `100%` }} />}
                  mapElement={<div style={{ height: `100%` }} />}
                  places={directions}
                  isMarkerShown
                  placeMarker={placeMarker}
                  routeMarker={routeMarker}
                  renderRouteWindow={this.renderRouteWindow}
                  renderPlaceWindow={this.renderPlaceWindow}
                  defaultCenter={defaultCenter}
                  center={defaultCenter}
                  defaultZoom={10}
                  fromComponent="travelreport"
                />
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => ({
  toggle: state.auth.toggle,
});

export default connect(mapStateToProps, null)(TravelReportComponent);
