import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import customFunctions from '../../../helper/customFunctions';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Type',
    field: 'type',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Payroll #',
    field: 'payroll',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Date',
    field: 'date',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Payment Date',
    field: 'paymentdate',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Payroll	Amount',
    field: 'paymentamount',
    sort: 'disabled',
    width: 150,
  },
];

class TechPayrollListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      techPayroll: [],
      rows: [],
      technicianId: null,
      showDelete: false,
      page: 1,
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      id: null,
      type: '',
      ordercode: null,
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    if (
      (await this.props.match) &&
      (await this.props.match.params) &&
      (await this.props.match.params.technicianId)
    ) {
      this.setState({
        id: await this.props.match.params.technicianId,
      });
    }
    if (
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.type)
    ) {
      this.setState({
        type: await this.props.location.state.type,
      });
    }
    if (
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.orderDetails) &&
      (await this.props.location.state.orderDetails.ID)
    ) {
      this.setState({
        ordercode: await this.props.location.state.orderDetails.ID,
      });
    }
    if (
      (await this.props.match) &&
      (await this.props.match.params) &&
      (await this.props.match.params.customerId)
    ) {
      this.setState({
        order_id: await this.props.match.params.customerId,
      });
    }

    await this.getTechPayroll();
  };

  getTechPayroll = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, type, id, ordercode, order_id } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint =
      await `${Api.payrollUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;

    if (type === 'technician' && id) {
      endPoint =
        await `${Api.payrollUrl}/by_technician?technician_id=${id}&skip=${skip}&limit=${limit}&search=${keyword}`;
    } else if (type === 'orders' && ordercode !== '') {
      endPoint =
        await `${Api.payrollUrl}/by_work_order?work_id=${ordercode}&skip=${skip}&limit=${limit}&search=${keyword}`;
    } else if (order_id) {
      endPoint =
        await `${Api.payrollUrl}/by_work_order?work_id=${order_id}&skip=${skip}&limit=${limit}&search=${keyword}`;
    }

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success !== false) {
      this.setState(
        {
          techPayroll: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.setState({ isShowLoader: false });
  };

  setTableData = () => {
    const { techPayroll } = this.state;
    const rows = [];
    techPayroll &&
      techPayroll.length > 0 &&
      techPayroll.map((payroll, i) => {
        payroll.payment_date = customFunctions.getMDYDate(payroll.payment_date);
        payroll.date = customFunctions.getMDYDate(payroll.date);
        rows.push({
          type: payroll.type,
          payroll: payroll.payroll,
          date: payroll.date,
          paymentdate: payroll.payment_date,
          paymentamount: payroll.payroll_amount,
        });
        return payroll;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, limit, totalCount, currentPage, isShowLoader } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="row">
          <div className="col-12">
            <div className="card m-b-20">
              <div className="card-body mdbtable">
                <MDBDataTable
                  noBottomColumns={true}
                  paging={false}
                  bordered
                  hover
                  data={{ columns, rows }}
                  searching={false}
                  noRecordsFoundLabel="No records found"
                  scrollX
                  maxHeight="350px"
                />
                {totalCount > 10 && (
                  <div className="pagination">
                    <Pagination
                      totalCount={totalCount}
                      currentPage={currentPage}
                      limit={limit}
                      getData={this.getTechPayroll}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(TechPayrollListing);
