/* eslint-disable */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import { Button, Row, Col, Card } from 'react-bootstrap';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import PhoneInput from '../../../components/phoneInput/phoneInput.component';
import SignatureCanvas from 'react-signature-canvas';
import Loader from '../../../components/Loader';

class PreventativeMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Preventative Maintenance',
      serviceplans: { airConditioning: '', heating: '' },
      selectedOption: null,
      selectedOptionErr: '',
      customersData: [],
      date: new Date(),
      street: '',
      city_state_zip: '',
      homePhone: '',
      secondPhone: '',
      contractor: '',
      selectedTechnicainOption: null,
      technicainData: [],
      units: [
        {
          make: { outdoor: '', indoor: '', indoorUnit: '' },
          model: { outdoor: '', indoor: '', indoorUnit: '' },
          serial: { outdoor: '', indoor: '', indoorUnit: '' },
          checkThermostat: { outdoor: '', indoor: '' },
          checkFilter: { outdoor: '', indoor: '' },
          pressures: { outdoor: { suction: '' }, indoor: { head: '' } },
          compressorAmp: { outdoor: { rating: '' }, indoor: { actual: '' } },
          compressorCapacitor: {
            outdoor: { rating: '' },
            indoor: { actual: '' },
          },
          condMotorAmp: { outdoor: { rating: '' }, indoor: { actual: '' } },
          condMotorCapacitor: {
            outdoor: { rating: '' },
            indoor: { actual: '' },
          },
          checkContactor: { outdoor: '', indoor: '' },
          cleanCondCoilWater: { outdoor: '', indoor: '' },
          checkElectConnections: { outdoor: '', indoor: '' },
          blowerMotorAmp: { outdoor: { rating: '' }, indoor: { actual: '' } },
          blowerMotorCapacitor: {
            outdoor: { rating: '' },
            indoor: { actual: '' },
          },
          checkEvaporatorCoil: { outdoor: '', indoor: '' },
          checkCondensateLine: { outdoor: '', indoor: '' },
          inspectHeatExchanger: { outdoor: '', indoor: '' },
          inspectPilotAssembly: { outdoor: '', indoor: '' },
          cleanBurners: { outdoor: '', indoor: '' },
          testSafetySwitches: { outdoor: '', indoor: '' },
          testLimitSwitches: { outdoor: '', indoor: '' },
          testHeatStrips: { outdoor: '', indoor: '' },
          testDefrostCycle: { outdoor: '', indoor: '' },
        },
      ],
      recommendations: '',
      signUrl: null,
      customer_sign: '',
      customerSignDate: new Date(),
      roleId: '',
      premaintenanceid: null,
      disabled: false,
      customerId: '',
      preventativeType: '',
      contractorErr: '',
      work_order_id: '',
      isShowLoader: false,
    };
  }
  sigPad = {};

  saveSign = () => {
    this.setState(
      {
        signUrl: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
      },
      () => {
        // swal('Sign saved!', '', 'success');
        this.handleClick();
      }
    );
  };

  clearSign = () => {
    this.sigPad.clear();
  };

  componentDidMount = async () => {
    let fromComponent = this.props.fromComponent || '';
    if (!fromComponent) customFunctions.setTitle(this.state.title);
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    const customerId = this.props.customerId;
    const premaintenceId = this.props.premaintenceId;
    const work_order_id = this.props.work_order_id;

    if (this.props && this.props.pretype) {
      this.setState({
        preventativeType: this.props.pretype,
      });
    }
    this.setState(
      {
        roleId: userdata.role_id,
        login_id: userdata.login_id,
        customerId,
        premaintenceId,
        work_order_id,
        fromComponent,
      },
      async () => {
        await this.getCustomers();
        await this.getTechnicianData();
        await this.patchTechnician();
        this.getCustomersDetailsData(customerId);

        if (this.props.work_order_id) {
          await this.getPreventativeData();
        }
        if (
          this.props &&
          this.props.history &&
          this.props.history.location &&
          this.props.history.location.state &&
          this.props.history.location.state.work_order_id
        ) {
          this.setState({
            work_order_id: this.props.history.location.state.work_order_id,
          });
          await this.getPreventativeData();
        }
        if (userdata.role_id == 4) {
          this.setState({ disabled: true });
        }
      }
    );
  };

  componentWillReceiveProps(props) {
    if (props.customerId !== this.props.customerId) {
      this.setState(
        {
          customerId: props.customerId,
          work_order_id: props.work_order_id && props.work_order_id,
        },
        async () => {
          await this.getCustomers();
          await this.getTechnicianData();
          this.getCustomersDetailsData(props.customerId);
        }
      );
    }
  }

  patchTechnician = () => {
    if (this.props && this.props.multitech) {
      this.setState({
        selectedTechnicainOption:
          this.state.technicainData &&
          this.state.technicainData.filter(
            (i) => i.value == this.props.multitech[0].tech_id
          ),
      });
    } else if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.multitech
    ) {
      const tech = {
        label: this.props.location.state.multitech.label,
        value: this.props.location.state.multitech.value,
      };
      this.setState({
        selectedTechnicainOption: tech,
      });
    }
  };

  getPreventativeData = async () => {
    const { customerId, work_order_id } = this.state;
    this.setState({ isShowLoader: true });
    let endPoint = `${Api.getPremaintenance}?`;
    let index = this.props && this.props.index;
    if (customerId) {
      endPoint += `work_order_id=${work_order_id}`;
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data[index]) {
      this.setState(
        {
          isShowLoader: false,
          date:
            data.data.data[index].date && new Date(data.data.data[index].date),
          contractor: data.data.data[index].contractor,
          recommendations: data.data.data[index].notes,
          selectedTechnicainOption:
            this.state.technicainData &&
            this.state.technicainData.filter(
              (i) => i.value == data.data.data[index].tech_id
            ),
          selectedOption:
            this.state.customersData &&
            this.state.customersData.filter(
              (i) => i.value == data.data.data[index].customer_id
            ),
          premaintenanceid: data.data.data[index].id,
          preventativeType: data.data.data[index].type,
          serviceplans:
            data.data.data[index].service_plans &&
            JSON.parse(data.data.data[index].service_plans),
          units:
            data.data.data[index].checklist &&
            JSON.parse(data.data.data[index].checklist),
          customer_sign:
            data.data.data[index].customer_sign !== null &&
            JSON.parse(data.data.data[index].customer_sign),
        },
        () => {
          this.setState({
            selectedTechnicainOption:
              this.state.technicainData &&
              this.state.technicainData.filter(
                (i) => i.value == data.data.data[0].tech_id
              ),
          });
          this.getCustomersDetailsData(data.data.data[index].customer_id);
          if (
            this.state.customer_sign &&
            Object.keys(this.state.customer_sign).length
          ) {
            this.setState({ disabled: true });
          }
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
    }
  };

  getCustomers = async () => {
    let endPoint = `${Api.customerUrl}?`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ isShowLoader: false });
      const customerOptions = [];
      data.data.data &&
        data.data.data.map((customer) => {
          customerOptions.push({
            value: customer.ID,
            label: `${customer.CustomerName}`,
          });
          return customer;
        });
      this.setState({ customersData: customerOptions });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  components = {
    DropdownIndicator: null,
  };

  handleCustomerChange = (selectedOption) => {
    this.setState({ selectedOption: [selectedOption] }, () => {
      this.getCustomersDetailsData(selectedOption.value);
    });
  };

  getCustomersDetailsData = async (customerId) => {
    this.setState({ isShowLoader: true });
    let endPoint = `${Api.getCustomerById}?customer_id=${customerId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        isShowLoader: false,
        CustomerName: data.data.CustomerName,
        city_state_zip: `${data.data.City1 ? data.data.City1 + ', ' : ''}${
          data.data.State1 ? data.data.State1 + ', ' : ''
        }${data.data.Zip1 ? data.data.Zip1 : ''}`,
        street: data.data.AddressLine1_1,
        // date: data.data.Created && new Date(data.data.Created),
        homePhone: data.data.PhoneNumber1,
        secondPhone: data.data.PhoneNumber2,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  getTechnicianData = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.technicianUrl}?skip=0&limit=1000`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data) {
      this.setState({ isShowLoader: false });
      const technicianOption = [];

      data &&
        data.data &&
        data.data.data.map((tech) => {
          technicianOption.push({
            value: tech.id,
            label: tech.name,
          });
          return tech;
        });
      this.setState(
        {
          technicainData: technicianOption,
        },
        () => {
          if (this.state.roleId == 5 || this.state.roleId == 6) {
            let selectedTechnicainOption =
              technicianOption &&
              technicianOption.filter((i) => i.value == this.state.login_id);
            this.setState({ selectedTechnicainOption });
          }
        }
      );
    } else {
      this.setState({ isShowLoader: false });
    }
  };

  handleTechnicianChange = (selectedTechnicainOption) => {
    this.setState({ selectedTechnicainOption: [selectedTechnicainOption] });
  };

  addUnit = () => {
    const { units } = this.state;
    if (units.length < 3) {
      units.push({
        make: { outdoor: '', indoor: '', indoorUnit: '' },
        model: { outdoor: '', indoor: '', indoorUnit: '' },
        serial: { outdoor: '', indoor: '', indoorUnit: '' },
        checkThermostat: { outdoor: '', indoor: '' },
        checkFilter: { outdoor: '', indoor: '' },
        pressures: { outdoor: { suction: '' }, indoor: { head: '' } },
        compressorAmp: { outdoor: { rating: '' }, indoor: { actual: '' } },
        compressorCapacitor: {
          outdoor: { rating: '' },
          indoor: { actual: '' },
        },
        condMotorAmp: { outdoor: { rating: '' }, indoor: { actual: '' } },
        condMotorCapacitor: { outdoor: { rating: '' }, indoor: { actual: '' } },
        checkContactor: { outdoor: '', indoor: '' },
        cleanCondCoilWater: { outdoor: '', indoor: '' },
        checkElectConnections: { outdoor: '', indoor: '' },
        blowerMotorAmp: { outdoor: { rating: '' }, indoor: { actual: '' } },
        blowerMotorCapacitor: {
          outdoor: { rating: '' },
          indoor: { actual: '' },
        },
        checkEvaporatorCoil: { outdoor: '', indoor: '' },
        checkCondensateLine: { outdoor: '', indoor: '' },
        inspectHeatExchanger: { outdoor: '', indoor: '' },
        inspectPilotAssembly: { outdoor: '', indoor: '' },
        cleanBurners: { outdoor: '', indoor: '' },
        testSafetySwitches: { outdoor: '', indoor: '' },
        testLimitSwitches: { outdoor: '', indoor: '' },
        testHeatStrips: { outdoor: '', indoor: '' },
        testDefrostCycle: { outdoor: '', indoor: '' },
      });
    }
    this.setState({ units });
  };

  removeUnits = (id) => {
    const { units } = this.state;
    units.splice(id, 1);
    this.setState({ units });
  };

  handleClick = async (e) => {
    if (e) e.preventDefault();
    const {
      serviceplans,
      date,
      selectedTechnicainOption,
      units,
      recommendations,
      signUrl,
      customerSignDate,
      roleId,
      premaintenanceid,
      customer_sign,
      customerId,
      preventativeType,
      work_order_id,
    } = this.state;
    let isValid = true;
    let selectedOptionErr,
      contractorErr,
      selectedTechOptionErr,
      preventativeTypeErr,
      servicePlanErr;
    // if (!selectedOption) {
    //   selectedOptionErr = 'Please select Customer';
    //   isValid = false;
    // }
    if (roleId !== 4) {
      units.map((unit) => {
        if (Object.values(unit.make).filter((i) => i).length == 0) {
          unit['makeErr'] = 'Please enter atleast one make';
          isValid = false;
        } else {
          unit['makeErr'] = '';
          isValid = true;
        }
        if (Object.values(unit.model).filter((i) => i).length == 0) {
          unit['modelErr'] = 'Please enter atleast one model';
          isValid = false;
        } else {
          unit['modelErr'] = '';
          isValid = true;
        }
        if (Object.values(unit.serial).filter((i) => i).length == 0) {
          unit['serialErr'] = 'Please enter atleast one serial';
          isValid = false;
        } else {
          unit['serialErr'] = '';
          isValid = true;
        }
      });

      if (!Object.values(serviceplans).includes(true)) {
        servicePlanErr = 'Select Atleast one Service plan';
        isValid = false;
      }
      if (!preventativeType) {
        preventativeTypeErr = 'Please select type';
        isValid = false;
      }
      // if (!contractor) {
      //   contractorErr = 'Please select Contractor';
      //   isValid = false;
      // }
      if (!selectedTechnicainOption) {
        selectedTechOptionErr = 'Please select customer';
        isValid = false;
      }
    }

    this.setState({
      selectedTechOptionErr,
      // contractorErr,
      preventativeTypeErr,
      servicePlanErr,
    });

    if (isValid) {
      units.map((i) => {
        delete i.makeErr;
        delete i.modelErr;
        delete i.serialErr;
      });

      this.setState({
        isShowLoader: true,
      });
      let postData = {
        service_plans: JSON.stringify(serviceplans),
        // customer_id:
        //   selectedOption && selectedOption[0] && selectedOption[0].value,
        customer_id: customerId,
        date: date && new Date(date).toISOString(),
        // contractor,
        tech_id:
          selectedTechnicainOption && selectedTechnicainOption[0]
            ? selectedTechnicainOption[0].value
            : '',
        notes: recommendations,
        checklist: JSON.stringify(units),
        type: preventativeType,
      };

      let sign = {};
      if (signUrl !== null) {
        sign.signature_url = signUrl;
        sign.signature_date = new Date();
      }
      if (roleId == 4) {
        postData.customer_sign =
          Object.keys(customer_sign).length !== 0
            ? JSON.stringify(customer_sign)
            : JSON.stringify(sign);
        postData.sign_date = new Date(customerSignDate).toISOString();
      }
      if (premaintenanceid) {
        postData.premaintenanceid = premaintenanceid;
      }
      if (premaintenanceid == null) {
        postData.work_order_id = parseInt(work_order_id);
      }

      const callback = premaintenanceid
        ? AuthApi.putDataToServer
        : AuthApi.postDataToServer;

      const endPoint = Api.addPremaintenance;
      const { data, message } = await callback(endPoint, postData);
      if (premaintenanceid && data && data.success) {
        this.setState({
          isShowLoader: false,
        });
        swal('Updated successfully', '', 'success').then(() => {
          this.props.history.push(`/update-order/${work_order_id}`);
        });
      }
      if (!premaintenanceid && data && data.success) {
        this.setState({
          isShowLoader: false,
        });
        swal('Created successfully', '', 'success').then(() => {
          this.props.history.push(`/update-order/${work_order_id}`);
        });
      }
      this.setState({
        isShowLoader: false,
      });
    }
  };
  render() {
    const {
      title,
      serviceplans,
      date,
      street,
      city_state_zip,
      homePhone,
      secondPhone,
      // contractor,
      selectedTechnicainOption,
      technicainData,
      units,
      recommendations,
      customerSignDate,
      customer_sign,
      roleId,
      disabled,
      premaintenanceid,
      CustomerName,
      preventativeType,
      // contractorErr,
      selectedTechOptionErr,
      preventativeTypeErr,
      servicePlanErr,
      isShowLoader,
    } = this.state;
    return (
      <AUX>
        <div className="">
          <div className="">
            {!this.props.fromComponent && <HelmetComponent title={title} />}
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
              <div className="row">
                <div className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="row">
                        <label className="col-sm-2 col-form-label">
                          SERVICE PLANS
                        </label>
                        <Col md={4}>
                          <div className="row">
                            <div className="col-sm-2">
                              <input
                                className="form-control checkbox"
                                checked={serviceplans.airConditioning}
                                value={serviceplans.airConditioning}
                                type="checkbox"
                                id="example-checkbox-input1"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    serviceplans.airConditioning = true;
                                  } else {
                                    serviceplans.airConditioning = false;
                                  }
                                  this.setState({
                                    serviceplans,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </div>
                            <label
                              htmlFor="example-checkbox-input1"
                              className="col-sm-10 col-form-label"
                            >
                              Air Conditioning
                            </label>
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="form-group row">
                            <div className="col-sm-2">
                              <input
                                className="form-control checkbox"
                                checked={serviceplans.heating}
                                value={serviceplans.heating}
                                type="checkbox"
                                id="example-checkbox-input2"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    serviceplans.heating = true;
                                  } else {
                                    serviceplans.heating = false;
                                  }
                                  this.setState({
                                    serviceplans,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </div>
                            <label
                              htmlFor="example-checkbox-input2"
                              className="col-sm-10 col-form-label"
                            >
                              Heating
                            </label>
                            <span
                              id="err"
                              style={{ fontSize: '13px', fontWeight: 600 }}
                            >
                              {servicePlanErr}
                            </span>
                          </div>
                        </Col>
                        <Col md={2}>
                          <div className="select-wrap-pre">
                            <select
                              className="form-control"
                              value={preventativeType}
                              disabled={
                                roleId === 4 ||
                                disabled ||
                                (this.props && this.props.pretype)
                              }
                              onChange={async (e) => {
                                this.setState({
                                  preventativeType: e.target.value,
                                });
                              }}
                            >
                              <option>Select Type</option>
                              <option value="spring">Spring</option>
                              <option value="fall">Fall</option>
                            </select>
                          </div>
                          <span
                            id="err"
                            style={{ fontSize: '13px', fontWeight: 600 }}
                          >
                            {preventativeTypeErr}
                          </span>
                        </Col>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">Name</label>
                        <Col md={4}>
                          {/* <ReactSelect
                            components={this.components}
                            placeholder="Select Customer"
                            value={selectedOption}
                            onChange={this.handleCustomerChange}
                            options={customersData}
                            isDisabled={premaintenanceid ? true: false}
                          /> */}
                          <input
                            placeholder="Select Customer"
                            value={CustomerName}
                            className="form-control"
                            disabled
                          />
                          {/* <span id="err">{selectedOptionErr}</span> */}
                        </Col>
                        <label className="col-sm-2 col-form-label">Date</label>
                        <Col md={4}>
                          <DatePickerComponent
                            selected={date}
                            onChange={(date) =>
                              this.setState({
                                date: date,
                              })
                            }
                            dateFormat="MM/dd/yyyy"
                            startDate={date}
                            className="form-control col-sm-12"
                            disabled={disabled}
                          />
                        </Col>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Street
                        </label>
                        <Col md={4}>
                          <input
                            type="text"
                            className="form-control col-sm-12"
                            placeholder="Street"
                            value={street}
                            id="street"
                            onChange={(e) => {
                              this.setState({
                                street: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Col>
                        <label className="col-sm-2 col-form-label">
                          City/State/Zip
                        </label>
                        <Col md={4}>
                          <input
                            type="text"
                            className="form-control col-sm-12"
                            placeholder="City"
                            value={city_state_zip}
                            id="city_state_zip"
                            onChange={(e) => {
                              this.setState({
                                city_state_zip: e.target.value,
                              });
                            }}
                            disabled
                          />
                        </Col>
                      </div>
                      <div className="row prev">
                        <label
                          className="col-sm-2"
                          style={{ display: 'inline' }}
                        >
                          Home Phone
                        </label>
                        <Col md={4}>
                          <PhoneInput
                            name="homePhone"
                            country="us"
                            disabled
                            onlyCountries={['in', 'us']}
                            placeholder="Home Phone"
                            className="w-50"
                            value={homePhone}
                            onInputChange={(e) =>
                              this.setState({ homePhone: e })
                            }
                          />
                        </Col>
                        <label className="col-sm-2 col-form-label">
                          Second Phone
                        </label>
                        <Col md={4}>
                          <PhoneInput
                            name="secondPhone"
                            country="us"
                            disabled
                            onlyCountries={['in', 'us']}
                            className="col-sm-10"
                            placeholder="Second Phone"
                            value={secondPhone}
                            onInputChange={(e) =>
                              this.setState({ secondPhone: e })
                            }
                          />
                        </Col>
                      </div>
                      <div className="form-group row">
                        {/* <label
                          className="col-sm-1 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Contractor
                        </label>
                        <Col md={5}>
                          <input
                            type="text"
                            className="form-control col-sm-12"
                            value={contractor}
                            placeholder="Contractor"
                            id="contractor"
                            onChange={(e) => {
                              this.setState({
                                contractor: e.target.value,
                              });
                            }}
                            disabled={disabled}
                          />
                          <span id="err">{contractorErr}</span>
                        </Col> */}
                        <label
                          className="col-sm-2 col-form-label"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          Technician
                        </label>
                        <Col md={4}>
                          <ReactSelect
                            components={this.components}
                            placeholder="Select Technician"
                            value={selectedTechnicainOption}
                            onChange={this.handleTechnicianChange}
                            options={technicainData}
                            isDisabled={
                              this.state.roleId === 1 ||
                              this.state.roleId == 5 ||
                              this.state.roleId == 6
                            }
                          />
                          <span
                            id="err"
                            style={{ fontSize: '13px', fontWeight: 600 }}
                          >
                            {selectedTechOptionErr}
                          </span>
                        </Col>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              {units &&
                units.map((unit, index) => (
                  <div className="row">
                    <div className="col-12">
                      <Card>
                        <Card.Header>
                          <b>UNIT #{index + 1}</b>
                          {roleId !== 4 && (
                            <i
                              className="icon-container mdi mdi-close"
                              style={{
                                cursor: 'pointer',
                                fontSize: '22px',
                                float: 'right',
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.removeUnits(index);
                              }}
                            />
                          )}
                        </Card.Header>
                        <Card.Body>
                          <div className="row">
                            <label className="col-sm-3 col-form-label"></label>
                            <label
                              className="col-sm-3"
                              style={{ fontSize: '18px!important' }}
                            >
                              Outdoor
                            </label>
                            <label
                              className="col-sm-4"
                              style={{ fontSize: '18px!important' }}
                            >
                              Indoor
                            </label>
                          </div>
                          <div className="row">
                            <label className="col-sm-3 col-form-label">
                              Make
                            </label>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Make outdoor"
                                value={unit.make.outdoor}
                                id="unitmakeoutdoor"
                                onChange={(e) => {
                                  unit.make.outdoor = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                              {
                                <span id="err">
                                  {unit.makeErr && unit.makeErr}
                                </span>
                              }
                            </label>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Make indoor"
                                value={unit.make.indoor}
                                id="unitmakeindoor"
                                onChange={(e) => {
                                  unit.make.indoor = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </label>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Make indoor unit"
                                value={unit.make.indoorUnit}
                                id="unitmakeindoorUnit"
                                onChange={(e) => {
                                  unit.make.indoorUnit = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </label>
                          </div>
                          <div className="row">
                            <label className="col-sm-3 col-form-label">
                              Model
                            </label>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Model outdoor"
                                value={unit.model.outdoor}
                                id="unitmodeloutdoor"
                                onChange={(e) => {
                                  unit.model.outdoor = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                              {
                                <span id="err">
                                  {unit.modelErr && unit.modelErr}
                                </span>
                              }
                            </label>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Model indoor"
                                value={unit.model.indoor}
                                id="unitmodelindoor"
                                onChange={(e) => {
                                  unit.model.indoor = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </label>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Model indoor unit"
                                value={unit.model.indoorUnit}
                                id="unitmodelindoorUnit"
                                onChange={(e) => {
                                  unit.model.indoorUnit = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </label>
                          </div>
                          <div className="row">
                            <label className="col-sm-3 col-form-label">
                              Serial
                            </label>
                            <div className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Serial outdoor"
                                value={unit.serial.outdoor}
                                id="unitserialoutdoor"
                                onChange={(e) => {
                                  unit.serial.outdoor = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                              {
                                <span
                                  id="err"
                                  style={{ fontSize: '13px', fontWeight: 600 }}
                                >
                                  {unit.serialErr && unit.serialErr}
                                </span>
                              }
                            </div>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Serial indoor"
                                value={unit.serial.indoor}
                                id="unitserialindoor"
                                onChange={(e) => {
                                  unit.serial.indoor = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </label>
                            <label className="col-sm-3">
                              <input
                                type="text"
                                className="form-control col-sm-12"
                                placeholder="Serial indoor unit"
                                value={unit.serial.indoorUnit}
                                id="unitserialindoorUnit"
                                onChange={(e) => {
                                  unit.serial.indoorUnit = e.target.value;
                                  this.setState({
                                    units,
                                  });
                                }}
                                disabled={disabled}
                              />
                            </label>
                          </div>
                          <hr />
                          <div className="row">
                            <label className="col-sm-4 col-form-label">
                              Check Thermostat
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkThermostat.outdoor}
                                    value={unit.checkThermostat.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkThermostat.outdoor = true;
                                      } else {
                                        unit.checkThermostat.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkThermostat.indoor}
                                    value={unit.checkThermostat.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkThermostat.indoor = true;
                                      } else {
                                        unit.checkThermostat.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-sm-4 col-form-label">
                              Check Filter
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkFilter.outdoor}
                                    value={unit.checkFilter.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkFilter.outdoor = true;
                                      } else {
                                        unit.checkFilter.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkFilter.indoor}
                                    value={unit.checkFilter.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkFilter.indoor = true;
                                      } else {
                                        unit.checkFilter.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Pressures
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  SUCTION
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.pressures.outdoor.suction}
                                  id="suction"
                                  onChange={(e) => {
                                    unit.pressures.outdoor.suction =
                                      e.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  HEAD
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.pressures.outdoor.head}
                                  id="head"
                                  onChange={(e) => {
                                    unit.pressures.outdoor.head =
                                      e.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Compressor Amp
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  RATING
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.compressorAmp.outdoor.rating}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.compressorAmp.outdoor.rating =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  ACTUAL
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.compressorAmp.indoor.actual}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.compressorAmp.indoor.actual =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Compressor Capacitor
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  RATING
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={
                                    unit.compressorCapacitor.outdoor.rating
                                  }
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.compressorCapacitor.outdoor.rating =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  ACTUAL
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.compressorCapacitor.indoor.actual}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.compressorCapacitor.indoor.actual =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Cond. Motor Amp
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  RATING
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.condMotorAmp.outdoor.rating}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.condMotorAmp.outdoor.rating =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  ACTUAL
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.condMotorAmp.indoor.actual}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.condMotorAmp.indoor.actual =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Cond. Motor Capacitor
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  RATING
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.condMotorCapacitor.outdoor.rating}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.condMotorCapacitor.outdoor.rating =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  ACTUAL
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.condMotorCapacitor.indoor.actual}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.condMotorCapacitor.indoor.actual =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Check Contactor
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkContactor.outdoor}
                                    value={unit.checkContactor.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkContactor.outdoor = true;
                                      } else {
                                        unit.checkContactor.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkContactor.indoor}
                                    value={unit.checkContactor.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkContactor.indoor = true;
                                      } else {
                                        unit.checkContactor.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Clean Cond. Coil (Water)
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.cleanCondCoilWater.outdoor}
                                    value={unit.cleanCondCoilWater.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.cleanCondCoilWater.outdoor = true;
                                      } else {
                                        unit.cleanCondCoilWater.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.cleanCondCoilWater.indoor}
                                    value={unit.cleanCondCoilWater.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.cleanCondCoilWater.indoor = true;
                                      } else {
                                        unit.cleanCondCoilWater.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Check Elect. Connections{' '}
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkElectConnections.outdoor}
                                    value={unit.checkElectConnections.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkElectConnections.outdoor = true;
                                      } else {
                                        unit.checkElectConnections.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkElectConnections.indoor}
                                    value={unit.checkElectConnections.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkElectConnections.indoor = true;
                                      } else {
                                        unit.checkElectConnections.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Blower Motor Amp
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  RATING
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.blowerMotorAmp.outdoor.rating}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.blowerMotorAmp.outdoor.rating =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  ACTUAL
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={unit.blowerMotorAmp.indoor.actual}
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.blowerMotorAmp.indoor.actual =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Blower Motor Capacitor
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  RATING
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={
                                    unit.blowerMotorCapacitor.outdoor.rating
                                  }
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.blowerMotorCapacitor.outdoor.rating =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <label className="col-sm-4 col-form-label">
                                  ACTUAL
                                </label>
                                <input
                                  type="text"
                                  className="form-control col-sm-7"
                                  value={
                                    unit.blowerMotorCapacitor.indoor.actual
                                  }
                                  id="suction"
                                  onChange={(e) => {
                                    let event = e;
                                    event.target.value =
                                      customFunctions.numberMasking(
                                        e.target.value
                                      );
                                    unit.blowerMotorCapacitor.indoor.actual =
                                      event.target.value;
                                    this.setState({
                                      unit,
                                    });
                                  }}
                                  disabled={disabled}
                                />
                                <label className="col-sm-1"></label>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Check Evaporator Coil
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkEvaporatorCoil.outdoor}
                                    value={unit.checkEvaporatorCoil.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkEvaporatorCoil.outdoor = true;
                                      } else {
                                        unit.checkEvaporatorCoil.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkEvaporatorCoil.indoor}
                                    value={unit.checkEvaporatorCoil.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkEvaporatorCoil.indoor = true;
                                      } else {
                                        unit.checkEvaporatorCoil.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Check Condensate Line
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkCondensateLine.outdoor}
                                    value={unit.checkCondensateLine.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkCondensateLine.outdoor = true;
                                      } else {
                                        unit.checkCondensateLine.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.checkCondensateLine.indoor}
                                    value={unit.checkCondensateLine.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.checkCondensateLine.indoor = true;
                                      } else {
                                        unit.checkCondensateLine.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Inspect Heat Exchanger
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.inspectHeatExchanger.outdoor}
                                    value={unit.inspectHeatExchanger.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.inspectHeatExchanger.outdoor = true;
                                      } else {
                                        unit.inspectHeatExchanger.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.inspectHeatExchanger.indoor}
                                    value={unit.inspectHeatExchanger.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.inspectHeatExchanger.indoor = true;
                                      } else {
                                        unit.inspectHeatExchanger.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Inspect Pilot Assembly
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.inspectPilotAssembly.outdoor}
                                    value={unit.inspectPilotAssembly.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.inspectPilotAssembly.outdoor = true;
                                      } else {
                                        unit.inspectPilotAssembly.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.inspectPilotAssembly.indoor}
                                    value={unit.inspectPilotAssembly.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.inspectPilotAssembly.indoor = true;
                                      } else {
                                        unit.inspectPilotAssembly.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Clean Burners
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.cleanBurners.outdoor}
                                    value={unit.cleanBurners.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.cleanBurners.outdoor = true;
                                      } else {
                                        unit.cleanBurners.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.cleanBurners.indoor}
                                    value={unit.cleanBurners.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.cleanBurners.indoor = true;
                                      } else {
                                        unit.cleanBurners.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Test Safety Switches
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testSafetySwitches.outdoor}
                                    value={unit.testSafetySwitches.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testSafetySwitches.outdoor = true;
                                      } else {
                                        unit.testSafetySwitches.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testSafetySwitches.indoor}
                                    value={unit.testSafetySwitches.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testSafetySwitches.indoor = true;
                                      } else {
                                        unit.testSafetySwitches.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Test Limit Switches
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testLimitSwitches.outdoor}
                                    value={unit.testLimitSwitches.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testLimitSwitches.outdoor = true;
                                      } else {
                                        unit.testLimitSwitches.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testLimitSwitches.indoor}
                                    value={unit.testLimitSwitches.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testLimitSwitches.indoor = true;
                                      } else {
                                        unit.testLimitSwitches.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Test Heat Strips
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testHeatStrips.outdoor}
                                    value={unit.testHeatStrips.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testHeatStrips.outdoor = true;
                                      } else {
                                        unit.testHeatStrips.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testHeatStrips.indoor}
                                    value={unit.testHeatStrips.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testHeatStrips.indoor = true;
                                      } else {
                                        unit.testHeatStrips.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row form-group">
                            <label className="col-sm-4 col-form-label">
                              Test Defrost Cycle
                            </label>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testDefrostCycle.outdoor}
                                    value={unit.testDefrostCycle.outdoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testDefrostCycle.outdoor = true;
                                      } else {
                                        unit.testDefrostCycle.outdoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-4">
                              <div className="row">
                                <div className="col-sm-1">
                                  <input
                                    className="form-control checkbox"
                                    checked={unit.testDefrostCycle.indoor}
                                    value={unit.testDefrostCycle.indoor}
                                    type="checkbox"
                                    id="example-checkbox-input1"
                                    onChange={(e) => {
                                      if (e.target.checked) {
                                        unit.testDefrostCycle.indoor = true;
                                      } else {
                                        unit.testDefrostCycle.indoor = false;
                                      }
                                      this.setState({
                                        units,
                                      });
                                    }}
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                ))}
              {roleId !== 4 && Object.keys(customer_sign).length === 0 && (
                <Row
                  className="m-2"
                  style={{
                    textAlign: 'center',
                    display: units.length >= 3 ? 'none' : 'block',
                  }}
                >
                  <Col>
                    <Button variant="link" onClick={() => this.addUnit()}>
                      <i
                        style={{
                          color: 'blue',
                          marginLeft: '-34%',
                          fontSize: '21px',
                        }}
                        className="mdi mdi-plus-circle"
                        id="editDriver"
                        title="View agent"
                      >
                        {' '}
                        <span style={{ fontSize: '17px' }}>Add Unit</span>
                      </i>
                    </Button>
                  </Col>
                </Row>
              )}
              <div className="row">
                <div className="col-12">
                  <Card>
                    <Card.Body>
                      <div className="form-group row">
                        <label className="col-sm-12 col-form-label">
                          RECOMMENDATIONS:
                        </label>
                        <Col md={12}>
                          <textarea
                            className="form-control"
                            type="textarea"
                            id="example-text-input"
                            rows="5"
                            cols="90"
                            value={recommendations}
                            onChange={(e) => {
                              this.setState({
                                recommendations: e.target.value,
                              });
                            }}
                            disabled={disabled}
                          />
                        </Col>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Card>
                    <Card.Body>
                      This Pre-Season Tune-up does not include: supplying
                      filters, clearing condensate line stoppages, evaporator
                      coil cleaning (including acid cleaning), or
                      cleaning/unclogging services required to correct problems
                      due to lack of maintenance. This Tune-up does not warrant
                      or guarantee against future mechanical breakdowns and you
                      (the policy holder) are responsible for any applicable
                      service fees upon present or future equipment failures.
                    </Card.Body>
                  </Card>
                </div>
              </div>
              {Object.keys(customer_sign).length === 0 &&
                roleId == 4 &&
                premaintenanceid && (
                  <div className="row">
                    <div className="col-12">
                      <Card>
                        <Card.Body>
                          <div className="row">
                            <div
                              className="col-6"
                              style={{ textAlign: 'center' }}
                            >
                              <p style={{ textAlign: 'center' }}>
                                Customer Signature
                              </p>
                              <div
                                style={{
                                  display: 'inline-block',
                                  border: '1px solid darkgrey',
                                  marginBottom: '10px',
                                }}
                              >
                                <SignatureCanvas
                                  penColor="green"
                                  canvasProps={{
                                    width: 240,
                                    height: 240,
                                    className: 'sigCanvas',
                                  }}
                                  style={{ border: '1px solid' }}
                                  ref={(ref) => {
                                    this.sigPad = ref;
                                  }}
                                />
                              </div>
                              <div>
                                <Button
                                  variant="secondary"
                                  style={{ marginLeft: '10px' }}
                                  type="reset"
                                  onClick={() => this.clearSign()}
                                >
                                  Clear
                                </Button>
                                <Button
                                  variant="secondary"
                                  style={{ marginLeft: '10px' }}
                                  type="reset"
                                  onClick={() => this.saveSign()}
                                >
                                  Save Sign
                                </Button>
                              </div>
                            </div>
                            <div className="col-6">
                              <div style={{ float: 'right' }}>
                                <p>Date</p>
                                <DatePickerComponent
                                  selected={customerSignDate}
                                  onChange={(date) =>
                                    this.setState({
                                      customerSignDate: date,
                                    })
                                  }
                                  dateFormat="MM/dd/yyyy"
                                  startDate={customerSignDate}
                                  className="form-control col-sm-6"
                                  disabled={
                                    roleId == 4 &&
                                    Object.keys(customer_sign).length
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                )}
              {Object.keys(customer_sign).length ? (
                <div className="row">
                  <div className="col-12">
                    <Card>
                      <Card.Body>
                        <div className="row">
                          <div
                            className="col-6"
                            style={{ textAlign: 'center' }}
                          >
                            <p style={{ textAlign: 'center' }}>
                              Customer Signature
                            </p>
                            <div
                              style={{
                                display: 'inline-block',
                                border: '1px solid darkgrey',
                                marginBottom: '10px',
                              }}
                            >
                              <img
                                src={customer_sign.signature_url}
                                height="200"
                                width="100"
                              />
                            </div>
                          </div>
                          <div className="col-6">
                            <p>Date</p>
                            <DatePickerComponent
                              selected={customerSignDate}
                              onChange={(date) =>
                                this.setState({
                                  customerSignDate: date,
                                })
                              }
                              dateFormat="MM/dd/yyyy"
                              startDate={customerSignDate}
                              className="form-control col-sm-6"
                              disabled
                            />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                </div>
              ) : (
                ''
              )}

              <Row className="mt7" style={{ textAlign: 'center' }}>
                <Col>
                  {Object.keys(customer_sign).length === 0 &&
                    (roleId == 1 || roleId == 5 || roleId == 6) && (
                      <Button
                        variant="primary"
                        type="submit"
                        onClick={(e) => {
                          this.handleClick(e);
                        }}
                      >
                        Submit
                      </Button>
                    )}
                  <Button
                    variant="secondary"
                    className="l-m-10"
                    type="reset"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(PreventativeMaintenance);
