import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';
import flag from '../../../assets/images/flag.png';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'For Reorder',
    field: 'forreorder',
    sort: 'disabled',
    width: 110,
  },
  {
    label: 'Inventory ID',
    field: 'inventoryid',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'Part Number',
    field: 'partnumber',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'AHS Part Cost',
    field: 'ahspartcost',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Description',
    field: 'description',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Part Price',
    field: 'price',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'After Tax',
    field: 'aftertax',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'Quantity In Stock',
    field: 'quantityinstock',
    sort: 'disabled',
    width: 140,
  },
  {
    label: 'Inventory Value',
    field: 'inventoryvalue',
    sort: 'disabled',
    width: 140,
  },
  {
    label: 'Alert Tech Reorder Level',
    field: 'alerttechreorderlevel',
    sort: 'disabled',
    width: 200,
  },
  {
    label: 'Quantity In Reorder',
    field: 'quantityinreorder',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Supply House',
    field: 'supplyhouse',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Discountinued?',
    field: 'discountinued',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'action',
    width: 150,
    sort: 'disabled',
  },
];

export default class Parts extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      rows: [],
      partsData: [],
      partId: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      isShowLoader: false,
      id: null,
      type: '',
      quantity: '',
      types: [
        { label: 'Credit (Add)', value: 'credit' },
        { label: 'Debit (Remove)', value: 'debit' },
      ],
      technicians: [],
      technicianId: null,
      allocatePartId: null,
      isPartAllocated: false,
      allocateType: '',
      balanceStock: 0,
      isWriteAllowed: false,
      allocatedPartName: '',
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-parts'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-parts',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('All Parts');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.navSkip &&
      this.props.location.state.navCurrentPage
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.navSkip,
          navCurrentPage: this.props.location.state.navCurrentPage,
        },
        () => this.getParts()
      );
    } else {
      this.getParts();
    }
    this.getTechnicians();
  };

  getTechnicians = async () => {
    const { keyword } = this.state;
    const endPoint = `${Api.technicianUrl}?search=${keyword}&skip=0&limit=1000`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.success !== false) {
      this.setState({
        technicians: data.data.data,
      });
    }
  };

  changePartStatus = async (part, event) => {
    var isChecked = event.target.checked;
    const { currentPage } = this.state;
    const payload = {
      id: part.id,
      name: part.name,
      description: part.description,
      price: part.price,
      isactive: isChecked ? '1' : '0',
      for_reorder: part.for_reorder,
      invetoryvalue: part.invetoryvalue,
      reorderlevel: part.reorderlevel,
      reordertimeindays: part.reordertimeindays,
      quantityinreorder: part.quantityinreorder,
      continued: part.continued,
      partno: part.partno,
      ahspartcost: part.ahspartcost,
      partprice: part.partprice,
      aftertaxprice: part.aftertaxprice,
      tax: part.tax,
    };
    const { data, message } = await AuthApi.putDataToServer(
      Api.partsUrl,
      payload
    );
    if (data && data.success !== false) {
      swal('Part status changed', '', 'success');
      this.getParts(currentPage);
    } else {
      swal(message, '', 'error');
    }
  };

  updateQuantity = async () => {
    const { id, type, quantity, currentPage } = this.state;
    const payload = {
      id,
      type,
      quantity,
    };
    const { data, message } = await AuthApi.putDataToServer(
      Api.updatePartQuantityUrl,
      payload
    );
    if (data && data.success !== false) {
      swal('Quantity updated', '', 'success');
      this.flushAllData();
      this.getParts(currentPage);
      if (this.modalRefUpdateQuantity)
        window.$(this.modalRefUpdateQuantity).modal('hide');
    } else {
      swal(message, '', 'error');
    }
  };

  allocateParts = async () => {
    const {
      allocatePartId,
      technicianId,
      techpartId,
      quantity,
      isPartAllocated,
      allocateType,
      currentPage,
    } = this.state;
    let payload = {
      quantity,
    };
    if (isPartAllocated) {
      payload.id = techpartId;
      payload.type = allocateType;
    } else {
      payload.partid = allocatePartId;
      payload.technicianid = technicianId;
    }
    const callback = isPartAllocated
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;

    const { data, message } = await callback(Api.technicianPartsUrl, payload);
    if (data && data.success !== false) {
      if (isPartAllocated) {
        swal('Part allocation updated', '', 'success');
      } else {
        swal('Part allocated', '', 'success');
      }
      this.flushAllData();
      this.getParts(currentPage);
      if (this.modalRef) window.$(this.modalRef).modal('hide');
    } else {
      swal(message, '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { partsData, limit, isWriteAllowed } = this.state;
    const page = npage;
    const rows = [];
    partsData &&
      partsData.map((part, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          name: part.name,
          description: part.description,
          price: part.partprice,
          forreorder: (
            <React.Fragment>
              {part.for_reorder && part.for_reorder === 1 ? (
                <img src={flag} width="20" height="20" alt="_image" />
              ) : (
                'No'
              )}
            </React.Fragment>
          ),
          inventoryid: part.inventoryid,
          partnumber: part.partno,
          ahspartcost: part.ahspartcost,
          aftertax: part.aftertaxprice,
          quantityinstock: part.quantityinstock,
          inventoryvalue: part.invetoryvalue,
          alerttechreorderlevel: part.reorderlevel,
          quantityinreorder: part.quantityinreorder,
          supplyhouse: part.supply_house ? part.supply_house : '-',
          discountinued: part.continued && part.continued === 1 ? 'Yes' : 'No',
          status: (
            <React.Fragment>
              <input
                type="checkbox"
                disabled={!isWriteAllowed}
                id={`switch${part.id}`}
                checked={Number(part.isactive) ? 'checked' : ''}
                switch="info"
                onChange={(event) => {
                  this.changePartStatus(part, event);
                }}
              />
              <label
                htmlFor={`switch${part.id}`}
                data-on-label=""
                data-off-label=""
              ></label>
            </React.Fragment>
          ),
          action: (
            <React.Fragment>
              <div>
                <a
                  href="/"
                  style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                  data-toggle="modal"
                  data-target="#partquantity"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      id: part.id,
                      allocatedPartName: part.name,
                    });
                  }}
                >
                  Update-quantity
                </a>
                <a
                  href="/"
                  style={{
                    paddingRight: '10px',
                    display: 'block',
                    pointerEvents: isWriteAllowed ? '' : 'none',
                  }}
                  data-toggle="modal"
                  data-target="#allocate-technician"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      allocatePartId: part.id,
                      balanceStock: part.balancestock,
                      allocatedPartName: part.name,
                    });
                  }}
                >
                  Allocate-part
                </a>
                <a
                  href="/"
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    pointerEvents: isWriteAllowed ? '' : 'none',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.history.push(`part/${part.id}`, {
                      detail: part,
                      navSkip: skip,
                      navCurrentPage: npage,
                    });
                  }}
                >
                  <i
                    className="mdi mdi-lead-pencil"
                    id="editPart"
                    title="Edit Part"
                  ></i>
                </a>
                <a
                  href="/"
                  style={{
                    paddingLeft: '10px',
                    paddingRight: '10px',
                    pointerEvents: isWriteAllowed ? '' : 'none',
                  }}
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({ showDelete: true, partId: part.id });
                  }}
                >
                  <i className="mdi mdi-delete text-danger" title="Delete"></i>
                </a>
              </div>
            </React.Fragment>
          ),
        });
        return part;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getParts = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword, navSkip, navCurrentPage } = this.state;
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }
    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${Api.partsUrl}/all?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          partsData: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      swal(message, '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getParts();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getParts();
    });
  };

  deletePart = async () => {
    const { partId, currentPage } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.partsUrl}?id=${partId}`
    );
    if (data) {
      this.setState({ showDelete: false, partId: null }, () => {
        swal('Part deleted!', '', 'success').then(() => {
          this.getParts(currentPage);
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { type, quantity } = this.state;
    let typeErr = '',
      quantityErr,
      isValid = true;

    if (type.trim() === '') {
      typeErr = 'Type is required';
      isValid = false;
    }

    if (quantity.trim() === '') {
      quantityErr = 'Quantity is required';
      isValid = false;
    }

    this.setState({ typeErr, quantityErr });
    if (isValid) {
      this.updateQuantity();
    }
  };

  validateAllocateForm = (e) => {
    e.preventDefault();
    const {
      technicianId,
      quantity,
      isPartAllocated,
      allocateType,
      balanceStock,
      partQuantityOfTech,
    } = this.state;

    let technicianErr = '',
      quantityErr = '',
      allocateTypeErr = '',
      isValid = true;

    if (technicianId === '' || !technicianId) {
      technicianErr = 'Technician is required';
      isValid = false;
    }

    if (quantity < 1 || quantity.trim() === '') {
      quantityErr = 'Quantity is required';
      isValid = false;
    }
    if (isPartAllocated && allocateType === '') {
      allocateTypeErr = 'Type is required';
      isValid = false;
    }

    if (!isPartAllocated && quantity > balanceStock) {
      quantityErr = `Insufficient Stock!`;
      isValid = false;
    }
    if (allocateType === 'credit' && quantity > balanceStock) {
      quantityErr = `Insufficient stock to credit!`;
      isValid = false;
    }
    if (allocateType === 'debit' && quantity > partQuantityOfTech) {
      quantityErr = `Insufficient part quantity in your account to debit! quantity is ${partQuantityOfTech}`;
      isValid = false;
    }
    this.setState({ technicianErr, quantityErr, allocateTypeErr });

    if (isValid) {
      this.allocateParts();
    }
  };

  flushAllData = () => {
    this.setState({
      quantity: '',
      quantityErr: '',
      type: '',
      typeErr: '',
      technicianId: null,
      allocatePartId: null,
      isPartAllocated: false,
      allocateType: '',
    });
  };

  getTechnicianParts = async () => {
    const { allocatePartId, technicianId } = this.state;
    this.setState({
      isShowLoader: true,
    });
    let endPoint = `${Api.technicianPartsUrl}?partid=${allocatePartId}&technicianid=${technicianId}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data.length) {
      this.setState({
        isPartAllocated: data.data.data ? true : false,
        techpartId: data.data.data && data.data.data[0].id,
        partQuantityOfTech: data.data.data && data.data.data[0].quantity,
      });
    }
    //  else {
    //   swal(message, '', 'error');
    // }
    this.setState({
      isShowLoader: false,
    });
  };

  downloadCSV = async () => {
    const { keyword } = this.state;
    const endPoint = `${Api.downloadPartsCsvUrl}?search=${keyword}`;
    window.open(endPoint, '_blank');
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      showDelete,
      isShowLoader,
      quantity,
      quantityErr,
      type,
      typeErr,
      types,
      technicians,
      technicianId,
      technicianErr,
      isPartAllocated,
      allocateTypeErr,
      balanceStock,
      isWriteAllowed,
      allocatedPartName,
      navSkip,
      navCurrentPage,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All Parts" />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete this part ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deletePart();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, partId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit} autoComplete="off">
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by part name"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6 text-right">
                        {rows && rows.length > 0 && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.downloadCSV();
                            }}
                          >
                            <i className="mdi mdi-download"></i> CSV
                          </button>
                        )}
                        {isWriteAllowed && (
                          <button
                            className="btn btn-primary waves-effect waves-light m-l-10"
                            onClick={() => {
                              this.props.history.push('/part/add');
                            }}
                          >
                            Add Part
                          </button>
                        )}
                      </div>
                    </div>
                    <div ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {/*{totalCount > 10 && (*/}
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={
                          navSkip && navCurrentPage
                            ? this.setState(
                                {
                                  navSkip: null,
                                  navCurrentPage: null,
                                },
                                () => this.getParts
                              )
                            : this.getParts
                        }
                      />
                    </div>
                    {/*)}*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="partquantity"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          ref={(modal) => {
            this.modalRefUpdateQuantity = modal;
          }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Update Part Quantity
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <React.Fragment>
                  <form onSubmit={this.validateForm}>
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label"></div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Allocated Part
                      </label>
                      <div className="col-sm-5">
                        <label>{allocatedPartName}</label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label"></div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Quantity
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Quantity"
                          value={quantity}
                          onChange={(e) => {
                            let event = e;
                            event.target.value = customFunctions.numberMasking(
                              e.target.value
                            );
                            this.setState({ quantity: event.target.value });
                          }}
                        />
                        <span id="err">{quantityErr}</span>
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label"></div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-5">
                        <select
                          className="form-control"
                          onChange={(e) => {
                            this.setState({ type: e.target.value });
                          }}
                        >
                          <option value="">Select type</option>
                          {types.map((item, id) => (
                            <option
                              key={id}
                              selected={item.value === type}
                              value={item.value}
                            >
                              {item.label}
                            </option>
                          ))}
                        </select>
                        <span id="err">{typeErr}</span>
                      </div>
                    </div>

                    <div className="form-group row justify-content-center">
                      <div className="col-sm-3">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Submit
                        </button>
                        <button
                          type="reset"
                          data-dismiss="modal"
                          onClick={() => this.flushAllData()}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="allocate-technician"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          aria-labelledby="exampleModalLabel1"
          aria-hidden="true"
          ref={(modal) => {
            this.modalRef = modal;
          }}
        >
          <div
            className="modal-dialog modal-lg modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel1">
                  Allocate Part to Technician
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <React.Fragment>
                  <form onSubmit={this.validateAllocateForm}>
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label"></div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Allocated Part
                      </label>
                      <div className="col-sm-5">
                        <label>{allocatedPartName}</label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label"></div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Select Technician
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-5">
                        <select
                          className="form-control"
                          // value={technicianId}
                          onChange={(e) => {
                            this.setState(
                              {
                                technicianId: parseInt(e.target.value),
                                isPartAllocated: false,
                              },
                              () => {
                                this.getTechnicianParts();
                              }
                            );
                          }}
                        >
                          <option value="">Select Technician</option>
                          {technicians &&
                            technicians.map((tech) => (
                              <option
                                selected={tech.id === technicianId}
                                value={tech.id}
                              >
                                {tech.name}
                              </option>
                            ))}
                        </select>
                        <span id="err">{technicianErr}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label"></div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Available Stock
                      </label>
                      <div className="col-sm-5">
                        <label>{balanceStock}</label>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="col-sm-2 col-form-label"></div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Quantity
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-5">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Quantity"
                          value={quantity}
                          onChange={(e) => {
                            let event = e;
                            event.target.value = customFunctions.numberMasking(
                              e.target.value
                            );
                            this.setState({ quantity: event.target.value });
                          }}
                        />
                        <span id="err">{quantityErr}</span>
                      </div>
                    </div>
                    {isPartAllocated && (
                      <div className="form-group row">
                        <div className="col-sm-2 col-form-label"></div>
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Type
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <select
                            className="form-control"
                            onChange={(e) => {
                              this.setState({ allocateType: e.target.value });
                            }}
                          >
                            <option value="">Select type</option>
                            {types.map((type) => (
                              <option value={type.value}>{type.label}</option>
                            ))}
                          </select>
                          <span id="err">{allocateTypeErr}</span>
                        </div>
                      </div>
                    )}
                    <div className="form-group row justify-content-center">
                      <div className="col-sm-3">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Submit
                        </button>
                        <button
                          type="reset"
                          data-dismiss="modal"
                          onClick={() => this.flushAllData()}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
