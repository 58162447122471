import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';

class SettingsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Settings',
      paymentTypeList: [],
    };
  }
  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-settings'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-settings',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle(this.state.title);
    this.getPaymentType();
  };

  getPaymentType = async () => {
    const endPoint = `${Api.paymentTypeUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        paymentTypeList: data.data.data,
      });
    }
  };

  updateStatus = async (e) => {
    e.preventDefault();
    const { paymentTypeList } = this.state;
    let payload = [];
    paymentTypeList &&
      paymentTypeList.map((i) => {
        //eslint-disable-line
        payload.push({ id: i.id, status: i.status });
        return i;
      });
    const { data, message } = await AuthApi.putDataToServer(
      Api.updatePaymentSettingsUrl,
      payload
    );
    if (data && data.success !== false) {
      swal('Settings changed', '', 'success');
    } else {
      alert(message);
    }
  };

  render() {
    const { title, paymentTypeList } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body p-4">
                    <div className="row">
                      <div className="col-sm-12">
                        <form onSubmit={this.updateStatus}>
                          <div className="form-group row">
                            <label className="col-sm-4">
                              <h4>Payment Options</h4>
                            </label>
                          </div>
                          {paymentTypeList &&
                            paymentTypeList.map((payment) => {
                              //eslint-disable-line
                              if (
                                payment.payment_type.toLowerCase() !== 'credit'
                              ) {
                                return (
                                  <div className="form-group row">
                                    <label className="col-sm-2">
                                      <h6>{payment.payment_type}</h6>
                                    </label>

                                    <div className="col-sm-2 pt-2">
                                      <input
                                        className="form-control checkbox"
                                        checked={
                                          payment.status === '1' ? true : false
                                        }
                                        value={
                                          payment.status === '1' ? true : false
                                        }
                                        type="checkbox"
                                        style={{ height: '24px' }}
                                        id="example-checkbox-input"
                                        onChange={(e) => {
                                          payment.status = e.target.checked
                                            ? '1'
                                            : '0';
                                          this.setState({ paymentTypeList });
                                        }}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          {paymentTypeList &&
                            paymentTypeList.map((payment) => {
                              //eslint-disable-line
                              if (
                                payment.payment_type.toLowerCase() === 'credit'
                              ) {
                                return (
                                  <div>
                                    <div className="form-group row">
                                      <label className="col-sm-4">
                                        <h4>Credit Card Processing</h4>
                                      </label>
                                    </div>
                                    <div className="form-group row">
                                      <label className="col-sm-2">
                                        <h6>{payment.payment_type}</h6>
                                      </label>

                                      <div className="col-sm-2 pt-2">
                                        <input
                                          className="form-control checkbox"
                                          checked={
                                            payment.status === '1'
                                              ? true
                                              : false
                                          }
                                          value={
                                            payment.status === '1'
                                              ? true
                                              : false
                                          }
                                          type="checkbox"
                                          style={{ height: '24px' }}
                                          id="example-checkbox-input"
                                          onChange={(e) => {
                                            payment.status = e.target.checked
                                              ? '1'
                                              : '0';
                                            this.setState({ paymentTypeList });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            })}
                          <div className="form-group row justify-content-center">
                            <div className="col-sm-3">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Submit
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.props.history.goBack();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(SettingsComponent);
