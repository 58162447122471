import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import customFunctions from '../../../helper/customFunctions';
import CustomerDetails from './addCustomers';
import CustomerWorkOrder from '../AHSworkorder/workorders';
import Complaint from '../Complaints/index';
import PurchaseView from './purchaseView';
import ProfileLoss from './ProfitLoss';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Loader from '../../../components/Loader';
import swal from 'sweetalert';
import RecordingComponent from '../Recording';
import CustomerEstimateComponent from '../CustomerProfile/customerEstimate';
import CustomerInvoiceComponent from '../CustomerProfile/customerInvoice';

class CustomerModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerId: null,
      keyword: '',
      customers: [],
      customerDetails: {},
      isShowLoader: false,
      paginationOptions: {},
    };
  }

  componentDidMount = async () => {
    let isWorkorderAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'ahsworkorders-accessahsworkorders',
      'write'
    );
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addcustomer',
      'write'
    );
    this.setState({ isWorkorderAllowed });
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    customFunctions.setTitle('Customer Module');
    if (
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.customerId
    ) {
      this.setState({ customerId: this.props.match.params.customerId });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.customerDetails
    ) {
      this.setState({
        customerDetails: this.props.location.state.customerDetails,
      });
    } else {
      this.props.history.push('/customer');
      return;
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCustomers();
  };

  clearSearch = () => {
    this.setState({ keyword: '', customers: [] });
  };

  getCustomers = async () => {
    this.setState({ isShowLoader: true });
    const { keyword } = this.state;
    const endPoint = `${Api.customerUrl}?search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ customers: data.data.data, isShowLoader: false });
    } else {
      this.setState({ customers: [], isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      customerDetails,
      isWorkorderAllowed,
      customerId,
      isShowLoader,
      paginationOptions,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="col-12">
              <div className="card m-b-23">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <h3 style={{ paddingLeft: '12px' }}>
                        {(customerDetails && customerDetails.CustomerName) ||
                          '-'}
                      </h3>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-sm-12 text-right">
                          <form
                            onSubmit={this.onSearchSubmit}
                            autoComplete="off"
                          >
                            <div className="form-group row">
                              <div className="col-sm-5">
                                {/* <div
                                  id="myDropdown"
                                  className="dropdown-content"
                                >
                                  <input
                                    className="form-control"
                                    value={keyword}
                                    type="text"
                                    placeholder="Search here..."
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.setState({
                                        keyword: e.target.value,
                                      });
                                      if (!e.target.value) this.clearSearch();
                                    }}
                                    required={true}
                                    autoComplete="off"
                                  />
                                  {customers && customers.length > 0 && (
                                    <div>
                                      {customers.map((item, index) => (
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(
                                              `/view-customer/${item.ID}`,
                                              {
                                                customerDetails: item,
                                                type: 'customer',
                                              }
                                            );
                                            window.location.reload();
                                          }}
                                          key={`${index}-customer-list`}
                                        >
                                          {item.CustomerName}
                                        </a>
                                      ))}
                                    </div>
                                  )}
                                </div> */}
                              </div>
                              <div
                                className="col-sm-7"
                                style={{ paddingRight: '22px' }}
                              >
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light offset-md-2"
                                  onClick={() => {
                                    if (
                                      this.props &&
                                      this.props.location &&
                                      this.props.location.state &&
                                      this.props.location.state.path
                                    ) {
                                      this.props.history.push(
                                        `${this.props.location.state.path}`,
                                        {
                                          paginationObj: paginationOptions,
                                        }
                                      );
                                    } else {
                                      this.props.history.push('/customer');
                                    }
                                  }}
                                >
                                  Back
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="tab-wrapper">
                    <div className="container-fluid">
                      <Tabs defaultActiveKey={'Customer Details'}>
                        <Tab
                          eventKey={'Customer Details'}
                          title="Customer Details"
                        >
                          {paginationOptions && (
                            <CustomerDetails
                              paginationObj={
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.paginationObj
                                  ? this.props.location.state.paginationObj
                                  : ''
                              }
                            />
                          )}
                        </Tab>
                        {isWorkorderAllowed && (
                          <Tab
                            eventKey="Customer Work Orders"
                            title="Customer Work Orders"
                          >
                            <CustomerWorkOrder
                              fromComponent="customerModule"
                              customerId={customerId}
                            />
                          </Tab>
                        )}

                        <Tab eventKey="Purchase Orders" title="Purchase Orders">
                          <PurchaseView />
                        </Tab>

                        <Tab
                          eventKey="Customer Complaints"
                          title="Customer Complaints"
                        >
                          <Complaint />
                        </Tab>

                        <Tab eventKey="Profit/Loss" title="Profit/Loss">
                          <ProfileLoss />
                        </Tab>

                        <Tab eventKey="Recording" title="Recording">
                          {customerId && (
                            <RecordingComponent
                              fromComponent="customer"
                              customerId={customerId}
                            />
                          )}
                        </Tab>
                        <Tab eventKey="Invoice" title="Invoice">
                          <CustomerInvoiceComponent />
                        </Tab>
                        <Tab eventKey="Estimates" title="Estimates">
                          <CustomerEstimateComponent />
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(CustomerModule);
