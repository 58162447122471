import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 50,
  },
  {
    label: 'Category',
    field: 'category',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
    width: 150,
  },
];

export default class ComplaintCategory extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      category: '',
      categoryId: '',
      categoryErr: '',
      categoryData: [],
      rows: [],
      skip: 0,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      isWriteAllowed: false,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  changeCategoryStatus = async (category, event) => {
    var isChecked = event.target.checked;
    const { currentPage } = this.state;
    const payload = {
      category_id: category.id,
      category: category.category,
      status: isChecked ? '1' : '0',
    };
    const { data, message } = await AuthApi.putDataToServer(
      Api.AddcomplaintCategoryUrl,
      payload
    );
    if (data && data.success !== false) {
      swal('Complaint category status changed successfully', '', 'success');
      this.getCategoryList(currentPage);
    } else {
      swal(message, '', 'error');
    }
  };

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-complaintcategory'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-complaintcategory',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('All Complaint Category');

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.navSkip &&
      this.props.location.state.navCurrentPage
    ) {
      await this.setState(
        {
          navSkip: this.props.location.state.navSkip,
          navCurrentPage: this.props.location.state.navCurrentPage,
        },
        () => this.getCategoryList()
      );
    } else {
      this.getCategoryList();
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { categoryData, currentPage, limit, isWriteAllowed } = this.state;
    const rows = [];
    const page = currentPage || 1;
    categoryData &&
      categoryData.map((category, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          category: category.category,
          status: (
            <React.Fragment>
              <input
                type="checkbox"
                id={`switch${category.id}`}
                disabled={!isWriteAllowed}
                checked={Number(category.status) ? 'checked' : ''}
                switch="info"
                onChange={(event) => {
                  this.changeCategoryStatus(category, event);
                }}
              />
              <label
                htmlFor={`switch${category.id}`}
                data-on-label=""
                data-off-label=""
              ></label>
            </React.Fragment>
          ),
          action: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: '/add-category',
                    state: {
                      detail: category,
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  id="editCategory"
                  title="Edit Category"
                ></i>
              </a>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, categoryId: category.id });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return category;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getCategoryList = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, navSkip, navCurrentPage } = this.state;
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }
    const page = navCurrentPage
      ? navCurrentPage
      : currentPage
      ? currentPage
      : 1;
    const skip = navSkip ? navSkip : page * limit - limit;
    const endPoint = `${Api.complaintCategoryUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          categoryData: data.data.data,
          totalCount: data.data.total_count,
          isShowLoader: false,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCategoryList();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getCategoryList();
    });
  };

  deleteCategory = async () => {
    const { categoryId, currentPage } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.AddcomplaintCategoryUrl}?category_id=${categoryId}`
    );
    if (data) {
      this.setState({ showDelete: false, categoryId: null }, () => {
        swal('Complaint category deleted!', '', 'success').then(() => {
          this.getCategoryList(currentPage);
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      isWriteAllowed,
      showDelete,
      isShowLoader,
      navCurrentPage,
      navSkip,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All Complaint Category" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the complaint category?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteCategory();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, categoryId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <form onSubmit={this.onSearchSubmit} autoComplete="off">
                          <div className="form-group row">
                            <div className="col-sm-6 col-md-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by category"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6 col-md-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {isWriteAllowed && (
                        <div className="col-sm-6 col-md-6 text-right">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.props.history.push('/add-category');
                            }}
                          >
                            Add Complaint Category
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getCategoryList
                                )
                              : this.getCategoryList
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
