import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import AUX from '../../../../hoc/Aux_';

class PiChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pieChartData: {},
      pieChartOption: {},
      data: this.props.chartData,
      fromComponent: this.props.fromComponent,
      width: this.props.width || 100,
      height: this.props.height || 50,
    };
  }

  async componentDidMount() {
    this.getCharts();
  }
  async componentWillReceiveProps(props) {
    this.setState({ data: props.chartData }, () => {
      this.getCharts();
    });
  }
  getCharts = () => {
    const { data, fromComponent } = this.state;
    let labels = '';
    var newArrayOfObj = data && data;
    var chartData = [];
    var chartLabels = [];
    var backgroundColor = [
      'rgb(255, 128, 0)',
      'rgb(55, 255, 100)',
      'rgb(0, 0, 128)',
      'rgb(100, 191, 255)',
      'rgb(120, 25, 70)',
      'rgb(255, 0, 255)',
      'rgb(191, 25, 0)',
      'rgb(0, 128, 255)',
      'rgb(255, 110, 64)',
      'rgb(205, 191, 0)',
      'rgb(20, 11, 20)',
      'rgb(120, 11, 20)',
      'rgb(220, 111, 20)',
      'rgb(20, 110, 201)',
      'rgb(01, 205, 022)',
      'rgb(0, 0, 022)',
      'rgb(10, 100, 62)',
      'rgb(90, 90, 2)',
      'rgb(66, 30, 92)',
      'rgb(33, 99, 99)',
      'rgb(111,111, 99)',
      'rgb(77, 77, 9)',
      'rgb(88, 7, 90)',
      'rgb(7, 7, 9)',
    ];

    if (fromComponent === 'review') {
      data &&
        data.length > 0 &&
        data.map((i) => {
          // eslint-disable-line
          chartLabels.push(i.rating);
          chartData.push(i.ratingpercentage);
          return i;
        });
      //set labels for review piechart
      labels = {
        label: function (tooltipItem) {
          let total = newArrayOfObj.reduce(
            (ac, val) => ac + val.ratingpercentage,
            0
          );
          let labels = '';
          labels += `
          <table>
          <tr><th style="padding-left:10px"><h4>${(
            (newArrayOfObj[tooltipItem.index].ratingpercentage / total) *
            100
          ).toFixed(1)}%</h4></th></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag>COUNT </mytag><span1 style="float: right">${
            newArrayOfObj[tooltipItem.index].ratingcount
          }<span1></span><br>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td><span style="font-size:12px;">${
            newArrayOfObj[tooltipItem.index].rating
          }</span></td></tr>
          `;
          return labels;
        },
      };
    }
    if (fromComponent === 'jobs_jobsByServiceType') {
      data &&
        data.length > 0 &&
        data.map((i) => {
          // eslint-disable-line
          chartLabels.push(i.ServiceType);
          chartData.push(i.servicepercentage);
          return i;
        });
      //set labels for jobs piechart
      labels = {
        label: function (tooltipItem) {
          let total = newArrayOfObj.reduce(
            (ac, val) => ac + val.servicepercentage,
            0
          );
          let labels = '';
          labels += `
            <table>
            <tr><td><br /><br /><span style="font-size:20px;padding-top: 10px"><b>${(
              (newArrayOfObj[tooltipItem.index].servicepercentage / total) *
              100
            ).toFixed(1)}%</b></span></h4></td></tr>
            <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
            <tr><th><span style="font-size:12px;">${
              newArrayOfObj[tooltipItem.index].ServiceType &&
              newArrayOfObj[tooltipItem.index].ServiceType.toUpperCase()
            }</span></th></tr>
            `;
          return labels;
        },
      };
      backgroundColor = [
        'rgb(128, 255, 0)',
        'rgb(0, 191, 255)',
        'rgb(255, 0, 255)',
        'rgb(0, 255, 128)',
        'rgb(191, 255, 0)',
        'rgb(0, 128, 255)',
        'rgb(255, 0, 64)',
        'rgb(255, 191, 0)',
      ];
    }
    if (fromComponent === 'jobs_jobsBySource') {
      data &&
        data.length > 0 &&
        data.map((i) => {
          // eslint-disable-line
          chartLabels.push(i.comapny_name);
          chartData.push(i.jobpercentage);
          return i;
        });
      //set labels for jobs piechart
      labels = {
        label: function (tooltipItem) {
          let total = newArrayOfObj.reduce(
            (ac, val) => ac + val.jobpercentage,
            0
          );
          let labels = '';
          labels += `
            <table>
            <tr><td><br /><br /><span style="font-size:20px;padding-top: 10px"><b>${(
              (newArrayOfObj[tooltipItem.index].jobpercentage / total) *
              100
            ).toFixed(1)}%</b></span></td></tr>
            <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
            <tr><th><span style="font-size:12px;">${
              newArrayOfObj[tooltipItem.index].comapny_name &&
              newArrayOfObj[tooltipItem.index].comapny_name.toUpperCase()
            }</span></th></tr>
            `;
          return labels;
        },
      };
      backgroundColor = [
        'rgb(128, 55, 0)',
        'rgb(0, 91, 255)',
        'rgb(0, 55, 128)',
        'rgb(255, 100, 255)',
        'rgb(19, 255, 0)',
        'rgb(0, 12, 255)',
        'rgb(25, 110, 64)',
        'rgb(255, 0, 10)',
      ];
    }
    if (fromComponent === 'technician') {
      data &&
        data.length > 0 &&
        data.map((i) => {
          chartLabels.push(i.technicianName);
          chartData.push(i.workloadPercentage);
          return i;
        });
      //set labels for technician piechart
      labels = {
        label: function (tooltipItem) {
          let total = newArrayOfObj.reduce(
            (ac, val) => ac + val.workloadPercentage,
            0
          );
          let labels = '';
          labels += `
          <table>
          <tr><td><br /><br /><span style="font-size:20px;padding-top: 10px"><b>${(
            (newArrayOfObj[tooltipItem.index].workloadPercentage / total) *
            100
          ).toFixed(1)}%</b></span></td></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><th><span style="font-size:12px;">${
            newArrayOfObj[tooltipItem.index].technicianName &&
            newArrayOfObj[tooltipItem.index].technicianName.toUpperCase()
          }</span></th></tr>
          `;
          return labels;
        },
      };
    }

    let pieChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: '',
          fill: true,
          lineTension: 0,
          backgroundColor: backgroundColor,
          borderWidth: 0,
          data: chartData,
          // data: [{"rating": 10, "ratingcount": 1, x: 100}, {"rating": 11, "ratingcount": 1, x: 20}, {"rating": 11, "ratingcount": 1, x: 40}, {"rating": 11, "ratingcount": 1, x: 70}],
        },
      ],
    };
    let pieChartOption = {
      responsive: true,
      legend: {
        display: true,
      },
      tooltips: {
        mode: 'nearest',
        enabled: false,
        custom: function (tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.color = '#fff';
            tooltipEl.innerHTML = '<div id="toolTip"></div>';
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = '<table>';
            titleLines.forEach(function (title) {
              innerHtml += '<tr><th>' + title + '</th></tr>';
            });

            bodyLines.forEach(function (body, i) {
              innerHtml += body;
            });
            innerHtml +=
              '</table><div id="tailShadow"></div><div id="tail1"></div>';

            var tableRoot = tooltipEl.querySelector('#toolTip');
            tableRoot.innerHTML = innerHtml;
          }

          var position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        },
        pieceLabel: {
          render: 'label',
          arc: true,
          fontColor: '#000',
          position: 'outside',
        },
        callbacks: labels,
      },
    };
    this.setState({ pieChartData, pieChartOption });
  };

  render() {
    const { pieChartData, pieChartOption, width, height } = this.state;
    return (
      <AUX>
        <Pie
          ref="chart"
          data={pieChartData}
          width={Number(width)}
          height={Number(height)}
          options={pieChartOption}
        />
      </AUX>
    );
  }
}

export default withRouter(PiChart);
