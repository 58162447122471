import React, { useState, useEffect, useRef } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { animateScroll } from 'react-scroll';
import CANCEL from '../../assets/images/cancel.png';
import SEND from '../../assets/images/sendMsg.svg';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import customFunctions from './../../helper/customFunctions';
import ChatUserList from './chatUserList';
import { connect } from 'react-redux';
import {
  SetChatBoxToggle,
  SetChatRecieverData,
} from '../../action/user.action';

const ChatBox = (props) => {
  const {
    userId,
    recieverData,
    roleId,
    userName,
    messageType,
    SetChatRecieverData,
    SetChatBoxToggle,
  } = props;
  const [senderId, setSenderId] = useState(userId);
  const [recieverId, setrecieverId] = useState(
    recieverData && typeof recieverData !== 'string'
      ? recieverData.userid
      : null
  );
  const [sendRole, setSendRole] = useState(roleId);
  const [username, setUserName] = useState(userName);
  const [messageText, setmessageText] = useState('');
  const [messages, setmessages] = useState([]);
  const [recieversData, setRecieverData] = useState(recieverData);
  const [messagesType, setmessageType] = useState(messageType);
  const [chatFlag, setchatFlag] = useState(null);
  const [userSelectFromBottom, setuserSelectFromBottom] = useState(false);
  const [count, setCount] = useState(0);
  const [toggle, setToggle] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollDown = () => {
    if (messagesEndRef && !messagesEndRef.current) return;
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(scrollDown, [messages]);

  const socketEndpoint = `wss://${process.env.REACT_APP_WS_URL}`;
  var ws = React.useRef(new WebSocket(socketEndpoint)).current;

  ws.onopen = () => {
    console.log('Connected to the server');
    ws.send(
      JSON.stringify({
        message: 'onuserconnect',
        data: {
          id: senderId,
          role: sendRole,
        },
      })
    );
  };

  ws.onmessage = async (e) => {
    const newData = [...messages];
    const message = e.data.replace(/["']/g, '');
    const createdAt = new Date().toISOString();
    if (
      newData &&
      newData.length &&
      newData[newData.length - 1].message !== message
    ) {
      newData.push({
        message: message,
        createdat: createdAt,
        status: 'delivered',
      });
      setmessages([...newData]);
      setCount(count + 1);
    }
  };
  ws.onclose = (e) => {
    console.log('Disconnected', e);
  };
  ws.onerror = (e) => {
    console.log('CHATS_ERR_FOUND', e);
  };

  // useEffect(() => {
  // if (props.techdata && props.techdata.userid) {
  //   setrecieverId(props.techdata.userid);
  //   setRecieverData(props.techdata);
  //   setmessageType('');
  //   getMessages();
  // }
  // if (props.custdata && props.custdata.userid) {
  //   setrecieverId(props.custdata.userid);
  //   setRecieverData(props.custdata);
  //   setmessageType('');
  // }
  //   getMessages();
  // }, []);

  useEffect(() => {
    if (
      (messageType !== '' &&
        messageType === 'new message' &&
        userSelectFromBottom) ||
      props.chatBoxToggle
    ) {
      setmessageType('');
    }
    setrecieverId(
      recieverData && typeof recieverData !== 'string'
        ? recieverData.userid
        : null
    );
    setRecieverData(recieverData);
    getMessages();
  }, [props]);

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({
      containerId: 'chat-body',
      smooth: true,
    });
  };

  const getMessages = async () => {
    if (!senderId || !recieverId) return;
    const endPoint = `${Api.messageUrl}?sender_id=${senderId}&receiver_id=${recieverId}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (!data) {
      return;
    }
    if (data && data.data) {
      setmessages(data.data);
      scrollToBottom();
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!messageText) return;
    const dt = new Date().toISOString();
    let newData = [...messages];
    newData.push({
      message: messageText,
      createdat: dt,
      img: recieversData && recieversData.image,
      name: username,
      status: 'sent',
    });
    setmessages([...newData]);

    ws.send(
      JSON.stringify({
        message: 'sendmessage',
        data: {
          sender_id: senderId,
          sender_role: sendRole,
          receiver_id: recieversData && recieversData.userid,
          receiver_role: recieversData && recieversData.roleid,
          message: messageText,
        },
      })
    );

    setmessageText('');
    if (!messages.length) {
      scrollToBottom();
    }
  };

  const handleMsgSubmit = (e) => {
    if (e.key === 'Enter') {
      sendMessage(e);
      setmessageText('');
      return false;
    }
  };

  const onChangeMsgHandle = (e) => {
    e.preventDefault();
    setmessageText(e.target.value);
  };

  const handleSelect = (key) => {
    setchatFlag(key);
  };

  return (
    <div
      className={`${
        roleId === 1 || roleId === 4
          ? 'user-admin-popup'
          : 'user-technician-popup'
      }  mid-container chat-popup`}
    >
      <div className="row">
        <div className="col-md-12">
          <div className="arrow-right">
            <div className="card-header">
              <div className="d-flex">
                <h6
                  style={{ width: '95%' }}
                  onClick={() => {
                    if (toggle) {
                      setToggle(false);
                    } else {
                      setToggle(true);
                    }
                  }}
                >
                  <span style={{ cursor: 'pointer' }}>
                    QUALITY HVAC{' '}
                    {recieversData &&
                    recieversData.name &&
                    messagesType !== 'new message'
                      ? `- ${recieversData.name}`
                      : ''}{' '}
                  </span>
                </h6>
                <a
                  href="/"
                  className="chat-close-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    // if (props && props.SetChatBoxToggle) {
                    //   // props.SetChatTechDetails([]);
                    //   // props.SetChatCustDetails([]);
                    // }
                    SetChatBoxToggle(false);
                    SetChatRecieverData([]);
                    props.onCallbackBox();
                  }}
                  style={{ width: '5%' }}
                >
                  <img
                    style={{ maxHeight: '10px', marginLeft: '10px' }}
                    src={CANCEL}
                    alt=""
                    className="svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      {messagesType === '' && (
        <div style={{ display: toggle ? 'none' : 'block' }}>
          <div id="chat-body" className="chat-body">
            {messages &&
              messages.length > 0 &&
              messages.map((dt, id) => (
                <div className="chat-section" key={id}>
                  {dt.status !== 'delivered' && dt.status !== 'sent' && (
                    <div
                      className={
                        dt.sender_id !== senderId
                          ? 'chat-info-wrap'
                          : 'd-flex justify-content-end'
                      }
                    >
                      <div className="mt-3 d-flex justify-content-end">
                        <div
                          className="mt-2 card first-send"
                          style={{
                            wordBreak: 'break-word',
                            backgroundColor: '#e5f7cf',
                            width:
                              dt.sender_id === senderId ? 'fit-content' : '',
                          }}
                        >
                          <div className="card-body p-1">
                            <span className="msg_time_send">{dt.message}</span>
                            <span className="chat-date">
                              {`${customFunctions.getMonthDayYear(
                                dt.createdat
                              )} ${customFunctions.changeTimeOnlyHMS(
                                dt.createdat
                              )}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dt.status === 'sent' && (
                    <div
                      className={
                        dt.sender_id !== senderId
                          ? 'chat-info-wrap'
                          : 'd-flex justify-content-end'
                      }
                    >
                      <div
                        className="mt-3 d-flex justify-content-end float-right"
                        style={{ width: '70%' }}
                      >
                        <div
                          className="mt-2 card first-send"
                          style={{
                            wordBreak: 'break-word',
                            backgroundColor: '#e5f7cf',
                            width:
                              dt.sender_id === senderId ? 'fit-content' : '',
                          }}
                        >
                          <div className="card-body p-1">
                            <span className="msg_time_send">{dt.message}</span>
                            <span className="chat-date">
                              {`${customFunctions.getMonthDayYear(
                                dt.createdat
                              )} ${customFunctions.changeTimeOnlyHMS(
                                dt.createdat
                              )}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {dt.status === 'delivered' && (
                    <div
                      className={
                        dt.sender_id !== senderId
                          ? 'chat-info-wrap'
                          : 'd-flex justify-content-start'
                      }
                    >
                      <div
                        className="mt-3 d-flex justify-content-start"
                        style={{ width: '70%' }}
                      >
                        <div
                          className="mt-2 card first-send"
                          style={{
                            wordBreak: 'break-word',
                            backgroundColor: 'whitesmoke',
                            width:
                              dt.sender_id === senderId ? 'fit-content' : '',
                          }}
                        >
                          <div className="card-body p-1">
                            <span className="msg_time_send">{dt.message}</span>
                            <span className="chat-date">
                              {`${customFunctions.getMonthDayYear(
                                dt.createdat
                              )} ${customFunctions.changeTimeOnlyHMS(
                                dt.createdat
                              )}`}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            <div ref={messagesEndRef} />
          </div>

          <div
            className="card-footer fixed-footer"
            style={{ display: toggle ? 'none' : 'block' }}
          >
            <div className="">
              <div className="chat-box">
                <form action="" onSubmit={sendMessage}>
                  <div className="input-group send">
                    <textarea
                      type="text"
                      className="form-control"
                      placeholder="Chat with HVAC"
                      aria-label="Chat with HVAC"
                      aria-describedby="basic-addon2"
                      cols="40"
                      rows="2"
                      value={messageText}
                      onChange={(e) => onChangeMsgHandle(e)}
                      onKeyPress={handleMsgSubmit}
                    />
                    <button type="submit" className="btn" title="send">
                      <img src={SEND} alt="" className="svg" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {messagesType === 'new message' && (
        <Tabs
          defaultActiveKey={sendRole !== 4 ? 'customers' : 'technician'}
          className="chat"
          onSelect={handleSelect}
        >
          {sendRole !== 4 && (
            <Tab eventKey="customers" title="CUSTOMERS">
              {
                <ChatUserList
                  onCallbackBox={async (data) => {
                    setrecieverId(data.userid);
                    setRecieverData(data);
                  }}
                  onSelect={chatFlag}
                  onUserSelectCallback={() => {
                    setmessageType('');
                    setuserSelectFromBottom(true);
                  }}
                />
              }
            </Tab>
          )}
          <Tab eventKey="technician" title="TECHNICIANS">
            {
              <ChatUserList
                onCallbackBox={async (data) => {
                  setrecieverId(data.userid);
                  setRecieverData(data);
                }}
                onSelect={chatFlag}
                onUserSelectCallback={() => {
                  setmessageType('');
                  setuserSelectFromBottom(true);
                }}
              />
            }
          </Tab>
          {sendRole !== 1 && (
            <Tab eventKey="admin" title="ADMIN">
              {
                <ChatUserList
                  onCallbackBox={async (data) => {
                    setrecieverId(data.userid);
                    setRecieverData(data);
                  }}
                  onSelect={chatFlag}
                  onUserSelectCallback={() => {
                    setmessageType('');
                    setuserSelectFromBottom(true);
                  }}
                />
              }
            </Tab>
          )}
        </Tabs>
      )}
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  SetChatBoxToggle: (data) => dispatch(SetChatBoxToggle(data)),
  SetChatRecieverData: (data) => dispatch(SetChatRecieverData(data)),
});

const mapStateToProps = (state) => ({
  chatBoxToggle: state.auth.chatBoxToggle,
  // techdata: state.auth.techdata,
  // custdata: state.auth.custdata,
  recieverData: state.auth.recieverData,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatBox);
