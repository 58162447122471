import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import customFunctions from '../../../../helper/customFunctions';
import AUX from '../../../../hoc/Aux_';

class LineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineChartData: {},
      lineChartOption: {},
      data: this.props.lineChartData,
    };
    this.lineChartRef = React.createRef();
  }

  async componentDidMount() {
    this.getCharts();
  }
  async componentWillReceiveProps(props) {
    this.setState({ data: props.lineChartData }, () => {
      this.getCharts();
    });
  }
  getCharts = () => {
    var { data, lineChartData, lineChartOption } = this.state;
    let labels = '';
    const chartLabels = [];
    const chartData = [];

    data &&
      Object.keys(data).length > 0 &&
      data.map((i, index) => {
        //eslint-disable-line
        let exists = 0;
        data.map((k, idx) => {
          //eslint-disable-line
          if (k.formatDate === i.formatDate) {
            exists = idx;
          }
        });
        if (index === exists) {
          chartLabels.push(i.formatDate);
        } else {
          chartLabels.push(i.formatDate.split(' ')[0]);
        }
      });
    data &&
      Object.keys(data).length > 0 &&
      data.map((i) => {
        //eslint-disable-line
        chartData.push(i.total);
      });
    //set labels for review barchart
    labels = {
      label: function (tooltipItem) {
        let labels = '';
        labels += `
          <table>
          <tr><td><br /><br /><span style="font-size:20px;padding-top: 10px"><b>${
            data[tooltipItem.index].total
          }</b></span></td></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag style="font-size:11px;">WEEK OF </mytag>${
            data[tooltipItem.index].date &&
            customFunctions.getStringDate(data[tooltipItem.index].date)
          }</span><br>
           `;
        return labels;
      },
    };

    lineChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: 'Data',
          lineTension: 0,
          borderColor: '#FFD740',
          pointBorderColor: '#FFD740',
          pointBackgroundColor: '#FFD740',
          pointHoverBackgroundColor: '#FFD740',
          pointHoverBorderColor: '#FFD740',
          pointBorderWidth: 5,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 1,
          pointRadius: 3,
          fill: false,
          // data: [100, 120, 150, 120, 180, 170, 160],
          data: chartData,
          scaleShowLabels: false,
        },
      ],
    };
    lineChartOption = {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              callback: function (val, index) {
                // return index % 2 === 0 ? val : '';
                // return val.includes('21') ? val : '';
                return val ? val : '';
              },
              color: 'red',
            },
          },
        ],
        yAxes: [
          {
            gridLines: {
              display: true,
            },
          },
        ],
      },
      tooltips: {
        mode: 'nearest',
        enabled: false,
        custom: function (tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.color = '#fff';
            tooltipEl.innerHTML = '<div id="toolTip"></div>';
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = '<table>';

            bodyLines.forEach(function (body, i) {
              innerHtml += body;
            });
            innerHtml +=
              '</table><div id="tailShadow"></div><div id="tail1"></div>';

            var tableRoot = tooltipEl.querySelector('#toolTip');
            tableRoot.innerHTML = innerHtml;
          }

          var position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        },
        pieceLabel: {
          render: 'label',
          arc: true,
          fontColor: '#000',
          position: 'outside',
        },
        callbacks: labels,
      },
    };
    this.setState({ lineChartData, lineChartOption });
  };

  render() {
    const { lineChartData, lineChartOption } = this.state;

    return (
      <AUX>
        <Line data={lineChartData} options={lineChartOption} height={80} />
      </AUX>
    );
  }
}

export default withRouter(LineChart);
