import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 70,
  },
  // {
  //   label: 'Work Order#',
  //   field: 'work_order_id',
  //   sort: 'disabled',
  //   width: 150,
  // },
  // {
  //   label: 'Is Active',
  //   field: 'isactive',
  //   sort: 'disabled',
  //   width: 100,
  // },
  // {
  //   label: 'Is Paid',
  //   field: 'ispaid',
  //   sort: 'disabled',
  //   width: 100,
  // },
  {
    label: 'Vendor ID',
    field: 'vendorid',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'H/O Name',
    field: 'honame',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Contract Start Date',
    field: 'contract_start_date',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Contract End Date',
    field: 'contract_end_date',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Invoice Number',
    field: 'invoiceid',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Completed Portions',
    field: 'completedPortions',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
    width: 150,
  },
];

class PreMaintenanceContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      contracts: [],
      rows: [],
      contractId: null,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      title: 'Pre-Maintenance Contracts',
      isWriteAllowed: false,
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'preventativemaintenance-accesscontracts'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'preventativemaintenance-accesscontracts',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    const { title } = this.state;
    customFunctions.setTitle(title);
    await this.getContracts();
  };

  getContracts = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.premaintenanceContract}?skip=${skip}&limit=${limit}&search=${keyword}&`;
    this.setState({ isShowLoader: true });
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          contracts: data.data.data,
          totalCount: data.data.total_count,
          isShowLoader: false,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  setTableData = () => {
    const { contracts, limit, currentPage, isWriteAllowed } = this.state;
    const page = currentPage || 1;
    const rows = [];
    contracts &&
      contracts.length > 0 &&
      contracts.map((contract, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          work_order_id: contract.WorkOrder ? contract.WorkOrder : '-',
          isactive: contract.isactive === 1 ? 'Yes' : 'No',
          ispaid: contract.ispaid ? 'Yes' : 'No',
          contract_start_date: contract.contract_start_date
            ? contract.contract_start_date
            : '-',
          completedPortions: contract.completed_portions
            ? contract.completed_portions
            : '-',
          invoiceid: contract.invoice_id ? contract.invoice_id : '-',
          vendorid: contract.vendorid ? contract.vendorid : '-',
          honame: contract.honame ? contract.honame : '-',
          contract_end_date: contract.contract_end_date
            ? contract.contract_end_date
            : '-',
          actions: (
            <React.Fragment>
              {contract.completed_portions && (
                <a
                  href="/"
                  style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                  className=""
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.history.push({
                      pathname: '/preventative-maintenance',
                      state: {
                        customerId: contract.CustomerID,
                        work_order_id: contract.work_order_id,
                        multitech: {
                          label: contract.technicianName,
                          value: contract.technician_id,
                        },
                      },
                    });
                  }}
                >
                  <i className="mdi mdi-lead-pencil" title="Edit Contract"></i>
                </a>
              )}
              {/* <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, contractId: contract.id });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title="Delete Contract"
                ></i>
              </a> */}
            </React.Fragment>
          ),
        });
        return contract;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  // deleteContract = async () => {
  //   const { contractId } = this.state;
  //   const { data, message } = await AuthApi.deleteDataFromServer(
  //     `${Api.getContractItem}?contract_id=${contractId}`
  //   );
  //   if (data) {
  //     this.setState({ showDelete: false, contractId: null }, () => {
  //       this.getContracts();
  //     });
  //     swal('Contract deleted', '', 'success');
  //   } else {
  //     swal(message, '', 'error');
  //   }
  // };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getContracts();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getContracts();
    });
  };

  downloadCSV = async () => {
    const { keyword } = this.state;
    const endPoint = `${Api.downloadPremaintenaneContractCsvUrl}?search=${keyword}`;
    window.open(endPoint, '_blank');
  };

  render() {
    const { rows, limit, totalCount, currentPage, showDelete, isShowLoader } =
      this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <HelmetComponent title={this.state.title} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the contract?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteContract();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, contractId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div
                      className="table-header"
                      style={{ paddingBottom: '16px' }}
                    >
                      <div className="left-section">
                        {/* <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group d-flex">
                            <div className="input-wrap">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="buttons-wrap">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form> */}
                      </div>
                      <div className="right-section">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            this.downloadCSV();
                          }}
                        >
                          <i className="mdi mdi-download"></i> CSV
                        </button>
                      </div>
                    </div>
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getContracts}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(PreMaintenanceContracts);
