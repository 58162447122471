import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../../../../helper/api';
import AuthApi from '../../../../helper/authApi';
import customFunctions from '../../../../helper/customFunctions';

class AverageTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
      rows: [],
      statuses: '',
      startDate: '',
      endDate: '',
      averageData: [],
    };
  }
  componentDidMount = async () => {
    const columns = [
      {
        label: 'Month',
        field: 'month',
        width: 150,
      },
      {
        label: 'Count',
        field: 'count',
        width: 150,
      },
    ];
    this.setState({ columns }, () => {
      this.getDispatchesReport();
    });
  };

  getDispatchesReport = async (currentPage) => {
    let { startDate, endDate } = this.state;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.averageCompletedByMonthUrl}?from_date=${startDate}&to_date=${endDate}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          averageData: data.data,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message, '', 'error');
    }
  };

  setTableData = () => {
    const { averageData } = this.state;
    const rows = [];
    averageData &&
      averageData.length &&
      averageData.map((avg) => {
        rows.push({
          month: customFunctions.getMDYDate(avg.month) || '-',
          count: avg.count || '-',
        });
        return avg;
      });
    this.setState({ rows: rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns } = this.state;

    return (
      <>
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="400px"
          />
        </div>
      </>
    );
  }
}

export default withRouter(AverageTable);
