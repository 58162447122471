import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';

class RolePermission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moduleData: [],
      moduleId: '',
      userRoleData: [],
      title: 'Roles Permissions',
      roleId: '',
      roleIdErr: '',
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Roles Permissions');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-rolepermission'
    );
    if ((!isAllowed && userdata.role_id !== 1) || userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
      return;
    }
    this.getModule();
    this.getUserRoles();
  };

  getModule = async () => {
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${Api.moduleUrl}?submodule=1&skip=0&limit=100`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        moduleData: data.data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  getUserRoles = async () => {
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${Api.getRoles}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState({
        userRoleData: data.data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };
  getPermissions = async () => {
    this.setState({
      isShowLoader: true,
    });
    const { roleId } = this.state;
    const endPoint = `${Api.rolePermissionUrl}/allpermission?role_id=${roleId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data && data.data) {
      this.setState({
        allpermissioin: data.data,
      });
      let { moduleData } = this.state;
      moduleData &&
        moduleData.map(async (module) => {
          module.submodules.map((submodule) => {
            let dt = data.data.filter(
              (item) => item.submodule_id === submodule.submodule_id
            );
            if (dt.length) {
              submodule.read = dt[0].read;
              submodule.write = dt[0].write;
            }
            return submodule;
          });
          return module;
        });
      this.setState({ moduleData, isShowLoader: false });
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  updatePermission = async () => {
    this.setState({ isShowLoader: true });
    const { roleId, moduleData } = this.state;

    let permissions = [];
    await moduleData.map((module) => {
      module.submodules.map((submodule) => {
        permissions.push({
          read: submodule.read || false,
          write: submodule.write || false,
          submodule_id: submodule.submodule_id,
        });
        return submodule;
      });
      return module;
    });
    const postData = {
      role_id: roleId,
      permissions,
    };

    const endPoint = `${Api.rolePermissionUrl}`;
    const callback = AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      swal('Permission updated successfully', '', 'success');
      this.setState({ roleId: '', isShowLoader: false }, () =>
        this.forceUpdate()
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    let roleIdErr = '';
    let isValid = true;
    const { roleId } = this.state;

    if (roleId.trim() === '') {
      roleIdErr = 'Role is required';
      isValid = false;
    }

    this.setState({ roleIdErr });
    if (isValid) {
      this.updatePermission();
    }
  };

  render() {
    const { userRoleData, roleIdErr, roleId, title, moduleData, isShowLoader } =
      this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="form-group row p-t-10">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Role <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-5">
                        <select
                          className="form-control"
                          value={roleId}
                          onChange={(e) => {
                            this.setState(
                              { roleId: e.target.value },
                              async () => {
                                await this.getModule();
                                await this.getPermissions();
                              }
                            );
                          }}
                        >
                          <option value="">Select User Role </option>
                          {userRoleData &&
                            userRoleData.map(
                              (urole) =>
                                urole.id !== 1 && (
                                  <option value={urole.id}>{urole.role}</option>
                                )
                            )}
                        </select>
                        <span id="err">{roleIdErr}</span>
                      </div>
                      <div className="col-sm-5">
                        <button
                          className="btn btn-primary waves-effect waves-light float-right"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    {roleId && moduleData && (
                      <form onSubmit={this.validateForm} autoComplete="off">
                        <div className="accordion" id="accordionExample">
                          {moduleData &&
                            moduleData.map((module, parentId) => (
                              <div className="card">
                                <div
                                  className="card-header"
                                  id={`headingOne${parentId}`}
                                >
                                  <div className="mb-0 row">
                                    <button
                                      className="btn btn-link text-left col-sm-3"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target={`#collapseOne${parentId}`}
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      {module.module_name}
                                    </button>
                                    {/* <label htmlFor={`readparent${parentId}`}>Read
                                <input
                                    className="m-l-10"
                                    id={`readparent${parentId}`}
                                    type="checkbox"
                                    name="module"
                                  />
                              </label>
                              <label htmlFor={`writeparent${parentId}`} style={{paddingLeft: "10px"}}>Write
                                <input
                                    className="m-l-10"
                                    id={`writeparent${parentId}`}
                                    type="checkbox"
                                    name="module"
                                  />
                              </label> */}
                                  </div>
                                </div>

                                <div
                                  id={`collapseOne${parentId}`}
                                  className="collapse"
                                  aria-labelledby={`headingOne${parentId}`}
                                  data-parent="#accordionExample"
                                >
                                  <div className="card-body">
                                    {module &&
                                      module.submodules &&
                                      module.submodules.map((submodule, id) => (
                                        <div className="row">
                                          <label
                                            htmlFor="example-text-input2"
                                            className="col-sm-3 col-form-label"
                                          >
                                            {submodule.submodule_name}
                                          </label>
                                          <label
                                            htmlFor={`readchild${id}${parentId}`}
                                          >
                                            Read
                                            <input
                                              className="m-l-10"
                                              id={`readchild${id}${parentId}`}
                                              checked={
                                                submodule.read ? 'checked' : ''
                                              }
                                              onChange={(e) => {
                                                let isChecked =
                                                  e.target.checked;
                                                submodule.read = isChecked;
                                                this.setState({ submodule });
                                              }}
                                              type="checkbox"
                                              name="module"
                                            />
                                          </label>
                                          <label
                                            htmlFor={`writechild${id}${parentId}`}
                                            style={{ paddingLeft: '10px' }}
                                          >
                                            Write
                                            <input
                                              className="m-l-10"
                                              id={`writechild${id}${parentId}`}
                                              checked={
                                                submodule.write ? 'checked' : ''
                                              }
                                              onChange={(e) => {
                                                let isChecked =
                                                  e.target.checked;
                                                submodule.write = isChecked;
                                                if (isChecked)
                                                  submodule.read = isChecked;
                                                this.setState({ submodule });
                                              }}
                                              type="checkbox"
                                              name="module"
                                            />
                                          </label>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="form-group row justify-content-center m-t-10">
                          <div className="col-sm-3">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Submit
                            </button>
                            <button
                              type="reset"
                              onClick={() => {
                                this.props.history.goBack();
                              }}
                              className="btn btn-secondary waves-effect m-l-10"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(RolePermission);
