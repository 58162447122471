import React, { Component } from 'react';
import { Row, Col, Card, Button, Image } from 'react-bootstrap';
// import Checkbox from '@material-ui/core/Checkbox';
import Checkbox from '@mui/material/Checkbox';
import { connect } from 'react-redux';
import { Stepper, Step } from 'react-mui-stepper';
import axios from 'axios';
import SurveyMap from '../../../components/Map/surveyMap';
import AUX from '../../../hoc/Aux_';
import StarRatings from '../../../components/Rating/rating';
import customFunctions from '../../../helper/customFunctions';
import * as actionTypes from '../../../store/action';
import swal from 'sweetalert';
import DRAFT from '../../../assets/images/draft.png';
import LOST from '../../../assets/images/lost.png';
import PAID from '../../../assets/images/paid.png';
import SENT from '../../../assets/images/sent.png';
import WON from '../../../assets/images/won.png';

class SurveyComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      orderId: null,
      customerId: null,
      token: '',
      newRatingValue: null,
      rateLabel: '',
      serviceValue: false,
      priceValue: false,
      timelinessValue: false,
      technicianValue: false,
      sourceId: false,
      estimateData: [],
      reviewToggle: false,
      invoiceData: [],
      activityData: [],
      reviewData: [],
      complaintData: [],
      technicianId: null,
      tokenExpire: false,
      feedBackComment: '',
      feedBackMessage: '',
      feedBackMessageErr: '',
      custLatitude: null,
      custLongitude: null,
      placeMarker: [],
      defaultCenter: {
        lat:
          this.props &&
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
            ? Number(this.props.location.state.data.activity[0].latitude)
            : 40.689167,
        lng:
          this.props &&
          this.props.location &&
          this.props.location.state &&
          this.props.location.state.data
            ? Number(this.props.location.state.data.activity[0].longitude)
            : -111.993889,
      },
      activityToggle: false,
      billingToggle: false,
      statusToggle: false,
      checkboxValue: [],
      ratingErr: '',
      technicianName: '',
      viewId: null,
      items: [],
      invoiceEstimateToggle: false,
      totalAmount: null,
      subTotalamount: null,
      taxAmount: null,
      paymentamount: null,
      dueAmount: null,
      invoiceEstimateTitle: '',
      invoiceToggle: false,
      estimateToggle: false,
      file: '',
      commentErr: '',
      surveyType: '',
      finalStatusToggle: '',
    };
  }

  componentDidMount = async () => {
    if (this.props && this.props.location && this.props.location.state) {
      this.setState({
        tokenExpire: this.props.location.state.tokenExpire ? true : false,
        statusToggle: this.props.location.state.tokenExpire ? false : true,
      });
    } else {
      this.setState({
        tokenExpire: true,
      });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.data
    ) {
      const surveyData = this.props.location.state.data;
      this.setState(
        {
          estimateData: surveyData.estimates,
          invoiceData: surveyData.invoices,
          activityData: surveyData.activity,
          reviewData: surveyData.review,
          complaintData: surveyData.complaint,
          surveyType:
            surveyData.response_data && surveyData.response_data.type
              ? surveyData.response_data.type
              : '',
          orderId: surveyData.activity[0] && surveyData.activity[0].workorderid,
          customerId:
            surveyData.activity[0] && surveyData.activity[0].CustomerID,
          custLatitude:
            surveyData.activity[0] && surveyData.activity[0].latitude,
          custLongitude:
            surveyData.activity[0] && surveyData.activity[0].longitude,
          sourceId: surveyData.activity[0] && surveyData.activity[0].sourceid,
          technicianName:
            surveyData.estimates[0] && surveyData.estimates[0].technician,
          placeMarker: [
            {
              name: 'test',
              popup_type: 'workorder',
              latitude:
                surveyData.activity[0] && surveyData.activity[0].latitude,
              longitude:
                surveyData.activity[0] && surveyData.activity[0].longitude,
              option: {
                label: { color: 'black', text: 'test' },
                icon: {
                  path: 'circle',
                  strokeColor: 'red',
                },
              },
            },
          ],
        },
        () => {
          // if (this.state.reviewData && this.state.reviewData.length > 0) {
          //   this.setState({
          //     reviewToggle: true,
          //   });
          // }
        }
      );
      let surveyCondition =
        surveyData.complaint &&
        surveyData.complaint.length &&
        surveyData.review &&
        surveyData.review.length;
      let surveyType = surveyData.response_data.type;

      if (
        (surveyType === 'Intial Survey' &&
          surveyCondition &&
          surveyData.review[0].rating <= 3) ||
        (surveyCondition && surveyData.review[0].rating >= 4) ||
        (surveyType === 'Final Survey' &&
          surveyCondition &&
          surveyData.complaint[0].ComplaintStatus !== 'Resolved' &&
          surveyData.complaint[0].ComplaintStatus !== 'Cancelled')
      ) {
        this.setState({ finalStatusToggle: true, statusToggle: true });
      }

      if (
        surveyType === 'Intial Survey' &&
        surveyData.complaint.length === 0 &&
        surveyData.review.length === 0
      ) {
        this.setState({ reviewToggle: true, statusToggle: true });
      }

      if (
        surveyType === 'Final Survey' &&
        surveyCondition &&
        surveyData.review[0].rating <= 3 &&
        (surveyData.complaint[0].ComplaintStatus === 'Resolved' ||
          surveyData.complaint[0].ComplaintStatus === 'Cancelled')
      ) {
        this.setState({ reviewToggle: true, statusToggle: true });

        if (surveyData.review && surveyData.review.length) {
          let tellus = surveyData.review[0].telluswhy
            ? JSON.parse(surveyData.review[0].telluswhy)
            : null;
          if (tellus !== null) {
            this.setState({
              serviceValue: tellus.tell[0].serviceValue ? true : false,
              technicianValue: tellus.tell[1].technicianValue ? true : false,
              priceValue: tellus.tell[2].priceValue ? true : false,
              timelinessValue: tellus.tell[3].timelinessValue ? true : false,
            });
          }
          this.setState({
            newRatingValue: surveyData.review[0].rating,
            feedBackMessage: surveyData.review[0].message,
            feedBackComment: surveyData.review[0].review,
          });
          this.rateLabel(surveyData.review[0].rating);
        }
      }
    }
  };

  rateLabel = (newRating) => {
    switch (newRating) {
      case 1:
        this.setState({
          rateLabel: 'Bad',
        });
        break;
      case 2:
        this.setState({
          rateLabel: 'Poor',
        });
        break;
      case 3:
        this.setState({
          rateLabel: 'Average',
        });
        break;
      case 4:
        this.setState({
          rateLabel: 'Good',
        });
        break;
      case 5:
        this.setState({
          rateLabel: 'Best',
        });
        break;
      default:
        return newRating;
    }
  };

  validateForm = (type = '') => {
    const { feedBackMessage, newRatingValue, feedBackComment } = this.state;
    let ratingErr = '',
      commentErr = '',
      feedBackMessageErr = '',
      isValid = true;

    if (feedBackMessage === '' && type === 'yes') {
      feedBackMessageErr = 'Feedback required';
      isValid = false;
    }

    if (!newRatingValue) {
      ratingErr = 'Please select rating';
      isValid = false;
    }
    if (feedBackComment === '') {
      commentErr = 'Please comment';
      isValid = false;
    }
    this.setState({
      ratingErr,
      commentErr,
      feedBackMessageErr,
    });
    if (isValid) {
      this.addRating();
    }
  };

  addRating = async () => {
    const {
      sourceId,
      feedBackMessage,
      customerId,
      orderId,
      newRatingValue,
      serviceValue,
      timelinessValue,
      technicianValue,
      feedBackComment,
      priceValue,
      surveyType,
      reviewData,
    } = this.state;

    let tellUs = {
      tell: [
        {
          serviceValue: serviceValue ? 'service' : '',
        },
        {
          technicianValue: technicianValue ? 'technician' : '',
        },
        {
          priceValue: priceValue ? 'price' : '',
        },
        {
          timelinessValue: timelinessValue ? 'timeliness' : '',
        },
      ],
    };

    const payload = {
      work_order_id: orderId,
      rating: newRatingValue,
      rating_by: customerId,
      review: feedBackComment,
      telluswhy: JSON.stringify(tellUs),
      source: sourceId,
      message: newRatingValue <= 3 ? feedBackMessage : '',
      is_offered: feedBackMessage ? 'YES' : 'NO',
    };

    let endPoint = '';
    if (process.env.NODE_ENV === 'development') {
      endPoint = `${process.env.REACT_APP_API_URL_DEV}review`;
    } else {
      endPoint = `${process.env.REACT_APP_API_URL}review`;
    }

    let data = null;
    if (surveyType === 'Final Survey') {
      payload.tech_id =
        reviewData && reviewData.length && reviewData[0].tech_id;
      payload.reviewid =
        reviewData && reviewData.length && reviewData[0].reviewid;
      payload.isactive =
        reviewData && reviewData.length && reviewData[0].isactive;
      payload.status = reviewData && reviewData.length && reviewData[0].status;

      data = await axios.put(endPoint + '/byadmin', payload, {
        headers: {
          'x-access-token': `${this.state.token}`,
        },
      });
    } else {
      data = await axios.post(endPoint, payload, {
        headers: {
          'x-access-token': `${this.state.token}`,
        },
      });
    }
    if (data && data.data) {
      swal('Review added successfully', '', 'success').then(async () => {
        await customFunctions.removeLocalStorage('surveyToken');
        this.props.history.push('/dashboard');
      });
    } else {
      swal('Something went wrong', '', 'error');
    }
  };

  getImage = (imgs) => {
    let img = imgs && imgs.toUpperCase();
    switch (img) {
      case 'DRAFT':
        return DRAFT;
      case 'WON':
        return WON;
      case 'LOST':
        return LOST;
      case 'SENT':
        return SENT;
      case 'PAID':
        return PAID;
      default:
        return;
    }
  };

  resetData = async () => {
    await this.setState({
      commentErr: '',
      feedBackMessageErr: '',
      newRatingValue: null,
      serviceValue: false,
      priceValue: false,
      timelinessValue: false,
      technicianValue: false,
      rateLabel: '',
    });
  };

  render() {
    const {
      ratingErr,
      tokenExpire,
      activityData,
      serviceValue,
      priceValue,
      technicianValue,
      timelinessValue,
      defaultCenter,
      placeMarker,
      rateLabel,
      newRatingValue,
      activityToggle,
      statusToggle,
      billingToggle,
      technicianName,
      estimateData,
      invoiceData,
      viewId,
      items,
      invoiceEstimateToggle,
      subTotalamount,
      totalAmount,
      paymentamount,
      taxAmount,
      dueAmount,
      invoiceToggle,
      file,
      feedBackComment,
      commentErr,
      feedBackMessage,
      feedBackMessageErr,
      reviewToggle,
      finalStatusToggle,
      reviewData,
      surveyType,
      complaintData,
    } = this.state;

    return (
      <AUX>
        <div
          className={
            tokenExpire ? 'survey-session-expire' : 'survey-map-rating'
          }
        >
          <div className={tokenExpire ? 'd-none' : 'survey-sidebar'}>
            {!tokenExpire && (
              <ul className="list-unstyled p-0 m-0">
                <li
                  style={{ background: activityToggle ? '#b5b2b2' : '#cccccc' }}
                >
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      // this.resetData();
                      this.setState({
                        activityToggle: true,
                        billingToggle: false,
                        statusToggle: false,
                        invoiceEstimateToggle: false,
                        reviewToggle: false,
                      });
                    }}
                  >
                    <span className="mdi mdi-chart-line"></span>
                    Activity
                  </a>
                </li>
                <li
                  style={{ background: billingToggle ? '#b5b2b2' : '#cccccc' }}
                >
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      // this.resetData();
                      this.setState({
                        billingToggle: true,
                        statusToggle: false,
                        activityToggle: false,
                        invoiceEstimateToggle: false,
                        reviewToggle: false,
                      });
                    }}
                  >
                    <span className="mdi mdi-briefcase"></span>
                    Billing
                  </a>
                </li>
                <li
                  style={{ background: statusToggle ? '#b5b2b2' : '#cccccc' }}
                >
                  <a
                    href="/"
                    onClick={(e) => {
                      e.preventDefault();
                      // if (reviewData && reviewData.length > 0) {
                      //   this.setState({
                      //     reviewToggle: true,
                      //     // statusToggle: false,
                      //     activityToggle: false,
                      //     billingToggle: false,
                      //     invoiceEstimateToggle: false,
                      //   });
                      //   return;
                      // }
                      this.setState({
                        statusToggle: true,
                        reviewToggle:
                          (reviewData.length === 0 && !finalStatusToggle) ||
                          (surveyType === 'Final Survey' &&
                            reviewData.length &&
                            reviewData[0].rating <= 3 &&
                            complaintData &&
                            complaintData.length &&
                            (complaintData[0].ComplaintStatus === 'Resolved' ||
                              complaintData[0].ComplaintStatus === 'Cancelled'))
                            ? true
                            : false,
                        activityToggle: false,
                        billingToggle: false,
                        invoiceEstimateToggle: false,
                      });
                    }}
                  >
                    <span className="mdi mdi-chart-timeline"></span>
                    Status
                  </a>
                </li>
              </ul>
            )}
          </div>

          {tokenExpire && (
            <Row>
              <Col>
                <div className="rating-box">
                  <Card>
                    <Card.Body>
                      <Row>
                        <Col sm={2}>
                          <Image
                            style={{ height: '30px', width: '30px' }}
                            src="/assets/images/workstatus/iconUnscheduled.svg"
                          />
                        </Col>
                        <Col sm={10}>
                          <span>
                            Sorry, you are not logged in. Your session or link
                            may have expired.
                          </span>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="rating-box">
                {(statusToggle ||
                  activityToggle ||
                  billingToggle ||
                  invoiceEstimateToggle ||
                  reviewToggle) && (
                  <Card>
                    <Card.Header>
                      {statusToggle || activityToggle || reviewToggle ? (
                        <Stepper withNumbers activeStep={4}>
                          <Step>CONFIRMED</Step>
                          <Step>SCHEDULED</Step>
                          <Step>IN PROGRESS</Step>
                          <Step>COMPLETE</Step>
                        </Stepper>
                      ) : billingToggle ? (
                        <span>Estimate and Invoice</span>
                      ) : invoiceEstimateToggle ? (
                        <>
                          <span
                            className="mdi mdi-arrow-left-bold"
                            style={{ cursor: 'pointer' }}
                            onClick={async () => {
                              await this.setState({
                                totalAmount: null,
                                subTotalamount: null,
                                taxAmount: null,
                                paymentamount: null,
                                dueAmount: null,
                                invoiceEstimateToggle: false,
                                billingToggle: true,
                                file: '',
                              });
                            }}
                          ></span>
                          <span className="l-m-10">
                            {this.state.invoiceEstimateTitle}
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </Card.Header>

                    {statusToggle && finalStatusToggle && (
                      <Card.Body>
                        <h5>Thank You</h5>
                        <span>
                          Your feedback is greatly appreciated. Thank you for
                          using Quality HVAC HOU "TACLB10790E".
                        </span>
                      </Card.Body>
                    )}
                    {statusToggle && reviewToggle && (
                      <Card.Body>
                        <div className="logo-content">
                          <img src="/assets/images/logo2.png" alt="" />
                          <span>
                            How was your service experience with{' '}
                            {technicianName} from Quality HVAC HOU "TACLB10790E"
                          </span>
                        </div>
                        <StarRatings
                          name="rating"
                          starCount={5}
                          onStarClick={(newRating) => {
                            this.setState({ newRatingValue: newRating });
                            this.rateLabel(newRating);
                          }}
                          value={newRatingValue}
                          fromComponent="survey"
                        />
                        <span id="err">{ratingErr}</span>
                        <div style={{ textAlign: 'center' }}>{rateLabel}</div>
                        {newRatingValue && (
                          <>
                            <Row>
                              <Col sm={12}>
                                <h6>Tell us why:</h6>
                              </Col>
                            </Row>
                            <Row>
                              <Col sm={6}>
                                <Checkbox
                                  checked={serviceValue}
                                  onChange={(e) => {
                                    this.setState({
                                      serviceValue: e.target.checked,
                                    });
                                  }}
                                  value="service"
                                  color="default"
                                  name="radio-button-demo"
                                />
                                {'Service'}
                              </Col>

                              <Col sm={6}>
                                <Checkbox
                                  checked={priceValue}
                                  onChange={(e) => {
                                    this.setState({
                                      priceValue: e.target.checked,
                                    });
                                  }}
                                  value="price"
                                  color="default"
                                  name="radio-button-demo"
                                />
                                {'Price'}
                              </Col>

                              <Col sm={6}>
                                <Checkbox
                                  checked={timelinessValue}
                                  onChange={(e) => {
                                    this.setState({
                                      timelinessValue: e.target.checked,
                                    });
                                  }}
                                  value="time"
                                  color="default"
                                  name="radio-button-demo"
                                />
                                {'Timeliness'}
                              </Col>

                              <Col sm={6}>
                                <Checkbox
                                  checked={technicianValue}
                                  onChange={(e) => {
                                    this.setState({
                                      technicianValue: e.target.checked,
                                    });
                                  }}
                                  value="technician"
                                  color="default"
                                  name="radio-button-demo"
                                />
                                {'Technician'}
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                Feedback
                                <span className="text-danger">*</span>
                              </Col>
                              <Col sm={12}>
                                <textarea
                                  className="form-control"
                                  placeholder="Please provide a comment about your experience"
                                  cols={3}
                                  rows={3}
                                  onChange={(e) => {
                                    this.setState({
                                      feedBackComment: e.target.value,
                                    });
                                  }}
                                  value={feedBackComment}
                                />
                                <span id="err">{commentErr}</span>
                              </Col>
                            </Row>
                            {newRatingValue && newRatingValue <= 3 && (
                              <Row className="mt-1">
                                <Col>
                                  Do you mind if we speak with you for an
                                  attempt to turn this frown upside before
                                  leaving your final review? If you select YES a
                                  COMPLAINT will be filed immediately and a
                                  Supervisor WILL reach out to you before end of
                                  the business Day. Thank You for Consideration.
                                </Col>
                                <Col sm={12}>
                                  <textarea
                                    className="form-control"
                                    placeholder="Leave Message"
                                    cols={3}
                                    rows={3}
                                    onChange={(e) => {
                                      this.setState({
                                        feedBackMessage: e.target.value,
                                      });
                                    }}
                                    value={feedBackMessage}
                                  />
                                </Col>
                                <span id="err">{feedBackMessageErr}</span>
                              </Row>
                            )}
                            {newRatingValue > 3 ? (
                              <Row style={{ marginTop: '3%' }}>
                                <Col>
                                  <Button
                                    variant="primary"
                                    className="w-100"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.validateForm('submit');
                                    }}
                                  >
                                    Submit
                                  </Button>
                                </Col>
                              </Row>
                            ) : (
                              <>
                                <Row
                                  style={{
                                    marginTop: '3%',
                                    textAlign: 'center',
                                  }}
                                >
                                  <Col>
                                    <Button
                                      variant="primary"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.validateForm('yes');
                                      }}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      variant="secondary"
                                      className="m-l-10"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        this.setState(
                                          { feedBackMessage: '' },
                                          () => {
                                            this.validateForm('no');
                                          }
                                        );
                                      }}
                                    >
                                      No
                                    </Button>
                                  </Col>
                                </Row>
                              </>
                            )}
                          </>
                        )}
                      </Card.Body>
                    )}

                    {activityToggle && (
                      <Card.Body>
                        {activityData &&
                          activityData.length > 0 &&
                          activityData.map((activity) => (
                            <>
                              <Row className="acivity-content">
                                <Col sm={2}>
                                  <Image
                                    style={{ height: '30px', width: '30px' }}
                                    src={
                                      '/assets/images/workstatus/completed.svg'
                                    }
                                  />
                                </Col>
                                <Col sm={10}>
                                  <h6>
                                    <b>{activity.description}</b>
                                  </h6>
                                  <span>By {activity.created_name}</span>
                                  <br />
                                  <span>
                                    {customFunctions.changeDateFormatMDY(
                                      activity.createdat
                                    )}
                                  </span>
                                </Col>
                              </Row>
                              <hr />
                            </>
                          ))}
                      </Card.Body>
                    )}

                    {billingToggle && (
                      <Card.Body>
                        {invoiceData && invoiceData.length > 0 && (
                          <span> INVOICES </span>
                        )}
                        {invoiceData &&
                          invoiceData.length > 0 &&
                          invoiceData.map((i) => (
                            <>
                              <div className="row mt-0 invoice-list">
                                <div className="col-sm-2">
                                  <img
                                    src={this.getImage(i.invoice_type)}
                                    alt=""
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <a
                                    href="/"
                                    onClick={async (e) => {
                                      e.preventDefault();
                                      let tax = 0;
                                      let taxRate = JSON.parse(`${i.tax}`);
                                      if (
                                        taxRate.tax_rate ||
                                        taxRate.tax_rate !== null
                                      ) {
                                        tax =
                                          (i.totalAmout * taxRate.tax_rate) /
                                          100;
                                      }
                                      this.setState(
                                        {
                                          file: i.file,
                                          invoiceToggle: true,
                                          estimateToggle: false,
                                          subTotalamount: i.sub_total,
                                          totalAmount: i.totalAmout,
                                          paymentamount:
                                            i.totalAmout - i.due_amount,
                                          taxAmount: tax.toFixed(2),
                                          dueAmount: i.due_amount,
                                          viewId: i.work_order_id,
                                          items: i.items,
                                          invoiceEstimateToggle: true,
                                          billingToggle: false,
                                          invoiceEstimateTitle: `Invoice #${i.invoice_id} ${i.invoice_type}`,
                                        },
                                        () => {
                                          this.forceUpdate();
                                        }
                                      );
                                    }}
                                  >
                                    <span>{i.invoice_id}</span>
                                  </a>
                                </div>
                                <div className="col-sm-4">
                                  <span>
                                    {customFunctions.getMonthDayYear(
                                      i.work_start_date
                                    )}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span>
                                    <b>${i.due_amount ? i.due_amount : '0'}</b>{' '}
                                    of ${i.total ? i.total : '0'}
                                  </span>
                                </div>
                              </div>
                            </>
                          ))}
                        {estimateData && estimateData.length > 0 && (
                          <span> ESTIMATES </span>
                        )}
                        {estimateData &&
                          estimateData.length > 0 &&
                          estimateData.map((est) => (
                            <>
                              <div className="row mt-0 invoice-list">
                                <div className="col-sm-2">
                                  <img
                                    src={this.getImage(est.estimate_type)}
                                    alt=""
                                  />
                                </div>
                                <div className="col-sm-3">
                                  <a
                                    href="/"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      let tax = 0;
                                      let taxRate = JSON.parse(`${est.tax}`);
                                      if (
                                        taxRate.tax_rate ||
                                        taxRate.tax_rate !== null
                                      ) {
                                        tax =
                                          (est.total * taxRate.tax_rate) / 100;
                                      }
                                      this.setState(
                                        {
                                          file: est.file,
                                          estimateToggle: true,
                                          invoiceToggle: false,
                                          subTotalamount: est.sub_total,
                                          totalAmount: est.total,
                                          paymentamount: est.total,
                                          taxAmount: tax.toFixed(2),
                                          viewId: est.work_order_id,
                                          items: est.items,
                                          invoiceEstimateToggle: true,
                                          billingToggle: false,
                                          invoiceEstimateTitle: `Estimate #${est.estimate_id} ${est.estimate_type}`,
                                        },
                                        () => {
                                          this.forceUpdate();
                                        }
                                      );
                                    }}
                                  >
                                    <span>#{est.estimate_id}</span>
                                  </a>
                                </div>
                                <div className="col-sm-4">
                                  <span>
                                    {customFunctions.getMonthDayYear(
                                      est.work_start_date
                                    )}
                                  </span>
                                </div>
                                <div className="col-sm-3">
                                  <span>${est.total ? est.total : '0'}</span>
                                </div>
                              </div>
                            </>
                          ))}
                      </Card.Body>
                    )}

                    {invoiceEstimateToggle && viewId && (
                      <Card.Body>
                        {invoiceToggle && (
                          <>
                            <div className="row d-flex justify-content-center">
                              <span>${dueAmount}</span>
                              <br />
                            </div>
                            <div className="row d-flex justify-content-center mb-2 mt-2">
                              <span>BALANCE DUE</span>
                            </div>
                          </>
                        )}
                        <div className="row d-flex justify-content-center">
                          {file && (
                            <a
                              href={file}
                              className="icon-b"
                              // onClick={(e) => {
                              //   e.preventDefault();
                              //   e.stopPropagation();
                              // }}
                              rel="noreferrer noopener"
                              target="_blank"
                              download
                            >
                              <button className="btn btn-primary">
                                View & Download
                              </button>
                            </a>
                          )}
                        </div>
                        <div className="mt-5">
                          <div className="row mt-0">
                            <span className="m-l-10">
                              <b>Summary</b>
                            </span>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <span>Item</span>
                            </div>
                            <div className="col-sm-6 text-right">
                              <span>Price</span>
                            </div>
                          </div>
                          <hr />
                          {items &&
                            items.length > 0 &&
                            items.map((item) => (
                              <div className="row">
                                <div className="col-sm-6">
                                  <span>{item.item_name}</span>
                                </div>
                                <div className="col-sm-6 text-right">
                                  <span>${item.price}</span>
                                </div>
                              </div>
                            ))}
                          <hr />
                          <table className="summary-table">
                            <tbody>
                              <tr
                                data-test="summary-item"
                                className="css-8atqhb"
                              >
                                <td className="css-kvjruz">
                                  <label>Subtotal</label>
                                </td>
                                <td className="css-1crph0c">
                                  ${subTotalamount}
                                </td>
                              </tr>
                              <tr
                                data-test="summary-item"
                                className="css-8atqhb"
                              >
                                <td className="css-kvjruz">
                                  <label>Tax</label>
                                </td>
                                <td className="css-1crph0c">${taxAmount}</td>
                              </tr>
                              <tr
                                data-test="summary-item"
                                className="css-8atqhb"
                              >
                                <td className="css-kvjruz">
                                  <label>Total</label>
                                </td>
                                <td className="css-sxnbuz">${totalAmount}</td>
                              </tr>
                              <tr
                                data-test="summary-item"
                                className="css-8atqhb"
                              >
                                <td className="css-kvjruz">
                                  <label>Payment</label>
                                </td>
                                <td className="css-1crph0c">
                                  ${paymentamount}
                                </td>
                              </tr>
                              {invoiceToggle && (
                                <tr
                                  data-test="summary-item"
                                  className="css-8atqhb"
                                >
                                  <td className="css-kvjruz">
                                    <label>Balance Due</label>
                                  </td>
                                  <td className="css-sxnbuz">${dueAmount}</td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Card.Body>
                    )}
                  </Card>
                )}
              </div>

              <SurveyMap
                googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={
                  <div className="map-view" style={{ height: `100vh` }} />
                }
                mapElement={<div style={{ height: `100%` }} />}
                isMarkerShown
                placeMarker={placeMarker}
                renderPlaceWindow={this.renderPlaceWindow}
                defaultCenter={defaultCenter}
                center={defaultCenter}
                defaultZoom={10}
              />
            </Col>
          </Row>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => {
  return {
    loginpage: state.ui_red.loginpage,
  };
};

const mapDispatchtoProps = (dispatch) => {
  return {
    UpdateLogin: () => dispatch({ type: actionTypes.LOGINPAGE, value: true }),
    UpdateLoginAgain: () =>
      dispatch({ type: actionTypes.LOGINPAGE, value: false }),
  };
};

export default connect(mapStatetoProps, mapDispatchtoProps)(SurveyComponent);
