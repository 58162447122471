import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../../helper/api';
import AuthApi from '../../helper/authApi';
import { SetChatNotificationCount } from '../../action/user.action';

class ChatNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerData: [],
      technicianData: [],
      jobData: [],
      adminData: [],
    };
  }

  componentDidMount() {
    this.getPresentMessageUsers();
  }
  getPresentMessageUsers = async () => {
    const { SetChatNotificationCount } = this.props;
    const endPoint = `${Api.getResentMessagesUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (!data) {
      return;
    }
    const allData = data.data;
    SetChatNotificationCount(allData.count);

    this.setState({
      technicianData: allData.technician,
      customerData: allData.customer,
      jobData: allData.job_sources,
      adminData: allData.admin,
    });
  };

  getReadMessage = async (roleId, userId) => {
    let endPoint = `${Api.updateResentMessagesUrl}`;
    let postData = {
      is_read: 1,
      roleid: roleId,
      userid: userId,
    };

    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);

    if (data && data.data && data.success) {
      this.getPresentMessageUsers();
    } else {
      swal(message, '', 'error');
    }
  };

  getShortName = (name) => {
    let newName = name
      ? name.charAt(0) +
        (name.split(' ').length >= 1
          ? name.split(' ')[1]
            ? name.split(' ')[1][0]
            : ''
          : '')
      : '';
    return newName;
  };

  render() {
    const { customerData, technicianData, jobData, adminData } = this.state;
    return (
      <div className="card chatt">
        <div
          className="card-body"
          style={{ maxHeight: '380px', overflowY: 'scroll', paddingTop: '0' }}
        >
          <div className="inbox-widget">
            {this.props.onSelect === 'customers' &&
            customerData &&
            customerData.length
              ? customerData.map((item, key) => {
                  item.shortName = item.name && this.getShortName(item.name);

                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.getReadMessage(4, item.userid);
                        this.props.onCallbackBox(item);
                        this.props.onCallbackNewMessage('');
                      }}
                    >
                      <div className="row">
                        <div className="inbox-item">
                          <div className="col">
                            <div className="inbox-item-img">
                              <span
                                style={{ height: '45px', width: '45px' }}
                                className="thumb-sm rounded-circle mr-2"
                              >
                                {item.shortName}
                              </span>
                              {Number(item.is_read) === 0 && (
                                <span className="badge badge-danger nav-link-badge">
                                  {item.count_m}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <p className="inbox-item-author">
                              {' '}
                              {item.name ? `${item.name}` : '-'}{' '}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-3" />
                            <div className="col pl-1">
                              <p className="inbox-item-text">
                                {' '}
                                {item.message ? `${item.message}` : '-'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              : ''}

            {this.props.onSelect === 'technician' &&
            technicianData &&
            technicianData.length
              ? technicianData.map((item, key) => {
                  item.shortName = item.name && this.getShortName(item.name);

                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.getReadMessage(5, item.userid);
                        this.props.onCallbackBox(item);
                        this.props.onCallbackNewMessage('');
                      }}
                    >
                      <div className="row">
                        <div className="inbox-item">
                          <div className="col">
                            <div className="inbox-item-img">
                              <span
                                style={{ height: '45px', width: '45px' }}
                                className="thumb-sm rounded-circle mr-2"
                              >
                                {item.shortName}
                              </span>
                              {Number(item.is_read) === 0 && (
                                <span className="badge badge-danger nav-link-badge">
                                  {item.count_m}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <p className="inbox-item-author">
                              {' '}
                              {item.name ? `${item.name}` : '-'}{' '}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-3" />
                            <div className="col pl-1">
                              <p className="inbox-item-text">
                                {' '}
                                {item.message ? `${item.message}` : '-'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              : ''}

            {this.props.onSelect === 'job' && jobData && jobData.length
              ? jobData.map((item, key) => {
                  item.shortName = item.name && this.getShortName(item.name);

                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.props.onCallbackBox(item);
                        this.props.onCallbackNewMessage('');
                      }}
                    >
                      <div className="row">
                        <div className="inbox-item">
                          <div className="col">
                            <div className="inbox-item-img">
                              <span
                                style={{ height: '45px', width: '45px' }}
                                className="thumb-sm rounded-circle mr-2"
                              >
                                {item.shortName}
                              </span>
                            </div>
                          </div>
                          <div className="col">
                            <p className="inbox-item-author">
                              {' '}
                              {item.name ? `${item.name}` : '-'}{' '}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-3" />
                            <div className="col pl-1">
                              <p className="inbox-item-text">
                                {' '}
                                {item.message ? `${item.message}` : '-'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              : ''}

            {this.props.onSelect === 'admin' && adminData && adminData.length
              ? adminData.map((item, key) => {
                  item.shortName = item.name && this.getShortName(item.name);

                  return (
                    <a
                      key={key}
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.getReadMessage(1, item.userid);
                        this.props.onCallbackBox(item);
                        this.props.onCallbackNewMessage('');
                      }}
                    >
                      <div className="row">
                        <div className="inbox-item">
                          <div className="col">
                            <div className="inbox-item-img">
                              <span
                                style={{ height: '45px', width: '45px' }}
                                className="thumb-sm rounded-circle mr-2"
                              >
                                {item.shortName}
                              </span>
                              {Number(item.is_read) === 0 && (
                                <span className="badge badge-danger nav-link-badge">
                                  {item.count_m}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="col">
                            <p className="inbox-item-author">
                              {' '}
                              {item.name ? `${item.name}` : '-'}{' '}
                            </p>
                          </div>
                          <div className="row">
                            <div className="col-md-3" />
                            <div className="col pl-1">
                              <p className="inbox-item-text">
                                {' '}
                                {item.message ? `${item.message}` : '-'}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  );
                })
              : ''}
            {(this.props.onSelect === 'job' && jobData.length === 0) ||
            (this.props.onSelect === 'technician' &&
              technicianData.length === 0) ||
            (this.props.onSelect === 'customers' &&
              customerData.length === 0) ||
            (this.props.onSelect === 'admin' && adminData.length === 0)
              ? 'No recent messages'
              : ''}
          </div>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <a
            href="/"
            className="new-message-link"
            onClick={(e) => {
              e.preventDefault();
              this.props.onCallbackNewMessage('new message');
            }}
          >
            New Message{' '}
          </a>
          {/* <a href=" " style={{ textDecoration: 'underline' }}>
              View All
            </a> */}
        </div>
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  SetChatNotificationCount: (data) => dispatch(SetChatNotificationCount(data)),
});
const mapStateToProps = (state) => ({
  chatCount: state.auth.chatCount,
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChatNotification)
);
