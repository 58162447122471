import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import axios from 'axios';
import swal from 'sweetalert';

class LiveLocationAuth extends Component {
  componentDidMount = async () => {
    const token = await this.props.match.params.token;
    if (token) {
      this.validateToken(token);
    } else {
      this.props.history.push('/login');
    }
  };

  validateToken = async (token) => {
    let endPoint = '';
    if (process.env.NODE_ENV === 'development') {
      endPoint = `${process.env.REACT_APP_API_URL_DEV}workorder_history/checkTokenValidation`;
    } else {
      endPoint = `${process.env.REACT_APP_API_URL}workorder_history/checkTokenValidation`;
    }
    await axios
      .get(endPoint, {
        headers: {
          'x-access-token': `${token}`,
        },
      })
      .then((res) => {
        this.props.history.push('/live-location', {
          data: res.data.data,
        });
      })
      .catch((err) => {
        swal('Sorry, Your session or link may have expired.', '', 'error').then(
          () => {
            this.props.history.push('/login');
          }
        );
      });
  };

  render() {
    return (
      <AUX>
        <div className="accountbg"></div>
        <div className="wrapper-page"></div>
      </AUX>
    );
  }
}

export default LiveLocationAuth;
