import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import SignatureCanvas from 'react-signature-canvas';
import { Button, Card } from 'react-bootstrap';

class AddPreMaintenanceContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notes: '',
      sign_date: '',
      contractId: null,
      editType: false,
      title: '',
      work_order_id: null,
      // notesErr: '',
      contractName: '',
      contract_body: '',
      signUrl: null,
      customer_sign: null,
      isShowLoader: false,
      roleId: null,
      customerApproved: false,
    };
  }

  sigPad = {};

  saveSign = (e) => {
    e.preventDefault();
    const { customer_sign, roleId } = this.state;

    if (!customer_sign && roleId === 4) {
      if (
        this.sigPad &&
        this.sigPad.getTrimmedCanvas().width > 1 &&
        this.sigPad.getTrimmedCanvas().height > 1
      ) {
        this.setState(
          { signUrl: this.sigPad.getTrimmedCanvas().toDataURL('image/png') },
          () => {
            this.validateForm();
          }
        );
      } else {
        this.validateForm();
      }
    } else {
      this.validateForm();
    }
  };

  clearSign = () => {
    this.sigPad.clear();
    this.setState({ signUrl: null });
  };

  componentDidMount = async () => {
    // let writeAllowed = await customFunctions.isAllowedRoute('submodule', 'master-premaintenancecontract', 'write');
    // if(!writeAllowed){
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    await this.getPreContract();

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id });
    // if(userdata.role_id !== 1 && userdata.role_id !== 4){
    //   this.props.history.push('/')
    // }
    const contractId = this.props.match.params.contractId;
    if (!contractId) {
      const work_order_id =
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.work_order_id;
      this.setState({ work_order_id });
    }
    const url_segment = this.props.match.url.split('/');
    this.setState({ contractId });
    if (url_segment && url_segment.includes('update-pre-contract')) {
      this.setState({ editType: true });
    }

    this.setState({ title: `Add Contract` }, () =>
      customFunctions.setTitle(this.state.title)
    );

    if (contractId) {
      this.setState({ title: `Edit Contract` }, () =>
        customFunctions.setTitle(this.state.title)
      );
      if (
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.contractDetails
      ) {
        const contract = this.props.location.state.contractDetails;
        this.setState({
          contractId: contract.id,
          notes: contract.notes,
          customer_sign: contract.customer_sign,
          signUrl: contract.customer_sign,
          sign_date: contract.sign_date,
          customerApproved:
            contract.customer_sign && contract.customer_sign !== null
              ? true
              : false,
        });
      }
    }
  };

  getPreContract = async () => {
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${Api.contractpremaintenance}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        isShowLoader: false,
        contractName: data.data.contract_name,
        contract_body: data.data.contract_body,
      });
    } else {
      var $this = this;
      swal(message, '', 'success');
      this.setState({
        isShowLoader: false,
      });
      setTimeout(() => {
        $this.props.history.goBack();
      }, 1000);
    }
  };

  validateForm = () => {
    const { signUrl, roleId } = this.state;

    let signUrlErr = '',
      // notesErr = '',
      isValid = true;

    // if ((notes && notes === '') || !notes) {
    //   notesErr = 'Notes is required';
    //   isValid = false;
    // }

    if (signUrl === null && roleId === 4) {
      signUrlErr = 'Sign is required';
      isValid = false;
    }

    this.setState({
      // notesErr,
      signUrlErr,
    });
    if (isValid) {
      this.addContract();
    }
  };

  addContract = async (e) => {
    const {
      contractId,
      work_order_id,
      signUrl,
      customer_sign,
      sign_date,
      notes,
    } = this.state;
    const postData = {
      notes,
    };
    if (!contractId) {
      postData.work_order_id = work_order_id;
      postData.customer_sign = signUrl;
      postData.sign_date = new Date().toISOString();
    }
    if (contractId) {
      postData.premaintenancecontractid = contractId;
      postData.customer_sign = customer_sign || signUrl;
      postData.sign_date =
        (sign_date && new Date(sign_date).toISOString()) ||
        new Date().toISOString();
    }

    const callback = contractId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.premaintenanceContract;
    if (contractId) {
      postData.id = parseInt(contractId);
    }
    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      this.setState({
        isShowLoader: false,
      });
      var $this = this;
      if (contractId) {
        swal('Contract updated successfully', '', 'success').then(() => {
          $this.props.history.goBack();
        });
        return;
      }
      swal('Contract added successfully', '', 'success').then(() => {
        this.resetData();
        $this.props.history.goBack();
      });
      return;
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  resetData = () => {
    this.setState({
      notes: '',
      customer_sign: '',
      sign_date: '',
      contractId: null,
      work_order_id: null,
    });
  };

  render() {
    const {
      contract_body,
      notes,
      customer_sign,
      sign_date,
      contractId,
      title,
      // notesErr,
      signUrlErr,
      isShowLoader,
      roleId,
      customerApproved,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12" style={{ minHeight: '60vh' }}>
                <div dangerouslySetInnerHTML={{ __html: contract_body }} />
              </div>
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form onSubmit={this.saveSign} autoComplete="off">
                      {contractId && (
                        <div className="form-group row">
                          <label
                            htmlFor="contractId"
                            className="col-sm-2 col-form-label"
                          >
                            Contract Id
                          </label>
                          <div className="col-sm-6">
                            <input
                              className="form-control"
                              disabled
                              value={contractId}
                              type="text"
                              placeholder="John"
                              id="contractId"
                              onChange={(e) => {
                                this.setState({ contractId: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          htmlFor="desc"
                          className="col-sm-2 col-form-label"
                        >
                          Notes
                          {/* <span className="text-danger">*</span> */}
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            className="form-control"
                            placeholder="Enter Contract Notes"
                            type="textarea"
                            id="desc"
                            rows="6"
                            cols="190"
                            value={notes}
                            disabled={
                              (roleId !== 5 && roleId !== 1) ||
                              customer_sign !== null
                            }
                            onChange={(e) => {
                              this.setState({
                                notes: e.target.value,
                              });
                            }}
                          />
                          {/* <span id="err">{notesErr}</span> */}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="desc"
                          className="col-sm-2 col-form-label"
                        >
                          Customer Signature
                          <span className="text-danger">
                            {roleId !== 5 && `*`}
                          </span>
                        </label>
                        <div className="col-sm-6">
                          <Card>
                            {customer_sign !== null && (
                              <Card.Body>
                                <img src={customer_sign} alt="_image" />
                              </Card.Body>
                            )}
                            {roleId !== 4 && !customer_sign && (
                              <Card.Body>Not signed yet!</Card.Body>
                            )}

                            <Card.Body>
                              {roleId === 4 && !customer_sign && (
                                <>
                                  <SignatureCanvas
                                    penColor="green"
                                    canvasProps={{
                                      width: 365,
                                      height: 250,
                                      className: 'sigCanvas',
                                    }}
                                    ref={(ref) => {
                                      this.sigPad = ref;
                                    }}
                                  />
                                  <div className="text-center">
                                    <Button
                                      variant="secondary"
                                      className="saveBtns m-l-10"
                                      type="reset"
                                      onClick={() => this.clearSign()}
                                    >
                                      Clear
                                    </Button>
                                  </div>
                                </>
                              )}
                            </Card.Body>
                            {customer_sign !== null && (
                              <Card.Footer
                                style={{
                                  textAlign: 'center',
                                  background: '#fff',
                                }}
                              >
                                {sign_date &&
                                  customFunctions.getFormattedDate(sign_date)}
                              </Card.Footer>
                            )}
                          </Card>
                          <span id="err">{signUrlErr}</span>
                        </div>
                      </div>
                      <div className="form-group row d-flex justify-content-center">
                        {!customerApproved && (
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light text-center  m-r-10"
                          >
                            Submit
                          </button>
                        )}
                        <button
                          type="reset"
                          onClick={() => {
                            this.resetData();
                            this.props.history.goBack();
                          }}
                          className="btn btn-secondary waves-effect"
                          style={{ marginRight: '160px' }}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddPreMaintenanceContract);
