import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import AUX from '../../../hoc/Aux_';
import Api from '../../../helper/api';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import RadioGroup from '@mui/material/RadioGroup';
import {
  SetTechnician,
  SetCustomer,
  SetOrderType,
  SetPaymentType,
  SetWorkComplete,
  SetFromDate,
  SetToDate,
  SetMonth,
  SetMonthYear,
  SetYear,
  SetVendor,
  SetFilterType,
  SetWeekType,
} from '../../../action/user.action';
import { connect } from 'react-redux';
import customFunctions from '../../../helper/customFunctions';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';
import SelectStaticDropdown from '../../../components/ReactSelectPagination/SelectStaticDropdown';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technician:
        this.props && this.props.technician ? this.props.technician : '',
      customer: this.props && this.props.customer ? this.props.customer : '',
      orderType: this.props && this.props.orderType ? this.props.orderType : '',
      paymentType:
        this.props && this.props.paymentType ? this.props.paymentType : '',
      isWeekSelected: '',
      isMonthSelected: '',
      isYearSeleced: '',
      CurrentMonth: '',
      fromDate: new Date(),
      toDate: new Date(),
      WorkCompleteValue:
        this.props && this.props.WorkCompleteValue
          ? this.props.WorkCompleteValue
          : '',
      monthyear:
        this.props && this.props.monthyear
          ? { name: this.props.monthyear }
          : '',
      year: this.props && this.props.year ? { name: this.props.year } : '',
      receivedValue: 'week',
      skip: 0,
      limit: 100,
      Months: [
        {
          id: 1,
          name: 'January',
        },
        {
          id: 2,
          name: 'February',
        },
        {
          id: 3,
          name: 'March',
        },
        {
          id: 4,
          name: 'April',
        },
        {
          id: 5,
          name: 'May',
        },
        {
          id: 6,
          name: 'June',
        },
        {
          id: 7,
          name: 'July',
        },
        {
          id: 8,
          name: 'August',
        },
        {
          id: 9,
          name: 'September',
        },
        {
          id: 10,
          name: 'October',
        },
        {
          id: 11,
          name: 'November',
        },
        {
          id: 12,
          name: 'December',
        },
      ],
      roleId: '',
      vendorid: this.props && this.props.vendorid ? this.props.vendorid : '',
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (this.props.month) {
      this.setCurrentMonth(this.props.month);
    }
    this.setState({ roleId: userdata.role_id, isWeekSelected: true });
    this.props.SetWeekType('thisWeek');
    this.startOfWeek(new Date());
  };

  setCurrentMonth = (monthid) => {
    const { Months } = this.state;
    var selectedMonth = Months.filter((month) => month.id === monthid);
    if (selectedMonth && selectedMonth.length) {
      this.setState({
        CurrentMonth: { id: selectedMonth[0].id, name: selectedMonth[0].name },
      });
    }
  };

  handleWeekBtnChange = (week) => {
    this.setState({
      isWeekSelected: true,
      isMonthSelected: false,
      isYearSeleced: false,
      isDateRangeSelected: false,
    });
  };

  handleMonthBtnChange = (month) => {
    const { SetFromDate, SetToDate } = this.props;
    var d = new Date();
    const { Months } = this.state;
    var n = Months[d.getMonth()];
    var currentMonth = n && Months.filter((month) => month.name === n.name);

    this.setState(
      {
        isMonthSelected: true,
        isWeekSelected: false,
        CurrentMonth:
          currentMonth && currentMonth[0].length
            ? { id: currentMonth[0].id, name: currentMonth[0].name }
            : '',
        isDateRangeSelected: false,
      },
      () => {
        SetFromDate('');
        SetToDate('');
      }
    );
  };

  handleYearBtnChange = (year) => {
    const { SetFromDate, SetToDate } = this.props;
    this.setState(
      {
        isYearSeleced: true,
        isMonthSelected: false,
        isWeekSelected: false,
        isDateRangeSelected: false,
      },
      () => {
        SetFromDate('');
        SetToDate('');
      }
    );
  };

  handleDateRangeBtnChange = () => {
    this.setState({
      isYearSeleced: false,
      isMonthSelected: false,
      isWeekSelected: false,
      isDateRangeSelected: true,
      fromDate: '',
      toDate: '',
    });

    this.props.SetMonth('');
    this.props.SetMonthYear('');
    this.props.SetYear('');
  };

  onCancel = () => {
    const { onCancelButtonCallback } = this.props;
    if (onCancelButtonCallback) onCancelButtonCallback();
  };

  onChangeHandler = (event, type = '') => {
    const { SetFromDate, SetToDate, SetMonth, SetMonthYear, SetYear } =
      this.props;

    SetFromDate('');
    SetToDate('');
    if (type !== 'month') SetMonth('');
    if (type !== 'month') SetMonthYear('');
    if (type !== 'month' && type !== 'year') SetYear('');
    this.setState({ receivedValue: event.target.value });
    this.props.SetFilterType(event.target.value);
  };

  beforeOneWeek = () => {
    const { SetFromDate, SetToDate } = this.props;
    this.setState({ fromDate: '', toDate: '' });
    var date = new Date();
    let firstday;
    let dates = customFunctions.getLastWeek(date);
    firstday = customFunctions.formatDate(dates.firstDayOfLastWeek, '-', 'ymd');
    date = customFunctions.formatDate(dates.lastDayOfLastWeek, '-', 'ymd');
    SetFromDate(firstday);
    SetToDate(date);
    this.setState({ fromDate: firstday, toDate: date });
  };

  startOfWeek = (date) => {
    const { SetFromDate, SetToDate } = this.props;
    this.setState({ fromDate: '', toDate: '' });
    var date = new Date();
    let firstday;
    var curr = new Date();
    var first = curr.getDate() - curr.getDay();
    var last = first + 6;
    firstday = customFunctions.formatDate(
      new Date(curr.setDate(first)),
      '-',
      'ymd'
    );
    // date = customFunctions.formatDate(new Date(curr.setDate(last)), '-', 'ymd');
    date = customFunctions.changeDateOnlyMDY(
      new Date(new Date().setDate(last))
    );
    SetFromDate(firstday);
    SetToDate(date);
    this.setState({ fromDate: firstday, toDate: date });
  };

  clearFilter = () => {
    this.setState({
      technician: '',
      customer: '',
      orderType: '',
      paymentType: '',
      isWeekSelected: '',
      isMonthSelected: '',
      isYearSeleced: '',
      fromDate: '',
      toDate: '',
      WorkCompleteValue: '',
      monthyear: '',
      year: '',
    });
  };

  render() {
    const {
      technician,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      CurrentMonth,
      receivedValue,
      fromDate,
      toDate,
      monthyear,
      year,
      roleId,
      vendorid,
    } = this.state;

    const {
      SetTechnician,
      SetCustomer,
      SetOrderType,
      SetPaymentType,
      SetWorkComplete,
      SetMonth,
      SetMonthYear,
      SetYear,
      SetVendor,
      SetWeekType,
    } = this.props;

    return (
      <AUX>
        <div
          className="card-body"
          style={{ border: '1px solid #f2f2f2', marginBottom: '20px' }}
        >
          <p
            style={{
              fontSize: '14px!important',
              paddingLeft: 0,
              fontWeight: '600',
            }}
          >
            Advance Search
          </p>
          <form autoComplete="off">
            <RadioGroup
              field="radioTest"
              defaultValue="week"
              style={{ flexWrap: 'nowrap' }}
            >
              <div className="row">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedValue === 'week'}
                        onChange={(e) => {
                          this.onChangeHandler(e);
                          this.handleWeekBtnChange(e.target.value);
                          // SetWeekType('thisWeek');
                          var d = new Date();
                          this.startOfWeek(d);
                        }}
                        color="default"
                        value="week"
                        name="radio-button-demo"
                      />
                      Week
                    </div>
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedValue === 'month'}
                        onChange={(e) => {
                          this.setState({
                            monthyear: { name: new Date().getFullYear() },
                          });
                          SetMonthYear(new Date().getFullYear());
                          this.onChangeHandler(e, 'month');
                          this.handleMonthBtnChange(e.target.value);
                          let newDate = new Date().getMonth() + 1;
                          SetMonth(newDate);
                          this.setCurrentMonth(newDate);
                          SetYear('');
                        }}
                        value="month"
                        color="default"
                        name="radio-button-demo"
                      />
                      Month
                    </div>
                    {!this.props.showCreatePaysheet && (
                      <div className="col-sm-3">
                        <Radio
                          checked={receivedValue === 'year'}
                          onChange={(e) => {
                            let newYear = new Date().getFullYear();
                            this.setState({ year: { name: newYear } });
                            SetYear(newYear);
                            this.onChangeHandler(e, 'year');
                            this.handleYearBtnChange(e.target.value);
                          }}
                          color="default"
                          value="year"
                          name="radio-button-demo"
                        />
                        Year
                      </div>
                    )}
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedValue === 'dateRange'}
                        onChange={(e) => {
                          this.handleDateRangeBtnChange(e);
                          this.onChangeHandler(e);
                        }}
                        value="dateRange"
                        color="default"
                        name="radio-button-demo"
                      />
                      Date Range
                    </div>
                  </div>
                </div>
              </div>
            </RadioGroup>
            <br />
            {receivedValue === 'week' && (
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-3">
                      <Radio
                        checked={this.props.weektype === 'thisWeek'}
                        onChange={(e) => {
                          var d = new Date();
                          this.startOfWeek(d);
                          SetWeekType('thisWeek');
                        }}
                        value="thisWeek"
                        color="default"
                        name="radio_button"
                      />
                      This Week
                    </div>
                    <div className="col-sm-3">
                      <Radio
                        checked={this.props.weektype === 'lastWeek'}
                        onChange={(e) => {
                          var d = new Date();
                          this.beforeOneWeek(d);
                          SetWeekType('lastWeek');
                        }}
                        value="lastWeek"
                        color="default"
                        name="radio_button"
                      />
                      Last Week
                    </div>
                  </div>
                </div>
              </div>
            )}
            {receivedValue === 'month' && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">Month</div>
                    <div className="col-sm-6">Year</div>
                  </div>
                </div>
              </div>
            )}
            {receivedValue === 'dateRange' && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">From</div>
                    <div className="col-sm-6">To</div>
                  </div>
                </div>
              </div>
            )}
            {receivedValue === 'year' && !this.props.showCreatePaysheet && (
              <div className="row">
                <div className="col-sm-12">Year</div>
              </div>
            )}
            {receivedValue === 'year' && !this.props.showCreatePaysheet && (
              <div className="row">
                <div className="col-sm-6 date-picker">
                  <SelectStaticDropdown
                    onSelectChange={(e) => {
                      this.setState({ year: e ? { name: e.name } : '' }, () => {
                        if (e) {
                          SetYear(e.name);
                        }
                        if (!e) {
                          SetMonthYear('');
                          SetYear('');
                        }
                        this.forceUpdate();
                      });
                    }}
                    className="w-100"
                    isSearch={false}
                    value={year}
                    isClearable={year}
                    dropdownType="years"
                    selectPlaceholder="Select Year"
                  />
                </div>
              </div>
            )}
            {receivedValue === 'month' && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 date-picker">
                      <SelectStaticDropdown
                        onSelectChange={(e) => {
                          this.setState(
                            {
                              CurrentMonth: e ? { id: e.id, name: e.name } : '',
                            },
                            () => {
                              if (e) {
                                SetMonth(e.id);
                                SetYear('');
                              }
                              if (!e) {
                                SetYear(new Date().getFullYear());
                                SetMonth('');
                                SetMonthYear('');
                              }
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={false}
                        value={CurrentMonth}
                        isClearable={CurrentMonth}
                        dropdownType="months"
                        selectPlaceholder="Select Month"
                      />
                    </div>
                    <div className="col-sm-6 date-picker">
                      <SelectStaticDropdown
                        onSelectChange={(e) => {
                          this.setState(
                            { monthyear: e ? { name: e.name } : '' },
                            () => {
                              if (e) {
                                if (CurrentMonth) {
                                  SetMonthYear(e.name);
                                  SetYear('');
                                }
                                if (!CurrentMonth) SetYear(e.name);
                              }
                              if (!e) {
                                SetMonthYear('');
                              }
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={false}
                        value={monthyear}
                        isClearable={monthyear}
                        dropdownType="years"
                        selectPlaceholder="Select Year"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {receivedValue === 'dateRange' && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6 date-picker">
                      <DatePicker
                        className="form-control"
                        placeholderText="Select From Date"
                        selected={fromDate ? fromDate : this.props.fromDate}
                        onChange={(date) => {
                          this.setState(
                            {
                              fromDate: date,
                            },
                            () => {
                              let date = new Date(this.state.fromDate);
                              var d = date.getDate();
                              var m = date.getMonth() + 1;
                              var y = date.getFullYear();
                              var dt =
                                y +
                                '-' +
                                (m <= 9 ? '0' + m : m) +
                                '-' +
                                (d <= 9 ? '0' + d : d);
                              this.props.SetFromDate(dt);
                            }
                          );
                        }}
                        name="startDate"
                        dateFormat="MM/dd/yyyy"
                      />
                    </div>
                    <div className="col-sm-6 date-picker">
                      <DatePicker
                        className="form-control"
                        placeholderText="Select To Date"
                        selected={toDate ? toDate : this.props.toDate}
                        onChange={(date) => {
                          this.setState(
                            {
                              toDate: date,
                            },
                            () => {
                              let date = new Date(this.state.toDate);
                              var d = date.getDate();
                              var m = date.getMonth() + 1;
                              var y = date.getFullYear();
                              var dt =
                                y +
                                '-' +
                                (m <= 9 ? '0' + m : m) +
                                '-' +
                                (d <= 9 ? '0' + d : d);
                              this.props.SetToDate(dt);
                            }
                          );
                        }}
                        name="startDate"
                        dateFormat="MM/dd/yyyy"
                        minDate={fromDate ? fromDate : this.props.fromDate}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <br />
            <div className="row">
              {roleId !== 5 &&
                this.props.fromComponent !== 'unscheduleWorkOrder' && (
                  <div className="col-sm-4 mb-4">
                    <div className="global-dropdown-wrapper">
                      <span>Technician</span>
                      <SelectAsyncPaginate
                        onSelectChange={(e) => {
                          this.setState(
                            { technician: e ? { id: e.id, name: e.name } : '' },
                            () => {
                              if (e) SetTechnician({ id: e.id, name: e.name });
                              if (!e) SetTechnician('');
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={true}
                        isClearable={technician}
                        value={technician}
                        apiname="technician"
                        endpoint={Api.technicianUrl + `?search=`}
                        selectPlaceholder="Select Technician"
                      />
                    </div>
                  </div>
                )}
              {roleId !== 4 && !this.props.showCreatePaysheet && (
                <div className="col-sm-4 mb-4">
                  <div className="global-dropdown-wrapper">
                    <span>Customer</span>
                    <SelectAsyncPaginate
                      onSelectChange={(e) => {
                        this.setState(
                          {
                            customer: e ? { id: e.ID, name: e.name } : '',
                          },
                          () => {
                            if (e) SetCustomer({ id: e.ID, name: e.name });
                            if (!e) SetCustomer('');
                            this.forceUpdate();
                          }
                        );
                      }}
                      isClearable={customer}
                      className="w-100"
                      isSearch={true}
                      value={customer}
                      apiname="customer"
                      endpoint={Api.customerUrl + `?search=`}
                      selectPlaceholder="Customer Name"
                    />
                  </div>
                </div>
              )}
              {!this.props.showCreatePaysheet && (
                <div className="col-sm-4">
                  <div className="global-dropdown-wrapper">
                    <span>Work Order Type</span>
                    <SelectAsyncPaginate
                      onSelectChange={(e) => {
                        this.setState(
                          { orderType: e ? { name: e.name } : '' },
                          () => {
                            if (e) SetOrderType({ name: e.name });
                            if (!e) SetOrderType('');
                            this.forceUpdate();
                          }
                        );
                      }}
                      className="w-100"
                      isSearch={true}
                      value={orderType}
                      isClearable={orderType}
                      apiname="workordertype"
                      endpoint={Api.getOrderType + `?type=1&search=`}
                      selectPlaceholder="Work Order Type"
                    />
                  </div>
                </div>
              )}
              {this.props.fromComponent !== 'payroll' &&
                this.props.fromComponent !== 'unscheduleWorkOrder' && (
                  <div className="col-sm-4">
                    <div className="global-dropdown-wrapper">
                      <span>Payment Type</span>
                      <SelectAsyncPaginate
                        onSelectChange={(e) => {
                          this.setState(
                            { paymentType: e ? { name: e.name } : '' },
                            () => {
                              if (e) SetPaymentType({ name: e.name });
                              if (!e) SetPaymentType('');
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={true}
                        value={paymentType}
                        isClearable={paymentType}
                        apiname="paymenttype"
                        endpoint={Api.paymentTypeUrl + `?search=`}
                        selectPlaceholder="Payment Type"
                      />
                    </div>
                  </div>
                )}
              {this.props.fromComponent !== 'payroll' &&
                this.props.fromComponent !== 'unscheduleWorkOrder' && (
                  <div className="col-sm-4">
                    <div className="global-dropdown-wrapper">
                      <span>Work Complete</span>
                      <SelectStaticDropdown
                        onSelectChange={(e) => {
                          this.setState(
                            {
                              WorkCompleteValue: e
                                ? { id: e.id, name: e.name }
                                : '',
                            },
                            () => {
                              if (e)
                                SetWorkComplete({ id: e.id, name: e.name });
                              if (!e) SetWorkComplete('');
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={false}
                        value={WorkCompleteValue}
                        isClearable={WorkCompleteValue}
                        dropdownType="workComplete"
                        selectPlaceholder="Work Complete"
                      />
                    </div>
                  </div>
                )}
              {this.props.fromComponent === 'ahsWorkorder' && (
                <div className="col-sm-4">
                  <div className="global-dropdown-wrapper">
                    <span>Vendor</span>
                    <SelectAsyncPaginate
                      onSelectChange={(e) => {
                        this.setState(
                          { vendorid: e ? { id: e.id, name: e.name } : '' },
                          () => {
                            if (e) SetVendor({ id: e.id, name: e.name });
                            if (!e) SetVendor('');
                            this.forceUpdate();
                          }
                        );
                      }}
                      className="w-100"
                      isSearch={true}
                      value={vendorid}
                      isClearable={vendorid}
                      apiname="vendor"
                      endpoint={Api.vendorsUrl + `?search=`}
                      selectPlaceholder="Vendor"
                    />
                  </div>
                </div>
              )}
            </div>
            <br></br>
            <div className="form-group row d-flex justify-content-center">
              <div className="col-sm-3 col-md-6">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onFilterSearch();
                  }}
                  className="btn btn-primary waves-effect waves-light"
                >
                  Search
                </button>
                <button
                  type="reset"
                  onClick={() => {
                    this.onCancel();
                  }}
                  className="btn btn-secondary waves-effect m-l-10"
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      </AUX>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  SetTechnician: (technician) => dispatch(SetTechnician(technician)),
  SetCustomer: (customer) => dispatch(SetCustomer(customer)),
  SetOrderType: (orderType) => dispatch(SetOrderType(orderType)),
  SetPaymentType: (paymentType) => dispatch(SetPaymentType(paymentType)),
  SetWorkComplete: (WorkCompleteValue) =>
    dispatch(SetWorkComplete(WorkCompleteValue)),
  SetFromDate: (fromDate) => dispatch(SetFromDate(fromDate)),
  SetToDate: (toDate) => dispatch(SetToDate(toDate)),
  SetMonth: (month) => dispatch(SetMonth(month)),
  SetMonthYear: (monthyear) => dispatch(SetMonthYear(monthyear)),
  SetYear: (year) => dispatch(SetYear(year)),
  SetVendor: (vendorid) => dispatch(SetVendor(vendorid)),
  SetFilterType: (filtertype) => dispatch(SetFilterType(filtertype)),
  SetWeekType: (weektype) => dispatch(SetWeekType(weektype)),
});

const mapStateToProps = (state) => ({
  technician: state.auth.technician,
  customer: state.auth.customer,
  orderType: state.auth.orderType,
  paymentType: state.auth.paymentType,
  WorkCompleteValue: state.auth.WorkCompleteValue,
  fromDate: state.auth.fromDate,
  toDate: state.auth.toDate,
  month: state.auth.month,
  monthyear: state.auth.monthyear,
  year: state.auth.year,
  vendorid: state.auth.vendorid,
  filtertype: state.auth.filtertype,
  weektype: state.auth.weektype,
});
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
