import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import DatePicker from 'react-datepicker';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import customFunctions from '../../../helper/customFunctions';

class CompleteJobPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workcompleteOptions: [],
      message: '',
      dynamicMessage: '',
      dynamicStatus: '',
      messageErr: '',
      optionId: null,
      pausedToggle: false,
      completeToggle: false,
      statusMessage: '',
      noteMessage: '',
      roleId: null,
      technicianId: null,
      supplyHouse: '',
      supplyNumber: '',
      partOrdered: '',
      partNumber: '',
      poNumber: '',
      partDeliveryAddress: '',
      trackingNumber: '',
      partETA: '',
      speakWith: '',
      selectedIndex: null,
      partOrderedErr: '',
      supplyNumberErr: '',
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
      technicianId: userdata.login_id,
    });

    this.getWorkCompleteOptions();
  };

  getWorkCompleteOptions = async () => {
    try {
      const { data, message } = await AuthApi.getDataFromServer(
        `${Api.workCompleteOptions}`
      );
      if (data && data.data) {
        this.setState({ workcompleteOptions: data.data });
      } else {
        swal(message, '', 'error');
      }
    } catch (err) {}
  };

  dynamicMessageHandleChange = (e, index) => {
    if (e.target.value !== '45') {
      this.setState({
        supplyHouse: '',
        supplyNumber: '',
        partOrdered: '',
        partNumber: '',
        poNumber: '',
        partDeliveryAddress: '',
        trackingNumber: '',
        partETA: '',
        speakWith: '',
      });
    }
    this.setState({
      noteMessage: '',
      dynamicMessage: e.target.value,
      dynamicStatus: e.target.name,
      selectedIndex: index,
    });
  };

  validateForm = (e) => {
    e.preventDefault();
    const { dynamicMessage, dynamicStatus, partOrdered, supplyNumber } =
      this.state;
    let isValid = true,
      partOrderedErr = '',
      supplyNumberErr = '',
      messageErr = '';

    if (dynamicMessage === '') {
      messageErr = 'Please select reason';
      isValid = false;
    }

    if (dynamicStatus === 'Pending Parts') {
      if (partOrdered === '') {
        partOrderedErr = 'Part name is required';
        isValid = false;
      }

      if (supplyNumber !== '' && !customFunctions.validatePhone(supplyNumber)) {
        supplyNumberErr = 'Please enter valid phone number';
        isValid = false;
      }
    }

    this.setState({
      messageErr,
      partOrderedErr,
      supplyNumberErr,
    });

    if (isValid) {
      if (dynamicStatus === 'Pending Parts') {
        this.pendingPartsCall();
      } else {
        this.completesubmitMessage();
      }
    }
  };

  completesubmitMessage = async () => {
    const { dynamicMessage, dynamicStatus, noteMessage } = this.state;

    const endpoint = Api.workOrderCompleted;
    let pretechdata = [];
    pretechdata = JSON.stringify(this.props && this.props.previousTechnicians);
    const postData = {
      id: Number(dynamicMessage),
      work_order_id: Number(this.props.workorderId),
      status: 'Completed',
      note: noteMessage || '',
      previoustechnicians: pretechdata,
    };

    if (dynamicMessage !== '1') {
      (postData.paused_reason = dynamicStatus), //eslint-disable-line
        (postData.cust_id = this.props.custId ? this.props.custId : null),
        (postData.tech_id = this.props.technicianId
          ? this.props.technicianId
          : null);
      postData.status = dynamicStatus;
      delete postData.previoustechnicians;
    }
    const { data, message } = await AuthApi.putDataToServer(endpoint, postData);
    if (data) {
      this.resetData();
      if (this.props.popupClose) this.props.popupClose();
      if (this.props.callbackfun) this.props.callbackfun();
      swal(
        dynamicMessage === '1'
          ? 'Work order completed successfully'
          : 'Work order status updated successfully',
        '',
        'success'
      );
      return;
    } else {
      swal(message, '', 'error');
    }
  };

  pendingPartsCall = async () => {
    const {
      noteMessage,
      supplyHouse,
      supplyNumber,
      partOrdered,
      partNumber,
      poNumber,
      partDeliveryAddress,
      trackingNumber,
      partETA,
      speakWith,
    } = this.state;

    const postData = {
      work_order_id: Number(this.props.workorderId),
      items: [
        {
          SupplyHouse: supplyHouse,
          SupplyHousePhoneNumber: supplyNumber,
          PartOrdered: partOrdered,
          PartNumber: partNumber,
          PONumber: poNumber,
          PartDeliveryAddress: partDeliveryAddress,
          TrackingNumber: trackingNumber,
          PartETA: partETA && customFunctions.getMDYDate(partETA),
          WhoDidYouSpeakWith: speakWith,
          note: noteMessage,
        },
      ],
    };
    const endpoint = Api.updatePendingworkOrderstatus;
    const payload = await customFunctions.cleanObject(postData);

    const { data, message } = await AuthApi.postDataToServer(endpoint, payload);
    if (data) {
      await this.completesubmitMessage();
    } else {
      swal(message, '', 'error');
    }
  };

  popupClose = async () => {
    await this.setState({
      completeToggle: false,
      pausedToggle: false,
      statusMessage: null,
      dynamicMessage: '',
      messageErr: '',
      message: '',
      noteMessage: '',
      supplyHouse: '',
      supplyNumber: '',
      partOrdered: '',
      partNumber: '',
      poNumber: '',
      partDeliveryAddress: '',
      trackingNumber: '',
      partETA: '',
      speakWith: '',
    });
    this.props.popupClose();
  };

  handleChange = (e) => {
    this.setState({
      noteMessage: e.target.value,
    });
  };

  resetData = async () => {
    await this.setState({
      completeToggle: false,
      pausedToggle: false,
      statusMessage: null,
      dynamicMessage: '',
      messageErr: '',
      message: '',
    });
  };

  render() {
    const {
      dynamicMessage,
      messageErr,
      workcompleteOptions,
      supplyNumber,
      supplyHouse,
      partOrdered,
      partNumber,
      poNumber,
      partDeliveryAddress,
      trackingNumber,
      partETA,
      speakWith,
      dynamicStatus,
      selectedIndex,
      partOrderedErr,
      noteMessage,
      supplyNumberErr,
    } = this.state;
    return (
      <div>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={this.props.popupClose}
          show={this.props.workCompleteJob}
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              What is the status of this job?
            </Modal.Header>
            <Modal.Body style={{ maxHeight: '350px', overflowY: 'scroll' }}>
              <>
                <div className="form-group">
                  {workcompleteOptions &&
                    workcompleteOptions.data &&
                    workcompleteOptions.data.map((option, index) => (
                      <React.Fragment key={index}>
                        <Radio
                          checked={option.id == dynamicMessage}
                          onChange={(e) => {
                            this.dynamicMessageHandleChange(e, index);
                          }}
                          value={option.id}
                          color="default"
                          name={option.name}
                        />
                        {option.name}

                        {dynamicMessage &&
                          dynamicMessage !== '45' &&
                          index === selectedIndex && (
                            <textarea
                              className="form-control col-sm-10 margin-start"
                              placeholder="Please add note"
                              name="message"
                              value={this.state.noteMessage}
                              onChange={this.handleChange}
                            />
                          )}
                        <br />
                      </React.Fragment>
                    ))}

                  {dynamicMessage && (
                    <>
                      {dynamicStatus === 'Pending Parts' && (
                        <>
                          <div className="form-group row">
                            <label
                              htmlFor="supply-house"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Supply House
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                placeholder="Supply House"
                                type="text"
                                value={supplyHouse}
                                id="supply-house"
                                onChange={(e) => {
                                  this.setState({
                                    supplyHouse: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="supply-number"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Supply Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                placeholder="Supply Phone Number"
                                type="text"
                                value={supplyNumber}
                                maxLength="10"
                                id="supply-numbere"
                                onChange={(e) => {
                                  this.setState({
                                    supplyNumber: e.target.value,
                                  });
                                }}
                              />
                              <span id="err">{supplyNumberErr}</span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="part-ordered"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Part Ordered
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                placeholder="Part name"
                                type="text"
                                value={partOrdered}
                                id="part-ordered"
                                onChange={(e) => {
                                  this.setState({
                                    partOrdered: e.target.value,
                                  });
                                }}
                              />
                              <span id="err">{partOrderedErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="part-number"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Part Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                placeholder="Part Number"
                                type="text"
                                value={partNumber}
                                id="part-number"
                                onChange={(e) => {
                                  this.setState({ partNumber: e.target.value });
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="po-number"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              PO Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                placeholder="PO Number"
                                type="text"
                                value={poNumber}
                                id="po-number"
                                onChange={(e) => {
                                  this.setState({ poNumber: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="part-address"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Part Delivery Address
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                className="form-control"
                                placeholder="Part Delivery Address"
                                value={partDeliveryAddress}
                                id="part-address"
                                onChange={(e) => {
                                  this.setState({
                                    partDeliveryAddress: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="tracking-number"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Trackimg Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                placeholder="Trackimg Number"
                                type="text"
                                value={trackingNumber}
                                id="tracking-number"
                                onChange={(e) => {
                                  this.setState({
                                    trackingNumber: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="part-eta"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Part ETA
                            </label>
                            <div className="col-sm-6">
                              <DatePicker
                                className="form-control"
                                selected={partETA}
                                onChange={(date) => {
                                  this.setState({ partETA: date });
                                }}
                                minDate={new Date()}
                                name="startDate"
                                dateFormat="MM/dd/yyyy"
                                placeholderText="Select date"
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="speak-with"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Who did you speak with
                            </label>
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                placeholder="Who did you speak with"
                                type="text"
                                value={speakWith}
                                id="speak-with"
                                onChange={(e) => {
                                  this.setState({ speakWith: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="note"
                              className="col-sm-3 col-form-label margin-start"
                            >
                              Note
                            </label>
                            <div className="col-sm-6">
                              <textarea
                                className="form-control"
                                placeholder="Please add note"
                                name="note"
                                value={noteMessage}
                                onChange={(e) => {
                                  this.setState({
                                    noteMessage: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <span id="err">{messageErr}</span>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer id="md-filder" className="w-100">
              <div className="float-right">
                <Button
                  variant="primary"
                  type="submit"
                  className="saveBtns l-m-10"
                  onClick={(e) => this.validateForm(e)}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  className="saveBtns ml-2"
                  onClick={() => {
                    this.popupClose();
                  }} // eslint-disable-line
                >
                  Back
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default CompleteJobPopup;
