import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import customFunctions from '../../../helper/customFunctions';
import MonthlyExpense from './monthlyExpense';
import ExpenseIncomeAnalysis from './ExpenseIncomeAnalysis';
import HelmetComponent from '../Title/index';

class POExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      year: '',
      isWriteAllowed: false,
      addNewExpenseAllowed: false,
      addNewPOAllowed: false,
    };
  }

  componentDidMount = async () => {
    let addNewExpenseAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addnewexpense',
      'write'
    );
    if (addNewExpenseAllowed) this.setState({ addNewExpenseAllowed: true });

    let addNewPOAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'addnewrecord-addpurchaseorder',
      'write'
    );
    if (addNewPOAllowed) this.setState({ addNewPOAllowed: true });

    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'profitloss-accessprofitloss'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'profitloss-accessprofitloss',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    customFunctions.setTitle('Profit Loss');
    var curdate = new Date();
    var year = curdate.getFullYear();
    this.setState({ year });
  };

  render() {
    const { addNewExpenseAllowed, addNewPOAllowed } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="Profit Loss" />
            <div className="col-12">
              <div className="card m-b-23">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6"></div>
                  </div>
                  <div className="tab-wrapper">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="pull-right">
                            {addNewPOAllowed && (
                              <button
                                className="btn btn-primary waves-effect waves-light m-l-7"
                                onClick={() => {
                                  this.props.history.push('/purchase');
                                }}
                              >
                                Add New PO
                              </button>
                            )}
                            {addNewExpenseAllowed && (
                              <button
                                className="btn btn-primary waves-effect waves-light m-l-10"
                                onClick={() => {
                                  this.props.history.push('/add-newexpense');
                                }}
                              >
                                Add New Expense
                              </button>
                            )}

                            <button
                              className="btn btn-primary waves-effect waves-light m-l-10"
                              onClick={() => {
                                window.location.reload();
                              }}
                            >
                              Refresh
                            </button>
                          </div>
                        </div>
                      </div>
                      <Tabs defaultActiveKey={'Monthly Expense Report'}>
                        <Tab
                          eventKey={'Monthly Expense Report'}
                          title="Monthly Expense Report"
                        >
                          <MonthlyExpense />
                        </Tab>
                        <Tab
                          eventKey="Expense VS Income Analysis"
                          title="Expense VS Income Analysis"
                        >
                          <ExpenseIncomeAnalysis />
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(POExpenses);
