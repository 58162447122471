import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import AUX from '../../../../hoc/Aux_';

class BarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: {},
      barChartOption: {},
      data: this.props && this.props.chartData,
      fromComponent: this.props && this.props.fromComponent,
    };
  }

  async componentDidMount() {
    this.getCharts();
  }
  async componentWillReceiveProps(props) {
    this.setState({ data: props.chartData }, () => {
      this.getCharts();
    });
  }
  getCharts = () => {
    // const { datasets } = this.refs.chart.chartInstance.data;
    const { data, fromComponent } = this.state;
    let labels = '';
    var newArrayOfObj = [];
    const chartLabels = [];

    if (fromComponent === 'review') {
      newArrayOfObj =
        data &&
        data.length > 0 &&
        data.map(({ AverageRating: y, ...rest }) => ({
          y,
          ...rest,
        }));
      data &&
        data.length > 0 &&
        data.map((i) => {
          chartLabels.push(i.source_name);
          return i;
        });
      //set labels for review barchart
      labels = {
        label: function (tooltipItem, data) {
          let labels = '';
          labels += `
          <table>
          <tr><th style="padding-left:10px"><h4>${
            data.datasets[0].data[tooltipItem.index].y
          }</h4></th></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag>COUNT </mytag><span1 style="float: right">${
            data.datasets[0].data[tooltipItem.index].source
          }<span1></span><br>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td><span style="font-size:12px;">${
            data.datasets[0].data[tooltipItem.index].source_name
          }</span></td></tr>
          <tr><td><span style="font-size:12px;">${
            data.datasets[0].data[tooltipItem.index].source
          }</span></td></tr>
          `;
          return labels;
        },
      };
    }
    if (fromComponent === 'technician') {
      newArrayOfObj =
        data &&
        data.length > 0 &&
        data.map(({ AverageRating: y, ...rest }) => ({
          y,
          ...rest,
        }));
      data &&
        data.length > 0 &&
        data.map((i) => {
          chartLabels.push(i.technician);
          return i;
        });
      //set labels for review barchart
      labels = {
        label: function (tooltipItem, data) {
          let labels = '';
          labels += `
          <table>
          <tr><th style="padding-left:10px"><h4>${
            data.datasets[0].data[tooltipItem.index].y
          }</h4></th></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag>COUNT </mytag><span1 style="float: right">${
            data.datasets[0].data[tooltipItem.index].y
          }<span1></span><br>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag>TOTAL </mytag><span1 style="float: right">${
            data.datasets[0].data[tooltipItem.index].y
          }<span1></span><br>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td><span style="font-size:12px;">${
            data.datasets[0].data[tooltipItem.index].technician
          }</span></td></tr>
          `;
          return labels;
        },
      };
    }
    if (fromComponent === 'ahsReport') {
      newArrayOfObj =
        data &&
        data.length > 0 &&
        data.map(({ count: y, ...rest }) => ({
          y,
          ...rest,
        }));
      data &&
        data.length > 0 &&
        data.map((i) => {
          chartLabels.push(i.timeRange);
          return i;
        });
      //set labels for review barchart
      labels = {
        label: function (tooltipItem) {
          let labels = '';
          labels += `
          <table style="text-align: center">
          <tr><td><h3>${tooltipItem.value}</h3></td></tr>
          <tr><td><hr style="margin:0 10px;background: #fff;width: 60px"></td></tr>
          <tr><td>
          <div style="font-size:12px">${tooltipItem.label}
          </div>
          </td></tr>
          `;
          return labels;
        },
      };
    }

    let barChartData = {
      labels: chartLabels,
      datasets: [
        {
          label: '',
          fill: true,
          // lineTension: 0.1,
          backgroundColor: [
            'rgb(255, 128, 0)',
            'rgb(255, 255, 0)',
            'rgb(128, 255, 0)',
            'rgb(0, 255, 128)',
            'rgb(0, 191, 255)',
            'rgb(255, 0, 255)',
            'rgb(191, 255, 0)',
            'rgb(0, 128, 255)',
            'rgb(255, 0, 64)',
            'rgb(255, 191, 0)',
          ],
          borderColor: 'rgba(75,192,192,1)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(75,192,192,1)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(75,192,192,1)',
          pointHoverBorderColor: 'rgba(220,220,220,1)',
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: newArrayOfObj,
          // data: [{"rating": 10, "ratingcount": 1, y: 100}, {"rating": 11, "ratingcount": 1, y: 20}, {"rating": 11, "ratingcount": 1, y: 40}, {"rating": 11, "ratingcount": 1, y: 70}],
          //   parsing: {
          //     yAxisKey: "ratingpercentage"
          // }
        },
      ],
    };
    let xaxes = {
      ticks: {
        beginAtZero: true,
      },
      gridLines: {
        display: fromComponent == 'ahsReport' ? false : true,
        zeroLineWidth: 1,
      },
    };

    let barChartOption = {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        xAxes: [xaxes],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      tooltips: {
        mode: 'nearest',
        enabled: false,
        custom: function (tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.color = '#fff';
            tooltipEl.innerHTML = '<div id="toolTip"></div>';
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = '<table>';
            bodyLines.forEach(function (body, i) {
              innerHtml += body;
            });
            innerHtml +=
              '</table><div id="tailShadow"></div><div id="tail1"></div>';

            var tableRoot = tooltipEl.querySelector('#toolTip');
            tableRoot.innerHTML = innerHtml;
            if (tableRoot && tableRoot.innerHTML !== null) {
            }
          }

          var position = this._chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        },
        pieceLabel: {
          render: 'label',
          arc: true,
          fontColor: '#000',
          position: 'outside',
        },
        callbacks: labels,
      },
    };
    this.setState({ barChartData, barChartOption });
  };

  render() {
    const { barChartData, barChartOption } = this.state;

    return (
      <AUX>
        <Bar
          ref="chart"
          data={barChartData}
          width={100}
          height={75}
          options={barChartOption}
        />
      </AUX>
    );
  }
}

export default withRouter(BarChart);
