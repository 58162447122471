import React from 'react';
// import ReactDOM from 'react-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { combineReducers, createStore } from 'redux';
// import '../node_modules/fullcalendar/dist/fullcalendar.min.js';
import App from './App';
import './App.css';
import Interceptor from './services/Interceptor';
import * as serviceWorker from './serviceWorker';
import UIreducer from './store/reducers/reducer';
import userReducer from './reducer/user.reducer';

const rootReducer = combineReducers({
  ui_red: UIreducer,
  auth: userReducer,
});

const store = createStore(rootReducer);
const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

Interceptor.setupInterceptors();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(app);

serviceWorker.unregister();
