import React, { forwardRef, useEffect, useState } from 'react';
import {
  DirectionsRenderer,
  GoogleMap,
  InfoWindow,
  Marker,
  withGoogleMap,
  withScriptjs,
} from 'react-google-maps';

const LocationMap = withScriptjs(
  withGoogleMap(
    forwardRef((props, ref) => {
      const [directions, setDirections] = useState([]);
      const [selectedtype, setselectedtype] = useState('');
      const defaultProp = {
        waypoint: true,
        onlyRoute: true,
        travelMode: 'driving',
        routePolylineOptions: {
          strokeColor: '#ff2343',
          strokeOpacity: 0.8,
          strokeWeight: 4,
          clickable: true,
        },
        routeMarkerOptions: {
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 2,
            strokeColor: '#fff',
            fillColor: '#ff2343',
            fillOpacity: 0.7,
            strokeWeight: 0.5, // eslint-disable-line
          },
          label: { color: 'white', text: ' ' },
        },
        InfoWindow: true,
        infowindowOptions: {},
        markerOptions: {
          icon: {
            path: window.google.maps.SymbolPath.Marker,
            scale: 20,
            strokeColor: 'blue',
            fillColor: 'blue',
            fillOpacity: 1,
            strokeWeight: 1,
          },
          label: { color: 'black', text: ' ' },
        },
        routeMarker: [],
        placeMarker: [],
        isMarkerShown: false,
        // renderPlaceWindow: () => {},
        // renderRouteWindow: () => { }
      };
      // let delayFactor = 0;
      const finalProps = { ...defaultProp, ...props };

      const setDirectionsRoute = (places = []) => {
        let directionData = [];
        const directionsService = new window.google.maps.DirectionsService();
        for (let item of places) {
          const waypoints = [
            {
              location: {
                lat: Number(item[0].latitude),
                lng: Number(item[0].longitude),
                stopover: true,
              },
            },
            {
              location: {
                lat: Number(item[1].latitude),
                lng: Number(item[1].longitude),
                stopover: true,
              },
            },
          ];
          const origin = waypoints[0].location;
          const destination = waypoints[1].location;
          directionsService.route(
            {
              origin: origin,
              destination: destination,
              optimizeWaypoints: true,
              travelMode:
                finalProps.travelMode.toUpperCase() ||
                window.google.maps.TravelMode.DRIVING,
              waypoints: finalProps.waypoint ? waypoints : [],
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                directionData.push({
                  color: item[0].color || 'red',
                  route: result,
                });
                setDirections(JSON.parse(JSON.stringify(directionData)));
              } else {
                console.error(result);
              }
            }
          );
        }
      };

      useEffect(() => {
        if (props.places && props.places.length > 0) {
          setDirectionsRoute(props.places);
        } else {
          setDirections([]);
        }
      }, [props]);

      const overrideMarkerColor = (option, color) => {
        let ops = option;
        if (Object.keys(ops).length < 1) return ops;

        ops.icon.fillColor = color ? color : ops.icon.fillColor;
        return ops;
      };

      const onToggleOpen = (e) => {
        setselectedtype(e);
      };

      const RenderPlaceWindow = ({ data }) => {
        try {
          let html = finalProps.renderPlaceWindow ? (
            finalProps.renderPlaceWindow(data)
          ) : (
            <div
              className="pl-0"
              style={{ cursor: 'pointer' }}
              // onClick={(e) =>
              //   this.redirectWorkOrder(
              //     item.ID,
              //     item.WorkStatus
              //   )}
            >
              <h6 className="m-0">
                <b>{data.workOrder}</b>
              </h6>
              <b title={data.jobTitle}>{data.jobTitle}</b>
              <hr />
              <span style={{ fontSize: '11px' }}>
                {/* {(data.WorkStatus === 'Paused' || data.WorkStatus === 'Offered') ? 'No Appointments' : this.getTimeZone(data.FromTime, data.ToTime)} */}
                {/* <br /> */}
                <span style={{ fontSize: '13px' }}>{data.name}</span>
                <br />
                {data.type === 'customer' && data.customerAddress}
              </span>
            </div>
          );
          // return <div dangerouslySetInnerHTML={{ __html: html }} />
          return html;
        } catch (e) {
          return <p>{data.name}</p>;
        }
      };

      const getMarkerOption = (dest, src) => {
        let pt = '';
        if (!src) return dest;

        dest.label = { ...dest.label, ...src.label };
        pt = src.icon && src.icon.path ? src.icon.path.toUpperCase() : 'CIRCLE';
        src.icon.path = window.google.maps.SymbolPath[pt];
        dest.icon = { ...dest.icon, ...src.icon };
        return dest;
      };

      return (
        <div className="map-wrapper">
          <GoogleMap
            id="map"
            ref={ref}
            defaultZoom={finalProps.defaultZoom}
            center={finalProps.center}
            defaultCenter={finalProps.defaultCenter}
          >
            {directions &&
              directions.length > 0 &&
              directions.map((item, i) => (
                <DirectionsRenderer
                  key={i}
                  directions={item.route}
                  options={{
                    polylineOptions: {
                      ...finalProps.routePolylineOptions,
                      strokeColor: item.color,
                    },
                    suppressMarkers: finalProps.onlyRoute,
                    preserveViewport: true,
                    markerOptions: overrideMarkerColor(
                      finalProps.routeMarkerOptions,
                      item.color
                    ),
                  }}
                />
              ))}

            {finalProps.isMarkerShown &&
              finalProps.placeMarker &&
              finalProps.placeMarker.map((item, i) => (
                <Marker
                  key={i}
                  label={item.label || ''}
                  name={item.name}
                  title={item.name}
                  position={{
                    lat: Number(item.latitude),
                    lng: Number(item.longitude),
                  }}
                  // icon={{ ...finalProps.routeMarkerOptions.icon, ...(item.option && item.option.icon || null) }}
                  onClick={() => onToggleOpen(item.type)}
                  // {...getMarkerOption(finalProps.markerOptions, item.option)}
                  // {...{ ...finalProps.markerOptions, ...item.option }}
                >
                  {finalProps.InfoWindow && selectedtype === item.type && (
                    <InfoWindow
                      onCloseClick={() => onToggleOpen('')}
                      {...finalProps.infowindowOptions}
                    >
                      <div className="info-window">
                        <RenderPlaceWindow data={item} />
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}

            {finalProps.children && finalProps.children}
          </GoogleMap>
        </div>
      );
    })
  )
);

export default LocationMap;
