import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../../helper/authApi';
import Api from '../../../../helper/api';
import customFunctions from '../../../../helper/customFunctions';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../../components/Pagination';

class AllApptTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appointments: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      startDate: '',
      endDate: '',
      technicianId: null,
    };
  }
  async componentDidMount() {
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'APPT ID',
        field: 'apptid',
        width: 150,
      },
      {
        label: 'FIRST NAME',
        field: 'firstname',
        width: 150,
      },
      {
        label: 'LAST NAME',
        field: 'lastname',
        width: 150,
      },
      {
        label: 'JOB ID',
        field: 'jobid',
        width: 150,
      },
      {
        label: 'SCHEDULED BY',
        field: 'scheduledby',
        width: 150,
      },
      {
        label: 'APPT STATUS',
        field: 'apptstatus',
        width: 150,
      },
      {
        label: 'CUSTOMER FIRST',
        field: 'cfirstname',
        width: 150,
      },
      {
        label: 'CUSTOMER LAST',
        field: 'clastname',
        width: 150,
      },
      {
        label: 'JOB TITLE',
        field: 'jobtitle',
        width: 150,
      },
      {
        label: 'SERVICE TYPE',
        field: 'servicetype',
        width: 150,
      },
      {
        label: 'DATE & TIME',
        field: 'dateandtime',
        width: 180,
      },
      {
        label: 'DATE COMPLETED',
        field: 'datecompleted',
        width: 150,
      },
      {
        label: 'DEPARTED TIME',
        field: 'departedtime',
        width: 150,
      },
      {
        label: 'STARTED TIME',
        field: 'startedtime',
        width: 150,
      },
      {
        label: 'DRIVE TIME',
        field: 'drivetime',
        width: 150,
      },
      {
        label: 'WORK TIME',
        field: 'worktime',
        width: 150,
      },
      {
        label: 'VIEW JOB',
        field: 'actions',
        width: 150,
      },
    ];
    this.setState(
      {
        columns,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        technicianId:
          this.props &&
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.technicianId,
      },
      () => {
        this.forceUpdate();
        this.getWorkOrder();
      }
    );
  }
  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      this.setState(
        {
          startDate: props.startDate,
          endDate: props.endDate,
          technicianId: props.technicianId,
        },
        () => {
          this.forceUpdate();
          this.getWorkOrder();
        }
      );
    }
  }
  getWorkOrder = async (currentPage) => {
    let { pagination, startDate, endDate, technicianId } = this.state;

    this.setState({ currentPage });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.getWorkOrder}?skip=${skip}&limit=${pagination.itemsPerPage}&from_date=${new Date(startDate).toISOString()}&to_date=${new Date(endDate).toISOString()}`;
    if (technicianId) {
      endPoint += `&technician=${technicianId}`;
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          appointments: data.data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.props.callback();
  };

  downloadCSVForAllAppt = async () => {
    let { startDate, endDate, technicianId } = this.state;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.downloadCSVForAllAppt}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}`;
    if (technicianId) {
      endPoint += `&tech_id=${technicianId}`;
    }
    window.open(endPoint, '_blank');
  };

  setTableData = () => {
    const { appointments, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    appointments &&
      appointments.length &&
      appointments.map((appoint, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          apptid: appoint.WorkOrder || '-',
          firstname:
            (appoint.Technician && appoint.Technician.split(' ')[0]) || '-',
          lastname:
            (appoint.Technician && appoint.Technician.split(' ')[1]) || '-',
          jobid: appoint.ID || '-',
          scheduledby: appoint.Technician || '-',
          apptstatus: appoint.WorkStatus || '-',
          cfirstname:
            (appoint.CustomerName && appoint.CustomerName.split(' ')[0]) || '-',
          clastname:
            (appoint.CustomerName && appoint.CustomerName.split(' ')[1]) || '-',
          jobtitle: appoint.job_title || '-',
          servicetype: appoint.WorkOrderType || '-',
          dateandtime:
            (appoint.WorkOrderDate &&
              customFunctions.getMDYDateTime(appoint.WorkOrderDate)) ||
            '-',
          datecompleted:
            (appoint.FirstApptDate &&
              customFunctions.getMDYDateTime(appoint.FirstApptDate)) ||
            '-',
          departedtime:
            (appoint.DepartedTime &&
              customFunctions.getMDYDateTime(appoint.DepartedTime)) ||
            '-',
          startedtime:
            (appoint.StartedTime &&
              customFunctions.getMDYDateTime(appoint.StartedTime)) ||
            '-',
          drivetime: appoint.DriveTime || '-',
          worktime: appoint.WorkTime || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${appoint.ID}`, {
                    orderDetails: appoint,
                    type: 'jobs',
                  });
                }}
              >
                <i className="mdi mdi-eye" id="viewJob" title="View Job"></i>
              </a>
            </React.Fragment>
          ),
        });
        return appointments;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6> {this.props.title}</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                if (this.props.csvType === 'downloadCSVForAllAppt') {
                  this.downloadCSVForAllAppt();
                }
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>
        {/* <Table
        columns={columns}
        rows={rows}
        showPagination
        paginationProps={{
          ...pagination,
          activePage: pagination.current_page,
          onPageChange: (pageNo) => {
            this.getWorkOrder(pageNo);
          },
        }}
        tableclassName="table-bordered"
        dropdownContainerclassName="mb-3"
        emptyRender={() => <p className="text-center mb-0">No record found</p>}
      /> */}
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="400px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getWorkOrder}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(AllApptTables);
