import Aux from '../../hoc/Aux_';
import Sidebar from './Sidebar';
import Header from './Header';
import Footer from './Footer';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import customFunctions from './../../helper/customFunctions';
import { connect } from 'react-redux';

class layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      route: '',
    };
  }

  componentDidMount = () => {
    let location = window.location.pathname.split('/');
    this.setState({
      route: location[1],
    });
  };

  componentWillReceiveProps = () => {
    let location = window.location.pathname.split('/');
    this.setState({
      route: location[1],
    });
  };

  render() {
    const { toggle } = this.props;
    return (
      <Aux>
        {customFunctions.getLocalStorage('isLogin') === 'true' ? (
          <main>
            <div id="wrapper">
              <Sidebar toggle={toggle} />
              <div
                className={`content-page ${toggle ? 'col-sm-12' : ''}`}
                style={{
                  paddingLeft: toggle ? 0 : '20px',
                  paddingRight: 0,
                  marginLeft: toggle ? 0 : '217px',
                }}
              >
                <div className="content">
                  <Header />
                  {this.props.children}
                </div>
                <Footer />
              </div>
            </div>
          </main>
        ) : (
          this.props.children
        )}
      </Aux>
    );
  }
}

const mapStateToProps = (state) => ({
  toggle: state.auth.toggle,
});

export default withRouter(connect(mapStateToProps, null)(layout));
