import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import AddAttachment from './addFileAttachment';
import CustomTable from '../../../components/Table/simpletable.component';
import swal from 'sweetalert';
import { Col, Row } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import Loader from '../../../components/Loader';

class FileListComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      customerId: null,
      rows: [],
      showDelete: false,
      attachId: null,
      WorkOrderId: null,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      column: [],
      isShowLoader: false,
    };
  }

  actionColumnCallback = ({ id }) => {
    return (
      <React.Fragment>
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            this.setState({ showDelete: true, attachId: id });
          }}
        >
          <i className="mdi mdi-delete text-danger" title="Delete File"></i>
        </a>
      </React.Fragment>
    );
  };

  componentDidMount = async () => {
    const columns = [
      {
        label: 'Sr.no',
        id: 'index',
      },
      {
        label: 'Attachment',
        id: 'url',
        render: ({ url }) => (
          <React.Fragment>
            <a href={url} target="blank">
              {' '}
              {url.split(`${Api.s3url}attachments/`)}{' '}
            </a>
          </React.Fragment>
        ),
      },
      {
        label: 'Action',
        id: 'id',
        render: this.actionColumnCallback,
      },
    ];

    this.setState({ column: columns });
    if (this.props) {
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.customerId
      ) {
        await this.setState({
          customerId: await this.props.match.params.customerId,
        });
      }
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.orderDetails
    ) {
      const WorkOrderId = this.props.location.state.orderDetails.ID;
      this.setState({ WorkOrderId, isShowLoader: true });
    }
    this.getFiles();
  };

  getFiles = async (currentPage) => {
    const { pagination } = this.state;
    const pageNo = currentPage || 1;
    const skip = pageNo * pagination.current_page - pagination.current_page;

    const endPoint = `${Api.getAttachment}`;
    const params = {
      work_order_id: this.state.customerId,
      skip: skip,
      limit: pagination.itemsPerPage,
    };

    const url = customFunctions.generateUrl(endPoint, params);
    const { data, message } = await AuthApi.getDataFromServer(url);

    if (data && data.data) {
      const newPagination = {
        current_page: pageNo,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };

      this.setState(
        {
          files: data.data.data,
          pagination: newPagination,
        },
        () => {
          this.setTableData();
        }
      );
    } else {
      swal(message, '', 'error');
      setTimeout(() => {
        this.props.history.goBack();
      }, 2000);
    }
    this.setState({
      isShowLoader: false,
    });
  };

  deleteAttachment = async () => {
    const { attachId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getAttachment}?attachment_id=${attachId}`
    );
    if (data) {
      this.setState({ showDelete: false, attachId: null }, () => {
        this.getFiles();
      });
      swal('Attachment deleted', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  setTableData = () => {
    const { files, pagination } = this.state;
    const rows = [];
    files &&
      files.length &&
      files.map((file, i) => {
        rows.push({
          index:
            (pagination.current_page - 1) * pagination.itemsPerPage + (i + 1),
          url: file.url,
          id: file.id,
        });
        return file;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getFiles(1);
  };

  clearSearch = () => {
    const { pagination } = this.state;
    this.getFiles(pagination.current_page);
  };

  parentCallBack = () => {
    this.getFiles(1);
  };

  render() {
    const { rows, pagination, showDelete, isShowLoader } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <Row>
          <Col md={12}>
            <AddAttachment parentCallBack={this.parentCallBack} />
          </Col>
          <Col md={12} className="">
            <div className="card m-b-20">
              <div className="p-r-10 p-t-10">
                <div className="d-flex justify-content-end">
                  <div className="card-body" style={{ minWidth: '300px' }}>
                    {showDelete && (
                      <SweetAlert
                        title="Are you sure, do you want to delete the attachment ?"
                        error
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                          this.deleteAttachment();
                        }}
                        onCancel={() => {
                          this.setState({ showDelete: false, attachId: null });
                        }}
                      >
                        You won't be able to revert this!
                      </SweetAlert>
                    )}
                    <CustomTable
                      columns={this.state.column}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.getFiles(pageNo);
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </AUX>
    );
  }
}
export default withRouter(FileListComponent);
