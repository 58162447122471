import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddRoleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleName: '',
      roleNameErr: '',
      roleId: null,
      editType: false,
      title: '',
      toggle: false,
    };
  }

  componentDidMount = () => {
    const roleId = this.props.data.id;

    this.setState({ title: this.state.title, roleId }, () =>
      customFunctions.setTitle(this.state.title)
    );
    if (roleId) {
      this.setState({ title: this.state.title, editType: true }, () =>
        customFunctions.setTitle(this.state.title)
      );
      this.getRoleData(roleId);
    }
  };

  static getDerivedStateFromProps(props, state) {
    if (props.data.id !== state.roleId || props.data.type !== state.type) {
      return {
        roleId: props.data.id,
        title: props.data.type,
      };
    }
    return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.data.id !== prevProps.data.id) {
      this.getRoleData(this.props.data.id);
      this.componentDidMount();
    }
  }

  getRoleData = async (roleI) => {
    const { roleId } = this.state;
    const endPoint = `${Api.getRoles}?role=${roleI}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        roleName: roleId ? data.data.data[0].role : '',
      });
    } else {
      swal(message, '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { roleName } = this.state;

    let roleNameErr = '',
      isValid = true;

    if (roleName === '') {
      roleNameErr = 'Role is required';
      isValid = false;
    }

    this.setState({
      roleNameErr,
    });

    if (isValid) {
      this.addRole();
    }
  };

  addRole = async (e) => {
    const { roleName, roleId } = this.state;
    const postData = {
      role: roleName.trim(),
    };

    const callback = roleId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.addRoleItem;
    if (roleId) {
      postData.role_id = parseInt(roleId);
    }
    const { data, message } = await callback(endPoint, postData);

    if (data && roleId) {
      swal('Role updated successfully', '', 'success');
      this.props.data.changeToggle(true);
      return;
    } else if (data) {
      swal('Role added successfully', '', 'success');
      this.setState({ roleName: '' });
      this.props.data.changeToggle(true);
      return;
    } else {
      swal(message, '', 'error');
    }
  };

  resetData = () => {
    this.setState({
      roleName: '',
      roleId: null,
    });
  };

  render() {
    const { roleName, roleId, roleNameErr, title } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form
                      onSubmit={this.validateForm}
                      autoComplete="off"
                      id="addRole"
                    >
                      {roleId && (
                        <div className="form-group row">
                          <label
                            htmlFor="roleId"
                            className="col-sm-2 col-form-label"
                          >
                            Role Id
                          </label>
                          <div className="col-sm-4">
                            <input
                              className="form-control"
                              disabled
                              value={roleId}
                              type="text"
                              placeholder="John"
                              id="roleId"
                              onChange={(e) => {
                                this.setState({ roleId: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          htmlFor="itemname"
                          className="col-sm-2 col-form-label"
                        >
                          Role<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-4">
                          <input
                            className="form-control"
                            value={roleName}
                            type="text"
                            placeholder="Role Name"
                            id="itemname"
                            onChange={(e) => {
                              this.setState({ roleName: e.target.value });
                            }}
                          />
                          <span id="err">{roleNameErr}</span>
                        </div>
                      </div>

                      <div className="form-group row d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light text-center"
                        >
                          Submit
                        </button>
                        <button
                          type="reset"
                          onClick={async () => {
                            this.resetData();
                            this.props.data.changeToggle(this.state.toggle);
                            this.props.history.push('/roles');
                          }}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddRoleComponent);
