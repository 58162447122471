import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

export default class Leaves extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      columns: [],
      LeaveData: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      isWriteAllowed: false,
      roleId: null,
      loginId: null,
      isShowLoader: false,
      isCancel: false,
      leaveId: null,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Leaves');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'leaves-accessleaves'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'leaves-accessleaves',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }

    this.setState(
      {
        roleId: userdata.role_id,
        loginId: userdata.login_id,
      },
      () => {
        this.getLeaves();
      }
    );

    const columns = [
      {
        label: 'Sr.No.',
        field: 'index',
        sort: 'disabled',
        width: 75,
      },
      {
        label: 'Technician',
        field: 'technician',
        sort: 'disabled',
        width: 200,
      },
      {
        label: 'Leave Date',
        field: 'date',
        sort: 'disabled',
        width: 200,
      },
      {
        label: 'Type',
        field: 'type',
        sort: 'disabled',
        width: 150,
      },
      {
        label: 'Duration Type',
        field: 'durationtype',
        sort: 'disabled',
        width: 120,
      },
      {
        label: 'Days',
        field: 'days',
        sort: 'disabled',
        width: 80,
      },
      {
        label: 'Applied On',
        field: 'applieddate',
        sort: 'disabled',
        width: 110,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 140,
      },
      {
        label: 'Action',
        field: 'action',
        sort: 'disabled',
        width: 100,
      },
    ];
    if (userdata.role_id === 5) columns.splice(1, 1);
    this.setState({ columns });
  };

  setTableData = () => {
    const { LeaveData, currentPage, limit, isWriteAllowed, roleId } =
      this.state;
    const page = currentPage || 1;
    const rows = [];
    LeaveData &&
      LeaveData.map((leave, i) => {
        leave.from_date = customFunctions.changeDateFormatMomentMDY(
          leave.from_date
        );
        leave.to_date = customFunctions.changeDateFormatMomentMDY(
          leave.to_date
        );
        leave.created_at = customFunctions.changeDateFormatMomentMDY(
          leave.created_at
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          date: leave.from_date + ' to ' + leave.to_date,
          technician: leave.name,
          type: leave.leave_type,
          durationtype: leave.duration_type,
          days:
            leave.days == 0
              ? leave.duration_type === 'Half Day'
                ? 0.5
                : 1
              : leave.days,
          applieddate: leave.created_at,
          status:
            leave.status && customFunctions.capitalizeFirstLetter(leave.status),
          action: (
            <React.Fragment>
              <a
                href="/"
                style={{
                  pointerEvents: isWriteAllowed ? '' : 'none',
                  marginRight: '10px',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: '/apply-leave',
                    state: { detail: leave },
                  });
                }}
              >
                <i
                  className="mdi mdi-eye"
                  id="editleave"
                  title="View leave"
                ></i>
              </a>
              {Number(roleId) === 1 && leave.status === 'approved' && (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.setState({
                      isCancel: true,
                      leaveId: leave.id,
                    });
                  }}
                >
                  <i
                    className="mdi mdi-close ltr-10 text-danger"
                    id="cancelleave"
                    title="Cancel leave"
                  ></i>
                </a>
              )}
            </React.Fragment>
          ),
        });
        return leave;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  cancelLeave = async () => {
    const { leaveId } = this.state;
    this.setState({ isShowLoader: true, isCancel: false });
    const postData = {
      id: leaveId,
      status: 'cancelled',
    };
    let endPoint = `${Api.updateLeaveStatus}`;
    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);
    if (data) {
      this.setState({ isCancel: false, leaveId: null }, () => {
        this.getLeaves();
      });
      swal('Leave cancelled', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  getLeaves = async (currentPage) => {
    const { limit, roleId, loginId } = this.state;
    this.setState({ currentPage, isShowLoader: true });
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.leavesUrl}?skip=${skip}&limit=${limit}`;
    if (roleId === 5) {
      endPoint += `&tech_id=${loginId}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          isShowLoader: false,
          LeaveData: data.data.data,
          totalCount: data.data.totalCount,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getLeaves();
  };

  deleteLeave = async () => {
    const { leaveId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.leavesUrl}?id=${leaveId}`
    );
    if (data) {
      this.setState({ showDelete: false, leaveId: null }, () => {
        this.getLeaves();
      });
      swal('Leave deleted!', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  downloadCSV = async () => {
    let endPoint = `${Api.leavesUrl}/getLeavesCsv?skip=${0}&limit=${10000}`;
    window.open(endPoint, '_blank');
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      isWriteAllowed,
      showDelete,
      columns,
      roleId,
      isShowLoader,
      isCancel,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All Leaves" />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {(showDelete || isCancel) && (
              <div className="sweetalert">
                <SweetAlert
                  title={`Are you sure, ${
                    isCancel
                      ? 'Do you want to cancel the leave?'
                      : 'Do you want to delete the leave?'
                  }`}
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    if (isCancel) {
                      this.cancelLeave();
                    } else {
                      this.deleteLeave();
                    }
                  }}
                  onCancel={() => {
                    this.setState({
                      isCancel: false,
                      showDelete: false,
                      leaveId: null,
                    });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6"></div>
                      {isWriteAllowed && roleId === 5 && (
                        <div className="col-sm-6 text-right pb-2">
                          <button
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.props.history.push('/apply-leave');
                            }}
                          >
                            Apply Leave
                          </button>
                        </div>
                      )}
                    </div>
                    {roleId === 1 && totalCount > 0 && (
                      <div className="text-right pb-2">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            this.downloadCSV();
                          }}
                        >
                          <i className="mdi mdi-download"></i> CSV
                        </button>
                      </div>
                    )}
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getLeaves}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
