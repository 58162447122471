import React, { Component } from 'react';
import FullCalenderComponent from '../../../components/FullCalender';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import { EventPopup } from './EventPopup';
import { Button } from 'react-bootstrap';
import FilterPopup from './filterPopup';
import { Row, Col } from 'react-bootstrap';

class dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardData: [],
      date: new Date(),
      showEventPopup: false,
      note: '',
      notesData: [],
      submitType: '',
      canDisable: false,
      isShowLoader: false,
      filterPopup: false,
      callBackOptions: '',
    };
    this.calendarRef = React.createRef();
    this._isMounted = false;
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.props.history.push('dashboard');
    if (this._isMounted) {
      const loginToken = customFunctions.getLocalStorage('token');
      const callbackData =
        customFunctions.getStringifyDataFromLocalStorage('callBackOptions');
      if (callbackData && Object.keys(callbackData).length > 0) {
        this._isMounted &&
          this.setState({
            callBackOptions: callbackData,
          });
      }
      if (!loginToken) {
        this.props.history.push('/login');
        return;
      }
      var userdata = await customFunctions.getDataFromLocalStorage(
        'userdata',
        true
      );
      this._isMounted &&
        this.setState(
          { roleId: userdata.role_id, login_id: userdata.login_id },
          () => {
            this.getDashboardData();
            this.forceUpdate();
          }
        );
      customFunctions.setTitle('Dashboard');
      var calprevElements = document.getElementsByClassName('fc-prev-button');
      var calnextElements = document.getElementsByClassName('fc-next-button');

      calnextElements[0].addEventListener('click', this.calenderNext);
      calprevElements[0].addEventListener('click', this.calenderPrev);
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  calenderPrev = (e) => {
    e.preventDefault();
    var d = new Date(this.state.date);
    d.setMonth(d.getMonth() - 1);

    let dt = new Date(d);
    this._isMounted &&
      this.setState(
        {
          date: dt,
        },
        () => {
          this.getDashboardData();
        }
      );
  };

  calenderNext = (e) => {
    e.preventDefault();
    var d = new Date(this.state.date);
    d.setMonth(d.getMonth() + 1, 1);

    let dt = new Date(d);
    this._isMounted &&
      this.setState(
        {
          date: dt,
          // date: new Date(
          //   this.state.date.setMonth(this.state.date.getMonth() + 1)
          // ),
        },
        () => {
          this.getDashboardData();
        }
      );
  };

  getDashboardData = async (callbackoptions) => {
    const { date, callBackOptions } = this.state;
    this._isMounted && this.setState({ isShowLoader: true });
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var yearMonth = y + '-' + m;

    let endPoint = `${Api.getDashboardData}?month_year=${yearMonth}`;
    if (callBackOptions) {
      endPoint += callBackOptions.techId
        ? `&technician=${callBackOptions.techId.value}`
        : '';
      endPoint += callBackOptions.workType
        ? `&order_type=${callBackOptions.workType.label}`
        : '';
      endPoint += callBackOptions.workStatus
        ? `&work_status=${callBackOptions.workStatus.label}`
        : '';
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this._isMounted && this.setState({ isShowLoader: false });
      const oldData = data && data.data;
      oldData.map((item) => {
        item.date = customFunctions.changeDateOnlyMDY(item.date);
        if (item.rtype === 'workorder') {
          item.title =
            item.title +
            ` Appt: ${item.date} Time: ${customFunctions.changeTimeOnlyHMS(
              item.FromTime
            )} to ${customFunctions.changeTimeOnlyHMS(item.ToTime)}`;
        }
        return item;
      });
      this._isMounted &&
        this.setState(
          {
            isShowLoader: false,
            dashboardData: oldData,
          },
          () => {
            this.getEvents();
            this.forceUpdate();
          }
        );
    } else {
      this._isMounted && this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  getEvents = async () => {
    const { date, dashboardData, roleId, login_id } = this.state;
    // var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();
    var yearMonth = y + '-' + m;

    let endPoint = `${Api.eventsDataUrl}?month_year=${yearMonth}`;
    if (roleId === 5) endPoint += `&tech_id=${login_id}`;
    if (roleId === 4) endPoint += `&cust_id=${login_id}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      data.data.data.map((item) => {
        item.date = customFunctions.getDate(item.date, '-');
        return item;
      });
      let alldata = [...dashboardData, ...data.data.data];
      this._isMounted &&
        this.setState(
          {
            dashboardData: [...alldata],
            notesData: data.data,
          },
          () => {
            this.forceUpdate();
          }
        );
    }
  };

  popupClose = () => {
    this._isMounted && this.setState({ showEventPopup: false });
  };

  handleChange = (e) => {
    this._isMounted && this.setState({ note: e.target.value });
    this.forceUpdate();
  };

  render() {
    let {
      dashboardData,
      showEventPopup,
      roleId,
      login_id,
      isShowLoader,
      filterPopup,
      callBackOptions,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid dashboard-calendar">
            <HelmetComponent title="Dashboard" />
            <Row>
              {roleId === 1 && (
                <Col sm={12}>
                  <Button
                    variant={
                      callBackOptions && Object.keys(callBackOptions).length > 0
                        ? 'success'
                        : 'primary'
                    }
                    style={{
                      float: 'right',
                      marginTop: '-1%',
                    }}
                    onClick={() => {
                      this.setState({ filterPopup: !filterPopup });
                    }}
                  >
                    {callBackOptions && Object.keys(callBackOptions).length > 0
                      ? `Filter applied`
                      : `Filter`}
                  </Button>
                </Col>
              )}
              <Col style={{ marginTop: '1%' }}>
                <FullCalenderComponent
                  plugins={[dayGridPlugin, interactionPlugin]}
                  schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_KEY}
                  initialView="dayGridMonth"
                  // editable={true}
                  headerToolbar={{
                    left: 'prev',
                    center: 'title',
                    right: 'next',
                  }}
                  dateClick={(res) => {
                    if (
                      Math.round(new Date(res.date).getTime() / 1000) >
                        Math.round(new Date().getTime() / 1000) ||
                      new Date(res.date).toLocaleDateString() ===
                        new Date().toLocaleDateString()
                    ) {
                      this.setState({
                        eventObj: res,
                        showEventPopup: true,
                        submitType: 'add',
                        eventDetail: '',
                        title: '',
                        canDisable: false,
                      });
                    }
                  }}
                  refs={this.calendarRef}
                  eventClick={(e) => {
                    customFunctions.setLocalStorage('activeTab', 'WO Details');
                    if (
                      (e.event._def.extendedProps.rtype === 'note' ||
                        e.event._def.extendedProps.rtype === 'event') &&
                      e.event._def.extendedProps.eventid
                    ) {
                      this.setState({
                        eventObj: '',
                        showEventPopup: true,
                        submitType: '',
                        eventDetail: e.event._def.extendedProps,
                        title: e.event._def.title,
                        canDisable:
                          Math.round(
                            new Date(
                              e.event._def.extendedProps.start_date
                            ).getTime() / 1000
                          ) < Math.round(new Date().getTime() / 1000)
                            ? true
                            : false,
                        // date: e.event.start
                      });
                    }
                  }}
                  allDay="true"
                  events={dashboardData.map((eve) => {
                    const className =
                      eve && eve.type
                        ? `${eve.rtype} hvac-${eve.type.replace(' ', '')}`
                        : `${eve.rtype}`;
                    return {
                      ...eve,
                      className: className,
                    };
                  })}
                />
                {roleId !== 4 && (
                  <EventPopup
                    showEventPopup={showEventPopup}
                    callbackfunction={() => this.getDashboardData()}
                    popupClose={() =>
                      this.setState({ showEventPopup: !showEventPopup })
                    }
                    {...this.state}
                    login_id={login_id}
                    roleId={roleId}
                    canDisable={this.state.canDisable}
                  />
                )}
                {roleId === 1 && filterPopup && (
                  <FilterPopup
                    callbackfun={(callbackoptions) => {
                      this.setState(
                        {
                          callBackOptions: callbackoptions,
                        },
                        () => {
                          this.getDashboardData();
                        }
                      );
                    }}
                    popupClose={() =>
                      this.setState({ filterPopup: !filterPopup })
                    }
                    filterPopup={filterPopup}
                    callBackOptions={callBackOptions}
                  />
                )}
              </Col>
            </Row>
          </div>
        </div>
      </AUX>
    );
  }
}

export default dashboard;
