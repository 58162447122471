import React, { useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import ColorCodePicker from '../../../components/ColorPicker';
import ModalInput from '../../../components/Modal/modal.component';

const TechnicianColorCode = (props) => {
  const { color } = props;
  const [taskModalShow, setTaskModalShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [technicianColor, setTechnicianColor] = useState(color);

  useEffect(() => {
    setTaskModalShow(props.show);
  }, [props]);

  const onTaskCancel = () => {
    setTaskModalShow(false);
    props.handleModalCLose(false);
    if (disableButton) return;
    setDisableButton(true);
  };

  const handleChangeComplete = (color) => {
    setTechnicianColor(color.hex);
  };

  const submitColor = async () => {
    await props.callback(technicianColor);
    setTaskModalShow(false);
    props.handleModalCLose(false);
    if (disableButton) return;
    setDisableButton(true);
  };

  return (
    <Card>
      <Card.Body>
        <ModalInput
          size="md"
          centered
          onHide={onTaskCancel}
          show={taskModalShow}
          label="Choose color"
          id="contained-modal-title-vcenter"
          modalBody={
            <>
              <div className="form-group row">
                <label
                  htmlFor="example-text-input"
                  className="col-sm-3 col-form-label"
                >
                  Color Picker<span className="text-danger">*</span>
                </label>
                <div className="col-sm-5">
                  <ColorCodePicker
                    color={technicianColor}
                    onChangeComplete={handleChangeComplete}
                    disableAlpha={true}
                  />
                </div>
              </div>
              <div className="form-group row justify-content-center">
                <div className="">
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={() => submitColor()}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    onClick={() => {
                      onTaskCancel();
                    }}
                    className="btn btn-secondary waves-effect m-l-10"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </>
          }
        />
      </Card.Body>
    </Card>
  );
};

export default withRouter(TechnicianColorCode);
