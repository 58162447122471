import React, { Component } from 'react';
import './style.css';

class CustomDropdown extends Component {
  state = {
    items: this.props.items || [],
    showItems: false,
    selectedItem: '',
    placeholder: this.props.placeholder ? this.props.placeholder : 'Select',
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.clearSelectedDropdown === true) {
      this.setState({ selectedItem: '', showItems: false });
    }
  }
  dropDown = () => {
    this.setState((prevState) => ({
      showItems: !prevState.showItems,
    }));
  };

  selectItem = (item) => {
    this.setState(
      {
        selectedItem: item,
        showItems: false,
      },
      () => {
        this.dropDown();
        this.props.handleSelect(item);
      }
    );
  };

  handleBlur = (e) => {
    if (e.target.tagName === 'INPUT') {
      return;
    }
    this.setState({
      showItems: false,
    });
  };

  render() {
    const { selectedItem, placeholder, showItems, items } = this.state;
    const { isFilterOn } = this.props;

    return (
      <div className="select-box--box">
        <div
          className={`select-box--container  ${
            isFilterOn ? 'filter-open' : ''
          }`}
          tabIndex="1"
          onClick={this.dropDown}
          onBlur={this.handleBlur}
        >
          <div
            className={`select-box--selected-item ${
              isFilterOn ? 'filter-open-text' : ''
            }`}
          >
            {isFilterOn
              ? 'Filtering is on'
              : selectedItem && selectedItem.name
              ? selectedItem.name
              : placeholder}
          </div>
          <div className="select-box--arrow">
            {!isFilterOn && (
              <span
                className={`${
                  showItems ? 'select-box--arrow-up' : 'select-box--arrow-down'
                }`}
              />
            )}
            {isFilterOn && (
              <button
                style={{ width: '38px', height: '34px', marginTop: '1px' }}
                type="button"
                onClick={this.props.clearAllFilter}
                className="btn btn-secondary active"
              >
                X
              </button>
            )}
          </div>

          <div
            style={{ display: showItems ? 'block' : 'none' }}
            className={'select-box--items'}
          >
            {items &&
              items.map((item, id) => (
                <div
                  key={id}
                  onClick={() => this.selectItem(item)}
                  className={selectedItem === item ? 'selected' : ''}
                >
                  {' '}
                  {item.imageUrl && (
                    <img src={item.imageUrl} width="30" height="29" alt="" />
                  )}
                  <span style={{ paddingLeft: '10px' }}>{item.name}</span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomDropdown;
