import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Api from '../../../helper/api';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
// import RadioGroup from '@material-ui/core/RadioGroup';
import RadioGroup from '@mui/material/RadioGroup';
import {
  SetComplaintTechnician,
  SetComplaintCustomer,
  SetComplaintWarrantyCode,
  SetComplaintCategory,
  SetComplaintStatus,
  SetComplaintMonth,
  SetComplaintMonthYear,
  SetComplaintYear,
  SetComplaintFromDate,
  SetComplaintToDate,
} from '../../../action/user.action';
import { connect } from 'react-redux';
import customFunctions from '../../../helper/customFunctions';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';
import SelectStaticDropdown from '../../../components/ReactSelectPagination/SelectStaticDropdown';

class ComplaintFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      complaintTechnician:
        this.props && this.props.complaintTechnician
          ? this.props.complaintTechnician
          : '',
      complaintCustomer:
        this.props && this.props.complaintCustomer
          ? this.props.complaintCustomer
          : '',
      complaintCategory:
        this.props && this.props.complaintCategory
          ? this.props.complaintCategory
          : '',
      complaintStatus:
        this.props && this.props.complaintStatus
          ? this.props.complaintStatus
          : '',
      isWeekSelected: '',
      isMonthSelected: '',
      isYearSeleced: '',
      monthyear:
        this.props && this.props.complaintMonthYear
          ? { name: this.props.complaintMonthYear }
          : '',
      year:
        this.props && this.props.complaintYear
          ? { name: this.props.complaintYear }
          : '',
      receivedValue: 'week',
      receivedRadioBtnValue: 'thisWeek',
      complaintToDate: '',
      complaintFromDate: '',
      complaintMonth: '',
      complaintMonthYear: '',
      complaintYear: '',
      Months: [
        {
          id: 1,
          name: 'January',
        },
        {
          id: 2,
          name: 'February',
        },
        {
          id: 3,
          name: 'March',
        },
        {
          id: 4,
          name: 'April',
        },
        {
          id: 5,
          name: 'May',
        },
        {
          id: 6,
          name: 'June',
        },
        {
          id: 7,
          name: 'July',
        },
        {
          id: 8,
          name: 'August',
        },
        {
          id: 9,
          name: 'September',
        },
        {
          id: 10,
          name: 'October',
        },
        {
          id: 11,
          name: 'November',
        },
        {
          id: 12,
          name: 'December',
        },
      ],
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (this.props.month) {
      this.setCurrentMonth(this.props.month);
    }

    this.setState({ roleId: userdata.role_id });
    this.startOfWeek(new Date());
  };

  setCurrentMonth = (monthid) => {
    const { Months } = this.state;
    var selectedMonth = Months.filter((month) => month.id === monthid);
    if (selectedMonth && selectedMonth.length) {
      this.setState({
        CurrentMonth: { id: selectedMonth[0].id, name: selectedMonth[0].name },
      });
    }
  };

  handleWeekBtnChange = (week) => {
    this.setState({
      isWeekSelected: true,
      isMonthSelected: false,
      isYearSeleced: false,
      isDateRangeSelected: false,
    });
  };

  handleMonthBtnChange = (month) => {
    const { SetComplaintFromDate, SetComplaintToDate } = this.props;
    var d = new Date();
    const { Months } = this.state;
    var n = Months[d.getMonth()];
    var currentMonth = n && Months.filter((month) => month.name === n.name);

    this.setState(
      {
        isMonthSelected: true,
        isWeekSelected: false,
        CurrentMonth:
          currentMonth && currentMonth[0].length
            ? { id: currentMonth[0].id, name: currentMonth[0].name }
            : '',
        isDateRangeSelected: false,
      },
      () => {
        SetComplaintFromDate('');
        SetComplaintToDate('');
      }
    );
  };

  handleYearBtnChange = (year) => {
    const { SetComplaintFromDate, SetComplaintToDate } = this.props;

    this.setState(
      {
        isYearSeleced: true,
        isMonthSelected: false,
        isWeekSelected: false,
        isDateRangeSelected: false,
        // year: year,
      },
      () => {
        SetComplaintFromDate('');
        SetComplaintToDate('');
      }
    );
  };

  handleDateRangeBtnChange = () => {
    this.setState({
      isYearSeleced: false,
      isMonthSelected: false,
      isWeekSelected: false,
      isDateRangeSelected: true,
      complaintToDate: '',
      complaintFromDate: '',
    });

    this.props.SetComplaintMonth('');
    this.props.SetComplaintMonthYear('');
    this.props.SetComplaintYear('');
  };

  onCancelBtn = () => {
    const { showFilter } = this.props;
    if (!showFilter) return;
  };

  onCancel = () => {
    const { onCancelButtonCallback } = this.props;
    if (onCancelButtonCallback) onCancelButtonCallback();
  };

  onChangeHandler = (event) => {
    this.setState({ receivedValue: event.target.value });
  };

  onRadioBtnChangeHandler = (event) => {
    this.setState({ receivedRadioBtnValue: event.target.value });
  };

  beforeOneWeek = () => {
    const { SetComplaintFromDate, SetComplaintToDate } = this.props;
    this.setState({ fromDate: '', toDate: '' });
    var date = new Date();
    let firstday;
    let dates = customFunctions.getLastWeek(date);
    firstday = customFunctions.formatDate(dates.firstDayOfLastWeek, '-', 'ymd');
    date = customFunctions.formatDate(dates.lastDayOfLastWeek, '-', 'ymd');
    SetComplaintFromDate(firstday);
    SetComplaintToDate(date);
    this.setState({ fromDate: firstday, toDate: date });
  };

  startOfWeek = (date) => {
    const { SetComplaintFromDate, SetComplaintToDate } = this.props;
    this.setState({ fromDate: '', toDate: '' });
    let firstday;
    var curr = new Date();
    var first = curr.getDate() - curr.getDay();
    var last = first + 6;
    firstday = customFunctions.formatDate(
      new Date(curr.setDate(first)),
      '-',
      'ymd'
    );
    // date = customFunctions.formatDate(new Date(curr.setDate(last)), '-', 'ymd');
    date = customFunctions.changeDateOnlyMDY(
      new Date(new Date().setDate(last))
    );
    SetComplaintFromDate(firstday);
    SetComplaintToDate(date);
    this.setState({ fromDate: firstday, toDate: date });
  };

  render() {
    const {
      isWeekSelected,
      complaintCategory,
      complaintStatus,
      isYearSeleced,
      isMonthSelected,
      receivedValue,
      receivedRadioBtnValue,
      complaintCustomer,
      complaintTechnician,
      complaintFromDate,
      complaintToDate,
      CurrentMonth,
      roleId,
      isDateRangeSelected,
      monthyear,
      year,
    } = this.state;

    const {
      SetComplaintTechnician,
      SetComplaintCustomer,
      SetComplaintMonth,
      SetComplaintMonthYear,
      SetComplaintYear,
      SetComplaintCategory,
      SetComplaintStatus,
    } = this.props;

    return (
      <AUX>
        <div
          className="card-body"
          style={{ border: '1px solid #f2f2f2', marginBottom: '20px' }}
        >
          <form>
            <RadioGroup field="radioTest" defaultValue="week">
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedValue === 'week'}
                        onChange={(e) => {
                          this.onChangeHandler(e);
                          this.handleWeekBtnChange(e.target.value);
                          var d = new Date();
                          this.startOfWeek(d);
                        }}
                        color="default"
                        value="week"
                        name="radio-button-demo"
                      />
                      Week
                    </div>
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedValue === 'month'}
                        onChange={(e) => {
                          this.onChangeHandler(e);
                          this.handleMonthBtnChange(e.target.value);
                          let newYear = new Date().getFullYear();
                          this.setState({ monthyear: { name: newYear } });
                          SetComplaintMonthYear(newYear);
                          let newDate = new Date().getMonth() + 1;
                          SetComplaintMonth(newDate);
                          SetComplaintYear('');
                          this.setCurrentMonth(newDate);
                        }}
                        value="month"
                        color="default"
                        name="radio-button-demo"
                      />
                      Month
                    </div>
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedValue === 'year'}
                        onChange={(e) => {
                          this.onChangeHandler(e);
                          this.handleYearBtnChange(e.target.value);
                          let newYear = new Date().getFullYear();
                          this.setState({ year: { name: newYear } });
                          SetComplaintYear(newYear);
                          SetComplaintMonthYear('');
                          SetComplaintMonth('');
                        }}
                        color="default"
                        value="year"
                        name="radio-button-demo"
                      />
                      Year
                    </div>
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedValue === 'dateRange'}
                        onChange={(e) => {
                          this.handleDateRangeBtnChange(e);
                          this.onChangeHandler(e);
                        }}
                        value="dateRange"
                        color="default"
                        name="radio-button-demo"
                      />
                      Date Range
                    </div>
                  </div>
                </div>
              </div>
              <br></br>
            </RadioGroup>
            {!isMonthSelected && !isYearSeleced && !isDateRangeSelected && (
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-sm-3">
                      <Radio
                        checked={receivedRadioBtnValue === 'thisWeek'}
                        onChange={(e) => {
                          this.onRadioBtnChangeHandler(e);
                          var d = new Date();
                          this.startOfWeek(d);
                        }}
                        value="thisWeek"
                        color="default"
                        name="radio_button"
                      />
                      This Week
                    </div>
                    <div className="col-sm-4">
                      <Radio
                        checked={receivedRadioBtnValue === 'lastWeek'}
                        onChange={(e) => {
                          var d = new Date();
                          this.onRadioBtnChangeHandler(e);
                          this.beforeOneWeek(d);
                        }}
                        value="lastWeek"
                        color="default"
                        name="radio_button"
                      />
                      Last Week
                    </div>
                  </div>
                </div>
              </div>
            )}
            {isMonthSelected && !isWeekSelected && !isDateRangeSelected && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">Month</div>
                    <div className="col-sm-6">Year</div>
                  </div>
                </div>
              </div>
            )}
            {!isMonthSelected && !isYearSeleced && isDateRangeSelected && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">From</div>
                    <div className="col-sm-6">To</div>
                  </div>
                </div>
              </div>
            )}
            {isYearSeleced &&
              !isMonthSelected &&
              !isWeekSelected &&
              !isDateRangeSelected && (
                <div className="row">
                  <div className="col-sm-12">Year</div>
                </div>
              )}
            {isYearSeleced &&
              !isMonthSelected &&
              !isWeekSelected &&
              !isDateRangeSelected && (
                <div className="row">
                  <div className="col-sm-6">
                    <SelectStaticDropdown
                      onSelectChange={(e) => {
                        this.setState(
                          { year: e ? { name: e.name } : '' },
                          () => {
                            if (e) {
                              SetComplaintYear(e.name);
                            }
                            if (!e) {
                              SetComplaintMonthYear('');
                              SetComplaintYear('');
                            }
                            this.forceUpdate();
                          }
                        );
                      }}
                      className="w-100"
                      isSearch={false}
                      value={year}
                      isClearable={year}
                      dropdownType="years"
                      selectPlaceholder="Select Year"
                    />
                  </div>
                </div>
              )}
            {isMonthSelected && !isWeekSelected && !isDateRangeSelected && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <SelectStaticDropdown
                        onSelectChange={(e) => {
                          this.setState(
                            {
                              CurrentMonth: e ? { id: e.id, name: e.name } : '',
                            },
                            () => {
                              if (e) {
                                SetComplaintMonth(e.id);
                                SetComplaintYear('');
                              }
                              if (!e) {
                                SetComplaintYear(new Date().getFullYear());
                                SetComplaintMonth('');
                                SetComplaintMonthYear('');
                              }
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={false}
                        value={CurrentMonth}
                        isClearable={CurrentMonth}
                        dropdownType="months"
                        selectPlaceholder="Select Month"
                      />
                    </div>
                    <div className="col-sm-6">
                      <SelectStaticDropdown
                        onSelectChange={(e) => {
                          this.setState(
                            { monthyear: e ? { name: e.name } : '' },
                            () => {
                              if (e) {
                                if (CurrentMonth) {
                                  SetComplaintMonthYear(e.name);
                                  SetComplaintYear('');
                                }
                                if (!CurrentMonth) SetComplaintYear(e.name);
                              }
                              if (!e) {
                                SetComplaintMonthYear('');
                                SetComplaintYear('');
                              }
                              this.forceUpdate();
                            }
                          );
                        }}
                        className="w-100"
                        isSearch={false}
                        value={monthyear}
                        isClearable={monthyear}
                        dropdownType="years"
                        selectPlaceholder="Select Year"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isYearSeleced && !isMonthSelected && isDateRangeSelected && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="row">
                    <div className="col-sm-6">
                      <DatePicker
                        className="form-control"
                        placeholderText="Select From Date"
                        selected={
                          complaintFromDate
                            ? complaintFromDate
                            : this.props.complaintFromDate
                        }
                        onChange={(date) => {
                          this.setState(
                            {
                              complaintFromDate: date,
                            },
                            () => {
                              let date = new Date(this.state.complaintFromDate);
                              var d = date.getDate();
                              var m = date.getMonth() + 1;
                              var y = date.getFullYear();
                              var dt =
                                y +
                                '-' +
                                (m <= 9 ? '0' + m : m) +
                                '-' +
                                (d <= 9 ? '0' + d : d);
                              this.props.SetComplaintFromDate(dt);
                            }
                          );
                        }}
                        name="startDate"
                        dateFormat="MM/dd/yyyy"
                      />
                    </div>
                    <div className="col-sm-6">
                      <DatePicker
                        className="form-control"
                        placeholderText="Select To Date"
                        selected={
                          complaintToDate
                            ? complaintToDate
                            : this.props.complaintToDate
                        }
                        onChange={(date) => {
                          this.setState(
                            {
                              complaintToDate: date,
                            },
                            () => {
                              let date = new Date(this.state.complaintToDate);
                              var d = date.getDate();
                              var m = date.getMonth() + 1;
                              var y = date.getFullYear();
                              var dt =
                                y +
                                '-' +
                                (m <= 9 ? '0' + m : m) +
                                '-' +
                                (d <= 9 ? '0' + d : d);
                              this.props.SetComplaintToDate(dt);
                            }
                          );
                        }}
                        name="endDate"
                        dateFormat="MM/dd/yyyy"
                        minDate={
                          complaintFromDate
                            ? complaintFromDate
                            : this.props.complaintFromDate
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <br></br>
            <div className="row">
              {roleId !== 5 && (
                <div className="col-sm-4 mb-4">
                  <SelectAsyncPaginate
                    onSelectChange={(e) => {
                      this.setState(
                        {
                          complaintTechnician: e
                            ? { id: e.id, name: e.name }
                            : '',
                        },
                        () => {
                          if (e)
                            SetComplaintTechnician({ id: e.id, name: e.name });
                          if (!e) SetComplaintTechnician('');
                          this.forceUpdate();
                        }
                      );
                    }}
                    className="w-100"
                    isSearch={true}
                    isClearable={complaintTechnician}
                    value={complaintTechnician}
                    apiname="technician"
                    endpoint={Api.technicianUrl + `?search=`}
                    selectPlaceholder="Select Technician"
                  />
                </div>
              )}
              {roleId !== 4 && (
                <div className="col-sm-4 mb-4">
                  <SelectAsyncPaginate
                    onSelectChange={(e) => {
                      this.setState(
                        {
                          complaintCustomer: e
                            ? { id: e.ID, name: e.name }
                            : '',
                        },
                        () => {
                          if (e)
                            SetComplaintCustomer({ id: e.ID, name: e.name });
                          if (!e) SetComplaintCustomer('');
                          this.forceUpdate();
                        }
                      );
                    }}
                    isClearable={complaintCustomer}
                    className="w-100"
                    isSearch={true}
                    value={complaintCustomer}
                    apiname="customer"
                    endpoint={Api.customerUrl + `?search=`}
                    selectPlaceholder="Customer Name"
                  />
                </div>
              )}
              <div className="col-sm-4">
                <SelectAsyncPaginate
                  onSelectChange={(e) => {
                    this.setState(
                      {
                        complaintCategory: e ? { name: e.name } : '',
                      },
                      () => {
                        if (e) SetComplaintCategory({ name: e.name });
                        if (!e) SetComplaintCategory('');
                        this.forceUpdate();
                      }
                    );
                  }}
                  isClearable={complaintCategory}
                  className="w-100"
                  isSearch={true}
                  value={complaintCategory}
                  apiname="complaint-category"
                  endpoint={Api.complaintCategoryUrl + `?search=`}
                  selectPlaceholder="Complaint Category"
                />
              </div>
              <div className="col-sm-4">
                <SelectAsyncPaginate
                  onSelectChange={(e) => {
                    this.setState(
                      {
                        complaintStatus: e ? { name: e.name } : '',
                      },
                      () => {
                        if (e) SetComplaintStatus({ name: e.name });
                        if (!e) SetComplaintStatus('');
                        this.forceUpdate();
                      }
                    );
                  }}
                  isClearable={complaintStatus}
                  className="w-100"
                  isSearch={true}
                  value={complaintStatus}
                  apiname="complaint-status"
                  endpoint={Api.complaintStatusUrl + `?search=`}
                  selectPlaceholder="Complaint Status"
                />
              </div>
            </div>
            <br></br>
            <div className="form-group row d-flex justify-content-center">
              <div className="col-sm-3">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    this.props.onFilterSearch();
                  }}
                  className="btn btn-primary waves-effect waves-light"
                >
                  Search
                </button>
                <button
                  type="reset"
                  onClick={() => {
                    this.onCancel();
                  }}
                  className="btn btn-secondary waves-effect m-l-10"
                >
                  Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      </AUX>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  SetComplaintTechnician: (complaintTechnician) =>
    dispatch(SetComplaintTechnician(complaintTechnician)),
  SetComplaintCustomer: (complaintWarantyCode) =>
    dispatch(SetComplaintCustomer(complaintWarantyCode)),
  SetComplaintWarrantyCode: (complaintCategory) =>
    dispatch(SetComplaintWarrantyCode(complaintCategory)),
  SetComplaintCategory: (complaintCategory) =>
    dispatch(SetComplaintCategory(complaintCategory)),
  SetComplaintStatus: (complaintStatus) =>
    dispatch(SetComplaintStatus(complaintStatus)),
  SetComplaintMonth: (complaintMonth) =>
    dispatch(SetComplaintMonth(complaintMonth)),
  SetComplaintMonthYear: (complaintMonthYear) =>
    dispatch(SetComplaintMonthYear(complaintMonthYear)),
  SetComplaintYear: (complaintYear) =>
    dispatch(SetComplaintYear(complaintYear)),
  SetComplaintFromDate: (complaintFromDate) =>
    dispatch(SetComplaintFromDate(complaintFromDate)),
  SetComplaintToDate: (complaintToDate) =>
    dispatch(SetComplaintToDate(complaintToDate)),
});

const mapStateToProps = (state) => ({
  complaintTechnician: state.auth.complaintTechnician,
  complaintWarantyCode: state.auth.complaintWarantyCode,
  complaintCustomer: state.auth.complaintCustomer,
  complaintCategory: state.auth.complaintCategory,
  complaintStatus: state.auth.complaintStatus,
  complaintFromDate: state.auth.complaintFromDate,
  complaintToDate: state.auth.complaintToDate,
  complaintMonth: state.auth.complaintMonth,
  complaintMonthYear: state.auth.complaintMonthYear,
  complaintYear: state.auth.complaintYear,
});
export default connect(mapStateToProps, mapDispatchToProps)(ComplaintFilter);
