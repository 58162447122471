import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import AddPaymentType from '../../../components/CustomerComplaint/AddPaymentType';
import swal from 'sweetalert';
import { uploadFile } from 'react-s3';
import Loader from '../../../components/Loader';
import SharedService from '../../../helper/SharedService';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class AddEditPayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      paymentDate: new Date(),
      estimationCompletionDate: new Date(),
      invoice: '',
      jobTotal: 0,
      percentage: 0,
      dailyRate: 0,
      payrollPaymentType: '',
      noOfDays: 0,
      payrollAmount: 0,
      paymentTypes: [
        {
          payment_type: '',
          credit_card_autho: '',
          check_credit: '',
          payment_amount: '',
          is_paid: false,
          deducted_amount: '',
          paymentAmountErr: '',
          paymentTypeErr: '',
        },
      ],
      paymentTotal: '',
      paymentTypeList: [],
      viewType: false,
      formType: 'Add',
      paymentData: null,
      paymentDateErr: '',
      payrollAmountErr: '',
      audioRecordOfPaymentErr: '',
      invoiceErr: '',
      paidErr: '',
      payment_type_Err: '',
      credit_card_autho_Err: '',
      check_credit_Err: '',
      workOrderData: [],
      workOrder: '',
      totalPayment: 0,
      payment_id: '',
      audioRecordOfPayment: null,
      audioRecordOfPaymentUrl: '',
      saveaudioRecordOfPayment: '',
      saveaudioRecordOfPaymentExt: '',
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    // const url_segment = this.props.match.url.split('/');
    this.setState({ id: await this.props.match.params.customerId });

    await this.getWorkOrderDetailsById();
    await this.getPaymentData();
    await this.getWorkOrder();
    await this.getPaymentType();
  };

  getPaymentData = async () => {
    const { id } = this.state;
    let endPoint = `${Api.payment}/by_work_order?work_order_id=${id}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data && data.data) {
      const paymentData = data.data;
      var saveaudioRecordOfPaymentExt =
        'audio/' + paymentData.audio_record_of_payment.split('.').pop();

      this.setState({
        workOrder: paymentData.work_order_id,
        paymentData: paymentData,
        payment_id: paymentData.id,
        paymentDate:
          paymentData.payment_date != null
            ? new Date(paymentData.payment_date)
            : '',
        invoice: paymentData.Invoice,
        jobTotal: paymentData.JobTotal,
        payroll_amount: paymentData.payroll_amount,
        tech_payroll: paymentData.tech_payroll,
        saveaudioRecordOfPayment: paymentData.audio_record_of_payment,
        paymentTypes: paymentData.payment_array
          ? JSON.parse(paymentData.payment_array)
          : [
              {
                payment_type: '',
                credit_card_autho: '',
                check_credit: '',
                payment_amount: '',
                deducted_amount: 0,
                is_paid: 0,
              },
            ],
        paymentTotal: paymentData.Total,
        saveaudioRecordOfPaymentExt: saveaudioRecordOfPaymentExt,
      });
    }
  };

  addPayment = (id) => {
    const { paymentTypes } = this.state;
    paymentTypes.push({
      payment_type: '',
      credit_card_autho: '',
      check_credit: '',
      payment_amount: '',
      is_paid: false,
      deducted_amount: 0,
    });
    this.setState({ paymentTypes });
  };

  removePayment = (id) => {
    const { paymentTypes } = this.state;
    paymentTypes.splice(id, 1);
    this.setState({ paymentTypes });
  };

  getPaymentType = async () => {
    const endPoint = `${Api.AddpaymentTypeUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        paymentTypeList: data.data.data,
      });
    }
  };

  getWorkOrder = async () => {
    const endPoint = `${Api.getWorkOrder}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        workOrderData: data.data.data,
      });
    }
  };

  getWorkOrderDetailsById = async () => {
    const { id } = this.state;
    const endPoint = `${Api.orderById}?work_order_id=${id}&type=1`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState({
        estimationCompletionDate:
          data.data[0].EstimatedCompletionDate !== null
            ? new Date(data.data[0].EstimatedCompletionDate)
            : '',
        jobTotal: data.data[0].JobTotal,
        percentage: data.data[0].percentage,
        dailyRate: data.data[0].daily_rate,
        payrollAmount: data.data[0].PayrollAmount,
        noOfDays: data.data[0].of_days,
      });
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { paymentDate, paymentTypes } = this.state;
    let paymentDateErr = '',
      isValid = true;

    if (paymentDate === '') {
      paymentDateErr = 'Payment Date is required';
      isValid = false;
    }

    if (paymentTypes.length > 0) {
      paymentTypes.map((payment, index) => {
        paymentTypes[index].paymentTypeErr = '';
        paymentTypes[index].paymentAmountErr = '';
        if (payment.payment_type === '') {
          paymentTypes[index].paymentTypeErr = 'Please enter payment type';
          isValid = false;
        }
        if (payment.payment_amount === '') {
          paymentTypes[index].paymentAmountErr = 'Please enter payment amount';
          isValid = false;
        }
      });
    }

    this.setState({
      paymentDateErr,
      paymentTypes,
    });

    if (isValid) {
      this.onSubmit();
    }
  };

  onSubmit = async () => {
    const {
      id,
      paymentDate,
      payrollAmount,
      audioRecordOfPayment,
      paymentTypes,
      invoice,
      payment_id,
      saveaudioRecordOfPayment,
    } = this.state;
    let payload = {};
    payload = {
      work_order_id: parseInt(id),
      payment_date: customFunctions.getDate(paymentDate),
      payroll_amount: payrollAmount,
      payments: paymentTypes || '',
      invoice_no: invoice ? invoice : null,
      audio_record_of_payment: saveaudioRecordOfPayment || '',
    };

    if (audioRecordOfPayment) {
      const imgUrl = await this.uploadAudioFile();
      if (imgUrl) {
        payload.audio_record_of_payment = imgUrl;
        this.setState({
          saveaudioRecordOfPayment: imgUrl,
          audioRecordOfPayment: '',
        });
      }
    }

    if (payment_id) {
      payload['payment_id'] = payment_id;
    }
    this.setState({
      isShowLoader: true,
    });
    const callback =
      payment_id !== '' ? AuthApi.putDataToServer : AuthApi.postDataToServer;
    const endPoint = Api.payment;
    const { data } = await callback(endPoint, payload);

    if (data) {
      SharedService.changesInPaymentFromAddEditPayment.next(true);
      if (id !== '') {
        swal('Payment Added successfully', '', 'success');
        await this.resetData();
        this.setState({
          isShowLoader: false,
        });
        return;
      }
      swal('Payment Added successfully', '', 'success');
      await this.resetData();
      this.setState({
        isShowLoader: false,
      });
      return;
    }
  };

  resetData = () => {
    this.setState({});
  };

  handleInputChange(event) {
    const target = event.target;
    const value = target.checked;
    this.setState({
      is_paid: value,
    });
  }

  handelAudioFileChange = (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const fileUrl = URL.createObjectURL(e.target.files[0]);
    this.setState({
      audioRecordOfPayment: e.target.files[0],
      audioRecordOfPaymentUrl: fileUrl,
      saveaudioRecordOfPayment: '',
    });
  };

  uploadAudioFile = async () => {
    this.setState({
      isShowLoader: true,
    });
    const { audioRecordOfPayment } = this.state;
    if (!audioRecordOfPayment) return null;

    const newImage = audioRecordOfPayment;
    const todayDate = new Date().getTime();
    const file = new File(
      [newImage],
      `payment/${newImage.name.split('.')[0]}${todayDate}.${
        newImage.name.split('.')[1]
      }`,
      { type: newImage.type }
    );

    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    this.setState({
      isShowLoader: false,
    });
    return imgData;
  };

  percentage = (percent, total) => {
    var deducted_amount = total - ((percent / 100) * total).toFixed(2);
    return deducted_amount;
  };

  showDeductedAmount = (amount, index, paymentType) => {
    const { paymentTypes, paymentTypeList } = this.state;
    let percent;

    const result = paymentTypeList.filter(
      (list, i) => list.payment_type === paymentType
    );
    if (result) {
      result.map((res) => {
        percent = res.percentage;
        return res;
      });
    }
    paymentTypes[index].deducted_amount = this.percentage(percent, amount);
    this.setState({
      paymentTypes,
    });
  };

  render() {
    const {
      paymentDate,
      paymentDateErr,
      estimationCompletionDate,
      invoice,
      jobTotal,
      percentage,
      dailyRate,
      payrollPaymentType,
      noOfDays,
      payroll_amount,
      paymentTypes,
      paymentTypeList,
      viewType,
      formType,
      saveaudioRecordOfPayment,
      isShowLoader,
      saveaudioRecordOfPaymentExt,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className={viewType ? '' : 'row'}>
          <div className={viewType ? '' : 'col-12'}>
            <div className="card m-b-20">
              <div className="card-body">
                <fieldset>
                  <form onSubmit={(e) => this.validate(e)} autoComplete="off">
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Payment Date <span className="text-danger">*</span>{' '}
                      </label>
                      <div className="col-sm-4">
                        <DatePicker
                          className="form-control"
                          selected={paymentDate}
                          onChange={(e) => {
                            this.setState({ paymentDate: e });
                          }}
                          name="startDate"
                          dateFormat="MM/dd/yyyy"
                        />
                        <span id="err">{paymentDateErr}</span>
                      </div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Estimated Completion Date
                      </label>
                      <div className="col-sm-4">
                        <DatePicker
                          className="form-control"
                          selected={estimationCompletionDate}
                          onChange={(e) => {
                            this.setState({ estimationCompletionDate: e });
                          }}
                          disabled="true"
                          name="startDate"
                          dateFormat="MM/dd/yyyy"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Invoice #
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          value={invoice}
                          type="text"
                          placeholder=""
                          onChange={(e) => {
                            this.setState({ invoice: e.target.value });
                          }}
                        />
                      </div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Job Total
                      </label>
                      <div className="col-sm-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="jobTotal">
                              $
                            </span>
                          </div>
                          <input
                            className="form-control"
                            value={jobTotal}
                            type="text"
                            placeholder="Please enter"
                            onChange={(e) => {
                              this.setState({ jobTotal: e.target.value });
                            }}
                            disabled="true"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="percentage"
                        className="col-sm-2 col-form-label"
                      >
                        Percentage
                      </label>
                      <div className="col-sm-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="jobTotal">
                              %
                            </span>
                          </div>
                          <input
                            className="form-control"
                            value={percentage}
                            type="text"
                            placeholder="Please enter"
                            id="percentage"
                            name="percentage"
                            onChange={(e) => {
                              this.setState({ percentage: e.target.value });
                            }}
                            disabled
                          />
                        </div>
                      </div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Daily Rate
                      </label>
                      <div className="col-sm-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="jobTotal">
                              $
                            </span>
                          </div>
                          <input
                            className="form-control"
                            value={dailyRate}
                            type="text"
                            placeholder="Please enter"
                            onChange={(e) => {
                              this.setState({ dailyRate: e.target.value });
                            }}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Payroll Payment Type
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          value={payrollPaymentType}
                          type="text"
                          placeholder="Please enter"
                          onChange={(e) => {
                            this.setState({
                              payrollPaymentType: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        # of Days
                      </label>
                      <div className="col-sm-4">
                        <input
                          className="form-control"
                          value={noOfDays}
                          type="text"
                          placeholder="Please enter"
                          onChange={(e) => {
                            this.setState({ noOfDays: e.target.value });
                          }}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Payroll Amount
                      </label>
                      <div className="col-sm-4">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text" id="jobTotal">
                              $
                            </span>
                          </div>
                          <input
                            className="form-control"
                            value={payroll_amount}
                            type="text"
                            placeholder="Please enter"
                            onChange={(e) => {
                              this.setState({ payroll_amount: e.target.value });
                            }}
                            disabled="true"
                          />
                        </div>
                      </div>
                      <label
                        htmlFor="example-text-input"
                        className="col-sm-2 col-form-label"
                      >
                        Audio Record Of Payment
                      </label>
                      <div className="col-sm-4">
                        <input
                          id="imageUpload"
                          type="file"
                          className=""
                          onChange={(e) => {
                            this.handelAudioFileChange(e);
                          }}
                          accept="audio/mp3, audio/wav"
                          crossOrigin="anonymous"
                        />
                        {saveaudioRecordOfPayment && (
                          <audio controls>
                            <source
                              src={saveaudioRecordOfPayment}
                              type={saveaudioRecordOfPaymentExt}
                            />
                          </audio>
                        )}
                      </div>
                    </div>

                    {paymentTypes.map((payment, index) => (
                      <div className="m-t-50">
                        <div className="form-group row">
                          <label
                            htmlFor="payment_type"
                            className="col-sm-2 col-form-label"
                          >
                            Payment Type
                          </label>
                          <div className="col-sm-10">
                            <select
                              className="form-control"
                              value={payment.payment_type}
                              onChange={(e) => {
                                paymentTypes[index].payment_type =
                                  e.target.value;
                                this.showDeductedAmount(
                                  paymentTypes[index].payment_amount,
                                  index,
                                  paymentTypes[index].payment_type
                                );
                                this.setState({ paymentTypes });
                              }}
                            >
                              <option value="">Select Payment</option>
                              {paymentTypeList.map((paymenttype, index) => (
                                <option
                                  value={paymenttype.payment_type}
                                >{`${paymenttype.payment_type}`}</option>
                              ))}
                            </select>
                            <span id="err">
                              {paymentTypes[index].paymentTypeErr}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="credit_card_autho"
                            className="col-sm-2 col-form-label"
                          >
                            Credit Card Autho#
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              value={payment.credit_card_autho}
                              name="credit_card_autho"
                              type="text"
                              placeholder="Please enter"
                              onChange={(e) => {
                                paymentTypes[index].credit_card_autho =
                                  e.target.value;
                                this.setState({ paymentTypes });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="check_credit"
                            className="col-sm-2 col-form-label"
                          >
                            Cheque / Credit#
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              value={payment.check_credit}
                              type="text"
                              name="check_credit"
                              placeholder="Please enter"
                              onChange={(e) => {
                                paymentTypes[index].check_credit =
                                  e.target.value;
                                this.setState({ paymentTypes });
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="payment_amount"
                            className="col-sm-2 col-form-label"
                          >
                            Payment Amount
                          </label>
                          <div className="col-sm-10">
                            <input
                              className="form-control"
                              value={payment.payment_amount}
                              name="payment_amount"
                              type="text"
                              placeholder="0"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  paymentTypes[index].payment_amount =
                                    e.target.value;
                                  this.showDeductedAmount(
                                    paymentTypes[index].payment_amount,
                                    index,
                                    paymentTypes[index].payment_type
                                  );
                                  this.setState({ paymentTypes });
                                }
                              }}
                            />
                            <span id="err">
                              {paymentTypes[index].paymentAmountErr}
                            </span>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="deducted_amount"
                            className="col-sm-2 col-form-label"
                          >
                            Actual Amount
                          </label>
                          <div className="col-sm-10">
                            <input
                              disabled="true"
                              className="form-control"
                              value={
                                payment.deducted_amount
                                  ? payment.deducted_amount
                                  : 0.0
                              }
                              name="payment_amount"
                              type="text"
                              placeholder="0"
                              onChange={(e) => {
                                const re = /^[0-9\b]+$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  paymentTypes[index].deducted_amount =
                                    e.target.value;
                                  this.setState({ paymentTypes });
                                }
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label
                            htmlFor="is_paid"
                            className="col-sm-2 col-form-label"
                          >
                            Paid
                          </label>
                          <div className="col-sm-10 p-t-10">
                            <input
                              type="checkbox"
                              name="paid"
                              checked={payment.is_paid}
                              onChange={(e) => {
                                paymentTypes[index].is_paid = e.target.checked
                                  ? 1
                                  : 0;
                                this.setState({ paymentTypes });
                              }}
                            />
                          </div>
                        </div>
                        {
                          <div className="form-group row">
                            <div className="col-sm-10 float-right">
                              {index >= 0 && (
                                <button
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={() => {
                                    this.addPayment();
                                  }}
                                >
                                  Add More
                                </button>
                              )}
                              &nbsp;
                              {index !== 0 && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.removePayment(index);
                                  }}
                                  className="btn btn-secondary waves-effect m-l-10"
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        }
                      </div>
                    ))}
                    {
                      <div className="form-group row justify-content-center">
                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            {formType === 'Add' ? 'Submit' : 'Update'}
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push(`/orderDetails`);
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    }
                  </form>
                </fieldset>
              </div>
            </div>
          </div>
        </div>

        <AddPaymentType
          getPaymentTypeCallback={() => {
            this.getPaymentType();
          }}
        />
      </AUX>
    );
  }
}

export default withRouter(AddEditPayment);
