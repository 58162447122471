import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 50,
  },
  {
    label: 'Contract Name',
    field: 'contractName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
    width: 150,
  },
];

class EmploymentContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      contracts: [],
      rows: [],
      contractId: null,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      title: 'Employment Contracts',
      isWriteAllowed: false,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-employmentcontract'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-employmentcontract',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    const { title } = this.state;
    customFunctions.setTitle(title);
    await this.getContracts();
  };

  getContracts = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.employmentContractUrl}?skip=${skip}&limit=50&search=${keyword}`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          contracts: data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message, '', 'error');
    }
  };

  setTableData = () => {
    const { contracts, limit, currentPage, isWriteAllowed } = this.state;
    const page = currentPage || 1;
    const rows = [];
    contracts &&
      contracts.length > 0 &&
      contracts.map((cont, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          contractName: cont.contract_name ? cont.contract_name : '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(
                    `/edit-employment-contract/${cont.id}`,
                    {
                      contractDetails: cont,
                      type: 'cont',
                    }
                  );
                }}
              >
                <i className="mdi mdi-lead-pencil" title="Edit Contract"></i>
              </a>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, contractId: cont.id });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title="Delete Contract"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return cont;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteContract = async () => {
    const { contractId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getContractItem}?contract_id=${contractId}`
    );
    if (data) {
      this.setState({ showDelete: false, contractId: null }, () => {
        this.getContracts();
      });
      swal('Employment contract deleted!', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getContracts();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getContracts();
    });
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      isWriteAllowed,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={this.state.title} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the employment contract ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteContract();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, contractId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-header">
                      <div className="left-section">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group d-flex">
                            <div className="input-wrap">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="buttons-wrap">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {isWriteAllowed && (
                        <div className="right-section">
                          <div className="right-actions">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={() => {
                                this.props.history.push(
                                  '/add-employment-contract'
                                );
                              }}
                            >
                              Add Employment Contract
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                      scrollX
                      maxHeight="350px"
                    />
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getContracts}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(EmploymentContract);
