import React, { Component } from 'react';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import axios from 'axios';
import Loader from '../../../components/Loader';

class SurveyAuth extends Component {
  state = {
    isShowLoader: false,
  };
  componentDidMount = async () => {
    const token = await this.props.match.params.token;
    if (token) {
      await customFunctions.setLocalStorage('surveyToken', token);
      this.validateToken(token);
    } else {
      this.props.history.push('/login');
    }
  };

  validateToken = async (token) => {
    this.setState({ isShowLoader: true });
    let endPoint = '';
    if (process.env.NODE_ENV === 'development') {
      endPoint = `${process.env.REACT_APP_API_URL_DEV}workorder_history/checkTokenValidation`;
    } else {
      endPoint = `${process.env.REACT_APP_API_URL}workorder_history/checkTokenValidation`;
    }
    await axios
      .get(endPoint, {
        headers: {
          'x-access-token': `${token}`,
        },
      })
      .then((res) => {
        this.props.history.push('/job-survey', {
          data: res.data.data,
        });
      })
      .catch((err) => {
        this.props.history.push('/job-survey', {
          tokenExpire: true,
        });
      });
    this.setState({ isShowLoader: false });
  };

  render() {
    return (
      <AUX>
        <Loader showLoader={this.state.isShowLoader} needFullPage={false} />
        <div className="accountbg"></div>
        <div className="wrapper-page"></div>
      </AUX>
    );
  }
}

export default SurveyAuth;
