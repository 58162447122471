import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddHomeWarrantyCompany extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      compTitle: '',
      description: '',
      compTitleErr: '',
      descriptionErr: '',
      warrantyCompanyId: '',
      companyData: [],
      title: '',
      paginationOptions: {},
    };
  }

  componentDidMount = async () => {
    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-homewarrantycompany',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    this.setState(
      {
        title: 'Add Home Warranty Company',
      },
      () => {
        customFunctions.setTitle(this.state.title);
      }
    );

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getWarrantyCompany();

      this.setState(
        {
          title: 'Edit  Home Warranty Company',
        },
        () => {
          customFunctions.setTitle(this.state.title);
        }
      );
    }
  };

  getWarrantyCompany = async () => {
    const companyData = this.props.location.state.detail;
    this.setState({
      warrantyCompanyId: companyData.id,
      compTitle: companyData.title,
      description: companyData.description,
      status: companyData.status,
    });
  };

  addWarranty = async () => {
    const {
      compTitle,
      description,
      status,
      warrantyCompanyId,
      paginationOptions,
    } = this.state;
    const postData = {
      title: compTitle,
      description: description,
    };
    if (warrantyCompanyId) {
      postData.homewarrantycompanyId = parseInt(warrantyCompanyId);
      postData.status = status;
    }

    const endPoint = `${Api.AddWarrantyCompany}`;

    const payload = customFunctions.cleanObject(postData);

    const callback = warrantyCompanyId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, payload);
    if (data && data.success !== false) {
      var $this = this;
      if (warrantyCompanyId) {
        swal('Home warranty company updated successfully', '', 'success').then(
          () => {
            $this.props.history.push('/home-warranty-company', {
              paginationObj: paginationOptions,
            });
          }
        );
      } else {
        swal('Home warranty company added successfully', '', 'success').then(
          () => {
            $this.props.history.push('/home-warranty-company', {
              paginationObj: paginationOptions,
            });
          }
        );
      }
    } else {
      swal(message, '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { compTitle, description } = this.state;
    let compTitleErr = '',
      descriptionErr,
      isValid = true;

    if (compTitle.trim() === '') {
      compTitleErr = 'Title is required';
      isValid = false;
    }

    if (description.trim() === '') {
      descriptionErr = 'Description is required';
      isValid = false;
    }

    this.setState({ compTitleErr, descriptionErr });
    if (isValid) {
      this.addWarranty();
    }
  };

  flushAllData = () => {
    this.setState({
      compTitle: '',
      description: '',
      warrantyCompanyId: '',
      compTitleErr: '',
      descriptionErr: '',
    });
  };

  render() {
    const {
      title,
      compTitle,
      compTitleErr,
      description,
      descriptionErr,
      paginationOptions,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm}>
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Title
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            value={compTitle}
                            onChange={(e) => {
                              this.setState({ compTitle: e.target.value });
                            }}
                          />
                          <span id="err">{compTitleErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Description
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Description"
                            value={description}
                            onChange={(e) => {
                              this.setState({ description: e.target.value });
                            }}
                          />
                          <span id="err">{descriptionErr}</span>
                        </div>
                      </div>

                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push(
                                '/home-warranty-company',
                                {
                                  paginationObj: paginationOptions,
                                }
                              );
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddHomeWarrantyCompany);
