import React from 'react';
import FullCalendar from '@fullcalendar/react';

const FullCalenderComponent = (props) => {
  const {
    plugins,
    initialView,
    editable,
    headerToolbar,
    refs,
    events,
    dateClick,
    eventClick,
    eventRender,
    allDay,
  } = props;
  return (
    <div>
      <FullCalendar
        plugins={plugins}
        initialView={initialView}
        editable={editable}
        headerToolbar={headerToolbar}
        ref={refs}
        events={events}
        dateClick={dateClick}
        eventClick={eventClick}
        eventRender={eventRender}
        allDay={allDay}
        showNonCurrentDates={false}
        fixedWeekCount={false}
      />
    </div>
  );
};

export default FullCalenderComponent;
