import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      module_name: '',
      moduleNameErr: '',
      moduleId: '',
      title: '',
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('All Modules');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getModules();
      customFunctions.setTitle('Edit Module');
      this.setState({
        title: 'Edit Module',
      });
    } else {
      customFunctions.setTitle('Add Module');
      this.setState({
        title: 'Add Module',
      });
    }
  };

  getModules = async () => {
    const moduleData = this.props.location.state.detail;
    this.setState({
      moduleId: moduleData.module_id,
      module_name: moduleData.module_name,
    });
  };

  addModule = async () => {
    const { module_name, moduleId } = this.state;
    const postData = {
      module_name,
    };
    const endPoint = `${Api.moduleUrl}`;
    const callback = moduleId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (moduleId) {
      postData.module_id = parseInt(moduleId);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (moduleId) {
        swal('Module updated successfully', '', 'success').then(() => {
          $this.props.history.push('/modules', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('Module added successfully', '', 'success').then(() => {
          $this.props.history.push('/modules');
        });
      }
    } else {
      swal(message, '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { module_name } = this.state;
    let moduleNameErr = '';
    let isValid = true;
    if (module_name.trim() === '') {
      moduleNameErr = 'Module is required';
      isValid = false;
    }
    this.setState({ moduleNameErr });
    if (isValid) {
      this.addModule();
    }
  };

  render() {
    const { module_name, moduleNameErr, title } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-primary waves-effect waves-light float-right"
                          onClick={() => {
                            this.props.history.goBack();
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Module<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Module"
                            value={module_name}
                            onChange={(e) => {
                              this.setState({ module_name: e.target.value });
                            }}
                          />
                          <span id="err">{moduleNameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/modules', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddModule);
