import React, { useEffect, useState } from 'react';
import { AsyncPaginate } from 'react-select-async-paginate';
import AuthApi from '../../helper/authApi';

const SelectAsyncPaginate = ({
  isClearable,
  value,
  clearSelected,
  onSelectChange,
  selectPlaceholder,
  key,
  className,
  disabled,
  isSearch,
  endpoint,
  apiname,
  clearCacheOnID,
  clearSelectedByParent,
}) => {
  const [selectedValue, setSelectedValue] = useState({});

  useEffect(() => {
    if (value && value.name !== (selectedValue && selectedValue.name)) {
      setSelectedValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (clearSelectedByParent) setSelectedValue({});
  }, [clearSelectedByParent]);

  const loadOptions = async (searchQuery, loadedOptions, { page }) => {
    const searchKeyword = `${searchQuery ? searchQuery.toLowerCase() : ''}`;
    let pagination = '';

    if (apiname !== 'job-technician') {
      pagination = `&skip=${page ? page + '0' : '0'}&limit=10`;
    }
    const endPoint = searchQuery
      ? `${endpoint}${searchKeyword}${pagination}`
      : `${endpoint}${pagination}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    let newdata = [];

    if (apiname === 'job-technician') {
      if (
        data &&
        data.data &&
        data.data[0] &&
        data.data[0].multitech &&
        data.data[0].multitech.length
      ) {
        data.data[0].multitech.map((dt) => {
          dt.name = dt.techname;
        });
        newdata = data.data[0].multitech;
        return {
          options: newdata,
          hasMore: false,
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }

    if (apiname === 'job-installer') {
      if (
        data &&
        data.data &&
        data.data[0] &&
        data.data[0].multitech &&
        data.data[0].multitech.length
      ) {
        let newTechArr = [];
        newdata = data.data[0].multitech.map((dt, i) => {
          dt.name = dt.techname;
          if (
            dt.is_primary === 1 ||
            (dt.is_primary === 0 && dt.tech_function !== 'Installation')
          ) {
            return false;
          } else {
            if (dt.is_primary === 0 && dt.tech_function === 'Installation') {
              if (newTechArr && newTechArr.length > 0) return false;
              newTechArr.push(dt);
            }
          }
        });
        return {
          options: newTechArr,
          hasMore: false,
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }

    if (apiname === 'contracts') {
      if (data && data.data && data.data.length) {
        data.data.map((dt) => {
          dt.name = dt.contract_name;
        });
        newdata = data.data;
        return {
          options: newdata,
          hasMore: false,
        };
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }

    if (
      apiname !== 'job-technician' &&
      apiname !== 'contracts' &&
      apiname !== 'job-installer'
    ) {
      if (data && data.data && data.data.data && data.data.data.length) {
        data.data.data.map((dt) => {
          if ((apiname === 'vendor' || apiname === 'technician') && dt.name) {
            //for vendor api
            // dt.name = dt.name;
          } else if (apiname === 'homewarranty' && dt.title) {
            //for home warranty company api
            dt.name = dt.title;
          } else if (apiname === 'customer' && dt.CustomerName) {
            //for customer api
            dt.name = dt.CustomerName;
          } else if (apiname === 'workordertype' && dt.order_type) {
            //for workorder type api
            dt.name = dt.order_type;
          } else if (apiname === 'listtype' && dt.ListType) {
            //for listtype api
            dt.name = dt.ListType;
          } else if (apiname === 'listitem' && dt.ListItem) {
            //for listitem api
            dt.name = dt.ListItem;
          } else if (apiname === 'workorder' && dt.WorkOrder) {
            //for purchase workorder
            dt.name = dt.WorkOrder;
          } else if (apiname === 'complaint-category' && dt.category) {
            //for complaint-category
            dt.name = dt.category;
          } else if (apiname === 'complaint-status' && dt.status) {
            //for complaint-status
            dt.name = dt.status;
          } else if (apiname === 'paymenttype' && dt.payment_type) {
            //for paymenttype
            dt.name = dt.payment_type;
          } else if (apiname === 'dispatcher') {
            //for dispatcher
            // dt.name = dt.name;
          }
        });
        newdata = data.data.data;
      } else {
        return {
          options: [],
          hasMore: false,
        };
      }
    }
    if (newdata && newdata.length) {
      return {
        options: newdata,
        hasMore: newdata.length >= 1,
        additional: {
          page: page + 1,
        },
      };
    }
  };

  const onSelectChangeData = (option) => {
    if (clearSelectedByParent) setSelectedValue(option);
    if (clearSelected) {
      setSelectedValue({});
    } else {
      setSelectedValue(option);
    }
    if (onSelectChange) {
      onSelectChange(option);
    }
  };

  return (
    <AsyncPaginate
      key={key}
      value={selectedValue && selectedValue.name ? selectedValue : null}
      className={className}
      loadOptions={loadOptions}
      getOptionValue={(option) => option.name}
      getOptionLabel={(option) => option.name}
      onChange={onSelectChangeData}
      isDisabled={disabled}
      isSearchable={isSearch}
      placeholder={selectPlaceholder}
      isClearable={isClearable ? isClearable : false}
      cacheUniqs={[clearCacheOnID]}
      additional={{
        page: 0,
      }}
    />
  );
};

export default SelectAsyncPaginate;
