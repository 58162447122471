import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import AdvancePaycheckComponent from '../MainContent/AdvancePaycheck';
import AHSworkorders from '../MainContent/AHSworkorder/workorders';
import AuthorizationRequired from '../MainContent/AuthorizationRequired';
import Complaints from '../MainContent/Complaints';
import AddComplaint from '../MainContent/Complaints/addComplaint';
import ComplaintData from '../MainContent/Complaints/ComplaintData';
import Customer from '../MainContent/Workorder';
import AddCustomers from '../MainContent/Workorder/addCustomers';
import AddEditWorkOrderParts from '../MainContent/Workorder/AddEditWorkorderParts';
import AddFileAttachment from '../MainContent/Workorder/addFileAttachment';
import AddNewExpense from '../MainContent/Workorder/AddNewExpense';
import CustomerModule from '../MainContent/Workorder/CustomerModule';
import orderDetails from '../MainContent/Workorder/orderDetails';
import Profit from '../MainContent/Workorder/Profit';
import ProfitLoss from '../MainContent/Workorder/ProfitLoss';
import PurchaseOrder from '../MainContent/Workorder/purchaseOrder';
import purchaseView from '../MainContent/Workorder/purchaseView';
import Tab from '../MainContent/Workorder/Tab';
import WorkModule from '../MainContent/Workorder/WorkModule';
import WorkOrder from '../MainContent/Workorder/WorkOrder';
import WorkOrderParts from '../MainContent/Workorder/WorkorderParts';
import AddContractComponent from '../MainContent/CustomerBilling/addContract.component';
import AddBillingItems from '../MainContent/CustomerBilling/addItems.component';
import AddLineItem from '../MainContent/CustomerBilling/addLineItem.component';
import SettingsComponent from '../MainContent/CustomerBilling/addSettings.component';
import AddTaxRateComponent from '../MainContent/CustomerBilling/addTaxRate.component';
import ContractComponent from '../MainContent/CustomerBilling/contracts.component';
import EstimateComponent from '../MainContent/CustomerBilling/estimate.component';
import EstimateDetailsComponent from '../MainContent/CustomerBilling/estimateDetails.component';
import InvoiceCustomerPayment from '../MainContent/CustomerBilling/invoiceCustomerPay';
import InvoiceDetailsComponent from '../MainContent/CustomerBilling/invoiceDetails.component';
import InvoiceComponent from '../MainContent/CustomerBilling/invoices.component';
import ItemComponent from '../MainContent/CustomerBilling/items.component';
import TaxRateComponent from '../MainContent/CustomerBilling/taxRate.component';
import Customers from '../MainContent/Customers';
import AddCustomer from '../MainContent/Customers/addCustomer';
import CustomerAddresses from '../MainContent/Customers/addresses';
import Dashboard from '../MainContent/Dashboard/Dashboard';
import AddExpense from '../MainContent/Expense/AddExpense';
import ForgetPassword from '../MainContent/ForgetPassword';
import ResetPassword from '../MainContent/ForgetPassword/resetPassword';
import Leaves from '../MainContent/Leaves';
import AddLeave from '../MainContent/Leaves/addLeave';
import Login from '../MainContent/Login';
import AddComplaintCategory from '../MainContent/Master/addComplaintCategory';
import AddComplaintStatus from '../MainContent/Master/addComplaintStatus';
import AddEditEmailTemplate from '../MainContent/Master/addEditEmailTemplate';
import AddEditPart from '../MainContent/Master/addEditParts';
import AddEditVendor from '../MainContent/Master/addEditVendor';
import AddEmpContractComponent from '../MainContent/Master/addEmploymentContract';
import AddHomeWarrantyCode from '../MainContent/Master/addHomeWarrantyCode';
import AddHomeWarrantyCompany from '../MainContent/Master/addHomeWarrantyCompany';
import AddListItem from '../MainContent/Master/addListItem';
import AddListType from '../MainContent/Master/addListType';
import AddEditModule from '../MainContent/Master/addModule';
import AddPaymentType from '../MainContent/Master/addPaymentType';
import AddPreMaintenanceContract from '../MainContent/Master/addPreMaintenanceContract';
import AddEditSubModule from '../MainContent/Master/addSubModules';
import AddWorkOrderType from '../MainContent/Master/addWorkOrderType';
import ComplaintCategory from '../MainContent/Master/complaintCategory';
import ComplaintStatus from '../MainContent/Master/complaintStatus';
import EmailTemplates from '../MainContent/Master/emailTemplates';
import EmploymentContract from '../MainContent/Master/employmentContracts';
import HomeWarrentyCode from '../MainContent/Master/homeWarrentyCode';
import HomeWarrantyCompany from '../MainContent/Master/homeWarrentyCompany';
import ListItem from '../MainContent/Master/ListItem';
import ListType from '../MainContent/Master/ListType';
import Module from '../MainContent/Master/module';
import Parts from '../MainContent/Master/parts';
import PaymentType from '../MainContent/Master/paymentType';
import PreMaintenanceContract from '../MainContent/Master/preMaintenanceContracts';
import RolePermission from '../MainContent/Master/RolePermission';
import SubModules from '../MainContent/Master/SubModules';
import Vendors from '../MainContent/Master/vendors';
import WorkOrderType from '../MainContent/Master/workOrderType';
import AddEditNote from '../MainContent/Notes/addEdit';
import addEditPayment from '../MainContent/Payments/addEditPayment';
import PaymentListing from '../MainContent/Payments/paymentListing';
import Payroll from '../MainContent/Payroll';
import Paysheet from '../MainContent/Payroll/paysheet';
import TechPay from '../MainContent/Payroll/techpay';
import PendingOrders from '../MainContent/PendingWorkOrders';
import POExpenses from '../MainContent/POExpenses';
import AllPremaintenances from '../MainContent/PreventativeMaintenance/AllPreMaintenances';
import PreventativeMaintenance from '../MainContent/PreventativeMaintenance/PreventativeMaintenance';
import PreMaintenanceCustomer from '../MainContent/PreventativeMaintenance/PreventativeMaintenanceCustomers';
import AdminProfile from '../MainContent/Profile/profile';
import AHSReportCard from '../MainContent/Reports/ahsReportCard';
import ReportBilling from '../MainContent/Reports/billing';
import ReportCustomer from '../MainContent/Reports/customers';
import DispatcherReports from '../MainContent/Reports/dispatcherReport/index';
import Jobs from '../MainContent/Reports/jobs';
import Reviews from '../MainContent/Reports/reviews';
import ReportTechnician from '../MainContent/Reports/technicians';
import TravelReportComponent from '../MainContent/Reports/travelReport';
import RoleComponent from '../MainContent/Role';
import AddRoleComponent from '../MainContent/Role/addRole.component';
import Schedule from '../MainContent/schedule/schedule';
import Technicians from '../MainContent/Technicians';
import AddTechnician from '../MainContent/Technicians/addTechnician';
import ApproveEmploymentContract from '../MainContent/Technicians/approveEmploymentContract';
import TechniciansParts from '../MainContent/Technicians/technicianParts';
import viewTechnician from '../MainContent/Technicians/viewTechnician';
import AmazonConnect from '../MainContent/AmazonConnect/amazonConnect';
import Dispatchers from '../MainContent/Dispatchers';
import AddDispatcher from '../MainContent/Dispatchers/addDispatcher';
import CustomerApprovalWaiting from '../MainContent/CustomerApproval';
import PendingInstallation from '../MainContent/PendingInstallation';
import PreventativeMaintenanceList from '../MainContent/PreventativeMaintenance/index';
import WorkOrderStatus from '../MainContent/Master/workOrderStatus';
import AddWorkOrderStatus from '../MainContent/Master/addWorkOrderStatus';
import ApprovePaycheck from '../MainContent/AdvancePaycheck/approve.component';
import RecordingComponent from '../MainContent/Recording';
import UnscheduleWorkOrders from '../MainContent/UnscheduleWorkOrder';
import CustomerProfileModule from '../MainContent/CustomerProfile/customerProfileModule';
import AddOfficeAdmin from '../MainContent/OfficeAdmin/addOfficeAdmin';
import OfficeAdmin from '../MainContent/OfficeAdmin/officeAdmin';
import AddQualityTemplateMsg from '../MainContent/Master/addQualityTemplateMsg';
import QualityTemplateMsg from '../MainContent/Master/qualityTemplateMsg';
import SurveyComponent from '../MainContent/Survey/survey.component';
import SurveyAuth from '../MainContent/Survey/surveyAuth.component';
import LiveLocationUpdate from '../MainContent/liveUpdate/liveLocationUpdate';
import LiveLocationAuth from '../MainContent/liveUpdate/liveLocationAuth';
import Privacy from '../MainContent/privacyPolicy/Privacy';

class mainbuilder extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" exact component={Dashboard} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/forget-password" exact component={ForgetPassword} />
        <Route path="/login" exact component={Login} />
        <Route path="/tab" exact component={Tab} />

        <Route
          path="/update-customer/:customerId"
          exact
          component={CustomerModule}
        />
        <Route path="/view-order/:customerId" exact component={WorkModule} />
        <Route
          path="/view-customer/:customerId"
          exact
          component={CustomerModule}
        />

        <Route path="/update-order/:customerId" exact component={WorkModule} />
        <Route path="/view-payment/:orderId" exact component={addEditPayment} />
        <Route path="/profit" exact component={Profit} />
        <Route path="/profitloss/:customerId" exact component={ProfitLoss} />

        <Route path="/listype" exact component={ListType} />
        <Route path="/listitem" exact component={ListItem} />

        <Route path="/customer" exact component={Customer} />
        <Route path="/add-customer" exact component={AddCustomers} />
        <Route
          path="/update-customer/:customerId"
          exact
          component={AddCustomers}
        />

        <Route path="/update-purchase/:id" exact component={PurchaseOrder} />
        <Route path="/work-order" exact component={WorkOrder} />
        <Route path="/purchase" exact component={PurchaseOrder} />
        <Route path="/purchase-view" exact component={purchaseView} />

        <Route path="/orderDetails" exact component={orderDetails} />
        <Route path="/customers" exact component={Customers} />
        <Route path="/add-customers" exact component={AddCustomer} />
        <Route
          path="/edit-customer/:customerId"
          exact
          component={AddCustomer}
        />
        <Route
          path="/customers/address/:customerId"
          exact
          component={CustomerAddresses}
        />

        <Route path="/profile" exact component={AdminProfile} />
        <Route path="/listtype" exact component={ListType} />
        <Route path="/add-list-type" exact component={AddListType} />
        <Route path="/add-list-item" exact component={AddListItem} />

        <Route path="/technicians" exact component={Technicians} />
        <Route path="/add-technician" exact component={AddTechnician} />
        <Route
          path="/edit-technician/:technicianId"
          exact
          component={viewTechnician}
        />

        <Route path="/complaints" exact component={Complaints} />
        <Route path="/add-complaint" exact component={AddComplaint} />
        <Route
          path="/edit-complaint/:complaintId"
          exact
          component={AddComplaint}
        />
        <Route path="/complaint-category" exact component={ComplaintCategory} />
        <Route path="/complaint-status" exact component={ComplaintStatus} />

        <Route path="/work-order-type" exact component={WorkOrderType} />
        <Route path="/payment-type" exact component={PaymentType} />
        <Route path="/home-warrenty-code" exact component={HomeWarrentyCode} />
        <Route path="/add-category" exact component={AddComplaintCategory} />
        <Route
          path="/add-complaint-status"
          exact
          component={AddComplaintStatus}
        />
        <Route
          path="/add-home-warranty-code"
          exact
          component={AddHomeWarrantyCode}
        />

        <Route path="/add-payment-type" exact component={AddPaymentType} />
        <Route path="/add-work-order-type" exact component={AddWorkOrderType} />

        <Route path="/payment" exact component={PaymentListing} />
        <Route path="/payroll" exact component={Payroll} />
        <Route path="/profit-loss" exact component={POExpenses} />
        <Route path="/payment/add" exact component={addEditPayment} />
        <Route path="/payment/edit/:id" exact component={addEditPayment} />
        <Route path="/pending-orders" exact component={PendingOrders} />

        <Route path="/view-complaints" exact component={ComplaintData} />
        <Route
          path="/view-complaintDetails/:complaintId"
          exact
          component={AddComplaint}
        />

        <Route path="/add-newexpense" exact component={AddNewExpense} />
        <Route path="/expense" exact component={AddExpense} />

        <Route
          path="/add-purchaseorder/:viewId"
          exact
          component={PurchaseOrder}
        />
        <Route path="/add-purchaseorder" exact component={PurchaseOrder} />

        <Route path="/reset-password/:token" exact component={ResetPassword} />

        <Route path="/roles" exact component={RoleComponent} />
        <Route path="/add-role" exact component={AddRoleComponent} />
        <Route path="/add-role/:roleId" exact component={AddRoleComponent} />

        <Route path="/items" exact component={ItemComponent} />
        <Route path="/add-item" exact component={AddBillingItems} />
        <Route path="/update-item/:itemId" exact component={AddBillingItems} />

        <Route path="/add-line-item" exact component={AddLineItem} />
        <Route path="/update-line-item/:itemId" exact component={AddLineItem} />

        <Route path="/contracts" exact component={ContractComponent} />
        <Route path="/add-contract" exact component={AddContractComponent} />
        <Route
          path="/update-contract/:contractId"
          exact
          component={AddContractComponent}
        />

        <Route path="/tax-rates" exact component={TaxRateComponent} />
        <Route path="/settings" exact component={SettingsComponent} />
        <Route path="/add-tax-rate" exact component={AddTaxRateComponent} />
        <Route
          path="/update-tax-rate/:taxRateId"
          exact
          component={AddTaxRateComponent}
        />

        <Route path="/addFile" exact component={AddFileAttachment} />

        <Route path="/estimates" exact component={EstimateComponent} />
        <Route
          path="/estimateDetail/:estimateId"
          exact
          component={EstimateDetailsComponent}
        />
        <Route
          path="/estimateDetail"
          exact
          component={EstimateDetailsComponent}
        />
        <Route path="/invoices" exact component={InvoiceComponent} />
        <Route
          path="/invoiceDetail/:invoiceId"
          exact
          component={InvoiceDetailsComponent}
        />
        <Route
          path="/invoiceDetail"
          exact
          component={InvoiceDetailsComponent}
        />

        <Route
          path="/home-warranty-company"
          exact
          component={HomeWarrantyCompany}
        />
        <Route
          path="/add-home-warranty-company"
          exact
          component={AddHomeWarrantyCompany}
        />

        <Route path="/schedule" exact component={Schedule} />

        <Route path="/report-jobs" exact component={Jobs} />
        <Route path="/report-reviews" exact component={Reviews} />
        <Route path="/report-customers" exact component={ReportCustomer} />
        <Route path="/report-technicians" exact component={ReportTechnician} />
        <Route path="/report-billing" exact component={ReportBilling} />

        <Route
          path="/preventative-maintenance"
          exact
          component={PreventativeMaintenance}
        />
        <Route
          path="/view-premaintenance-customers"
          exact
          component={PreMaintenanceCustomer}
        />
        <Route
          path="/pre-maintenances/:id"
          exact
          component={AllPremaintenances}
        />

        <Route
          path="/invoice-customer-pay"
          exact
          component={InvoiceCustomerPayment}
        />
        <Route path="/note/:id" exact component={AddEditNote} />
        <Route path="/email-templates" exact component={EmailTemplates} />
        <Route
          path="/add-email-template"
          exact
          component={AddEditEmailTemplate}
        />

        <Route path="/ahs-workorders" exact component={AHSworkorders} />
        <Route
          path="/pre-maintenance-contract"
          exact
          component={PreMaintenanceContract}
        />
        <Route
          path="/add-pre-contract"
          exact
          component={AddPreMaintenanceContract}
        />
        <Route
          path="/update-pre-contract/:contractId"
          exact
          component={AddPreMaintenanceContract}
        />

        <Route path="/vendors" exact component={Vendors} />
        <Route path="/vendor/add" exact component={AddEditVendor} />
        <Route path="/vendor/:id" exact component={AddEditVendor} />

        <Route path="/parts" exact component={Parts} />
        <Route path="/part/add" exact component={AddEditPart} />
        <Route path="/part/:id" exact component={AddEditPart} />

        <Route path="/workorder-part" exact component={WorkOrderParts} />
        <Route
          path="/workorder-part/:id"
          exact
          component={AddEditWorkOrderParts}
        />
        <Route
          path="/workorder-part/add"
          exact
          component={AddEditWorkOrderParts}
        />

        <Route path="/add-module" exact component={AddEditModule} />
        <Route path="/modules" exact component={Module} />
        <Route path="/add-submodule" exact component={AddEditSubModule} />
        <Route path="/submodules" exact component={SubModules} />
        <Route path="/role-permission" exact component={RolePermission} />
        <Route path="/allocated-parts" exact component={TechniciansParts} />

        <Route path="/paysheet" exact component={Paysheet} />
        <Route path="/techpay" exact component={TechPay} />
        <Route path="/leaves" exact component={Leaves} />
        <Route path="/apply-leave" exact component={AddLeave} />
        <Route path="/report-travel" exact component={TravelReportComponent} />

        <Route
          path="/advance-paycheck"
          exact
          component={AdvancePaycheckComponent}
        />

        <Route
          path="/employment-contracts"
          exact
          component={EmploymentContract}
        />
        <Route
          path="/add-employment-contract"
          exact
          component={AddEmpContractComponent}
        />
        <Route
          path="/edit-employment-contract/:contractId"
          exact
          component={AddEmpContractComponent}
        />
        <Route
          path="/approve-employment-contract/:contractId"
          exact
          component={ApproveEmploymentContract}
        />

        <Route
          path="/authorization-required"
          exact
          component={AuthorizationRequired}
        />
        <Route path="/ahs-report" exact component={AHSReportCard} />
        <Route path="/dispatcher-reports" exact component={DispatcherReports} />
        <Route path="/amazon-connect" exact component={AmazonConnect} />
        <Route
          path="/customer-approval"
          exact
          component={CustomerApprovalWaiting}
        />
        <Route
          path="/pending-installation"
          exact
          component={PendingInstallation}
        />
        <Route path="/add-dispatcher" exact component={AddDispatcher} />
        <Route path="/dispatchers" exact component={Dispatchers} />
        <Route
          path="/edit-dispatcher/:technicianId"
          exact
          component={viewTechnician}
        />
        <Route
          path="/preventative-maintenance-list"
          exact
          component={PreventativeMaintenanceList}
        />
        <Route path="/work-order-status" exact component={WorkOrderStatus} />
        <Route
          path="/add-work-order-status"
          exact
          component={AddWorkOrderStatus}
        />

        <Route
          path="/request-advance-paycheck"
          exact
          component={ApprovePaycheck}
        />
        <Route path="/recording" exact component={RecordingComponent} />
        <Route
          path="/unschedule-orders"
          exact
          component={UnscheduleWorkOrders}
        />
        <Route
          path="/profile-module/:customerId"
          exact
          component={CustomerProfileModule}
        />
        <Route
          path="/add-office-admin-assistant"
          exact
          component={AddOfficeAdmin}
        />
        <Route
          path="/edit-office-admin-assistant/:adminId"
          exact
          component={AddOfficeAdmin}
        />
        <Route path="/office-admin-assistants" exact component={OfficeAdmin} />

        <Route
          path="/quality-template-msg"
          exact
          component={QualityTemplateMsg}
        />
        <Route
          path="/add-quality-template-msg"
          exact
          component={AddQualityTemplateMsg}
        />
        <Route path="/job-survey" exact component={SurveyComponent} />
        <Route path="/job-survey/:token" exact component={SurveyAuth} />
        <Route
          path="/live-location/:token"
          exact
          component={LiveLocationAuth}
        />
        <Route path="/live-location" exact component={LiveLocationUpdate} />
        <Route path="/privacy-policy" exact component={Privacy} />
      </Switch>
    );
  }
}

export default mainbuilder;
