import React, { Component } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import ACKNOWLEDGE from '../../../assets/images/approve.svg';
import ARCHIVE from '../../../assets/images/archive.svg';
import CANCEL from '../../../assets/images/cancel.svg';
import COMPLETE from '../../../assets/images/complete.svg';
import COMPLETED from '../../../assets/images/completed.svg';
import OFFERED from '../../../assets/images/email.svg';
import PAUSED from '../../../assets/images/pause.svg';
import INPROGRESS from '../../../assets/images/play.svg';
import SCHEDULE from '../../../assets/images/schedule.svg';
import SCHEDULED from '../../../assets/images/success.svg';
import UNSCHEDULED from '../../../assets/images/unscheduled.svg';
import CANCELLED from '../../../assets/images/cancelled.svg';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import PreventativeMaintenance from '../../MainContent/PreventativeMaintenance/PreventativeMaintenance';
import Complaint from '../Complaints/index';
import AddRating from '../Workorder/AddRating';
import EstimateComponent from '../CustomerBilling/estimate.component';
import InvoiceComponent from '../CustomerBilling/invoices.component';
import Notes from '../Notes';
import TechPayrollListing from '../TechPayroll/TechPayrollListing';
import Diagnosis from './Diagnosis';
import FileListComponent from './FileList';
import ProfileLoss from './ProfitLoss';
import PurchaseView from './purchaseView';
import OrderDetails from './WorkOrder';
import WorkorderActivity from './WorkorderActivity';
import WorkorderParts from './WorkorderParts';
import WorkOrderStatusUpdate from './workOrderStatusUpdate';
import CompleteJobPopup from './completeJobPopup';

class WorkModule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workorderId: null,
      keyword: '',
      orders: [],
      showRating: false,
      workorderData: [],
      WorkComplete: 0,
      preContractExist: false,
      contract: [],
      isPreMaintenance: null,
      multitech: [],
      preMaintenanceExist: false,
      showDelete: false,
      type: '',
      isShowLoader: false,
      workCompleteJob: false,
      custId: null,
      submodulesData: [],
      swalParams: null,
      confirmType: '',
      WorkOrderType: '',
      previousTab: 'WO Details',
      previousTechnicians: '',
      laboutToDate: 0,
      paginationOptions: {},
      func: this.nonStaticFunc.bind(this),
      techId: null,
    };
    this.scheduleRef = React.createRef();
  }

  componentDidMount = async () => {
    // let isAllowed = await customFunctions.isAllowedRoute('submodule', 'ahsworkorders-accessahsworkorders');
    // if(!isAllowed){
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    let previousTab = await customFunctions.getLocalStorage('activeTab');
    if (previousTab) this.setState({ previousTab: previousTab });

    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    customFunctions.setTitle('Work Order Module');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ roleId: userdata.role_id, techId: userdata.login_id });
    const workorderId = await this.props.match.params.customerId;
    await this.getPermissions(userdata.role_id);
    if (workorderId) {
      await this.getOrderData(workorderId);
      await this.getPreContract(workorderId);
      await this.getPreMaintenance(workorderId);
    }
    if (
      (await this.props.match) &&
      (await this.props.match.params) &&
      (await this.props.match.params.customerId)
    ) {
      this.setState({ workorderId: await this.props.match.params.customerId });
      if (
        this.props &&
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.orderDetails
      ) {
        this.setState({
          orderDetails: await this.props.location.state.orderDetails,
        });
      }
      const endPoint = `${Api.orderById}?work_order_id=${this.props.match.params.customerId}&type=1`;
      const { data } = await AuthApi.getDataFromServer(endPoint);
      var orderDetails = data && data.data[0];
      this.setState({
        orderDetails,
      });
    } else {
      this.props.history.push('/orderDetails');
      return;
    }
  };

  // componentWillReceiveProps = async (props) => {
  //   const {workorderId} = this.state;
  //   if(props && props.history && props.history.location && props.history.location.state && props.history.location.state.type == 'orders-search' && props.history.location.state.orderDetails.ID !== workorderId){
  //         this.setState({
  //           orderDetails: props.history.location.state.orderDetails
  //         });
  //         await this.getOrderData(props.history.location.state.orderDetails.ID);
  //         await this.getPreContract(props.history.location.state.orderDetails.ID);
  //         await this.getPreMaintenance(props.history.location.state.orderDetails.ID);
  //   }
  // };

  nonStaticFunc = async (workorderId) => {
    if (workorderId) {
      await this.getOrderData(workorderId);
      await this.getPreContract(workorderId);
      await this.getPreMaintenance(workorderId);
    }
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps &&
      nextProps.history &&
      nextProps.history.location &&
      nextProps.history.location.state &&
      nextProps.history.location.state.orderDetails &&
      nextProps.history.location.state.orderDetails.ID !== prevState.workorderId
    ) {
      return {
        model: prevState.func(nextProps.history.location.state.orderDetails.ID),
        orderDetails: nextProps.history.location.state.orderDetails,
      };
    }
    return null;
  }

  getPermissions = async (role_id) => {
    const endPoint = `${Api.rolePermissionUrl}?role_id=${role_id}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.success !== false) {
      let submodulesData = Object.keys(data.data.submodule);

      this.setState({ submodulesData });
      customFunctions.setStringifyDataToLocalStorage('permissions', data.data);
    }
  };

  getOrderData = async (workorderId, type) => {
    const endPoint =
      await `${Api.orderById}?work_order_id=${workorderId}&type=1`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      if (data.data && data.data[0]) {
        if (data.data[0].multitech) {
          const pretech = [];
          (await data.data) &&
            data.data[0] &&
            data.data[0].multitech.map((tech) => {
              pretech.push(tech.tech_id);
              return tech;
            });
          this.setState({ previousTechnicians: pretech });
        }
        this.setState({
          WorkComplete: data.data[0].WorkComplete,
          workorderId: data.data[0].ID,
          WorkOrderType: data.data[0].WorkOrderType,
          WorkStatus: data.data[0].WorkStatus,
          workorderData: data.data[0],
          isPreMaintenance: data.data[0].IsPrevMaintenance,
          multitech: data.data[0].multitech,
          primaryTechnician:
            data.data[0].multitech &&
            data.data[0].multitech.filter((i) => i.is_primary === 1)[0],
          custId: data.data[0].CustomerID,
          laboutToDate: data.data[0].labortodate || 0,
        });
      }
    } else {
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getOrders();
  };

  clearSearch = () => {
    this.setState({ keyword: '', orders: [] });
  };

  getOrders = async () => {
    const { keyword } = this.state;
    const endPoint = `${Api.getWorkOrder}?search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ orders: data.data.data });
    } else {
      this.setState({ orders: [] });
      alert(message);
    }
  };

  handleSelect = (key) => {
    if (key == 1) {
      this.setState({ showRating: true });
    } else {
      this.setState({ showRating: false });
    }
  };

  getPreContract = async (work_order_id) => {
    const endPoint = `${Api.premaintenanceContract}?work_order_id=${work_order_id}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data && data.data.data.length) {
      this.setState({
        preContractExist: true,
        contract: data.data.data[0],
      });
    }
  };

  getPreMaintenance = async (work_order_id) => {
    const endPoint = `${Api.getPremaintenance}?work_order_id=${work_order_id}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data[0]) {
      this.setState({
        preMaintenanceExist: true,
      });
    }
  };

  setWorkStatus = async (type) => {
    const { workorderId, confirmType, custId, roleId, techId } = this.state;
    this.setState({ type });
    let payload = {
      work_order_id: workorderId,
    };
    let api = '';
    if (type === 'schedule') return;
    if (type === 'acknowledge') {
      api = Api.workOrderAcknowledgement;
      payload.status = 'Offered';
    }
    if (confirmType === 'archivejob') {
      this.setState({ showDelete: false });
      api = Api.workOrderAcknowledgement;
      payload.status = 'archive';
    }
    if (type === 'completejob') {
      this.setState({ showDelete: false });
      api = Api.workOrderCompleted;
      payload.status = 'Completed';
    }
    if (confirmType === 'canceljob') {
      this.setState({ showDelete: false });
      api = Api.workOrderCompleted;
      if (roleId === 4) {
        payload.cust_id = custId;
      }
      if (roleId === 5) {
        payload.tech_id = techId;
      }
      payload.status = 'Job Cancelled';
      payload.paused_reason = 'Job Cancelled';
    }
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${api}`;

    const callback = AuthApi.putDataToServer;

    const { data } = await callback(endPoint, payload);
    if (data) {
      if (type === 'completejob') {
        swal('Workorder completed successfully', '', 'success');
      }
      if (confirmType === 'canceljob') {
        setTimeout(() => {
          swal('Workorder cancelled successfully', '', 'success');
          this.props.history.goBack();
        }, 1000);
      }
      await this.getOrderData(workorderId);
      if (confirmType === 'archivejob') {
        swal('Workorder archived successfully', '', 'success');
        this.props.history.push('/ahs-workorders');
      }
    }
    this.setState({
      isShowLoader: false,
      confirmType: '',
      showDelete: false,
    });
  };

  render() {
    const {
      orderDetails,
      workorderId,
      showRating,
      workorderData,
      WorkComplete,
      roleId,
      isPreMaintenance,
      preContractExist,
      preMaintenanceExist,
      contract,
      multitech,
      primaryTechnician,
      WorkStatus,
      showDelete,
      type,
      isShowLoader,
      workCompleteJob,
      custId,
      submodulesData,
      swalParams,
      WorkOrderType,
      previousTab,
      previousTechnicians,
      paginationOptions,
    } = this.state;

    let imgs = '';
    let color = '';
    if (WorkStatus === 'Completed') {
      imgs = COMPLETED;
      color = 'rgb(202, 212, 221)';
    }
    if (WorkStatus === 'Inprogress') {
      imgs = INPROGRESS;
      color = 'rgb(243 215 211)';
    }
    if (WorkStatus === 'Offered') {
      imgs = OFFERED;
      color = '#50e650';
    }
    if (WorkStatus === 'Scheduled') {
      imgs = SCHEDULED;
      color = 'rgb(23, 149, 204)';
    }
    if (WorkStatus === 'Unscheduled') {
      imgs = UNSCHEDULED;
      color = 'rgb(251, 54, 64)';
    }
    if (WorkStatus === 'Paused') {
      imgs = PAUSED;
      color = 'rgb(255, 219, 40)';
    }
    if (WorkStatus === 'Cancelled') {
      imgs = CANCELLED;
      color = 'rgb(171, 179, 188)';
    }

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        {roleId && (
          <div className="page-content-wrapper">
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title={'Are you sure, do you want to ' + swalParams + ' ?'}
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.setWorkStatus(swalParams);
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="container-fluid">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    {/* <div className="row">
                    <div
                      className="col-sm-4 col-md-4 p-l-2"
                      style={{ paddingLeft: '25px' }}
                    >
                      <h3>{(orderDetails && orderDetails.WorkOrder) || '-'}</h3>
                    </div>
                    <div className="col-sm-8 col-md-8">
                      <div className="row">
                        <div className="col-sm-12 col-md-12 pull-right">
                          <form onSubmit={this.onSearchSubmit}>
                            <div className="form-group row">
                              <div className="col-sm-5 col-md-5">
                                <div
                                  id="myDropdown"
                                  className="dropdown-content"
                                >
                                  {!customerId && (
                                    <input
                                      className="form-control"
                                      value={keyword}
                                      type="text"
                                      placeholder="Search by customer name,tech name,home warranty code, work complete, work order type ..."
                                      id="example-text-input"
                                      onChange={(e) => {
                                        this.setState({
                                          keyword: e.target.value,
                                        });
                                        if (!e.target.value) this.clearSearch();
                                      }}
                                      required={true}
                                      autoComplete="off"
                                    />
                                  )}
                                  {orders && orders.length > 0 && (
                                    <div>
                                      {orders.map((item, index) => (
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(
                                              `/view-order/${item.ID}`,
                                              {
                                                orderDetails: item,
                                                type: 'orders',
                                              }
                                            );
                                            window.location.reload();
                                          }}
                                          key={`${index}-customer-list`}
                                        >
                                          {item.WorkOrder}
                                        </a>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="col-sm-7 col-md-7">
                                {!customerId && (
                                  <>
                                    <button
                                      type="submit"
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="reset"
                                      onClick={() => {
                                        this.clearSearch();
                                      }}
                                      className="btn btn-secondary waves-effect m-l-10"
                                    >
                                      Cancel
                                    </button>
                                  </>
                                )}
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light float-right"
                                  style={{ marginRight: '15px' }}
                                  onClick={() => {
                                    this.props.history.goBack();
                                  }}
                                >
                                  Back
                                </button>
                                {roleId == 4 && roleId == 4 && preContractExist && (
                                  <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light float-right"
                                    style={{ marginRight: '10px' }}
                                    onClick={() => {
                                      this.props.history.push(
                                        `/update-pre-contract/${contract.id}`,
                                        {
                                          work_order_id: orderDetails.ID,
                                          contractDetails: contract,
                                        }
                                      );
                                    }}
                                  >
                                    View Pre-Maintencance Contract
                                  </button>
                                )}
                                {roleId == 5 && (
                                  <>
                                    {preContractExist ? (
                                      <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light float-right"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => {
                                          this.props.history.push(
                                            `/update-pre-contract/${contract.id}`,
                                            {
                                              work_order_id: orderDetails.ID,
                                              contractDetails: contract,
                                            }
                                          );
                                        }}
                                      >
                                        View Pre-Maintencance Contract
                                      </button>
                                    ) : (
                                      <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light float-right"
                                        style={{ marginRight: '10px' }}
                                        onClick={() => {
                                          this.props.history.push(
                                            '/add-pre-contract',
                                            {
                                              work_order_id: orderDetails.ID,
                                            }
                                          );
                                        }}
                                      >
                                        Add Pre-Maintencance Contract
                                      </button>
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> */}
                    <div
                      className="card mb-2"
                      style={{ backgroundColor: color, border: 'none' }}
                    >
                      <div className="workorder-header row">
                        <div
                          className="col-sm-6"
                          style={{ paddingLeft: '25px' }}
                        >
                          <h5 className="">
                            {(orderDetails && orderDetails.WorkOrder) || '-'}
                          </h5>
                        </div>
                        <div className="col-sm-6">
                          <form onSubmit={this.onSearchSubmit}>
                            <div
                              className="form-group1 row"
                              style={{ marginTop: '7px' }}
                            >
                              {/* <div className="col-sm-5 col-md-5">
                                <div
                                  id="myDropdown"
                                  className="dropdown-content"
                                >
                                  {!workorderId && (
                                    <input
                                      className="form-control"
                                      value={keyword}
                                      type="text"
                                      placeholder="Search by customer name,tech name,home warranty code, work complete, work order type ..."
                                      id="example-text-input"
                                      onChange={(e) => {
                                        this.setState({
                                          keyword: e.target.value,
                                        });
                                        if (!e.target.value) this.clearSearch();
                                      }}
                                      required={true}
                                      autoComplete="off"
                                    />
                                  )}
                                  {orders && orders.length > 0 && (
                                    <div>
                                      {orders.map((item, index) => (
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            this.props.history.push(
                                              `/view-order/${item.ID}`,
                                              {
                                                orderDetails: item,
                                                type: 'orders',
                                              }
                                            );
                                            window.location.reload();
                                          }}
                                          key={`${index}-customer-list`}
                                        >
                                          {item.WorkOrder}
                                        </a>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              </div> */}
                              <div className="col-sm-12 col-md-12">
                                {/* {!workorderId && (
                                  <>
                                    <button
                                      type="submit"
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="reset"
                                      onClick={() => {
                                        this.clearSearch();
                                      }}
                                      className="btn btn-secondary waves-effect m-l-10"
                                    >
                                      Cancel
                                    </button>
                                  </>
                                )} */}
                                <button
                                  type="reset"
                                  className="btn btn-primary waves-effect waves-light float-right mr-1 wfont"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    if (
                                      this.props &&
                                      this.props.location &&
                                      this.props.location.state &&
                                      this.props.location.state.path
                                    ) {
                                      this.props.history.push(
                                        `${this.props.location.state.path}`,
                                        {
                                          paginationObj: paginationOptions,
                                        }
                                      );
                                    } else {
                                      this.props.history.goBack();
                                    }
                                  }}
                                >
                                  Back
                                </button>
                                {roleId === 4 && preContractExist && (
                                  <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light float-right mr-1 wfont"
                                    onClick={() => {
                                      this.props.history.push(
                                        `/update-pre-contract/${contract.id}`,
                                        {
                                          work_order_id: orderDetails.ID,
                                          contractDetails: contract,
                                        }
                                      );
                                    }}
                                  >
                                    View Pre-Maintencance Contract
                                  </button>
                                )}
                                {(roleId === 5 ||
                                  roleId === 6 ||
                                  roleId === 1) && (
                                  <>
                                    {preContractExist &&
                                    WorkOrderType ===
                                      'Preventative Maintenance' ? (
                                      <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light float-right  mr-1 wfont"
                                        onClick={() => {
                                          this.props.history.push(
                                            `/update-pre-contract/${contract.id}`,
                                            {
                                              work_order_id: orderDetails.ID,
                                              contractDetails: contract,
                                            }
                                          );
                                        }}
                                      >
                                        View Pre-Maintencance Contract
                                      </button>
                                    ) : (
                                      WorkOrderType ===
                                        'Preventative Maintenance' && (
                                        <button
                                          type="submit"
                                          className="btn btn-primary waves-effect waves-light float-right  mr-1 wfont"
                                          onClick={() => {
                                            this.props.history.push(
                                              '/add-pre-contract',
                                              {
                                                work_order_id: orderDetails.ID,
                                              }
                                            );
                                          }}
                                        >
                                          Add Pre-Maintencance Contract
                                        </button>
                                      )
                                    )}
                                  </>
                                )}
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div className={`tab-wrapper ${roleId !== 4 ? 'row' : ''}`}>
                      <div
                        className={`container-fluid ${roleId !== 4 ? '' : ''}`}
                      >
                        <div className="top-actions-wrapper">
                          <div className="status-text">
                            {imgs && (
                              <img
                                src={imgs}
                                width="18"
                                height="25"
                                style={{ cursor: 'pointer' }}
                                alt=""
                              />
                            )}
                            <span
                              style={{ paddingLeft: '7px', fontSize: '12px' }}
                            >
                              {WorkStatus}
                            </span>
                          </div>
                          {roleId && WorkStatus && (
                            <div
                              className={`top-actions-right mb-2 ${
                                roleId === 4 ? 'icons-hidden' : 'icons-visible'
                              }`}
                            >
                              {WorkStatus === 'Offered' && (
                                <div
                                  className="workorder-icons "
                                  onClick={() =>
                                    this.setWorkStatus('acknowledge')
                                  }
                                >
                                  <img
                                    src={ACKNOWLEDGE}
                                    width="25"
                                    height="30"
                                    style={{ cursor: 'pointer' }}
                                    alt=""
                                  />
                                  <p>Acknowledge</p>
                                </div>
                              )}
                              {[1, 2, 3, 6].includes(roleId) &&
                                previousTab === 'WO Details' &&
                                WorkStatus !== 'Inprogress' && (
                                  <div
                                    className="workorder-icons"
                                    onClick={() => {
                                      this.setWorkStatus('schedule');
                                      this.scheduleRef.current.scrollIntoView({
                                        behavior: 'smooth',
                                        block: 'center',
                                      });
                                    }}
                                  >
                                    <img
                                      src={SCHEDULE}
                                      width="25"
                                      height="30"
                                      style={{ cursor: 'pointer' }}
                                      alt=""
                                    />
                                    <p>
                                      {WorkStatus === 'Scheduled' ||
                                      WorkStatus === 'Completed'
                                        ? 'Re-schedule'
                                        : 'Schedule Now'}
                                    </p>
                                  </div>
                                )}
                              {WorkStatus !== 'Completed' &&
                                WorkStatus !== 'Offered' &&
                                WorkStatus !== 'Cancelled' && (
                                  <div
                                    className="workorder-icons"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      this.setState({ workCompleteJob: true });
                                      // this.setWorkStatus('completejob')
                                    }}
                                  >
                                    <img
                                      src={COMPLETE}
                                      width="25"
                                      height="30"
                                      style={{ cursor: 'pointer' }}
                                      alt=""
                                    />
                                    <p>Complete Job</p>
                                  </div>
                                )}
                              {WorkStatus !== 'Scheduled' && (
                                <div
                                  className="workorder-icons"
                                  onClick={() => {
                                    this.setState({
                                      swalParams: 'archive this job',
                                      showDelete: true,
                                      confirmType: 'archivejob',
                                    });
                                  }}
                                  // onClick={() => this.setWorkStatus('archivejob')}
                                >
                                  <img
                                    src={ARCHIVE}
                                    width="25"
                                    height="30"
                                    style={{ cursor: 'pointer' }}
                                    alt=""
                                  />
                                  <p>Archive Job</p>
                                </div>
                              )}
                              {WorkStatus !== 'Offered' &&
                                WorkStatus !== 'Cancelled' &&
                                WorkStatus !== 'Completed' && (
                                  <div
                                    className="workorder-icons"
                                    onClick={() => {
                                      this.setState({
                                        swalParams: 'cancel this job',
                                        showDelete: true,
                                        confirmType: 'canceljob',
                                      });
                                    }}
                                  >
                                    <img
                                      src={CANCEL}
                                      width="25"
                                      height="30"
                                      style={{ cursor: 'pointer' }}
                                      alt=""
                                    />
                                    <p>Cancel Job</p>
                                  </div>
                                )}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className={`container-fluid workorder-tbl  ${
                          roleId !== 4 ? 'col-sm-12' : ''
                        }`}
                      >
                        {previousTab && (
                          <Tab.Container
                            activeKey={previousTab}
                            onSelect={(e) => this.handleSelect(e)}
                          >
                            <div className="row">
                              <div className="col-sm-3">
                                <Nav
                                  variant="pills"
                                  className="flex-column"
                                  onSelect={(selectedKey) => {
                                    this.setState({ previousTab: selectedKey });
                                    customFunctions.setLocalStorage(
                                      'activeTab',
                                      selectedKey
                                    );
                                  }}
                                >
                                  <Nav.Item>
                                    <Nav.Link eventKey={'WO Details'}>
                                      WO Details
                                    </Nav.Link>
                                  </Nav.Item>
                                  {submodulesData.includes(
                                    'jobdetails-accessattachments'
                                  ) && (
                                    <Nav.Item>
                                      <Nav.Link eventKey={'Media'}>
                                        Attachments
                                      </Nav.Link>
                                    </Nav.Item>
                                  )}
                                  {submodulesData.includes(
                                    'jobdetails-accesspurchaseorders'
                                  ) &&
                                    roleId !== 4 && (
                                      <Nav.Item>
                                        <Nav.Link eventKey={'Purchase Orders'}>
                                          Purchase Orders
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  {submodulesData.includes(
                                    'jobdetails-accesscomplaints'
                                  ) &&
                                    orderDetails && (
                                      <Nav.Item>
                                        <Nav.Link eventKey={'Complaints'}>
                                          Complaints
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                  {submodulesData.includes(
                                    'jobdetails-accesstechpayroll'
                                  ) &&
                                    roleId !== 4 && (
                                      <Nav.Item>
                                        <Nav.Link eventKey={'Tech Payroll'}>
                                          Tech Payroll
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {submodulesData.includes(
                                    'jobdetails-accessprofitloss'
                                  ) &&
                                    roleId !== 4 &&
                                    roleId !== 6 &&
                                    roleId !== 5 && (
                                      <Nav.Item>
                                        <Nav.Link eventKey={'Profit/Loss'}>
                                          Profit/Loss
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {submodulesData.includes(
                                    'jobdetails-accessestimates'
                                  ) &&
                                    workorderId && (
                                      <Nav.Item>
                                        <Nav.Link eventKey={'Estimates'}>
                                          Estimates
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {submodulesData.includes(
                                    'jobdetails-accessinvoices'
                                  ) &&
                                    workorderId && (
                                      <Nav.Item>
                                        <Nav.Link eventKey={'Invoices'}>
                                          Invoices
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {submodulesData.includes(
                                    'jobdetails-accessrating'
                                  ) &&
                                    WorkComplete == 1 &&
                                    workorderId && (
                                      <Nav.Item>
                                        <Nav.Link eventKey={'Rating'}>
                                          Rating
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {submodulesData.includes(
                                    'jobdetails-accessnotes'
                                  ) &&
                                    workorderId &&
                                    orderDetails && (
                                      <Nav.Item>
                                        <Nav.Link eventKey="Notes">
                                          Notes
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {submodulesData.includes(
                                    'jobdetails-accessactivity'
                                  ) &&
                                    workorderId && (
                                      <Nav.Item>
                                        <Nav.Link eventKey="WorkorderActivity">
                                          Activity
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {/* <Nav.Item>
                                  <Nav.Link eventKey="Payments">
                                     Payments
                                  </Nav.Link>
                              </Nav.Item> */}

                                  {(orderDetails &&
                                    isPreMaintenance &&
                                    roleId !== 4) ||
                                  (orderDetails &&
                                    isPreMaintenance &&
                                    roleId === 4 &&
                                    preMaintenanceExist &&
                                    submodulesData.includes(
                                      'jobdetails-accesspremaintainance'
                                    )) ? (
                                    <Nav.Item>
                                      <Nav.Link eventKey="Pre-Maintenance">
                                        Pre-Maintenance
                                      </Nav.Link>
                                    </Nav.Item>
                                  ) : (
                                    ''
                                  )}

                                  {submodulesData.includes(
                                    'jobdetails-accessparts'
                                  ) &&
                                    roleId !== 4 &&
                                    workorderId && (
                                      <Nav.Item>
                                        <Nav.Link eventKey="WorkorderParts">
                                          Parts
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {submodulesData.includes(
                                    'jobdetails-accessdiagnosis'
                                  ) &&
                                    roleId !== 4 &&
                                    workorderId && (
                                      <Nav.Item>
                                        <Nav.Link eventKey="diagnosis">
                                          Diagnosis
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}

                                  {[1, 5, 6].includes(roleId) &&
                                    workorderId &&
                                    WorkStatus === 'Paused' &&
                                    submodulesData.includes(
                                      'jobdetails-accessstatusupdate'
                                    ) && (
                                      <Nav.Item>
                                        <Nav.Link eventKey="WorkorderStatusUpdate">
                                          Status Update
                                        </Nav.Link>
                                      </Nav.Item>
                                    )}
                                </Nav>
                              </div>
                              <div className="col-sm-9">
                                <Tab.Content>
                                  {previousTab === 'WO Details' ? (
                                    <Tab.Pane eventKey={'WO Details'}>
                                      <OrderDetails
                                        scheduleRef={this.scheduleRef}
                                        type={type}
                                        statusChangeCallback={(workorderId) =>
                                          this.getOrderData(workorderId)
                                        }
                                        previousTechnicians={
                                          previousTechnicians &&
                                          previousTechnicians
                                        }
                                        previousPath={
                                          this.props &&
                                          this.props.location &&
                                          this.props.location.state &&
                                          this.props.location.state.path
                                        }
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {previousTab === 'Media' ? (
                                    <Tab.Pane eventKey={'Media'}>
                                      <FileListComponent />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {roleId !== 4 &&
                                  previousTab === 'Purchase Orders' ? (
                                    <Tab.Pane eventKey={'Purchase Orders'}>
                                      <PurchaseView />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {orderDetails &&
                                  previousTab === 'Complaints' ? (
                                    <Tab.Pane eventKey={'Complaints'}>
                                      <Complaint
                                        fromComponent="workorder"
                                        orderDetails={orderDetails}
                                        workorderid={workorderId}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {roleId !== 4 &&
                                  previousTab === 'Tech Payroll' ? (
                                    <Tab.Pane eventKey="Tech Payroll">
                                      <TechPayrollListing />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {![3, 4, 5, 6].includes(roleId) &&
                                  previousTab === 'Profit/Loss' ? (
                                    <Tab.Pane eventKey="Profit/Loss">
                                      <ProfileLoss />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {workorderId &&
                                  previousTab === 'Estimates' ? (
                                    <Tab.Pane eventKey="Estimates">
                                      <EstimateComponent
                                        fromComponent="workorder"
                                        workorderid={workorderId}
                                        orderDetails={orderDetails}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {workorderId && previousTab === 'Invoices' ? (
                                    <Tab.Pane eventKey="Invoices">
                                      <InvoiceComponent
                                        title="Work Order"
                                        fromComponent="workorder"
                                        workorderid={workorderId}
                                        orderDetails={orderDetails}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {WorkComplete == 1 &&
                                  workorderId &&
                                  previousTab === 'Rating' ? (
                                    <Tab.Pane eventKey="Rating">
                                      <AddRating
                                        showRating={showRating}
                                        workorderid={workorderId}
                                        workorderData={workorderData}
                                        handleClose={() =>
                                          this.setState({ showRating: false })
                                        }
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {workorderId &&
                                  orderDetails &&
                                  previousTab === 'Notes' ? (
                                    <Tab.Pane eventKey="Notes">
                                      <Notes
                                        workorderid={workorderId}
                                        orderDetails={orderDetails}
                                        technicians={multitech}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {workorderId &&
                                  previousTab === 'WorkorderActivity' ? (
                                    <Tab.Pane eventKey="WorkorderActivity">
                                      <WorkorderActivity
                                        workorderid={workorderId}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {/* <Tab.Pane
                                  eventKey="Payments"
                                  title="Payments"
                                >
                                  <AddEditPayment />
                              </Tab.Pane> */}

                                  {((orderDetails &&
                                    isPreMaintenance &&
                                    roleId !== 4) ||
                                    (orderDetails &&
                                      isPreMaintenance &&
                                      roleId === 4 &&
                                      preMaintenanceExist)) &&
                                  previousTab === 'Pre-Maintenance' ? (
                                    <Tab.Pane eventKey="Pre-Maintenance">
                                      <PreventativeMaintenance
                                        fromComponent="workorder"
                                        customerId={
                                          orderDetails &&
                                          orderDetails.CustomerID
                                        }
                                        work_order_id={orderDetails.ID}
                                        multitech={multitech}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {roleId !== 4 &&
                                  workorderId &&
                                  previousTab === 'WorkorderParts' ? (
                                    <Tab.Pane eventKey="WorkorderParts">
                                      <WorkorderParts
                                        workorderid={workorderId}
                                        orderDetails={orderDetails}
                                        primaryTechnician={
                                          primaryTechnician &&
                                          primaryTechnician.tech_id
                                        }
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {roleId !== 4 &&
                                  workorderId &&
                                  previousTab === 'diagnosis' ? (
                                    <Tab.Pane eventKey="diagnosis">
                                      <Diagnosis
                                        workorderid={workorderId}
                                        laboutToDate={this.state.laboutToDate}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}

                                  {[1, 5, 6].includes(roleId) &&
                                  workorderId &&
                                  WorkStatus === 'Paused' &&
                                  previousTab === 'WorkorderStatusUpdate' ? (
                                    <Tab.Pane eventKey="WorkorderStatusUpdate">
                                      <WorkOrderStatusUpdate
                                        workorderid={workorderId}
                                      />
                                    </Tab.Pane>
                                  ) : (
                                    ''
                                  )}
                                </Tab.Content>
                              </div>
                            </div>
                          </Tab.Container>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {roleId && roleId !== 4 && workorderId && (
          <CompleteJobPopup
            WorkStatus={WorkStatus}
            workCompleteJob={workCompleteJob}
            callbackfun={(type) => {
              this.getOrderData(workorderId);
            }}
            popupClose={() =>
              this.setState({ workCompleteJob: !workCompleteJob })
            }
            workorderId={workorderId}
            custId={custId}
            previousTechnicians={previousTechnicians}
          />
        )}
      </AUX>
    );
  }
}
export default withRouter(WorkModule);
