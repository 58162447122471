import React, { Component } from 'react';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import 'react-notifications/lib/notifications.css';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import { SetUserData } from '../../../action/user.action';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title';
import Loader from '../../../components/Loader';
import { uploadFile, deleteFile } from 'react-s3';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class updateAdminProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: '',
      last_name: '',
      email: '',
      mobile: '',
      newPassword: '',
      confirmPassword: '',
      fnameErr: '',
      lnameErr: '',
      emailErr: '',
      mobileErr: '',
      passErr: '',
      cpassErr: '',
      roleId: null,
      login_id: null,
      customerData: [],
      oldPassword: '',
      backupPassword: '',
      showOldPass: false,
      showNewPass: false,
      showConfirmPass: false,
      isShowLoader: false,
      changePassToggle: false,
      fromComponent: '',
      profile_pic: '',
      imageFile: null,
      imageUrl: '',
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('Update profile');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromcomponent
    ) {
      this.setState({
        fromComponent: this.props.location.state.fromcomponent,
      });
    }
    this.setState(
      {
        roleId: userdata.role_id,
        login_id: userdata.login_id,
        userId: userdata.id,
        username: `${userdata.first_name}${userdata.last_name}`,
      },
      async () => {
        if (userdata.role_id === 4 && userdata.login_id) {
          await this.getCustomerData(userdata.login_id);
        }
        if (userdata.role_id === 5 && userdata.login_id) {
          await this.getTechnicianData(userdata.login_id);
        }
        this.getAdminData();
      }
    );
  };
  getCustomerData = async (customerId) => {
    const endPoint = `${Api.getCustomerById}?customer_id=${customerId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        customerData: data.data,
        IsPreventiveMaintainance:
          data.data.IsPreventiveMaintainance &&
          data.data.IsPreventiveMaintainance.toString(),
      });
    } else {
      swal(message, '', 'error');
    }
  };

  getTechnicianData = async (technicianId) => {
    const endPoint = `${Api.technicianUrl}?tech_id=${technicianId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        technicianData: data.data.data[0],
      });
    } else {
      swal(message, '', 'error');
    }
  };

  getAdminData = async () => {
    this.setState({ isShowLoader: true });
    const { userId } = this.state;
    let endPoint = `${Api.getAdmin}?id=${userId}`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        isShowLoader: false,
        first_name: data.data.data[0].first_name,
        last_name: data.data.data[0].last_name,
        email: data.data.data[0].email,
        mobile: data.data.data[0].mobile,
        profile_pic: data.data.data[0].profile_pic,
      });
    } else {
      var $this = this;
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
      setTimeout(() => {
        $this.props.history.push('/dashboard');
      }, 1000);
    }
  };

  validateForm = async (e) => {
    e.preventDefault();
    const {
      first_name,
      email,
      oldPassword,
      newPassword,
      confirmPassword,
      roleId,
      imageFile,
    } = this.state;

    let fnameErr = '',
      lnameErr = '',
      emailErr = '',
      mobileErr = '',
      passErr = '',
      cpassErr = '',
      oldpassErr = '',
      isValid = true;

    if (!first_name || (first_name && first_name.trim() === '')) {
      fnameErr = 'First name is required';
      isValid = false;
    }
    // if (!last_name || (last_name && last_name.trim() === '')) {
    //   lnameErr = 'Last name is required';
    //   isValid = false;
    // }
    if (!email || (email && email.trim() === '')) {
      emailErr = 'Email is required';
      isValid = false;
    } else if (!customFunctions.validateEmail(email)) {
      emailErr = 'Invalid email';
    }

    // if (!mobile || (mobile && String(mobile).trim() === '')) {
    //   mobileErr = 'Mobile is required';
    //   isValid = false;
    // }

    if (confirmPassword && (newPassword.trim() === '' || !newPassword)) {
      passErr = 'New password is required';
      isValid = false;
    }
    if (newPassword && (confirmPassword.trim() === '' || !confirmPassword)) {
      cpassErr = 'Password confirmation is required';
      isValid = false;
    }
    if (newPassword && confirmPassword && newPassword !== confirmPassword) {
      cpassErr = 'Password did not match';
      isValid = false;
    }
    if (
      confirmPassword.trim() !== '' &&
      confirmPassword === newPassword &&
      (!oldPassword || oldPassword === '')
    ) {
      oldpassErr = 'Old password is required';
      isValid = false;
    }
    if (roleId === 5 && (!oldPassword || oldPassword === '')) {
      oldpassErr = 'Old password is required';
      isValid = false;
    }

    if (
      confirmPassword.trim() === '' &&
      newPassword.trim() === '' &&
      oldPassword
    ) {
      passErr = 'New password is required';
      isValid = false;
    }
    await this.setState({
      fnameErr,
      lnameErr,
      emailErr,
      mobileErr,
      passErr,
      cpassErr,
      oldpassErr,
    });
    if (isValid) {
      if (imageFile) {
        let imgurl = await this.uploadImage(imageFile);
        this.setState({ imageUrl: imgurl }, () => {
          this.updateProfile();
        });
      } else {
        this.updateProfile();
      }
    }
  };

  updateProfile = async () => {
    const { roleId } = this.state;
    if (roleId === 4) {
      await this.updateCustomer();
    }
    if (roleId === 5 || roleId === 3) {
      await this.updateTechnician();
    }
    if (roleId === 1) {
      await this.userUpdate();
    }
  };

  userUpdate = async () => {
    const {
      first_name,
      last_name,
      email,
      mobile,
      newPassword,
      oldPassword,
      roleId,
      userId,
      login_id,
      fromComponent,
      imageFile,
      imageUrl,
    } = this.state;
    this.setState({ isShowLoader: true });
    const postData = {
      first_name,
      last_name,
      email,
      mobile: mobile && mobile.toString(),
      username: first_name + ' ' + last_name,
      login_id: userId,
      isactive: 1,
      old_password: oldPassword,
      new_password: newPassword,
      profile_pic: imageUrl == '' ? '' : imageUrl,
    };

    if (roleId === 1) postData.role = 1;
    if (roleId === 4) postData.role = 4;
    if (roleId === 5) postData.role = 5;
    if (roleId === 3) postData.role = 3;

    const payload = customFunctions.cleanObject(postData);
    const endPoint = Api.getAdmin;
    const callback = AuthApi.putDataToServer;
    const { data, message } = await callback(endPoint, payload);

    if (data && data.success) {
      var $this = this;
      swal(
        [5, 3].includes(roleId)
          ? 'Password change successfully!'
          : 'Profile updated!',
        '',
        'success'
      );

      const { userdata } = this.props;
      userdata.first_name = first_name;
      userdata.last_name = last_name;
      if (imageFile) userdata.profile_pic = imageUrl;

      await this.props.SetUserData(userdata);

      await this.getAdminData();
      this.setState({ isShowLoader: false });
      setTimeout(() => {
        this.resetData();
        if (fromComponent === 'technician') {
          this.props.history.goBack();
        } else {
          if (roleId === 1) {
            this.props.history.push(`/profile`);
            return;
          }
          if (roleId === 4) {
            $this.props.history.push(`/profile-module/${login_id}`);
          }
        }
      }, 1000);
    } else {
      swal(message, '', 'error');
    }
    this.setState({ isShowLoader: false });
  };

  updateTechnician = async () => {
    this.setState({ isShowLoader: true });
    let { technicianData, first_name, last_name, roleId, imageUrl } =
      this.state;
    const callback = AuthApi.putDataToServer;
    const endPoint = Api.AddtechnicianUrl;
    const postData = {
      role: roleId,
      technician_id: technicianData.id,
      technician_name:
        first_name && last_name
          ? first_name + ' ' + last_name
          : technicianData.name,
      daily_rate: technicianData.daily_rate,
      percentage: technicianData.percentage,
      signature: technicianData.signature,
      latitude: technicianData.latitude,
      longitude: technicianData.longitude,
      status: technicianData.status,
      profile_pic: imageUrl == '' ? '' : imageUrl,
    };
    const { data } = await callback(endPoint, postData);
    if (data && data.success) {
      this.userUpdate();
    } else {
      swal('Something went wrong !', '', 'error');
    }
    this.setState({ isShowLoader: false });
  };

  updateCustomer = async () => {
    this.setState({ isShowLoader: true });
    let {
      customerData,
      IsPreventiveMaintainance,
      first_name,
      last_name,
      imageUrl,
    } = this.state;
    const callback = AuthApi.putDataToServer;
    const endPoint = Api.customerUrl;
    const postData = {
      id: customerData.ID,
      customer_name:
        first_name && last_name
          ? first_name + ' ' + last_name
          : customerData.CustomerName,
      contact_person: customerData.ContactName,
      job_title: customerData.ContactTitle,
      email: customerData.email,
      website: customerData.Website,
      type_1: customerData.Type1,
      type_2: customerData.Type2,
      type_3: customerData.Type3,
      type_4: customerData.Type4,
      mobile_1: String(customerData.PhoneNumber1),
      mobile_2: String(customerData.PhoneNumber2),
      mobile_3: String(customerData.PhoneNumber3),
      mobile_4: String(customerData.PhoneNumber4),
      notes: customerData.Notes,

      business_address1: customerData.AddressLine1_1,
      business_address2: customerData.AddressLine2_1,
      business_city: customerData.City1,
      business_state: customerData.State1,
      business_zip: customerData.Zip1,

      home_address1: customerData.AddressLine1_2,
      home_address2: customerData.AddressLine2_2,
      home_city: customerData.City2,
      home_state: customerData.State2,
      home_zip: customerData.Zip2,

      billing_address1: customerData.AddressLine1_3,
      billing_address2: customerData.AddressLine2_3,
      billing_city: customerData.City3,
      billing_state: customerData.State3,
      billing_zip: customerData.Zip3,

      shipping_address1: customerData.AddressLine1_4,
      shipping_address2: customerData.AddressLine2_4,
      shipping_city: customerData.City4,
      shipping_state: customerData.State4,
      shipping_zip: customerData.Zip4,
      is_active: 1,
      IsPreventiveMaintainance: IsPreventiveMaintainance,
      // profile_pic: profile_pic == '' ? '' : profile_pic
    };

    const payload = customFunctions.cleanObject(postData);

    const { data } = await callback(endPoint, payload);
    if (data && data.success) {
      this.userUpdate();
    } else {
      swal('Something went wrong !', '', 'error');
    }
    this.setState({ isShowLoader: false });
  };

  handelImageChange = async (e) => {
    if (!e.target.files || e.target.files.length < 1) return;
    const fileUrl = URL.createObjectURL(e.target.files[0]);

    this.setState({
      imageFile: e.target.files[0],
      imageUrl: fileUrl,
      profile_pic: fileUrl,
    });
  };

  uploadImage = async (imageFile) => {
    if (!imageFile) return null;
    const { username } = this.state;
    const newImage = imageFile;
    const file = new File(
      [newImage],
      `${username}_${new Date().toISOString()}_img.fileName`,
      { type: newImage.type }
    );

    // deleteFile(`${username}_${new Date().toISOString()}_img.fileName`, config)
    //       .then(response => console.log(response))
    //       .catch(err => console.error(err))

    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    return imgData;
  };

  resetData = () => {
    this.setState({
      changePassToggle: false,
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    });
  };

  render() {
    const {
      first_name,
      last_name,
      email,
      oldPassword,
      newPassword,
      confirmPassword,
      fnameErr,
      lnameErr,
      emailErr,
      passErr,
      cpassErr,
      oldpassErr,
      mobile,
      roleId,
      IsPreventiveMaintainance,
      showOldPass,
      showNewPass,
      showConfirmPass,
      isShowLoader,
      changePassToggle,
      fromComponent,
      profile_pic,
    } = this.state;

    return (
      <AUX>
        <div className={[1, 5].includes(roleId) ? 'page-content-wrapper' : ''}>
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <HelmetComponent title="My Profile" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body m-t-30">
                    <div className="text-center m-b-10">
                      <div className="position-relative">
                        <input
                          onChange={(e) => {
                            this.handelImageChange(e);
                          }}
                          title={`Edit Profile Picture`}
                          className="profile-file"
                          type="file"
                          accept="image/png, image/jpeg,image/jpg, "
                        />
                        <img
                          className="profilepic"
                          src={
                            profile_pic
                              ? profile_pic
                              : '/assets/images/users/user.png'
                          }
                          alt=""
                          width={100}
                          height={100}
                        />
                        <span
                          className="profile-pen"
                          style={{ left: roleId === 1 ? '53%' : '55%' }}
                        >
                          <i
                            className="mdi mdi-lead-pencil"
                            title={`Edit Profile Picture`}
                          ></i>
                        </span>
                      </div>
                    </div>
                    <form onSubmit={this.validateForm}>
                      {roleId !== 5 && (
                        <>
                          <div className="form-group row">
                            <div className="col-sm-2" />
                            <label
                              htmlFor="firstname"
                              className="col-sm-2 col-form-label"
                            >
                              First Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-5">
                              <input
                                className="form-control"
                                value={first_name}
                                type="text"
                                placeholder="John"
                                id="firstname"
                                onChange={(e) => {
                                  this.setState({ first_name: e.target.value });
                                }}
                              />
                              <span id="err">{fnameErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-2" />
                            <label
                              htmlFor="lastname"
                              className="col-sm-2 col-form-label"
                            >
                              Last Name
                            </label>
                            <div className="col-sm-5">
                              <input
                                className="form-control"
                                value={last_name}
                                type="text"
                                placeholder="Doe"
                                id="lastname"
                                onChange={(e) => {
                                  this.setState({ last_name: e.target.value });
                                }}
                              />
                              <span id="err">{lnameErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-2" />
                            <label
                              htmlFor="email"
                              className="col-sm-2 col-form-label"
                            >
                              Email<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-5">
                              <input
                                className="form-control"
                                value={email}
                                disabled
                                type="text"
                                placeholder="user@example.com"
                                id="email"
                                onChange={(e) => {
                                  this.setState({ email: e.target.value });
                                }}
                              />
                              <span id="err">{emailErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-2" />
                            <label
                              htmlFor="mobile"
                              className="col-sm-2 col-form-label"
                            >
                              Mobile
                            </label>
                            <div className="col-sm-5">
                              <input
                                type="text"
                                className="form-control"
                                value={mobile}
                                id="mobile"
                                placeholder="9999999999"
                                maxLength="13"
                                onChange={(e) => {
                                  this.setState({
                                    mobile: customFunctions.getMobile(
                                      e.target.value
                                    ),
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}
                      {roleId === 4 && (
                        <div className="form-group row">
                          <div className="col-sm-2" />
                          <label
                            htmlFor="preventative"
                            className="col-sm-2 col-form-label"
                          >
                            Is Preventative Maintenance
                          </label>
                          <div className="col-sm-6">
                            <Radio
                              checked={IsPreventiveMaintainance === '1'}
                              onChange={(e) => {
                                this.setState({
                                  IsPreventiveMaintainance: e.target.value,
                                });
                              }}
                              value="1"
                              id="preventative"
                              disabled
                              color="default"
                              name="radio-button-demo"
                            />
                            Yes
                            <Radio
                              checked={
                                IsPreventiveMaintainance === '0' ||
                                IsPreventiveMaintainance !== '1'
                              }
                              onChange={(e) => {
                                this.setState({
                                  IsPreventiveMaintainance: e.target.value,
                                });
                              }}
                              value="0"
                              id="preventative"
                              color="default"
                              disabled
                              name="radio-button-demo"
                            />
                            No
                          </div>
                        </div>
                      )}
                      {fromComponent !== 'technician' && (
                        <div className="form-group row">
                          <div className="col-sm-2" />
                          <a
                            href="/"
                            className="col-sm-4 col-form-label"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                changePassToggle: !changePassToggle,
                              });
                            }}
                          >
                            Change Password ?
                          </a>
                        </div>
                      )}
                      {(changePassToggle || fromComponent === 'technician') && (
                        <>
                          <div className="form-group row">
                            <div className="col-sm-2" />
                            <label
                              htmlFor="old-password"
                              className="col-sm-2 col-form-label"
                            >
                              Old Password
                            </label>
                            <div className="col-sm-5">
                              <input
                                className="form-control"
                                value={oldPassword}
                                type={`${showOldPass ? 'text' : 'password'}`}
                                placeholder="*****"
                                id="old-password"
                                onChange={(e) => {
                                  this.setState({
                                    oldPassword: e.target.value,
                                  });
                                }}
                              />
                              <div
                                className="password__toggle"
                                onClick={() =>
                                  this.setState({ showOldPass: !showOldPass })
                                }
                              >
                                <i
                                  className={`${
                                    showOldPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye`}
                                ></i>
                                <i
                                  className={`${
                                    !showOldPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye-line-through`}
                                ></i>
                              </div>
                              <span id="err">{oldpassErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-2" />
                            <label
                              htmlFor="new-pass"
                              className="col-sm-2 col-form-label"
                            >
                              New Password
                            </label>
                            <div className="col-sm-5">
                              <input
                                className="form-control"
                                value={newPassword}
                                type={`${showNewPass ? 'text' : 'password'}`}
                                placeholder="*****"
                                id="new-pass"
                                onChange={(e) => {
                                  this.setState({
                                    newPassword: e.target.value,
                                  });
                                }}
                              />
                              <div
                                className="password__toggle"
                                onClick={() =>
                                  this.setState({ showNewPass: !showNewPass })
                                }
                              >
                                <i
                                  className={`${
                                    showNewPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye`}
                                ></i>
                                <i
                                  className={`${
                                    !showNewPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye-line-through`}
                                ></i>
                              </div>
                              <span id="err">{passErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-2" />
                            <label
                              htmlFor="confirm-pass"
                              className="col-sm-2 col-form-label"
                            >
                              Confirm Password
                            </label>
                            <div className="col-sm-5">
                              <input
                                className="form-control"
                                value={confirmPassword}
                                type={`${
                                  showConfirmPass ? 'text' : 'password'
                                }`}
                                placeholder="*****"
                                id="confirm-pass"
                                onChange={(e) => {
                                  this.setState({
                                    confirmPassword: e.target.value,
                                  });
                                }}
                              />
                              <div
                                className="password__toggle"
                                onClick={() =>
                                  this.setState({
                                    showConfirmPass: !showConfirmPass,
                                  })
                                }
                              >
                                <i
                                  className={`${
                                    showConfirmPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye`}
                                ></i>
                                <i
                                  className={`${
                                    !showConfirmPass ? 'active' : 'inactive'
                                  } icon icon--md icon--eye-line-through`}
                                ></i>
                              </div>
                              <span id="err">{cpassErr}</span>
                            </div>
                          </div>
                        </>
                      )}
                      <div className="form-group row d-flex justify-content-center">
                        <div className="col-sm-4">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/dashboard');
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStatetoProps = (state) => ({
  userdata: state.auth.userdata,
});

const mapDispatchtoProps = (dispatch) => ({
  SetUserData: (data) => dispatch(SetUserData(data)),
});

export default withRouter(
  connect(mapStatetoProps, mapDispatchtoProps)(updateAdminProfile)
);
