import customFunctions from '../helper/customFunctions';

const initialState = {
  technician: '',
  warantyCode: '',
  vendorid: null,
  searchType: '',
  noteToggle: false,
  chatBoxToggle: false,
  messageBoxToggle: false,
  count: 0,
  chatCount: 0,
  userdata: customFunctions.getDataFromLocalStorage('userdata', true),
  notification: { title: '', body: '', id: null },
  messageRecieverData: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SETTECHNICIAN':
      return {
        ...state,
        technician: action.technician,
      };

    case 'SETWARRANTYCODE':
      return {
        ...state,
        warantyCode: action.warantyCode,
      };

    case 'SETCUSTOMER':
      return {
        ...state,
        customer: action.customer,
      };
    case 'SETORDERTYPE':
      return {
        ...state,
        orderType: action.orderType,
      };
    case 'SETPAYMENTYPE':
      return {
        ...state,
        paymentType: action.paymentType,
      };
    case 'SETWORKCOMPLETE':
      return {
        ...state,
        WorkCompleteValue: action.WorkCompleteValue,
      };
    case 'SETFROMDATE':
      return {
        ...state,
        fromDate: action.fromDate,
      };
    case 'SETODATE':
      return {
        ...state,
        toDate: action.toDate,
      };
    case 'SETMONTH':
      return {
        ...state,
        month: action.month,
      };
    case 'SETMONTHYEAR':
      return {
        ...state,
        monthyear: action.monthyear,
      };
    case 'SETYEAR':
      return {
        ...state,
        year: action.year,
      };
    case 'SETCOMPLAINTECHNICIAN':
      return {
        ...state,
        complaintTechnician: action.complaintTechnician,
      };
    case 'SETCOMPLAINTWRNTYCODE':
      return {
        ...state,
        complaintWarantyCode: action.complaintWarantyCode,
      };
    case 'SETCOMPLAINTCUSTOMER':
      return {
        ...state,
        complaintCustomer: action.complaintCustomer,
      };
    case 'SETCOMPLAINTCATEGORY':
      return {
        ...state,
        complaintCategory: action.complaintCategory,
      };
    case 'SETCOMPLAINTSTATUS':
      return {
        ...state,
        complaintStatus: action.complaintStatus,
      };
    case 'SETCOMPLAINTFROMDATE':
      return {
        ...state,
        complaintFromDate: action.complaintFromDate,
      };
    case 'SETCOMPLAINTODATE':
      return {
        ...state,
        complaintToDate: action.complaintToDate,
      };
    case 'SETCOMPLAINTMONTH':
      return {
        ...state,
        complaintMonth: action.complaintMonth,
      };
    case 'SETCOMPLAINTMONTHYEAR':
      return {
        ...state,
        complaintMonthYear: action.complaintMonthYear,
      };
    case 'SETCOMPLAINTYEAR':
      return {
        ...state,
        complaintYear: action.complaintYear,
      };
    case 'SETVENDOR':
      return {
        ...state,
        vendorid: action.vendorid,
      };
    case 'SETFILTERTYPE':
      return {
        ...state,
        filtertype: action.filtertype,
      };
    case 'SETWEEKTYPE':
      return {
        ...state,
        weektype: action.weektype,
      };
    case 'SETTOGGLE':
      return {
        ...state,
        toggle: action.toggle,
      };
    case 'SETUSERDATA':
      return {
        ...state,
        userdata: action.userdata,
      };
    case 'SETSEARCHTYPE':
      return {
        ...state,
        searchType: action.searchType,
      };
    case 'SETNOTIFICATIONTOGGLE':
      return {
        ...state,
        noteToggle: action.noteToggle,
      };
    case 'SETNOTIFICATIONCOUNT':
      return {
        ...state,
        count: action.count,
      };
    case 'SETCHATNOTIFICATIONCOUNT':
      return {
        ...state,
        chatCount: action.chatCount,
      };
      case 'SETCHATTOGGLE':
      return {
        ...state,
        chatBoxToggle: action.chatBoxToggle,
      };
    case 'SETMESSAGETOGGLE':
      return {
        ...state,
        messageBoxToggle: action.messageBoxToggle,
      };
    case 'SETCHATTECH':
      return {
        ...state,
        techdata: action.techdata,
      };
    case 'SETCHATCUST':
      return {
        ...state,
        custdata: action.custdata,
      };
    case 'SETCHATRECIEVERDATA':
      return {
        ...state,
        recieverData: action.recieverData,
      };
    case 'SETMESSAGERECIEVERDATA':
      return {
        ...state,
        messageRecieverData: action.messageRecieverData,
      };
    case 'SETCALENDARDATE':
      return {
        ...state,
        calDate: action.calDate,
      };
    case 'SETNOTIFICATION':
      return {
        ...state,
        notification:
          action.notification.body === state.notification.body
            ? { title: '', body: '', id: null }
            : action.notification,
      };
    default:
      return state;
  }
};
export default userReducer;
