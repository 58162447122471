import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import AUX from '../../../../hoc/Aux_';

class BarLineChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: {},
      barChartOption: {},
      data: this.props.chartData,
    };
  }

  async componentDidMount() {
    this.getCharts();
  }
  async componentWillReceiveProps(props) {
    this.setState({ data: props.chartData }, () => {
      this.getCharts();
    });
  }
  getCharts = () => {
    // const { datasets } = this.refs.chart.chartInstance.data;
    const { data } = this.state;
    const { fromComponent } = this.props;

    let labels = '';
    var barData = [];
    var lineData = [];
    const barchartLabels = [];

    if (fromComponent === 'ahsReport') {
      barData =
        data &&
        data.length > 0 &&
        data.map(({ wocount: y, ...rest }) => ({
          // eslint-disable-line
          y,
          ...rest,
        }));
      lineData =
        data &&
        data.length > 0 &&
        data.map(({ average: y, ...rest }) => ({
          // eslint-disable-line
          y,
          ...rest,
        }));

      let year = new Date().getFullYear();
      data &&
        data.length > 0 &&
        data.map((i) => {
          // eslint-disable-line
          barchartLabels.push(i.Months.substring(0, 3) + ' ' + year);
        });

      //set labels for jobs stackbarchart
      labels = {
        label: function (tooltipItem, dataset) {
          let labels = '';
          labels += `
          <table style="text-align: center">
          <tr><td style=""><h4>${
            tooltipItem.datasetIndex === 1
              ? tooltipItem.value && parseInt(tooltipItem.value) + '%'
              : tooltipItem.value && parseInt(tooltipItem.value)
          }</h4></td></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 70px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag style="font-size:11px;"></mytag>${
            tooltipItem.label + ' ' + new Date().getFullYear()
          }</span><br>
          <span style="font-size:12px;">${
            tooltipItem.datasetIndex === 1 ? 'AVG' : 'SUM'
          }</span></td></tr>
          `;
          return labels;
        },
      };
    }
    if (fromComponent === 'billing') {
      barData =
        data &&
        data.map(({ weekly_total: y, ...rest }) => ({
          y,
          ...rest,
        }));
      lineData =
        data &&
        data.map(({ average: y, ...rest }) => ({
          y,
          ...rest,
        }));

      data &&
        data.map((i) => {
          // eslint-disable-line
          let dt = new Date(i.date).toDateString().substr(4, 6);
          barchartLabels.push(dt);
        });

      //set labels for jobs stackbarchart
      labels = {
        label: function (tooltipItem, dataset) {
          let labels = '';
          labels += `
          <table style="text-align: center">
          <tr><td style=""><h4>${
            tooltipItem.datasetIndex === 1
              ? `$` + Number(tooltipItem.value).toFixed(1) + `k`
              : Number(tooltipItem.value).toFixed(1)
          }</h4></td></tr>
          <tr><td><hr style="background: #fff;width: 95%"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag style="font-size:11px;">WEEK OF </mytag>${new Date(
            dataset.datasets[tooltipItem.datasetIndex].data[
              tooltipItem.index
            ].date
          ).toDateString()}</span>
          <hr style="background: #fff;width: 95%">
          <span style="font-size:12px;">${
            tooltipItem.datasetIndex === 1 ? 'AVERAGE SALES' : 'TOTAL SALES'
          }</span></td></tr>
          `;
          return labels;
        },
      };
    }

    let barChartData = {
      labels: barchartLabels,
      datasets: [
        {
          label: 'Bar Dataset',
          backgroundColor: 'rgba(255, 99, 132)',
          data: barData,
          // this dataset is drawn below
          order: 2,
        },
        {
          label: 'Line Dataset',
          fill: false,
          lineTension: 0,
          backgroundColor: '#67c506',
          borderColor: '#67c506',
          pointBorderColor: '#67c506',
          pointBackgroundColor: '#67c506',
          pointHoverBackgroundColor: '#67c506',
          pointHoverBorderColor: '#67c506',
          pointBorderWidth: 5,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 1,
          pointRadius: 1,
          data: lineData,
          type: this.props.fromComponent === 'ahsReport' ? 'bubble' : 'line',
          // this dataset is drawn on top
          order: 1,
        },
      ],
    };
    let barChartOption = {
      responsive: true,
      legend: {
        display: false,
      },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
      tooltips: {
        mode: 'nearest',
        enabled: false,
        custom: function (tooltipModel) {
          // Tooltip Element
          var tooltipEl = document.getElementById('chartjs-tooltip');
          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.color = '#fff';
            tooltipEl.innerHTML = '<div id="toolTip"></div>';
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            // var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = '<table>';

            bodyLines.forEach(function (body, i) {
              innerHtml += body;
            });
            innerHtml +=
              '</table><div id="tailShadow"></div><div id="tail1"></div>';

            var tableRoot = tooltipEl.querySelector('#toolTip');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        },
        pieceLabel: {
          render: 'label',
          arc: true,
          fontColor: '#000',
          position: 'outside',
        },
        callbacks: labels,
      },
    };
    this.setState({ barChartData, barChartOption });
  };

  render() {
    const { barChartData, barChartOption } = this.state;

    return (
      <AUX>
        <Bar
          ref="chart"
          data={barChartData}
          width={100}
          height={50}
          options={barChartOption}
        />
      </AUX>
    );
  }
}

export default withRouter(BarLineChart);
