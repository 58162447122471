// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../../../../helper/api';
import AuthApi from '../../../../helper/authApi';
import customFunctions from '../../../../helper/customFunctions';
import AUX from '../../../../hoc/Aux_';
import HelmetComponent from '../../Title/index';
import Loader from '../../../../components/Loader';
import SelectAsyncPaginate from '../../../../components/ReactSelectPagination/SelectAsyncPaginate';

class DispatcherReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Dispatcher Reports',
      selectedStatuses: [],
      keyword: '',
      dateRangeList: [
        'Current Week',
        'Last Week',
        'Current Month',
        'All Dates',
        '365 Days',
        '180 Days',
        '90 Days',
        '60 Days',
        '30 Days',
        '14 Days',
        '7 Days',
        '3 Days',
        '1 Day',
      ],
      dateRange: '30 Days',
      startDate: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
      endDate: new Date(),
      filterApplied: false,
      jobsBySource: [],
      jobsByServiceType: [],
      jobsCreatedBySource: [],
      filterCount: 1,
      initialHide: true,
      avgAccept: '',
      amtNotes: '',
      amtNotesPerDay: '',
      avgAmtNotes: '',
      jobsCreated: '',
      jobsAccepted: '',
      textMessageSent: '',
      statusUpdated: '',
      timeSpendPhone: '',
      timeLogged: '',
      isShowLoader: false,
      dispatcher: '',
      dispatcherName: '',
      dispatcherId: false,
      fromComponent: '',
    };
  }

  async componentDidMount() {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'reports-jobs'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    if (this.props && this.props.fromComponent) {
      this.setState({
        fromComponent: this.props.fromComponent,
      });
    }
    if (this.props && this.props.dispatcherId) {
      this.setState({
        dispatcher: this.props.dispatcherId,
      });
    }
    if (this.props && !this.props.fromComponent) {
      customFunctions.setTitle(this.state.title);
    }
    await this.getDispatchesReports();
  }

  getDispatchesReports = async () => {
    var { startDate, endDate, dateRange, dispatcher } = this.state;
    this.setState({ isShowLoader: true });
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    if (dateRange === '') {
      startDate = '';
      endDate = '';
    }
    let endPoint = `${Api.dispatchReports}?from_date=${startDate}&to_date=${endDate}&dispatcherId=${dispatcher}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        isShowLoader: false,
        amtNotes: data.data.Amtofnotesinput,
        amtNotesPerDay: data.data.Amtofnotesinputperday,
        avgAmtNotes: data.data.Avgamountofnotesinput.toFixed(2),
        jobsCreated: data.data.JobsCreatedBy,
        jobsAccepted: data.data.Jobsacceptedby,
        textMessageSent: data.data.Textmessagessent,
        statusUpdated: data.data.Statusupdates,
        timeSpendPhone: data.data.TimeSpentonphone,
        timeLogged: data.data.TimeloggedintoSoftware,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  filterCount = () => {
    const { selectedStatuses, dateRange } = this.state;
    let dateLen = dateRange !== '' ? 1 : 0;
    this.setState({
      filterCount: selectedStatuses && selectedStatuses.length + dateLen,
    });
  };

  changeDates = async () => {
    const { dateRange } = this.state;
    var date = new Date();
    let firstday;

    if (dateRange === 'Current Week') {
      var curr = new Date();
      var first = curr.getDate() - curr.getDay();
      var last = first + 6;
      // firstday = new Date(curr.setDate(first));
      firstday = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(first))
      );

      // date = new Date(curr.setDate(last));
      date = customFunctions.changeDateOnlyMDY(
        new Date(new Date().setDate(last))
      );
    }
    if (dateRange === 'Last Week') {
      let dates = customFunctions.getLastWeek(date);
      firstday = dates.firstDayOfLastWeek;
      date = dates.lastDayOfLastWeek;
    }
    if (dateRange === 'Current Month') {
      firstday = new Date(date.getFullYear(), date.getMonth(), 1);
      date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    }
    if (dateRange === '365 Days') {
      firstday = new Date(date.getTime() - 365 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '180 Days') {
      firstday = new Date(date.getTime() - 180 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '90 Days') {
      firstday = new Date(date.getTime() - 90 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '60 Days') {
      firstday = new Date(date.getTime() - 60 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '30 Days') {
      firstday = new Date(date.getTime() - 30 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '14 Days') {
      firstday = new Date(date.getTime() - 14 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '7 Days') {
      firstday = new Date(date.getTime() - 7 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '3 Days') {
      firstday = new Date(date.getTime() - 3 * 24 * 60 * 60 * 1000);
    }
    if (dateRange === '1 Day') {
      firstday = new Date(date.getTime() - 24 * 60 * 60 * 1000);
    }
    this.setState({ startDate: firstday, endDate: date, initialHide: false });

    if (dateRange === 'All Dates') {
      this.setState({ startDate: '', endDate: '' });
    }
    this.filterCount();
  };

  callback = () => {
    this.setState({ filterApplied: false });
  };

  render() {
    const {
      title,
      selectedStatuses,
      dateRange,
      dateRangeList,
      filterCount,
      initialHide,
      amtNotes,
      amtNotesPerDay,
      avgAmtNotes,
      jobsCreated,
      jobsAccepted,
      textMessageSent,
      statusUpdated,
      timeSpendPhone,
      timeLogged,
      isShowLoader,
      dispatcher,
      dispatcherName,
      dispatcherId,
      fromComponent,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12 m-b-20">
                <div id="accordion">
                  <div
                    className="card"
                    style={{
                      position: 'relative',
                      border: '2px solid #CCC',
                      borderBottom: 'none',
                    }}
                  >
                    <div
                      style={{ fontSize: '16px', minHeight: '59px' }}
                      className="card-header"
                      data-toggle="collapse"
                      href="#collapseOne"
                    >
                      <b>{`FILTERS(${filterCount})`}</b>{' '}
                      <span className="dropdown-toggle"></span>{' '}
                    </div>
                    {dateRange && (
                      <span className="selected-daterange">
                        <b>DateRange</b> {dateRange}
                        {initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                        {!initialHide && (
                          <i
                            id="collapseOne"
                            className={`icon-container mdi mdi-close collapse show`}
                            style={{ cursor: 'pointer', fontSize: '17px' }}
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              this.setState({ dateRange: '' }, () => {
                                this.filterCount();
                              });
                            }}
                          />
                        )}
                      </span>
                    )}
                    {selectedStatuses && selectedStatuses.length ? (
                      <span className="selected-statuses">
                        <b>Status</b>&nbsp;&nbsp;
                        {selectedStatuses.map((status, index) => (
                          <React.Fragment key={index}>
                            {status}
                            <i
                              id="collapseOne"
                              className="icon-container mdi mdi-close collapse show"
                              style={{ cursor: 'pointer', fontSize: '17px' }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                this.changeStatus(status);
                              }}
                            />
                            &nbsp;&nbsp;
                          </React.Fragment>
                        ))}
                      </span>
                    ) : (
                      ''
                    )}
                    <div
                      id="collapseOne"
                      className="collapse"
                      data-parent="#accordion"
                    >
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="card-body1">
                          <div className="row">
                            <div
                              className="col-4"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="accordion" id="accordionExample">
                                <div className="card">
                                  <div className="card-body1" id="headingOne">
                                    <button
                                      className="btn btn-block text-left dropdown-toggle card-header"
                                      type="button"
                                      data-toggle="collapse"
                                      data-target="#collapseOne1"
                                      aria-expanded="true"
                                      aria-controls="collapseOne"
                                    >
                                      Date Range
                                    </button>
                                  </div>

                                  <div
                                    id="collapseOne1"
                                    className="collapse show"
                                    aria-labelledby="headingOne"
                                    data-parent="#accordionExample"
                                  >
                                    <div className="card-body">
                                      {dateRangeList &&
                                        dateRangeList.map((dates, index) => (
                                          <React.Fragment key={index}>
                                            <Radio
                                              checked={dateRange === dates}
                                              onChange={(e) => {
                                                this.setState(
                                                  {
                                                    dateRange: e.target.value,
                                                  },
                                                  () => this.changeDates()
                                                );
                                              }}
                                              id={`dates${index}`}
                                              value={dates}
                                              color="default"
                                            />
                                            <label htmlFor={`dates${index}`}>
                                              {dates}
                                            </label>
                                            <br />
                                          </React.Fragment>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-4"
                              style={{ paddingLeft: '0px' }}
                            >
                              {!fromComponent && (
                                <div
                                  className="accordion"
                                  id="accordionExample1"
                                >
                                  <div className="card">
                                    <div className="card-body1" id="headingOne">
                                      <button
                                        className="btn btn-block text-left dropdown-toggle card-header"
                                        type="button"
                                        data-toggle="collapse"
                                        data-target="#collapseOne2"
                                        aria-expanded="true"
                                        aria-controls="collapseOne"
                                      >
                                        Dispatcher
                                      </button>
                                    </div>
                                    <div
                                      id="collapseOne2"
                                      className="collapse show"
                                      aria-labelledby="headingOne"
                                      data-parent="#accordionExample1"
                                    >
                                      <div
                                        className="card-body"
                                        style={{
                                          height: '295px',
                                          overflow: 'hidden',
                                        }}
                                      >
                                        <SelectAsyncPaginate
                                          onSelectChange={(e) => {
                                            this.setState({
                                              dispatcherId: false,
                                              dispatcher: e ? e.id : null,
                                              dispatcherName: e
                                                ? { name: e.name }
                                                : '',
                                            });
                                          }}
                                          className="w-100"
                                          isSearch={true}
                                          isClearable={dispatcher}
                                          value={
                                            dispatcherName ? dispatcherName : ''
                                          }
                                          apiname="technician"
                                          clearSelectedByParent={dispatcherId}
                                          endpoint={
                                            Api.technicianUrl +
                                            `?role=3&search=`
                                          }
                                          selectPlaceholder="Select Dispatcher"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div
                              className="col-12"
                              style={{ paddingRight: '0px' }}
                            >
                              <div className="card-footer">
                                <div className="row">
                                  <div className="col-4"></div>
                                  <div className="col-4"></div>
                                  <div className="col-4">
                                    <span
                                      onClick={() =>
                                        this.setState(
                                          {
                                            dateRange: '30 Days',
                                            selectedStatuses: [],
                                            filterApplied: false,
                                            startDate: new Date(
                                              new Date().getTime() -
                                                30 * 24 * 60 * 60 * 1000
                                            ),
                                            endDate: new Date(),
                                            dispatcher: '',
                                            dispatcherName: '',
                                            dispatcherId: true,
                                          },
                                          async () => {
                                            await this.filterCount();
                                            await this.getDispatchesReports();
                                            await this.forceUpdate();
                                          }
                                        )
                                      }
                                      className="resetFilter f-btns"
                                    >
                                      RESET FILTERS
                                    </span>
                                    <button
                                      className="btn btn-secondary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                    >
                                      CANCEL
                                    </button>
                                    <button
                                      className="btn btn-primary f-btns"
                                      href="#collapseOne"
                                      data-toggle="collapse"
                                      onClick={() => {
                                        this.setState(
                                          { filterApplied: true },
                                          async () => {
                                            await this.getDispatchesReports();
                                          }
                                        );
                                      }}
                                    >
                                      APPLY
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <h6>Ability to select a date range for average</h6>
                    <div className="row">
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Amt of notes input
                          </p>
                          <h1>{amtNotes}</h1>
                          <p style={{ fontSize: '22px' }}>Amt of notes input</p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Amt of Notes per Day
                          </p>
                          <h1>{amtNotesPerDay}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Amt of Notes per Day
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime3"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg amount of notes input
                          </p>
                          <h1>{avgAmtNotes}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg amount of notes input
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime2"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Text Messages Sent per Day
                          </p>
                          <h1>{textMessageSent}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Text Messages Sent per Day
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime4"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Time Spent on Phone per Day
                          </p>
                          <h1>{timeSpendPhone}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Time Spent on Phone per Day
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-2">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime1"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '14px', textAlign: 'left' }}
                          >
                            Avg Jobs Accepted per day
                          </p>
                          <h1>{jobsAccepted}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Jobs Accepted per day
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-2">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime4"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '14px', textAlign: 'left' }}
                          >
                            Avg Jobs Created per day
                          </p>
                          <h1>{jobsCreated}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Jobs Created per day
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-2">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime3"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Status Updates Per Day
                          </p>
                          <h1>{statusUpdated}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Status Updates Per Day
                          </p>
                          <br />
                        </div>
                      </div>
                      <div className="card col-3">
                        <div
                          className="card-body text-center py-3"
                          id="avgTime4"
                        >
                          <p
                            className="avgClass"
                            style={{ fontSize: '16px', textAlign: 'left' }}
                          >
                            Avg Time Logged into Software per day
                          </p>
                          <h1>{timeLogged}</h1>
                          <p style={{ fontSize: '22px' }}>
                            Avg Time Logged into Software per day
                          </p>
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(DispatcherReports);
