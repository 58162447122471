import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class FilterPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicianData: [],
      installerData: [],
      selectedTechnician: null,
      orderTypeData: [],
      selectedWorkType: null,
      workStatusData: [],
      selectedWorkStatus: null,
      callbackoptions: {},
    };
  }

  componentDidMount = async (props) => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
      technicianId: userdata.login_id,
    });

    if (
      this.props &&
      this.props.callBackOptions &&
      Object.keys(this.props.callBackOptions).length > 0
    ) {
      if (this.props.callBackOptions && this.props.callBackOptions.techId) {
        this.setState({
          selectedTechnician: this.props.callBackOptions.techId,
        });
      }

      if (this.props.callBackOptions && this.props.callBackOptions.workType) {
        this.setState({
          selectedWorkType: this.props.callBackOptions.workType,
        });
      }

      if (this.props.callBackOptions && this.props.callBackOptions.workStatus) {
        this.setState({
          selectedWorkStatus: this.props.callBackOptions.workStatus,
        });
      }
    }
    this.getTechnician();
    this.getWorkOrderType();
    this.getWorkStatus();
  };

  getTechnician = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.technicianUrl}?skip=0&limit=10000&onleave=approved&role=5,6`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const techOptions = [];

      (await data.data.data) &&
        data.data.data.map((tech) => {
          techOptions.push({
            value: tech.id,
            label: `${tech.name}`,
          });
          return tech;
        });

      this.setState({
        technicianData: techOptions,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: true });
      swal(message, '', 'error');
    }
  };

  getWorkOrderType = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.workOrderTypeUrl}?skip=0&limit=1000&type=1`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const orderOptions = [];

      await data.data.data.map((type) => {
        orderOptions.push({
          value: type.id,
          label: `${type.order_type}`,
        });
        return type;
      });
      this.setState({
        orderTypeData: orderOptions,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  getWorkStatus = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.workOrderTypeUrl}?skip=0&limit=1000&type=2`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const statusOptions = [];

      await data.data.data.map((type) => {
        statusOptions.push({
          value: type.id,
          label: `${type.order_type}`,
        });
        return type;
      });
      this.setState({
        workStatusData: statusOptions,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  filterCallback = async () => {
    const {
      selectedTechnician,
      selectedWorkType,
      selectedWorkStatus,
      callbackoptions,
    } = this.state;
    if (selectedTechnician && selectedTechnician.value) {
      callbackoptions.techId = selectedTechnician;
    }

    if (selectedWorkType && selectedWorkType.value) {
      callbackoptions.workType = selectedWorkType;
    }

    if (selectedWorkStatus && selectedWorkStatus.value) {
      callbackoptions.workStatus = selectedWorkStatus;
    }

    await customFunctions.setLocalStorage(
      'callBackOptions',
      JSON.stringify(callbackoptions)
    );

    if (this.props.popupClose) this.props.popupClose();
    if (this.props.callbackfun) this.props.callbackfun(callbackoptions);
  };

  popupClose = async () => {
    const { callbackoptions } = this.state;
    await this.setState({
      technicianData: [],
      installerData: [],
      selectedTechnician: null,
      orderTypeData: [],
      selectedWorkType: null,
      workStatusData: [],
      selectedWorkStatus: null,
      callbackoptions: '',
    });
    await customFunctions.setLocalStorage('callBackOptions', '');
    this.props.popupClose();
    this.props.callbackfun(callbackoptions);
  };

  handleTechnicianChange = (selectedOption) => {
    this.setState({ selectedTechnician: selectedOption });
  };

  handleWorkTypeChange = (selectedOption) => {
    this.setState({ selectedWorkType: selectedOption });
  };

  handleWorkStatus = (selectedOption) => {
    this.setState({ selectedWorkStatus: selectedOption });
  };

  render() {
    const {
      selectedTechnician,
      technicianData,
      selectedWorkType,
      orderTypeData,
      workStatusData,
      selectedWorkStatus,
    } = this.state;
    return (
      <div>
        <Modal
          className={'filter-popup'}
          size="md"
          centered
          onHide={this.props.popupClose}
          show={this.props.filterPopup}
        >
          <div className="add-task-popup schedule-filter-popup">
            <Modal.Header className="col-12">
              <>
                <span>Filter</span>
                <i
                  className="icon-container mdi mdi-close"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.popupClose();
                  }}
                />
              </>
            </Modal.Header>
            <Modal.Body>
              <>
                <div className="form-group row">
                  <label className="col-sm-3">Technician</label>
                  <div className="col-sm-6">
                    <ReactSelect
                      value={selectedTechnician}
                      placeholder="Select Technician"
                      onChange={(values) => this.handleTechnicianChange(values)}
                      options={technicianData}
                      isClearable
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3">Work Order Type</label>
                  <div className="col-sm-6">
                    <ReactSelect
                      value={selectedWorkType}
                      placeholder="Select Work Order Type"
                      onChange={(values) => this.handleWorkTypeChange(values)}
                      options={orderTypeData}
                      isClearable
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <label className="col-sm-3">Work Order Status</label>
                  <div className="col-sm-6">
                    <ReactSelect
                      value={selectedWorkStatus}
                      placeholder="Select Work Order Status"
                      onChange={(values) => this.handleWorkStatus(values)}
                      options={workStatusData}
                      isClearable
                    />
                  </div>
                </div>
              </>
            </Modal.Body>
            <Modal.Footer id="md-filder" className="w-100">
              <div className="float-right">
                <Button
                  variant="primary"
                  type="submit"
                  className="saveBtns l-m-10"
                  onClick={() => {
                    this.filterCallback();
                  }}
                >
                  Submit
                </Button>
                <Button
                  variant="secondary"
                  className="saveBtns ml-2"
                  onClick={() => {
                    this.popupClose();
                  }}
                >
                  Clear
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    );
  }
}

export default FilterPopup;
