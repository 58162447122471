import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Table from '../../../components/Table/simpletable.component';
import { Row, Col, Button } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

class HomeWarrantyCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      warrantyData: [],
      rows: [],
      title: 'All Home Warranty Company',
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      column: [],
      isWriteAllowed: false,
      isShowLoader: false,
      paginationObject: {},
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-homewarrantycompany'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-homewarrantycompany',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    const columns = [
      {
        label: 'Sr. No.',
        id: 'index',
        sort: 'disabled',
      },
      {
        label: 'Title',
        id: 'title',
        sort: 'disabled',
      },
      {
        label: 'Description',
        id: 'description',
        sort: 'disabled',
      },
      {
        label: 'Status',
        id: 'warranty',
        render: ({ warranty }) => (
          <React.Fragment>
            <input
              type="checkbox"
              id={`switch${warranty.id}`}
              disabled={!this.state.isWriteAllowed}
              checked={Number(warranty.status) ? 'checked' : ''}
              switch="info"
              onChange={(event) => {
                this.changeWarrantyCodeStatus(warranty, event);
              }}
            />
            <label
              htmlFor={`switch${warranty.id}`}
              data-on-label=""
              data-off-label=""
            ></label>
          </React.Fragment>
        ),
      },
      {
        label: 'Action',
        id: 'action',
        render: ({ warranty }) => (
          <React.Fragment>
            <a
              href="/"
              style={{ pointerEvents: this.state.isWriteAllowed ? '' : 'none' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push({
                  pathname: '/add-home-warranty-company',
                  state: {
                    detail: warranty,
                    paginationObj: this.state.pagination,
                  },
                });
              }}
            >
              <i
                className="mdi mdi-lead-pencil"
                id="editWaranty"
                title="Edit Home Warranty Code"
              ></i>
            </a>
            <a
              href="/"
              style={{ pointerEvents: this.state.isWriteAllowed ? '' : 'none' }}
              className=""
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ showDelete: true, companyId: warranty.id });
              }}
            >
              <i className="mdi mdi-delete text-danger" title="Delete"></i>
            </a>
          </React.Fragment>
        ),
      },
    ];

    this.setState({ column: columns });

    customFunctions.setTitle(this.state.title);
    if (
      this.props &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          paginationObject: this.props.location.state.paginationObj,
        },
        async () => {
          await this.getWarrantyList();
        }
      );
    } else {
      await this.getWarrantyList();
    }
  };

  getWarrantyList = async (currentPage) => {
    const { keyword, pagination, paginationObject } = this.state;
    this.setState({ isShowLoader: true });
    const page =
      paginationObject && paginationObject.current_page
        ? paginationObject.current_page
        : currentPage || 1;
    const skip = page * pagination.itemsPerPage - pagination.itemsPerPage;
    const endPoint = `${Api.getWarrantyCompany}`;
    const params = {
      skip: skip,
      limit: pagination.itemsPerPage,
      search: keyword,
    };

    const url = customFunctions.generateUrl(endPoint, params);

    const { data, message } = await AuthApi.getDataFromServer(url);
    if (data && data.data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          warrantyData: data.data.data,
          pagination: newPagination,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  changeWarrantyCodeStatus = async (warrantyCode, event) => {
    var isChecked = event.target.checked;
    const payload = {
      title: warrantyCode.title,
      description: warrantyCode.description,
      homewarrantycompanyId: warrantyCode.id,
      status: isChecked ? '1' : '0',
    };
    const { data, message } = await AuthApi.putDataToServer(
      Api.AddWarrantyCompany,
      payload
    );
    if (data && data.success !== false) {
      swal('Home warranty company status changed', '', 'success');
      this.getWarrantyList(this.state.pagination.current_page);
    } else {
      swal(message, '', 'error');
    }
  };

  setTableData = () => {
    const { warrantyData, pagination } = this.state;
    const rows = [];
    const page = pagination.current_page || 1;
    warrantyData &&
      warrantyData.map((warranty, i) => {
        rows.push({
          index: (page - 1) * pagination.itemsPerPage + (i + 1),
          title: warranty.title,
          description: warranty.description,
          warranty: warranty,
          action: warranty,
        });
        return warranty;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getWarrantyList();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getWarrantyList();
    });
  };

  deleteCompany = async () => {
    const { companyId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.AddWarrantyCompany}?homewarrantycompanyId=${companyId}`
    );
    if (data) {
      this.setState({ showDelete: false, companyId: null }, () => {
        swal('Home warranty company deleted', '', 'success').then(() => {
          this.getWarrantyList();
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      keyword,
      rows,
      pagination,
      showDelete,
      isWriteAllowed,
      isShowLoader,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={this.state.title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the home warranty comapany ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteCompany();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, companyId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-header">
                      <div className="left-section">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group d-flex">
                            <div className="input-wrap">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here by title"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>

                            <div className="buttons-wrap">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {isWriteAllowed && (
                        <div className="right-section">
                          <div className="right-actions">
                            <Row md={12}>
                              <Col col-sm-3>
                                <Button
                                  style={{ float: 'right' }}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/add-home-warranty-company`
                                    )
                                  }
                                >
                                  Add Home Warranty Company
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}
                    </div>

                    <Table
                      columns={this.state.column}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.setState({
                            paginationObject: {},
                          });
                          this.getWarrantyList(pageNo);
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(HomeWarrantyCompany);
