import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import SharedService from '../../../helper/SharedService';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';

class ProfitLoss extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      customers: [],
      rows: [],
      id: null,
      custmrnme: '',
      selectedCustomerName: '',
      customerNameData: [],
      jobTotal: '',
      paymentTotal: '',
      poTotal: '',
      payrollCommission: '',
      profitLoss: '',
      skip: 0,
      page: 1,
      limit: 4,
      totalCount: 5,
      currentPage: 1,
      type: null,
      ordercode: null,
      isShowLoader: false,
      outstandingbal: 0,
      installerCost: null,
    };
  }

  componentDidMount = async () => {
    this.unsubscribe =
      SharedService.changesInPaymentFromAddEditPayment.subscribe(
        async (value) => {
          await this.getProfitLossData();
        }
      );
    if (this.props) {
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.customerId
      ) {
        await this.setState({
          order_id: await this.props.match.params.customerId,
        });
        await this.getProfitLossData(await this.props.match.params.customerId);
      }
      if (this.props.location && this.props.location.state) {
        if (this.props.location.state.type) {
          await this.setState({ type: await this.props.location.state.type });
        }
        if (
          this.props.location.state.orderDetails &&
          this.props.location.state.orderDetails.ID
        ) {
          await this.setState({
            ordercode: await this.props.location.state.orderDetails.ID,
          });
        }
      }
    }
    if (this.props.technicianId) {
      this.setState({ id: this.props.technicianId }, () => {
        this.getProfitLossData();
      });
    }
  };

  getProfitLossData = async (customerId) => {
    this.setState({ isShowLoader: true });
    const { id, type, ordercode, order_id } = this.state;
    let endPoint = `${Api.profitLossUrl}`;
    if (type === 'technician') {
      endPoint = `${Api.profitLossUrl}/by_technician?technician_id=${id}`;
    } else if (type === 'customer') {
      endPoint = `${Api.profitLossUrl}/by_customer_id?customer_id=${order_id}`;
    } else if (type === 'orders') {
      endPoint = `${Api.profitLossUrl}/by_work_order?work_order=${ordercode}`;
    } else if (
      (await this.props.match) &&
      (await this.props.match.params) &&
      (await this.props.match.params.customerId)
    ) {
      let id1 = this.props.match.params.customerId;
      endPoint = `${Api.profitLossUrl}/by_work_order?work_order=${id1}`;
    }

    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState({
        jobTotal: data.data.job_total || 0,
        paymentTotal: data.data.payment_total || 0,
        poTotal: data.data.po_total || 0,
        payrollCommission:
          data.data.tech_payroll !== 'NaN' ? data.data.tech_payroll : 0,
        profitLoss: data.data.profit_loss || 0,
        outstandingbal: data.data.outstanding || 0,
        installerCost: data.data.installer_cost || 0,
      });
    } else {
      swal(message, '', 'error');
    }
    this.setState({ isShowLoader: false });
  };

  componentWillUnmount = () => {
    if (this.unsubscribe) {
      this.unsubscribe.unsubscribe();
    }
  };

  render() {
    const {
      jobTotal,
      paymentTotal,
      poTotal,
      payrollCommission,
      profitLoss,
      outstandingbal,
      isShowLoader,
      installerCost,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="card m-b-20">
          <div className="card-body">
            <div className="row col-md-12 mb-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="example-search-input">
                  Work Order Job Total
                </label>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="jobTotal">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control disabled"
                    aria-label="jobTotal"
                    aria-describedby="jobTotal"
                    value={jobTotal}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row col-md-12 mb-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="example-search-input">Payment Total</label>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="paymentTotal">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control disabled"
                    aria-label="paymentTotal"
                    aria-describedby="paymentTotal"
                    value={paymentTotal}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row col-md-12 mb-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="example-search-input">Po Total</label>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="poTotal">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control disabled"
                    aria-label="poTotal"
                    aria-describedby="poTotal"
                    value={poTotal}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row col-md-12 mb-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="example-search-input">Installer Cost</label>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="poTotal">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control disabled"
                    aria-label="poTotal"
                    aria-describedby="poTotal"
                    value={installerCost}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row col-md-12 mb-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="example-search-input">
                  Tech Payroll Commission
                </label>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="payrollCommission">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control disabled"
                    aria-label="payrollCommission"
                    aria-describedby="payrollCommission"
                    value={payrollCommission}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row col-md-12 mb-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="example-search-input">Profit/Loss</label>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="profitLoss">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control disabled"
                    aria-label="profitLoss"
                    aria-describedby="profitLoss"
                    value={profitLoss}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="row col-md-12 mb-3 align-items-center">
              <div className="col-md-6">
                <label htmlFor="example-search-input">
                  Outstanding Balance
                </label>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text" id="outstanding balance">
                      $
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control disabled"
                    aria-label="profitLoss"
                    aria-describedby="profitLoss"
                    value={outstandingbal}
                    readOnly
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(ProfitLoss);
