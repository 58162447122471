import React, { Component } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import CardPayment from './invoiceCustomerPay';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class InvoicePayment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chequeAmount: null,
      chequeNumber: null,
      chequePaymentDate: '',
      chequeAmountErr: '',
      chequeNumberErr: '',
      chequePaymentDateErr: '',
      accountName: '',
      accountNameErr: '',
      routingNumber: null,
      routingNumberErr: '',
      accountNumber: null,
      accountNumberErr: '',
      accountType: '',
      accountTypeErr: '',
      accountTypeData: [
        {
          value: 'checking',
          label: 'Checking',
        },
        {
          value: 'savings',
          label: 'Savings',
        },
        {
          value: 'business_checking',
          label: 'Business Checking',
        },
      ],

      cashAmount: null,
      cashPaymentDate: '',
      cashAmountErr: '',
      cashPaymentDateErr: '',

      invoiceId: null,
      totalAmount: null,
      due_amount: null,
      touched: {},
      isValidateCash: false,
      isValidateCheque: false,
      isShowLoader: false,
      paymentTypeList: [],
      activeTab: '',
      ipaddress: '',
      taxPercentage: null,
      chequeTaxAmount: null,
      cashTaxAmount: null,
    };
  }
  async componentDidMount() {
    //creating function to load ip address from the API

    fetch('https://ipapi.co/json/')
      .then((response) => {
        return response.json();
      }, 'jsonp')
      .then((res) => {
        this.setState({ ipaddress: res.ip });
      })
      .catch((err) => console.log(err));

    this.setState({
      invoiceId: await this.props.invoiceId,
      totalAmount: this.props.due_amount
        ? this.props.due_amount
        : this.props.totalAmount,
      chequeAmount: this.props.due_amount
        ? this.props.due_amount
        : this.props.totalAmount,
      cashAmount: this.props.due_amount
        ? this.props.due_amount
        : this.props.totalAmount,
      due_amount: this.props.due_amount,
    });
    this.getPaymentType();
    if (this.props && this.props.showPaymentPopup) {
      await this.getScript();
      this.forceUpdate();
    }
  }

  getPaymentType = async () => {
    const endPoint = `${Api.paymentTypeUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      let response = data.data.data;
      let active =
        response &&
        response.length &&
        response.filter(
          (i) => i.status === '1' && i.payment_type.toLowerCase() !== 'finance'
        );
      this.setState(
        {
          paymentTypeList: data.data.data,
          activeTab:
            active && active.length && active[0].payment_type.toLowerCase(),
        },
        () => {
          this.getPercentage(this.state.activeTab);
        }
      );
    }
  };

  async componentWillReceiveProps(props) {
    this.setState({
      due_amount: props.due_amount,
      totalAmount: props.due_amount ? props.due_amount : props.totalAmount,
      chequeAmount: props.due_amount ? props.due_amount : props.totalAmount,
      cashAmount: props.due_amount ? props.due_amount : props.totalAmount,
    });
  }

  validateCash = () => {
    const { cashAmount, cashPaymentDate, due_amount, cashTaxAmount } =
      this.state;

    let isValid = true,
      cashAmountErr,
      cashPaymentDateErr;

    if (!cashAmount || cashAmount <= 0) {
      isValid = false;
      cashAmountErr = 'Please enter amount';
    }
    if (cashAmount && parseFloat(cashAmount) > cashTaxAmount) {
      isValid = false;
      cashAmountErr = `Entered amount should not be greater than ${
        due_amount ? 'due' : 'total'
      } amount`;
    }
    if (!cashPaymentDate) {
      isValid = false;
      cashPaymentDateErr = 'Please enter a payment date';
    }

    this.setState({
      cashAmountErr,
      cashPaymentDateErr,
    });
    return isValid;
  };

  validateCheque = () => {
    const {
      chequeAmount,
      // chequeNumber,
      chequePaymentDate,
      due_amount,
      accountName,
      routingNumber,
      accountNumber,
      accountType,
      chequeTaxAmount,
    } = this.state;

    let isValid = true,
      chequeAmountErr,
      // chequeNumberErr,
      chequePaymentDateErr,
      routingNumberErr,
      accountNumberErr,
      accountNameErr,
      accountTypeErr;

    if (!chequeAmount || chequeAmount <= 0) {
      isValid = false;
      chequeAmountErr = 'Please enter amount';
    }

    if (chequeAmount && parseFloat(chequeAmount) > chequeTaxAmount) {
      isValid = false;
      chequeAmountErr = `Entered amount should not be greater than ${
        due_amount ? 'due' : 'total'
      } amount`;
    }
    // if (!chequeNumber || (chequeNumber && chequeNumber.trim() === '')) {
    //   isValid = false;
    //   chequeNumberErr = 'Please enter check number';
    // }

    if (!chequePaymentDate) {
      isValid = false;
      chequePaymentDateErr = 'Please enter a payment date';
    }

    if (!routingNumber || (routingNumber && routingNumber.trim() === '')) {
      isValid = false;
      routingNumberErr = 'Please enter aba routing number';
    }

    if (!accountNumber || (accountNumber && accountNumber.trim() === '')) {
      isValid = false;
      accountNumberErr = 'Please enter account number';
    }
    if (!accountName) {
      isValid = false;
      accountNameErr = 'Please enter account name';
    }
    if (!accountType) {
      isValid = false;
      accountTypeErr = 'Please select account type';
    }

    this.setState({
      chequeAmountErr,
      // chequeNumberErr,
      chequePaymentDateErr,
      accountNameErr,
      routingNumberErr,
      accountTypeErr,
      accountNumberErr,
    });
    return isValid;
  };

  handleTouch(field_name) {
    let { touched } = this.state;
    if (field_name && touched[field_name] !== true) {
      touched[field_name] = true;
      this.setState(
        {
          touched,
        },
        () => {
          this.forceUpdate();
        }
      );
    }
  }
  startPayment = async (e, type) => {
    e.preventDefault();
    this.setState({
      isValidateCash: type === 'cash' ? true : false,
      isValidateCheque: type === 'echeck' ? true : false,
    });
    let isValid = type === 'cash' ? this.validateCash() : this.validateCheque();
    if (isValid) {
      const {
        invoiceId,
        totalAmount,
        cashPaymentDate,
        cashAmount,
        chequeAmount,
        chequeNumber,
        chequePaymentDate,
        accountName,
        routingNumber,
        accountType,
        accountNumber,
        ipaddress,
      } = this.state;
      const payload = {
        invoice_id: invoiceId,
        amount: totalAmount,
        payment_type: type,
        cheque_no: '',
        status: 'success',
        payment_response: {},
        id_address: ipaddress,
      };

      if (type === 'echeck') {
        let {success,res} = await this.startPaymentCheck(type);
        if (!success) return;
        payload.payment_date =
          chequePaymentDate && new Date(chequePaymentDate).toISOString();
        payload.received_amt = chequeAmount && Number(chequeAmount);
        payload.cheque_no = chequeNumber && Number(chequeNumber);
        payload.account_name = accountName && accountName;
        payload.response_code = '1';
        payload.transaction_id = res.transId;
        payload.routing_number = routingNumber && routingNumber;
        payload.account_number = accountNumber && accountNumber;
        payload.account_type = accountType && accountType.value;
        payload.payment_response = res;
      }
      if (type === 'cash') {
        payload.payment_date = cashPaymentDate && new Date(cashPaymentDate).toISOString();
        payload.received_amt = cashAmount && Number(cashAmount);
        payload.response_code = '1';
        payload.transaction_id = '0';
      }

      const endPoint = `${Api.addPayment}`;

      const callback = AuthApi.postDataToServer;
      this.setState({
        isShowLoader: true,
      });
      const { data, message } = await callback(endPoint, payload);
      if (data && data.success) {
        swal('Payment successfull', '', 'success');
        this.setState(
          {
            isShowLoader: false,
            chequeAmount: null,
            chequeNumber: null,
            chequePaymentDate: '',
            cashAmount: null,
            cashPaymentDate: '',
            cardAmount: null,
            cardName: '',
            cardNumber: null,
            cardExpMonth: '',
            cardExpYear: '',
            cardCvv: '',
            cardPaymentDate: '',
            accountName: '',
            routingNumber: null,
            accountNumber: null,
            accountType: '',
          },
          () => {
            this.forceUpdate();
            this.props.callback();
          }
        );
      } else {
        this.setState({
          isShowLoader: false,
        });
        swal(message, '', 'error');
      }
    }
  };

  // handleSelect = (key) => {
  //   const { invoiceId, totalAmount, due_amount } = this.state;
  //   if (key == 'card') {
  //     this.props.history.push('/invoice-customer-pay', {
  //       invoiceId,
  //       totalAmount,
  //       due_amount,
  //     });
  //   }
  // };

  getScript = async () => {
    let AuthorizeNetSealIcon = document.getElementById('AuthorizeNetSeal');
    let clone = AuthorizeNetSealIcon.cloneNode(true);
    var newDocEle = document.createElement('div');
    if (clone) {
      newDocEle.appendChild(clone);
    }
    var doc = await document.querySelector('.modal-header #Auth');
    if (doc) {
      doc.appendChild(newDocEle);
    }
  };

  startPaymentCheck = async (type) => {
    this.setState({
      isValidate: true,
    });
    const {
      invoiceId,
      ipaddress,
      chequePaymentDate,
      chequeAmount,
      chequeNumber,
      accountName,
      routingNumber,
      accountNumber,
      accountType,
    } = this.state;
    const payload = {
      refid: invoiceId,
      id_address: ipaddress,
      payment_date:
        chequePaymentDate && new Date(chequePaymentDate).toISOString(),
      received_amt: chequeAmount && Number(chequeAmount),
      cheque_no: chequeNumber && chequeNumber,
      account_name: accountName && accountName,
      response_code: '1',
      transaction_id: '0',
      routing_number: routingNumber && routingNumber,
      account_number: accountNumber && accountNumber,
      account_type: accountType && accountType.value,
      payment_type: type,
    };

    const endPoint = `${Api.cardPayment}`;

    const callback = AuthApi.postDataToServer;
    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await callback(endPoint, payload);
    if (data) {
      this.setState({
        isShowLoader: false,
        startPaymentCheck: false,
      });
      return {success: true, res: data.data.transactionResponse};
    } else {
      this.setState({
        isShowLoader: false,
        startPaymentCheck: true,
      });
      swal(message, '', 'error');
      return {success: false, res: {}};
    }
  };

  getPercentage = async (e) => {
    const { paymentTypeList } = this.state;
    const taxPriceData =
      paymentTypeList &&
      paymentTypeList.filter((p) => {
        return p.payment_type.toLowerCase() === e;
      });

    let chequeAmount = this.props.due_amount
      ? this.props.due_amount
      : this.props.totalAmount;
    let cashAmount = this.props.due_amount
      ? this.props.due_amount
      : this.props.totalAmount;

    if (taxPriceData && taxPriceData.length > 0) {
      if (taxPriceData[0].payment_type.toLowerCase() === e) {
        let cashTax =
          cashAmount +
          parseFloat(cashAmount) * (taxPriceData[0].percentage / 100);
        let checkTax =
          chequeAmount +
          parseFloat(chequeAmount) * (taxPriceData[0].percentage / 100);
        this.setState(
          {
            chequeTaxAmount: checkTax,
            cashAmount: cashTax,
            chequeAmount: checkTax,
            cashTaxAmount: cashTax,
          },
          () => {
            this.forceUpdate();
          }
        );
      }
      this.setState({ taxPercentage: taxPriceData[0].percentage }, () => {
        this.forceUpdate();
      });
    }
  };

  render() {
    const {
      chequeAmount,
      chequePaymentDate,
      chequeNumber,
      chequeAmountErr,
      chequePaymentDateErr,
      cashAmount,
      cashPaymentDate,
      cashAmountErr,
      cashPaymentDateErr,
      touched,
      isValidateCash,
      isValidateCheque,
      due_amount,
      isShowLoader,
      totalAmount,
      invoiceId,
      paymentTypeList,
      activeTab,
      accountName,
      accountNameErr,
      routingNumber,
      routingNumberErr,
      accountNumber,
      accountNumberErr,
      accountType,
      accountTypeErr,
      accountTypeData,
      taxPercentage,
      chequeTaxAmount,
      cashTaxAmount,
    } = this.state;

    return (
      <>
        <Modal
          centered
          size="lg"
          show={this.props.showPaymentPopup}
          onHide={this.props.handleClose}
        >
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <Modal.Header>
            <Modal.Title className="text-secondary">
              <h5>Payment</h5>
            </Modal.Title>
            <div id="Auth">
              <i
                className="mdi mdi-close float-right"
                onClick={() => this.props.handleClose()}
              ></i>
            </div>
          </Modal.Header>
          <Modal.Body>
            {activeTab ? (
              <Tabs
                defaultActiveKey={activeTab}
                // activeKey={activeTab}
                onSelect={(e) => this.getPercentage(e)}
              >
                {paymentTypeList &&
                  paymentTypeList.map((i) => {
                    if (
                      i.payment_type.toLowerCase() === 'cash' &&
                      i.status === '1' &&
                      this.props.roleId !== 4
                    ) {
                      return (
                        <Tab eventKey="cash" title="CASH">
                          <form onSubmit={(e) => this.startPayment(e, 'cash')}>
                            <div className="card">
                              <div className="card-body m-t-10">
                                {due_amount && (
                                  <div className="row">
                                    <div className="col-sm-4" />
                                    <p className="col-sm-4 my-0">
                                      Balance due amount: ${due_amount}
                                    </p>
                                  </div>
                                )}
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    AMOUNT
                                  </label>
                                  <div className="col-sm-7">
                                    {cashTaxAmount} (Including{' '}
                                    {`${Number(taxPercentage)}%`} Additional
                                    charges on amount)
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    GRAND TOTAL
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-7">
                                    <input
                                      type="text"
                                      placeholder="$0"
                                      value={cashAmount}
                                      className="form-control"
                                      onChange={async (e) => {
                                        let event = e;
                                        event.target.value =
                                          customFunctions.numberMaskingDecimal(
                                            e.target.value
                                          );
                                        this.setState(
                                          {
                                            cashAmount: event.target.value,
                                          },
                                          () => {
                                            this.validateCash();
                                            this.handleTouch('cashAmount');
                                          }
                                        );
                                      }}
                                      onBlur={() => {
                                        this.validateCash();
                                        this.handleTouch('cashAmount');
                                      }}
                                    />
                                    <span id="err">
                                      {((touched &&
                                        touched.cashAmount &&
                                        cashAmountErr) ||
                                        (isValidateCash && cashAmountErr)) &&
                                        cashAmountErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    PAYMENT DATE
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="col-sm-7">
                                    <DatePicker
                                      className="form-control"
                                      placeholderText="01/01/2021"
                                      selected={cashPaymentDate}
                                      onChange={(date) => {
                                        this.setState({
                                          cashPaymentDate: date,
                                        });
                                      }}
                                      // minDate={new Date()}
                                      name="cashPaymentDate"
                                      dateFormat="MM/dd/yyyy"
                                    />
                                    <span id="err">
                                      {isValidateCash &&
                                        cashPaymentDateErr &&
                                        cashPaymentDateErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                              </div>

                              <div
                                className="row"
                                style={{ paddingBottom: '28px' }}
                              >
                                <div className="col-sm-7" />
                                <Button
                                  variant="primary"
                                  onClick={() => {}}
                                  type="submit"
                                >
                                  Submit Payment
                                </Button>
                                <Button
                                  variant="secondary"
                                  className="l-m-10 saveBtns"
                                  type="reset"
                                  onClick={() => {
                                    this.props.history.goBack();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </form>
                        </Tab>
                      );
                    }
                    if (
                      i.payment_type.toLowerCase() === 'echeck' &&
                      i.status === '1'
                    ) {
                      return (
                        <Tab eventKey="echeck" title="E-CHECK">
                          <form
                            onSubmit={(e) => this.startPayment(e, 'echeck')}
                          >
                            <div className="card">
                              <div className="card-body m-t-10">
                                {due_amount && (
                                  <div className="row">
                                    <div className="col-sm-4" />
                                    <p className="col-sm-4 my-0">
                                      Balance due amount: ${due_amount}
                                    </p>
                                  </div>
                                )}
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    AMOUNT
                                  </label>
                                  <div className="col-sm-7">
                                    {chequeTaxAmount} (Including{' '}
                                    {`${Number(taxPercentage)}%`} Additional
                                    charges on amount)
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    ABA ROUTING NUMBER
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-7">
                                    <input
                                      type="text"
                                      maxLength="9"
                                      placeholder="Enter routing number"
                                      value={routingNumber}
                                      className="form-control"
                                      onChange={async (e) => {
                                        let event = e;
                                        event.target.value =
                                          customFunctions.numberMasking(
                                            e.target.value
                                          );
                                        this.setState(
                                          {
                                            routingNumber: event.target.value,
                                          },
                                          () => {
                                            this.validateCheque();
                                            this.handleTouch('routingNumber');
                                          }
                                        );
                                      }}
                                      onBlur={() => {
                                        this.validateCheque();
                                        this.handleTouch('routingNumber');
                                      }}
                                    />
                                    <span id="err">
                                      {((touched &&
                                        touched.routingNumber &&
                                        routingNumberErr) ||
                                        (isValidateCheque &&
                                          routingNumberErr)) &&
                                        routingNumberErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    BANK ACCOUNT NUMBER
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="col-sm-7">
                                    <input
                                      type="text"
                                      maxLength="20"
                                      placeholder="Enter account number"
                                      value={accountNumber}
                                      className="form-control"
                                      onChange={async (e) => {
                                        let event = e;
                                        event.target.value =
                                          customFunctions.numberMasking(
                                            e.target.value
                                          );
                                        this.setState(
                                          {
                                            accountNumber: event.target.value,
                                          },
                                          () => {
                                            this.validateCheque();
                                            this.handleTouch('accountNumber');
                                          }
                                        );
                                      }}
                                      onBlur={() => {
                                        this.validateCheque();
                                        this.handleTouch('accountNumber');
                                      }}
                                    />
                                    <span id="err">
                                      {((touched &&
                                        touched.accountNumber &&
                                        accountNumberErr) ||
                                        (isValidateCheque &&
                                          accountNumberErr)) &&
                                        accountNumberErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    BANK ACCOUNT TYPE
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-7">
                                    <ReactSelect
                                      value={accountType}
                                      placeholder="Select account type"
                                      onChange={(e) => {
                                        if (e) {
                                          let type = {};
                                          type.label = e.label;
                                          type.value = e.value;
                                          this.setState(
                                            { accountType: type },
                                            () => {
                                              this.validateCheque();
                                              this.handleTouch('accountType');
                                            }
                                          );
                                        }
                                      }}
                                      onBlur={() => {
                                        this.validateCheque();
                                        this.handleTouch('accountType');
                                      }}
                                      // isClearable={true}
                                      options={accountTypeData}
                                    />
                                    <span id="err">
                                      {isValidateCheque &&
                                        accountTypeErr &&
                                        accountTypeErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    NAME ON THE BANK ACCOUNT
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="col-sm-7">
                                    <input
                                      type="text"
                                      placeholder="Enter account name"
                                      value={accountName}
                                      className="form-control"
                                      onChange={(e) => {
                                        this.setState(
                                          { accountName: e.target.value },
                                          () => {
                                            this.validateCheque();
                                            this.handleTouch('accountName');
                                          }
                                        );
                                      }}
                                      onBlur={() => {
                                        this.validateCheque();
                                        this.handleTouch('accountName');
                                      }}
                                    />
                                    <span id="err">
                                      {((touched &&
                                        touched.accountName &&
                                        accountNameErr) ||
                                        (isValidateCheque && accountNameErr)) &&
                                        accountNameErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    GRAND TOTAL
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-sm-7">
                                    <input
                                      type="text"
                                      placeholder="$0"
                                      value={chequeAmount}
                                      className="form-control"
                                      onChange={async (e) => {
                                        let event = e;
                                        event.target.value =
                                          customFunctions.numberMaskingDecimal(
                                            e.target.value
                                          );
                                        this.setState(
                                          {
                                            chequeAmount: event.target.value,
                                          },
                                          () => {
                                            this.validateCheque();
                                            this.handleTouch('chequeAmount');
                                          }
                                        );
                                      }}
                                      onBlur={() => {
                                        this.validateCheque();
                                        this.handleTouch('chequeAmount');
                                      }}
                                    />
                                    <span id="err">
                                      {((touched &&
                                        touched.chequeAmount &&
                                        chequeAmountErr) ||
                                        (isValidateCheque &&
                                          chequeAmountErr)) &&
                                        chequeAmountErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    CHEQUE NUMBER
                                    {/* <span className="text-danger">*</span> */}
                                  </label>

                                  <div className="col-sm-7">
                                    <input
                                      type="text"
                                      maxLength="20"
                                      placeholder="Enter cheque number"
                                      value={chequeNumber}
                                      className="form-control"
                                      onChange={async (e) => {
                                        let event = e;
                                        this.setState(
                                          {
                                            chequeNumber: event.target.value,
                                          }
                                          // () => {
                                          //   this.validateCheque();
                                          //   this.handleTouch('chequeNumber');
                                          // }
                                        );
                                      }}
                                      // onBlur={() => {
                                      //   this.validateCheque();
                                      //   this.handleTouch('chequeNumber');
                                      // }}
                                    />
                                    {/* <span id="err">
                                      {((touched &&
                                        touched.chequeNumber &&
                                        chequeNumberErr) ||
                                        (isValidateCheque && chequeNumberErr)) &&
                                        chequeNumberErr}
                                    </span> */}
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                                <div className="form-group row">
                                  <div className="col-sm-1" />
                                  <label
                                    htmlFor=""
                                    className="col-sm-3 text-secondary"
                                  >
                                    PAYMENT DATE
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="col-sm-7">
                                    <DatePicker
                                      className="form-control"
                                      placeholderText="01/01/2021"
                                      selected={chequePaymentDate}
                                      onChange={(date) => {
                                        this.setState({
                                          chequePaymentDate: date,
                                        });
                                      }}
                                      minDate={new Date()}
                                      name="chequePaymentDate"
                                      dateFormat="MM/dd/yyyy"
                                    />
                                    <span id="err">
                                      {isValidateCheque &&
                                        chequePaymentDateErr &&
                                        chequePaymentDateErr}
                                    </span>
                                  </div>
                                  <div className="col-sm-1" />
                                </div>
                              </div>
                              <div
                                className="row"
                                style={{ paddingBottom: '28px' }}
                              >
                                <div className="col-sm-7" />
                                <Button
                                  variant="primary"
                                  onClick={() => {}}
                                  type="submit"
                                >
                                  Submit Payment
                                </Button>
                                <Button
                                  variant="secondary"
                                  className="l-m-10 saveBtns"
                                  type="reset"
                                  onClick={() => {
                                    this.props.history.goBack();
                                  }}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </form>
                        </Tab>
                      );
                    }
                    if (
                      i.payment_type.toLowerCase() === 'credit' &&
                      i.status === '1'
                    ) {
                      return (
                        <Tab eventKey="credit" title="CARD">
                          {taxPercentage && (
                            <CardPayment
                              invoiceId={invoiceId}
                              taxPercentage={taxPercentage}
                              totalAmount={totalAmount}
                              due_amount={due_amount}
                              fromComponent="payment"
                              callback={this.props.callback}
                            />
                          )}
                        </Tab>
                      );
                    }
                  })}
              </Tabs>
            ) : (
              <h5 className="text-center">Payment options not available</h5>
            )}
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withRouter(InvoicePayment);
