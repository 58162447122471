import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
// import DatePickerComponent from '../../../components/DatePicker/datePicker.component';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Customer Name',
    field: 'custName',
    sort: 'disabled',
  },
  {
    label: 'Contact Person Name',
    field: 'contactName',
    sort: 'disabled',
  },
  {
    label: 'Contact',
    field: 'contact',
    sort: 'disabled',
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
  },
];

class PreMaintenanceCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      customers: [],
      rows: [],
      customerId: null,
      showDelete: false,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      filterValue: '',
      filterdata: [
        {
          value: 1,
          label: 'Active',
        },
        {
          value: 0,
          label: 'In-Active',
        },
      ],
      roleId: null,
      login_id: null,
      showFilter: false,
      startDate: '',
      endDate: '',
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Preventative Maintenance Customers');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id === 4 || userdata.role_id === 5) {
      this.props.history.push('/pre-maintenances/' + userdata.login_id, {
        customerId: userdata.login_id,
      });
    }

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.paginationObj.navSkip,
          navCurrentPage:
            this.props.location.state.paginationObj.navCurrentPage,
        },
        async () => {
          await this.getCustomers();
        }
      );
    } else {
      await this.getCustomers();
    }
  };

  getCustomers = async (currentPage) => {
    this.setState({ currentPage });
    const {
      limit,
      keyword,
      filterValue,
      startDate,
      endDate,
      City,
      Zip,
      showFilter,
      navSkip,
      navCurrentPage,
    } = this.state;
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }

    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;

    let endPoint = `${
      Api.customerUrl
    }?skip=${skip}&limit=${limit}&search=${keyword}&type=${
      filterValue ? filterValue.value : ''
    }&IsPreventiveMaintainance=1`;
    if (!filterValue) {
      endPoint = `${Api.customerUrl}?skip=${skip}&limit=${limit}&search=${keyword}&IsPreventiveMaintainance=1`;
    }
    if (showFilter) {
      endPoint += City ? `&city=${City}` : '';
      endPoint += Zip ? `&zip=${Zip}` : '';
      endPoint += startDate
        ? '&start_date=' + new Date(startDate).toISOString()
        : '';
      endPoint += endDate
        ? '&end_date=' + new Date(endDate).toISOString()
        : '';
    }

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          customers: data.data.data,
          totalCount: data.data.total_count,
          showFilter: false,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({
        showFilter: false,
      });
      swal(message, '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { customers, limit } = this.state;
    const page = npage;
    const rows = [];
    customers &&
      customers.length > 0 &&
      customers.map((customer, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          custName: customer.CustomerName || '-',
          contactName: customer.ContactName || '-',
          contact: customer.PhoneNumber1 || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-customer/${customer.ID}`, {
                    customerDetails: customer,
                    type: 'customer',
                    path: '/view-premaintenance-customers',
                    paginationObj: {
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                <i className="mdi mdi-lead-pencil" title="Edit Customer"></i>
              </a>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, customerId: customer.ID });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title="Delete Customer"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return customer;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCustomers();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getCustomers();
    });
  };

  advanceClear = () => {
    this.setState(
      {
        City: '',
        Zip: '',
        showFilter: false,
      },
      () => {
        this.getCustomers();
      }
    );
  };

  deteleCustomer = async () => {
    const { customerId, currentPage } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.customerUrl}?customer_id=${customerId}`
    );
    if (data) {
      this.setState({ showDelete: false, customerId: null }, () => {
        swal('Customer deleted', '', 'success').then(() => {
          this.getCustomers(currentPage);
        });
      });
    } else {
      alert(message);
    }
  };

  downloadCSV = async () => {
    const { keyword, filterValue } = this.state;
    let endPoint = `${
      Api.downloadCustomerCsvUrl
    }?skip=${0}&limit=${10000}&IsPreventiveMaintainance=1&search=${keyword}&type=${
      filterValue ? filterValue.value : ''
    }`;

    window.open(endPoint, '_blank');
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      filterdata,
      filterValue,
      showFilter,
      City,
      Zip,
      navSkip,
      navCurrentPage,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All Customers" />
            {showDelete && (
              <SweetAlert
                title="Are you sure, do you want to delete the customer ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deteleCustomer();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, customerId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-header">
                      <div className="left-section">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group d-flex">
                            <div className="input-wrap">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by customer name, contact person"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="buttons-wrap">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                              {/* <button
                                type="reset"
                                onClick={() => {
                                  this.setState({ showFilter: true });
                                }}
                                className="btn btn-primary waves-effect waves-light m-l-10"
                              >
                                Advance Search
                              </button> */}
                            </div>
                            <div className="select-wrap">
                              <ReactSelect
                                value={filterValue}
                                placeholder="Select"
                                onChange={(e) => {
                                  if (e) {
                                    let type = {};
                                    type.label = e.label;
                                    type.value = e.value;
                                    this.setState({ filterValue: type }, () => {
                                      this.getCustomers();
                                      this.forceUpdate();
                                    });
                                  } else {
                                    this.setState({ filterValue: '' }, () => {
                                      this.getCustomers();
                                    });
                                  }
                                }}
                                isClearable={true}
                                options={filterdata}
                              />
                            </div>
                            <div className="pl-2">
                              {totalCount > 0 && (
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={() => {
                                    this.downloadCSV();
                                  }}
                                >
                                  <i className="mdi mdi-download"></i> CSV
                                </button>
                              )}
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    {showFilter && (
                      <form onSubmit={this.onSearchSubmit}>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-sm-4">
                                  City
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      this.setState({ City: e.target.value });
                                    }}
                                    value={City}
                                  />
                                </div>
                                <div className="col-sm-4">
                                  Zip
                                  <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) => {
                                      let event = e;
                                      event.target.value =
                                        customFunctions.numberMasking(
                                          e.target.value
                                        );
                                      this.setState({
                                        Zip: event.target.value,
                                      });
                                    }}
                                    value={Zip}
                                  />
                                </div>
                                {/* <div className="col-sm-4">
                                  Expiration Date
                                  <div className="row">
                                  <div className="col-sm-6">
                                    <DatePickerComponent
                                      selected={startDate}
                                      placeholderText="Start Date"
                                      onChange={(date) =>{
                                        this.setState({ startDate: date })}
                                      }
                                      startDate={startDate}
                                      endDate={endDate}
                                      className="form-control"
                                    />
                                  </div>
                                  <div className="col-sm-6">
                                    <DatePickerComponent
                                      selected={endDate}
                                      placeholderText="End Date"
                                      disabled={!startDate}
                                      onChange={(date) =>
                                        this.setState({ endDate: date })
                                      }
                                      selectsEnd
                                      startDate={startDate}
                                      endDate={endDate}
                                      minDate={startDate}
                                      className="form-control"
                                    />
                                  </div>
                              </div>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-group row d-flex justify-content-center">
                          <div className="col-sm-3 col-md-6">
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                            >
                              Search
                            </button>
                            <button
                              type="reset"
                              onClick={() => {
                                this.advanceClear();
                              }}
                              className="btn btn-secondary waves-effect m-l-10"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getCustomers
                                )
                              : this.getCustomers
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(PreMaintenanceCustomer);
