import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import Api from '../../../../helper/api';
import AuthApi from '../../../../helper/authApi';
import customFunctions from '../../../../helper/customFunctions';

class TechnicianStatisticsTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statistics: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      startDate: '',
      endDate: '',
      filterApplied: false,
      technicianId: null,
    };
  }
  async componentDidMount() {
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'FIRST NAME',
        field: 'firstname',
        width: 150,
      },
      {
        label: 'LAST NAME',
        field: 'lastname',
        width: 150,
      },
      {
        label: 'AVG RATING',
        field: 'avgRating',
        width: 150,
      },
      {
        label: 'RATINGS COUNT',
        field: 'ratingCount',
        width: 150,
      },
      {
        label: 'APPT/RATING',
        field: 'apptRating',
        width: 150,
      },
      {
        label: 'DRIVETIME',
        field: 'drivetime',
        width: 150,
      },
      {
        label: 'WORKINGTIME',
        field: 'workingtime',
        width: 150,
      },
      {
        label: '% ON MY WAY',
        field: 'onmyway',
        width: 150,
      },
      {
        label: 'USER COMPLETION',
        field: 'userCompletion',
        width: 180,
      },
    ];
    this.setState(
      {
        columns,
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        technicianId:
          this.props &&
          this.props.match &&
          this.props.match.params &&
          this.props.match.params.technicianId,
      },
      () => {
        this.getStatistics();
        this.forceUpdate();
      }
    );
  }
  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      this.setState(
        {
          startDate: props.startDate,
          endDate: props.endDate,
          technicianId: props.technicianId,
        },
        () => {
          this.forceUpdate();
          this.getStatistics();
        }
      );
    }
  }
  getStatistics = async (currentPage) => {
    let { startDate, endDate, technicianId } = this.state;
    this.setState({ currentPage });
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.getTechStatisticsData}?start_date=${startDate}&end_date=${endDate}`;
    if (technicianId) {
      endPoint += `&tech_id=${Number(technicianId)}`;
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      let dataFormat = [];
      if (
        data &&
        data.success &&
        data.data &&
        data.data.data &&
        data.data.data.length > 0
      ) {
        dataFormat = data.data.data;
      }
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      if (dataFormat && dataFormat.length > 0) {
        this.setState(
          {
            statistics: dataFormat && dataFormat.length > 0 && dataFormat,
            totalCount: data.data.total_count,
            pagination: paginationParams,
          },
          () => {
            this.setTableData();
            this.forceUpdate();
          }
        );
      }
    }
    this.props.callback();
  };

  downloadCSVForTechStatistic = async () => {
    let { startDate, endDate, technicianId } = this.state;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.downloadCSVForTechStatistic}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}`;
    if (technicianId) {
      endPoint += `&tech_id=${technicianId}`;
    }
    window.open(endPoint, '_blank');
  };

  setTableData = () => {
    const { statistics, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    statistics &&
      statistics.length &&
      statistics.map((estimate, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          firstname: (estimate.name && estimate.name.split(' ')[0]) || '-',
          lastname: (estimate.name && estimate.name.split(' ')[1]) || '-',
          avgRating: estimate.avg_rating || '-',
          ratingCount: estimate.ratings || '-',
          apptRating: estimate.apptRating || '-',
          drivetime: estimate.drive_time || '-',
          workingtime: estimate.workingtime || '-',
          onmyway: estimate.onmyway || '-',
          userCompletion: estimate.userCompletion || '-',
        });
        return statistics;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6> {this.props.title}</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                if (this.props.csvType === 'downloadCSVForTechStatistic') {
                  this.downloadCSVForTechStatistic();
                }
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>
        {/* <Table
        columns={columns}
        rows={rows}
        showPagination
        paginationProps={{
          ...pagination,
          activePage: pagination.current_page,
          onPageChange: (pageNo) => {
            this.getStatistics(pageNo);
          },
        }}
        tableclassName="table-bordered"
        dropdownContainerclassName="mb-3"
        emptyRender={() => <p className="text-center mb-0">No record found</p>}
      /> */}
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="400px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getStatistics}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(TechnicianStatisticsTables);
