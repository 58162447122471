import React, { useEffect, useState } from 'react';
import LiveLocationMap from '../../../components/Map/liveLocation';

const LiveLocationUpdate = (props) => {
  const [technicianId, setTechnicianId] = useState(null);
  const [defaultCenter, setDefaultCenter] = useState({
    lat:
      props.location.state && props.location.state.data
        ? Number(props.location.state.data.customer_latitude)
        : 0,
    lng:
      props.location.state && props.location.state.data
        ? Number(props.location.state.data.customer_longitude)
        : 0,
  });
  const [directions, updateDirections] = useState([
    [
      {
        color: '#0056b3',
      },
      {
        color: '#0056b3',
      },
    ],
  ]);

  const [placeMarker, setPlaceMarker] = useState([]);
  const [count, forceUpdate] = useState(0);

  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.data
    ) {
      setTechnicianId(props.location.state.data.TechnicianID);
      var arr = [
        {
          color: '#0056b3',
          latitude: directions[0][0].latitude,
          longitude: directions[0][0].longitude,
        },
        {
          color: '#0056b3',
          latitude: `${props.location.state.data.customer_latitude}`,
          longitude: `${props.location.state.data.customer_longitude}`,
        },
      ];
      directions[0].length = 0;
      directions[0].push(...arr);

      placeMarker[0] = {
        type: 'customer',
        workOrder: props.location.state.data.WorkOrder,
        jobTitle: props.location.state.data.jobTitle,
        name: props.location.state.data.customerName,
        customerAddress: props.location.state.data.customer_address,
        latitude: props.location.state.data.customer_latitude,
        longitude: props.location.state.data.customer_longitude,
      };
      setPlaceMarker(placeMarker);
      updateDirections(directions);
      setDefaultCenter({
        lat: Number(directions[0][0].latitude),
        lng: Number(directions[0][0].longitude),
      });
    }
    forceUpdate(count + 1);
  }, []);

  const socketEndpoint = `wss://ce59pyiyzl.execute-api.us-east-1.amazonaws.com/production`;
  var ws = React.useRef(new WebSocket(socketEndpoint)).current;
  ws.onopen = () => {
    if (!technicianId) return;
    console.log('connected');
    setInterval(() => {
      ws.send(
        JSON.stringify({
          action: 'getlocation',
          data: { tech_id: technicianId },
        })
      );
    }, 5000);
  };
  ws.onmessage = async (e) => {
    const data = e && JSON.parse(e.data);
    directions[0][0] = {
      color: '#0056b3',
      latitude: `${data.currentlatitude}`,
      longitude: `${data.currentlongitude}`,
    };

    placeMarker[1] = {
      type: 'technician',
      name: 'Technician',
      latitude: data.currentlatitude,
      longitude: data.currentlongitude,
    };

    setPlaceMarker(placeMarker);
    updateDirections(directions);
    setDefaultCenter({
      lat: Number(data.currentlatitude),
      lng: Number(data.currentlongitude),
    });
    forceUpdate(count + 1);
  };
  ws.onclose = (e) => {
    console.log('Disconnected', e);
  };
  ws.onerror = (e) => {
    console.log('CHATS_ERR_FOUND', e);
  };

  return (
    <div>
      {directions && directions[0] && directions[0].length === 2 && (
        <LiveLocationMap
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_MAP_KEY}&v=3.exp&libraries=geometry,drawing,places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div className="map-view" style={{ height: `100vh` }} />
          }
          mapElement={<div style={{ height: `100%` }} />}
          places={directions}
          isMarkerShown
          placeMarker={placeMarker}
          defaultCenter={defaultCenter}
          center={defaultCenter}
          defaultZoom={10}
        />
      )}
    </div>
  );
};
export default LiveLocationUpdate;
