const menu = [
  {
    title: 'Dashboard',
    path: '/dashboard',
    icon: 'mdi-view-dashboard',
    submenu: [],
    roleaccess: ['dashboard'],
    key: 'dashboard',
  },
  {
    title: 'Work',
    path: '#',
    icon: 'mdi mdi-database',
    roleaccess: ['work'],
    submenu: [
      {
        title: 'Schedule',
        path: '/schedule?job_type=service',
        roleaccess: ['work-schedule'],
      },
    ],
  },
  {
    title: 'Master',
    path: '#',
    icon: 'mdi mdi-voice',
    roleaccess: ['master'],
    submenu: [
      {
        title: 'List Type',
        path: '/listtype',
        roleaccess: ['master-listtype'],
      },
      {
        title: 'List Item',
        path: '/listitem',
        roleaccess: ['master-listitem'],
      },
      {
        title: 'Complaint Category',
        path: '/complaint-category',
        roleaccess: ['master-complaintcategory'],
      },
      {
        title: 'Complaint Status',
        path: '/complaint-status',
        roleaccess: ['master-complaintstatus'],
      },
      {
        title: 'Work Order Type',
        path: '/work-order-type',
        roleaccess: ['master-workordertype'],
      },
      {
        title: 'Work Order Status',
        path: '/work-order-status',
        roleaccess: ['master-workorderstatus'],
      },
      {
        title: 'Payment Type',
        path: '/payment-type',
        roleaccess: ['master-paymenttype'],
      },
      // {
      //   title: 'Home Warranty Code',
      //   path: '/home-warrenty-code',
      //   roleaccess: ['master-homewarrantycode'],
      // },
      {
        title: 'Home Warranty Company',
        path: '/home-warranty-company',
        roleaccess: ['master-homewarrantycompany'],
      },
      {
        title: 'Email Template',
        path: '/email-templates',
        roleaccess: ['master-emailtemplate'],
      },
      {
        title: 'Vendors',
        path: '/vendors',
        roleaccess: ['master-vendors'],
      },
      {
        title: 'Parts',
        path: '/parts',
        roleaccess: ['master-parts'],
      },
      {
        title: 'Contracts',
        path: '/contracts',
        roleaccess: ['master-accesscontracts'],
      },
      {
        title: 'Employment Contract',
        path: '/employment-contracts',
        roleaccess: ['master-employmentcontract'],
      },
      {
        title: 'Modules',
        path: '/modules',
        roleaccess: ['master-modules'],
      },
      {
        title: 'Sub Modules',
        path: '/submodules',
        roleaccess: ['master-submodules'],
      },
      {
        title: 'Role Permission',
        path: '/role-permission',
        roleaccess: ['master-rolepermission'],
      },
      {
        title: 'Good Better Best Template Msg',
        path: '/quality-template-msg',
        roleaccess: ['master-accessgoodbetterbesttemplatemsg'],
      },
    ],
  },
  {
    title: 'Amazon Connect',
    path: '/amazon-connect',
    icon: 'mdi mdi-cast-connected',
    submenu: [],
    roleaccess: ['amazonconnect'],
  },
  {
    title: 'Recording',
    path: '/recording',
    icon: 'mdi mdi-record',
    submenu: [],
    roleaccess: ['recording'],
  },
  {
    title: 'Add New Record',
    path: '#',
    icon: 'mdi mdi-checkbox-multiple-blank-circle',
    roleaccess: ['addnewrecord'],
    submenu: [
      {
        title: 'Add Job',
        path: '/work-order',
        roleaccess: ['addnewrecord-addworkorder'],
      },
      {
        title: 'Add Purchase Order',
        path: '/purchase',
        roleaccess: ['addnewrecord-addpurchaseorder'],
      },
      {
        title: 'Add Customer',
        path: '/add-customer',
        roleaccess: ['addnewrecord-addcustomer'],
      },
      {
        title: 'Add Team User',
        path: '/add-technician',
        roleaccess: ['addnewrecord-addtechnician'],
      },
      {
        title: 'Add New Expense',
        path: '/add-newexpense',
        roleaccess: ['addnewrecord-addnewexpense'],
      },
      {
        title: 'Add New Complaint',
        path: '/add-complaint',
        roleaccess: ['addnewrecord-addnewcomplaint'],
      },
      {
        title: 'Add Office Admin Assistant',
        path: '/add-office-admin-assistant',
        roleaccess: ['addnewrecord-accessaddofficeadminassistant'],
      },
    ],
  },
  {
    title: 'Complaints',
    path: '/view-complaints',
    icon: 'mdi mdi-flag-variant',
    submenu: [],
    roleaccess: ['complaints'],
    countKey: 'complaintsCount',
  },
  {
    title: 'Unscheduled/New Job Offers',
    path: '/unschedule-orders',
    icon: 'mdi mdi-book-open',
    submenu: [],
    roleaccess: ['unschedulejobs'],
    countKey: 'UnscheduledOrders',
  },
  {
    title: 'Authorization Required',
    path: '/authorization-required',
    icon: 'mdi mdi-security',
    submenu: [],
    roleaccess: ['authorizationrequired'],
    countKey: 'authorizedCount',
  },
  {
    title: 'Pending Parts',
    path: '/pending-orders',
    icon: 'mdi mdi-library-books',
    submenu: [],
    roleaccess: ['pendingworkorders'],
    countKey: 'PendingParts',
  },
  {
    title: 'Pending Installation',
    path: '/pending-installation',
    icon: 'mdi mdi-information-outline',
    submenu: [],
    roleaccess: ['pendinginstallation'],
    countKey: 'WorkPending',
  },
  {
    title: 'Waiting on customer approval',
    path: '/customer-approval',
    icon: 'mdi mdi-information-outline',
    submenu: [],
    roleaccess: ['waitingoncustomerapproval'],
    countKey: 'WaitingonCustomerApproval',
  },
  {
    title: 'Preventative Maintenance',
    path: '#',
    icon: 'mdi mdi-information-outline',
    submenu: [
      {
        title: 'Customers',
        path: '/view-premaintenance-customers',
        roleaccess: ['preventativemaintenance-accesscustomers'],
      },
      // {
      //   title: 'Contracts',
      //   path: '/pre-maintenance-contract',
      //   roleaccess: ['preventativemaintenance-accesscontracts'],
      // },
      {
        title: 'Jobs',
        path: '/preventative-maintenance-list',
        roleaccess: ['preventativemaintenance-accessjobs'],
        countKey: 'preventativeMaintainance',
      },
    ],
    roleaccess: ['preventativemaintenance'],
  },
  {
    title: 'Customer Billing',
    path: '#',
    icon: 'mdi mdi-file-document',
    roleaccess: ['customerbilling'],
    submenu: [
      {
        title: 'Estimates',
        path: '/estimates',
        roleaccess: ['customerbilling-estimates'],
      },
      {
        title: 'Invoices',
        path: '/invoices',
        roleaccess: ['customerbilling-invoices'],
      },
      {
        title: 'Items',
        path: '/items',
        roleaccess: ['customerbilling-items'],
      },
      // {
      //   title: 'Contracts',
      //   path: '/contracts',
      //   roleaccess: ['customerbilling-contracts'],
      // },
      {
        title: 'Tax Rates',
        path: '/tax-rates',
        roleaccess: ['customerbilling-taxrates'],
      },
      {
        title: 'Settings',
        path: '/settings',
        roleaccess: ['customerbilling-settings'],
      },
    ],
  },
  {
    title: 'Customers',
    path: '/customer',
    icon: 'mdi mdi-account-multiple',
    submenu: [],
    roleaccess: ['customers'],
  },
  {
    title: 'Team',
    path: '/technicians',
    icon: 'mdi mdi-account-settings-variant',
    submenu: [],
    roleaccess: ['technician'],
  },
  {
    title: 'Office Admin Assistants',
    path: '/office-admin-assistants',
    icon: 'mdi mdi-account-settings-variant',
    submenu: [],
    roleaccess: ['officeadminassistant'],
  },
  {
    title: 'Roles',
    path: '/roles',
    icon: 'mdi mdi-account-network',
    submenu: [],
    roleaccess: ['roles'],
  },
  {
    title: 'Allocated Parts',
    path: '/allocated-parts',
    icon: 'mdi mdi-collage',
    submenu: [],
    roleaccess: ['allocatedparts'],
  },
  {
    title: 'Payroll',
    path: '/payroll',
    icon: 'mdi mdi-cash',
    submenu: [],
    roleaccess: ['payroll'],
  },
  {
    title: 'Pay Sheet',
    path: '/paysheet',
    icon: 'mdi mdi-file-document',
    submenu: [],
    roleaccess: ['paysheet'],
    countKey: 'approvedByAdminPaid',
  },
  {
    title: 'Advance Paycheck',
    path: '/advance-paycheck',
    icon: 'mdi mdi-file-document',
    submenu: [],
    roleaccess: ['advancepaycheck'],
    countKey: 'approvedCount',
  },
  {
    title: 'Leaves',
    path: '/leaves',
    icon: 'mdi mdi-application',
    submenu: [],
    roleaccess: ['leaves'],
    countKey: 'leavesCount',
  },
  {
    title: 'Reports',
    path: '#',
    icon: 'mdi mdi-file-chart',
    roleaccess: ['reports'],
    submenu: [
      {
        title: 'Jobs',
        path: '/report-jobs',
        roleaccess: ['reports-jobs'],
      },
      {
        title: 'Customers',
        path: '/report-customers',
        roleaccess: ['reports-customers'],
      },
      {
        title: 'Reviews',
        path: '/report-reviews',
        roleaccess: ['reports-reviews'],
      },
      {
        title: 'Technician',
        path: '/report-technicians',
        roleaccess: ['reports-technicians'],
      },
      {
        title: 'Billing',
        path: '/report-billing',
        roleaccess: ['reports-billing'],
      },
      {
        title: 'Travel Report',
        path: '/report-travel',
        roleaccess: ['reports-accesstravelreport'],
      },
      {
        title: 'AHS Report Card',
        path: '/ahs-report',
        roleaccess: ['reports-accessahsreportcard'],
      },
      {
        title: 'Dispatcher Reports',
        path: '/dispatcher-reports',
        roleaccess: ['dispatcherreports-accessdispatcherreports'],
      },
    ],
  },
  {
    title: 'Profit Loss',
    path: '/profit-loss',
    icon: 'mdi mdi-currency-usd',
    submenu: [],
    roleaccess: ['profitloss'],
  },
  {
    title: 'AHS Billing',
    path: '/ahs-workorders',
    icon: 'mdi mdi-book-open',
    submenu: [],
    roleaccess: ['ahsworkorders'],
    countKey: 'ScheduleOrders',
  },
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    icon: 'mdi mdi-file-document',
    submenu: [],
    roleaccess: ['ahsworkorders'],
    countKey: 'ScheduleOrders',
  },
  {
    title: 'Work Orders',
    path: '/orderDetails',
    icon: 'mdi mdi-book-open',
    submenu: [],
    roleaccess: ['workorders'],
  },
  // {
  //   title: 'Tech Pay',
  //   path: '/techpay',
  //   icon: 'mdi mdi-file-document-box',
  //   submenu: [],
  //   roleaccess: ['techpay'],
  // },
  // {
  //   title: 'Quit Application',
  //   path: '/login',
  //   icon: 'mdi mdi-close-circle',
  //   submenu: [],
  //   roleaccess: ['quitapplication'],
  // },
];
export default menu;
