import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddWorkOrderType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      workOrderType: '',
      workOrderTypeId: '',
      status: '',
      workOrderTypeErr: '',
      title: '',
      background: '',
      backgroundErr: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-workordertype',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    this.setState(
      {
        title: `Add Work Order Type`,
      },
      () => customFunctions.setTitle(this.state.title)
    );

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getWorkOrderType();
      this.setState(
        {
          title: `Edit Work Order Type`,
        },
        () => customFunctions.setTitle(this.state.title)
      );
    }
  };

  getWorkOrderType = async () => {
    const workOrderTypeData = this.props.location.state.detail;
    this.setState({
      workOrderTypeId: workOrderTypeData.id,
      workOrderType: workOrderTypeData.order_type,
      status: workOrderTypeData.status,
      background: workOrderTypeData.color_code,
    });
  };

  addWorkOrderType = async () => {
    const { workOrderType, workOrderTypeId, status } = this.state;
    const postData = {
      order_type: workOrderType,
      type: 1,
    };
    if (workOrderTypeId) {
      postData.order_type_id = parseInt(workOrderTypeId);
      postData.status = status;
    }
    const endPoint = `${Api.AddworkOrderTypeUrl}`;
    const callback = workOrderTypeId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);

    if (data && data.success !== false) {
      var $this = this;
      if (workOrderTypeId) {
        swal('Work order type updated successfully', '', 'success').then(() => {
          $this.props.history.push('/work-order-type', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('Work order type added successfully', '', 'success').then(() => {
          $this.props.history.push('/work-order-type');
        });
      }
    } else {
      swal(message, '', 'error');
    }
  };

  handleChangeComplete = (color) => {
    this.setState({ background: color.hex });
  };

  validateForm = (e) => {
    e.preventDefault();
    const { workOrderType } = this.state;
    let workOrderTypeErr = '',
      backgroundErr = '',
      isValid = true;

    if (workOrderType.trim() === '') {
      workOrderTypeErr = 'Work order type is required';
      isValid = false;
    }

    this.setState({ workOrderTypeErr, backgroundErr });
    if (isValid) {
      this.addWorkOrderType();
    }
  };

  render() {
    const { title, workOrderType, workOrderTypeErr } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Work Order Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            value={workOrderType}
                            placeholder="work order type"
                            onChange={(e) => {
                              this.setState({ workOrderType: e.target.value });
                            }}
                          />
                          <span id="err">{workOrderTypeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/work-order-type', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddWorkOrderType);
