import React, { Component } from 'react';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import LocationMap from '../../../components/LocationMap/index';
import Geocode from 'react-geocode';

class AddCustomerPopup extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.state = {
      custmrnme: '',
      cntctprsnme: '',
      // jobtitle: '',
      email: '',
      password: '',
      custmrnmerr: '',
      // cntctprsnmerr: '',
      // jobtitlerr: '',
      emailerr: '',
      passwordErr: '',
      PhoneOne: '',
      PhoneOneErr: '',
      numberOne: '',
      numberOneErr: '',
      business_address1: '',
      business_address2: '',
      business_city: '',
      business_state: '',
      business_zip: '',
      business_country: '',
      phoneType1: [
        {
          id: '1',
          name: 'Main',
        },
        {
          id: '2',
          name: 'Office',
        },
        {
          id: '3',
          name: 'Home',
        },
        {
          id: '4',
          name: 'Mobile',
        },
        {
          id: '5',
          name: 'Fax',
        },
      ],
      selectedLocation: '',
      latitude: '',
      longitude: '',
      showPass: false,
    };
  }

  validateForm = (e) => {
    e.preventDefault();
    const {
      custmrnme,
      email,
      password,
      PhoneOne,
      numberOne,
      business_address1,
      business_city,
      business_state,
      business_zip,
      latitude,
      longitude,
    } = this.state;

    let custmrnmerr = '',
      // cntctprsnmerr = '',
      numberOneErr = '',
      // jobtitlerr = '',
      emailerr = '',
      passwordErr = '',
      PhoneOneErr = '',
      business_address1Err = '',
      business_address2Err = '',
      business_cityErr = '',
      business_stateErr = '',
      business_zipErr = '',
      business_countryErr = '',
      locationErr = '';

    let isValid = true;

    if (
      latitude === '' ||
      longitude === '' ||
      latitude === null ||
      longitude == null
    ) {
      locationErr = 'Location is required';
      isValid = false;
    }

    if (!custmrnme || custmrnme.trim() === '') {
      custmrnmerr = 'Customer name is required';
      isValid = false;
    }
    // if (!cntctprsnme || cntctprsnme.trim() === '') {
    //   cntctprsnmerr = 'Person name is required';
    //   isValid = false;
    // }
    if (email.trim() !== '') {
      if (!customFunctions.validateEmail(email)) {
        emailerr = 'Invalid email';
        isValid = false;
      }
    }
    if (!email || email.trim() === '') {
      emailerr = 'Email is required';
      isValid = false;
    }
    if (!password || password.trim() === '') {
      passwordErr = 'Password is required';
      isValid = false;
    }
    if (!PhoneOne || PhoneOne.trim() === '') {
      PhoneOneErr = 'Please select phone type';
      isValid = false;
    }

    if (!numberOne || numberOne.trim() === '') {
      numberOneErr = 'Please enter mobile number';
      isValid = false;
    }

    if (!business_address1 || business_address1.trim() === '') {
      business_address1Err = 'Address 1 is required';
      isValid = false;
    }

    if (!business_city || business_city.trim() === '') {
      business_cityErr = 'City is required';
      isValid = false;
    }
    if (!business_state || business_state.trim() === '') {
      business_stateErr = 'State is required';
    }
    if (!business_zip || business_zip.trim() === '') {
      business_zipErr = 'Zip is required';
      isValid = false;
    }
    if (business_zip && !customFunctions.validateZipCode(business_zip)) {
      business_zipErr = 'Invalid zip';
      isValid = false;
    }
    this.setState({
      custmrnmerr,
      // cntctprsnmerr,
      // jobtitlerr,
      emailerr,
      passwordErr,
      PhoneOneErr,
      numberOneErr,
      business_address1Err,
      business_address2Err,
      business_cityErr,
      business_stateErr,
      business_countryErr,
      business_zipErr,
      locationErr,
    });
    if (isValid) {
      this.addCustomer();
    }
  };

  addCustomer = async (e) => {
    const {
      custmrnme,
      cntctprsnme,
      // jobtitle,
      email,
      password,
      PhoneOne,
      numberOne,
      business_address1,
      business_address2,
      business_city,
      business_state,
      business_zip,
      business_country,
      latitude,
      longitude,
    } = this.state;

    const { getCustomerCallback } = this.props;

    const postData = {
      customer_name: custmrnme,
      contact_person: cntctprsnme,
      // job_title: jobtitle,
      email: email,
      password: password,
      type_1: PhoneOne,
      mobile_1: String(numberOne),
      business_address1: business_address1,
      business_address2: business_address2,
      business_city: business_city,
      business_state: business_state,
      business_country: business_country,
      business_zip: business_zip,
      latitude,
      longitude,
    };

    const callback = AuthApi.postDataToServer;
    const endPoint = Api.customerUrl;
    const { data } = await callback(endPoint, postData);
    if (data) {
      swal('Customer added successfully', '', 'success');
      if (getCustomerCallback) getCustomerCallback();
      await this.resetData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
    }
  };

  getCustomerLIst = async () => {
    const endPoint = `${Api.customerUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        customerNameData: data.data.data,
      });
    }
  };

  resetData = () => {
    this.setState({
      custmrnme: '',
      cntctprsnme: '',
      // jobtitle: '',
      email: '',
      PhoneOne: '',
      numberOne: '',
      business_address1: '',
      business_address2: '',
      business_zip: '',
      business_country: '',
      business_state: '',
      business_city: '',
      business_countryErr: '',
      business_zipErr: '',
      business_address1Err: '',
      business_address2Err: '',
      business_cityErr: '',
      business_stateErr: '',
      emailerr: '',
      passwordErr: '',
      selectedLocation: '',
      password: '',
    });
  };

  onMapClick = (e, marker, event) => {
    const selectedLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    this.setState({
      latitude: event.latLng.lat(),
      longitude: event.latLng.lng(),
      selectedLocation,
    });
  };

  getLatLogFromAddress = async () => {
    const { business_address1, business_city, business_state, business_zip } =
      this.state;
    let location =
      business_address1 +
      ',' +
      business_city +
      ',' +
      business_state +
      ',' +
      business_zip;
    if (!location) return '';
    var $this = this;
    Geocode?.fromAddress(location).then(
      (response) => {
        $this.setState({
          selectedLocation: response.results[0].geometry.location,
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  render() {
    const {
      custmrnme,
      cntctprsnme,
      // jobtitle,
      email,
      password,
      custmrnmerr,
      // cntctprsnmerr,
      // jobtitlerr,
      emailerr,
      PhoneOne,
      PhoneOneErr,
      phoneType1,
      numberOne,
      numberOneErr,
      business_address1,
      business_address2,
      business_city,
      business_state,
      business_zip,
      business_address1Err,
      business_cityErr,
      business_stateErr,
      business_zipErr,
      passwordErr,
      selectedLocation,
      locationErr,
      showPass,
    } = this.state;

    return (
      <div
        className="modal fade"
        id="customer-popup"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        ref={(modal) => {
          this.modalRef = modal;
        }}
      >
        <div
          className="modal-dialog modal-xl modal-dialog-centered"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Add Customer
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={this.resetData}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                <form onSubmit={this.validateForm} autoComplete="off">
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Customer Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={custmrnme}
                        type="text"
                        placeholder="John"
                        id="example-text-input"
                        onChange={(e) => {
                          const re = /^[a-zA-Z ]*$/;
                          if (
                            e.target.value === '' ||
                            re.test(e.target.value)
                          ) {
                            this.setState({ custmrnme: e.target.value });
                          }
                        }}
                      />
                      <span id="err">{custmrnmerr}</span>
                    </div>
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Contact Person Name
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={cntctprsnme}
                        type="text"
                        placeholder="Doe"
                        id="example-search-input"
                        onChange={(e) => {
                          this.setState({ cntctprsnme: e.target.value });
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Email <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={email}
                        type="text"
                        autoComplete="new-email"
                        placeholder="test@gmail.com"
                        id="example-search-input"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                      <span id="err">{emailerr}</span>
                    </div>
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Password <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={password}
                        type={`${showPass ? 'text' : 'password'}`}
                        placeholder="Password"
                        autoComplete="new-password"
                        id="example-search-input"
                        onChange={(e) => {
                          this.setState({ password: e.target.value });
                        }}
                      />
                      <div
                        className="password__toggle"
                        onClick={() => this.setState({ showPass: !showPass })}
                      >
                        <i
                          className={`${
                            showPass ? 'active' : 'inactive'
                          } icon icon--md icon--eye`}
                        ></i>
                        <i
                          className={`${
                            !showPass ? 'active' : 'inactive'
                          } icon icon--md icon--eye-line-through`}
                        ></i>
                      </div>
                      <span id="err">{passwordErr}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Address 1 <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={business_address1}
                        type="text"
                        placeholder="Address 1"
                        id="example-search-input"
                        onChange={(e) => {
                          this.setState(
                            { business_address1: e.target.value },
                            () => {
                              this.getLatLogFromAddress();
                            }
                          );
                        }}
                      />
                      <span id="err">{business_address1Err}</span>
                    </div>
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Address 2
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={business_address2}
                        type="text"
                        placeholder="Address 2"
                        id="example-text-input"
                        onChange={(e) => {
                          this.setState(
                            { business_address2: e.target.value },
                            () => {
                              this.getLatLogFromAddress();
                            }
                          );
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <div className="row">
                        <div className="col-5">
                          <select
                            className="form-control"
                            value={PhoneOne}
                            onChange={(e) => {
                              this.setState({ PhoneOne: e.target.value });
                            }}
                          >
                            <option value="">Select</option>
                            {phoneType1.map((name, id) => (
                              <option
                                key={id}
                                value={name.name}
                              >{`${name.name}`}</option>
                            ))}
                          </select>
                          <span id="err">{PhoneOneErr}</span>
                        </div>
                        <div className="col-sm-7">
                          <input
                            className="form-control"
                            value={numberOne}
                            type="text"
                            placeholder="822-456-102-1"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({
                                numberOne: customFunctions.getMobile(
                                  e.target.value
                                ),
                              });
                            }}
                            maxLength="13"
                          />
                          <span id="err">{numberOneErr}</span>
                        </div>
                      </div>
                    </div>
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      State <span className="text-danger">*</span>{' '}
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={business_state}
                        type="text"
                        placeholder="State"
                        id="example-text-input"
                        onChange={(e) => {
                          this.setState(
                            { business_state: e.target.value },
                            () => {
                              this.getLatLogFromAddress();
                            }
                          );
                        }}
                      />
                      <span id="err">{business_stateErr}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      City <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={business_city}
                        type="text"
                        placeholder="City"
                        id="example-search-input"
                        onChange={(e) => {
                          this.setState(
                            { business_city: e.target.value },
                            () => {
                              this.getLatLogFromAddress();
                            }
                          );
                        }}
                      />
                      <span id="err">{business_cityErr}</span>
                    </div>
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Zip <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <input
                        className="form-control"
                        value={business_zip}
                        type="text"
                        maxLength="5"
                        placeholder="Zip"
                        id="example-search-input"
                        onChange={(e) => {
                          let event = e;
                          event.target.value = customFunctions.numberMasking(
                            e.target.value
                          );
                          this.setState(
                            { business_zip: event.target.value },
                            () => {
                              this.getLatLogFromAddress();
                            }
                          );
                        }}
                      />
                      <span id="err">{business_zipErr}</span>
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-2 col-form-label"
                    >
                      Select Location<span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-4">
                      <LocationMap
                        location={selectedLocation}
                        onMapClick={this.onMapClick}
                        popupLocation={true}
                        callback={(loc) => {
                          let selectedLocation = {
                            lat: loc.lat,
                            lng: loc.lng,
                          };
                          this.setState({
                            latitude: loc.lat,
                            longitude: loc.lng,
                            selectedLocation,
                          });
                        }}
                      />
                      <br />
                      <span id="err">{locationErr}</span>
                    </div>
                  </div>

                  <div className="form-group row d-flex justify-content-center">
                    <div className="col-sm-4 col-md-6">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        data-dismiss="modal"
                        onClick={this.resetData}
                        className="btn btn-secondary waves-effect m-l-10"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddCustomerPopup;
