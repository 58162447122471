// import listPlugin from '@fullcalendar/daygrid';
// import timeLInePlugin from '@fullcalendar/timegrid';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import React, { forwardRef } from 'react';

const CalendarTimeLine = forwardRef((props, ref) => {
  return (
    <div className="wrapper-timeline">
      <FullCalendar
        editable={props.editable}
        ref={ref}
        plugins={[resourceTimelinePlugin, interactionPlugin]}
        {...props}
      />
    </div>
  );
});

export default CalendarTimeLine;
