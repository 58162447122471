import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import AddRoleComponent from './addRole.component';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Role',
    field: 'roleName',
    sort: 'disabled',
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
  },
];

class RoleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      roles: [],
      rows: [],
      roleId: null,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      toggle: false,
      type: '',
      title: '',
      isWriteAllowed: false,
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'roles-accessroles'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'roles-accessroles',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    this.setState({ title: `All Roles` }, () =>
      customFunctions.setTitle(this.state.title)
    );
    await this.getRolesData();
  };

  getRolesData = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.getRoles}?skip=${skip}&limit=${limit}&search=${keyword}&`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          isShowLoader: false,
          roles: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  setTableData = () => {
    const { roles, limit, currentPage, isWriteAllowed } = this.state;
    const page = currentPage || 1;
    const rows = [];
    roles &&
      roles.length > 0 &&
      roles.map((role, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          roleName: role.role ? role.role : '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({
                    toggle: true,
                    roleId: role.id,
                    type: 'Edit Role',
                  });
                }}
              >
                <i className="mdi mdi-lead-pencil" title="Edit Role"></i>
              </a>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, roleId: role.id });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title="Delete Role"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return role;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteRole = async () => {
    const { roleId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getRoles}?role=${roleId}`
    );
    if (data) {
      this.setState({ showDelete: false, roleId: null }, () => {
        this.getRolesData();
      });
      swal('Role deleted!', '', 'success');
      this.setState({ toggle: false });
    } else {
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getRolesData();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getRolesData();
    });
  };

  changeToggle = (item) => {
    this.setState({ toggle: item, title: this.state.title });
    this.forceUpdate();
    customFunctions.setTitle(this.state.title);
    this.getRolesData();
  };

  render() {
    const {
      keyword,
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      title,
      toggle,
      isWriteAllowed,
      isShowLoader,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <Row>
          <Col>
            {toggle && (
              <AddRoleComponent
                data={{
                  id: this.state.roleId,
                  changeToggle: this.changeToggle,
                  type: this.state.type,
                }}
              />
            )}
          </Col>

          <Col className={toggle ? 'col-md-7' : 'col-md-12'}>
            <div className="page-content-wrapper">
              <div className="container-fluid">
                {!toggle && <HelmetComponent title={title} />}
                {showDelete && (
                  <div className="sweetalert">
                    <SweetAlert
                      title="Are you sure, do you want to delete the role ?"
                      error
                      showCancel
                      confirmBtnBsStyle="success"
                      cancelBtnBsStyle="danger"
                      onConfirm={() => {
                        this.deleteRole();
                      }}
                      onCancel={() => {
                        this.setState({ showDelete: false, roleId: null });
                      }}
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  </div>
                )}
                <div className="row">
                  <div className="col-12">
                    <div className="card m-b-20">
                      <div className="card-body">
                        <div className="table-header">
                          <div className="left-section">
                            <form onSubmit={this.onSearchSubmit}>
                              <div className="form-group d-flex">
                                <div className="input-wrap">
                                  <input
                                    className="form-control"
                                    value={keyword}
                                    type="text"
                                    placeholder="Search by role"
                                    id="example-text-input"
                                    onChange={(e) => {
                                      this.setState({
                                        keyword: e.target.value,
                                      });
                                    }}
                                    required={true}
                                  />
                                </div>
                                <div className="buttons-wrap">
                                  <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light"
                                  >
                                    Search
                                  </button>
                                  <button
                                    type="reset"
                                    onClick={() => {
                                      this.clearSearch();
                                    }}
                                    className="btn btn-secondary waves-effect m-l-10"
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                          {isWriteAllowed && (
                            <div className="right-section">
                              <div className="right-actions">
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={
                                    (async () => {
                                      this.props.history.push('/add-role');
                                    },
                                    () => {
                                      this.setState({
                                        toggle: true,
                                        type: 'Add Role',
                                        roleId: null,
                                      });
                                    })
                                  }
                                >
                                  Add Role
                                </button>
                              </div>
                            </div>
                          )}
                        </div>
                        <MDBDataTable
                          noBottomColumns={true}
                          paging={false}
                          bordered
                          hover
                          data={{ columns, rows }}
                          searching={false}
                          noRecordsFoundLabel="No records found"
                        />
                        {totalCount > 10 && (
                          <div className="pagination">
                            <Pagination
                              totalCount={totalCount}
                              currentPage={currentPage}
                              limit={limit}
                              getData={this.getRolesData}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </AUX>
    );
  }
}
export default withRouter(RoleComponent);
