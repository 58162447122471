import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Name',
    field: 'name',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Email',
    field: 'email',
    sort: 'disabled',
    // width: 270
  },
  {
    label: 'Mobile',
    field: 'mobile',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Registration date',
    field: 'regdate',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    // width: 200
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
  },
];

class Customers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customers: [],
      rows: [],
      customerId: null,
      showDelete: false,
      keyword: '',
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Customers');
    this.getCustomers(1);
  };

  getCustomers = async (currentPage) => {
    const { keyword, limit } = this.state;
    const pageNo = currentPage || 1;
    const endPoint =
      keyword !== ''
        ? `${Api.searchCustomer}`
        : `${Api.getCustomers}/${pageNo}/${limit}`;
    const searchPayload = {
      searchfield: keyword,
      skip: 1,
      limit: limit,
    };
    const callback =
      keyword !== '' ? AuthApi.postDataToServer : AuthApi.getDataFromServer;
    const { data, message } = await callback(endPoint, searchPayload);
    if (data) {
      this.setState(
        {
          customers: data.data,
          totalCount: data.totalcount,
          currentPage: pageNo,
        },
        () => {
          this.setTableData();
        }
      );
    } else {
      NotificationManager.error(message);
    }
  };

  changeCustomerStatus = async (customer) => {
    const { currentPage } = this.state;
    const payload = {
      customerid: customer.customerid,
      userstatus: customer.isactive ? 0 : 1,
    };

    const { data, message } = await AuthApi.postDataToServer(
      Api.changeCustomerStatus,
      payload
    );
    if (data && data.status !== 'Fail') {
      NotificationManager.success('Customer status changed');
      this.getCustomers(currentPage);
    } else {
      NotificationManager.error(
        (data && data.message) || message || 'Please try again later'
      );
    }
  };

  setTableData = () => {
    const { customers } = this.state;
    const rows = [];
    customers.map((customer, i) => {
      rows.push({
        name: `${customer.first_name} ${customer.last_name}`,
        email: `${customer.email}`,
        mobile: `${customer.mobile}`,
        regdate: '-',
        status: (
          <React.Fragment>
            <input
              type="checkbox"
              id={`switch${customer.customerid}`}
              switch="info"
              checked={customer.isactive ? 'checked' : ''}
              onChange={() => {
                this.changeCustomerStatus(customer);
              }}
            />
            <label
              htmlFor={`switch${customer.customerid}`}
              data-on-label=""
              data-off-label=""
            ></label>
          </React.Fragment>
        ),
        actions: (
          <React.Fragment>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(
                  `/edit-customer/${customer.customerid}`
                );
              }}
            >
              <i className="mdi mdi-lead-pencil" title="Edit driver"></i>
            </a>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(
                  `/customers/address/${customer.customerid}`
                );
              }}
            >
              <i className="mdi mdi-note-text" title="Address list"></i>
            </a>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(`/users-orders/${customer.id}`);
              }}
            >
              <i className="mdi mdi-calendar-text" title="Orders"></i>
            </a>
            <a
              href="/"
              className=""
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({
                  showDelete: true,
                  customerId: customer.customerid,
                });
              }}
            >
              <i
                className="mdi mdi-delete text-danger"
                title="Delete customer"
              ></i>
            </a>
          </React.Fragment>
        ),
      });
      return customer;
    });
    this.setState({ showLoader: false, rows });
    this.forceUpdate();
  };

  deteleCustomer = async () => {
    const { customerId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.deleteCustomer}/${customerId}`
    );
    if (data) {
      this.setState({ showDelete: false, customerId: null }, () => {
        this.getCustomers(1);
      });
      NotificationManager.success('Customer deleted');
    } else {
      NotificationManager.error(message);
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCustomers(1);
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getCustomers(1);
    });
  };

  render() {
    const { rows, showDelete, keyword, limit, totalCount, currentPage } =
      this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, you want to delete?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deteleCustomer();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, customerId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.onSearchSubmit}>
                      <div className="form-group row">
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={keyword}
                            type="text"
                            placeholder="Search here"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({ keyword: e.target.value });
                            }}
                            required={true}
                          />
                        </div>
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Search
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.clearSearch();
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getCustomers}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(Customers);
