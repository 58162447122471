import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import swal from 'sweetalert';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
import SweetAlert from 'react-bootstrap-sweetalert';
import { setHours, setMinutes } from 'date-fns';

export const EventPopup = ({ showEventPopup, popupClose, ...props }) => {
  const [type, setType] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setstartDate] = useState(new Date());
  const [endDate, setendDate] = useState(null);
  const [showDelete, setshowDelete] = useState(false);
  const [showEventPop, setshowEventPop] = useState(showEventPopup);
  const [titleError, setTitleError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [eventStartError, setEventStartError] = useState('');
  const [eventEndError, setEventEndError] = useState('');
  const [noteError, setnoteError] = useState('');
  const [canDisable, setDisable] = useState('');

  const onEventSubmit = async () => {
    const { submitType, eventDetail, callbackfunction, roleId, login_id } =
      props;
    if (title === '') return false;
    const postData = {
      title: title,
      description: description,
      rtype: type,
      tech_id: roleId === 5 || roleId === 6 ? login_id : null,
      cust_id: roleId === 4 ? login_id : null,
    };
    if (submitType !== 'add') {
      postData.date =
        type === 'event'
          ? new Date(startDate).toISOString()
          : new Date(date).toISOString();
      postData.eventid = eventDetail.eventid;
      postData.start_date =
        type === 'event'
          ? new Date(startDate).toISOString()
          : new Date(eventDetail.start_date).toISOString();
      postData.end_date =
        type === 'event'
          ? new Date(endDate).toISOString()
          : new Date(eventDetail.end_date).toISOString();
    } else {
      postData.date =
        type === 'event'
          ? new Date(startDate).toISOString()
          : new Date(date).toISOString();
      postData.start_date =
        type === 'event' ? new Date(startDate).toISOString() : '';
      postData.end_date =
        type === 'event' ? new Date(endDate).toISOString() : '';
    }
    const endPoint = `${Api.eventsDataUrl}`;
    const callback =
      submitType !== 'add' ? AuthApi.putDataToServer : AuthApi.postDataToServer;

    const { data } = await callback(endPoint, postData);
    if (data) {
      swal(
        `${type === 'event' ? 'Event' : 'Note'} ${
          submitType !== 'add' ? 'updated' : 'added'
        } succcessfully!`,
        '',
        'success'
      );
      if (callbackfunction) {
        callbackfunction();
        setstartDate('');
        setendDate('');
      }
      popupClose();
    }
  };

  const onEventDelete = async () => {
    const { eventDetail, callbackfunction } = props;
    const endPoint = `${Api.eventsDataUrl}?eventid=${eventDetail.eventid}`;
    const callback = AuthApi.deleteDataFromServer;

    const { data } = await callback(endPoint);
    if (data) {
      if (callbackfunction) {
        callbackfunction();
      }
      setshowDelete(false);
      popupClose();
      swal(`${eventDetail.rtype} deleted successfully`, '', 'success');
    }
  };

  useEffect(() => {
    if (
      props.submitType !== 'add' &&
      props.eventDetail &&
      props.eventDetail.rtype
    ) {
      setType(props.eventDetail.rtype);
      setTitle(props.title);
      setDescription(props.eventDetail.description);
      let dt =
        props.notesData &&
        props.notesData.data.filter(
          (i) => i.eventid == props.eventDetail.eventid
        )[0];
      setDate(dt.date);
      if (props.eventDetail.start_date)
        setstartDate(new Date(props.eventDetail.start_date));
      if (props.eventDetail.end_date)
        setendDate(new Date(props.eventDetail.end_date));
      setDisable(props.canDisable);
    } else {
      setType('');
      setTitle('');
      // setendDate('');
      setDescription('');
      if (props && props.eventObj && props.eventObj.date) {
        setDate(props.eventObj.date.toISOString());
        if (
          new Date(props.eventObj.date).toLocaleDateString() ===
          new Date().toLocaleDateString()
        ) {
          setstartDate(new Date().toISOString());
          setendDate(new Date().toISOString());
        } else {
          setstartDate(props.eventObj.date.toISOString());
          setendDate(props.eventObj.date.toISOString());
        }
      }
      setDisable(props.canDisable);
    }
    setshowEventPop(showEventPopup)
  }, [showEventPopup]);

  const validate = (e) => {
    e.preventDefault();
    let titleError = '';
    let typeError = '';
    let eventStartError = '';
    let eventEndError = '';
    let noteError = '';
    let isValid = true;

    if (!title || (title && title.trim() === '')) {
      titleError = 'title is required';
      isValid = false;
    }
    if (!type) {
      typeError = 'type is required';
      isValid = false;
    }
    if (!startDate && type === 'event') {
      eventStartError = 'start date is required';
      isValid = false;
    }
    if (!endDate && type === 'event') {
      eventEndError = 'end date  is required';
      isValid = false;
    }
    if (
      !description ||
      (description && description.trim() === '' && type === 'note')
    ) {
      noteError = 'note  is required';
      isValid = false;
    }
    setTitleError(titleError);
    setTypeError(typeError);
    setEventStartError(eventStartError);
    setEventEndError(eventEndError);
    setnoteError(noteError);

    if (isValid) {
      onEventSubmit();
    }
  };

  return (
    <div>
      {showDelete && (
        <SweetAlert
          title={`Are you sure, do you want to delete the ${
            props.eventDetail && props.eventDetail.rtype
          } ?`}
          error
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            onEventDelete();
          }}
          onCancel={() => {
            setshowDelete(false);
            setshowEventPop(true);
          }}
        >
          You won't be able to revert this!
        </SweetAlert>
      )}
      <Modal
        className={'filter-popup'}
        size="lg"
        centered
        onHide={popupClose}
        show={showEventPop}
      >
        <div className="add-task-popup schedule-filter-popup">
          <Modal.Header className="col-12">Add Event or Note</Modal.Header>
          <Modal.Body>
            <div className="row pb-2">
              <div className="col-sm-6">
                Select Type<span className="text-danger">*</span>
                <select
                  className="form-control"
                  value={type}
                  disabled={props.submitType !== 'add'}
                  onChange={(e) => {
                    setType(e.target.value);
                  }}
                >
                  <option name="type" value="">
                    Select
                  </option>
                  <option name="type" value="event">
                    Event
                  </option>
                  <option name="type" value="note">
                    Note
                  </option>
                </select>
                <span id="err">{typeError}</span>
              </div>
              <div className="col-sm-6">
                Title<span className="text-danger">*</span>
                <input
                  className="form-control"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  disabled={canDisable}
                />
                <span id="err">{titleError}</span>
              </div>
            </div>
            {type === 'event' && (
              <div className="row pb-2">
                <div className="col-sm-6 event-date">
                  Event Start Time<span className="text-danger">*</span>
                  <DatePickerComponent
                    selected={startDate ? new Date(startDate) : null}
                    placeholderText="Start Time"
                    onChange={(date) => {
                      setendDate(date);
                      setstartDate(date);
                      if (
                        new Date(date).toLocaleDateString() ===
                          new Date().toLocaleDateString() &&
                        Math.round(new Date().getTime() / 1000) >
                          Math.round(new Date(date).getTime() / 1000)
                      ) {
                        setstartDate(new Date());
                        setendDate(new Date());
                      }
                    }}
                    // dayClassName={(date) =>
                    //   date.getTime() > new Date().getTime()
                    //     ? 'disabled-date'
                    //     : undefined
                    // }
                    startDate={new Date(startDate)}
                    minDate={new Date()}
                    endDate={new Date(endDate)}
                    timeIntervals={5}
                    showTimeSelect
                    minTime={
                      new Date(startDate).toLocaleDateString() ===
                      new Date().toLocaleDateString()
                        ? Math.round(new Date(startDate).getTime() / 1000) >
                          Math.round(new Date().getTime() / 1000)
                          ? setMinutes(new Date(), new Date().getMinutes())
                          : setMinutes(
                              new Date(startDate),
                              new Date(startDate).getMinutes()
                            )
                        : setMinutes(
                            new Date(startDate),
                            new Date(startDate).setHours(0, 0, 0, 0)
                          )
                    }
                    maxTime={setHours(setMinutes(new Date(), 59), 23)}
                    disabled={canDisable}
                    dateFormat="MM/dd/yyyy hh:mm:ss a"
                    className="form-control filter-date"
                  />
                  <span id="err">{eventStartError}</span>
                </div>
                <div className="col-sm-6 event-date">
                  Event End Time<span className="text-danger">*</span>
                  <DatePickerComponent
                    selected={endDate ? new Date(endDate) : null}
                    placeholderText="End Time"
                    onChange={(date) => {
                      setendDate(date);
                      if (
                        new Date(date).toLocaleDateString() ===
                          new Date(startDate).toLocaleDateString() &&
                        Math.round(new Date(startDate).getTime() / 1000) >
                          Math.round(new Date(date).getTime() / 1000)
                      ) {
                        setendDate(new Date());
                      }
                    }}
                    selectsEnd
                    startDate={new Date(startDate)}
                    endDate={new Date(endDate)}
                    minDate={new Date(startDate)}
                    showTimeSelect
                    timeIntervals={5}
                    minTime={
                      new Date(startDate).toLocaleDateString() ===
                      new Date(endDate).toLocaleDateString()
                        ? setMinutes(
                            new Date(startDate),
                            new Date(startDate).getMinutes()
                          )
                        : Math.round(new Date(startDate).getTime() / 1000) >
                          Math.round(new Date(endDate).getTime() / 1000)
                        ? setMinutes(
                            new Date(startDate),
                            new Date(startDate).getMinutes()
                          )
                        : setMinutes(
                            new Date(startDate),
                            new Date(startDate).setHours(0, 0, 0, 0)
                          )
                    }
                    maxTime={setHours(setMinutes(new Date(), 59), 23)}
                    disabled={canDisable}
                    dateFormat="MM/dd/yyyy hh:mm:ss a"
                    className="form-control filter-date"
                  />
                  <span id="err">{eventEndError}</span>
                </div>
              </div>
            )}
            <div>
              {type === 'event' ? 'Event:' : 'Note:'}
              <span className="text-danger">*</span>
              <textarea
                disabled={canDisable}
                rows="5"
                className="form-control"
                name="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <span id="err">{noteError}</span>
            </div>
          </Modal.Body>
          <Modal.Footer id="md-filder" className="w-100">
            <div className="float-right">
              {props.eventDetail && props.eventDetail.eventid && (
                <Button
                  variant="danger"
                  type="submit"
                  className="saveBtns"
                  onClick={() => {
                    setshowDelete(true);
                    setshowEventPop(false)
                  }}
                >
                  Delete
                </Button>
              )}
              {!canDisable && (
                <Button
                  variant="primary"
                  type="submit"
                  className="saveBtns l-m-10"
                  onClick={validate}
                >
                  Save
                </Button>
              )}
              <Button
                variant="secondary"
                className="saveBtns l-m-10"
                onClick={() => {
                  popupClose();
                  setstartDate('');
                  setendDate('');
                }}
              >
                Cancel
              </Button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </div>
  );
};
