import React, { Component } from 'react';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';

class AddWarrantyCompany extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      compTitle: '',
      description: '',
      compTitleErr: '',
      descriptionErr: '',
      companyData: [],
      title: '',
      isShowLoader: false,
    };
  }

  addWarranty = async () => {
    const { compTitle, description } = this.state;
    const { getWarrantyCompanyCallback } = this.props;
    const postData = {
      title: compTitle,
      description: description,
    };

    const endPoint = `${Api.AddWarrantyCompany}`;
    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await AuthApi.postDataToServer(
      endPoint,
      postData
    );
    if (data && data.success !== false) {
      if (getWarrantyCompanyCallback) getWarrantyCompanyCallback();
      swal('Home warranty company added successfully', '', 'success');
      await this.flushAllData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
    } else {
      swal(message, '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  validateForm = (e) => {
    e.preventDefault();
    const { compTitle, description } = this.state;
    let compTitleErr = '',
      descriptionErr,
      isValid = true;

    if (compTitle.trim() === '') {
      compTitleErr = 'Title is required';
      isValid = false;
    }

    if (description.trim() === '') {
      descriptionErr = 'Description is required';
      isValid = false;
    }

    this.setState({ compTitleErr, descriptionErr });
    if (isValid) {
      this.addWarranty();
    }
  };

  flushAllData = () => {
    this.setState({
      compTitle: '',
      description: '',
      compTitleErr: '',
      descriptionErr: '',
    });
  };

  render() {
    const {
      compTitle,
      compTitleErr,
      description,
      descriptionErr,
      isShowLoader,
    } = this.state;
    return (
      <div
        className="modal fade"
        id="homewarrantycompany"
        tabIndex="-1"
        role="dialog"
        data-backdrop="static"
        aria-labelledby="exampleModalLabel1"
        aria-hidden="true"
        ref={(modal) => {
          this.modalRef = modal;
        }}
      >
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel1">
                Add Home Warranty Company
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <React.Fragment>
                <form onSubmit={this.validateForm}>
                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-3 col-form-label"
                    >
                      Title
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Title"
                        value={compTitle}
                        onChange={(e) => {
                          this.setState({ compTitle: e.target.value });
                        }}
                      />
                      <span id="err">{compTitleErr}</span>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="example-text-input"
                      className="col-sm-3 col-form-label"
                    >
                      Description
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Description"
                        value={description}
                        onChange={(e) => {
                          this.setState({ description: e.target.value });
                        }}
                      />
                      <span id="err">{descriptionErr}</span>
                    </div>
                  </div>

                  <div className="form-group row justify-content-center">
                    <div className="">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        data-dismiss="modal"
                        onClick={() => this.flushAllData()}
                        className="btn btn-secondary waves-effect m-l-10"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </React.Fragment>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddWarrantyCompany;
