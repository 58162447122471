import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Template Name',
    field: 'templatename',
    sort: 'disabled',
    width: 170,
  },
  {
    label: 'Type',
    field: 'type',
    sort: 'disabled',
    width: 100,
  },
  {
    label: 'Subject',
    field: 'subject',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
    width: 150,
  },
];

export default class EmailTemplates extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      rows: [],
      emailData: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      showDelete: false,
      filterValue: '',
      filterdata: [
        {
          id: 'sms',
          name: 'SMS',
        },
        {
          id: 'email',
          name: 'Email',
        },
      ],
      isShowLoader: false,
      isWriteAllowed: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-emailtemplate'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-emailtemplate',
      'write'
    );
    if (writeAllowed) {
      this.setState({ isWriteAllowed: true });
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
    }
    customFunctions.setTitle('All Email Templates');

    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.navSkip &&
      this.props.location.state.navCurrentPage
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.navSkip,
          navCurrentPage: this.props.location.state.navCurrentPage,
        },
        () => this.getTemplates()
      );
    } else {
      this.getTemplates();
    }
  };

  changeTemplateStatus = async (template, event) => {
    var isChecked = event.target.checked;
    const { currentPage } = this.state;
    const payload = {
      emailtemplateid: template.id,
      subject: template.subject,
      status: isChecked ? '1' : '0',
      html_template: template.html_template,
      type: template.type,
    };
    const { data, message } = await AuthApi.putDataToServer(
      Api.emailTemplate,
      payload
    );
    if (data && data.success !== false) {
      swal('Email template status changed', '', 'success');
      this.getTemplates(currentPage);
    } else {
      swal(message, '', 'error');
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { emailData, limit, isWriteAllowed } = this.state;
    const page = npage;
    const rows = [];
    emailData &&
      emailData.map((template, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          templatename: template.template_name,
          type: template.type,
          subject: template.subject,
          status: (
            <React.Fragment>
              <input
                type="checkbox"
                disabled={!isWriteAllowed}
                id={`switch${template.id}`}
                checked={Number(template.status === 1) ? 'checked' : ''}
                switch="info"
                onChange={(event) => {
                  this.changeTemplateStatus(template, event);
                }}
              />
              <label
                htmlFor={`switch${template.id}`}
                data-on-label=""
                data-off-label=""
              ></label>
            </React.Fragment>
          ),
          action: (
            <React.Fragment>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: '/add-email-template',
                    state: {
                      detail: template,
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  id="editTemplate"
                  title="Edit Template"
                ></i>
              </a>
              <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, id: template.id });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return template;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getTemplates = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, filterValue, navSkip, navCurrentPage } = this.state;
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }
    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;
    let endPoint = `${Api.emailTemplate}?skip=${skip}&limit=${limit}&search=${keyword}`;
    if (filterValue) {
      endPoint += `&type=${filterValue}`;
    }
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          emailData: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      swal(message, '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getTemplates();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getTemplates();
    });
  };

  deleteTemplate = async () => {
    const { id, currentPage } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.emailTemplate}?emailtemplateid=${id}`
    );
    if (data) {
      this.setState({ showDelete: false, id: null }, () => {
        swal('Email template deleted!', '', 'success').then(() => {
          this.getTemplates(currentPage);
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      showDelete,
      filterdata,
      isShowLoader,
      filterValue,
      isWriteAllowed,
      navSkip,
      navCurrentPage,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All Email Templates" />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the email template ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteTemplate();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, id: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-7">
                        <form onSubmit={this.onSearchSubmit} autoComplete="off">
                          <div className="form-group row">
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-4">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="col-sm-4">
                              <select
                                className="form-control"
                                value={filterValue}
                                onChange={async (e) => {
                                  await this.setState(
                                    { filterValue: e.target.value },
                                    () => {
                                      this.getTemplates();
                                    }
                                  );
                                }}
                              >
                                <option value="">All</option>
                                {filterdata &&
                                  filterdata.map((name) => (
                                    <option
                                      value={name.id}
                                    >{`${name.name}`}</option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </form>
                      </div>
                      {isWriteAllowed && (
                        <div className="col-sm-5">
                          <div className="offset-md-7">
                            <button
                              className="btn btn-primary waves-effect waves-light float-right"
                              onClick={() => {
                                this.props.history.push('/add-email-template');
                              }}
                            >
                              Add Email Template
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mdbtable" ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                        scrollX
                        maxHeight="350px"
                      />
                    </div>
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={
                            navSkip && navCurrentPage
                              ? this.setState(
                                  {
                                    navSkip: null,
                                    navCurrentPage: null,
                                  },
                                  () => this.getTemplates
                                )
                              : this.getTemplates
                          }
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
