import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddBillingItems extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemName: '',
      itemNameErr: '',
      price: '',
      priceErr: '',
      description: '',
      descriptionErr: '',
      isTaxChecked: false,
      itemId: null,
      editType: false,
      title: '',
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'customerbilling-items',
      'write'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    const itemId = this.props.match.params.itemId;
    const url_segment = this.props.match.url.split('/');
    this.setState({ itemId });
    if (url_segment && url_segment.includes('update-item')) {
      this.setState({ editType: true });
    }

    this.setState({ title: `Add Billing Item` }, () =>
      customFunctions.setTitle(this.state.title)
    );

    if (itemId) {
      this.setState({ title: `Edit Billing Item` }, () =>
        customFunctions.setTitle(this.state.title)
      );
      this.getLineItemData(itemId);
    }
  };

  getLineItemData = async (itemId) => {
    const endPoint = `${Api.getLineItem}?item_id=${itemId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        itemName: data.data.data[0].item_name,
        price: data.data.data[0].item_price,
        description: data.data.data[0].item_description,
        isTaxChecked: data.data.data[0].taxable,
      });
    } else {
      var $this = this;
      swal(message, '', 'success');
      setTimeout(() => {
        $this.props.history.push('/items');
      }, 1000);
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { itemName, price, description } = this.state;

    let itemNameErr = '',
      priceErr = '',
      descriptionErr = '',
      isValid = true;

    if (itemName === '') {
      itemNameErr = 'Item name is required';
      isValid = false;
    }

    if (description === '') {
      descriptionErr = 'Description is required';
      isValid = false;
    }

    if (price === '') {
      priceErr = 'Price is required';
      isValid = false;
    }

    this.setState({
      itemNameErr,
      priceErr,
      descriptionErr,
    });

    if (isValid) {
      this.addBillingItems();
    }
  };

  addBillingItems = async (e) => {
    const { itemName, price, description, itemId, isTaxChecked } = this.state;

    const postData = {
      item_name: itemName.trim(),
      item_price: price,
      item_description: description.trim(),
      taxable: isTaxChecked ? 1 : 0,
    };

    const callback = itemId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.AddLineItem;
    if (itemId) {
      postData.id = parseInt(itemId);
    }
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      if (itemId) {
        swal('Billing item updated successfully', '', 'success');
        this.resetData();
        setTimeout(() => {
          var $this = this;
          $this.props.history.push('/items');
        }, 100);
        return;
      }
      swal('Billing item added successfully', '', 'success');
      this.resetData();
      setTimeout(() => {
        var $this = this;
        $this.props.history.push('/items');
      }, 100);
      return;
    } else {
      swal(message, '', 'error');
    }
  };

  resetData = () => {
    this.setState({
      itemName: '',
      price: '',
      description: '',
    });
  };

  handleCheckBox = (e, i) => {
    if (e.target.checked) {
      this.setState({ isTaxChecked: true });
    } else {
      this.setState({ isTaxChecked: false });
    }
  };

  render() {
    const {
      itemName,
      price,
      description,
      title,
      itemId,
      itemNameErr,
      priceErr,
      descriptionErr,
      isTaxChecked,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      {itemId && (
                        <div className="form-group row">
                          <label
                            htmlFor="itemId"
                            className="col-sm-2 col-form-label"
                          >
                            Item Id
                          </label>
                          <div className="col-sm-6">
                            <input
                              className="form-control"
                              disabled
                              value={itemId}
                              type="text"
                              placeholder="John"
                              id="itemId"
                              onChange={(e) => {
                                this.setState({ itemId: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <label
                          htmlFor="itemname"
                          className="col-sm-2 col-form-label"
                        >
                          Item Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <input
                            className="form-control"
                            value={itemName}
                            type="text"
                            placeholder="Item name"
                            id="itemname"
                            onChange={(e) => {
                              this.setState({ itemName: e.target.value });
                            }}
                          />
                          <span id="err">{itemNameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="price-field"
                          className="col-sm-2 col-form-label"
                        >
                          Price<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <div className="input-group">
                            <div className="input-group-prepend">
                              <span className="input-group-text" id="jobTotal">
                                $
                              </span>
                            </div>
                            <input
                              className="form-control"
                              value={price}
                              maxLength="10"
                              type="text"
                              placeholder="$0.00"
                              id="price-field"
                              onChange={(e) => {
                                const re = /^[0-9]*\.?[0-9]*$/;
                                if (
                                  e.target.value === '' ||
                                  re.test(e.target.value)
                                ) {
                                  this.setState({ price: e.target.value });
                                }
                              }}
                            />
                          </div>
                          <span id="err">{priceErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="desc"
                          className="col-sm-2 col-form-label"
                        >
                          Description<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            className="form-control"
                            placeholder="Description"
                            type="textarea"
                            id="desc"
                            rows="10"
                            cols="190"
                            value={description}
                            onChange={(e) => {
                              this.setState({
                                description: e.target.value,
                              });
                            }}
                          />
                          <span id="err">{descriptionErr}</span>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          htmlFor="example-checkbox-input"
                          className="col-sm-2 col-form-label"
                        >
                          Taxable
                        </label>
                        <div className="col-sm-1">
                          <input
                            className="form-control checkbox"
                            checked={isTaxChecked}
                            value={isTaxChecked}
                            type="checkbox"
                            id="example-checkbox-input"
                            onChange={(e) => this.handleCheckBox(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group row d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light text-center"
                        >
                          Submit
                        </button>
                        <button
                          type="reset"
                          onClick={() => {
                            this.resetData();
                            this.props.history.push('/items');
                          }}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddBillingItems);
