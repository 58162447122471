import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
// import Radio from '@material-ui/core/Radio';
import Radio from '@mui/material/Radio';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class Diagnosis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      questions: [],
      workorderid: null,
      answers: [],
      disabled: false,
      isShowLoader: false,
      laboutToDate: null,
    };
  }

  componentDidMount = async () => {
    if (this.props && this.props.workorderid) {
      this.setState(
        {
          workorderid: this.props.workorderid,
          laboutToDate: this.props.laboutToDate.toFixed(2),
        },
        async () => {
          await this.getQuestions();
        }
      );
    }
  };

  getQuestions = async () => {
    const { laboutToDate } = this.state;
    const endPoint = await `${Api.diagnosisUrl}?skip=0&limit=50`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState(
        {
          questions: data.data,
        },
        async () => {
          this.state.questions &&
            this.state.questions.map((que) => {
              if (que.question === 'Labor to Date?') {
                que.answer = laboutToDate || 0;
                que.isDisabled = true;
              }
              que.options.map((q) => {
                q.label = q.name;
              });
              return que;
            });
          await this.patchAnswers();
        }
      );
    } else {
      swal(message, '', 'error');
    }
  };

  onChangeAnswer = (j, answer, type = '') => {
    var { questions } = this.state;
    var ans = type === 'select' ? answer.value : answer;
    if (ans === 'Yes') {
      questions[j].isSelected = true;
      if (questions[j].questionid === 11) {
        questions &&
          questions.map((i) => {
            //eslint-disable-line
            if (i.parentid !== 0) {
              i.isshow = true;
            }
            return i;
          });
      }
    }
    if (ans === 'No') {
      questions[j].isSelected = false;
      if (questions[j].questionid === 11) {
        questions &&
          questions.map((i) => {
            //eslint-disable-line
            if (i.parentid !== 0) {
              i.isshow = false;
            }
            return i;
          });
      }
    }
    questions[j].answer =
      ans && type === 'select' ? { label: ans, value: ans } : ans;
    questions[j].selectedAns = ans ? { label: ans, value: ans } : '';
    questions[j].answerid =
      type === 'select' ? ans && customFunctions.jsonParse(answer).id : null;
    this.setState({ questions });
  };

  patchAnswers = async () => {
    this.setState({ isShowLoader: true });
    const { questions, workorderid } = this.state;
    const endPoint =
      await `${Api.diagnosisUrl}/getworkorderanswers?work_order_id=${workorderid}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.length > 0) {
      questions &&
        questions.map((quest) => {
          data.data.some((answer) => {
            if (answer.questionid === quest.questionid) {
              try {
                quest.answer = answer.answervalue;
                quest.answerid = answer.answerid;
                quest.isshow = answer.isshow;
                quest.other = answer.other;

                let ans =
                  answer.answervalue && quest.type == 'select'
                    ? JSON.parse(
                        answer.answervalue.substring(
                          0,
                          answer.answervalue.length
                        )
                      )
                    : '';
                quest.selectedAns = ans
                  ? { label: ans.value, value: ans.value }
                  : '';
              } catch (err) {
                console.log(err);
              }
            }
          });
        });
      this.setState({ questions, answers: data.data, disabled: true });
    }
    this.setState({ isShowLoader: false });
  };

  validate = async () => {
    var { questions } = this.state;
    let isValid = true;
    var error = '';
    let errorData =
      questions &&
      (await questions.filter(
        (q) => (q.answer === '' || q.answer === null) && q.parentid === 0
      ));
    if (errorData && errorData.length) {
      isValid = false;
      error = 'Please fill all fields!';
    }

    if (questions[10].questionid === 11 && questions[10].answer === 'Yes') {
      const answerNP =
        questions[11].questionid === 12 && !questions[11].answer ? true : false;
      if (answerNP) {
        isValid = false;
        error = 'Please select What Needs to Be Replaced?';
      } else if (
        !answerNP &&
        questions[12].questionid === 13 &&
        !questions[12].answer &&
        questions[13].questionid === 14 &&
        !questions[13].answer &&
        questions[14].questionid === 15 &&
        !questions[14].answer
      ) {
        isValid = false;
        error =
          'Please select atleast one from 3 questions ( What Tonnage? & Upflow, DownFlow, Horizontal or NA? & Refrigerant Type? )';
      }
    }
    this.setState({ error });
    return isValid;
  };

  getItemName = (a4, a2, a3, a1) => {
    let key = '';
    if (a4) {
      key += a4;
    }
    if (a2) {
      key += key ? ' ' + a2 : a2;
    }
    if (a3) {
      key += key ? ' ' + a3 : a3;
    }
    if (a1) {
      key += key ? ' ' + a1 : a1;
    }
    return key;
  };

  onSubmit = async () => {
    const { questions, workorderid } = this.state;

    let question1 = '',
      question2 = '',
      question3 = '',
      question4 = '',
      queAnswer1 = '',
      answervalue1 = '',
      queAnswer2 = '',
      answervalue2 = '',
      queAnswer3 = '',
      answervalue3 = '',
      queAnswer4 = '',
      answervalue4 = '';

    const isValid = await this.validate();
    if (!isValid) return;
    this.setState({ isShowLoader: true });
    let items = [];
    questions &&
      questions.map((i) => {
        items.push({
          questionid: i.questionid,
          answerid: i.answerid,
          answervalue:
            i.answer && typeof i.answer == 'object'
              ? JSON.stringify(i.answer)
              : i.answer,
          parentid: i.parentid,
          workorderid: workorderid,
          isshow: i.isshow ? i.isshow && true : false,
          other: i.other ? i.other : '',
          isAuthRequired:
            (i.questionid === 10 && i.answer === 'Yes') ||
            (i.questionid === 11 && i.answer === 'Yes')
              ? 1
              : 0,
        });
        return i;
      });

    if (items && items.length > 0) {
      await items.map(async (check) => {
        if (check.questionid === 11 && check.answervalue === 'Yes') {
          items &&
            items.map((lineItem) => {
              if (lineItem.questionid === 12) {
                question1 = lineItem.answerid;
                const qanswer = JSON.parse(lineItem.answervalue);
                queAnswer1 = qanswer ? qanswer.name : '';
                answervalue1 = qanswer ? qanswer.value : '';
              }
              if (lineItem.questionid === 13) {
                question2 = lineItem.answerid;
                const qanswer = JSON.parse(lineItem.answervalue);
                queAnswer2 = qanswer ? qanswer.name : '';
                answervalue2 = qanswer ? qanswer.value : '';
              }
              if (lineItem.questionid === 14) {
                question3 = lineItem.answerid;
                const qanswer = JSON.parse(lineItem.answervalue);
                queAnswer3 = qanswer ? qanswer.name : '';
                answervalue3 = qanswer ? qanswer.value : '';
              }
              if (lineItem.questionid === 15) {
                question4 = lineItem.answerid;
                const qanswer = JSON.parse(lineItem.answervalue);
                queAnswer4 = qanswer ? qanswer.name : '';
                answervalue4 = qanswer ? qanswer.value : '';
              }
            });
        }
      });

      const itemName = this.getItemName(
        answervalue4,
        answervalue2,
        answervalue3,
        answervalue1
      );

      let postData = {
        items,
        item_name: itemName ? ' ' + itemName : '',
      };

      const postLineItemData = {
        item_name: itemName ? ' ' + itemName : '',
        question1: question1,
        answervalue1: answervalue1,
        question2: question2,
        answervalue2: answervalue2,
        question3: question3,
        answervalue3: answervalue3,
        question4: question4,
        answervalue4: answervalue4,
        items: [
          {
            quality: 'Good',
            price: '0',
            description: '',
            materiallist: '',
            attachment: '',
            taxable: 0,
            keypoint_benefit: [],
            variant_name: `${itemName} Good`,
            contractid: '',
          },
          {
            quality: 'Better',
            price: '0',
            description: '',
            materiallist: '',
            attachment: '',
            taxable: 0,
            keypoint_benefit: [],
            variant_name: `${itemName} Better`,
            contractid: '',
          },
          {
            quality: 'Best',
            price: '0',
            description: '',
            materiallist: '',
            attachment: '',
            taxable: 0,
            keypoint_benefit: [],
            variant_name: `${itemName} Best`,
            contractid: '',
          },
        ],
      };
      const lineEndpoint = Api.AddLineItem;
      this.setState({ isShowLoader: true });
      const { dt } = await AuthApi.postDataToServer(
        lineEndpoint,
        postLineItemData
      );
      if (dt && dt.success) {
        this.setState({ isShowLoader: false });
      }
      const endPoint = await `${Api.diagnosisUrl}/dignosisqauestionsandanswers`;
      const { data, message } = await AuthApi.postDataToServer(
        endPoint,
        postData
      );

      if (data && data.success) {
        this.setState({ isShowLoader: false });
        swal('Updated successfully', '', 'success').then(() => {
          this.patchAnswers();
        });
      } else {
        this.setState({ isShowLoader: false });
        swal(message, '', 'error');
      }
    } else {
      return false;
    }
    this.setState({ isShowLoader: false });
  };

  render() {
    const { questions, isShowLoader, error, disabled, laboutToDate } =
      this.state;

    return (
      <div className="mid-container">
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="container card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12 qa-card">
                <form className="form">
                  {questions &&
                    questions.length > 0 &&
                    questions.map((question, j) => {
                      if (question.type && question.parentid === 0) {
                        return (
                          <div
                            className="form-group select-yesno-type"
                            key={`${j}-questions`}
                          >
                            <label>{question.question}</label>
                            <br />
                            {question.type === 'input' && (
                              <input
                                type="text"
                                className="form-control qa-inputs"
                                name={question.questionid}
                                id={`${question.type}-${question.questionid}-${j}`}
                                value={question.answer}
                                disabled={disabled || question.isDisabled}
                                onChange={(e) => {
                                  this.onChangeAnswer(j, e.target.value);
                                }}
                              />
                            )}
                            {question.type === 'textarea' && (
                              <textarea
                                cols="5"
                                rows="2"
                                className="form-control qa-inputs"
                                name={question.questionid}
                                id={`${question.type}-${question.questionid}-${j}`}
                                value={question.answer}
                                disabled={disabled}
                                onChange={(e) => {
                                  this.onChangeAnswer(j, e.target.value);
                                }}
                              />
                            )}

                            {question.type === 'select' && (
                              // <select
                              //   name={question.questionid}
                              //   id={`${question.type}-${question.questionid}-${j}`}
                              //   value={question.answer}
                              //   disabled={disabled}
                              //   className="form-control qa-inputs"
                              //   onChange={(e) => {
                              // this.onChangeAnswer(
                              //   j,
                              //   e.target.value,
                              //   'select'
                              // );
                              // }}
                              // >
                              //   <option value="" disabled="">
                              //     Select
                              //   </option>
                              //   {question.options &&
                              //     question.options.length > 0 &&
                              //     question.options.map(
                              //       (selectItem, selectIdx) => (
                              //         <option
                              //           key={`${selectIdx}-select-${j}`}
                              //           value={
                              //             selectItem &&
                              //             JSON.stringify(selectItem)
                              //           }
                              //         >
                              //           {selectItem.name}
                              //         </option>
                              //       )
                              //     )}
                              // </select>
                              <ReactSelect
                                value={question.selectedAns}
                                placeholder="Select"
                                onChange={(e) => {
                                  this.onChangeAnswer(j, e ? e : '', 'select');
                                }}
                                isDisabled={disabled}
                                isClearable={true}
                                options={question.options}
                              />
                            )}

                            {question.answer &&
                              customFunctions.jsonParse(question.answer)
                                .value === 'Other' && (
                                <input
                                  type="text"
                                  className="form-control qa-inputs mt-1"
                                  name={question.questionid}
                                  id={`${question.type}-${question.questionid}-${j}`}
                                  value={question.other}
                                  disabled={disabled}
                                  onChange={(e) => {
                                    var { questions } = this.state;
                                    questions[j].other = e.target.value;
                                    this.setState({ questions });
                                  }}
                                />
                              )}

                            {question.type === 'radio' &&
                              question.options &&
                              question.options.length > 0 &&
                              question.options.map((radioItem, k) => (
                                <div
                                  className="form-check form-check-inline ml-3"
                                  key={`${k}-radio-${j}`}
                                >
                                  <Radio
                                    disabled={disabled}
                                    name={question.questionid}
                                    id={`${question.type}-${question.questionid}-${j}-${k}`}
                                    value={radioItem.value}
                                    onChange={(e) => {
                                      this.onChangeAnswer(j, radioItem.value);
                                    }}
                                    checked={
                                      question.answer === radioItem.value
                                    }
                                    color="default"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`${question.type}-${question.questionid}-${j}-${k}`}
                                  >
                                    {radioItem.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        );
                      }
                      if (
                        question.type &&
                        question.parentid !== 0 &&
                        (question.isshow === '1' || question.isshow)
                      ) {
                        return (
                          <div
                            className="form-group select-sub"
                            key={`${j}-subquestions`}
                          >
                            <label>{question.question}</label>
                            <br />
                            {question.type === 'input' && (
                              <input
                                type="text"
                                className="form-control qa-inputs"
                                name={question.questionid}
                                id={`${question.type}-${question.questionid}-${j}`}
                                value={question.answer}
                                disabled={disabled}
                                onChange={(e) => {
                                  this.onChangeAnswer(j, e.target.value);
                                }}
                              />
                            )}

                            {question.type === 'select' && (
                              // <select
                              //   name={question.questionid}
                              //   id={`${question.type}-${question.questionid}-${j}`}
                              //   value={question.answer}
                              //   disabled={disabled}
                              //   className="form-control qa-inputs"
                              //   onChange={(e) => {
                              //     this.onChangeAnswer(
                              //       j,
                              //       e.target.value,
                              //       'select'
                              //     );
                              //   }}
                              // >
                              //   <option value="" disabled="">
                              //     Select
                              //   </option>
                              //   {question.options &&
                              //     question.options.length > 0 &&
                              //     question.options.map(
                              //       (selectItem, selectIdx) => (
                              //         <option
                              //           key={`${selectIdx}-select-${j}`}
                              //           value={JSON.stringify(selectItem)}
                              //         >
                              //           {selectItem.name}
                              //         </option>
                              //       )
                              //     )}
                              // </select>
                              <ReactSelect
                                value={question.selectedAns}
                                placeholder="Select"
                                onChange={(e) => {
                                  this.onChangeAnswer(j, e ? e : '', 'select');
                                }}
                                isClearable={true}
                                isDisabled={disabled}
                                options={question.options}
                              />
                            )}
                            {question.type === 'radio' &&
                              question.options &&
                              question.options.length > 0 &&
                              question.options.map((radioItem, m) => (
                                <div
                                  className="form-check form-check-inline ml-3"
                                  key={`${m}-radio-${j}`}
                                >
                                  <Radio
                                    name={question.questionid}
                                    id={`${question.type}-${question.questionid}-${j}-${m}`}
                                    value={radioItem.value}
                                    disabled={disabled}
                                    onChange={(e) => {
                                      this.onChangequestionAnswer(
                                        j,
                                        radioItem.value
                                      );
                                    }}
                                    checked={
                                      question.answer === radioItem.value
                                    }
                                    color="default"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`${question.type}-${question.questionid}-${j}-${m}`}
                                  >
                                    {radioItem.name}
                                  </label>
                                </div>
                              ))}
                          </div>
                        );
                      }
                    })}
                </form>
                <div className="text-center">
                  <span id="err">{error}</span>
                </div>
                <div className="row">
                  {!disabled && (
                    <div className="col-sm-12 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={(e) => {
                          e.preventDefault();
                          this.onSubmit();
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Diagnosis);
