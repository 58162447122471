import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';
import SelectAsyncPaginate from '../../../components/ReactSelectPagination/SelectAsyncPaginate';

class AssignContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicianId: null,
      contracts: [],
      contractId: null,
      loginId: null,
      contractName: '',
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({
      roleId: userdata.role_id,
      loginId: userdata.login_id,
      technicianId: this.props.technicianId,
    });
    await this.getContracts();
  };

  getContracts = async () => {
    let endPoint = `${Api.employmentContractUrl}?skip=0&limit=50`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        contracts: data.data,
        totalCount: data.data.total_count,
      });
    } else {
      swal(message, '', 'error');
    }
  };

  assignContract = async () => {
    const { contractId, technicianId } = this.state;
    const postData = {
      technician_sign: '',
      sign_date: '',
      notes: '',
      technician_id: technicianId,
      contractid: contractId,
    };
    const endPoint = `${Api.employmentUrl}`;
    const callback = AuthApi.postDataToServer;
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      swal('Contract Assigned!', '', 'success');
      setTimeout(() => {
        $this.props.handleClose();
        $this.props.handleCallback();
      }, 1000);
    } else {
      swal(message, '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { contractId } = this.state;
    let contractIdErr = '';
    let isValid = true;
    if (!contractId) {
      contractIdErr = 'Please select contract!';
      isValid = false;
    }
    this.setState({ contractIdErr });
    if (isValid) {
      this.assignContract();
    }
  };

  render() {
    const { contractIdErr, contractName } = this.state;
    return (
      <AUX>
        <Modal
          centered
          size="md"
          className="rating-popup"
          show={this.props.showAssignContract}
          onHide={this.props.handleClose}
        >
          <Modal.Header>
            <Modal.Title>
              <h6>
                Select Contract to Assign<span className="text-danger">*</span>
              </h6>
            </Modal.Title>
            <i
              className="mdi mdi-close"
              onClick={() => this.props.handleClose()}
            ></i>
          </Modal.Header>
          <Modal.Body>
            <div className="page-content-wrapper" style={{ paddingTop: '0px' }}>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-12">
                          {/*    <select
                            className="form-control"
                            value={contractId}
                            onChange={(e) => {
                              this.setState({ contractId: e.target.value });
                            }}
                          >
                            <option value="">Select Contract</option>
                            {contracts && contracts.length > 0 && 
                              contracts.map((contract) => (
                                <option
                                  value={contract.id}
                                >{`${contract.contract_name}`}</option>
                              ))}
                          </select>*/}
                          <SelectAsyncPaginate
                            onSelectChange={(e) => {
                              this.setState(
                                {
                                  contractId: e ? e.id : '',
                                  contractName: e ? { name: e.name } : '',
                                },
                                () => {
                                  this.forceUpdate();
                                }
                              );
                            }}
                            className="w-100"
                            isSearch={true}
                            value={contractName}
                            isClearable={contractName}
                            apiname="contracts"
                            endpoint={Api.employmentContractUrl + `?search=`}
                            selectPlaceholder="Select Contract"
                          />

                          <span id="err">{contractIdErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center text-center">
                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.handleClose();
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </AUX>
    );
  }
}
export default withRouter(AssignContract);
