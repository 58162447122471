/* eslint-disable */
import React, { Component } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter } from 'react-router-dom';
import { uploadFile } from 'react-s3';
// import ReactCreatableSelect from 'react-select/lib/Creatable';
import ReactCreatableSelect from 'react-select';
import SignatureCanvas from 'react-signature-canvas';
import swal from 'sweetalert';
import ATTACHMENT from '../../../assets/images/attachment.png';
import DELETE from '../../../assets/images/delete.svg';
import DOWNLOAD from '../../../assets/images/download.svg';
import DRAFT from '../../../assets/images/draft.png';
import INVOICE from '../../../assets/images/invoice.svg';
import LOSE from '../../../assets/images/los.svg';
import LOST from '../../../assets/images/lost.png';
import PRINT from '../../../assets/images/print.svg';
import SEND from '../../../assets/images/send.svg';
import SENT from '../../../assets/images/sent.png';
import WIN from '../../../assets/images/win.svg';
import WON from '../../../assets/images/won.png';
import DatePickerComponent from '../../../components/DatePicker/datePicker.component';
import ImagePreview from '../../../components/ImagePreview';
import Loader from '../../../components/Loader';
import PhoneInput from '../../../components/phoneInput/phoneInput.component';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';
import AddTerms from './termsPopup';
import SelectStaticDropdown from '../../../components/ReactSelectPagination/SelectStaticDropdown';

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_REGION,
  accessKeyId: process.env.REACT_APP_S3_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
};

class EstimateDetailsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      poNo: '',
      issueDate: new Date(),
      issueDateErr: '',
      workStartDate: new Date(),
      workStartDateErr: '',
      estmtNoteToggle: false,
      estmtToggle: false,
      isEstimateChecked: false,
      paymentTerm: '',
      paymentTermErr: '',
      billAddress: '',
      billAddressErr: '',
      contactNo: '',
      contactNoErr: '',
      billEmail: '',
      billEmailErr: '',
      estimateNo: '',

      cstId: null,
      customerName: '',
      customerEmail: '',
      customerAddress: '',
      customerPhone: '',
      customers: [],
      customersData: [],
      customersDetailsData: [],
      selectedOption: null,
      selectedOptionErr: '',

      contractName: '',
      contractBody: '',
      jobSelectedOption: null,
      jobSelectedOptionErr: '',
      jobsOptionData: [],
      jobDetailsData: [],

      purchaseSelectedOption: null,
      purchaseOptionData: [],
      purchaseDetailsData: [],

      show: false,
      contractTerms: {},
      termsId: null,

      lineItemsOptions: [],
      jobDetailsToggle: true,
      workType: '',
      modalShow: false,
      itemName: '',
      itemNameErr: '',
      price: '',
      priceErr: '',
      noteDescription: '',
      noteDescriptionErr: '',
      estimateId: null,
      editType: false,
      title: '',
      customerToggle: false,
      disabled: false,
      lineItemToggle: false,
      addedFiles: '',
      imgBucket: [],
      uploadComplete: true,
      index: '',
      showDelete: false,
      lineItems: [
        {
          item: { value: '', label: '' },
          quantity: 1,
          price: 0,
          isTaxChecked: false,
          total: null,
          lineDiscription: '',
          quality: { value: '', label: '' },
          materialList: '',
          changeItem: false,
          lineContract: '',
          lineContractId: null,
        },
      ],
      lineItemsErr: '',
      taxData: [],
      subTotal: null,
      tax: { label: '', value: null },
      discount: { discountType: 'flat', value: '' },
      total: 0,
      invoiceRef: '',
      status_estimate_type: '',
      items: [],
      roleId: null,
      signUrl: null,
      customer: [],
      customerId: null,
      customer_sign: null,
      tech_sign: '',
      work_order_id: null,
      swalMsg: '',
      imagePreviewShow: false,
      file: null,
      invoiceInDraft: false,
      isShowLoader: false,
      orderDetails: {},
      fromComponent: '',
      qualityOptions: [],
      currentQualityOptions: [],
      qualityData: [],
      disabledField: false,
      selectedLineId: null,
      termAgree: false,
      termsAgreeErr: '',
      signErr: '',
      ipaddress: '',
      paginationOptions: {},
      taxCheck: false,
      parentId: null,
      taxErr: '',
    };
    this.estimateRef = React.createRef();
  }

  sigPad = {};

  saveSign = (e) => {
    e.preventDefault();
    let isValid = true;
    if (this.sigPad.getTrimmedCanvas().toDataURL('image/png').length < 200) {
      isValid = false;
      this.setState({ signErr: 'Signature is required' });
      return;
    } else if (this.state.termAgree === false && this.state.roleId == 4) {
      if (!this.state.termAgree) {
        this.estimateRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      this.setState({
        signErr: '',
        termsAgreeErr: 'Terms and condition is required',
      });
      isValid = false;
      return;
    } else {
      this.setState(
        {
          termsAgreeErr: '',
          signErr: '',
          status_estimate_type: '',
          signUrl: this.sigPad.getTrimmedCanvas().toDataURL('image/png'),
        },
        async () => {
          // await this.getIPAddress();
          await this.addEstimateData('signSave');
        }
      );
    }
  };

  clearSign = (e) => {
    e.preventDefault();
    this.sigPad.clear();
    this.setState({ signErr: '' });
  };

  addItems = () => {
    const { lineItems } = this.state;
    lineItems.push({
      item: { value: '', label: '' },
      quantity: 1,
      price: 0,
      isTaxChecked: false,
      total: '',
      lineDiscription: '',
      quality: { value: '', label: '' },
      materialList: '',
      lineContract: '',
    });
    lineItems &&
      lineItems.length > 0 &&
      lineItems.map((li) => {
        if (li.quality.value !== '') {
          li.changeItem = true;
        }
        return li;
      });
    this.setState({ lineItems });
  };

  removeItems = (id) => {
    const { lineItems } = this.state;
    lineItems.splice(id, 1);
    this.setState({ lineItems }, async () => {
      let subTotal = await this.getSubtotal();
      let total = await this.getTotal();
      this.setState({ subTotal, total });
      await this.getTaxCheck();
    });
  };

  componentDidMount = async () => {
    //creating function to load ip address from the API
    fetch('https://ipapi.co/json/')
      .then((response) => {
        return response.json();
      }, 'jsonp')
      .then((res) => {
        this.setState({ ipaddress: res.ip });
      })
      .catch((err) => console.log(err));

    const estimateId = this.props.match.params.estimateId;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState({ disabledField: userdata.role_id == 4 ? true : false });

    let pageOptions =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.paginationObj
        ? this.props.location.state.paginationObj
        : '';
    this.setState({ paginationOptions: pageOptions });

    // const url_segment = this.props.match.url.split('/');
    // this.setState({ estimateId });
    // if (url_segment && url_segment.includes('update-estimate')) {
    //   this.setState({ editType: true });
    // }
    if (
      (await this.props) &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.parentId
    ) {
      this.setState({
        parentId: this.props.location.state.parentId,
      });
    }
    if (
      (await this.props) &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.orderDetails &&
      this.props.location.state.fromComponent == 'workorder'
    ) {
      let selectedOption = {
        value: this.props.location.state.orderDetails.CustomerID,
        label: this.props.location.state.orderDetails.CustomerName,
      };
      let jobSelectedOption = {
        value: this.props.location.state.orderDetails.ID,
        label: this.props.location.state.orderDetails.WorkOrder,
      };
      this.setState({
        orderDetails: this.props.location.state.orderDetails,
        selectedOption,
        jobSelectedOption,
        fromComponent: 'workorder',
        poNo: this.props.location.state.orderDetails.PO,
      });
      this.getCustomerData(this.props.location.state.orderDetails.CustomerID);
      this.getJobDetailsData(this.props.location.state.orderDetails.ID);
    }
    this.setState({ roleId: userdata.role_id });

    this.getTaxRateData();
    this.getCustomers();
    this.getPurchaseOrders();
    await this.getLineItemData();

    if (estimateId) {
      this.setState({ title: `Edit Estimate`, estimateId }, () =>
        customFunctions.setTitle(this.state.title)
      );
      this.getEstimateDetailsData(estimateId);
    } else {
      this.setState(
        {
          title: `Add Estimate`,
          estmtToggle: true,
          customerToggle: true,
          disabled: true,
        },
        () => {
          customFunctions.setTitle(this.state.title);
          this.getContracts();
        }
      );
    }
    if (this.state.cstId) this.getJobOrders(this.state.cstId);
    if (this.state.modalShow) this.getParentsData();
    // this.getParentsData();
  };

  getContracts = async () => {
    let endPoint = `${Api.getContractItem}?type=Invoice Contract`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      let terms =
        data.data.data &&
        data.data.data.filter(
          (i) => i.contract_name.toLowerCase() === 'standard invoice contract'
        );
      this.setState({ contractTerms: terms ? terms[0] : {} });
    } else {
      swal(message, '', 'error');
    }
  };

  getEstimateDetailsData = async (estimateId) => {
    const { qualityOptions } = this.state;
    if (estimateId == 'null') {
      this.props.history.push('/estimates');
      return;
    }
    this.setState({
      isShowLoader: true,
    });
    const endPoint = `${Api.getEstimate}?estimate_id=${estimateId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data && data.data.data.length === 0) {
      this.setState({ isShowLoader: false });
      this.props.history.push('/estimates');
    }
    if (data && data.data && data.data.data && data.data.data[0]) {
      let lineItems = [];
      let _qualityOptions = qualityOptions;
      // let lineItemsOptions = [...this.state.lineItemsOptions];
      // let items = [...this.state.items];
      data.data.data[0] &&
        data.data.data[0]['items'] &&
        data.data.data[0]['items'].map((i) => {
          lineItems.push({
            item: { label: i.item_name, value: i.item_id },
            quantity: i.quantity == null ? 1 : i.quantity,
            price: i.price,
            isTaxChecked: i.is_taxable ? true : false,
            total: i.total,
            lineDiscription: i.description,
            quality: { label: i.varient, value: i.varient },
            materialList: i.material_list,
            changeItem: true,
            lineContract: i.contractbody,
            lineContractId: i.contractid,
          });

          let matchedIndex = _qualityOptions.findIndex((item) => {
            return i.varient === item.value && i.item_id === item.item_id;
          });
          if (matchedIndex !== -1) {
            _qualityOptions[matchedIndex].isset = true;
          }

          //added existing items to items list
          // items.push({
          //   item_name: i.item_name,
          //   item_price: i.price,
          //   taxable: i.is_taxable ? 1 : 0,
          //   item_description: i.description,
          // });
          // lineItemsOptions.push({
          //   value: i.item_name,
          //   label: i.item_name,
          // });
        });

      if (lineItems && lineItems.length === 1) {
        let matchedIndex = _qualityOptions.findIndex((item) => {
          return (
            lineItems[0].quality.value === item.value &&
            lineItems[0].item.value === item.item_id
          );
        });
        if (matchedIndex !== -1) {
          _qualityOptions[matchedIndex].isset = false;
        }
      }

      this.setState({ qualityOptions: _qualityOptions });

      let tax = { ...this.state.tax };
      let taxs =
        data.data.data[0] &&
        data.data.data[0].tax &&
        JSON.parse(data.data.data[0].tax);
      tax.label = taxs && taxs.tax_name && taxs.tax_name;
      tax.value = taxs && taxs.tax_rate && taxs.tax_rate;
      let discount = { discountType: '', value: '' };
      let discountData =
        data.data.data[0] && data.data.data[0].discount
          ? JSON.parse(data.data.data[0].discount)
          : {};
      if (Object.keys(discountData).length) {
        discount.discountType = discountData.discount_type;
        discount.value = discountData.value;
      }
      this.setState(
        {
          isShowLoader: false,
          // lineItemsOptions,
          // items,
          estimateNo: data.data.data[0].estimate_id,
          issueDate:
            data.data.data[0].issue_date !== null
              ? new Date(data.data.data[0].issue_date)
              : '',
          // workStartDate: data.data.data[0].work_start_date && customFunctions.getMDYDateTime(data.data.data[0].work_start_date),
          paymentTerm: { name: data.data.data[0].payment_terms },
          // billAddress: data.data.data[0].payment_terms,
          // billEmail: data.data.data[0].payment_terms,
          customerName: data.data.data[0].customer_name
            ? data.data.data[0].customer_name
            : '-',
          customerEmail: data.data.data[0].email
            ? data.data.data[0].email
            : '-',
          customerAddress: data.data.data[0].address
            ? data.data.data[0].address
            : '-',
          customerPhone: data.data.data[0].phone_no
            ? data.data.data[0].phone_no
            : '-',
          customerId: data.data.data[0].customer_id
            ? data.data.data[0].customer_id
            : null,
          noteDescription: data.data.data[0].note,
          contractName: data.data.data[0].contract_name,
          contractBody: data.data.data[0].contract_body,

          imgBucket: data.data.data[0].attachments,

          workType: data.data.data[0].order_type,
          total: data.data.data[0].total,
          subTotal: data.data.data[0].sub_total,
          tax: tax,
          discount: discount,
          lineItems: lineItems,
          work_order_id: data.data.data[0].work_order_id,
          estimate_type: data.data.data[0].estimate_type,
          invoiceRef: data.data.data[0].invoice_ref,
          customer_sign: data.data.data[0].customer_sign
            ? JSON.parse(data.data.data[0].customer_sign)
            : null,
          tech_sign: data.data.data[0].tech_sign
            ? JSON.parse(data.data.data[0].tech_sign)
            : null,
          isEstimateChecked:
            data.data.data[0].is_show_portal == '1' ? true : false,
          file: data.data.data[0].file,
          termAgree: Number(data.data.data[0].term_agree) === 1 ? true : false,
        },
        () => {
          if (this.state.noteDescription !== '') {
            this.setState({ estmtNoteToggle: false });
          }
          if (this.state.customerId) {
            this.getCustomerData(this.state.customerId);
          }
          if (this.state.work_order_id) {
            this.getJobDetailsData(this.state.work_order_id);
          }
          if (this.state.invoiceRef) this.checkInvoice(this.state.invoiceRef);

          this.getTaxCheck();
          this.getTotal();
          this.getSubtotal();

          if (!this.state.contractName) this.getContracts();
        }
      );
    } else {
      var $this = this;
      swal(message, '', 'error');
      this.setState({
        isShowLoader: false,
      });
      setTimeout(() => {
        $this.props.history.push('/estimates');
      }, 1000);
    }
  };

  checkInvoice = async (invoiceId) => {
    const endPoint = `${Api.getInvoice}?invoice_id=${invoiceId}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    this.setState({
      invoiceInDraft:
        data && data.data && data.data.data && data.data.data.length == 0
          ? true
          : false,
    });
  };

  handleCustomerChange = (selectedOption) => {
    this.setState({ selectedOption, jobDetailsToggle: false }, () => {
      this.getCustomerData(selectedOption.value);
      this.getJobOrders(selectedOption.value);
    });
  };

  getCustomerData = async (customerId) => {
    const endPoint = `${Api.getCustomerById}?customer_id=${customerId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        customer: data.data || [],
        customersDetailsData: data.data,
      });
    }
  };

  getCustomers = async () => {
    let endPoint = `${Api.customerUrl}?skip=0&limit=10000`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const customerOptions = [];
      data.data.data &&
        data.data.data.map((customer) => {
          customerOptions.push({
            value: customer.ID,
            label: `${customer.CustomerName}`,
          });
          return customer;
        });
      this.setState({ customersData: customerOptions });
    } else {
      swal(message, '', 'error');
    }
  };

  handleJobChange = (jobSelectedOption) => {
    this.setState({ jobSelectedOption }, () => {
      this.getJobDetailsData(jobSelectedOption.value);
    });
  };

  getJobDetailsData = async (workId) => {
    let endPoint = `${Api.orderById}?work_order_id=${workId}&type=1`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data[0]) {
      this.setState({
        jobDetailsData: data.data[0],
        workStartDate:
          data.data[0].WorkOrderDate && new Date(data.data[0].WorkOrderDate),
        poNo: data.data[0].PO ? data.data[0].PO : '',
      });
    }
  };

  getJobOrders = async (cstId) => {
    let endPoint = `${Api.getWorkOrderById}?skip=0&limit=10000&customer_id=${cstId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const jobs = [];
      data.data.data &&
        data.data.data.map((job) => {
          jobs.push({
            value: job.ID,
            label: `${job.WorkOrder}`,
          });
          return job;
        });
      this.setState({ jobsOptionData: jobs });
    } else {
      swal(message, '', 'error');
    }
  };

  getPurchaseOrders = async () => {
    let endPoint = `${Api.createPurchaseOrder}?`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const purchaseData = [];
      data.data.data &&
        data.data.data.map((purchase) => {
          purchaseData.push({
            value: purchase.ID,
            label: `${purchase.ExpenseCode}`,
          });
          return purchase;
        });
      this.setState({ purchaseOptionData: purchaseData });
    } else {
      swal(message, '', 'error');
    }
  };

  handlePurchaseChange = (purchaseSelectedOption) => {
    this.setState({ purchaseSelectedOption }, () => {
      this.getPurchaseDetailsData(purchaseSelectedOption.value);
    });
  };

  getPurchaseDetailsData = async (poId) => {
    let endPoint = `${Api.purchaseById}?purchase_id=${poId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ purchaseDetailsData: data.data[0] });
    }
  };

  getParentsData = async (id) => {
    let endPoint = `${Api.getContractItem}?contract_id=${id}`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({ contractTerms: data.data.data[0] }, () => {
        if (this.state.estimateId) {
          this.setState({ swalMsg: 'Terms' }, () => {
            this.addEstimateData();
          });
        }
      });
    }
  };

  handleLineItemChange = (selectedLineItem, index) => {
    // this.setState({
    //   lineItems: [
    //     {
    //       item: { value: '', label: '' },
    //       quantity: 1,
    //       price: 0,
    //       isTaxChecked: false,
    //       total: '',
    //       lineDiscription: '',
    //       quality: { value: '', label: '' },
    //       materialList: '',
    //     },
    //   ],
    // });
    let lineItems = [...this.state.lineItems];
    if (lineItems[index] && lineItems[index]['item']) {
      lineItems[index]['item'].label = selectedLineItem.label;
      lineItems[index]['item'].value = selectedLineItem.value;
      lineItems[index].quantity = 1;
      lineItems[index].price = 0;
      lineItems[index].isTaxChecked = false;
      lineItems[index].total = '';
      lineItems[index].lineDiscription = '';
      lineItems[index].quality = { value: '', label: '' };
      lineItems[index].materialList = '';
      lineItems[index].changeItem = false;
      lineItems[index].lineContract = '';
      lineItems[index].lineContractId = '';
    }
    this.setState({ lineItems });
  };

  getLineItemData = async () => {
    let endPoint = `${Api.getLineItem}?skip=0&limit=1000`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const lineItemsData = [];
      const lineItemQualities = [];
      let lineItemsTotal =
        data.data.data && data.data.data.length > 0 ? data.data.data : [];

      for (let item of lineItemsTotal) {
        lineItemsData.push({
          value: item.item_id,
          label: `${item.item_name}`,
        });
        if (item.items && item.items.length > 0) {
          for (let quality of item.items) {
            lineItemQualities.push({
              item_id: quality.item_id,
              value: quality.quality,
              label: `${quality.quality}`,
              isset: false,
            });
          }
        }
      }
      this.setState({
        lineItemsOptions: lineItemsData,
        items: data.data.data,
        qualityOptions: lineItemQualities,
      });
    } else {
      swal(message, '', 'error');
    }
  };

  getTaxRateData = async () => {
    let endPoint = `${Api.getTaxRate}?skip=0&limit=100`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const taxOptions = [];
      data.data.data &&
        data.data.data.map((tax) => {
          taxOptions.push({
            value: tax.tax_rate,
            label: tax.tax_name,
          });
        });

      this.setState(
        {
          taxData: taxOptions,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      alert(message);
    }
  };

  handleTaxChange = (selectedOption) => {
    let tax = { ...this.state.tax };
    tax.label = selectedOption.label;
    tax.value = selectedOption.value;
    this.setState({ tax }, () => {
      this.changeLineItemTotal();
      let total = this.getTotal();
      this.setState({ total });
      this.forceUpdate();
    });
  };

  validateForm = (e) => {
    if (e) e.preventDefault();
    const {
      paymentTerm,
      selectedOption,
      jobSelectedOption,
      billEmail,
      purchaseSelectedOption,
      billAddress,
      contactNo,
      estimateId,
      lineItems,
      issueDate,
      workStartDate,
      tax,
    } = this.state;

    let paymentTermErr,
      purchaseSelectedOptionErr,
      selectedOptionErr,
      jobSelectedOptionErr,
      billAddressErr,
      billEmailErr,
      contactNoErr,
      lineItemsErr,
      issueDateErr,
      workStartDateErr,
      taxErr,
      isValid = true;

    // if (paymentTerm === '') {
    //   paymentTermErr = 'Payment Term is required';
    //   isValid = false;
    // }

    // if (billAddress === '') {
    //   billAddressErr = 'Bill Address is required';
    //   isValid = false;
    // }

    // if (contactNo === '') {
    //   contactNoErr = 'Mobile is required';
    //   isValid = false;
    // }

    // if (billEmail === '') {
    //   billEmailErr = 'Email is required';
    //   isValid = false;
    // }
    // if (!purchaseSelectedOption) {
    //   purchaseSelectedOptionErr = 'Please select po#';
    //   isValid = false;
    // }

    if (billEmail !== '' && !customFunctions.validateEmail(billEmail)) {
      billEmailErr = 'Enter Valid Email';
      isValid = false;
    }

    if (!estimateId && !selectedOption) {
      selectedOptionErr = 'Please select customer';
      isValid = false;
    }

    if (!estimateId && selectedOption && !jobSelectedOption) {
      jobSelectedOptionErr = 'Please select job';
      isValid = false;
    }
    if (!issueDate) {
      issueDateErr = 'Please select issue date';
      isValid = false;
    }
    if (!workStartDate) {
      workStartDateErr = 'Please select start date';
      isValid = false;
    }
    let isEmpty = lineItems.filter(
      (i) =>
        i.item.value == '' ||
        i.item.value == null ||
        i.quantity == '' ||
        i.quantity == null ||
        i.price == 0 ||
        i.price == null ||
        i.quality.value == '' ||
        i.quality.value == null
    );
    if (isEmpty.length > 0) {
      swal(
        'Please fill up all mandatory details for line item (Item, Quality, Quantity & Price)',
        '',
        'error'
      );
      isValid = false;
    }
    let isAnyTaxSelected = lineItems.some((i) => i.isTaxChecked);
    if (isAnyTaxSelected && tax.value === null) {
      taxErr = 'Please select tax';
      isValid = false;
    }
    this.setState({
      paymentTermErr,
      purchaseSelectedOptionErr,
      selectedOptionErr,
      jobSelectedOptionErr,
      billAddressErr,
      billEmailErr,
      contactNoErr,
      lineItemsErr,
      issueDateErr,
      workStartDateErr,
      taxErr,
    });

    if (isValid) {
      this.addEstimateData();
    }
  };

  addEstimateData = async (res = '') => {
    const {
      estimateId,
      issueDate,
      workStartDate,
      paymentTerm,
      billAddress,
      billEmail,
      contactNo,
      isEstimateChecked,
      selectedOption,
      jobSelectedOption,
      noteDescription,
      imgBucket,
      contractTerms,
      lineItems,
      subTotal,
      discount,
      discountType,
      total,
      tax,
      status_estimate_type,
      signUrl,
      roleId,
      swalMsg,
      fromComponent,
      termAgree,
      ipaddress,
      estimate_type,
    } = this.state;
    let updatedLineItems = [];
    lineItems &&
      lineItems.map((i) => {
        updatedLineItems.push({
          item_name: i.item.label,
          item_id: i.item.value,
          description: i.lineDiscription,
          quantity: parseInt(i.quantity),
          price: parseFloat(i.price),
          is_taxable: i.isTaxChecked ? 1 : 0,
          total: i.total ? i.total : 0,
          material_list: i.materialList,
          varient: i.quality.label,
          contractid: i.lineContractId,
        });
      });

    let postData = {
      issue_date: issueDate && new Date(issueDate).toISOString(),
      work_start_date: workStartDate && new Date(workStartDate).toISOString(),
      note: noteDescription,
      term_id: contractTerms && contractTerms.id,
      payment_terms: paymentTerm && paymentTerm.name,
      items: updatedLineItems,
      discount: { discount_type: discount.discountType, value: discount.value },
      tax: { tax_name: tax.label, tax_rate: tax.value },
      sub_total: subTotal,
      is_show_portal: isEstimateChecked ? '1' : '0',
      term_agree: termAgree === true ? 1 : 0,
      total,
    };

    const callback = estimateId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.addEstimate;

    let sign = {};
    if (signUrl !== null) {
      sign.signature_url = signUrl;
      sign.signature_date = new Date();
      sign.ipaddress = ipaddress;
    }

    if (roleId == 4 && signUrl !== null) {
      postData.customer_sign = JSON.stringify(sign);
    }
    if ((roleId == 5 || roleId == 6) && signUrl !== null) {
      postData.tech_sign = JSON.stringify(sign);
    }
    if (!estimateId) {
      postData.customer_id = selectedOption.value;
    }

    if (!estimateId) {
      postData.work_order_id = jobSelectedOption.value;
    }

    if (estimateId) {
      postData.estimate_id = parseInt(estimateId);
    }

    if (imgBucket) {
      postData.attachments = imgBucket;
    }

    if (status_estimate_type) {
      postData.estimate_type = status_estimate_type;
    }
    this.setState({
      isShowLoader: true,
    });
    const { data, message } = await callback(endPoint, postData);
    if (data) {
      this.setState({
        isShowLoader: false,
      });
      if (res == 'signSave') {
        swal('Sign saved!', '', 'success');
        this.getEstimateDetailsData(estimateId);
        return;
      }
      if (estimateId && swalMsg) {
        swal(`${swalMsg} updated successfully`, '', 'success');
        this.setState({ swalMsg: '' });
        return;
      }
      if (estimateId && !status_estimate_type) {
        swal('Estimate updated successfully', '', 'success');
        this.resetData();
        setTimeout(() => {
          var $this = this;
          $this.props.history.goBack();
        }, 100);
        return;
      }
      if (estimateId && status_estimate_type) {
        if (status_estimate_type == 'WON') {
          swal(
            roleId == 4
              ? 'Estimate approved successfully'
              : 'Estimate won successfully',
            '',
            'success'
          ).then(() => {
            if (fromComponent === 'workorder') {
              if (data && data.data && status_estimate_type === 'WON') {
                this.props.history.push(
                  `/invoiceDetail/${data.data.LastInsertId}`
                );
              } else {
                customFunctions.setLocalStorage('activeTab', 'Invoices');
                this.props.history.goBack();
              }
            } else {
              this.props.history.push('/invoices');
            }
            this.resetData();
          });
        }
        if (status_estimate_type == 'LOST') {
          swal(
            roleId == 4
              ? 'Estimate declined successfully'
              : 'Estimate lose successfully',
            '',
            'success'
          );
          setTimeout(() => {
            var $this = this;
            $this.props.history.goBack();
          }, 100);
        }
        // if (status_estimate_type == 'SENT') {
        //   this.setState({ isShowLoader: false });
        //   await this.getSendMail();
        //   swal(estimate_type == 'SENT' ? 'Estimate resent successfully' : 'Estimate sent successfully', '', 'success');
        //   setTimeout(() => {
        //     var $this = this;
        //     $this.props.history.goBack();
        //   }, 100);
        // }
        return;
      }
      swal(
        data.data && data.data.data
          ? data.data.data
          : 'Estimate generated for all variant successfully',
        '',
        'success'
      );
      this.resetData();
      setTimeout(() => {
        var $this = this;
        $this.props.history.goBack();
      }, 100);
      return;
    } else {
      swal(message, '', 'error');
      this.setState({
        isShowLoader: false,
      });
    }
  };

  getSendMail = async () => {
    const { parentId, estimateId } = this.state;
    this.setState({
      isShowLoader: true,
    });
    const postData = {
      parent_id: Number(parentId),
    };
    const callback = AuthApi.putDataToServer;
    const endPoint = Api.sendEmailUrl;

    const { data, message } = await callback(endPoint, postData);
    if (data) {
      swal(data.data, '', 'success');
      this.setState({
        isShowLoader: false,
      });
    } else {
      swal(message, '', 'error');
      this.setState({
        isShowLoader: false,
      });
    }
    this.setState(
      {
        status_estimate_type: '',
      },
      () => {
        this.getEstimateDetailsData(estimateId);
      }
    );
  };

  changeStatus = (e, i) => {
    this.setState({ isEstimateChecked: e.target.checked ? true : false });
  };

  components = {
    DropdownIndicator: null,
  };

  handleImageFileUpload = async (e) => {
    const { imgBucket } = this.state;
    let addedFiles = e.target.files;
    this.setState({ addedFiles });

    let uploadedImages = imgBucket || [];
    let keys = Object.keys(addedFiles);
    if (!keys.length) return;
    this.setState({ uploadComplete: false });
    for (let i = 0; i < keys.length; i++) {
      const fileRes = await this.uploadImage(addedFiles[keys[i]]);
      if (!fileRes) {
        continue;
      }
      uploadedImages.push({ url: fileRes });
    }
    this.setState({ imgBucket: uploadedImages, uploadComplete: true });
    this.fileInput.value = '';
  };

  uploadImage = async (imageFile) => {
    if (!imageFile) return null;
    const newImage = imageFile;
    const todayDate = new Date().getTime();
    const file = new File(
      [newImage],
      `estimate/${newImage.name.split('.')[0]}${todayDate}.${
        newImage.name.split('.')[1]
      }`,
      { type: newImage.type }
    );
    const imgData = await uploadFile(file, config)
      .then((data) => {
        return data.location;
      })
      .catch((err) => {
        return null;
      });
    return imgData;
  };

  onDeleteHandler = () => {
    const { imgBucket, index } = this.state;
    const buck = [];
    imgBucket.splice(index, 1);
    imgBucket &&
      imgBucket.map((bucket) => {
        //eslint-disable-line
        buck.push(bucket);
        return bucket;
      });
    this.setState({
      imgBucket: buck,
      showDelete: false,
      index: null,
      uploadComplete: true,
    });
  };

  resetData = () => {
    this.setState({
      itemName: '',
      price: '',
      noteDescription: '',
      status_estimate_type: '',
    });
  };

  getSubtotal = () => {
    const lineItems = this.state.lineItems;
    let subTotal =
      lineItems &&
      lineItems.length > 0 &&
      lineItems.reduce(
        (prevValue, curValue) =>
          prevValue +
          parseFloat(curValue.price) *
            parseInt(curValue.quantity ? curValue.quantity : 1),
        0
      );
    return subTotal;
  };

  getTotal = () => {
    const { discount, tax, lineItems, subTotal } = this.state;
    // var res =
    //   lineItems &&
    //   lineItems.length > 0 &&
    //   lineItems.reduce(
    //     (prevValue, curValue) =>
    //       prevValue +
    //       (parseFloat(curValue.price) *
    //         parseInt(curValue.quantity ? curValue.quantity : 1) +
    //         (curValue.isTaxChecked && tax.value && tax.value !== undefined
    //           ? parseFloat(curValue.price) *
    //           parseInt(curValue.quantity ? curValue.quantity : 1) *
    //           (tax.value / 100)
    //           : 0)),
    //     0
    //   );

    // //var totalTax = lineItems && lineItems.length>0 && lineItems.reduce((prevValue, curValue)=> prevValue + (curValue.isTaxChecked && tax.value && tax.value !== undefined && tax.value), 0);

    // if (
    //   discount &&
    //   discount.discountType == 'flat' &&
    //   discount.value !== undefined
    // ) {
    //   res = res - parseFloat(discount.value || 0);
    // }
    // if (
    //   discount &&
    //   discount.discountType == 'percent' &&
    //   discount.value !== undefined
    // ) {
    //   res = res - subTotal * (parseFloat(discount.value || 0) / 100);
    // }
    // return res;
    // var res =
    //   lineItems &&
    //   lineItems.length > 0 &&
    //   lineItems.reduce(
    //     (prevValue, curValue) =>
    //       prevValue +
    //         (curValue.isTaxChecked && tax.value && tax.value !== undefined
    //           ? (parseFloat(curValue.total) * (tax.value / 100))
    //           : 0),
    //     0
    //   );
    // let finalTotal = Number(subTotal) + Number(res);
    var res =
      lineItems &&
      lineItems.length > 0 &&
      lineItems.reduce(
        (prevValue, curValue) =>
          prevValue +
          (parseFloat(curValue.price) *
            parseInt(curValue.quantity ? curValue.quantity : 1) +
            (curValue.isTaxChecked && tax.value && tax.value !== undefined
              ? parseFloat(curValue.total) * (tax.value / 100)
              : 0)),
        0
      );
    if (
      discount &&
      discount.discountType == 'flat' &&
      discount.value !== undefined
    ) {
      res = res - parseFloat(discount.value || 0);
    }
    if (
      discount &&
      discount.discountType == 'percent' &&
      discount.value !== undefined
    ) {
      res = res - res * (parseFloat(discount.value || 0) / 100);
    }
    return res;
  };

  changeLineItemTotal = async () => {
    const { lineItems } = this.state;
    const { tax } = this.state;
    const newLI =
      (await lineItems) &&
      lineItems.map((i) => {
        // if (i.isTaxChecked) {
        //   i.total =
        //     parseFloat(i.price) * parseFloat(i.quantity ? i.quantity : 1) +
        //     (i.isTaxChecked && tax.value && tax.value !== undefined
        //       ? parseFloat(i.price) *
        //       parseFloat(i.quantity ? i.quantity : 1) *
        //       (parseFloat(tax.value) / 100)
        //       : 0);
        // } else {
        //   i.total =
        //     parseFloat(i.price) * parseFloat(i.quantity ? i.quantity : 1);
        // }
        i.total = parseFloat(i.price) * parseFloat(i.quantity ? i.quantity : 1);
        return i;
      });
    this.setState({ lineItems: [...newLI] });
  };

  deleteEstimateData = async () => {
    const { estimateId } = this.state;
    let endPoint = `${Api.deleteEstimate}?estimate_id=${estimateId}`;

    const { data, message } = await AuthApi.deleteDataFromServer(endPoint);
    if (data) {
      swal('Deleted successfully', '', 'success');
      // this.props.history.push('/estimates');
      this.props.history.goBack();
    }
  };

  getImage = (imgs) => {
    let img = imgs && imgs.toUpperCase();
    switch (img) {
      case 'DRAFT':
        return DRAFT;
        break;
      case 'WON':
        return WON;
        break;
      case 'LOST':
        return LOST;
        break;
      case 'SENT':
        return SENT;
        break;
    }
  };

  getLineItem = async (itemId, index) => {
    var { quality, lineItems, qualityOptions, currentQualityOptions } =
      this.state;

    let endPoint = `${Api.getMatchLineItems}?item_id=${itemId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);

    if (data && data.data && data.data.data && data.data.data.length > 0) {
      let currentItem =
        data.data.data[0].items && data.data.data[0].items.length > 0
          ? data.data.data[0].items
          : [];
      this.setState({ qualityData: currentItem });

      let _currentQualityOptions = [];
      let _qualityOptions = qualityOptions;

      if (_qualityOptions && _qualityOptions.length > 0) {
        for (let item of _qualityOptions) {
          let checkItem = lineItems.find((li) => {
            return (
              (li.quality && li.quality.value) === item.value &&
              (li.item && li.item.value) === item.item_id
            );
          });
          item.isset = checkItem ? true : false;
        }
        _currentQualityOptions = _qualityOptions.filter((qo) => {
          return !qo.isset && qo.item_id === itemId;
        });
      }
      let qualityLineItemsObj = {
        item: {
          value: currentItem[0].item_id,
          label: currentItem[0].item_name,
        },
        quality: {
          value:
            _currentQualityOptions &&
            _currentQualityOptions[0] &&
            _currentQualityOptions[0].value,
          label:
            _currentQualityOptions &&
            _currentQualityOptions[0] &&
            _currentQualityOptions[0].label,
        },
        price: currentItem[0].item_price,
        total: currentItem[0].item_price ? currentItem[0].item_price : null,
        isTaxChecked: currentItem[0].taxable == 1 ? true : false,
        lineDiscription: currentItem[0].item_description,
        materialList: currentItem[0].materiallist,
        quantity: currentItem[0].quantity == null ? 1 : currentItem[0].quantity,
        lineContract: currentItem[0].contract_body,
        lineContractId: currentItem[0].contractid,
      };

      let newLineItems = [...lineItems];
      const temp = newLineItems[0];
      newLineItems[0] = {};
      newLineItems[0] = JSON.parse(
        JSON.stringify({ ...temp, ...qualityLineItemsObj })
      );
      if (index == 0) {
        this.setState(
          {
            lineItems: [...newLineItems],
          },
          () => {
            this.getTaxCheck();
            this.forceUpdate();
          }
        );
      }
      this.setState({
        qualityOptions: _qualityOptions,
        currentQualityOptions: _currentQualityOptions,
      });
    } else {
      swal(message, '', 'error');
    }
  };

  getRemovedLineItem = async (qualityRemoved) => {
    const { qualityOptions, lineItems, qualityData } = this.state;
    let _qualityOptions = qualityOptions;
    let _currentQualityOptions = [];
    if (lineItems && lineItems.length > 0) {
      let obj = lineItems[lineItems.length - 1];
      let endPoint =
        obj.item && `${Api.getMatchLineItems}?item_id=${obj.item.value}`;
      const { data, message } = await AuthApi.getDataFromServer(endPoint);
      if (data && data.data && data.data.data && data.data.data.length > 0) {
        let removedItemQualities =
          data.data.data[0].items && data.data.data[0].items.length > 0
            ? data.data.data[0].items
            : [];
        this.setState({ qualityData: removedItemQualities });

        if (_qualityOptions && _qualityOptions.length > 0) {
          let matchedIndex = _qualityOptions.findIndex((item) => {
            return (
              qualityRemoved.quality.value === item.value &&
              qualityRemoved.item.value === item.item_id
            );
          });
          if (matchedIndex !== -1) {
            _qualityOptions[matchedIndex].isset = false;
          }

          matchedIndex = _qualityOptions.findIndex((item) => {
            return (
              obj.quality.value === item.value &&
              obj.item.value === item.item_id
            );
          });

          if (matchedIndex !== -1) {
            _qualityOptions[matchedIndex].isset = false;
          }
        }

        _currentQualityOptions = _qualityOptions.filter((qo) => {
          return !qo.isset && qo.item_id === obj.item.value;
        });
        this.setState({
          qualityOptions: _qualityOptions,
          currentQualityOptions: _currentQualityOptions,
        });
      }
    } else if (lineItems.length == 0) {
      for (let item of _qualityOptions) {
        item.isset = false;
      }
      this.setState({
        qualityOptions: _qualityOptions,
        currentQualityOptions: [],
        tax: '',
      });
    }
  };

  getQualityData = async (quality, index) => {
    const { qualityData, lineItems, qualityOptions } = this.state;

    qualityData &&
      qualityData.map(async (qt) => {
        if (String(qt.quality) === String(quality.value)) {
          lineItems[index].materialList = qt.materiallist;
          lineItems[index].price = qt.item_price;
          lineItems[index].lineDiscription = qt.item_description;
          lineItems[index].isTaxChecked = qt.taxable === 1 ? true : false;
          lineItems[index].lineContract = qt.contract_body;
          lineItems[index].lineContractId = qt.contractid;
        }
        return qt;
      });
    this.setState({ lineItems });
  };

  getTaxCheck = async () => {
    const { lineItems } = this.state;

    const taxfilter =
      lineItems &&
      lineItems.filter((qt) => {
        if (qt.isTaxChecked === true) return qt;
      });
    if (taxfilter.length === 0) {
      this.setState({ tax: '' });
    }
    this.setState({ taxCheck: taxfilter.length > 0 ? true : false });
  };

  render() {
    const {
      paymentTerm,
      paymentTermErr,
      billAddress,
      billEmail,
      itemName,
      price,
      noteDescription,
      title,
      itemId,
      itemNameErr,
      priceErr,
      noteDescriptionErr,
      isEstimateChecked,
      estmtNoteToggle,
      estmtToggle,
      issueDate,
      issueDateErr,
      workStartDate,
      workStartDateErr,
      estimateId,
      disabled,
      lineItems,
      lineItemToggle,
      imgBucket,
      showDelete,
      contactNo,
      selectedOption,
      customersData,
      customersDetailsData,
      jobSelectedOption,
      jobsOptionData,
      jobDetailsData,
      purchaseOptionData,
      purchaseSelectedOption,
      purchaseSelectedOptionErr,
      selectedOptionErr,
      jobSelectedOptionErr,
      show,
      contractTerms,
      modalShow,
      lineItemsOptions,
      billAddressErr,
      billEmailErr,
      contactNoErr,
      lineItemsErr,
      isTaxChecked,
      estimateNo,
      customerName,
      customerEmail,
      customerAddress,
      customerPhone,
      contractName,
      contractBody,
      jobDetailsToggle,
      workType,
      taxData,
      subTotal,
      tax,
      discount,
      total,
      estimate_type,
      invoiceRef,
      status_estimate_type,
      customer_sign,
      tech_sign,
      roleId,
      items,
      showDeleteEstimate,
      customer,
      work_order_id,
      customerId,
      imagePreviewShow,
      imagePreview,
      file,
      invoiceInDraft,
      isShowLoader,
      fromComponent,
      poNo,
      qualityOptions,
      currentQualityOptions,
      quality,
      disabledField,
      termAgree,
      termsAgreeErr,
      signErr,
      paginationOptions,
      taxCheck,
      taxErr,
    } = this.state;
    const paymentTerms = [
      { label: 'COD', value: 'COD' },
      { label: 'Net 30', value: 'Net 30' },
      { label: 'Upon Completion', value: 'Upon Completion' },
      { label: 'Pre-Pay To Begin Work', value: 'Pre-Pay To Begin Work' },
    ];
    const estimateTypeLabel =
      (estimate_type && estimate_type == 'WON' && roleId == 4
        ? 'APPROVE'
        : estimate_type && estimate_type == 'LOST' && roleId == 4
        ? 'DECLINED'
        : estimate_type &&
          (estimate_type !== 'LOST' || estimate_type !== 'WON') &&
          roleId == 4
        ? estimate_type
        : roleId !== 4 && estimate_type) + ':';

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            {showDelete && (
              <SweetAlert
                title="Are you sure, you want to delete?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.onDeleteHandler();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false });
                }}
              >
                You will not be able to revert this!
              </SweetAlert>
            )}
            {showDeleteEstimate && (
              <SweetAlert
                title="Are you sure, you want to delete this estimate?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deleteEstimateData();
                }}
                onCancel={() => {
                  this.setState({ showDeleteEstimate: false });
                }}
              >
                You will not be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body ">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                      autoComplete="off"
                    >
                      <>
                        <div>
                          <Row>
                            {estimateId && (
                              <Col md={6}>
                                <Row>
                                  <Col md={12}>
                                    <img
                                      src={this.getImage(estimate_type)}
                                      style={{ margin: '10px' }}
                                    />
                                    <span style={{ fontSize: '20px' }}>
                                      <b style={{ fontSize: '21px' }}>
                                        {estimateTypeLabel}
                                      </b>{' '}
                                      ESTIMATE #{estimateId}
                                      {/* {estimate_type == 'WON' &&
                                        !invoiceInDraft && (
                                          <a
                                            href="/"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              e.stopPropagation();
                                              this.props.history.push(
                                                `/invoiceDetail/${invoiceRef}`
                                              );
                                            }}
                                          >
                                            {' '}
                                            / View Invoice
                                          </a>
                                        )} 
                                       {(estimate_type === 'DRAFT' ||
                                        estimate_type === 'SENT') && (
                                        <a
                                          href="/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            this.setState(
                                              { status_estimate_type: 'WON' },
                                              () => {
                                                this.addEstimateData();
                                              }
                                            );
                                          }}
                                        >
                                          {' '}
                                          / Win
                                        </a>
                                      )} */}
                                    </span>
                                  </Col>
                                </Row>
                              </Col>
                            )}
                            {estimateId && (
                              <Col md={6}>
                                <Row style={{ float: 'right' }}>
                                  {roleId !== 5 &&
                                    roleId !== 6 &&
                                    (estimate_type === 'DRAFT' ||
                                      estimate_type === 'SENT') && (
                                      <span
                                        className="icon-b"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.setState(
                                            { status_estimate_type: 'WON' },

                                            () => {
                                              if (
                                                lineItems &&
                                                lineItems.length > 0
                                              ) {
                                                if (
                                                  lineItems[0].price === 0 ||
                                                  lineItems[0].price === null
                                                ) {
                                                  this.setState({
                                                    status_estimate_type: '',
                                                  });
                                                  swal(
                                                    'Please enter line item price',
                                                    '',
                                                    'error'
                                                  );
                                                  return;
                                                }
                                              }
                                              if (
                                                customer_sign !== null ||
                                                roleId == 1
                                              ) {
                                                this.addEstimateData();
                                              } else {
                                                swal(
                                                  'Please sign before approve',
                                                  '',
                                                  'error'
                                                );
                                              }
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          width="33"
                                          height="37"
                                          style={{ cursor: 'pointer' }}
                                          src={WIN}
                                        />
                                        <p className="estimate-icons">
                                          {roleId !== 4 ? 'Win' : 'Approve'}
                                        </p>
                                      </span>
                                    )}
                                  {estimate_type === 'WON' &&
                                    !invoiceInDraft && (
                                      <span
                                        className="icon-b"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.props.history.push(
                                            `/invoiceDetail/${invoiceRef}`
                                          );
                                        }}
                                      >
                                        <img
                                          src={INVOICE}
                                          width="35"
                                          height="40"
                                          style={{ cursor: 'pointer' }}
                                        />
                                        <p className="estimate-icons">
                                          View Invoice
                                        </p>
                                      </span>
                                    )}
                                  {roleId !== 5 &&
                                    roleId !== 6 &&
                                    (estimate_type === 'DRAFT' ||
                                      estimate_type === 'SENT') && (
                                      <span
                                        className="icon-b"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.setState(
                                            { status_estimate_type: 'LOST' },
                                            () => {
                                              this.addEstimateData();
                                            }
                                          );
                                        }}
                                      >
                                        <img
                                          src={LOSE}
                                          width="33"
                                          height="37"
                                          style={{ cursor: 'pointer' }}
                                        />
                                        <p className="estimate-icons">
                                          {roleId !== 4 ? 'Lose' : 'Decline'}
                                        </p>
                                      </span>
                                    )}
                                  {roleId == 1 && (
                                    <span
                                      className="icon-b"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (lineItems && lineItems.length > 0) {
                                          if (
                                            lineItems[0].price === 0 ||
                                            lineItems[0].price === null
                                          ) {
                                            this.setState({
                                              status_estimate_type: '',
                                            });
                                            swal(
                                              'Please enter line item price',
                                              '',
                                              'error'
                                            );
                                            return;
                                          }
                                        }
                                        this.setState(
                                          { status_estimate_type: 'SENT' },
                                          () => {
                                            this.getSendMail();
                                          }
                                        );
                                      }}
                                    >
                                      <img
                                        src={SEND}
                                        width="35"
                                        height="40"
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <p className="estimate-icons">
                                        {estimate_type == 'SENT'
                                          ? 'Resend'
                                          : 'Send'}
                                      </p>
                                    </span>
                                  )}
                                  {file && (
                                    <a
                                      href={file}
                                      className="icon-b"
                                      // onClick={(e) => {
                                      //   e.preventDefault();
                                      //   e.stopPropagation();
                                      // }}
                                      target="_blank"
                                      download
                                    >
                                      <img
                                        src={DOWNLOAD}
                                        width="35"
                                        height="40"
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <p className="estimate-icons text-dark">
                                        Download
                                      </p>
                                    </a>
                                  )}
                                  {file && (
                                    <span
                                      className="icon-b"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        customFunctions.printPdf(file);
                                      }}
                                      target="_blank"
                                    >
                                      <img
                                        src={PRINT}
                                        width="35"
                                        height="40"
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <p className="estimate-icons">Print</p>
                                    </span>
                                  )}
                                  {(roleId == 1 || roleId == 2) && (
                                    <span
                                      className="icon-b"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.setState({
                                          showDeleteEstimate: true,
                                        });
                                      }}
                                    >
                                      <img
                                        src={DELETE}
                                        width="35"
                                        height="40"
                                        style={{ cursor: 'pointer' }}
                                      />
                                      <p className="estimate-icons">Delete</p>
                                    </span>
                                  )}
                                </Row>
                              </Col>
                            )}
                            <Col md={8}>
                              <Card>
                                <Card.Header>
                                  <b>ESTIMATE DETAILS</b>
                                  {!estmtToggle &&
                                    estimateId &&
                                    estimate_type !== 'WON' &&
                                    estimate_type !== 'LOST' &&
                                    !disabledField && (
                                      <Button
                                        variant="outline-primary"
                                        style={{
                                          float: 'right',
                                          padding: '0 5px',
                                        }}
                                        onClick={() =>
                                          this.setState({ estmtToggle: true })
                                        }
                                      >
                                        Edit
                                      </Button>
                                    )}
                                </Card.Header>
                                <Card.Body>
                                  <Row>
                                    <Col md={4}>
                                      <Row>
                                        <Col sm={12}>Estimate#</Col>
                                        <Col sm={12}>
                                          {estmtToggle ? (
                                            <input
                                              type="text"
                                              disabled={disabledField}
                                              className="form-control"
                                              value={estimateNo}
                                            />
                                          ) : (
                                            <p>{estimateNo}</p>
                                          )}
                                        </Col>
                                        <Col sm={12} className="mt7">
                                          Issue Date
                                        </Col>
                                        <Col sm={12}>
                                          {estmtToggle ? (
                                            <DatePickerComponent
                                              selected={this.state.issueDate}
                                              onChange={(date) =>
                                                this.setState({
                                                  issueDate: date,
                                                })
                                              }
                                              dateFormat="MM/dd/yyyy"
                                              startDate={this.state.issueDate}
                                              className="form-control"
                                              disabled
                                            />
                                          ) : (
                                            <p>
                                              {this.state.issueDate &&
                                                customFunctions.getMDYDate(
                                                  this.state.issueDate
                                                )}
                                            </p>
                                          )}
                                          <span id="err">{issueDateErr}</span>
                                        </Col>
                                      </Row>
                                    </Col>
                                    <Col md={4}>
                                      <Row>
                                        <Col sm={12}>Po#</Col>
                                        <Col>
                                          {estmtToggle ? (
                                            // <ReactSelect
                                            //   // className="col-sm-10"
                                            //   components={this.components}
                                            //   placeholder="Select PO"
                                            //   value={purchaseSelectedOption}
                                            //   onChange={
                                            //     this.handlePurchaseChange
                                            //   }
                                            //   options={purchaseOptionData}
                                            // />
                                            <input
                                              type="text"
                                              placeholder="PO#"
                                              className="form-control col-sm-12"
                                              value={poNo}
                                              // value={purchaseSelectedOption.value}
                                            />
                                          ) : (
                                            <p>{poNo}</p>
                                          )}
                                          {/* <span id="err">{purchaseSelectedOptionErr}</span> */}
                                        </Col>
                                        <Col sm={12} className="mt7">
                                          Work Start Date
                                        </Col>
                                        <Col sm={12}>
                                          {estmtToggle ? (
                                            <DatePickerComponent
                                              selected={
                                                this.state.workStartDate
                                              }
                                              onChange={(date) =>
                                                this.setState({
                                                  workStartDate: date,
                                                })
                                              }
                                              dateFormat="MM/dd/yyyy"
                                              startDate={
                                                this.state.workStartDate
                                              }
                                              // endDate={workStartDate}
                                              // minDate={issueDate}
                                              className="form-control"
                                              disabled
                                            />
                                          ) : (
                                            <p>
                                              {this.state.workStartDate &&
                                                customFunctions.getMDYDate(
                                                  this.state.workStartDate
                                                )}
                                            </p>
                                          )}
                                          <span id="err">
                                            {workStartDateErr}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Col>
                                  </Row>

                                  <Row className="mt7">
                                    <Col sm={8}>Payment Terms</Col>
                                    <Col sm={8}>
                                      {estmtToggle ? (
                                        <SelectStaticDropdown
                                          onSelectChange={(e) => {
                                            this.setState(
                                              {
                                                paymentTerm: e
                                                  ? { name: e.name }
                                                  : '',
                                              },
                                              () => {
                                                this.forceUpdate();
                                              }
                                            );
                                          }}
                                          className="w-100"
                                          isSearch={false}
                                          value={paymentTerm}
                                          isClearable={paymentTerm}
                                          dropdownType="paymentTerm"
                                          selectPlaceholder="Select Payment Terms"
                                        />
                                      ) : (
                                        <p>
                                          {paymentTerm ? paymentTerm.name : ''}
                                        </p>
                                      )}
                                      <span id="err">{paymentTermErr}</span>
                                    </Col>
                                  </Row>

                                  {estmtToggle && (
                                    <Row className="mt7">
                                      <Col sm={12}>Bill From Address </Col>
                                      <Col sm={8}>
                                        <textarea
                                          className="form-control"
                                          placeholder="Address"
                                          type="textarea"
                                          disabled
                                          id="billAddress"
                                          rows="3"
                                          cols="190"
                                          value="USA"
                                          // value={billAddress}
                                          onChange={(e) => {
                                            this.setState({
                                              billAddress: e.target.value,
                                            });
                                          }}
                                        />
                                        <span id="err">{billAddressErr}</span>
                                      </Col>
                                    </Row>
                                  )}

                                  <Row className="mt7">
                                    <Col sm={12}>Bill From Phone Number</Col>
                                    <Col sm={12}>
                                      {estmtToggle ? (
                                        <PhoneInput
                                          name="contactNo"
                                          country="us"
                                          disabled
                                          onlyCountries={['in', 'us']}
                                          placeholder="Contact No"
                                          value="(833) 504-0353"
                                          // value={contactNo}
                                          onInputChange={(e) =>
                                            this.setState({ contactNo: e })
                                          }
                                        />
                                      ) : (
                                        <p>(833) 504-0353</p>
                                      )}
                                      <span id="err">{contactNoErr}</span>
                                    </Col>
                                  </Row>

                                  <Row className="mt7">
                                    <Col sm={12}>Bill From Email</Col>
                                    <Col sm={12}>
                                      {estmtToggle ? (
                                        <input
                                          type="text"
                                          className="form-control col-sm-8"
                                          value="qualityhvacstatus@gmail.com"
                                          // value={billEmail}
                                          placeholder="Bill Email"
                                          disabled
                                          id="email"
                                          onChange={(e) => {
                                            this.setState({
                                              billEmail: e.target.value,
                                            });
                                          }}
                                        />
                                      ) : (
                                        <p>qualityhvacstatus@gmail.com</p>
                                      )}
                                      <span id="err">{billEmailErr}</span>
                                    </Col>
                                  </Row>

                                  <Row className="mt7">
                                    {estimateId && (
                                      <Col sm={12}>
                                        Share In Customer Portal{' '}
                                      </Col>
                                    )}
                                    <Col sm={1}>
                                      <React.Fragment>
                                        <input
                                          type="checkbox"
                                          id="switch"
                                          switch="info"
                                          disabled={disabledField}
                                          checked={isEstimateChecked}
                                          value={isEstimateChecked}
                                          onChange={(e) => {
                                            this.changeStatus(e);
                                          }}
                                        />
                                        <label
                                          htmlFor="switch"
                                          data-on-label=""
                                          data-off-label=""
                                        ></label>
                                      </React.Fragment>
                                    </Col>{' '}
                                    <Col sm={6} style={{ marginLeft: '2%' }}>
                                      {isEstimateChecked
                                        ? `Yes, show in customer portal`
                                        : `No, do not show in customer portal`}
                                    </Col>
                                  </Row>

                                  {estmtToggle && estimateId && (
                                    <div style={{ float: 'right' }}>
                                      <Button
                                        variant="primary"
                                        className="saveBtns"
                                        style={{ marginLeft: '10px' }}
                                        type="button"
                                        onClick={() => {
                                          this.setState(
                                            {
                                              estmtToggle: false,
                                              swalMsg: 'Estimate Details',
                                            },
                                            () => {
                                              this.validateForm(e);
                                            }
                                          );
                                        }}
                                      >
                                        Save
                                      </Button>
                                      <Button
                                        variant="secondary"
                                        className="l-m-10 saveBtns"
                                        onClick={() =>
                                          this.setState({ estmtToggle: false })
                                        }
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  )}
                                </Card.Body>
                              </Card>
                            </Col>

                            <Col>
                              <Row>
                                <Col>
                                  <Card
                                    className={!estimateId ? 'cardHeight' : ''}
                                  >
                                    <Card.Header>
                                      <b>
                                        CUSTOMER
                                        {fromComponent !== 'workorder' &&
                                          !estimateId && (
                                            <span className="text-danger">
                                              *
                                            </span>
                                          )}
                                      </b>
                                      {estimateId &&
                                        roleId !== 4 &&
                                        !this.state.customerToggle && (
                                          <Button
                                            variant="outline-primary"
                                            style={{
                                              float: 'right',
                                              padding: '0 5px',
                                            }}
                                            onClick={() =>
                                              this.props.history.push(
                                                `/update-customer/${customerId}`,
                                                {
                                                  customerDetails: customer,
                                                  type: 'customer',
                                                }
                                              )
                                            }
                                          >
                                            Edit
                                          </Button>
                                        )}
                                    </Card.Header>
                                    <Card.Body>
                                      <Row>
                                        <Col>
                                          {!estimateId ? (
                                            <ReactSelect
                                              components={this.components}
                                              placeholder="Select Customer"
                                              isDisabled={
                                                fromComponent == 'workorder'
                                              }
                                              value={selectedOption}
                                              onChange={
                                                this.handleCustomerChange
                                              }
                                              options={customersData}
                                            />
                                          ) : (
                                            <h5>{customerName}</h5>
                                          )}
                                          <span id="err">
                                            {selectedOptionErr}
                                          </span>
                                        </Col>
                                      </Row>

                                      <React.Fragment>
                                        {(customersDetailsData.PhoneNumber1 ||
                                          customerPhone) && (
                                          <Row className="mt7">
                                            <Col md={1}>
                                              <span className="mdi mdi-phone-classic"></span>
                                            </Col>
                                            <Col>
                                              {disabledField ? (
                                                <span>
                                                  {' '}
                                                  {customersDetailsData.PhoneNumber1
                                                    ? customersDetailsData.PhoneNumber1
                                                    : `${customerPhone}`}{' '}
                                                </span>
                                              ) : (
                                                <a
                                                  href={`tel:${
                                                    customersDetailsData.PhoneNumber1
                                                      ? customersDetailsData.PhoneNumber1
                                                      : customerPhone
                                                  }`}
                                                >
                                                  {' '}
                                                  {customersDetailsData.PhoneNumber1
                                                    ? customersDetailsData.PhoneNumber1
                                                    : `${customerPhone}`}
                                                </a>
                                              )}
                                              {/* <span>
                                              {customersDetailsData.PhoneNumber1
                                                ? `${customersDetailsData.PhoneNumber1}`
                                                : `${customerPhone}`}
                                            </span> */}
                                            </Col>
                                          </Row>
                                        )}
                                        {(customersDetailsData.email ||
                                          customerEmail) && (
                                          <Row className="mt5">
                                            <Col md={1}>
                                              <span className="mdi mdi-email-open-outline"></span>
                                            </Col>
                                            <Col>
                                              {disabledField ? (
                                                <span>
                                                  {' '}
                                                  {customersDetailsData.email
                                                    ? customersDetailsData.email
                                                    : `${customerEmail}`}{' '}
                                                </span>
                                              ) : (
                                                <a
                                                  href={`mailto:${
                                                    customersDetailsData.email
                                                      ? customersDetailsData.email
                                                      : customerEmail
                                                  }`}
                                                >
                                                  {' '}
                                                  {customersDetailsData.email
                                                    ? customersDetailsData.email
                                                    : `${customerEmail}`}
                                                </a>
                                              )}
                                            </Col>
                                          </Row>
                                        )}
                                        {(customersDetailsData.AddressLine1_1 ||
                                          customersDetailsData.City1 ||
                                          customersDetailsData.Zip1 ||
                                          customerAddress) && (
                                          <Row className="mt5">
                                            <Col md={1}>
                                              <span className="mdi mdi-home"></span>
                                            </Col>
                                            <Col>
                                              <address>
                                                {customersDetailsData.AddressLine1_1 ||
                                                customersDetailsData.City1 ||
                                                customersDetailsData.Zip1 ? (
                                                  <span>
                                                    {
                                                      customersDetailsData.AddressLine1_1
                                                    }{' '}
                                                    {
                                                      customersDetailsData.AddressLine2_1
                                                    }{' '}
                                                    <br />
                                                    {
                                                      customersDetailsData.City1
                                                    }{' '}
                                                    {
                                                      customersDetailsData.State1
                                                    }
                                                    <br />
                                                    {customersDetailsData.Zip1}
                                                  </span>
                                                ) : (
                                                  `${customerAddress}`
                                                )}
                                              </address>
                                            </Col>
                                          </Row>
                                        )}
                                        {/* {estimateId &&
                                          this.state.customerToggle && (
                                            <Row
                                              className="mt5"
                                              style={{ float: 'right' }}
                                            >
                                              <Col>
                                                <Button
                                                  variant="secondary"
                                                  onClick={() =>
                                                    this.setState({
                                                      customerToggle: false,
                                                    })
                                                  }
                                                >
                                                  Cancel
                                                </Button>
                                              </Col>
                                            </Row>
                                          )} */}
                                      </React.Fragment>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <Card
                                    className={
                                      !estimateId ? 'cardHeight mt-5' : 'mt-5'
                                    }
                                  >
                                    <Card.Header style={{ color: 'black' }}>
                                      <b>JOB DETAILS</b>
                                    </Card.Header>
                                    <Card.Body>
                                      <>
                                        <Row>
                                          <Col>
                                            {!estimateId && (
                                              <ReactSelect
                                                components={this.components}
                                                placeholder="Select Job"
                                                value={jobSelectedOption}
                                                onChange={this.handleJobChange}
                                                options={jobsOptionData}
                                                isDisabled={jobDetailsToggle}
                                              />
                                            )}
                                            <span id="err">
                                              {jobSelectedOptionErr}
                                            </span>
                                          </Col>
                                        </Row>

                                        <Row className="mt7">
                                          <Col
                                            style={{ cursor: 'pointer' }}
                                            onClick={() => {
                                              if (estimateId) {
                                                this.props.history.push(
                                                  `/update-order/${work_order_id}`,
                                                  {
                                                    orderDetails:
                                                      jobDetailsData,
                                                    type: 'orders',
                                                  }
                                                );
                                              }
                                            }}
                                          >
                                            <span>
                                              {jobDetailsData &&
                                              jobDetailsData.WorkOrder
                                                ? jobDetailsData.WorkOrder
                                                : ``}
                                            </span>
                                            <br />
                                            <span>
                                              {jobDetailsData &&
                                              jobDetailsData.job_title
                                                ? jobDetailsData.job_title
                                                : ``}
                                            </span>
                                            <br />

                                            <span>
                                              {jobDetailsData &&
                                              jobDetailsData.WorkOrderType
                                                ? jobDetailsData.WorkOrderType
                                                : `${workType}`}
                                            </span>
                                            <br />
                                            <span>
                                              {jobDetailsData &&
                                              jobDetailsData.CustomerName
                                                ? jobDetailsData.CustomerName
                                                : `${customerName}`}
                                              <br />
                                              <span>
                                                {jobDetailsData &&
                                                jobDetailsData.AddressLine1_1 ? (
                                                  <span>
                                                    {
                                                      jobDetailsData.AddressLine1_1
                                                    }{' '}
                                                    {
                                                      jobDetailsData.AddressLine2_1
                                                    }{' '}
                                                    {jobDetailsData.City1}{' '}
                                                    {jobDetailsData.State1}{' '}
                                                    {jobDetailsData.Zip1}{' '}
                                                  </span>
                                                ) : (
                                                  `${customerAddress}`
                                                )}
                                              </span>
                                            </span>
                                          </Col>
                                        </Row>
                                      </>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={8}>
                              <Row>
                                <Col md={12}>
                                  <Card className="mt-5">
                                    <Card.Header>
                                      <b>LINE ITEMS</b>
                                    </Card.Header>
                                    <Card.Body>
                                      {/* <Row>
                                        <Col md={3}>
                                          <b>Item</b>
                                          <span className="text-danger">*</span>
                                        </Col>
                                        <Col md={2}>
                                          <b>Quality</b>
                                          <span className="text-danger">*</span>
                                        </Col>
                                        <Col
                                          md={2}
                                          style={{
                                            'margin-inline-start': '65px',
                                          }}
                                        >
                                          <b>Quantity</b>
                                          <span className="text-danger">*</span>
                                        </Col>
                                        <Col
                                          md={2}
                                          style={{
                                            'margin-inline-start': '-18px',
                                          }}
                                        >
                                          <b>Price</b>
                                          <span className="text-danger">*</span>
                                        </Col>
                                        <Col
                                          md={1}
                                          style={{
                                            paddingLeft: '0px',
                                            whiteSpace: 'nowrap',
                                          }}
                                        >
                                          <b>Taxable</b>
                                        </Col>
                                        <Col
                                          style={{
                                            paddingLeft: '6px',
                                          }}
                                          md={1}
                                        >
                                          <b>Total</b>
                                        </Col>
                                      </Row>*/}
                                      {estimate_type !== 'WON' &&
                                        estimate_type !== 'LOST' &&
                                        lineItems &&
                                        lineItems.map((add, index) => (
                                          <Row key={`line-item-${index}`}>
                                            <Col md={3}>
                                              <b>Item</b>
                                              <span className="text-danger">
                                                *
                                              </span>
                                              <ReactCreatableSelect
                                                components={this.components}
                                                placeholder="Select Item"
                                                value={add.item}
                                                isDisabled={disabledField}
                                                onChange={async (values) => {
                                                  await this.getLineItem(
                                                    values.value,
                                                    index
                                                  );
                                                  if (index !== 0) {
                                                    let existItem =
                                                      items &&
                                                      (await items.filter(
                                                        (i) =>
                                                          i.item_name ===
                                                          values.label
                                                      ));
                                                    let lineItems = [
                                                      ...this.state.lineItems,
                                                    ];
                                                    if (existItem.length) {
                                                      lineItems[index].price =
                                                        existItem[0].item_price;
                                                      lineItems[
                                                        index
                                                      ].lineDiscription =
                                                        existItem[0].item_description;
                                                    }
                                                    this.setState(
                                                      { lineItems },
                                                      async () => {
                                                        await this.changeLineItemTotal();
                                                        let total =
                                                          await this.getTotal();
                                                        let subTotal =
                                                          await this.getSubtotal();
                                                        this.setState({
                                                          total,
                                                          subTotal,
                                                        });
                                                        await this.getTaxCheck();
                                                      }
                                                    );
                                                    this.handleLineItemChange(
                                                      values,
                                                      index
                                                    );
                                                  }

                                                  let total =
                                                    await this.getTotal();
                                                  let subTotal =
                                                    await this.getSubtotal();
                                                  this.setState({
                                                    total,
                                                    subTotal,
                                                  });
                                                }}
                                                options={lineItemsOptions}
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <b>Quality</b>
                                              <span className="text-danger">
                                                *
                                              </span>
                                              <ReactCreatableSelect
                                                // value={quality}
                                                value={add.quality}
                                                isDisabled={
                                                  (lineItems.length - 1 >
                                                    index &&
                                                    lineItems[index]
                                                      .changeItem) ||
                                                  disabledField
                                                }
                                                onChange={async (values) => {
                                                  this.getQualityData(
                                                    values,
                                                    index
                                                  );
                                                  await this.changeLineItemTotal();
                                                  let total =
                                                    await this.getTotal();
                                                  let subTotal =
                                                    await this.getSubtotal();
                                                  this.setState({
                                                    total,
                                                    subTotal,
                                                  });
                                                  lineItems[index].quality = {
                                                    value: values.value,
                                                    label: values.label,
                                                  };
                                                  this.setState(
                                                    { lineItems },
                                                    async () => {
                                                      await this.getTaxCheck();
                                                    }
                                                  );
                                                }}
                                                options={
                                                  currentQualityOptions &&
                                                  currentQualityOptions
                                                }
                                              />
                                            </Col>
                                            <Col md={2}>
                                              <b>Quantity</b>
                                              <span className="text-danger">
                                                *
                                              </span>
                                              <input
                                                className="form-control"
                                                type="text"
                                                style={{ width: '60px' }}
                                                disabled={disabledField}
                                                value={add.quantity}
                                                onChange={async (e) => {
                                                  let event = e;
                                                  event.target.value =
                                                    customFunctions.numberMasking(
                                                      e.target.value
                                                    );
                                                  add.quantity =
                                                    event.target.value;
                                                  let subTotal =
                                                    await this.getSubtotal();
                                                  this.setState(
                                                    { lineItems, subTotal },
                                                    async () => {
                                                      await this.changeLineItemTotal();
                                                      let total =
                                                        await this.getTotal();
                                                      this.setState({ total });
                                                    }
                                                  );
                                                }}
                                              />
                                            </Col>
                                            <Col
                                              md={5}
                                              style={{
                                                'margin-inline-start':
                                                  'inherit',
                                              }}
                                            >
                                              <Row>
                                                <Col md={4}>
                                                  <b>Price</b>
                                                  <span className="text-danger">
                                                    *
                                                  </span>
                                                  <input
                                                    className="form-control"
                                                    type="text"
                                                    disabled={disabledField}
                                                    style={{ width: '77px' }}
                                                    value={add.price}
                                                    onChange={async (e) => {
                                                      let event = e;
                                                      event.target.value =
                                                        customFunctions.numberMaskingDecimal(
                                                          e.target.value
                                                        );
                                                      add.price =
                                                        event.target.value;
                                                      let subTotal =
                                                        await this.getSubtotal();
                                                      this.setState(
                                                        { lineItems, subTotal },
                                                        async () => {
                                                          await this.changeLineItemTotal();
                                                          let total =
                                                            await this.getTotal();
                                                          this.setState({
                                                            total,
                                                          });
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </Col>
                                                <Col md={4}>
                                                  <b>Taxable</b>
                                                  <input
                                                    // className="checkbox ae-checkbox"
                                                    className="m-l-10"
                                                    checked={add.isTaxChecked}
                                                    // style={{
                                                    //   'margin-inline-start':
                                                    //     '50px',
                                                    // }}
                                                    value={isTaxChecked}
                                                    type="checkbox"
                                                    id="example-checkbox-input"
                                                    onChange={async (e) => {
                                                      add.isTaxChecked =
                                                        e.target.checked;
                                                      this.setState(
                                                        { lineItems },
                                                        async () => {
                                                          await this.changeLineItemTotal();
                                                          let total =
                                                            await this.getTotal();
                                                          this.setState({
                                                            total,
                                                            // tax: '',
                                                          });
                                                          await this.getTaxCheck();
                                                        }
                                                      );
                                                    }}
                                                  />
                                                </Col>
                                                <Col md={3}>
                                                  <b>Total</b>
                                                  <p
                                                  // style={{
                                                  //   'margin-inline-start':
                                                  //     '55px',
                                                  // }}
                                                  >
                                                    {' '}
                                                    $
                                                    {add.total
                                                      ? Number(
                                                          add.total
                                                        ).toFixed(2)
                                                      : 0}{' '}
                                                  </p>
                                                </Col>
                                                <Col md={1}>
                                                  {!disabledField && (
                                                    <i
                                                      className="icon-container mdi mdi-close mt-2"
                                                      style={{
                                                        cursor: 'pointer',
                                                        fontSize: '22px',
                                                      }}
                                                      onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        this.removeItems(index);
                                                        this.getRemovedLineItem(
                                                          add
                                                        );
                                                      }}
                                                    />
                                                  )}
                                                </Col>
                                              </Row>
                                            </Col>
                                            {roleId == 1 && (
                                              <Col sm={6}>
                                                <b>Material List</b>
                                                <textarea
                                                  style={{ marginTop: '10px' }}
                                                  className="form-control"
                                                  placeholder="Material List"
                                                  type="textarea"
                                                  disabled={disabledField}
                                                  id="descr"
                                                  rows="4"
                                                  cols="100"
                                                  value={add.materialList}
                                                  onChange={(e) => {
                                                    add.materialList =
                                                      e.target.value;
                                                    this.setState({
                                                      lineItems,
                                                    });
                                                  }}
                                                />
                                              </Col>
                                            )}
                                            <Col sm={6}>
                                              <b>Description</b>
                                              <textarea
                                                style={{ marginTop: '10px' }}
                                                className="form-control"
                                                placeholder="Description"
                                                type="textarea"
                                                disabled={disabledField}
                                                id="descr"
                                                rows="4"
                                                cols="100"
                                                value={add.lineDiscription}
                                                onChange={(e) => {
                                                  add.lineDiscription =
                                                    e.target.value;
                                                  this.setState({ lineItems });
                                                }}
                                              />
                                            </Col>
                                            {add.lineContract && (
                                              <Col sm={12}>
                                                <b>Contract</b>
                                                <textarea
                                                  style={{ marginTop: '10px' }}
                                                  className="form-control"
                                                  placeholder="Contract"
                                                  type="textarea"
                                                  disabled
                                                  id="descr"
                                                  rows="6"
                                                  cols="400"
                                                  value={add.lineContract}
                                                />
                                              </Col>
                                            )}
                                            <Col sm={12}>
                                              <hr />
                                            </Col>
                                          </Row>
                                        ))}
                                      <span id="err">{lineItemsErr}</span>

                                      {/* below code is to show when estimate type is WON */}
                                      {(estimate_type === 'WON' ||
                                        estimate_type === 'LOST') &&
                                        lineItems && (
                                          <>
                                            {lineItems.map((add, index) => (
                                              <Row key={`line-item-${index}`}>
                                                <Col md={3}>
                                                  <b>Item</b>
                                                  <p
                                                    style={{
                                                      paddingTop: '10px',
                                                    }}
                                                  >
                                                    {add.item.label}
                                                  </p>
                                                </Col>
                                                <Col md={2}>
                                                  <b>Quality</b>
                                                  <p
                                                    style={{
                                                      paddingTop: '10px',
                                                    }}
                                                  >
                                                    {add.quality.label}
                                                  </p>
                                                </Col>
                                                <Col md={2}>
                                                  <b>Quantity</b>
                                                  <p
                                                    style={{
                                                      paddingTop: '10px',
                                                    }}
                                                  >
                                                    {add.quantity}
                                                  </p>
                                                </Col>
                                                <Col
                                                  md={5}
                                                  style={{
                                                    'margin-inline-start':
                                                      'inherit',
                                                  }}
                                                >
                                                  <Row>
                                                    <Col md={4}>
                                                      <b>Price</b>
                                                      <p
                                                        style={{
                                                          paddingTop: '10px',
                                                          width: '95px',
                                                        }}
                                                      >
                                                        ${add.price}
                                                      </p>
                                                    </Col>
                                                    <Col md={4}>
                                                      <b>Taxable</b>
                                                      <p
                                                        style={{
                                                          width: '35px',
                                                          paddingTop: '10px',
                                                        }}
                                                      >
                                                        $
                                                        {add.isTaxChecked &&
                                                        tax.value
                                                          ? tax.value
                                                          : '0'}
                                                      </p>
                                                    </Col>
                                                    <Col md={4}>
                                                      <b>Total</b>
                                                      <p
                                                        style={{
                                                          paddingTop: '10px',
                                                        }}
                                                      >
                                                        $
                                                        {add.total
                                                          ? Number(
                                                              add.total
                                                            ).toFixed(2)
                                                          : 0}{' '}
                                                      </p>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                                {roleId == 1 && (
                                                  <Col sm={6}>
                                                    <b>Material List</b>
                                                    <p
                                                      style={{
                                                        paddingTop: '10px',
                                                      }}
                                                    >
                                                      {add.materialList}
                                                    </p>
                                                  </Col>
                                                )}
                                                <Col sm={6}>
                                                  <b>Description</b>
                                                  <p
                                                    style={{
                                                      paddingTop: '10px',
                                                    }}
                                                  >
                                                    {add.lineDiscription}
                                                  </p>
                                                </Col>
                                                {add.lineContract && (
                                                  <Col sm={12}>
                                                    <b>Contract</b>
                                                    <textarea
                                                      style={{
                                                        marginTop: '10px',
                                                      }}
                                                      className="form-control"
                                                      placeholder="Contract"
                                                      type="textarea"
                                                      disabled
                                                      id="descr"
                                                      rows="6"
                                                      cols="400"
                                                      value={add.lineContract}
                                                    />
                                                  </Col>
                                                )}
                                                <Col sm={12}>
                                                  <hr />
                                                </Col>
                                              </Row>
                                            ))}
                                          </>
                                        )}

                                      <Row className="mt7">
                                        <>
                                          <Col>
                                            {estimate_type !== 'WON' &&
                                              estimate_type !== 'LOST' && (
                                                <>
                                                  {!disabledField && (
                                                    <Button
                                                      variant="link"
                                                      onClick={() =>
                                                        this.addItems()
                                                      }
                                                    >
                                                      <i
                                                        style={{
                                                          color: 'blue',
                                                          marginLeft: '-34%',
                                                          fontSize: '21px',
                                                        }}
                                                        className="mdi mdi-plus-circle"
                                                        id="editDriver"
                                                        title="View agent"
                                                      >
                                                        {' '}
                                                        <span
                                                          style={{
                                                            fontSize: '17px',
                                                          }}
                                                        >
                                                          Add Item
                                                        </span>
                                                      </i>
                                                    </Button>
                                                  )}
                                                  <hr />
                                                </>
                                              )}
                                            <Row>
                                              <Col />
                                              <Col sm={3}>SUBTOTAL</Col>
                                              <Col sm={3} xs={8}>
                                                $
                                                {subTotal
                                                  ? subTotal.toFixed(2)
                                                  : 0}
                                              </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                              <Col />
                                              <Col sm={3}>TAX</Col>
                                              <Col sm={3}>
                                                {estimate_type !== 'WON' &&
                                                estimate_type !== 'LOST' ? (
                                                  <ReactSelect
                                                    value={tax}
                                                    isDisabled={
                                                      disabledField || !taxCheck
                                                    }
                                                    onChange={
                                                      this.handleTaxChange
                                                    }
                                                    options={taxData}
                                                  />
                                                ) : (
                                                  <p>{tax.label}</p>
                                                )}
                                                <span id="err">{taxErr}</span>
                                              </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                              <Col />
                                              <Col sm={3}>DISCOUNT</Col>
                                              <Col
                                                sm={3}
                                                style={{ paddingBottom: '3px' }}
                                              >
                                                {estimate_type !== 'WON' &&
                                                estimate_type !== 'LOST' ? (
                                                  <>
                                                    <button
                                                      type="button"
                                                      disabled={disabledField}
                                                      onClick={() => {
                                                        let disc = {
                                                          ...discount,
                                                        };
                                                        disc.discountType =
                                                          'percent';
                                                        this.setState(
                                                          { discount: disc },
                                                          async () => {
                                                            let total =
                                                              this.getTotal();
                                                            this.setState({
                                                              total,
                                                            });
                                                          }
                                                        );
                                                      }}
                                                      style={{
                                                        width: '50%',
                                                        borderColor: '#fff',
                                                      }}
                                                      className={`btn border ${
                                                        discount.discountType ==
                                                        'percent'
                                                          ? 'btn-primary'
                                                          : 'btn-light'
                                                      }`}
                                                    >
                                                      %
                                                    </button>
                                                    <button
                                                      type="button"
                                                      disabled={disabledField}
                                                      onClick={() => {
                                                        let disc = {
                                                          ...discount,
                                                        };
                                                        disc.discountType =
                                                          'flat';
                                                        this.setState(
                                                          { discount: disc },
                                                          async () => {
                                                            let total =
                                                              this.getTotal();
                                                            this.setState({
                                                              total,
                                                            });
                                                          }
                                                        );
                                                      }}
                                                      style={{
                                                        width: '50%',
                                                        borderColor: '#fff',
                                                      }}
                                                      className={`btn border ${
                                                        discount.discountType ==
                                                        'flat'
                                                          ? 'btn-primary'
                                                          : 'btn-light'
                                                      }`}
                                                    >
                                                      $
                                                    </button>
                                                  </>
                                                ) : (
                                                  <p>
                                                    $
                                                    {discount.value
                                                      ? discount.value
                                                      : '0'}
                                                  </p>
                                                )}
                                              </Col>
                                            </Row>
                                            {estimate_type !== 'WON' &&
                                              estimate_type !== 'LOST' && (
                                                <Row>
                                                  <Col />
                                                  <Col sm={3}></Col>
                                                  <Col sm={3}>
                                                    <div className="input-group-prepend">
                                                      <input
                                                        className=" form-control"
                                                        type="text"
                                                        disabled={
                                                          estimate_type ==
                                                            'WON' ||
                                                          estimate_type ==
                                                            'LOST' ||
                                                          disabledField
                                                        }
                                                        style={{ width: '75%' }}
                                                        value={discount.value}
                                                        onChange={(e) => {
                                                          let event = e;
                                                          event.target.value =
                                                            customFunctions.numberMasking(
                                                              e.target.value
                                                            );
                                                          let disc = {
                                                            ...discount,
                                                          };
                                                          disc.value =
                                                            event.target.value;
                                                          this.setState(
                                                            { discount: disc },
                                                            () => {
                                                              let total =
                                                                this.getTotal();
                                                              this.setState({
                                                                total,
                                                              });
                                                            }
                                                          );
                                                        }}
                                                      />
                                                      <div
                                                        className="input-group-text"
                                                        style={{
                                                          backgroundColor:
                                                            'white',
                                                          width: '38px',
                                                        }}
                                                      >
                                                        {discount.discountType ==
                                                        'percent'
                                                          ? '%'
                                                          : '$'}
                                                      </div>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              )}
                                            <hr
                                              sm={2}
                                              style={{
                                                width: '60%',
                                                marginRight: '0%',
                                                textAlign: 'right',
                                              }}
                                            />

                                            <Row>
                                              <Col />
                                              <Col sm={3}>TOTAL</Col>
                                              <Col sm={3}>
                                                $
                                                {total > 0
                                                  ? total.toFixed(2)
                                                  : 0}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </>
                                      </Row>
                                    </Card.Body>
                                  </Card>
                                </Col>
                                <Col md={12}>
                                  <Card className="mt-5">
                                    <>
                                      <Card.Header>
                                        <b>TERMS</b>
                                        {estimate_type !== 'WON' &&
                                          estimate_type !== 'LOST' &&
                                          !disabledField && (
                                            <Button
                                              variant="outline-primary"
                                              style={{
                                                float: 'right',
                                                padding: '0 5px',
                                              }}
                                              onClick={() =>
                                                this.setState({
                                                  modalShow: true,
                                                })
                                              }
                                            >
                                              {estimateId
                                                ? `Edit`
                                                : `Edit Term`}
                                            </Button>
                                          )}
                                      </Card.Header>
                                      <Card.Body>
                                        <div>
                                          {contractTerms.contract_body ? (
                                            <div>
                                              <h6>
                                                {' '}
                                                {
                                                  contractTerms.contract_name
                                                }{' '}
                                              </h6>
                                              <p style={{ fontSize: '14px' }}>
                                                {contractTerms.contract_body}
                                              </p>
                                            </div>
                                          ) : (
                                            <div>
                                              <h6> {contractName} </h6>
                                              <p style={{ fontSize: '14px' }}>
                                                {contractBody}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      </Card.Body>
                                    </>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={4}>
                              <Row>
                                <Col>
                                  <Card className="mt-5">
                                    <Card.Header>
                                      <b>SIGNATURE</b>
                                    </Card.Header>
                                    {customer_sign !== null && (
                                      <Card.Body className="text-center">
                                        <img
                                          src={customer_sign.signature_url}
                                          width={100}
                                          height={100}
                                        />
                                      </Card.Body>
                                    )}
                                    {/* {(tech_sign !== '') &&
                                      <Card.Body><img src={tech_sign.signature_url} /></Card.Body>} */}
                                    {roleId !== 4 && !customer_sign && (
                                      <Card.Body>Not signed yet!</Card.Body>
                                    )}

                                    <Card.Body className="text-center">
                                      {roleId === 4 && !customer_sign && (
                                        <>
                                          <SignatureCanvas
                                            penColor="green"
                                            canvasProps={{
                                              width: 250,
                                              height: 250,
                                              className: 'sigCanvas',
                                            }}
                                            ref={(ref) => {
                                              this.sigPad = ref;
                                            }}
                                          />
                                          <span id="err">{signErr}</span>
                                          <div>
                                            <Button
                                              variant="primary"
                                              style={{ marginLeft: '10px' }}
                                              type="reset"
                                              onClick={(e) => {
                                                this.saveSign(e);
                                              }}
                                            >
                                              Save Sign
                                            </Button>
                                            <Button
                                              variant="secondary"
                                              className="saveBtns"
                                              style={{ marginLeft: '10px' }}
                                              type="reset"
                                              onClick={(e) => this.clearSign(e)}
                                            >
                                              Clear
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                    </Card.Body>
                                    {customer_sign !== null && (
                                      <Card.Footer
                                        style={{
                                          textAlign: 'center',
                                          background: '#fff',
                                        }}
                                      >
                                        {customer_sign.signature_date &&
                                          `Signature Date: ${customFunctions.getFormattedDate(
                                            customer_sign.signature_date
                                          )}`}{' '}
                                        <br />
                                        {customer_sign.ipaddress &&
                                          ` IP address:${customer_sign.ipaddress}`}
                                      </Card.Footer>
                                    )}
                                  </Card>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <Card className="mt-5">
                                    <Card.Header>
                                      <b>ESTIMATE NOTE</b>
                                    </Card.Header>
                                    <Card.Body>
                                      {!estmtNoteToggle && (
                                        <p>{noteDescription}</p>
                                      )}
                                      {!estmtNoteToggle && (
                                        <>
                                          {!disabledField && (
                                            <Button
                                              variant="link"
                                              onClick={() =>
                                                this.setState({
                                                  estmtNoteToggle: true,
                                                })
                                              }
                                            >
                                              <i
                                                style={{
                                                  color: 'blue',
                                                  marginLeft: '-34%',
                                                  fontSize: '21px',
                                                }}
                                                className="mdi mdi-plus-circle"
                                                id="editDriver"
                                                title="View agent"
                                              >
                                                {' '}
                                                <span
                                                  style={{ fontSize: '17px' }}
                                                >
                                                  Enter Note
                                                </span>
                                              </i>
                                            </Button>
                                          )}
                                        </>
                                      )}
                                      {estmtNoteToggle && (
                                        <>
                                          <div className="form-group row">
                                            <textarea
                                              className="form-control"
                                              placeholder="Description"
                                              type="textarea"
                                              id="desc"
                                              rows="10"
                                              cols="190"
                                              disabled={disabledField}
                                              value={noteDescription}
                                              onChange={(e) => {
                                                this.setState({
                                                  noteDescription:
                                                    e.target.value,
                                                });
                                              }}
                                            />
                                            <span id="err">
                                              {noteDescriptionErr}
                                            </span>
                                          </div>
                                          <div>
                                            <Button
                                              variant="primary"
                                              style={{ marginLeft: '10px' }}
                                              type="button"
                                              className="saveBtns"
                                              onClick={() => {
                                                this.setState(
                                                  {
                                                    estmtNoteToggle: false,
                                                    swalMsg: 'Notes',
                                                  },
                                                  () => {
                                                    if (estimateId) {
                                                      this.addEstimateData();
                                                    } else {
                                                      swal(
                                                        'Notes saved!',
                                                        '',
                                                        'success'
                                                      );
                                                    }
                                                  }
                                                );
                                              }}
                                            >
                                              Save
                                            </Button>
                                            <Button
                                              variant="secondary"
                                              className="saveBtns"
                                              style={{ marginLeft: '10px' }}
                                              type="reset"
                                              onClick={() =>
                                                this.setState({
                                                  estmtNoteToggle: false,
                                                })
                                              }
                                            >
                                              Cancel
                                            </Button>
                                          </div>
                                        </>
                                      )}
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <Card className="mt-5">
                                    <div className="pb-1 card-header">
                                      <b>ATTACHMENTS</b>
                                      {!disabledField && (
                                        <label
                                          style={{
                                            float: 'right',
                                            color: 'blue',
                                          }}
                                          htmlFor="attachemnt"
                                        >
                                          <span
                                            className="mdi mdi-plus-circle"
                                            style={{
                                              fontSize: '21px',
                                              cursor: 'pointer',
                                            }}
                                          />
                                        </label>
                                      )}
                                    </div>
                                    <Card.Body style={{ padding: '1rem' }}>
                                      <div>
                                        <input
                                          className="form-control imageUpload hide"
                                          disabled={disabledField}
                                          id="attachemnt"
                                          ref={(ref) => (this.fileInput = ref)}
                                          type="file"
                                          multiple
                                          accept=".xls,.txt,.pdf,.doc,image/jpeg,image/png,"
                                          onChange={(e) => {
                                            this.handleImageFileUpload(e);
                                          }}
                                        ></input>
                                      </div>

                                      <div className="attchement-img-preview">
                                        {imgBucket &&
                                          imgBucket.map((props, index) => {
                                            let ext = props.url.substr(
                                              -3,
                                              props.url.lastIndexOf('.')
                                            );
                                            let image = null;
                                            image =
                                              ext == 'pdf' ||
                                              ext == 'doc' ||
                                              ext == 'xls' ||
                                              ext == 'txt'
                                                ? ATTACHMENT
                                                : null;

                                            return (
                                              <span
                                                className="span-container"
                                                key={index}
                                              >
                                                <img
                                                  className="img-container my-10"
                                                  src={
                                                    image ? image : props.url
                                                  }
                                                  alt=""
                                                  style={{
                                                    height: '80px',
                                                    width: '80px',
                                                  }}
                                                  onClick={() =>
                                                    this.setState({
                                                      imagePreviewShow: true,
                                                      imagePreview: props.url,
                                                    })
                                                  }
                                                  title={props.url}
                                                />
                                                {!this.state.editType && (
                                                  <i
                                                    className="icon-container mdi mdi-close"
                                                    style={{
                                                      position: 'absolute',
                                                    }}
                                                    onClick={(e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      this.setState({
                                                        index: index,
                                                        showDelete: true,
                                                      });
                                                    }}
                                                  />
                                                )}
                                              </span>
                                            );
                                          })}
                                      </div>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <h6
                            id="technician2"
                            ref={this.estimateRef}
                            tabIndex="-1"
                          >
                            <input
                              className="mr-3"
                              checked={Number(termAgree) === 1 ? true : false}
                              disabled={
                                roleId !== 4 || (roleId === 4 && customer_sign)
                              }
                              value={termAgree}
                              type="checkbox"
                              onChange={async (e) => {
                                this.setState({ termAgree: e.target.checked });
                              }}
                            />
                            Accept terms and condition{' '}
                          </h6>
                          <span id="err">{termsAgreeErr}</span>
                          {estimate_type !== 'WON' &&
                            estimate_type !== 'LOST' &&
                            !disabledField && (
                              <Row
                                className="mt7"
                                style={{ textAlign: 'center' }}
                              >
                                <Col>
                                  <Button
                                    variant="primary"
                                    type="button"
                                    className="saveBtns"
                                    ref="btn"
                                    onClick={(e) => {
                                      this.validateForm(e);
                                    }}
                                  >
                                    Submit
                                  </Button>
                                  <Button
                                    variant="secondary"
                                    className="l-m-10 saveBtns"
                                    type="reset"
                                    onClick={() => {
                                      if (
                                        this.props &&
                                        this.props.location &&
                                        this.props.location.state &&
                                        this.props.location.state.path
                                      ) {
                                        this.props.history.push(
                                          `${this.props.location.state.path}`,
                                          {
                                            paginationObj: paginationOptions,
                                          }
                                        );
                                      } else {
                                        this.props.history.goBack();
                                      }
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                              </Row>
                            )}
                        </div>
                      </>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {modalShow && (
          <AddTerms
            show={modalShow}
            handleModalCLose={(e) => this.setState({ modalShow: e })}
            onChanges={(id) => {
              this.getParentsData(id);
            }}
          />
        )}
        {imagePreviewShow && imagePreview && (
          <ImagePreview
            imagePreviewShow={imagePreviewShow}
            imagePreview={imagePreview && imagePreview}
            handleClose={() => this.setState({ imagePreviewShow: false })}
          />
        )}
      </AUX>
    );
  }
}
export default withRouter(EstimateDetailsComponent);
