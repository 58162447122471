import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import {
  SetCustomer,
  SetFromDate,
  SetMonth,
  SetMonthYear,
  SetOrderType,
  SetPaymentType,
  SetTechnician,
  SetToDate,
  SetVendor,
  SetWarrantyCode,
  SetWorkComplete,
  SetYear,
  SetSearchType,
  SetFilterType,
  SetWeekType,
} from '../../../action/user.action';
import Loader from '../../../components/Loader';
import Table from '../../../components/Table/simpletable.component';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import Filter from '../Workorder/AddFilter';
import HelmetComponent from '../Title/index';
import { Button } from 'react-bootstrap';

const columns = [
  {
    label: 'ID',
    id: 'id',
    sort: 'disabled',
    isDisplay: false,
    width: 70,
  },
  {
    label: 'Sr. No.',
    id: 'index',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Vendor ID',
    id: 'vendorid',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'Recall',
    id: 'recall',
    sort: 'disabled',
    width: 70,
  },
  {
    label: 'Dispatch #',
    id: 'workorder',
    width: 150,
    sort: 'disabled',
  },
  {
    label: 'Job Title',
    id: 'jobtitle',
    width: 150,
    sort: 'disabled',
  },
  {
    label: 'Date Recieved',
    id: 'dateRecieved',
    sort: 'disabled',
    width: 120,
  },
  {
    label: 'H/O Name',
    id: 'ownerName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Phone Number',
    id: 'phoneNumber',
    sort: 'disabled',
    width: 170,
  },
  {
    label: 'Address',
    id: 'address',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Zipcode',
    id: 'zipcode',
    sort: 'disabled',
    width: 80,
  },
  {
    label: 'Action',
    id: 'actions',
    sort: 'disabled',
    width: 80,
  },
];

class UnscheduleWorkOrders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      orders: [],
      rows: [],
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      currentPage: 1,
      itemsPerPage: 10,
      orderId: null,
      showDelete: false,
      id: null,
      type: '',
      viewType: false,
      viewForm: false,
      showFilter: false,
      technician: '',
      warantyCode: '',
      roleId: null,
      isShowLoader: false,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      paginationObject: {},
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'unschedulejobs-accessunchedulejobs'
    );
    if (this.props.searchType !== 'unschedule-orders') {
      await this.onCancelBtn('unschedule-orders');
      await SetSearchType('unschedule-orders');
    }
    var component =
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.fromComponent;

    if (
      (!isAllowed && this.state.roleId === 4) ||
      (this.state.roleId === 4 && component !== 'profile')
    ) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('Unscheduled/New Job Offers');
    const id = await this.props.match.params.customerId;
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    if (
      this.props &&
      this.props.location.state &&
      this.props.location.state.paginationObj
    ) {
      this.setState(
        {
          roleId: userdata.role_id,
          login_id: userdata.login_id,
          paginationObject: this.props.location.state.paginationObj,
        },
        async () => {
          await this.getWorkOrder();
        }
      );
    } else {
      this.setState(
        { roleId: userdata.role_id, login_id: userdata.login_id },
        async () => {
          await this.getWorkOrder();
        }
      );
    }
    const url_segment = this.props.match.url.split('/');
    if (url_segment && url_segment.includes('orderDetails')) {
      this.setState({ viewType: true });
    }
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.type
    ) {
      this.setState({ type: this.props.location.state.type });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.orderDetails
    ) {
      this.setState({
        orderDetails: await this.props.location.state.orderDetails,
      });
    }
    await this.setState({ id });
  };

  downloadCSV = async () => {
    const { roleId, login_id, keyword } = this.state;

    const {
      technician,
      customer,
      orderType,
      paymentType,
      // WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
      vendorid,
    } = this.props;
    let endPoint = `${Api.downloadcsvAhsUrl}?search=${keyword}&isAuthRequired=0&work_status=Unscheduled,Offered&pausedreason=0`;

    endPoint +=
      [3, 5, 6].includes(roleId) && login_id ? `&technician=${login_id}` : '';
    endPoint += roleId === 4 && login_id ? `&customer=${login_id}` : '';

    endPoint +=
      technician && technician.id ? `&technician=${technician.id}` : '';
    endPoint += customer && customer.id ? `&customer=${customer.id}` : '';
    endPoint +=
      orderType && orderType.name ? `&order_type=${orderType.name}` : '';
    endPoint +=
      paymentType && paymentType.name
        ? `&payment_type=${paymentType.name}`
        : '';
    // endPoint += WorkCompleteValue && WorkCompleteValue.id ? `&work_complete=${WorkCompleteValue.id}` : '';
    endPoint += vendorid && vendorid.id ? `&vendorid=${vendorid.id}` : '';

    endPoint += !month && !year && fromDate ? `&from_date=${new Date(fromDate).toISOString()}` : '';
    endPoint += !month && !year && toDate ? `&to_date=${new Date(toDate).toISOString()}` : '';
    endPoint += month && monthyear ? `&month=${monthyear}-${month}` : '';
    endPoint += year && year ? `&year=${year}` : `&year=${monthyear}`;

    window.open(endPoint, '_blank');
  };

  getWorkOrder = async (currentPage) => {
    const { keyword, pagination, paginationObject } = this.state;
    this.setState({ currentPage, isShowLoader: true, selectedWorkOrderId: [] });
    const page =
      paginationObject && paginationObject.current_page
        ? paginationObject.current_page
        : currentPage || 1;
    const skip = page * pagination.itemsPerPage - pagination.itemsPerPage;
    const itemsPerPage = pagination.itemsPerPage;

    const endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${itemsPerPage}&isAuthRequired=0&search=${keyword}&work_status=Unscheduled,Offered&pausedreason=0`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          orders: data.data.data,
          currentPage: page,
          pagination: newPagination,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({
        isShowLoader: false,
      });
      swal(message, '', 'error');
    }
  };

  onFilterSearch = async (currentPages = '') => {
    this.setState({
      isShowLoader: true,
    });
    const { keyword } = this.state;
    const {
      technician,
      customer,
      orderType,
      paymentType,
      WorkCompleteValue,
      fromDate,
      toDate,
      month,
      monthyear,
      year,
      vendorid,
    } = this.props;

    if (currentPages === 'clear') {
      this.setState({ limit: 10, currentPage: 1 });
      var { pagination } = this.state;

      var limit = 10;
      var currentPage = 1;
    } else {
      var { pagination, currentPage, limit } = this.state;
    }
    this.setState({ currentPage });

    const page = currentPage || 1;
    const skip = page * limit - limit;

    const params = {
      skip: skip,
      limit: pagination.itemsPerPage,
    };

    var endPoint = `${Api.getAhsWorkOrder}?skip=${params.skip}&limit=${params.limit}&isAuthRequired=0&search=${keyword}&work_status=Unscheduled,Offered&pausedreason=0`;
    var str = '';

    str += technician && technician.id ? `&technician=${technician.id}` : '';
    str += customer && customer.id ? `&customer=${customer.id}` : '';
    str += orderType && orderType.name ? `&order_type=${orderType.name}` : '';
    str +=
      paymentType && paymentType.name
        ? `&payment_type=${paymentType.name}`
        : '';
    // str += WorkCompleteValue && WorkCompleteValue.id ? `&work_complete=${WorkCompleteValue.id}` : '';
    str += vendorid && vendorid.id ? `&vendorid=${vendorid.id}` : '';

    str += !month && !year && fromDate ? `&from_date=${customFunctions.setDateToMidNight(fromDate)}` : '';
    str += !month && !year && toDate ? `&to_date=${customFunctions.setDateToMidNight(toDate)}` : '';
    str += month && monthyear ? `&month=${monthyear}-${month}` : '';
    str +=
      year && year ? `&year=${year}` : monthyear ? `&year=${monthyear}` : '';

    const { data } = await AuthApi.getDataFromServer(endPoint + str);
    if (data && data.data) {
      this.setState({ isShowLoader: false });
      const newPagination = {
        current_page: page,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          isShowLoader: false,
          orders: data.data.data,
          // totalCount: data.data.total_count,
          pagination: newPagination,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      this.setState({ isShowLoader: false });
    }
  };

  setTableData = (skip, npage) => {
    this.setState({ currentPage: npage });
    const { orders, limit, currentPage, roleId } = this.state;
    const page = npage;
    const rows = [];
    orders &&
      orders.length &&
      orders.map((orders, i) => {
        orders.Created = customFunctions.changeDateFormatMomentMDY(
          orders.Created
        );
        orders.WorkOrderDate = customFunctions.changeDateFormatMomentMDY(
          orders.WorkOrderDate
        );
        let customerAddress = `${orders.AddressLine1_1} ${orders.AddressLine2_1} ${orders.City1} ${orders.State1} ${orders.Zip1} `;

        rows.push({
          index: (page - 1) * limit + (i + 1),
          workorder: (
            <React.Fragment>
              <a
                href="/"
                className=""
                disabled={orders.WorkOrder ? orders.WorkOrder : ''}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${orders.ID}`, {
                    orderDetails: orders,
                    type: 'orders',
                    path: '/unschedule-orders',
                    paginationObj: this.state.pagination,
                  });
                }}
              >
                {orders.WorkOrder || '-'}
              </a>
            </React.Fragment>
          ),
          id: orders.ID || '-',
          vendorid: orders.refid || '-',
          recall: orders.recall ? 'Yes' : 'No',
          ownerName: orders.CustomerName || '-',
          dateRecieved: orders.WorkOrderDate || '-',
          jobtitle: orders.jobTitle || '-',
          workStatus: orders.WorkStatus,
          phoneNumber: orders.PhoneNumber1 || '-',
          address: customerAddress || '-',
          zipcode: orders.Zip1 || '-',
          actions: (
            <React.Fragment>
              <div
                style={{
                  width: orders.WorkStatus === 'Offered' ? '170px' : '',
                }}
              >
                {orders.WorkStatus === 'Offered' && (
                  <Button
                    variant="success"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.updateToUnschedule(orders.ID);
                    }}
                  >
                    Acknowledge
                  </Button>
                )}
                <a
                  href="/"
                  className={orders.WorkStatus === 'Offered' ? 'px-1' : ''}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.history.push(`/update-order/${orders.ID}`, {
                      orderDetails: orders,
                      type: 'orders',
                      path: '/unschedule-orders',
                      paginationObj: this.state.pagination,
                    });
                  }}
                >
                  <i className="mdi mdi-lead-pencil" title="Edit Order"></i>
                </a>
                {(roleId === 1 || roleId === 2) && (
                  <a
                    href="/"
                    className="px-1"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      this.setState({ showDelete: true, orderId: orders.ID });
                    }}
                  >
                    <i
                      className="mdi mdi-delete text-danger"
                      title="Delete Order"
                    ></i>
                  </a>
                )}
              </div>
            </React.Fragment>
          ),
        });
        return orders;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  updateToUnschedule = async (id) => {
    const postData = {
      work_order_id: id,
      status: 'Offered',
    };
    const endPoint = Api.workOrderAcknowledgement;
    const { data, message } = await AuthApi.putDataToServer(endPoint, postData);
    if (data) {
      swal('Order is unscheduled', '', 'success');
      this.getWorkOrder();
    } else {
      swal(message, '', 'error');
    }
  };

  deleteOrder = async () => {
    const { orderId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getWorkOrder}?work_order_id=${orderId}`
    );
    if (data) {
      this.setState({ showDelete: false, orderId: null }, () => {
        swal('Order deleted!', '', 'success').then(() => {
          this.getWorkOrder();
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getWorkOrder();
  };

  clearSearch = () => {
    this.onCancelBtn();
    this.setState({ keyword: '', orders: [] }, () => {
      this.getWorkOrder();
    });
  };

  onAdvancedSearch = () => {
    this.setState({ showFilter: !this.state.showFilter });
  };

  onCancelBtn = (type = '') => {
    this.setState({ showFilter: false });
    const {
      SetTechnician,
      SetWarrantyCode,
      SetCustomer,
      SetOrderType,
      SetPaymentType,
      SetWorkComplete,
      SetFromDate,
      SetToDate,
      SetMonth,
      SetMonthYear,
      SetYear,
      SetFilterType,
      SetWeekType,
      SetVendor,
    } = this.props;

    SetTechnician('');
    SetWarrantyCode('');
    SetCustomer('');
    SetOrderType('');
    SetPaymentType('');
    // SetWorkComplete('');
    SetFromDate('');
    SetToDate('');
    SetMonth('');
    SetMonthYear('');
    SetYear('');
    SetFilterType('');
    SetWeekType('');
    SetVendor('');
    if (type === 'unschedule-orders') return;
    this.getWorkOrder();
  };

  render() {
    const {
      rows,
      keyword,
      showDelete,
      showFilter,
      isShowLoader,
      roleId,
      pagination,
    } = this.state;

    const { fromComponent, SetSearchType, searchType } = this.props;

    return (
      <AUX>
        <div
          className={
            fromComponent !== 'customerModule' ? 'page-content-wrapper' : ''
          }
        >
          <Loader showLoader={isShowLoader} needFullPage={false} />
          <div
            className={
              fromComponent !== 'customerModule' ? 'container-fluid' : ''
            }
          >
            <HelmetComponent title="Unscheduled/New Job Offers" />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, you want to delete the order ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.deleteOrder();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false });
                  }}
                >
                  You will not be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className={'row'}>
              <div className={'col-12'}>
                <div className="card m-b-20">
                  <div className="card-body">
                    {fromComponent !== 'customerModule' && (
                      <div className="row">
                        <div className="col-sm-8">
                          <div className="form-group row">
                            <div className="col-sm-8">
                              <form
                                onSubmit={this.onSearchSubmit}
                                autoComplete="off"
                              >
                                <div className="row">
                                  <div className="col-sm-6">
                                    <input
                                      className="form-control"
                                      value={keyword}
                                      type="text"
                                      placeholder="Search by H/O name, Dispatch Id"
                                      id="example-text-input"
                                      onChange={(e) => {
                                        this.setState({
                                          keyword: e.target.value,
                                        });
                                        if (!e.target.value) this.clearSearch();
                                      }}
                                      required={true}
                                    />
                                  </div>
                                  <div className="col-sm-6 col-md-6">
                                    <button
                                      type="submit"
                                      className="btn btn-primary waves-effect waves-light"
                                    >
                                      Search
                                    </button>
                                    <button
                                      type="reset"
                                      onClick={() => {
                                        this.clearSearch();
                                      }}
                                      className="btn btn-secondary waves-effect m-l-10"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div className="col-sm-4">
                              <button
                                type="reset"
                                onClick={async () => {
                                  this.onAdvancedSearch();
                                }}
                                className="btn btn-primary waves-effect waves-light m-l-10"
                              >
                                Advance Search
                              </button>
                            </div>
                          </div>
                        </div>
                        {(roleId === 1 || roleId === 2) && (
                          <div className="col-sm-4">
                            <div className="right-actions float-right">
                              {rows && rows.length > 0 && (
                                <button
                                  type="submit"
                                  className="btn btn-primary waves-effect waves-light"
                                  onClick={() => {
                                    this.downloadCSV();
                                  }}
                                >
                                  <i className="mdi mdi-download"></i> CSV
                                </button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {showFilter && (
                      <Filter
                        // onSubmitButtonCallback={() => {
                        //   this.onSubmit();
                        // }}
                        onCancelButtonCallback={() => {
                          this.onCancelBtn();
                        }}
                        onFilterSearch={() => this.onFilterSearch('clear')}
                        // showFilter={showFilter}
                        fromComponent="unscheduleWorkOrder"
                      />
                    )}
                    <Table
                      columns={columns}
                      rows={rows}
                      showPagination
                      paginationProps={{
                        ...pagination,
                        activePage: pagination.current_page,
                        onPageChange: (pageNo) => {
                          this.setState(
                            { currentPage: pageNo, paginationObject: {} },
                            () => {
                              this.onFilterSearch();
                            }
                          );
                        },
                      }}
                      tableClass="table-bordered"
                      dropdownContainerClass="mb-3"
                      emptyRender={() => (
                        <p className="text-center mb-0">No record found</p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

const mapStateToProps = (state) => ({
  technician: state.auth.technician,
  warantyCode: state.auth.warantyCode,
  customer: state.auth.customer,
  orderType: state.auth.orderType,
  paymentType: state.auth.paymentType,
  // WorkCompleteValue: state.auth.WorkCompleteValue,
  fromDate: state.auth.fromDate,
  toDate: state.auth.toDate,
  month: state.auth.month,
  monthyear: state.auth.monthyear,
  year: state.auth.year,
  vendorid: state.auth.vendorid,
  searchType: state.auth.searchType,
  weektype: state.auth.weektype,
});

const mapDispatchToProps = (dispatch) => ({
  SetTechnician: (technician) => dispatch(SetTechnician(technician)),
  SetWarrantyCode: (warantyCode) => dispatch(SetWarrantyCode(warantyCode)),
  SetCustomer: (customer) => dispatch(SetCustomer(customer)),
  SetOrderType: (orderType) => dispatch(SetOrderType(orderType)),
  SetPaymentType: (paymentType) => dispatch(SetPaymentType(paymentType)),
  // SetWorkComplete: (WorkCompleteValue) =>
  //   dispatch(SetWorkComplete(WorkCompleteValue)),
  SetFromDate: (fromDate) => dispatch(SetFromDate(fromDate)),
  SetToDate: (toDate) => dispatch(SetToDate(toDate)),
  SetMonth: (month) => dispatch(SetMonth(month)),
  SetMonthYear: (monthyear) => dispatch(SetMonthYear(monthyear)),
  SetYear: (year) => dispatch(SetYear(year)),
  SetVendor: (vendorid) => dispatch(SetVendor(vendorid)),
  SetSearchType: (searchType) => dispatch(SetSearchType(searchType)),
  SetFilterType: (filtertype) => dispatch(SetFilterType(filtertype)),
  SetWeekType: (weektype) => dispatch(SetWeekType(weektype)),
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnscheduleWorkOrders)
);
