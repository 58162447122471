import React from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import StarRatingComponent from 'react-star-rating-component';

export default function CustomStarRatingsComponent(props) {
  const {
    label,
    onStarClick,
    value,
    name,
    starCount,
    errorMessage,
    containerClass,
    asterisk,
    isEdit = true,
    starColor,
    fromComponent,
  } = props;

  return (
    <Form.Group className={containerClass}>
      <Row>
        {fromComponent !== 'survey' && (
          <Col sm={2}>
            <Form.Label>
              {label}
              <span style={{ color: 'red' }}>{asterisk}</span>
            </Form.Label>
          </Col>
        )}
        <Col>
          <StarRatingComponent
            starCount={starCount}
            onStarClick={(newRating) => {
              if (onStarClick) onStarClick(newRating);
            }}
            value={value}
            name={name}
            starColor={starColor}
            editing={isEdit}
          />
        </Col>
      </Row>
      {Number(starCount) <= 0 && (
        <p style={{ color: 'red' }}> {errorMessage} </p>
      )}
    </Form.Group>
  );
}
