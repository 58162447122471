import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AllPreMaintenances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      premaintences: [],
      rows: [],
      columns: [],
      customerId: null,
      showDelete: false,
      roleId: null,
      login_id: null,
      technicainData: [],
      customerData: [],
      showMaintenance: false,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Preventative Maintenances');
    var columns = [
      {
        label: 'Id',
        field: 'index',
        sort: 'disabled',
      },
      {
        label: 'Technician',
        field: 'technician',
        sort: 'disabled',
      },
      {
        label: 'Contractor',
        field: 'contractor',
        sort: 'disabled',
      },
      {
        label: 'Created',
        field: 'created_at',
        sort: 'disabled',
      },
      {
        label: 'Action',
        field: 'actions',
        sort: 'disabled',
      },
    ];

    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    // const premaintenance =
    //   this.props.history.location.state &&
    //   this.props.history.location.state.premaintenance;

    // const currentDate = new Date();
    // let showMaintenance = false;

    // if(new Date(premaintenance.endDate) < currentDate){
    //   showMaintenance = true;
    //   this.setState({showMaintenance});
    // }
    // if(new Date(premaintenance.endDate) > currentDate && new Date(premaintenance.startDate) < currentDate && premaintenance.isFall === '0'){
    //   showMaintenance = true;
    //   this.setState({showMaintenance});
    // }

    if (userdata.role_id === 5) {
      columns[1].label = 'Customer';
    }
    if (userdata.role_id !== 5) {
      columns[1].label = 'Technician';
    }
    this.setState({ roleId: userdata.role_id, columns }, async () => {
      if (userdata.role_id === 5) {
        await this.getCustomerData();
      }
      if (userdata.role_id !== 5) {
        await this.getTechnicianData();
      }
    });
    if (
      (await this.props) &&
      (await this.props.location) &&
      (await this.props.location.state) &&
      (await this.props.location.state.customerId)
    ) {
      this.setState(
        {
          customerId: this.props.location.state.customerId,
          techId: userdata.login_id,
        },
        () => {
          if (this.state.customerId) {
            this.preMaintenances();
          }
        }
      );
    }
  };

  preMaintenances = async (currentPage) => {
    const { customerId, techId, roleId } = this.state;
    let endPoint = `${Api.getPremaintenance}?`;
    if (roleId !== 5) {
      endPoint += `customer_id=${customerId}`;
    }
    if (techId && roleId === 5) {
      endPoint += `tech_id=${techId}`;
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data && data.data && data.data.data && data.data.data.length) {
      this.setState(
        {
          premaintences: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
  };

  getCustomerData = async () => {
    const endPoint = `${Api.customerUrl}?skip=0&limit=1000`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data) {
      const customerDataOption = [];

      data &&
        data.data &&
        data.data.data.map((cust) => {
          customerDataOption.push({
            value: cust.ID,
            label: cust.CustomerName,
          });
          return cust;
        });

      this.setState({
        customerData: customerDataOption,
      });
    }
  };

  getTechnicianData = async () => {
    const endPoint = `${Api.technicianUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data) {
      const technicianOption = [];

      data &&
        data.data &&
        data.data.data.map((tech) => {
          technicianOption.push({
            value: tech.id,
            label: tech.name,
          });
          return tech;
        });

      this.setState({
        technicainData: technicianOption,
      });
    }
  };

  getTechnicianName = (id) => {
    const { technicainData } = this.state;
    const tech =
      technicainData && technicainData.filter((i) => i.value === id)[0];
    return tech && tech.label;
  };
  getCustomerName = (id) => {
    const { customerData } = this.state;
    const cust = customerData && customerData.filter((i) => i.value === id)[0];
    return cust && cust.label;
  };

  setTableData = () => {
    const { premaintences, roleId } = this.state;
    const rows = [];
    premaintences &&
      premaintences.length > 0 &&
      premaintences.map((premaintence, i) => {
        let tech = '';
        if (roleId !== 5) {
          tech = this.getTechnicianName(premaintence.tech_id);
        }
        if (roleId === 5) {
          tech = this.getCustomerName(premaintence.customer_id);
        }
        rows.push({
          index: premaintence.id,
          technician: tech || '-',
          contractor: premaintence.contractor,
          created_at:
            premaintence.created_at &&
            customFunctions.getFormattedDate(premaintence.created_at),
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/preventative-maintenance`, {
                    customerId: premaintence.customer_id,
                    premaintenceId: premaintence.id,
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  title="Add or Edit Preventative"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return premaintence;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  detelePremaintenance = async () => {
    const { customerId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.deletePremaintenance}?customer_id=${customerId}`
    );
    if (data) {
      this.setState({ showDelete: false, customerId: null }, () => {
        this.getCustomers();
      });
      swal('Customer deleted');
    } else {
      alert(message);
    }
  };

  render() {
    const { rows, columns, showDelete, customerId, roleId } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title="All Customers" />
            {showDelete && (
              <div className="sweetalert">
                <SweetAlert
                  title="Are you sure, do you want to delete the customer ?"
                  error
                  showCancel
                  confirmBtnBsStyle="success"
                  cancelBtnBsStyle="danger"
                  onConfirm={() => {
                    this.detelePremaintenance();
                  }}
                  onCancel={() => {
                    this.setState({ showDelete: false, customerId: null });
                  }}
                >
                  You won't be able to revert this!
                </SweetAlert>
              </div>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-header m-b-20">
                      <div className="left-section"></div>
                      <div className="right-section">
                        <div className="right-actions">
                          {roleId !== 4 && (
                            <button
                              type="submit"
                              className="btn btn-primary waves-effect waves-light"
                              onClick={async () => {
                                this.props.history.push(
                                  `/preventative-maintenance`,
                                  {
                                    customerId: customerId,
                                  }
                                );
                              }}
                            >
                              Add New Preventative Maintenance
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    {/* <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getCustomers}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AllPreMaintenances);
