import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import customFunctions from '../../../helper/customFunctions';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import {
  NotificationContainer,
  NotificationManager,
} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class AddCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      mail: '',
      password: '',
      mobile: '',
      countrycode: '',
      confirmPassword: '',
      fnameErr: '',
      lnameErr: '',
      mailErr: '',
      mobileErr: '',
      countrycodeErr: '',
      passErr: '',
      cpassErr: '',
      roleid: 1,
      customerId: null,
      countries: [],
    };
  }

  componentDidMount = () => {
    const customerId = this.props.match.params.customerId;
    this.setState({ customerId });
    this.getCountries();
    if (customerId) {
      this.getCustomerData(customerId);
      customFunctions.setTitle('Edit Customer');
    } else {
      customFunctions.setTitle('Add Customer');
    }
  };

  getCountries = async () => {
    const { data } = await AuthApi.getDataFromServer(Api.getCountries);
    if (data && data.status !== 'Fail') {
      this.setState({ countries: data.data });
    }
  };

  getCustomerData = async (customerId) => {
    const { data, message } = await AuthApi.getDataFromServer(
      `${Api.getCustomer}${customerId}`
    );
    if (data) {
      this.setState({
        firstname: data.data.first_name,
        lastname: data.data.last_name,
        mail: data.data.email,
        mobile: data.data.mobile,
        countrycode: data.data.countrycode
          ? data.data.countrycode.toString()
          : '',
      });
    } else {
      var $this = this;
      NotificationManager.error(message, '', 1000);
      setTimeout(() => {
        $this.props.history.push('/customers');
      }, 1000);
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const {
      firstname,
      lastname,
      mail,
      mobile,
      countrycode,
      password,
      confirmPassword,
      customerId,
    } = this.state;
    let fnameErr = '',
      lnameErr = '',
      mailErr = '',
      mobileErr = '',
      passErr = '',
      cpassErr = '',
      countrycodeErr = '';
    let isValid = true;
    if (firstname.trim() === '') {
      fnameErr = 'First name is required';
      isValid = false;
    }

    if (lastname.trim() === '') {
      lnameErr = 'Last name is required';
      isValid = false;
    }

    if (mail.trim() === '') {
      mailErr = 'Email is required';
      isValid = false;
    } else if (!customFunctions.validateEmail(mail)) {
      mailErr = 'Invalid email';
    }

    if (countrycode.trim() === '') {
      countrycodeErr = 'Country code is required';
      isValid = false;
    }

    if (String(mobile).trim() === '') {
      mobileErr = 'Mobile is required';
      isValid = false;
    } else if (
      !customFunctions.validatePhoneNumber(
        String('+' + countrycode + ' ' + mobile)
      )
    ) {
      mobileErr = 'Invalid mobile';
      isValid = false;
    }

    if (
      !customerId ||
      (customerId && (password.trim() !== '' || confirmPassword.trim() !== ''))
    ) {
      if (password.trim() === '') {
        passErr = 'Password is required';
        isValid = false;
      }

      if (confirmPassword.trim() === '') {
        cpassErr = 'Password confirmation is required';
        isValid = false;
      } else if (password !== confirmPassword) {
        cpassErr = 'Password did not match';
        isValid = false;
      }
    }

    this.setState({
      fnameErr,
      lnameErr,
      mailErr,
      mobileErr,
      passErr,
      cpassErr,
      countrycodeErr,
    });
    if (isValid) {
      this.addCustomer();
    }
  };

  addCustomer = async () => {
    const {
      firstname,
      lastname,
      mail,
      mobile,
      password,
      roleid,
      customerId,
      countrycode,
    } = this.state;
    const postData = {
      firstname,
      lastname,
      mobile: String(mobile),
      countrycode: parseInt(countrycode),
    };
    const callback = customerId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = customerId ? Api.updateCustomer : Api.addCustomer;
    if (customerId) {
      postData.customerid = parseInt(customerId);
    } else {
      postData.mail = mail;
      postData.password = password;
      postData.roleid = roleid;
      postData.sendemail = 0;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.status !== 'Fail') {
      if (customerId) {
        var $this = this;
        NotificationManager.success('Customer edited', '', 1000);
        setTimeout(() => {
          $this.props.history.push('/customers');
        }, 1000);
        return;
      }
      NotificationManager.success('Customer added');
      this.resetData();
    } else {
      NotificationManager.error(
        (data && data.message) || message || 'Please try again later'
      );
    }
  };

  resetData = () => {
    this.setState({
      firstname: '',
      lastname: '',
      mail: '',
      password: '',
      mobile: '',
      countrycode: '',
      confirmPassword: '',
      fnameErr: '',
      lnameErr: '',
      mailErr: '',
      mobileErr: '',
      passErr: '',
      cpassErr: '',
      roleid: 1,
      countrycodeErr: '',
    });
  };

  render() {
    const {
      firstname,
      lastname,
      mail,
      mobile,
      countrycode,
      password,
      confirmPassword,
      fnameErr,
      lnameErr,
      mobileErr,
      mailErr,
      passErr,
      cpassErr,
      customerId,
      countrycodeErr,
      countries,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <NotificationContainer />
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          First Name*
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={firstname}
                            type="text"
                            placeholder="John"
                            id="example-text-input"
                            onChange={(e) => {
                              this.setState({ firstname: e.target.value });
                            }}
                          />
                          <span id="err">{fnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-search-input"
                          className="col-sm-2 col-form-label"
                        >
                          Last Name*
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={lastname}
                            type="text"
                            placeholder="Doe"
                            id="example-search-input"
                            onChange={(e) => {
                              this.setState({ lastname: e.target.value });
                            }}
                          />
                          <span id="err">{lnameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-email-input"
                          className="col-sm-2 col-form-label"
                        >
                          Email*
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={mail}
                            type="text"
                            autoComplete="false"
                            placeholder="user@example.com"
                            id="example-email-input"
                            onChange={(e) => {
                              this.setState({ mail: e.target.value });
                            }}
                            disabled={customerId}
                          />
                          <span id="err">{mailErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Country Code*
                        </label>
                        <div className="col-sm-5">
                          <select
                            className="form-control"
                            value={countrycode}
                            onChange={(e) => {
                              this.setState({ countrycode: e.target.value });
                            }}
                          >
                            <option value="">Select country code</option>
                            {countries.map((country) => (
                              <option value={country.countrycode}>
                                +{country.countrycode}
                              </option>
                            ))}
                          </select>
                          <span id="err">{countrycodeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-sm-2 col-form-label">
                          Mobile*
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            value={mobile}
                            placeholder="9999999999"
                            onChange={(e) => {
                              var val = e.target.value;
                              var phoneformat = /^\d{1,15}$/;
                              if (val === '' || val.match(phoneformat)) {
                                this.setState({ mobile: val });
                              }
                            }}
                          />
                          <span id="err">{mobileErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-url-input"
                          className="col-sm-2 col-form-label"
                        >
                          Password
                          {!customerId && <React.Fragment>*</React.Fragment>}
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={password}
                            type="password"
                            placeholder="*****"
                            id="example-url-input"
                            onChange={(e) => {
                              this.setState({ password: e.target.value });
                            }}
                          />
                          <span id="err">{passErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <label
                          htmlFor="example-tel-input"
                          className="col-sm-2 col-form-label"
                        >
                          Confirm Password
                          {!customerId && <React.Fragment>*</React.Fragment>}
                        </label>
                        <div className="col-sm-5">
                          <input
                            className="form-control"
                            value={confirmPassword}
                            type="password"
                            placeholder="*****"
                            id="example-tel-input"
                            onChange={(e) => {
                              this.setState({
                                confirmPassword: e.target.value,
                              });
                            }}
                          />
                          <span id="err">{cpassErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/customers');
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(AddCustomer);
