import React, { Component } from 'react';
import { Bar } from 'react-chartjs-2';
import { withRouter } from 'react-router-dom';
import customFunctions from '../../../../helper/customFunctions';
import AUX from '../../../../hoc/Aux_';

class StackedBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barChartData: {},
      barChartOption: {},
      data: this.props.chartData,
      fromComponent: this.props.fromComponent,
      type: this.props.type,
    };
  }

  async componentDidMount() {
    this.getCharts();
  }

  componentWillReceiveProps(props) {
    this.setState({ data: props.chartData }, async () => {
      await this.getCharts();
    });
  }

  getCharts = () => {
    var { data, fromComponent, type } = this.state;
    let labels = '';

    var stackedGroupLabels = [];
    var stackedGroupdataset = [];
    var stackdataset = [];
    var stackedLabels = [];

    const chartLabels = [];

    if (fromComponent === 'review') {
      data &&
        data.length > 0 &&
        data.map((i) => {
          chartLabels.push(i.source_name);
          return i;
        });
    }
    if (fromComponent === 'technician') {
      data &&
        data.length > 0 &&
        data.map((i) => {
          chartLabels.push(i.rating);
          return i;
        });
    }
    if (fromComponent === 'billing-paymentTaken' && type === 'stackedgroup') {
      let obj = { Card: [], Cash: [], eCheck: [] };
      data &&
        data.length > 0 &&
        data.map((i) => {
          // eslint-disable-line
          stackedGroupLabels.push(
            i.date && customFunctions.getMonthDate(i.date)
          );
          obj.Card.push(i.invoices.card);
          obj.Cash.push(i.invoices.cash);
          obj.eCheck.push(i.invoices.check);
          return i;
        });
      var arr1 = ['Card', 'Cash', 'eCheck'];

      var backgroundColor1 = [
        'rgba(111, 184, 255, 1)',
        'rgba(150, 151, 153, 1)',
        'rgba(90, 204, 37, 1)',
      ];
      var hoverBackgroundColor1 = [
        'rgba(111, 184, 255, 0.7)',
        'rgba(150, 151, 153, 0.7)',
        'rgba(90, 204, 37, 0.7)',
      ];

      arr1 &&
        arr1.map((i, idx) => {
          //eslint-disable-line
          stackedGroupdataset.push({
            label: i === 'Card' ? 'Card' : i,
            backgroundColor: backgroundColor1[idx],
            stack: 2,
            hoverBackgroundColor: hoverBackgroundColor1[idx],
            data: obj[i],
          });
          return i;
        });

      //set labels for billing barchart
      labels = {
        label: function (tooltipItem, dataset) {
          let label = '';
          label += `
          <table>
          <tr><td style="padding-left:10px"><h2>$${
            tooltipItem.value
          }</h2></td></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:14px;">${
            data[tooltipItem.index].date &&
            customFunctions.getStringDate(data[tooltipItem.index].date)
          }</span><br>
          <span style="font-size:12px;">${dataset.datasets[
            tooltipItem.datasetIndex
          ].label.toUpperCase()}</span></td></tr>
          `;
          return label;
        },
      };
    }
    if (fromComponent === 'billing-invoiceCreated' && type === 'stackedgroup') {
      let obj = { Balance_outstanding: [], Sent: [], Draft: [], Paid: [] };
      data &&
        data.map((i) => {
          stackedGroupLabels.push(
            i.date && customFunctions.getMonthDate(i.date)
          );
          obj.Balance_outstanding.push(i.invoices.balance_outstanding);
          obj.Sent.push(i.invoices.sent);
          obj.Draft.push(i.invoices.draft);
          obj.Paid.push(i.invoices.paid);
          return i;
        });
      var arr2 = ['Balance_outstanding', 'Sent', 'Draft', 'Paid'];

      var backgroundColor2 = [
        'rgba(255, 129, 140, 1)',
        'rgba(150, 151, 153, 1)',
        'rgba(90, 204, 37, 1)',
        'rgba(255, 200, 165, 1)',
      ];
      var hoverBackgroundColor2 = [
        'rgba(255, 129, 140, 0.7)',
        'rgba(150, 151, 153, 0.7)',
        'rgba(90, 204, 37, 0.7)',
        'rgba(255, 200, 165, 0.7)',
      ];

      arr2 &&
        arr2.map((i, idx) => {
          stackedGroupdataset.push({
            label: i === 'Balance_outstanding' ? 'Balance Outstanding' : i,
            backgroundColor: backgroundColor2[idx],
            stack: 2,
            hoverBackgroundColor: hoverBackgroundColor2[idx],
            // data: [5, 10, 15, 20],
            data: obj[i],
          });
        });

      //set labels for billing stackbarchart
      labels = {
        label: function (tooltipItem, dataset) {
          let label = '';
          label += `
          <table>
          <tr><td style="padding-left:10px"><h2>${
            tooltipItem.value
          }</h2></td></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:14px;">${
            data[tooltipItem.index].date &&
            customFunctions.getStringDate(data[tooltipItem.index].date)
          }</span><br>
          <span style="font-size:12px;">${dataset.datasets[
            tooltipItem.datasetIndex
          ].label.toUpperCase()}</span></td></tr>
          `;
          return label;
        },
      };
    }
    if (fromComponent === 'jobs-jobscreated') {
      var arr3 = [];
      var arrData = [];

      data &&
        data.map((i) => {
          stackedLabels.push(
            i.date ? customFunctions.getMonthDate(i.date) : ''
          );
          i.details &&
            i.details.map((j) => {
              arr3.push(j.comapny_name);
              arrData[j.comapny_name] = [];
              return j;
            });
          return i;
        });
      //below code is to insert empty objects to make similar objs in all details of data
      for (let i = 0; i < data.length; i++) {
        add(data && data[i].details, arr3);
      }
      function add(details, arr3) {
        var found = [];
        var name = '';
        arr3 &&
          arr3.map((i) => {
            found = details && details.filter((el) => el.comapny_name === i);
            name = i;
            if (!found.length)
              details.push({ workorder_count: 0, comapny_name: name });
            return i;
          });
        return details;
      }
      data &&
        data.map((i) => {
          i.details &&
            i.details.map((j) => {
              arrData[j.comapny_name].push(
                j.workorder_count ? j.workorder_count : 0
              );
              return j;
            });
          return i;
        });

      var backgroundColor3 = [
        'rgba(255, 128, 0, 1)',
        'rgba(89, 206, 197, 1)',
        'rgba(242, 21, 164, 1)',
        'rgba(136, 73, 165, 1)',
        'rgba(255, 255, 70, 1)',
        'rgba(10, 25, 128, 1)',
        'rgba(128, 255, 0, 1)',
      ];
      var hoverBackgroundColor3 = [
        'rgba(255, 128, 0, 0.7)',
        'rgba(89, 206, 197, 0.7)',
        'rgba(242, 21, 164, 0.7)',
        'rgba(136, 73, 165, 0.7)',
        'rgba(255, 255, 0, 0.7)',
        'rgba(10, 25, 128, 0.7)',
        'rgba(128, 255, 0, 0.7)',
      ];

      let arrUniqueData = [...new Set(arr3)];

      arrUniqueData &&
        arrUniqueData.map((i, idx) => {
          stackdataset.push({
            label: i,
            backgroundColor: backgroundColor3[idx],
            hoverBackgroundColor: hoverBackgroundColor3[idx],
            data: arrData[i],
          });
          return i;
        });

      //set labels for jobs stackbarchart
      labels = {
        label: function (tooltipItem, dataset) {
          let label = '';
          label += `
          <table>
          <tr><td style="padding-left:10px"><h2>${
            tooltipItem.value
          }</h2></td></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag style="font-size:11px;">WEEK OF </mytag>${
            data[tooltipItem.index].date &&
            customFunctions.getStringDate(data[tooltipItem.index].date)
          }</span><br>
          <span style="font-size:12px;">${dataset.datasets[
            tooltipItem.datasetIndex
          ].label.toUpperCase()}</span></td></tr>
          `;
          return label;
        },
      };
    }
    if (fromComponent === 'billing-avgOfInvoiceCreated') {
      var arr4 = [];
      let arrData = [];

      data &&
        data.map((i) => {
          //eslint-disable-line
          stackedLabels.push(
            i.date ? customFunctions.getMonthDate(i.date) : ''
          );
          i.details &&
            i.details.map((j) => {
              arr4.push(j.comapny_name);
              arrData[j.comapny_name] = [];
              return j;
            });
          return i;
        });
      //below code is to insert empty objects to make similar objs in all details of data
      for (let i = 0; i < data.length; i++) {
        add(data && data[i].details, arr4);
      }
      function add(details, arr4) {
        var found = [];
        var name = '';
        arr4 &&
          arr4.map((i) => {
            found = details && details.filter((el) => el.comapny_name === i);
            name = i;
            if (!found.length) details.push({ average: 0, comapny_name: name });
            return i;
          });
        return details;
      }
      data &&
        data.map((i) => {
          i.details &&
            i.details.map((j) => {
              arrData[j.comapny_name].push(j.average ? j.average : 0);
              return j;
            });
          return i;
        });

      var backgroundColor4 = [
        'rgba(89, 206, 197, 1)',
        'rgba(242, 21, 164, 1)',
        'rgba(136, 73, 165, 1)',
        'rgba(255, 128, 0, 1)',
        'rgba(255, 255, 0, 1)',
        'rgba(128, 255, 0, 1)',
        'rgba(0, 255, 128, 1)',
      ];
      var hoverBackgroundColor4 = [
        'rgba(89, 206, 197, 0.7)',
        'rgba(242, 21, 164, 0.7)',
        'rgba(136, 73, 165, 0.7)',
        'rgba(255, 128, 0, 0.7)',
        'rgba(255, 255, 0, 0.7)',
        'rgba(128, 255, 0, 0.7)',
        'rgba(0, 255, 128, 0.7)',
      ];

      let arrUniqueData = [...new Set(arr4)];

      arrUniqueData &&
        arrUniqueData.map((i, idx) => {
          stackdataset.push({
            label: i,
            backgroundColor: backgroundColor4[idx],
            data: arrData[i],
            hoverBackgroundColor: hoverBackgroundColor4[idx],
          });
          return i;
        });

      //set labels for jobs stackbarchart
      labels = {
        label: function (tooltipItem, dataset) {
          let label = '';
          label += `
          <table>
          <tr><td style="padding-left:10px"><h2>${
            tooltipItem.value
          }</h2></td></tr>
          <tr><td><hr style="margin-left:12px;background: #fff;width: 170px"></td></tr>
          <tr><td style="margin:0px!important"><span style="font-size:12px;"><mytag style="font-size:11px;">WEEK OF </mytag>${
            data[tooltipItem.index].date &&
            customFunctions.getStringDate(data[tooltipItem.index].date)
          }</span><br>
          <span style="font-size:12px;">${dataset.datasets[
            tooltipItem.datasetIndex
          ].label.toUpperCase()}</span></td></tr>
          `;
          return label;
        },
      };
    }

    let barChartData = {
      labels:
        this.props.type === 'stackedgroup' ? stackedGroupLabels : stackedLabels,
      datasets:
        this.props.type === 'stackedgroup' ? stackedGroupdataset : stackdataset,
    };
    let ystep = fromComponent === 'billing-invoiceCreated' ? 5 : 0;

    let barChartOption = {
      responsive: true,
      legend: {
        display: true,
      },
      interaction: {
        intersect: false,
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            gridLines: {
              display: false,
            },
            ticks: {
              beginAtZero: true,
              callback: function (val, index) {
                if (type === 'stackedgroup') {
                  return index % 2 === 0 ? val : '';
                }
                return val;
              },
              color: 'red',
            },
          },
        ],
        yAxes: [
          {
            stacked: true,
            ticks: {
              beginAtZero: true,
              // max: 15,
              // min: 5,
              stepSize: ystep,
              callback: function (value) {
                let res =
                  fromComponent === 'billing-invoiceCreated'
                    ? value
                    : fromComponent === 'billing-paymentTaken'
                    ? value !== 0
                      ? `$${value / 1000}k`
                      : 0
                    : fromComponent === 'billing-avgOfInvoiceCreated'
                    ? value !== 0
                      ? `${value / 1000}k`
                      : 0
                    : value;
                return res;
              },
            },
          },
        ],
      },
      tooltips: {
        mode: 'nearest',
        enabled: false,
        custom: function (tooltipModel) {
          var tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.style.color = '#fff';
            tooltipEl.innerHTML = '<div id="toolTip"></div>';
            document.body.appendChild(tooltipEl);
          }
          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            // var titleLines = tooltipModel.title || [];
            var bodyLines = tooltipModel.body.map(getBody);

            var innerHtml = '<table>';
            bodyLines.forEach(function (body, i) {
              innerHtml += body;
            });
            innerHtml +=
              '</table><div id="tailShadow"></div><div id="tail1"></div>';

            var tableRoot = tooltipEl.querySelector('#toolTip');
            tableRoot.innerHTML = innerHtml;
          }

          // `this` will be the overall tooltip
          var position = this._chart.canvas.getBoundingClientRect();

          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left =
            position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top =
            position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
          tooltipEl.style.padding =
            tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
          tooltipEl.style.pointerEvents = 'none';
        },
        pieceLabel: {
          render: 'label',
          arc: true,
          fontColor: '#000',
          position: 'outside',
        },
        callbacks: labels,
      },
    };
    this.setState({ barChartData, barChartOption });
  };

  render() {
    const { barChartData, barChartOption, fromComponent, width, height } = this.state;

    return (
      <AUX>
        <Bar
          ref="chart"
          data={barChartData}
          width={fromComponent === "jobs-jobscreated" ? width : 100}
          height={fromComponent === "jobs-jobscreated" ? height : 100}
          options={barChartOption}
        />
      </AUX>
    );
  }
}

export default withRouter(StackedBarChart);
