import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddListType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listType: '',
      status: '',
      listTypeErr: '',
      ListTypeId: '',
      title: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-listtype',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('All List Types');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getListType();
      customFunctions.setTitle('Edit List Type');
      this.setState({
        title: `Edit List Type`,
      });
    } else {
      customFunctions.setTitle('Add List Type');
      this.setState({
        title: `Add List Type`,
      });
    }
  };

  getListType = async () => {
    const listTypeData = this.props.location.state.detail;
    this.setState({
      listTypeId: listTypeData.ID,
      listType: listTypeData.ListType,
      status: listTypeData.status,
    });
  };

  addListType = async () => {
    const { listType, listTypeId, status } = this.state;
    const postData = {
      list_type: listType,
    };
    const endPoint = `${Api.AddListTypeUrl}`;
    const callback = listTypeId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (listTypeId) {
      postData.id = parseInt(listTypeId);
      postData.status = status;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      if (listTypeId) {
        swal('List type updated successfully', '', 'success').then(() => {
          this.props.history.push('/listtype', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('List type added successfully', '', 'success').then(() => {
          this.props.history.push('/listtype');
        });
      }
    } else {
      swal(message, '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { listType } = this.state;
    let listtypeErr = '';
    let isValid = true;
    if (listType.trim() === '') {
      listtypeErr = 'List type is required';
      isValid = false;
    }
    this.setState({ listtypeErr });
    if (isValid) {
      this.addListType();
    }
  };

  render() {
    const { listType, listtypeErr, title } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    {/* <div className="row">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-primary waves-effect waves-light float-right"
                          onClick={() => {
                            this.props.history.push('/listtype');
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div> */}
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          List Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="list type"
                            value={listType}
                            onChange={(e) => {
                              this.setState({ listType: e.target.value });
                            }}
                          />
                          <span id="err">{listtypeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/listtype', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddListType);
