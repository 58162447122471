import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../../helper/authApi';
import Api from '../../../../helper/api';
import customFunctions from '../../../../helper/customFunctions';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../../components/Pagination';

class JobsTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: 'Jobs',
      jobs: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      workStatus: this.props.workStatus,
      statuses: '',
      startDate: '',
      endDate: '',
    };
  }
  async componentDidMount() {
    customFunctions.setTitle(this.state.title);
    const { workStatus } = this.state;

    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'JOB ID',
        field: 'workorder',
        width: 130,
      },
      {
        label: 'CREATED DATE',
        field: 'created',
        width: 140,
      },
      {
        label: 'CUSTOMER',
        field: 'customer',
        width: 150,
      },
      {
        label: 'CUSTOMER EMAIL',
        field: 'customerEmail',
        width: 200,
      },
      {
        label: 'CUSTOMER NUMBER',
        field: 'customerNumber',
        width: 180,
      },
      {
        label: 'TITLE',
        field: 'title',
        width: 150,
      },
      {
        label: 'STREET',
        field: 'street',
        width: 150,
      },
      {
        label: 'POSTAL CODE',
        field: 'postalcode',
        width: 150,
      },
      {
        label: 'CITY',
        field: 'city',
        width: 150,
      },
      {
        label: 'STATUS',
        field: 'status',
        width: 150,
      },
      {
        label: 'COMPLETED',
        field: 'workcomplete',
        width: 150,
      },
      {
        label: 'REASON',
        field: 'reason',
        width: 150,
      },
      {
        label: 'LABEL',
        field: 'labe',
        width: 150,
      },
      {
        label: 'NOTE',
        field: 'note',
        width: 150,
      },
      {
        label: 'TYPE',
        field: 'type',
        width: 150,
      },
      {
        label: 'SOURCE',
        field: 'source',
        width: 150,
      },
      {
        label: 'BRAND NAME',
        field: 'brandname',
        width: 150,
      },
      {
        label: 'VIEW JOB',
        field: 'actions',
        width: 150,
      },
    ];
    if (workStatus === 'Paused') {
      columns[13] = {
        label: 'PAUSED REASON',
        field: 'pausereason',
        width: 150,
      };
    }
    this.setState(
      { columns, startDate: this.props.startDate, endDate: this.props.endDate },
      () => {
        this.getWorkOrder();
      }
    );
  }
  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      let status =
        props.statuses && props.statuses.length
          ? props.statuses.join(',').toLowerCase()
          : '';
      this.setState(
        {
          statuses: status,
          startDate: props.startDate,
          endDate: props.endDate,
          workStatus: props.workStatus,
        },
        () => {
          this.getWorkOrder();
        }
      );
    }
  }
  getWorkOrder = async (currentPage) => {
    let { pagination, workStatus, startDate, endDate, statuses } = this.state;
    this.setState({ currentPage });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.getWorkOrder}?skip=${skip}&limit=${pagination.itemsPerPage}&from_date=${new Date(startDate).toISOString()}&to_date=${new Date(endDate).toISOString()}`;
    if (workStatus === 'Paused') {
      endPoint += `&work_status=paused,${statuses}`;
    } else {
      endPoint += statuses ? `&work_status=${statuses}` : '';
    }

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          jobs: data.data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.props.callback();
  };

  setTableData = () => {
    const { jobs, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    jobs &&
      jobs.length &&
      jobs.map((jobs, i) => {
        jobs.Created = customFunctions.changeDateFormatMomentMDY(jobs.Created);
        rows.push({
          index: (page - 1) * limit + (i + 1),
          workorder: jobs.WorkOrder || '-',
          created: jobs.Created || '-',
          customer: jobs.CustomerName || '-',
          customerEmail: (
            <React.Fragment>
              <p style={{ wordBreak: 'break-word' }}>
                {jobs.EmailAddress || '-'}
              </p>
            </React.Fragment>
          ),
          customerNumber: jobs.PhoneNumber1 || '-',
          title: jobs.job_title || '-',
          street: jobs.AddressLine1_1 || '-',
          postalcode: jobs.Zip1 || '-',
          city: jobs.City1 || '-',
          status: jobs.WorkStatus || '-',
          workcomplete: jobs.CompleteDate
            ? customFunctions.changeDateFormatMomentMDY(jobs.CompleteDate)
            : '-',
          reason: jobs.pausedStatus || '-',
          pausereason: jobs.pausedStatus || '-',
          labe: jobs.recall || '-',
          note: jobs.note || '-',
          type: jobs.WorkOrderType || '-',
          source: jobs.source || '-',
          brandname: jobs.source || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${jobs.ID}`, {
                    orderDetails: jobs,
                    type: 'jobs',
                  });
                }}
              >
                <i className="mdi mdi-eye" id="viewJob" title="View Job"></i>
              </a>
            </React.Fragment>
          ),
        });
        return jobs;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  downloadCSVForJobCreatedDateAndStatus = async () => {
    let { startDate, endDate, statuses } = this.state;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${
      Api.downloadCSVForJobCreatedDateAndStatus
    }?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}&work_status=${
      statuses ? statuses : ''
    }`;
    window.open(endPoint, '_blank');
  };

  downloadCSVForJobCreatedDateAndPaused = async () => {
    let { startDate, endDate, statuses } = this.state;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.downloadCSVForJobCreatedDateAndPaused}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}&work_status=paused,${statuses}`;
    window.open(endPoint, '_blank');
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6> {this.props.title}</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                if (
                  this.props.csvType === 'downloadCSVForJobCreatedDateAndStatus'
                ) {
                  this.downloadCSVForJobCreatedDateAndStatus();
                }
                if (
                  this.props.csvType === 'downloadCSVForJobCreatedDateAndPaused'
                ) {
                  this.downloadCSVForJobCreatedDateAndPaused();
                }
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>

        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="400px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getWorkOrder}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(JobsTables);
