import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import $ from 'jquery';
import { Component } from 'react';
var jwtDecode = require('jwt-decode');
const moment = require('moment');
const moment_tz = require('moment-timezone');

class CustomFunctions extends Component {
  jsonParse = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (error) {
      return jsonString;
    }
  };

  validateEmail = (email) => {
    var emailReg =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailReg.test(email);
  };

  validatePhone = (phone) => {
    var phoneformat = /^\d{10}$/; // eslint-disable-line
    return phone.match(phoneformat);
  };

  getMobile = (value) => {
    let newValue = value.split('+');
    if (newValue[1]) {
      let newValueDash = value.split('-');
      let newVal =
        newValueDash.length > 1
          ? newValueDash[1].replace(/\D/g, '')
          : newValue[1].replace(/\D/g, '');
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length > 3) {
        newVal = newVal.substring(0);
      }
      return newValueDash.length > 1
        ? newValueDash[0] + '-' + newVal
        : '+' + newVal;
    } else {
      let newVal = value.replace(/\D/g, '+', '-');
      if (newVal.length === 0) {
        newVal = '';
      } else if (newVal.length) {
        if (!newVal.startsWith('+')) {
          newVal = '+1' + '-' + newVal;
        }
      } else if (newVal.length > 4) {
        newVal = newVal.substring(0);
      }
      return newVal;
    }
  };

  getDate(dateTime, type = '-') {
    //eslint-disable-line
    var newdate = new Date(dateTime);
    var d = newdate.getDate();
    var m = newdate.getMonth() + 1;
    var y = newdate.getFullYear();
    const formattedate =
      y + type + (m <= 9 ? '0' + m : m) + type + (d <= 9 ? '0' + d : d);
    return formattedate;
  }

  getNewDate(dateTime) {
    //eslint-disable-line
    var newdate = new Date(dateTime);
    var d = newdate.getDate();
    var m = newdate.getMonth() + 1;
    var y = newdate.getFullYear();
    const formattedate =
      (d <= 9 ? '0' + d : d) + '-' + (m <= 9 ? '0' + m : m) + '-' + y;
    return formattedate;
  }

  // getMonthDayDate(date) {
  //     var newdate = new Date(dateTime);
  //     var d = newdate.getDate();
  //     var m = newdate.getMonth() + 1;
  //     var y = newdate.getFullYear();

  //     const monthdate = y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
  //   return monthdate;
  // }

  getFormattedDate = (inputDate) => {
    var date = new Date(inputDate);
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();

    var createdNewDate =
      (d <= 9 ? '0' + d : d) + '/' + (m <= 9 ? '0' + m : m) + '/' + y;
    const time =
      (date.getHours() % 12 || 12) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':' +
      date.getSeconds();
    var s = date.getSeconds();
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return createdNewDate + ' ' + time + ' ' + ampm;
  };

  getMDYDate = (inputDate) => {
    var date = new Date(inputDate);
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();

    var createdNewDate =
      (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
    return createdNewDate;
  };

  getMDYDateTime = (inputDate) => {
    var date = new Date(inputDate);
    var d = date.getDate();
    var m = date.getMonth() + 1;
    var y = date.getFullYear();

    var createdNewDate =
      (m <= 9 ? '0' + m : m) + '/' + (d <= 9 ? '0' + d : d) + '/' + y;
    const time =
      (date.getHours() % 12 || 12) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':' +
      date.getSeconds();
    var s = date.getSeconds();
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return createdNewDate + ' ' + time + ' ' + ampm;
    // return createdNewDate;
  };

  formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }

  validatePrice = (price) => {
    var priceformat = /^\d{1,8}(\.\d{0,2})?$/g; // eslint-disable-line
    return price.match(priceformat);
  };

  validateAmount = (amount) => {
    var amountFormat = /^[1-9]\d{0,8}(((,\d{3}){1})?(\.\d{0,2})?)$/;
    return amountFormat.test(amount);
  };

  validateTextField = (input) => {
    var inputFormat = /^[a-zA-Z ]*$/;
    return inputFormat.test(input);
  };

  loadScript = async (src) => {
    const ele = document.getElementById(src);
    if (ele) {
      ele.remove();
    }
    var script = document.createElement('script');
    script.id = src;
    script.src = src;
    script.type = 'text/javascript';
    script.async = false;
    // script.type = "text/babel";
    // script.type = "text/jsx";
    //  document.getElementsByClassName("wrapper")[0].appendChild(script);
    await document.body.appendChild(script);
  };

  setTitle = (title) => {
    $('#now_routing').empty();
    $('#now_routing').append(title);
  };

  setLocalStorage = (key, value) => {
    try {
      localStorage.setItem(key, value);
      return true;
    } catch (error) {
      return false;
    }
  };

  getLocalStorage = (key) => {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return false;
    }
  };

  removeLocalStorage = (key) => {
    try {
      if (typeof key === 'string') return localStorage.removeItem(key);

      if (typeof key === 'object') {
        key.map((item) => {
          return this.removeLocalStorage(item);
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  clearLocalStorage = () => {
    return localStorage.clear();
  };

  getUserData = async () => {
    try {
      const userdata = await localStorage.getItem('userdata');
      const decodedData = jwtDecode(userdata);
      return decodedData;
    } catch (err) {
      return null;
    }
  };

  validateMobileNumber = (number) => {
    var regx = /^[6-9]\d{9}$/;
    return regx.test(number);
  };

  validateNumber = (number) => {
    var digitFormat = /^[0-9\b]+$/;
    return digitFormat.test(number);
  };

  getShipmentStatus = (status) => {
    const objStatus = {
      status: '',
      description: '',
    };

    switch (status) {
      case 'BookingPending':
        objStatus.status = 'Booking pending';
        objStatus.description = 'Booking is pending';
        break;
      case 'BookingDone':
        objStatus.status = 'Booking done';
        objStatus.description = 'Booking is done';
        break;
      case 'OFP':
        objStatus.status = 'Out for pickup';
        objStatus.description = 'Order is out for pickup';
        break;
      case 'OFD':
        objStatus.status = 'Out for delivery';
        objStatus.description = 'Order is out for delivery';
        break;
      case 'Delivered':
        objStatus.status = 'Delivered';
        objStatus.description = 'Order delivered';
        break;
      case 'Undelivered':
        objStatus.status = 'Undelivered';
        objStatus.description = 'Order not delivered';
        break;

      default:
        objStatus.status = 'Booking pending';
        objStatus.description = 'Booking is pending';
    }

    return objStatus;
  };

  validatePhoneNumber(phoneNumber) {
    /*
    Phone number validation using google-libphonenumber
    */
    let valid = false;
    try {
      const phoneUtil = PhoneNumberUtil.getInstance();
      valid = phoneUtil.isValidNumber(phoneUtil.parse(phoneNumber));
    } catch (e) {
      valid = false;
    }
    return valid;
  }

  getValidNumber(phoneNumber) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const parsedNumber = phoneUtil.parse(phoneNumber);
    return phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
  }

  validateUrl = (url) => {
    const urlReg =
      /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/;
    return urlReg.test(url);
  };

  clean(obj) {
    for (var propName in obj) {
      if (!obj[propName]) {
        delete obj[propName];
      }
    }
    return obj;
  }

  checkIfEmpty = (value, type = 'default') => {
    switch (type) {
      case 'A':
        return !value || (value && !value.length);
      case 'O':
        return !Object.keys(value).length;
      default:
        return !value;
    }
  };

  cleanObject = (obj) => {
    const cleanedObject = Object.entries(obj).reduce(
      (a, [k, v]) => (v || v === false || v === 0 ? ((a[k] = v), a) : a), // eslint-disable-line
      {}
    );
    return cleanedObject;
  };

  generateUrl = (url, urlParams = {}) => {
    const searchParams = new URLSearchParams(
      this.cleanObject(urlParams)
    ).toString();
    let apiEndpoint = url;
    if (!this.checkIfEmpty(urlParams, 'O'))
      apiEndpoint = `${apiEndpoint}?${searchParams}`;
    return apiEndpoint;
  };

  setStringifyDataToLocalStorage = (keyname, data) => {
    localStorage.setItem(keyname, JSON.stringify(data));
  };

  getStringifyDataFromLocalStorage = (keyname) => {
    return localStorage.getItem(keyname)
      ? JSON.parse(localStorage.getItem(keyname))
      : '';
  };

  setDataToLocalStorage = (keyname, data, stringify = false) => {
    if (keyname) {
      if (stringify && data && typeof data === 'object') {
        this.setStringifyDataToLocalStorage(keyname, data);
      } else {
        this.setStringDataToLocalStorage(keyname, data);
      }
    }
  };

  getDataFromLocalStorage = (keyname, stringify = false) => {
    if (keyname) {
      if (stringify) {
        return this.getStringifyDataFromLocalStorage(keyname);
      }
      return this.getStringDataFromLocalStorage(keyname);
    }
    return '';
  };

  getStringDataFromLocalStorage = (keyname) => {
    return localStorage.getItem(keyname) || '';
  };

  setStringDataToLocalStorage = (keyname, data) => {
    localStorage.setItem(keyname, data);
  };

  numberMasking(value) {
    let newVal = value ? value.toString().replace(/\D/g, '') : '';
    if (newVal.length === 0) {
      newVal = '';
    }
    return newVal;
  }

  formatDate = (dt, seperator = '-', format = 'ymd') => {
    const dateObj = new Date(dt);
    let month = dateObj.getUTCMonth() + 1; // months from 1-12
    month = month < 10 ? `0${month}` : month;
    let day = dateObj.getDate();
    day = day < 10 ? `0${day}` : day;
    const year = dateObj.getUTCFullYear();
    let newdate;
    switch (format) {
      case 'ymd':
        newdate = year + seperator + month + seperator + day;
        break;
      case 'dmy':
        newdate = day + seperator + month + seperator + year;
        break;
      case 'mdy':
        newdate = month + seperator + day + seperator + year;
        break;
      case 'dM':
        newdate =
          day +
          seperator +
          dateObj.toLocaleString('default', { month: 'short' });
        break;
      case 'Md':
        newdate =
          dateObj.toLocaleString('default', { month: 'short' }) +
          seperator +
          day;
        break;
      case 'dt':
        const time =
          (dateObj.getHours() % 12 || 12) +
          ':' +
          (dateObj.getMinutes() < 10
            ? '0' + dateObj.getMinutes()
            : dateObj.getMinutes());

        const ampm = dateObj.getHours() >= 12 ? 'pm' : 'am';
        newdate = time + '' + ampm;
        break;
      case 'dz':
        let dt = dateObj.toUTCString().split(' ');
        newdate = dt[dt.length - 1];
        break;
      default:
        newdate = year + seperator + month + seperator + day;
        break;
    }
    return newdate;
  };

  formatSimpleDate = (dt) => {
    let dtFormated = dt ? dt.replaceAll('/', '-') : '';
    if (dtFormated) {
      dtFormated = dtFormated.split('-');
      if (dtFormated.length > 0)
        dtFormated = new Date(
          `${dtFormated[2]}-${dtFormated[1]}-${dtFormated[0]}`
        );
    }
    return dtFormated;
  };

  getMyColor = () => {
    let n = (Math.random() * 0xfffff * 1000000).toString(16);
    return '#' + n.slice(0, 6);
  };

  getShortName = (nameString, opt = ' ') => {
    if (!nameString) return nameString;

    const fullName = nameString.split(opt);
    if (fullName.length < 2) return fullName.shift().charAt(0);

    const initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
    return initials.toUpperCase();
  };

  numberMaskingDecimal(value) {
    let newVal = value && !isNaN(Number(value)) ? value : '';
    if (newVal.length === 0) {
      newVal = '';
    }
    return newVal;
  }
  getMonthDate(date) {
    if (!date) return;
    var newdate = new Date(date).toString();
    const formattedate = newdate.split(' ')[1] + ' ' + newdate.split(' ')[2];
    return formattedate;
  }
  getStringDate(date) {
    if (!date) return;
    var newdate = new Date(date).toString();
    const formattedate = newdate.substring(0, 15);
    return formattedate;
  }
  getDateOnly(date) {
    if (!date) return;
    var newdate = new Date(date).toDateString();
    return newdate;
  }
  getTimeOnly(date) {
    if (!date) return;
    var newdate = new Date(date).toLocaleTimeString();
    return newdate;
  }

  getAsDate(day, time) {
    if (!day) return;
    if (!time) return;
    var newDate = new Date(day + ' ' + time);
    return newDate;
  }

  validateZipCode(elementValue) {
    //eslint-disable-line
    var zipCodePattern = /^\d{5}$/;
    return zipCodePattern.test(elementValue);
  }

  validateCard = (url) => {
    var pattern =
      /^(?:(4[0-9]{12}(?:[0-9]{3})?)|(5[1-5][0-9]{14})|(6(?:011|5[0-9]{2})[0-9]{12})|(3[47][0-9]{13})|((?:0[0-5]|[68][0-9])[0-9]{11})|((?:2131|1800|35[0-9]{3})[0-9]{11}))$/; //eslint-disable-line
    return url.match(pattern);
  };

  convertToNumber = (value) => {
    const newVal = value ? value.toString().replace(/\D/g, '') : 0;
    return newVal;
  };

  validateMonth = (value, year) => {
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear().toString().slice(2, 4);
    const selectedYear = year && Number(year);
    if (
      Number(currentYear) === selectedYear &&
      currentMonth + 1 < Number(value)
    ) {
      return Number(value)
        .toString()
        .match(/^([1-9]|0[1-9]|1[0-2])$/);
    }
    if (Number(currentYear) < selectedYear) {
      return value.match(/^([1-9]|0[1-9]|1[0-2])$/);
    }
    return null;
  };

  validateYear = (value) => {
    var d = new Date();
    var n = d.getFullYear().toString().substring(2, 4);
    let newVal = value.replace(/\D/g, '');
    if (this.convertToNumber(newVal) >= this.convertToNumber(n)) {
      return true;
    }
    return false;
  };

  getLastWeek = (dateNow) => {
    var firstDayOfTheWeek = dateNow.getDate() - dateNow.getDay();
    var lastDayOfTheWeek = firstDayOfTheWeek + 6;
    var firstDayOfLastWeek = new Date(dateNow.setDate(firstDayOfTheWeek - 7));
    let d = new Date();
    var lastDayOfLastWeek = new Date(d.setDate(lastDayOfTheWeek - 7));
    return { firstDayOfLastWeek, lastDayOfLastWeek };
  };

  capitalizeFirstLetter = (string) => {
    if (!string) return string;

    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  printPdf = (url) => {
    // var w = window.open(url);
    // w.onload = function () {
    //   w.print();
    // };
    // w.onafterprint = function () {
    //   w.close();
    // };
    var iframe = document.createElement('iframe');
    // Hide the IFrame.
    iframe.style.visibility = 'hidden';
    // Define the source.
    iframe.src = url;
    // Add the IFrame to the web page.
    document.body.appendChild(iframe);
    iframe.contentWindow.focus();
    iframe.contentWindow.print(); // Print.
  };

  getSubString = (str, len = 40) => {
    if (!str) return '';

    return str.substring(0, len) + (str.length > len ? '...' : '');
  };

  diff_Minutes = (startDate, endDate) => {
    var diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  };

  isAllowedRoute = (type = 'module', route, permissionType = 'read') => {
    let routes = this.getStringifyDataFromLocalStorage('permissions');
    let isRouteExist = false;
    if (type == 'module') {
      let isRouteExist =
        routes && routes.module && routes.module.includes(route);
      return isRouteExist;
    }
    if (type == 'submodule') {
      let exists =
        routes &&
        routes.submodule &&
        Object.keys(routes.submodule).includes(route);
      if (exists) {
        isRouteExist = routes.submodule[route].includes(permissionType);
      }
      return isRouteExist;
    }
  };

  isWriteAllowedRoute = (type = 'submodule', route, permissionType) => {
    let routes = this.getStringifyDataFromLocalStorage('permissions');
    let result = false;
    if (type == 'submodule') {
      let isRouteExist =
        routes &&
        routes.submodule &&
        Object.keys(routes.submodule).includes(route);
      if (isRouteExist) {
        result = routes.submodule[route].includes(permissionType);
      }
      return result;
    }
  };

  getDifferenceInDays = (date1, date2) => {
    const diffInMs = Math.abs(date2 - date1);
    return diffInMs / (1000 * 60 * 60 * 24);
  };

  getUTCDate(date1) {
    // Multiply by 1000 because JS works in milliseconds instead of the UNIX seconds
    const date = new Date(date1);

    let year = date.getUTCFullYear();
    let month = date.getUTCMonth() + 1; // getMonth() is zero-indexed, so we'll increment to get the correct month number
    let day = date.getUTCDate();
    let hours = date.getUTCHours();
    let minutes = date.getUTCMinutes();
    // let seconds = date.getUTCSeconds();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    // hours = hours < 10 ? '0' + hours : hours;
    // minutes = minutes < 10 ? '0' + minutes : minutes;
    // seconds = seconds < 10 ? '0' + seconds : seconds;

    return year + '-' + month + '-' + day;
  }

  changeDateFormatMDY(date) {
    return moment(new Date(date)).format('MM/DD/YYYY hh:mm A');
  }

  changeDateOnlyMDY(date) {
    return moment(new Date(date)).format('YYYY-MM-DD');
  }

  changeTimeOnlyHMS(date) {
    return moment(new Date(date)).format('hh:mm A');
  }

  changeDateFormatMomentMDY(date) {
    return moment(new Date(date)).format('MM/DD/YYYY');
  }

  addTimeToDate(date) {
    const tm = new Date().toLocaleTimeString();
    const dt = date ? new Date(date).toDateString() : new Date().toDateString();
    return new Date(dt + ' ' + tm);
  }

  getInstanceDetails() {
    const CCP_URL =
      'https://test-connect-expand.my.connect.aws/ccp-v2/&output=embed';
    const REGION = 'us-east-1';
    return {
      ccpUrl: CCP_URL,
      loginPopup: true,
      loginPopupAutoClose: true,
      softphone: {
        allowFramedSoftphone: true,
      },
      region: REGION,
    };
  }

  getDateWithCurrentTimezone(date) {
    return moment_tz(date)
      .tz(this.getCurrentTimezone())
      .format('MM/DD/YYYY hh:mm A');
  }

  getCurrentTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  changeDateWithCurrentTimezone(str) {
    if (str.split(' at ')[1]) {
      return str.split(' at ')[1].split(' to ');
    }
  }

  reinvoicedate(str) {
    let smp = str;
    let res = smp.split(',');
    if (res && res.length > 0) {
      res.map((result) => {
        result = this.changeDateFormatMomentMDY(result);
      });
      return res.join(',');
    }
  }

  workorderimage(status) {
    switch (status) {
      case 'Scheduled':
        return './assets/images/workstatus/iconSchedule.svg';
      case 'Paused':
        return './assets/images/workstatus/iconPaused.svg';
      case 'Unscheduled':
        return './assets/images/workstatus/iconUnscheduled.svg';
      case 'Offered':
        return './assets/images/workstatus/iconJobOffers.svg';
      case 'Inprogress':
        return './assets/images/workstatus/play.svg';
      case 'Completed':
        return './assets/images/workstatus/completed.svg';
      default:
        return '';
    }
  }

  getTime = (inputDate) => {
    var date = new Date(inputDate);

    const time =
      (date.getHours() % 12 || 12) +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':' +
      date.getSeconds();
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return time + ' ' + ampm;
  };

  timeZoneAbbreviated = () => {
    const { 1: tz } = new Date().toString().match(/\((.+)\)/);
    if (tz.includes(' ')) {
      return tz
        .split(' ')
        .map(([first]) => first)
        .join('');
    } else {
      return tz;
    }
  };

  convertISODatetoOffsetWiseDate = ({
    apptDate = new Date().toISOString(),
    currentFromDate = new Date().toISOString(),
    currentToDate = new Date().toISOString(),
    offsetMins = null,
    format = 'regular',
  }) => {
    try {
      if (!offsetMins) throw 'Offset Invalid';

      let payLoadDate = new Date(apptDate).getTime();
      // let payLoadDate = new Date(currentFromDate).getTime();

      // let offset = Number(offsetMins);

      // let finalDate = new Date(
      //   payLoadDate - Number(offset) * 60000
      // ).toISOString();

      let finalDate = new Date(payLoadDate);

      if (format === 'timestamp') finalDate = new Date(finalDate).getTime();
      // const date1 = new Date(apptDate).toDateString().split(' ');
      const date = new Date(finalDate).toDateString().split(' ');
      const formattedDate = `${date[1]} ${date[2]} / ${this.getTime(
        currentFromDate
      )} - ${this.getTime(currentToDate)} ${this.timeZoneAbbreviated()}`;
      return formattedDate;
    } catch (err) {
      return currentFromDate;
    }
  };

  getMonthDayYear = (dt) => {
    const date = new Date(dt).toDateString().split(' ');
    const formattedDate = `${date[1]} ${date[2]} ${date[3]}`;
    return formattedDate;
  };

  alphaNumericMasking(value) {
    let newVal = value ? value.toString().match('^[a-zA-Z0-9]+$') : '';
    return newVal;
  }

  convertISODatetoOffsetWiseDateForAPI = (date = new Date().toISOString(), format = 'regular') => {
        try {
                let userOffset = this.getLocalStorage('userTimeOffset') || null;
                let offsetMins =  (userOffset > 0) ? userOffset * -1 : userOffset;
                let payLoadDate = '';
                if (!offsetMins) throw 'Offset Invalid';
                if (format == 'regular') payLoadDate = new Date(date).getTime();
                if (format == 'timestamp') payLoadDate = new Date(Number(date));
                let offset = Number(offsetMins);
                let finalDate = new Date(payLoadDate - Number(offset) * 60000);
                return new Date(finalDate).toISOString();
            } catch (err) {
                return new Date(date).toISOString();
        }
    };

    setDateToMidNight = (date) =>{
      var d = new Date(date);
      d.setHours(0,0,0,0);
      return d.toISOString();
    }


}

export default new CustomFunctions();
