import React from 'react';
import { SketchPicker } from 'react-color';

const ColorCodePicker = (props) => {
  const { color, onChangeComplete, disableAlpha } = props;

  return (
    <div>
      <SketchPicker
        color={color}
        onChangeComplete={onChangeComplete}
        disableAlpha={disableAlpha}
      />
    </div>
  );
};

export default ColorCodePicker;
