import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';

class AddWarrantyCode extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      warrantyCode: '',
      warrantyCodeId: '',
      warrantyCodeErr: '',
      warrantyCodeSuccess: '',
      homewrntycodedata: [],
      title: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-homewarrantycode',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getWarrantyCode();
      customFunctions.setTitle('Edit Home Warranty Code');
      this.setState({
        title: `Edit  Home Warranty Code`,
      });
    } else {
      customFunctions.setTitle('Add Home Warranty Code');
      this.setState({
        title: `Add Home Warranty Code`,
      });
    }
  };

  getWarrantyCode = async () => {
    const warrantyCodeData = this.props.location.state.detail;
    this.setState({
      warrantyCodeId: warrantyCodeData.id,
      warrantyCode: warrantyCodeData.code,
      status: warrantyCodeData.status,
    });
  };

  addWarranty = async () => {
    const { warrantyCode, warrantyCodeId, status } = this.state;
    const postData = {
      warranty_code: warrantyCode,
    };
    const endPoint = `${Api.AddwarrentyCodeUrl}`;
    const callback = warrantyCodeId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (warrantyCodeId) {
      postData.warranty_code_id = parseInt(warrantyCodeId);
      postData.status = status;
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (warrantyCodeId) {
        swal('Warranty code edited succssfully', '', 'success');
        setTimeout(() => {
          $this.props.history.push('/home-warrenty-code');
        }, 1000);
      } else {
        swal('Warranty code added successfully', '', 'success');
        setTimeout(() => {
          $this.props.history.push('/home-warrenty-code');
        }, 1000);
      }
    } else {
      swal(message, '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { warrantyCode } = this.state;
    let warrantyCodeErr = '';
    let isValid = true;
    if (warrantyCode.trim() === '') {
      warrantyCodeErr = 'Home warranty code is required';
      isValid = false;
    }
    this.setState({ warrantyCodeErr });
    if (isValid) {
      this.addWarranty();
    }
  };

  flushAllData = () => {
    this.setState({
      warrantyCode: '',
      warrantyCodeId: '',
      warrantyCodeErr: '',
    });
  };

  render() {
    const { title, warrantyCode, warrantyCodeErr } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-primary waves-effect waves-light float-right"
                          onClick={() => {
                            this.props.history.push('/home-warrenty-code');
                          }}
                        >
                          Back
                        </button>
                      </div>
                    </div>
                    <form onSubmit={this.validateForm} autoComplete="off">
                      <div className="form-group row">
                        <label
                          htmlFor="example-text-input"
                          className="col-sm-2 col-form-label"
                        >
                          Home Warranty Code
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-5">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="home warranty code"
                            value={warrantyCode}
                            onChange={(e) => {
                              this.setState({ warrantyCode: e.target.value });
                            }}
                          />
                          <span id="err">{warrantyCodeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/home-warrenty-code');
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddWarrantyCode);
