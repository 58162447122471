import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import Loader from '../../../components/Loader';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class AddContractComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractName: '',
      contractNameErr: '',
      body: '',
      bodyErr: '',
      isCustomerAgree: false,
      isDocumentAttached: false,
      contractId: null,
      editType: false,
      title: '',
      isShowLoader: false,
      contractType: null,
      contractData: [],
      contractTypeErr: '',
    };
  }

  componentDidMount = async () => {
    let isAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-accesscontracts',
      'write'
    );
    if (!isAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    const contractId = this.props.match.params.contractId;
    const url_segment = this.props.match.url.split('/');
    this.setState({ contractId });
    if (url_segment && url_segment.includes('update-contract')) {
      this.setState({ editType: true });
    }

    this.setState({ title: `Add Contract` }, () =>
      customFunctions.setTitle(this.state.title)
    );

    if (contractId) {
      this.setState({ title: `Edit Contract` }, () =>
        customFunctions.setTitle(this.state.title)
      );
      this.getLineItemData(contractId);
    }
    this.getContracts();
  };

  getLineItemData = async (contractId) => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getContractItem}?contract_id=${contractId}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        contractName: data.data.data[0].contract_name,
        body: data.data.data[0].contract_body,
        isCustomerAgree: data.data.data[0].customer_agree,
        isDocumentAttached: data.data.data[0].is_default,
        contractType: data.data.data[0].contract_type && {
          label: data.data.data[0].contract_type,
          value: data.data.data[0].contract_type,
        },
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      var $this = this;
      swal(message, '', 'success');
      setTimeout(() => {
        $this.props.history.push('/contracts');
      }, 1000);
    }
  };

  getContracts = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.getcontracttypesUrl}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      this.setState({
        contractData: data.data,
        isShowLoader: false,
      });
    } else {
      this.setState({ isShowLoader: false });
      swal(message, '', 'error');
    }
  };

  validateForm = (e) => {
    e.preventDefault();
    const { contractName, body, contractType } = this.state;

    let contractNameErr = '',
      bodyErr = '',
      contractTypeErr = '',
      isValid = true;

    if (contractName === '') {
      contractNameErr = 'Contract Name is required';
      isValid = false;
    }

    if (body === '') {
      bodyErr = 'Body is required';
      isValid = false;
    }

    if (!contractType) {
      contractTypeErr = 'Please select contract type';
      isValid = false;
    }

    this.setState({
      contractNameErr,
      bodyErr,
      contractTypeErr,
    });

    if (isValid) {
      this.addContract();
    }
  };

  addContract = async (e) => {
    const {
      contractName,
      body,
      contractId,
      isDocumentAttached,
      isCustomerAgree,
      contractType,
    } = this.state;

    const postData = {
      contract_name: contractName.trim(),
      contract_body: body.trim(),
      customer_agree: isCustomerAgree ? 1 : 0,
      is_default: isDocumentAttached ? 1 : 0,
      contract_type: contractType ? contractType.value : '',
    };

    const callback = contractId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = Api.addContractItem;
    if (contractId) {
      postData.id = parseInt(contractId);
    }
    const { data, message } = await callback(endPoint, postData);

    if (data) {
      var $this = this;
      if (contractId) {
        swal('Contract updated successfully', '', 'success').then(() => {
          $this.props.history.push('/contracts', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
        return;
      }

      swal('Contract added successfully', '', 'success').then(() => {
        this.resetData();
        $this.props.history.push('/contracts', {
          navSkip:
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.navSkip,
          navCurrentPage:
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.navCurrentPage,
        });
      });
      return;
    } else {
      swal(message, '', 'error');
    }
  };

  resetData = () => {
    this.setState({
      contractName: '',
      body: '',
    });
  };

  handleCustomerAgree = (e, i) => {
    if (e.target.checked) {
      this.setState({ isCustomerAgree: true });
    } else {
      this.setState({ isCustomerAgree: false });
    }
  };

  handleDocumentAttached = (e, i) => {
    if (e.target.checked) {
      this.setState({ isDocumentAttached: true });
    } else {
      this.setState({ isDocumentAttached: false });
    }
  };

  render() {
    const {
      contractName,
      body,
      title,
      contractId,
      contractNameErr,
      bodyErr,
      isCustomerAgree,
      isDocumentAttached,
      isShowLoader,
      contractType,
      contractData,
      contractTypeErr,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-23">
                  <div className="card-body">
                    <form onSubmit={this.validateForm} autoComplete="off">
                      {contractId && (
                        <div className="form-group row">
                          <div className="col-sm-1" />
                          <label
                            htmlFor="contractId"
                            className="col-sm-2 col-form-label"
                          >
                            Contract Id
                          </label>
                          <div className="col-sm-6">
                            <input
                              className="form-control"
                              disabled
                              value={contractId}
                              type="text"
                              placeholder="John"
                              id="contractId"
                              onChange={(e) => {
                                this.setState({ contractId: e.target.value });
                              }}
                            />
                          </div>
                        </div>
                      )}
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="itemname"
                          className="col-sm-2 col-form-label"
                        >
                          Contract Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <input
                            className="form-control"
                            value={contractName}
                            type="text"
                            placeholder="Contract Name"
                            id="itemname"
                            onChange={(e) => {
                              this.setState({ contractName: e.target.value });
                            }}
                          />
                          <span id="err">{contractNameErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="desc"
                          className="col-sm-2 col-form-label"
                        >
                          Body<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <textarea
                            className="form-control"
                            placeholder="Enter Contract Body"
                            type="textarea"
                            id="desc"
                            rows="8"
                            cols="190"
                            value={body}
                            onChange={(e) => {
                              this.setState({
                                body: e.target.value,
                              });
                            }}
                          />
                          <span id="err">{bodyErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="contractType"
                          className="col-sm-2 col-form-label"
                        >
                          Contract Type<span className="text-danger">*</span>
                        </label>
                        <div className="col-sm-6">
                          <ReactSelect
                            value={contractType}
                            placeholder="Select"
                            onChange={(e) => {
                              if (e) {
                                let type = {};
                                type.label = e.label;
                                type.value = e.value;
                                this.setState({ contractType: type }, () => {
                                  this.forceUpdate();
                                });
                              } else {
                                this.setState({ contractType: '' }, () => {});
                              }
                            }}
                            isClearable={true}
                            options={contractData}
                          />
                          <span id="err">{contractTypeErr}</span>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-checkbox-input"
                          className="col-sm-2 col-form-label"
                        >
                          Ensure customer agrees before signing
                        </label>
                        <div className="col-sm-1">
                          <input
                            className="form-control checkbox"
                            checked={isCustomerAgree}
                            value={isCustomerAgree}
                            type="checkbox"
                            id="example-checkbox-input"
                            onChange={(e) => this.handleCustomerAgree(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <div className="col-sm-1" />
                        <label
                          htmlFor="example-checkbox-default"
                          className="col-sm-2 col-form-label"
                        >
                          Attach this contract to all documents by default
                        </label>
                        <div className="col-sm-1">
                          <input
                            className="form-control checkbox"
                            checked={isDocumentAttached}
                            value={isDocumentAttached}
                            type="checkbox"
                            id="example-checkbox-default"
                            onChange={(e) => this.handleDocumentAttached(e)}
                          />
                        </div>
                      </div>

                      <div className="form-group row d-flex justify-content-center">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light text-center"
                        >
                          Submit
                        </button>
                        <button
                          type="reset"
                          onClick={() => {
                            this.resetData();
                            this.props.history.push('/contracts', {
                              navSkip:
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.navSkip,
                              navCurrentPage:
                                this.props &&
                                this.props.location &&
                                this.props.location.state &&
                                this.props.location.state.navCurrentPage,
                            });
                          }}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddContractComponent);
