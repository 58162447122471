import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import StarRatings from '../../../components/Rating/rating';
import ReactSelect from '../../../components/ReactSelect/reactSelect';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import Loader from '../../../components/Loader';
import AUX from '../../../hoc/Aux_';
import RatingConfirmPopup from './RatingConfirmPopup';
import swal from 'sweetalert';
class AddRating extends Component {
  constructor(props) {
    super(props);
    this.state = {
      technicianObject: [
        {
          technician: '',
          technicianFunction: '',
        },
      ],
      techniciandata: [],
      selecteFunctiondOption: [],
      review: '',
      workorderid: this.props.workorderid,
      tech_id: null,
      ratings: 0,
      rating_by: 0,
      source: 1,
      roleId: null,
      technicianErr: '',
      reviewList: [],
      isEdit: false,
      reviewid: null,
      reviewedTechList: [],
      multitechLength: 0,
      selectedOption: { label: '', value: '' },
      reviewErr: '',
      ratingErr: '',
      isShowLoader: false,
      showRatingConfirm: false,
      msg: '',
    };
  }
  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState(
      { roleId: userdata.role_id, workorderid: this.props.workorderid },
      async () => {
        await this.getReviews();
        await this.getTechnician();
      }
    );
  };

  getReviews = async () => {
    const endPoint = `${Api.getReviews}?work_order_id=${this.state.workorderid}`;
    var { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      let reviewedTechList = [];
      data.data.data.map((i) => {
        reviewedTechList.push(i.tech_id);
        return i;
      });
      this.setState({ reviewList: data.data.data, reviewedTechList }, () => {
        // window.location.reload();
      });
    }
  };
  getTechnician = async () => {
    this.setState({ isShowLoader: true });
    const endPoint = `${Api.AddtechnicianUrl}`;
    var { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      const workorderData = this.props.workorderData;
      if (
        workorderData &&
        workorderData.multitech &&
        workorderData.multitech.length
      ) {
        let techniciandata = [];
        let technicianFunction = [];
        const allTechObject = [];

        data.data.data &&
          data.data.data.length &&
          data.data.data.map((tech, i) => {
            workorderData.multitech.map((res) => {
              if (tech.id === res.tech_id) {
                techniciandata.push({
                  value: tech.id,
                  label: tech.name,
                });
                technicianFunction.push(tech.tech_function);
              }
              return res;
            });
            return tech;
          });
        workorderData.multitech.map((technician, i) => {
          allTechObject.push({
            technician: technician.tech_id,
            technicianFunction: technician.tech_function,
          });
          return technician;
        });
        if (this.state.reviewedTechList) {
          techniciandata = techniciandata.filter(
            (i) => !this.state.reviewedTechList.includes(i.value)
          );
        }
        this.setState({
          technicianObject: allTechObject,
          techniciandata: techniciandata,
          source: workorderData.sourceid || 1,
          rating_by: workorderData.CustomerID,
          multitechLength: workorderData.multitech.length,
        });
      }
    }
    this.setState({ isShowLoader: false });
  };

  addTechnicianObject = (id) => {
    const { technicianObject } = this.state;
    technicianObject.push({
      technician: '',
      technicianFunction: '',
    });
    this.setState({ technicianObject });
  };

  removeTechnicianObject = (id) => {
    const { technicianObject } = this.state;
    technicianObject.splice(id, 1);
    this.setState({ technicianObject });
  };

  handleTechnicianChange = (selectedOption, index) => {
    const { technicianObject } = this.state;
    technicianObject[index].technician = selectedOption.value;
    technicianObject[index].selectedOption = selectedOption;
    this.setState({ technicianObject, selectedOption });
  };

  addReview = async () => {
    const {
      ratings,
      review,
      workorderid,
      selectedOption,
      roleId,
      rating_by,
      source,
      tech_id,
      isactive,
      reviewid,
      msg,
    } = this.state;
    var technicianErr = '';
    var reviewErr = '';
    var ratingErr = '';
    if (!selectedOption.value) {
      technicianErr = 'Please select technician';
      this.setState({ technicianErr });
      return false;
    } else {
      this.setState({ technicianErr });
    }
    if (!ratings) {
      ratingErr = 'Please select rating';
      this.setState({ ratingErr });
      return false;
    } else {
      this.setState({ technicianErr });
    }
    if (!review) {
      reviewErr = 'Please Enter feedback';
      this.setState({ reviewErr });
      return false;
    } else {
      this.setState({ technicianErr });
    }
    this.setState({ technicianErr, reviewErr, ratingErr });

    const endPoint = reviewid ? `${Api.UpdateReviews}` : `${Api.AddReviews}`;
    let payload = {
      work_order_id: parseInt(workorderid),
      tech_id: (selectedOption && selectedOption.value) || tech_id,
      rating: ratings,
      rating_by: rating_by,
      review: review,
      message: msg,
      is_offered: msg !== '' ? 'YES' : 'NO',
    };
    if (roleId === 4 && reviewid) {
      payload.reviewid = reviewid;
      payload.isactive = isactive;
    }
    if (roleId !== 1) {
      payload.source = source;
    }
    const callback = reviewid
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const { data } = await callback(endPoint, payload);
    if (data) {
      swal('Updated !', '', 'success');
      if (roleId == 4) {
        this.setState({
          review: '',
          tech_id: null,
          ratings: 0,
          rating_by: 0,
          source: 1,
          technicianErr: '',
          selectedOption: { label: '', value: '' },
          showRatingConfirm: false,
        });
      }
      await this.getReviews();
      await this.getTechnician();
    }
  };

  changeRatingFeedback = (newRating) => {
    this.setState({ ratings: newRating });
  };

  downloadCSV = async () => {
    const endPoint = `${Api.downloadCSVForReviews}?skip=0&limit=10000&work_order_id=${this.state.workorderid}`;
    window.open(endPoint, '_blank');
  };

  render() {
    const {
      technicianObject,
      techniciandata,
      review,
      ratings,
      selectedOption,
      technicianErr,
      reviewList,
      isEdit,
      reviewid,
      roleId,
      reviewedTechList,
      multitechLength,
      reviewErr,
      ratingErr,
      isShowLoader,
      showRatingConfirm,
    } = this.state;

    return (
      <AUX>
        <div>
          <form onSubmit={(e) => e.preventDefault()} autoComplete="off">
            <Loader showLoader={isShowLoader} needFullPage={false} />
            <div className="card m-b-20">
              {reviewList.length > 0 && (
                <div>
                  <button
                    type="submit"
                    style={{ fontSize: '9px' }}
                    className="btn btn-primary waves-effect waves-light float-right"
                    onClick={() => {
                      this.downloadCSV();
                    }}
                  >
                    Download CSV
                  </button>
                </div>
              )}
              <div className="card-body custom-rating-styling ">
                {reviewList &&
                  reviewList.map((rev) => (
                    <>
                      <div className="card review-card">
                        <div className="row card-body">
                          <div className="col-6" style={{ marginLeft: '-8px' }}>
                            <StarRatings
                              name="rating"
                              starCount={5}
                              value={rev.rating || ''}
                            />
                          </div>
                          <div className="col-6 " style={{ marginLeft: '8px' }}>
                            {rev.technician}
                          </div>
                          <div className="col-6">
                            <p>{rev.message} </p>
                          </div>
                          <div className="col-6">{rev.job_title}</div>
                        </div>
                        {roleId === 4 && (
                          <a
                            href="/review-page"
                            onClick={(e) => {
                              e.preventDefault();
                              this.setState({
                                isEdit: true,
                                workorderid: rev.work_order_id,
                                tech_id: rev.tech_id,
                                source: rev.sourceid,
                                review: rev.message,
                                ratings: rev.rating,
                                jobtitle: rev.job_title,
                                rating_by: rev.rating_by,
                                reviewid: rev.reviewid,
                                selectedOption: {
                                  label: rev.technician,
                                  value: rev.tech_id,
                                },
                              });
                            }}
                            className="review-edit"
                          >
                            <i
                              className="mdi mdi-lead-pencil"
                              title="Edit Review"
                            ></i>
                          </a>
                        )}
                        {isEdit && reviewid == rev.reviewid && (
                          <div className="card" id="review-page">
                            <div className="card-body">
                              <div>
                                <div
                                  className="form-group row"
                                  key={`technician-0`}
                                >
                                  <label
                                    htmlFor="technician"
                                    className="col-sm-2 col-form-label"
                                  >
                                    Technician
                                  </label>

                                  <div className="col-sm-3">
                                    <ReactSelect
                                      components={this.components}
                                      value={selectedOption}
                                      isDisabled
                                      onChange={(values) =>
                                        // this.handleTechnicianChange(values, index)
                                        this.setState({
                                          selectedOption: values,
                                        })
                                      }
                                      options={techniciandata}
                                    />
                                  </div>

                                  <label
                                    htmlFor="technicianFunction"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Technician Functions
                                  </label>
                                  <div className="col-sm-3 col-form-label">
                                    {/* {selectedOption && selectedOption.value == rev.tech_id && */}
                                    <p className="text-secondory">
                                      {rev.job_title}
                                    </p>
                                    {/* } */}
                                    {/* {object.technicianObject && object.technicianObject.map(func =>( */}
                                    {/* ))} */}
                                  </div>
                                </div>
                              </div>
                              <StarRatings
                                label="Ratings"
                                name="rating"
                                starCount={5}
                                value={ratings || ''}
                                onStarClick={(nextValue) =>
                                  this.changeRatingFeedback(nextValue)
                                }
                              />
                              <label className="form-group col-form-label Feedback">
                                Feedback
                              </label>
                              <textarea
                                label="Feedback"
                                className="form-control"
                                rows={3}
                                placeholder="Write your message here"
                                name="feedback"
                                value={review || ''}
                                onChange={(e) =>
                                  this.setState({ review: e.target.value })
                                }
                              />
                            </div>
                            <div
                              className="text-center"
                              style={{ paddingBottom: '18px' }}
                            >
                              {isEdit == true && (
                                <Button
                                  variant="primary"
                                  onClick={() => {
                                    this.addReview();
                                    this.setState({ isEdit: false });
                                  }}
                                  type="submit"
                                  style={{ marginLeft: '14px' }}
                                >
                                  Update
                                </Button>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                      <br />
                    </>
                  ))}
                {roleId !== 4 && reviewList.length === 0 && (
                  <div className="card ">
                    <div className="row card-body">
                      <div className="col-6">No Ratings!</div>
                    </div>
                  </div>
                )}
                {!isEdit &&
                  roleId == 4 &&
                  reviewedTechList.length < multitechLength && (
                    <div className="card" id="review-page">
                      <div className="card-body">
                        <div>
                          {/* {technicianObject &&
                            technicianObject.map((object, index) => ( */}
                          <div className="form-group row" key={`technician`}>
                            <label
                              htmlFor="technician"
                              className="col-sm-2 col-form-label"
                            >
                              Technician<span className="text-danger">*</span>
                            </label>

                            <div className="col-sm-3">
                              <ReactSelect
                                components={this.components}
                                value={selectedOption}
                                onChange={(values) =>
                                  // this.handleTechnicianChange(values)
                                  this.setState({ selectedOption: values })
                                }
                                options={techniciandata}
                              />
                              <span id="err">
                                {technicianErr && technicianErr}
                              </span>
                            </div>

                            <label
                              htmlFor="technicianFunction"
                              className="col-sm-3 col-form-label"
                            >
                              Technician Functions
                            </label>
                            <div className="col-sm-3 col-form-label">
                              {selectedOption && selectedOption.value && (
                                <p className="text-secondory">
                                  {technicianObject &&
                                    technicianObject.filter(
                                      (i) =>
                                        i.technician == selectedOption.value
                                    )[0].technicianFunction}
                                </p>
                              )}
                              {/* {object.technicianObject && object.technicianObject.map(func =>( */}
                              {/* ))} */}
                            </div>
                          </div>
                          {/* // ))} */}
                        </div>
                        <StarRatings
                          label="Ratings"
                          name="rating"
                          starCount={5}
                          value={ratings || ''}
                          onStarClick={(nextValue) =>
                            this.changeRatingFeedback(nextValue)
                          }
                        />
                        <span id="err">{ratingErr && ratingErr}</span>
                        <label className="form-group col-form-label">
                          Feedback<span className="text-danger">*</span>
                        </label>
                        <textarea
                          label="Feedback"
                          className="form-control"
                          rows={3}
                          placeholder="Write your message here"
                          name="feedback"
                          containerClass="form-group"
                          value={review || ''}
                          onChange={(e) =>
                            this.setState({ review: e.target.value })
                          }
                        />
                        <span id="err">{reviewErr && reviewErr}</span>
                      </div>
                      <div
                        className="text-center"
                        style={{ paddingBottom: '18px' }}
                      >
                        {isEdit == true && (
                          <Button
                            variant="primary"
                            onClick={() => {
                              this.addReview();
                              this.setState({ isEdit: false });
                            }}
                            type="submit"
                            style={{ marginLeft: '14px' }}
                          >
                            Update
                          </Button>
                        )}
                        {isEdit !== true &&
                          reviewedTechList.length < multitechLength && (
                            <Button
                              variant="primary"
                              onClick={() => {
                                if (ratings && ratings <= 3) {
                                  this.setState({ showRatingConfirm: true });
                                } else {
                                  this.setState({ showRatingConfirm: false });
                                  this.addReview();
                                }
                              }}
                              type="submit"
                              style={{ marginLeft: '14px' }}
                            >
                              Save
                            </Button>
                          )}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </form>
          {showRatingConfirm && (
            <RatingConfirmPopup
              showRatingConfirm={showRatingConfirm}
              handleClose={() =>
                this.setState({ showRatingConfirm: false }, () =>
                  this.addReview()
                )
              }
              callback={(msg) => {
                this.setState({ msg }, () => {
                  this.addReview();
                });
              }}
            />
          )}
        </div>
      </AUX>
    );
  }
}

export default AddRating;
