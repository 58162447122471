import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AUX from '../../../hoc/Aux_';
import customFunctions from '../../../helper/customFunctions';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import HelmetComponent from '../Title/index';
import ReactSelect from '../../../components/ReactSelect/reactSelect';

class AddEditPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      title: '',
      partId: null,
      name: '',
      description: '',
      nameErr: '',
      descriptionErr: '',
      for_reorder: '',
      quantityinstock: '',
      invetoryvalue: '',
      reorderlevel: '',
      reordertimeindays: '',
      quantityinreorder: '',
      continued: '',
      partno: '',
      ahspartcost: '',
      partprice: '',
      aftertaxprice: '',
      tax: { label: '', value: 0 },
      inventoryid: '',
      taxData: [],
      supply_house: '',
    };
  }

  componentDidMount = async () => {
    let writeAllowed = await customFunctions.isAllowedRoute(
      'submodule',
      'master-parts',
      'write'
    );
    if (!writeAllowed) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('Edit Part');
    this.getTaxRateData();
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.detail
    ) {
      this.getPart();
      customFunctions.setTitle('Edit Part');
      this.setState({
        title: `Edit Part`,
      });
    } else {
      customFunctions.setTitle('Add Part');
      this.setState({
        title: `Add Part`,
      });
    }
  };

  getPart = async () => {
    const partsData = this.props.location.state.detail;
    let tax = {};
    let taxs = partsData.tax && JSON.parse(partsData.tax);
    tax.label = taxs && taxs.tax_name && taxs.tax_name;
    tax.value = taxs && taxs.tax_rate && taxs.tax_rate;

    this.setState({
      partId: partsData.id,
      name: partsData.name,
      description: partsData.description,
      price: partsData.price,
      for_reorder: partsData.for_reorder,
      quantityinstock: partsData.quantityinstock,
      invetoryvalue: partsData.invetoryvalue,
      reorderlevel: partsData.reorderlevel,
      reordertimeindays: partsData.reordertimeindays,
      quantityinreorder: partsData.quantityinreorder,
      continued: partsData.continued,
      partno: partsData.partno,
      ahspartcost: partsData.ahspartcost,
      partprice: partsData.partprice,
      aftertaxprice: partsData.aftertaxprice,
      tax,
      inventoryid: partsData.inventoryid,
      supply_house: partsData.supply_house,
    });
  };

  getTaxRateData = async () => {
    let endPoint = `${Api.getTaxRate}?skip=0&limit=100`;

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data) {
      const taxOptions = [];
      data.data.data &&
        data.data.data.map((tax) => {
          taxOptions.push({
            value: tax.tax_rate,
            label: tax.tax_name,
          });
          return tax;
        });

      this.setState(
        {
          taxData: taxOptions,
        },
        () => {
          this.forceUpdate();
        }
      );
    } else {
      alert(message);
    }
  };

  submit = async () => {
    const {
      partId,
      name,
      description,
      for_reorder,
      quantityinstock,
      invetoryvalue,
      reorderlevel,
      reordertimeindays,
      quantityinreorder,
      continued,
      partno,
      ahspartcost,
      partprice,
      aftertaxprice,
      tax,
      supply_house,
    } = this.state;

    const postData = {
      name,
      description,
      for_reorder,
      supply_house,
      quantityinstock,
      invetoryvalue,
      reorderlevel,
      reordertimeindays,
      quantityinreorder,
      continued,
      partno,
      ahspartcost,
      partprice,
      aftertaxprice,
      tax: { tax_name: tax.label, tax_rate: tax.value },
    };

    const endPoint = `${Api.partsUrl}`;
    const callback = partId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    if (partId) {
      postData.id = parseInt(partId);
    }
    const { data, message } = await callback(endPoint, postData);
    if (data && data.success !== false) {
      var $this = this;
      if (partId) {
        swal('Part updated successfully', '', 'success').then(() => {
          $this.props.history.push('/parts', {
            navSkip:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navSkip,
            navCurrentPage:
              this.props &&
              this.props.location &&
              this.props.location.state &&
              this.props.location.state.navCurrentPage,
          });
        });
      } else {
        swal('Part added successfully', '', 'success').then(() => {
          $this.props.history.push('/parts');
        });
      }
    } else if (message === 'Updated') {
      swal('Part updated successfully', '', 'success').then(() => {
        this.props.history.push('/parts', {
          navSkip:
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.navSkip,
          navCurrentPage:
            this.props &&
            this.props.location &&
            this.props.location.state &&
            this.props.location.state.navCurrentPage,
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { partId, name, description, quantityinstock, partno, partprice } =
      this.state;

    let nameErr = '',
      descriptionErr = '',
      quantityinstockErr = '',
      invetoryvalueErr = '',
      reorderlevelErr = '',
      reordertimeindaysErr = '',
      quantityinreorderErr = '',
      partnoErr = '',
      ahspartcostErr = '',
      partpriceErr = '',
      taxErr = '';

    let isValid = true;
    if ((name && name.trim() === '') || !name) {
      nameErr = 'Name is required';
      isValid = false;
    }
    if ((description && description.trim() === '') || !description) {
      descriptionErr = 'Description is required';
      isValid = false;
    }
    if (
      !partId &&
      ((quantityinstock && quantityinstock.trim() === '') || !quantityinstock)
    ) {
      quantityinstockErr = 'Quantity Stock is required';
      isValid = false;
    }
    if ((partno && partno.trim() === '') || !partno) {
      partnoErr = 'Part number is required';
      isValid = false;
    }
    if (
      (typeof partprice == 'string' && partprice && partprice.trim() === '') ||
      !partprice
    ) {
      partpriceErr = 'Part price is required';
      isValid = false;
    }
    this.setState({
      nameErr,
      descriptionErr,
      quantityinstockErr,
      invetoryvalueErr,
      reorderlevelErr,
      reordertimeindaysErr,
      quantityinreorderErr,
      partnoErr,
      ahspartcostErr,
      partpriceErr,
      taxErr,
    });
    if (isValid) {
      this.submit();
    }
  };

  handleTaxChange = (selectedOption) => {
    const { partprice, quantityinstock } = this.state;
    let tax = { ...this.state.tax };
    tax.label = selectedOption.label;
    tax.value = selectedOption.value;
    let aftertaxprice =
      partprice * quantityinstock +
      (partprice * quantityinstock * tax.value) / 100;
    this.setState({ tax, aftertaxprice }, () => {
      this.forceUpdate();
    });
  };

  render() {
    const {
      title,
      partId,
      name,
      nameErr,
      description,
      descriptionErr,
      for_reorder,
      quantityinstock,
      quantityinstockErr,
      invetoryvalue,
      reorderlevel,
      reordertimeindays,
      quantityinreorder,
      continued,
      partno,
      partnoErr,
      ahspartcost,
      partprice,
      partpriceErr,
      aftertaxprice,
      tax,
      taxErr,
      inventoryid,
      taxData,
      supply_house,
    } = this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <HelmetComponent title={title} />
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <form onSubmit={this.validate} autoComplete="off">
                      <div className="form-group row">
                        <div className="col-sm-6">
                          {inventoryid && (
                            <div className="form-group row">
                              <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                              >
                                Inventory ID
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="text"
                                  disabled
                                  className="form-control"
                                  value={inventoryid}
                                />
                              </div>
                            </div>
                          )}
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Name"
                                value={name}
                                onChange={(e) => {
                                  this.setState({ name: e.target.value });
                                }}
                              />
                              <span id="err">{nameErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Description<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <textarea
                                className="form-control"
                                placeholder="Description"
                                rows={6}
                                value={description}
                                onChange={(e) => {
                                  this.setState({
                                    description: e.target.value,
                                  });
                                }}
                              />
                              <span id="err">{descriptionErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Quantity In Stock
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                disabled={partId}
                                placeholder="Quantity In Stock"
                                value={quantityinstock}
                                onChange={(e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState({
                                    quantityinstock: event.target.value,
                                    invetoryvalue:
                                      event.target.value * partprice,
                                    aftertaxprice:
                                      event.target.value * partprice +
                                      (event.target.value *
                                        partprice *
                                        tax.value) /
                                        100,
                                  });
                                }}
                              />
                              <span id="err">{quantityinstockErr}</span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Part Price<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Part Price"
                                value={partprice}
                                onChange={(e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMaskingDecimal(
                                      e.target.value
                                    );
                                  this.setState({
                                    partprice: event.target.value,
                                    invetoryvalue:
                                      event.target.value * quantityinstock,
                                    aftertaxprice:
                                      event.target.value * quantityinstock +
                                      (event.target.value *
                                        quantityinstock *
                                        tax.value) /
                                        100,
                                  });
                                }}
                              />
                              <span id="err">{partpriceErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Tax
                            </label>
                            <div className="col-sm-9">
                              <ReactSelect
                                value={tax}
                                onChange={this.handleTaxChange}
                                options={taxData}
                              />
                              <span id="err">{taxErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              After Tax Price
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                disabled
                                className="form-control"
                                placeholder="0"
                                value={aftertaxprice}
                                // onChange={(e) => {
                                //   let event = e;
                                //   event.target.value =
                                //     customFunctions.numberMaskingDecimal(
                                //       e.target.value
                                //     );
                                //   this.setState({ aftertaxprice: event.target.value });
                                // }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Part Number<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Part Number"
                                value={partno}
                                onChange={(e) => {
                                  this.setState({ partno: e.target.value });
                                }}
                              />
                              <span id="err">{partnoErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Supply House
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Supply House"
                                value={supply_house}
                                onChange={(e) => {
                                  this.setState({
                                    supply_house: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Inventory Value
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                placeholder="0"
                                value={invetoryvalue}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Reorder Level
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Reorder Level"
                                value={reorderlevel}
                                onChange={(e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState({
                                    reorderlevel: event.target.value,
                                  });
                                }}
                              />
                              {/* <span id="err">{reorderlevelErr}</span> */}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Reorder Time In Days
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Reorder Time In Days"
                                value={reordertimeindays}
                                onChange={(e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState({
                                    reordertimeindays: event.target.value,
                                  });
                                }}
                              />
                              {/* <span id="err">{reordertimeindaysErr}</span> */}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              Quantity In Reorder
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Quantity In Reorder"
                                value={quantityinreorder}
                                onChange={(e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMasking(
                                      e.target.value
                                    );
                                  this.setState({
                                    quantityinreorder: event.target.value,
                                  });
                                }}
                              />
                              {/* <span id="err">{quantityinreorderErr}</span> */}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-3 col-form-label"
                            >
                              AHS Part Cost
                            </label>
                            <div className="col-sm-9">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="AHS Part Cost"
                                value={ahspartcost}
                                onChange={(e) => {
                                  let event = e;
                                  event.target.value =
                                    customFunctions.numberMaskingDecimal(
                                      e.target.value
                                    );
                                  this.setState({
                                    ahspartcost: event.target.value,
                                  });
                                }}
                              />
                              {/* <span id="err">{ahspartcostErr}</span> */}
                            </div>
                          </div>
                          {partId && (
                            <div className="form-group row">
                              <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                              >
                                For Reorder
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="checkbox"
                                  checked={Number(for_reorder) ? 'checked' : ''}
                                  onChange={(e) => {
                                    var isChecked = e.target.checked;
                                    this.setState({
                                      for_reorder: isChecked ? '1' : '0',
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {partId && (
                            <div className="form-group row">
                              <label
                                htmlFor="example-text-input"
                                className="col-sm-3 col-form-label"
                              >
                                Continued?
                              </label>
                              <div className="col-sm-9">
                                <input
                                  type="checkbox"
                                  checked={Number(continued) ? 'checked' : ''}
                                  onChange={(e) => {
                                    var isChecked = e.target.checked;
                                    this.setState({
                                      continued: isChecked ? '1' : '0',
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row justify-content-center">
                        <div className="col-sm-3">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                          >
                            Submit
                          </button>
                          <button
                            type="reset"
                            onClick={() => {
                              this.props.history.push('/parts', {
                                navSkip:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navSkip,
                                navCurrentPage:
                                  this.props &&
                                  this.props.location &&
                                  this.props.location.state &&
                                  this.props.location.state.navCurrentPage,
                              });
                            }}
                            className="btn btn-secondary waves-effect m-l-10"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(AddEditPart);
