import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import Pagination from '../../../../components/Pagination';
import Api from '../../../../helper/api';
import AuthApi from '../../../../helper/authApi';
import customFunctions from '../../../../helper/customFunctions';

class InvoiceTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invoices: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      startDate: '',
      endDate: '',
    };
  }
  async componentDidMount() {
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'INVOICE NO.',
        field: 'invoiceno',
        width: 150,
      },
      {
        label: 'JOB NO.',
        field: 'jobno',
        width: 150,
      },
      {
        label: 'CREATED',
        field: 'created',
        width: 180,
      },
      {
        label: 'JOB STATUS',
        field: 'jobstatus',
        width: 150,
      },
      {
        label: 'STATUS',
        field: 'status',
        width: 150,
      },
      {
        label: 'FIRST NAME',
        field: 'firstname',
        width: 150,
      },
      {
        label: 'LAST NAME',
        field: 'lastname',
        width: 150,
      },
      {
        label: 'JOB TITLE',
        field: 'jobtitle',
        width: 150,
      },
      {
        label: 'SOURCE',
        field: 'source',
        width: 150,
      },
      {
        label: 'SERVICE TYPE',
        field: 'servicetype',
        width: 150,
      },
      {
        label: 'AMOUNT',
        field: 'amount',
        width: 150,
      },
      {
        label: 'TECHNICIAN',
        field: 'technician',
        width: 150,
      },
      {
        label: 'CREATED BY',
        field: 'createdby',
        width: 150,
      },
      {
        label: 'VIEW JOB',
        field: 'actions',
        width: 150,
      },
    ];
    if (this.props.tableType === 'InvoiceWithPhone') {
      columns.splice(4, 1);
      columns[7] = {
        label: 'PHONE NUMBER',
        field: 'phone',
        width: 150,
      };
      columns[10] = {
        label: 'POSTAL CODE',
        field: 'postalCode',
        width: 150,
      };
    }
    this.setState(
      { columns, startDate: this.props.startDate, endDate: this.props.endDate },
      () => {
        this.getInvoices();
      }
    );
  }
  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      this.setState(
        { startDate: props.startDate, endDate: props.endDate },
        () => {
          this.getInvoices();
        }
      );
    }
  }
  getInvoices = async (currentPage) => {
    let { pagination, startDate, endDate } = this.state;

    this.setState({ currentPage });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? new Date(startDate).toISOString() : '';
    endDate = endDate ? new Date(endDate).toISOString() : '';
    let endPoint = `${Api.getInvoice}?skip=${skip}&limit=${pagination.itemsPerPage}&start_date=${startDate}&end_date=${endDate}`;

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.totalCount,
      };
      this.setState(
        {
          invoices: data.data.data,
          totalCount: data.data.totalCount,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.props.callback();
  };

  downloadCSVForInvoiceCreatedDate = async () => {
    let { startDate, endDate } = this.state;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.downloadCSVForInvoiceCreatedDate}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}`;
    window.open(endPoint, '_blank');
  };

  downloadCSVForInvoiceWithPC = async () => {
    let { startDate, endDate } = this.state;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.downloadCSVForInvoiceWithPC}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}`;
    window.open(endPoint, '_blank');
  };

  setTableData = () => {
    const { invoices, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    invoices &&
      invoices.length &&
      invoices.map((invoices, i) => {
        invoices.work_start_date = customFunctions.changeDateFormatMDY(
          invoices.work_start_date
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          invoiceno: invoices.invoice_id || '-',
          jobno: invoices.work_order_id || '-',
          created: invoices.work_start_date || '-',
          jobstatus: invoices.job_status || '-',
          status: invoices.invoice_type || '-',
          firstname:
            (invoices.customer_name && invoices.customer_name.split(' ')[0]) ||
            '-',
          lastname:
            (invoices.customer_name && invoices.customer_name.split(' ')[1]) ||
            '-',
          jobtitle: invoices.job_title || '-',
          source: invoices.source || '-',
          servicetype: invoices.order_type || '-',
          // servicetype: invoices.service_type || '-',
          amount: invoices.total || '-',
          technician: invoices.technician || '-',
          createdby: invoices.createdby || '-',
          phone: invoices.phone_no || '-',
          postalCode: invoices.zip_code || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(
                    `/invoiceDetail/${invoices.invoice_id}`,
                    {
                      orderDetails: invoices,
                      type: 'invoices',
                    }
                  );
                }}
              >
                <i
                  className="mdi mdi-eye"
                  id="viewJob"
                  title="View Invoice"
                ></i>
              </a>
            </React.Fragment>
          ),
        });
        return invoices;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6> {this.props.title}</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                if (this.props.csvType === 'downloadCSVForInvoiceCreatedDate') {
                  this.downloadCSVForInvoiceCreatedDate();
                }
                if (this.props.csvType === 'downloadCSVForInvoiceWithPC') {
                  this.downloadCSVForInvoiceWithPC();
                }
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>
        {/* <Table
        columns={columns}
        rows={rows}
        showPagination
        paginationProps={{
          ...pagination,
          activePage: pagination.current_page,
          onPageChange: (pageNo) => {
            this.getInvoices(pageNo);
          },
        }}
        tableClass="table-bordered"
        dropdownContainerClass="mb-3"
        emptyRender={() => <p className="text-center mb-0">No record found</p>}
      /> */}
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="350px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getInvoices}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(InvoiceTables);
