import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import SweetAlert from 'react-bootstrap-sweetalert';
import Loader from '../../../components/Loader';

const columns = [
  {
    label: 'PO Date',
    field: 'podate',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'PO #',
    field: 'po',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'PO Amount',
    field: 'poamount',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Main Account',
    field: 'mainaccount',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Sub Account',
    field: 'subaccount',
    sort: 'disabled',
    width: 150,
  },
  // {
  //   label: 'Home Warranty Code',
  //   field: 'homewarrantycode',
  //   sort: 'disabled',
  //   width: 150
  // },
  {
    label: 'Technician',
    field: 'technician',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
    width: 150,
  },
];

class PurchaseView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      rows: [],
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      skip: 0,
      showDelete: false,
      workId: null,
      purchaseList: [],
      workOrderId: null,
      purchaseOrderData: [],
      id: null,
      type: '',
      ordercode: null,
      viewId: null,
      purchaseID: null,
      technicianId: null,
      customId: null,
      WorkOrder: '',
      isShowLoader: false,
    };
  }

  componentDidMount = async () => {
    var newViewId = customFunctions.getLocalStorage('viewId');
    this.setState({
      viewId: newViewId,
    });

    if (this.props) {
      if (
        this.props.match &&
        this.props.match.params &&
        this.props.match.params.customerId
      ) {
        await this.setState({ type: await this.props.match.params.customerId });
      }
      if (this.props.location && this.props.location.state) {
        if (this.props.location.state.type) {
          await this.setState({ type: await this.props.location.state.type });
        }
        if (
          this.props.location.state.orderDetails &&
          this.props.location.state.orderDetails.WorkOrder
        ) {
          await this.setState({
            workId: await this.props.location.state.orderDetails.ID,
          });
        }

        if (
          this.props.location.state.orderDetails &&
          this.props.location.state.orderDetails.ID
        ) {
          await this.setState({
            WorkOrder: await this.props.location.state.orderDetails.ID,
          });
        }
        if (
          this.props.location.state.customerDetails &&
          this.props.location.state.customerDetails.ID
        ) {
          await this.setState({
            customId: await this.props.location.state.customerDetails.ID,
          });
        }
        if (
          (await this.props.location) &&
          (await this.props.location.state) &&
          (await this.props.location.state.technicianDetails) &&
          (await this.props.location.state.technicianDetails.id)
        ) {
          await this.setState({
            technicianId: await this.props.location.state.technicianDetails.id,
          });
        }
      }
    }
    await this.getPurchaseOrder();
  };

  setTableData = () => {
    const { purchaseList } = this.state;
    const rows = [];
    purchaseList &&
      purchaseList.length &&
      purchaseList.map((purchase, i) => {
        purchase.ExpenseDate = customFunctions.changeDateFormatMomentMDY(
          purchase.ExpenseDate
        );

        rows.push({
          action: (
            <React.Fragment>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: `/update-purchase/${purchase.ID}`,
                    state: {
                      workOrderId: this.props.match.params.customerId,
                    },
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  title="Edit Purchase Order"
                ></i>
              </a>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, purchaseID: purchase.ID });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title="Delete Purchase Order"
                ></i>
              </a>
            </React.Fragment>
          ),

          podate: purchase.ExpenseDate || '-',
          po: purchase.ExpenseCode || '-',
          poamount: purchase.ExpenseAmount || '-',
          mainaccount: purchase.MainAccountName || '-',
          subaccount: purchase.SubAccountName || '-',
          homewarrantycode: purchase.HomeWarrantyCode || '-',
          technician: purchase.Technician || '-',
        });
        return purchase;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getPurchaseOrder = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, customId, type, technicianId, WorkOrder } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;

    let endPoint = `${Api.createPurchaseOrder}/by_work_id?work_order=${this.props.match.params.customerId}`;

    if (type === 'technician') {
      endPoint = `${Api.createPurchaseOrder}/by_technician?technician_id=${technicianId}&skip=${skip}&limit=${limit}`;
    } else if (type === 'customer') {
      endPoint = `${Api.createPurchaseOrder}/by_customer?customer_id=${customId}&skip=${skip}&limit=${limit}`;
    } else if (type === 'orders') {
      endPoint = `${Api.createPurchaseOrder}/by_work_id?work_order=${WorkOrder}`;
    }

    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState(
        {
          purchaseList: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.setState({ isShowLoader: false });
  };

  deletePurchaseOrder = async () => {
    const { purchaseID } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.createPurchaseOrder}?purchase_id=${purchaseID}`
    );
    if (data) {
      this.setState({ showDelete: false, purchaseID: null }, () => {
        this.getPurchaseOrder();
      });
      swal('Purchase Order deleted', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  getPurchaseOrderByWorkIdCsv = async () => {
    let endPoint = `${
      Api.getPurchaseOrderByWorkIdCsv
    }?skip=0&limit=10000&work_order=${
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.customerId &&
      this.props.match.params.customerId
    }`;

    window.open(endPoint, '_blank');
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      WorkOrder,
      currentPage,
      viewId,
      showDelete,
      isShowLoader,
    } = this.state;

    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <div className="card m-b-20">
          {showDelete && (
            <SweetAlert
              title="Are you sure, Do you want to delete the purchase order ?"
              error
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                this.deletePurchaseOrder();
              }}
              onCancel={() => {
                this.setState({ showDelete: false, purchaseID: null });
              }}
            >
              You won't be able to revert this!
            </SweetAlert>
          )}

          <div style={{ paddingRight: '20px', paddingTop: '20px' }}>
            <div className="d-flex justify-content-end">
              {viewId && (
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    this.props.history.push({
                      pathname: `/add-purchaseorder/${
                        WorkOrder || this.props.match.params.customerId
                      }`,
                      state: {
                        workOrderId: this.props.match.params.customerId,
                        technician:
                          this.props &&
                          this.props.location &&
                          this.props.location.state &&
                          this.props.location.state.orderDetails &&
                          this.props.location.state.orderDetails.multitech,
                      },
                    });
                  }}
                >
                  Add Purchase Order
                </button>
              )}
              {rows && rows.length ? (
                <button
                  type="submit"
                  style={{ marginLeft: '5px' }}
                  className="btn btn-primary waves-effect waves-light float-right"
                  onClick={() => {
                    this.getPurchaseOrderByWorkIdCsv();
                  }}
                >
                  Download CSV
                </button>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="card-body">
            <MDBDataTable
              noBottomColumns={true}
              paging={false}
              bordered
              hover
              data={{ columns, rows }}
              searching={false}
              noRecordsFoundLabel="No records found"
              scrollX
              maxHeight="350px"
            />
            {totalCount > 10 && (
              <div className="pagination">
                <Pagination
                  totalCount={totalCount}
                  currentPage={currentPage}
                  limit={limit}
                  getData={this.getPurchaseOrder}
                />
              </div>
            )}
          </div>
        </div>
      </AUX>
    );
  }
}

export default withRouter(PurchaseView);
