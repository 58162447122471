import React, { Component } from 'react';
import PaginationComponent from 'react-js-pagination';
import './style.css';

export default class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      currentPage: null,
      limit: null,
      totalCount: null,
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      activePage: props.currentPage,
      limit: props.limit,
      totalCount: props.totalCount,
    });
  }

  handlePageChange = async (pageNumber) => {
    const { getData } = this.props;
    const { activePage } = this.state;
    if (activePage !== pageNumber) {
      await this.setState({ activePage: pageNumber });
      if (getData) getData(this.state.activePage);
    }
  };

  render() {
    const { totalCount, limit } = this.state;
    return (
      <PaginationComponent
        activePage={this.state.activePage}
        itemsCountPerPage={limit}
        totalItemsCount={totalCount}
        pageRangeDisplayed={5}
        onChange={this.handlePageChange}
        hideFirstLastPages
        hideDisabled={true}
        activeClass
      />
    );
  }
}
