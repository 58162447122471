import firebase from 'firebase';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASURE_ID,
};

let messaging = null;

if (firebase.messaging.isSupported()) {
  firebase.initializeApp(config);
  messaging = firebase.messaging();
  messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_API_KEY);
} else {
  console.log('no-support :(');
}

const { REACT_APP_FIREBASE_PUBLIC_KEY } = process.env;
const publicKey = REACT_APP_FIREBASE_PUBLIC_KEY;

export const getFCMToken = async () => {
  let currentToken = '';
  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      return currentToken;
    }
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error);
  }

  return currentToken;
};

export const deleteFCMToken = async () => {
  try {
    await messaging.deleteToken({ vapidKey: publicKey });
  } catch (error) {
    console.log('An error occurred while deleting token. ', error);
  }

  return true;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });

// export const onSMSListener = () =>
//   new Promise(async (resolve) => {
//     console.log('messaging', messaging);
//     // messaging.onMessage((payload) => {
//     //   resolve(payload);
//     // });
//     let token = await messaging.getToken({ vapidKey: publicKey });
//     console.log('token', token);
//     messaging.subscribeToTopic(token, 'NEWMESSAGE-10-0')
//     .then((response) => {
//       // See the MessagingTopicManagementResponse reference documentation
//       // for the contents of response.
//       console.log('Successfully subscribed to topic:', response);
//     })
//     .catch((error) => {
//       console.log('Error subscribing to topic:', error);
//     });
//   });
