import React, { Component } from 'react';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import swal from 'sweetalert';
import EditSubAccount from './editSubAccountData';
class EditMainAccount extends Component {
  modalRef = null;
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      listype: '',
      listypeErr: '',
      mainAcc: [],
    };
  }

  addListType = async () => {
    const { listype } = this.state;
    const { getMainAccountDataCallback } = this.props;
    const postData = {
      list_type: listype,
    };
    const endPoint = `${Api.AddListTypeUrl}`;
    const { data, message } = await AuthApi.postDataToServer(
      endPoint,
      postData
    );
    if (data) {
      swal('List Type added successfully', '', 'success');
      if (getMainAccountDataCallback) getMainAccountDataCallback();
      await this.flushAllData();
      if (this.modalRef) window.$(this.modalRef).modal('hide');
      this.getMainAccountData();
    } else {
      alert(message);
    }
  };

  getMainAccountData = async () => {
    const endPoint = `${Api.ListTypeUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        mainAcc: data.data.data,
      });
    }
  };

  validate = (e) => {
    e.preventDefault();
    const { listype } = this.state;
    let listypeErr = '';
    let isValid = true;
    if (listype.trim() === '') {
      listypeErr = 'List Type is required';
      isValid = false;
    }
    this.setState({ listypeErr });
    if (isValid) {
      this.addListType();
    }
  };

  flushAllData = () => {
    this.setState({
      listype: '',
    });
  };

  render() {
    const { listype, listypeErr, mainAcc } = this.state;
    const { getSubAccountDataCallback } = this.props;

    return (
      <div>
        <div
          className="modal fade"
          id="mainaccount"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          ref={(modal) => {
            this.modalRef = modal;
          }}
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Add List Type
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <React.Fragment>
                  <form onSubmit={this.validate} autoComplete="off">
                    <div className="form-group row">
                      <label
                        htmlFor="example-text-customer-charges"
                        className="col-sm-3 col-form-label"
                      >
                        List Type
                        <span className="text-danger">*</span>
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          value={listype}
                          onChange={(e) => {
                            this.setState({ listype: e.target.value });
                          }}
                        />
                        <span id="err">{listypeErr}</span>
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="d-flex justify-content-center w-100">
                        <button
                          type="submit"
                          className="btn btn-primary waves-effect waves-light"
                        >
                          Submit
                        </button>
                        <button
                          type="reset"
                          data-dismiss="modal"
                          onClick={() => this.flushAllData()}
                          className="btn btn-secondary waves-effect m-l-10"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </form>
                </React.Fragment>
              </div>
            </div>
          </div>
        </div>
        <EditSubAccount
          mainAccountData={mainAcc}
          getSubAccountDataCallback={(id) => {
            getSubAccountDataCallback(id);
          }}
        />
      </div>
    );
  }
}

export default EditMainAccount;
