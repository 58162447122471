import { Component } from 'react';

let environment = process.env.NODE_ENV;
export default class Api extends Component {
  static newUrl = Api.getNewUrl();
  static s3url = process.env.REACT_APP_S3_URL;
  static loginUrl = `${Api.baseUrl}admin/login`;
  static searchAdmin = `${Api.baseUrl}admin/search`;
  static getAdmin = `${Api.newUrl}users`;
  static updateAdmin = `${Api.baseUrl}admin/editAdmin`;
  static changeAdminStatus = `${Api.baseUrl}admin/changeStatus`;
  static addAdmin = `${Api.baseUrl}admin/register`;
  static deleteAdmin = `${Api.baseUrl}admin/deleteAdmin`;
  static updatePassword = `${Api.baseUrl}users/updatepassword`;
  static ListTypeUrl = `${Api.newUrl}list_type/all`;
  static ListItemUrl = `${Api.newUrl}list_item/all`;
  static AddListTypeUrl = `${Api.newUrl}list_type`;
  static AddListItemUrl = `${Api.newUrl}list_item`;
  static customerUrl = `${Api.newUrl}customer`;
  static getCustomerById = `${Api.newUrl}customer/by_id`;
  static complaintCategoryUrl = `${Api.newUrl}complaint_category/all`;
  static AddcomplaintCategoryUrl = `${Api.newUrl}complaint_category`;
  static getComplaintCategoryById = `${Api.newUrl}complaint_category`;
  static complaintStatusUrl = `${Api.newUrl}complaint_status/all`;
  static AddcomplaintStatusUrl = `${Api.newUrl}complaint_status`;
  static workOrderTypeUrl = `${Api.newUrl}order_type/all`;
  static AddworkOrderTypeUrl = `${Api.newUrl}order_type`;
  static paymentTypeUrl = `${Api.newUrl}payment_type/all`;
  static AddpaymentTypeUrl = `${Api.newUrl}payment_type`;
  static warrentyCodeUrl = `${Api.newUrl}warranty_code/all`;
  static AddwarrentyCodeUrl = `${Api.newUrl}warranty_code`;
  static technicianUrl = `${Api.newUrl}technician/all`;
  static AddtechnicianUrl = `${Api.newUrl}technician`;
  static complaintUrl = `${Api.newUrl}complaints`;
  static ListItemByType = `${Api.newUrl}list_item/by_type`;
  static statusUrl = `${Api.newUrl}customer/update_status`;
  static createOrder = `${Api.newUrl}work_order`;
  static orderById = `${Api.newUrl}work_order/by_id`;
  static getOrderType = `${Api.newUrl}order_type`;
  static getWorkOrder = `${Api.newUrl}work_order`;
  static createPurchaseOrder = `${Api.newUrl}purchase`;
  static getPurchaseOrderById = `${Api.newUrl}purchase/by_work_id`;
  static profitLossUrl = `${Api.newUrl}profit_loss`;
  static loginNewUrl = `${Api.newUrl}auth`;
  static payrollUrl = `${Api.newUrl}payroll`;
  static poExpenseUrl = `${Api.newUrl}expense/monthly`;
  static getDashboardData = `${Api.newUrl}dashboard`;
  static getComplaintDetailsById = `${Api.newUrl}complaints/by_id`;
  static payment = `${Api.newUrl}payment`;
  static workOrderUrl = `${Api.newUrl}work_order`;
  static filterApi = `${Api.newUrl}work_order`;
  static filterPayrollApi = `${Api.newUrl}payroll`;
  static customerActiveUrl = `${Api.newUrl}customer`;
  static payrollPaid = `${Api.newUrl}payroll?work_order=HVAC-DS-20&is_paid=0`;
  static actualIncomeExpense = `${Api.newUrl}expense/expense_analysis`;
  static forgotPasswordUrl = `${Api.newUrl}forget_password`;
  static resetPasswordUrl = `${Api.newUrl}reset_password`;
  static profitLossExpense = `${Api.newUrl}expense/subitem_expense`;
  static purchaseById = `${Api.newUrl}purchase/by_id`;
  static getRoles = `${Api.newUrl}roles`;
  static addRoleItem = `${Api.newUrl}roles`;
  static getLineItem = `${Api.newUrl}item`;
  static AddLineItem = `${Api.newUrl}item`;
  static getContractItem = `${Api.newUrl}contract`;
  static addContractItem = `${Api.newUrl}contract`;
  static getTaxRate = `${Api.newUrl}taxrates`;
  static addTaxRate = `${Api.newUrl}taxrates`;
  static getAttachment = `${Api.newUrl}attachment`;
  static addAttachment = `${Api.newUrl}attachment`;
  static getEstimate = `${Api.newUrl}estimate`;
  static addEstimate = `${Api.newUrl}estimate`;
  static deleteEstimate = `${Api.newUrl}estimate`;
  static getWorkOrderById = `${Api.newUrl}work_order/by_customer`;
  static getTechFunction = `${Api.newUrl}technician/functions`;
  static getWarrantyCompany = `${Api.newUrl}homewarrantycompany`;
  static AddWarrantyCompany = `${Api.newUrl}homewarrantycompany`;
  static technicianTransaction = `${Api.newUrl}technician_transaction`;
  static getInvoice = `${Api.newUrl}invoice`;
  static addInvoice = `${Api.newUrl}invoice`;
  static deleteInvoice = `${Api.newUrl}invoice`;
  static serviceType = `${Api.newUrl}service_type`;
  static getPremaintenance = `${Api.newUrl}premaintenance`;
  static addPremaintenance = `${Api.newUrl}premaintenance`;
  static deletePremaintenance = `${Api.newUrl}premaintenance`;
  static getWorkOrderAveragetime = `${Api.newUrl}work_order/averagetime`;
  static getReviews = `${Api.newUrl}review`;
  static getReviewAverage = `${Api.newUrl}review/averagerating`;
  static getWeeklyData = `${Api.newUrl}billing/weekly_data`;
  static getMonthlyData = `${Api.newUrl}billing/monthly_data`;
  static getQuarterlyData = `${Api.newUrl}billing/quarterly_data`;
  static getYearlyData = `${Api.newUrl}billing/yearly_data`;
  static getNoDraftData = `${Api.newUrl}billing/non_draft`;
  static getTotalPaidData = `${Api.newUrl}billing/total_paid`;
  static getAvgInvoiceData = `${Api.newUrl}billing/average_invoice`;
  static getAvgInvoiceNonDraft = `${Api.newUrl}billing/average_invoice_non_draft`;
  static getJobsWithoutInvoiceData = `${Api.newUrl}billing/jobs_without_invoice`;
  static getPaidInvoiceData = `${Api.newUrl}billing/total_paid_invoices`;
  static getPaymentMethodData = `${Api.newUrl}billing/payment_method`;
  static getTechPaymentMethodData = `${Api.newUrl}billing/technician_paymentmethod_history`;
  static getTeamMemberWinRateData = `${Api.newUrl}billing/teammember_winrate_list`;
  static getTechStatisticsData = `${Api.newUrl}billing/technicalstaticsrange`;
  static getPaymentData = `${Api.newUrl}transation/getPayment`;
  static getRatingBreakdown = `${Api.newUrl}review/ratingsbreakdown`;
  static getRatingbyjobsource = `${Api.newUrl}review/ratingsbyjobsource`;
  static getScheduleWorkOrder = `${Api.newUrl}work_order/getworkorderbytechnician`;
  static getCustomerWeekGraph = `${Api.newUrl}customer/customer_week_graph`;
  static getRatingbytechnician = `${Api.newUrl}review/ratingsbytechnician`;
  static getTechnicianWorkload = `${Api.newUrl}technician/workloadpertechnician`;
  static getInvoiceCreated = `${Api.newUrl}billing/invoice_created`;
  static getPaymentTaken = `${Api.newUrl}billing/payment_taken`;
  static getJobBySource = `${Api.newUrl}work_order/getjobbysource`;
  static getJobByServiceType = `${Api.newUrl}work_order/jobservicetype`;
  static getJobCreatedSource = `${Api.newUrl}work_order/get_jobs_created_source`;
  static AddReviews = `${Api.newUrl}review`;
  static UpdateReviews = `${Api.newUrl}review/byadmin`;
  static getTechnicianWorkOrder = `${Api.newUrl}work_order/by_technician`;
  static cardPayment = `${Api.newUrl}transation/startpayment`;
  static addPayment = `${Api.newUrl}transation/add/invoicepayment`;
  static getNotes = `${Api.newUrl}notes`;
  static addNotes = `${Api.newUrl}notes`;
  static updateNotes = `${Api.newUrl}notes`;
  static deleteNotes = `${Api.newUrl}notes`;
  static shareNotes = `${Api.newUrl}notes/sharenote`;
  static emailTemplate = `${Api.newUrl}emailtemplate`;
  static acknowledgeWorkOrder = `${Api.newUrl}work_order/isacknowledge`;
  static premaintenanceContract = `${Api.newUrl}premaintenancecontract`;
  static contractpremaintenance = `${Api.newUrl}contract/premaintenance`;
  static partsUrl = `${Api.newUrl}parts`;
  static vendorsUrl = `${Api.newUrl}vendors`;
  static workorderpartsUrl = `${Api.newUrl}workorderparts`;
  static getWorkorderUsers = `${Api.newUrl}work_order/detailsbyworkid`;
  static getAhsWorkOrder = `${Api.newUrl}work_order/ahs`;
  static getPremaintenancePdf = `${Api.newUrl}premaintenance/generatepremaintenancepdf`;
  static moduleUrl = `${Api.newUrl}module`;
  static subModuleUrl = `${Api.newUrl}submodule`;
  static rolePermissionUrl = `${Api.newUrl}permission`;
  static updatePartQuantityUrl = `${Api.newUrl}parts/updatepartquantity`;
  static downloadcsvAhsUrl = `${Api.newUrl}work_order/ahs/downloadcsv`;
  static workorderactiviesUrl = `${Api.newUrl}activities`;
  static technicianPartsUrl = `${Api.newUrl}technicianparts`;

  static downloadCustomerCsvUrl = `${Api.newUrl}customer/downloadCustomerCsv`;
  static downloadTechnicianCsvUrl = `${Api.newUrl}technician/downloadTechnicianCsv`;
  static downloadPartsCsvUrl = `${Api.newUrl}parts/downloadPartsCsv`;
  static downloadPremaintenaneContractCsvUrl = `${Api.newUrl}premaintenancecontract/getPremaintenanceContractCsv`;
  static downloadEstimateCsvUrl = `${Api.newUrl}estimate/downloadEstimateCsv`;
  static downloadInvoiceCsvUrl = `${Api.newUrl}invoice/downloadInvoiceCsv`;
  static downloadgetTechnicianPartsCsvUrl = `${Api.newUrl}technicianparts/getAllTechnicianPartsCsv`;
  static downloadComplaintsCsvUrl = `${Api.newUrl}complaints/downloadComplaintsCsv`;
  static downloadItemsCsvUrl = `${Api.newUrl}item/getItemsCsv`;
  static downloadVendorCsvUrl = `${Api.newUrl}vendors/getVendorCsv`;
  static downloadPayrollCsvUrl = `${Api.newUrl}payroll/getTechnicianPayrollCsv`;

  static downloadCSVForJobCreatedDateAndStatus = `${Api.newUrl}work_order/getOrdersPausedCsv`;
  static downloadCSVForJobCreatedDateAndPaused = `${Api.newUrl}work_order/getOrdersPausedCsv`;
  static downloadCSVForAllAppt = `${Api.newUrl}work_order/getOrdersReportsCsv`;
  static downloadCSVForCompletedAppt = `${Api.newUrl}work_order/getOrdersReportsCsv`;
  static downloadCSVForReviews = `${Api.newUrl}review/getReviewCsv`;
  static getPurchaseOrderByWorkIdCsv = `${Api.newUrl}purchase/getPurchaseOrderByWorkIdCsv`;
  static downloadCSVForInvoiceCreatedDate = `${Api.newUrl}invoice/downloadInvoiceCsv`;
  static downloadCSVForJobsWithoutInvoice = `${Api.newUrl}billing/jobs_without_invoice_csv`;
  static downloadCSVForEstimates = `${Api.newUrl}estimate/downloadEstimateCsv`;
  static downloadCSVForTeamMemberWinRate = `${Api.newUrl}billing/teammember_winrate_list_csv`;
  static downloadCSVForTechStatistic = `${Api.newUrl}billing/technicalStaticsticRangeCsv`;
  static downloadCSVForInvoiceWithPC = `${Api.newUrl}invoice/invoiceCsv`;
  static downloadCSVForPaymentMethods = `${Api.newUrl}billing/technician_paymentmethod_history_csv`;
  static downloadCSVForPaidInvoice = `${Api.newUrl}billing/total_paid_invoices_csv`;
  static downloadCSVForPayment = `${Api.newUrl}transation/getPaymentCsv`;

  static downloadCSVForActiveBillingItems = `${Api.newUrl}transation/getPaymentCsv`;
  static downloadCSVForLineItems = `${Api.newUrl}invoice/invoiceCsv`;
  static downloadCSVForArchivedJob = `${Api.newUrl}work_order/ahs/downloadcsv`;

  static workOrderAcknowledgement = `${Api.newUrl}work_order/workOrderAcknowledgement`;
  static workOrderCompleted = `${Api.newUrl}work_order/workOrderCompleted`;
  static technicianaveragetime = `${Api.newUrl}work_order/technicianaveragetime`;
  static eventsDataUrl = `${Api.newUrl}events`;
  static workorderHistoryDataUrl = `${Api.newUrl}workorder_history`;

  static updatePaymentSettingsUrl = `${Api.newUrl}payment_type/updatePaymentSettings`;
  static leavesUrl = `${Api.newUrl}leaves`;

  //Global Search
  static globalSearchUrl = `${Api.newUrl}dashboard/universalSearch`;
  static technicianById = `${Api.newUrl}technician/all`;

  static diagnosisUrl = `${Api.newUrl}dignosis`;
  static employmentContractUrl = `${Api.newUrl}contract/employment`;
  static employmentUrl = `${Api.newUrl}employment`;

  //status update for pending work orders
  static updatePendingworkOrderstatus = `${Api.newUrl}work_order/adddailyworkpending`;
  static getPendingWorkstatus = `${Api.newUrl}work_order/getdailyworkpending`;
  static updateDailyWorkPending = `${Api.newUrl}work_order/updateDailyWorkPending`;

  //Technician travel report
  static travelReportUrl = `${Api.newUrl}technician/travelReport`;
  static dispatchesUrl = `${Api.newUrl}workorder_history/dispatchescount`;
  static appointmentCountUrl = `${Api.newUrl}workorder_history/appointmentcount`;
  static averageCompletedByMonthUrl = `${Api.newUrl}workorder_history/averagecompletedbymonth`;
  static getAvgTimeToAcceptScheduleUrl = `${Api.newUrl}workorder_history/getAvgTimeToAcceptSchedule`;
  static getAvgTimeToCompleteUrl = `${Api.newUrl}workorder_history/getAvgTimeToComplete`;
  static getAvgTimeOnSiteUrl = `${Api.newUrl}workorder_history/avgtimeonsite`;
  static getFirstTimeFixRateUrl = `${Api.newUrl}workorder_history/firsttimefixrate`;

  static getHourlygraphrecordsUrl = `${Api.newUrl}workorder_history/gethourlygraphrecords`;
  static getscheduledwithin24hoursUrl = `${Api.newUrl}workorder_history/scheduledwithin24hours`;

  //advance paycheck
  static technicianLoanList = `${Api.newUrl}technicianpaycheck/getTechnicianLoanList`;
  static getTechnicianLoanById = `${Api.newUrl}technicianpaycheck/getTechnicianLoanById`;
  static technicianPaycheck = `${Api.newUrl}technicianpaycheck`;

  //Diagnosis Questions
  static getDisQuestions = `${Api.newUrl}dignosis`;

  //Delete variant from line item
  static deleteVariant = `${Api.newUrl}item/deletebyitemidandid`;

  static getJobCounts = `${Api.newUrl}customer/customerlatlong`;
  static getSalesBreakdownUrl = `${Api.newUrl}billing/salesbreakdown`;

  static dispatchReports = `${Api.newUrl}notes/dispatchreport`;
  static getMatchLineItems = `${Api.newUrl}item/getMatchLineItems`;

  static workCompleteOptions = `${Api.newUrl}workorder_history/ahsStatus`;
  static updateStatusToAhs = `${Api.newUrl}workorder_history/updateStatusToAhs`;

  static updateahsBillingAuthorization = `${Api.newUrl}work_order/updateahsbillingauthorization`;

  static updateTransactionID = `${Api.newUrl}technician_transaction/updateTransactionID`;

  static messageUrl = `${Api.newUrl}messages`;
  static getResentMessagesUrl = `${Api.newUrl}messages/getresentmessages`;
  static allPartsUrl = `${Api.newUrl}parts/all`;
  static contractLoanUrl = `${Api.newUrl}contract/loan`;
  static getPartsForWaitingUrl = `${Api.newUrl}workorderparts/getPartsForWaiting`;
  static waitingForPartsUrl = `${Api.newUrl}workorderparts/waitingForParts`;
  static addWaitingWorkOrderPartsUrl = `${Api.newUrl}workorderparts/addWaitingWorkOrderParts`;
  static updateStatusAhsButton = `${Api.newUrl}workorder_history/updatestatusahsbutton`;
  static recording = `${Api.newUrl}recording`;
  static notificationUrl = `${Api.newUrl}notification`;
  static adminUrl = `${Api.newUrl}admin`;
  static readallNotificationUrl = `${Api.newUrl}notification/readAllNotificaton`;
  static lineItemDocumentCsvUrl = `${Api.newUrl}invoice/lineitemdocumentcsv`;
  static workorderPartsShareNotes = `${Api.newUrl}workorderparts/shareNote`;
  static updateResentMessagesUrl = `${Api.newUrl}messages/updateresentmessages`;
  static getPremaintenanceType = `${Api.newUrl}premaintenance/getPremaintenanceType`;
  static cancelPaysheetUrl = `${Api.newUrl}technician_transaction/cancelPaysheet`;
  static getcontracttypesUrl = `${Api.newUrl}contract/getcontracttypes`;
  static sendEmailUrl = `${Api.newUrl}estimate/send_email`;
  static qualityTemplateMsgUrl = `${Api.newUrl}variant-message`;
  static jobsCountUrl = `${Api.newUrl}work_order/jobsCount`;
  static updateLeaveStatus = `${Api.newUrl}leaves/updateLeaveStatus`;
  static sendSmsUrl = `${Api.newUrl}smschat/sendSMSToUser`;
  static getSmsUrl = `${Api.newUrl}smschat`;

  constructor(props) {
    super(props);
    this.getNewUrl = this.getNewUrl.bind(this);
  }
  static getNewUrl() {
    let url = '';
    switch ('development') {
      case 'production':
        url = process.env.REACT_APP_API_URL;
        break;
      case 'development':
        url = process.env.REACT_APP_API_URL_DEV;
        break;
      default:
        // url = 'https://7cryop87i1.execute-api.us-east-1.amazonaws.com/prod/api/';
        // url = ' https://yon5snqw5k.execute-api.ap-south-1.amazonaws.com/dev/api/';
        // local
        // url = 'http://qualityhvacdev.reviewtestlink.com:6000/api/';
        // url = 'http://hvacapi.reviewtestlink.com:3070/api/';
        url = process.env.REACT_APP_API_URL_DEV;
        // Live
        // url = 'https://b64v3wyezf.execute-api.us-east-1.amazonaws.com/dev/api/';
        // url = 'https://2j2ko7vu82.execute-api.us-east-1.amazonaws.com/dev/api/';
        // url = 'http://localhost:3070/api/';

        break;
    }
    return url;
  }
}
