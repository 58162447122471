import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Customer Name',
    field: 'custName',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Contact Person Name',
    field: 'contactName',
    sort: 'disabled',
    // width: 150
  },

  {
    label: 'Job Title',
    field: 'jobTitle',
    sort: 'disabled',
    // width: 150
  },

  {
    label: 'Website',
    field: 'website',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    // width: 200
  },

  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
  },
];

class Tab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      customers: [],
      rows: [],
      customerId: null,
      showDelete: false,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
    };
  }

  componentDidMount = () => {
    customFunctions.setTitle('Customer');
    this.getCustomers();
  };

  getCustomers = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    const endPoint = `${Api.customerUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data.data) {
      this.setState(
        {
          customers: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      alert(message);
    }
  };

  setTableData = () => {
    const { customers, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    customers.map((customer, i) => {
      rows.push({
        index: (page - 1) * limit + (i + 1),
        custName: customer.CustomerName,
        contactName: customer.ContactName,
        jobTitle: customer.ContactTitle,
        website: customer.Website,
        status: (
          <React.Fragment>
            <input
              type="checkbox"
              id={`switch${customer.ID}`}
              switch="info"
              checked={Number(customer.Active) ? 'checked' : ''}
              onChange={(event) => {
                this.changeCustomerStatus(customer, event);
              }}
            />
            <label
              htmlFor={`switch${customer.ID}`}
              data-on-label=""
              data-off-label=""
            ></label>
          </React.Fragment>
        ),
        actions: (
          <React.Fragment>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(`/update-customer/${customer.ID}`);
              }}
            >
              <i className="mdi mdi-lead-pencil" title="Edit Customer"></i>
            </a>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(`/tabdesign`);
              }}
            >
              <i className="mdi mdi-calendar-text" title="Orders"></i>
            </a>

            <a
              href="/"
              className=""
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState({ showDelete: true, customerId: customer.ID });
              }}
            >
              <i
                className="mdi mdi-delete text-danger"
                title="Delete customer"
              ></i>
            </a>
          </React.Fragment>
        ),
      });
      return customer;
    });
    this.setState({ rows });
    this.forceUpdate();
  };

  changeCustomerStatus = async (customer, event) => {
    var isChecked = event.target.checked;
    const { currentPage } = this.state;
    const payload = {
      id: customer.ID,
      isactive: isChecked ? '1' : '0',
    };

    const { data } = await AuthApi.putDataToServer(Api.statusUrl, payload);
    if (data) {
      swal('Customer status changed!', '', 'success');
      this.getCustomers(currentPage);
    } else {
      swal('Please try again later');
    }
  };

  deteleCustomer = async () => {
    const { customerId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.customerUrl}?customer_id=${customerId}`
    );
    if (data) {
      this.setState({ showDelete: false, customerId: null }, () => {
        this.getCustomers();
      });
      alert('Customer deleted');
    } else {
      alert(message);
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCustomers();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getCustomers();
    });
  };

  render() {
    const { keyword, rows, limit, totalCount, currentPage, showDelete } =
      this.state;
    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            {showDelete && (
              <SweetAlert
                title="All the complaints raised by the customer will get deleted"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deteleCustomer();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, customerId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit}>
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6">
                        <div className="col-md-8 offset-md-8">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.props.history.push('/add-customer');
                            }}
                          >
                            Add Customer
                          </button>
                        </div>
                      </div>
                    </div>

                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getCustomers}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(Tab);
