import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import customFunctions from '../../../helper/customFunctions';

export default class AppointmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      columns: [],
      appointmentData: [],
      roleId: null,
      loginId: null,
    };
  }

  componentDidMount = async () => {
    customFunctions.setTitle('All Leaves');
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );

    this.setState(
      {
        roleId: userdata.role_id,
        loginId: userdata.login_id,
        appointmentData: this.props.appointmentData,
      },
      () => {
        this.setTableData();
        this.forceUpdate();
      }
    );

    const columns = [
      {
        label: 'Sr.No.',
        field: 'index',
        sort: 'disabled',
        width: 100,
      },
      {
        label: 'Appointment Date',
        field: 'date',
        sort: 'disabled',
        width: 250,
      },
      {
        label: 'Work Order',
        field: 'workorder',
        sort: 'disabled',
        width: 250,
      },
      {
        label: 'Customer',
        field: 'customer',
        sort: 'disabled',
        width: 180,
      },
      {
        label: 'Status',
        field: 'status',
        sort: 'disabled',
        width: 185,
      },
    ];
    this.setState({ columns });
  };

  setTableData = () => {
    const { appointmentData } = this.state;
    const { currentPage, limit } = this.props;
    const page = currentPage || 1;
    const rows = [];
    appointmentData &&
      appointmentData.map((appointment, i) => {
        appointment.FirstApptDate = customFunctions.changeDateFormatMomentMDY(
          appointment.FirstApptDate
        );
        rows.push({
          index: (page - 1) * limit + (i + 1),
          date: appointment.FirstApptDate,
          workorder: appointment.WorkOrder,
          customer: appointment.CustomerName,
          status: appointment.WorkStatus,
        });
        return appointment;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns } = this.state;

    const { appointmentCallback, limit, currentPage, totalCount } = this.props;
    return (
      <AUX>
        <div className="card-body col-sm-12">
          <div className="mdbtable1" ref={this.anyRef}>
            <MDBDataTable
              // striped
              className="text-center"
              noBottomColumns={true}
              paging={false}
              bordered
              hover
              data={{ columns, rows }}
              searching={false}
              noRecordsFoundLabel="No records found"
              scrollX
              maxHeight="350px"
            />
          </div>
          {totalCount > 10 && (
            <div className="pagination">
              <Pagination
                totalCount={totalCount}
                currentPage={currentPage}
                limit={limit}
                getData={appointmentCallback}
              />
            </div>
          )}
        </div>
      </AUX>
    );
  }
}
