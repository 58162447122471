import { MDBDataTable } from 'mdbreact';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import swal from 'sweetalert';
import Loader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import HelmetComponent from '../Title/index';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Name',
    field: 'module_name',
    sort: 'disabled',
  },
  {
    label: 'Action',
    field: 'action',
    sort: 'disabled',
  },
];

export default class Modules extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
    this.state = {
      module_name: '',
      moduleErr: '',
      rows: [],
      module: [],
      page: 1,
      limit: 10,
      totalCount: 0,
      currentPage: 1,
      keyword: '',
      showDelete: false,
      moduleId: null,
      isShowLoader: false,
      navSkip: null,
      navCurrentPage: null,
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    if (userdata.role_id !== 1) {
      this.props.history.push('/dashboard');
      return;
    }
    customFunctions.setTitle('All Modules');
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.navSkip &&
      this.props.location.state.navCurrentPage
    ) {
      this.setState(
        {
          navSkip: this.props.location.state.navSkip,
          navCurrentPage: this.props.location.state.navCurrentPage,
        },
        () => this.getModules()
      );
    } else {
      this.getModules();
    }
  };

  setTableData = (skip, npage) => {
    this.setState({
      currentPage: npage,
    });
    const { module, limit } = this.state;
    const page = npage;
    const rows = [];
    module &&
      module.map((mod, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          module_name: mod.module_name,
          action: (
            <React.Fragment>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push({
                    pathname: '/add-module',
                    state: {
                      detail: mod,
                      navSkip: skip,
                      navCurrentPage: npage,
                    },
                  });
                }}
              >
                <i
                  className="mdi mdi-lead-pencil"
                  id="editModule"
                  title="Edit Module"
                ></i>
              </a>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, moduleId: mod.module_id });
                }}
              >
                <i className="mdi mdi-delete text-danger" title="Delete"></i>
              </a>
            </React.Fragment>
          ),
        });
        return mod;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  getModules = async (currentPage) => {
    this.setState({ currentPage, isShowLoader: true });
    const { limit, keyword, navSkip, navCurrentPage } = this.state;
    if (navCurrentPage === 1) {
      this.setState({ navCurrentPage: null });
    }
    const page = navCurrentPage ? navCurrentPage : currentPage || 1;
    const skip = navSkip ? navSkip : page * limit - limit;
    const endPoint = `${Api.moduleUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState(
        {
          module: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData(skip, page);
          this.forceUpdate();
        }
      );
    } else {
      swal(message, '', 'error');
    }
    this.setState({
      isShowLoader: false,
    });
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getModules();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getModules();
    });
  };

  deleteModule = async () => {
    const { moduleId, currentPage } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.moduleUrl}?module_id=${moduleId}`
    );
    if (data) {
      this.setState({ showDelete: false, moduleId: null }, () => {
        swal('Module deleted!', '', 'success').then(() => {
          this.getModules(currentPage);
        });
      });
    } else {
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      keyword,
      showDelete,
      isShowLoader,
      navCurrentPage,
      navSkip,
    } = this.state;
    return (
      <AUX>
        <Loader showLoader={isShowLoader} needFullPage={false} />
        <HelmetComponent title="All Modules" />
        {showDelete && (
          <div className="sweetalert">
            <SweetAlert
              title="Are you sure, do you want to delete this module ?"
              error
              showCancel
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={() => {
                this.deleteModule();
              }}
              onCancel={() => {
                this.setState({ showDelete: false, moduleId: null });
              }}
            >
              You won't be able to revert this!
            </SweetAlert>
          </div>
        )}
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit} autoComplete="off">
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search by name"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div className="col-sm-6 text-right">
                        <button
                          className="btn btn-primary waves-effect waves-light"
                          onClick={() => {
                            this.props.history.push('/add-module');
                          }}
                        >
                          Add Module
                        </button>
                      </div>
                    </div>
                    <div ref={this.anyRef}>
                      <MDBDataTable
                        // striped
                        noBottomColumns={true}
                        paging={false}
                        bordered
                        hover
                        data={{ columns, rows }}
                        searching={false}
                        noRecordsFoundLabel="No records found"
                      />
                    </div>
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={
                          navSkip && navCurrentPage
                            ? this.setState(
                                {
                                  navSkip: null,
                                  navCurrentPage: null,
                                },
                                () => this.getModules
                              )
                            : this.getModules
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
