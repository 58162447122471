import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import Pagination from '../../../components/Pagination';
import { MDBDataTable } from 'mdbreact';
import customFunctions from '../../../helper/customFunctions';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
  },
  {
    label: 'Customer Name',
    field: 'custName',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Contact Person Name',
    field: 'contactName',
    sort: 'disabled',
    // width: 150
  },

  {
    label: 'Job Title',
    field: 'jobTitle',
    sort: 'disabled',
    // width: 150
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
  },
];

class Profit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyword: '',
      customers: [],
      rows: [],
      customerId: null,
      custmrnme: '',
      selectedCustomerName: '',
      customerNameData: [],
      jobTotal: '',
      paymentTotal: '',
      poTotal: '',
      payrollCommission: '',
      profitLoss: '',
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
    };
  }

  componentDidMount = () => {
    customFunctions.setTitle('Customer Profit/Loss List');
    this.getCustomerLIst();
    this.getCustomers();
  };

  getCustomers = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, keyword } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    const endPoint = `${Api.customerUrl}?skip=${skip}&limit=${limit}&search=${keyword}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data.data) {
      this.setState(
        {
          customers: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
  };

  setTableData = () => {
    const { customers, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    customers.map((customer, i) => {
      rows.push({
        index: (page - 1) * limit + (i + 1),
        custName: customer.CustomerName,
        contactName: customer.ContactName,
        jobTitle: customer.ContactTitle,

        actions: (
          <React.Fragment>
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.props.history.push(`/profitloss/${customer.ID}`);
              }}
            >
              <i className="mdi mdi-note-text" title="Profit/Loss"></i>
            </a>
          </React.Fragment>
        ),
      });
      return customer;
    });
    this.setState({ rows });
    this.forceUpdate();
  };

  getProfitLossData = async (selectedCustomerName) => {
    const endPoint = `${Api.profitLossUrl}/by_customer_id?customer_id=${selectedCustomerName}`;
    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        jobTotal: data.data.job_total,
        paymentTotal: data.data.payment_total,
        poTotal: data.data.po_total,
        payrollCommission: data.data.payroll_total,
        profitLoss: data.data.profit_loss,
      });
    } else {
      alert(message);
    }
  };

  getCustomerLIst = async () => {
    const endPoint = `${Api.customerUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        customerNameData: data.data.data,
      });
    }
  };

  onSearchSubmit = (e) => {
    e.preventDefault();
    this.getCustomers();
  };

  clearSearch = () => {
    this.setState({ keyword: '' }, () => {
      this.getCustomers();
    });
  };

  render() {
    const { keyword, rows, limit, totalCount, currentPage } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <form onSubmit={this.onSearchSubmit} autoComplete="off">
                          <div className="form-group row">
                            <div className="col-sm-6">
                              <input
                                className="form-control"
                                value={keyword}
                                type="text"
                                placeholder="Search here"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ keyword: e.target.value });
                                }}
                                required={true}
                              />
                            </div>
                            <div className="col-sm-6">
                              <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Search
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.clearSearch();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                    <br></br>
                    <MDBDataTable
                      // striped
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    <div className="pagination">
                      <Pagination
                        totalCount={totalCount}
                        currentPage={currentPage}
                        limit={limit}
                        getData={this.getCustomers}
                      />
                    </div>
                  </div>{' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </AUX>
    );
  }
}
export default withRouter(Profit);
