import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../../helper/authApi';
import Api from '../../../../helper/api';
import customFunctions from '../../../../helper/customFunctions';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../../components/Pagination';

class AchivedJobsTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      columns: [],
      rows: [],
      currentPage: 1,
      page: 1,
      limit: 10,
      skip: 0,
      totalCount: 0,
      pagination: {
        current_page: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      statuses: '',
      startDate: '',
      endDate: '',
      filterApplied: false,
    };
  }
  async componentDidMount() {
    const columns = [
      {
        label: 'Sr. No.',
        field: 'index',
        width: 70,
      },
      {
        label: 'JOB ID',
        field: 'workorder',
        width: 150,
      },
      {
        label: 'DATE ARCHIVED',
        field: 'dateArchived',
        width: 150,
      },
      {
        label: 'CUSTOMER',
        field: 'customer',
        width: 150,
      },
      {
        label: 'TITLE',
        field: 'title',
        width: 150,
      },
      {
        label: 'LABEL',
        field: 'label',
        width: 150,
      },
      {
        label: 'SOURCE',
        field: 'source',
        width: 150,
      },
      {
        label: 'BRAND NAME',
        field: 'brandname',
        width: 150,
      },
      {
        label: 'VIEW JOB',
        field: 'actions',
        width: 150,
      },
    ];
    this.setState(
      { columns, startDate: this.props.startDate, endDate: this.props.endDate },
      () => {
        this.getArchivedJobs();
      }
    );
  }

  componentWillReceiveProps(props) {
    if (props.filterApplied === true) {
      this.setState(
        {
          startDate: props.startDate,
          endDate: props.endDate,
          statuses: props.statuses,
        },
        () => {
          this.getArchivedJobs();
        }
      );
    }
  }

  getArchivedJobs = async (currentPage) => {
    let { pagination, startDate, endDate, statuses } = this.state;

    this.setState({ currentPage });
    const { limit } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.getAhsWorkOrder}?skip=${skip}&limit=${pagination.itemsPerPage}&isArchive=1&from_date=${new Date(startDate).toISOString()}&to_date=${new Date(endDate).toISOString()}`;
    endPoint += statuses ? `&work_status=${statuses}` : '';

    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      const paginationParams = {
        current_page: currentPage,
        itemsPerPage: 10,
        totalItems: data.data.total_count,
      };
      this.setState(
        {
          jobs: data.data.data,
          totalCount: data.data.total_count,
          pagination: paginationParams,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    }
    this.props.callback();
  };

  downloadCSVForArchivedJob = async () => {
    let { startDate, endDate, statuses } = this.state;
    startDate = startDate ? customFunctions.getDate(startDate) : '';
    endDate = endDate ? customFunctions.getDate(endDate) : '';
    let endPoint = `${Api.downloadCSVForArchivedJob}?skip=0&limit=10000&from_date=${startDate}&to_date=${endDate}&work_status=${statuses}&isArchive=1`;
    window.open(endPoint, '_blank');
  };

  setTableData = () => {
    const { jobs, limit, currentPage } = this.state;
    const page = currentPage || 1;
    const rows = [];
    jobs &&
      jobs.length &&
      jobs.map((jobs, i) => {
        jobs.Created = customFunctions.changeDateFormatMomentMDY(jobs.Created);
        rows.push({
          index: (page - 1) * limit + (i + 1),
          workorder: jobs.WorkOrder || '-',
          dateArchived: jobs.Updated
            ? customFunctions.changeDateFormatMomentMDY(jobs.Updated)
            : '-',
          customer: jobs.CustomerName || '-',
          title: jobs.job_title || '-',
          label: jobs.recall === 1 ? 'Recall' : '-',
          source: jobs.source || '-',
          brandname: jobs.source || '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(`/update-order/${jobs.ID}`, {
                    orderDetails: jobs,
                    type: 'jobs',
                  });
                }}
              >
                <i className="mdi mdi-eye" id="viewJob" title="View Job"></i>
              </a>
            </React.Fragment>
          ),
        });
        return jobs;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  render() {
    const { rows, columns, limit, totalCount, currentPage } = this.state;
    return (
      <>
        <div className="new-header-style">
          <h6>Archived Jobs for Date Range</h6>
          {rows && rows.length ? (
            <button
              type="submit"
              style={{ marginBottom: '5px' }}
              className="btn btn-primary waves-effect waves-light"
              onClick={() => {
                this.downloadCSVForArchivedJob();
              }}
            >
              <i className="mdi mdi-download"></i> CSV
            </button>
          ) : (
            ''
          )}
        </div>
        {/* <Table
        columns={columns}
        rows={rows}
        showPagination
        paginationProps={{
          ...pagination,
          activePage: pagination.current_page,
          onPageChange: (pageNo) => {
            this.getArchivedJobs(pageNo);
          },
        }}
        tableclassName="table-bordered"
        dropdownContainerclassName="mb-3"
        emptyRender={() => <p className="text-center mb-0">No record found</p>}
      /> */}
        <div ref={this.anyRef}>
          <MDBDataTable
            // striped
            noBottomColumns={true}
            paging={false}
            bordered
            hover
            data={{ columns, rows }}
            searching={false}
            noRecordsFoundLabel="No records found"
            scrollX
            maxHeight="400px"
          />
        </div>
        {totalCount > 10 && (
          <div className="pagination">
            <Pagination
              totalCount={totalCount}
              currentPage={currentPage}
              limit={limit}
              getData={this.getArchivedJobs}
            />
          </div>
        )}
      </>
    );
  }
}

export default withRouter(AchivedJobsTables);
