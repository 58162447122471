// import Edit from '@material-ui/icons/Edit';
import Edit from '@mui/icons-material/Edit';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { withRouter } from 'react-router-dom';
import swal from 'sweetalert';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import customFunctions from '../../../helper/customFunctions';
import AUX from '../../../hoc/Aux_';
import EditMainAccount from '../Workorder/editMainAccount';
import EditSubAccountData from '../Workorder/editSubAccountData';

class AddExpense extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expense_type: 1,
      poId1: '',
      po: '',
      orderId: null,
      poDate: new Date(),
      mainAccount: '',
      subAccount: '',
      poAmount: '',
      ExpenseDesc: '',
      startDate: '',
      mainAcc: [],
      subAccList: [],
      workOrderId: '',
      workOrderType: '',
      workOrderDate: '',
      WorkComplete: [
        {
          id: 1,
          name: 'Yes',
        },
        {
          id: 0,
          name: 'No',
        },
      ],
      wrntyCode: '',
      wrntyErr: '',
      cntctPrsn: '',
      tech: null,
      technicianErr: '',
      jobtitle: '',
      repairDesc: '',
      repairDescErr: '',
      customerId: '',
      custmrAddress: '',
      custmrAddressErr: '',
      workCompleteValue: null,
      workcompleteValueErr: '',
      completionDate: '',
      completionDateErr: '',
      paymentAmount: '',
      paymentAmountErr: '',
      ApptDate: '',
      ApptDateErr: '',
      installerId: '',
      installerIdErr: '',
      paymentDate: null,
      paymentDateErr: '',
      crditCardAuthor: '',
      crditCardAuthorErr: '',
      selectedPaymentMode: '',
      selectedPaymentModeErr: '',
      audioPayment: '',
      audioPaymentErr: '',
      credit: '',
      creditErr: '',
      payrollFourmula: '',
      payrollFourmulaErr: '',
      totalCorrespond: '',
      totalCorrespondErr: '',
      workOrderIdErr: '',
      ListData: [],
      workOrderTypeId: null,
      homeWarrantyCodeId: null,
      technicianId: null,
      techniciandata: [],
      workorderdata: [],
      homewrntycodedata: [],
      customerNameData: [],
      id: null,
      jobTotal: '',
      poamount: '',
      zipCode: '',
      jobTotalErr: '',
      poamountErr: '',
      paymentTotal: '',
      paymentTotalErr: '',
      techDeduction: '',
      techDeductionErr: '',
      zipCodeErr: '',
      createdDateTime: '',
      updatedTime: '',
      apptTimeErr: '',
      updatedApptDate: '',
      editType: false,
      paymentTypeList: [],
      payment_type: '',
      updatedCompletionDate: '',
      customerRedirectID: null,
      date: '',
      noteForReason: '',
      ToTime: new Date(),
      FromTime: new Date(),
      installerCost: '',
      expenseData: [],
      defaultExpenseId: '',
      workOrder: '',
      technician: '',
      urlParam: '',
    };
  }

  componentDidMount = async () => {
    const url_segment = this.props.match.url.split('/');
    if (url_segment && url_segment.includes('edit-technician')) {
      this.setState({ urlParam: 'technician' });
    } else {
      this.setState({ urlParam: 'dispatcher' });
    }
    if (
      this.props &&
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.id &&
      this.props.location.state.month &&
      this.props.location.state.year
    ) {
      var id = this.props.location.state.id;
      var month = this.props.location.state.month;
      var year = this.props.location.state.year;

      const endPoint = `${Api.profitLossExpense}?year=${year}&month=${month}&subaccount_id=${id}`;
      const { data } = await AuthApi.getDataFromServer(endPoint);

      if (data && data.data) {
        this.setState({
          expenseData: data.data,
          defaultExpenseId: data.data[0].ID,
        });
      }
    }

    customFunctions.setTitle('Purchase Order Details');

    this.getMainAccountData();
    this.getPoId();
    this.getWorkOrder();
    this.getTechnician();
    this.getExpenseRecord();
    this.getWarrantyCode();
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  validateExpense = () => {
    const { poDate, mainAccount, subAccount, poAmount } = this.state;

    let poDateErr = '',
      mainAccountErr = '',
      subAccountErr = '',
      poAmountErr = '',
      isValid = true;

    if (poDate === null) {
      poDateErr = 'Order Date is required';
      isValid = false;
    }
    if (mainAccount === '') {
      mainAccountErr = 'Main Account is required';
      isValid = false;
    }
    if (subAccount === '') {
      subAccountErr = 'Sub Account is required';
      isValid = false;
    }

    if (poAmount === '') {
      poAmountErr = 'PO Amount is required';
      isValid = false;
    }

    this.setState({
      poDateErr,
      mainAccountErr,
      subAccountErr,
      poAmountErr,
    });

    if (isValid) {
      this.createRegularPurchaseOrder();
    }
  };

  createRegularPurchaseOrder = async (e) => {
    const {
      poDate,
      orderId,
      mainAccount,
      subAccount,
      poAmount,
      ExpenseDesc,
      po,
      expense_type,
    } = this.state;

    const postData = {
      expense_code: po,
      po_date: new Date(poDate).toISOString(),
      main_account: mainAccount,
      sub_account: subAccount,
      po_amount: poAmount,
      description: ExpenseDesc,
      expense_type,
    };

    const callback = orderId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = `${Api.createPurchaseOrder}`;

    if (orderId) {
      postData.id = parseInt(orderId);
    }
    const { data } = await callback(endPoint, postData);

    if (data) {
      if (orderId) {
        var $this = this;
        swal('New expense Updated', '', 'success');
        setTimeout(() => {
          $this.props.history.goBack();
        }, 100);
        return;
      } else {
        swal('New expense Created', '', 'success');
        this.flushAllData();
        setTimeout(() => {
          var $this = this;
          $this.props.history.goBack();
        }, 100);
        return;
      }
    }
  };

  flushAllData = async () => {
    this.setState({
      poDate: '',
      mainAccount: '',
      subAccount: '',
      poAmount: '',
      workOrder: '',
      cstomer: '',
      ExpenseDesc: '',
      poId2: '',
      code: '',
      pieceEquipInst: '',
      technician: '',
      installerId: '',
      supplyHouse: '',
      miscInvoice: '',
      miscPoAmount: '',
      equipPo: '',
      equipPoAmount: '',
      miscPo: '',
      purchaseDesc: '',
    });
  };

  getPoId = async () => {
    const endPoint = `${Api.createPurchaseOrder}/po_id`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        po: data.data.ExpenseCode,
      });
    }
  };

  getWorkOrder = async () => {
    const endPoint = `${Api.getWorkOrder}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        workOrderData: data.data.data,
      });
    }
  };

  getTechnician = async () => {
    const { urlParam } = this.state;
    const endPoint = `${Api.AddtechnicianUrl}?role=${
      urlParam === 'technician' ? 5 : 3
    }`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        technicianList: data.data.data,
      });
    }
  };

  getMainAccountData = async () => {
    const endPoint = `${Api.AddListTypeUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        mainAcc: data.data.data,
      });
    }
  };

  getSubAccountData = async (mainAccount) => {
    const endPoint = `${Api.ListItemByType}?type_id=${mainAccount}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState({
        subAccList: data.data.data,
      });
    }
  };

  handleChange(date) {
    this.setState({
      startDate: date,
    });
  }

  onRegularExpense = () => {
    this.setState({
      expense_type: 1,
    });
  };

  onPOExpense = () => {
    this.setState({
      expense_type: 0,
    });
  };

  getExpenseRecord = async (orderId) => {
    const { defaultExpenseId } = this.state;

    let endPoint;
    if (orderId) {
      endPoint = `${Api.createPurchaseOrder}/by_id?purchase_id=${orderId}`;
    }
    if (defaultExpenseId) {
      endPoint = `${Api.createPurchaseOrder}/by_id?purchase_id=${defaultExpenseId}`;
    }
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState({
        mainAccount: data.data.MainAccount,
        installerCost: data.data.InstallerCost,
        subAccount: data.data.SubAccount,
        technician: data.data.technician_id,
        cstomer: data.data.Customer_ID,
        poDate:
          data.data.ExpenseDate !== null ? new Date(data.data.ExpenseDate) : '',
        expense_type: data.data.ExpenseType,
        ExpenseDesc: data.data.ExpenseDescription,
        supplyHouse: data.data.SupplyHouse,
        installerId: data.data.InstallerID,
        miscPoAmount: data.data.MiscPOAmount,
        pieceEquipInst: data.data.PiecesOfEquipmentInstall,
        purchaseDesc: data.data.DescriptionofPurchase,
        code: data.data.HomeWarrantyCode,
        poId1: data.data.ExpenseCode,
        poId2: data.data.PO,
        miscInvoice: data.data.MiscInvoice,
        equipPoAmount: data.data.MiscPOAmount1,
        purchaseDesc: data.data.DescriptionofPurchase, // eslint-disable-line
        poAmount: data.data.ExpenseAmount,
        miscPo: data.data.MiscPO,
        equipPo: data.data.EquipPO,
        purchaseorderid: data.data.WorkOrderID,
        orderId: data.data.ID,
      });
      const { mainAccount } = this.state;
      this.updateData(mainAccount);
      if (data.data.WorkOrderID) {
        this.getWorkOrderDetailsById(data.data.WorkOrderID);
      }
    }
  };

  updateData = async (mainAccount) => {
    const endPoint = `${Api.ListItemByType}?type_id=${mainAccount}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data && data.data && data.data.data) {
      this.setState({
        subAccList: data.data.data,
      });
    }
  };

  getWorkOrderDetailsById = async (orderId) => {
    const endPoint = `${Api.orderById}?work_order_id=${orderId}&type=1`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    if (data) {
      this.setState({
        cstomer: data.data[0].CustomerID,
        technician: data.data[0].technician_id,
        code: data.data[0].HomeWarrantyCode,
      });
    }
  };

  getWarrantyCode = async () => {
    const endPoint = `${Api.AddwarrentyCodeUrl}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);
    if (data) {
      this.setState({
        wrntyCode: data.data.data,
      });
    }
  };

  validatePurchaseOrder = () => {
    const {
      poDate,
      mainAccount,
      subAccount,
      poAmount,
      purchaseorderid,
      technician,
    } = this.state;

    let poDateErr = '',
      mainAccountErr = '',
      subAccountErr = '',
      poAmountErr = '',
      WorkOrderErr = '',
      technicianErr = '';

    let isValid = true;

    if (poDate === null) {
      poDateErr = 'Order Date is required';
      isValid = false;
    }

    if (mainAccount === '') {
      mainAccountErr = 'Main Account is required';
      isValid = false;
    }

    if (subAccount === '') {
      subAccountErr = 'Sub Account is required';
      isValid = false;
    }

    if (poAmount === '') {
      poAmountErr = 'PO Amount is required';
      isValid = false;
    }

    if (purchaseorderid === '') {
      WorkOrderErr = 'Work order is required';
      isValid = false;
    }

    if (technician === 'null') {
      technicianErr = 'Technician is required';
      isValid = false;
    }

    this.setState({
      poDateErr,
      mainAccountErr,
      subAccountErr,
      poAmountErr,
      WorkOrderErr,
      technicianErr,
    });

    if (isValid) {
      this.createPurchaseOrder();
    }
  };

  createPurchaseOrder = async (e) => {
    const {
      poDate,
      orderId,
      mainAccount,
      subAccount,
      poAmount,
      ExpenseDesc,
      cstomer,
      code,
      pieceEquipInst,
      technician,
      installerId,
      purchaseDesc,
      supplyHouse,
      expense_type,
      installerCost,
      po,
      purchaseorderid,
    } = this.state;

    const postData = {
      expense_code: po,
      po_date: new Date(poDate).toISOString(),
      main_account: mainAccount,
      sub_account: subAccount,
      po_amount: poAmount || null,
      work_order: purchaseorderid,
      customer_id: cstomer,
      description: ExpenseDesc,
      desc_of_purchase: purchaseDesc,
      warranty_code: code,
      pieces_of_equipment: pieceEquipInst || null,
      technician: technician,
      installer_id: installerId,
      supply_house: supplyHouse,
      expense_type,
      installer_cost: installerCost,
    };

    const callback = orderId
      ? AuthApi.putDataToServer
      : AuthApi.postDataToServer;
    const endPoint = `${Api.createPurchaseOrder}`;

    if (orderId) {
      postData.id = parseInt(orderId);
    }

    const { data } = await callback(endPoint, postData);

    if (data) {
      if (orderId) {
        var $this = this;
        swal('Purchase Order Updated', '', 'success');
        setTimeout(() => {
          $this.props.history.goBack();
        }, 100);
        return;
      } else {
        swal('Purchase Order Created', '', 'success');
        this.flushAllData();
        setTimeout(() => {
          var $this = this;
          $this.props.history.goBack();
        }, 100);
        return;
      }
    }
  };

  render() {
    const {
      expense_type,
      po,
      poDateErr,
      subAccountErr,
      ExpenseDescErr,
      subAccList,
      poDate,
      mainAccount,
      subAccount,
      poAmount,
      ExpenseDesc,
      mainAcc,
      code,
      wrntyCode,
      workOrderData,
      pieceEquipInst,
      technicianList,
      technician,
      installerId,
      purchaseDesc,
      mainAccountErr,
      poAmountErr,
      WorkOrderErr,
      technicianErr,
      supplyHouse,
      installerCost,
      purchaseorderid,
      expenseData,
    } = this.state;

    return (
      <AUX>
        <div className="page-content-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-responsive">
                      {expenseData.length > 1 && (
                        <table
                          style={{
                            display: 'block',
                            height: '153px',
                            'overflow-y': 'scroll',
                            width: '362px',
                          }}
                          className="table table-hover table-bordered"
                          id=""
                        >
                          <thead>
                            <tr className="table-danger">
                              <th colspan="2">
                                Multiple Expenses Found (Select below to
                                Navigate)
                              </th>
                            </tr>
                            <tr>
                              <th className="text-danger">Expense Date </th>
                              <th className="text-danger">Expense Amount </th>
                            </tr>
                          </thead>
                          <tbody>
                            {expenseData &&
                              expenseData.map((expense) => (
                                <>
                                  <tr>
                                    <td>
                                      <a
                                        href="/"
                                        style={{ 'text-color': '#FF0000' }}
                                        className="text-danger"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.getExpenseRecord(expense.ID);
                                        }}
                                      >
                                        {customFunctions.getDate(
                                          expense.ExpenseDate
                                        )}
                                      </a>
                                    </td>
                                    <td className="text-danger">
                                      <a
                                        href="/"
                                        className="text-danger"
                                        style={{ 'text-color': '#FF0000' }}
                                        onClick={(e) => {
                                          e.preventDefault();
                                          this.getExpenseRecord(expense.ID);
                                        }}
                                      >
                                        {expense.ExpenseAmount}
                                      </a>
                                    </td>
                                  </tr>
                                </>
                              ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                    <div
                      className="card  border-dark mb-3"
                      style={{ width: '28rem' }}
                    >
                      <div className="card-header">Expense Type</div>
                      <div className="card-body">
                        <div
                          className="btn-group mr-2"
                          role="group"
                          aria-label="First group"
                        >
                          <button
                            onClick={this.onRegularExpense}
                            style={{
                              backgroundColor:
                                this.state.expense_type === 1 ? 'black' : '',
                            }}
                            className="btn btn-outline-secondary"
                          >
                            Regular Expenses
                          </button>
                        </div>
                        <div
                          className="btn-group mr-2"
                          role="group"
                          aria-label="Second group"
                        >
                          <button
                            onClick={this.onPOExpense}
                            style={{
                              backgroundColor:
                                this.state.expense_type === 0 ? 'black' : '',
                            }}
                            className="btn btn-outline-secondary"
                          >
                            PO Expenses
                          </button>
                        </div>
                      </div>
                    </div>
                    <br></br>
                    {expense_type === 1 && (
                      <div id="regularExpense">
                        <form
                          onSubmit={this.handleSubmit}
                          id="regular_expense"
                          autoComplete="off"
                        >
                          <div className="form-group row">
                            <label
                              htmlFor="expense#"
                              className="col-sm-2 col-form-label"
                            >
                              Expense#
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={po}
                                placeholder="(New)"
                                type="text"
                                id="example-text-input"
                                onChange={(e) => {
                                  this.setState({ po: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="expensedate"
                              className="col-sm-2 col-form-label"
                            >
                              Expense Date{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-4">
                              <DatePicker
                                className="form-control"
                                selected={poDate}
                                onChange={(e) => {
                                  this.setState({ poDate: e });
                                }}
                                name="startDate"
                                dateFormat="MM/dd/yyyy"
                              />

                              <span id="err">{poDateErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-2 col-form-label"
                            >
                              Main Account<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-3">
                              <select
                                className="form-control"
                                value={mainAccount}
                                onChange={(e) => {
                                  this.setState({
                                    mainAccount: e.target.value,
                                  });
                                  this.getSubAccountData(e.target.value);
                                }}
                              >
                                <option value="">Select Main account </option>
                                {mainAcc.map((name) => (
                                  <option
                                    value={name.ID}
                                  >{`${name.ListType}`}</option>
                                ))}
                              </select>
                              <span id="err">{mainAccountErr}</span>
                            </div>
                            <div className="col-sm-1">
                              <button className="btn btn-light">
                                <a
                                  href="/"
                                  className=""
                                  data-toggle="modal"
                                  data-target="#mainaccount"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Edit />
                                </a>
                              </button>
                            </div>
                            <label
                              htmlFor="example-search-input"
                              className="col-sm-2 col-form-label"
                            >
                              Sub Account<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-3">
                              <select
                                className="form-control"
                                value={subAccount}
                                onChange={(e) => {
                                  this.setState({ subAccount: e.target.value });
                                }}
                              >
                                <option value="">Select Sub Account</option>
                                {subAccList &&
                                  subAccList.map((name) => (
                                    <option
                                      value={name.ID}
                                    >{`${name.ListItem}`}</option>
                                  ))}
                              </select>
                              <span id="err">{subAccountErr}</span>
                            </div>
                            <div className="col-sm-1">
                              <button className="btn btn-light">
                                <a
                                  href="/"
                                  className=""
                                  data-toggle="modal"
                                  data-target="#subaccount"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Edit />
                                </a>
                              </button>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-2 col-form-label"
                            >
                              Expense Amount
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-9">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="jobTotal"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  value={poAmount}
                                  placeholder="$0.00"
                                  type="text"
                                  id="example-text-input"
                                  onChange={(e) => {
                                    const re = /^[0-9\b]+$/;
                                    if (
                                      e.target.value === '' ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState({
                                        poAmount: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <span id="err">{poAmountErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="example-text-input"
                              className="col-sm-2 col-form-label"
                            >
                              Expense Description{' '}
                            </label>
                            <div className="col-10">
                              <textarea
                                className="form-control"
                                type="textarea"
                                placeholder="expense description goes here"
                                id="example-text-input"
                                style={{ resize: 'none' }}
                                value={ExpenseDesc}
                                rows="4"
                                cols="90"
                                onChange={(e) => {
                                  this.setState({
                                    ExpenseDesc: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group row d-flex justify-content-center">
                            <div className="col-sm-3 col-md-6">
                              <button
                                type="submit"
                                onClick={this.validateExpense}
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Submit
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.flushAllData();
                                  this.props.history.push('/orderDetails');
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                    {expense_type === 0 && (
                      <div id="poExpense">
                        <form onSubmit={this.handleSubmit} autoComplete="off">
                          <div className="form-group row">
                            <label
                              htmlFor="po#"
                              className="col-sm-2 col-form-label"
                            >
                              PO #
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={po}
                                type="text"
                                id="po#"
                                onChange={(e) => {
                                  this.setState({ po: e.target.value });
                                }}
                                readOnly
                              />
                            </div>
                            <label
                              htmlFor="podate"
                              className="col-sm-2 col-form-label"
                            >
                              PO Date
                            </label>
                            <div className="col-sm-4">
                              <DatePicker
                                className="form-control"
                                selected={poDate}
                                onChange={(e) => {
                                  this.setState({ poDate: e });
                                }}
                                name="startDate"
                                dateFormat="MM/dd/yyyy"
                              />
                              <span id="err">{poDateErr}</span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="mainAccount"
                              className="col-sm-2 col-form-label"
                            >
                              Main Account<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-3">
                              <select
                                className="form-control"
                                value={mainAccount}
                                onChange={(e) => {
                                  this.setState({
                                    mainAccount: e.target.value,
                                  });
                                  this.getSubAccountData(e.target.value);
                                }}
                              >
                                <option value="">Select Main Account </option>
                                {mainAcc.map((name) => (
                                  <option
                                    value={name.ID}
                                  >{`${name.ListType}`}</option>
                                ))}
                              </select>
                              <span id="err">{mainAccountErr}</span>
                            </div>
                            <div className="col-sm-1">
                              <button className="btn btn-light">
                                <a
                                  href="/"
                                  className=""
                                  data-toggle="modal"
                                  data-target="#mainaccount"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Edit />
                                </a>
                              </button>
                            </div>
                            <label
                              htmlFor="subAccount"
                              className="col-sm-2 col-form-label"
                            >
                              Sub Account<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-3">
                              <select
                                className="form-control"
                                value={subAccount}
                                onChange={(e) => {
                                  this.setState({ subAccount: e.target.value });
                                }}
                              >
                                <option value="">Select subaccount</option>
                                {subAccList &&
                                  subAccList.map((name) => (
                                    <option
                                      value={name.ID}
                                    >{`${name.ListItem}`}</option>
                                  ))}
                              </select>
                              <span id="err">{subAccountErr}</span>
                            </div>
                            <div className="col-sm-1">
                              <button className="btn btn-light">
                                <a
                                  href="/"
                                  className=""
                                  data-toggle="modal"
                                  data-target="#subaccount"
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <Edit />
                                </a>
                              </button>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="poamount"
                              className="col-sm-2 col-form-label"
                            >
                              PO Amount<span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-4">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="jobTotal"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  value={poAmount}
                                  placeholder="$0.00"
                                  type="text"
                                  id="poamount"
                                  onChange={(e) => {
                                    const re = /^[0-9]*\.?[0-9]*$/;
                                    if (
                                      e.target.value === '' ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState({
                                        poAmount: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                              <span id="err">{poAmountErr}</span>
                            </div>
                            <label
                              htmlFor="installercost"
                              className="col-sm-2 col-form-label"
                            >
                              Installer Cost
                            </label>
                            <div className="col-sm-4">
                              <div className="input-group">
                                <div className="input-group-prepend">
                                  <span
                                    className="input-group-text"
                                    id="jobTotal"
                                  >
                                    $
                                  </span>
                                </div>
                                <input
                                  className="form-control"
                                  value={installerCost}
                                  placeholder="$0.00"
                                  type="text"
                                  id="installercost"
                                  onChange={(e) => {
                                    const re = /^[0-9]*\.?[0-9]*$/;
                                    if (
                                      e.target.value === '' ||
                                      re.test(e.target.value)
                                    ) {
                                      this.setState({
                                        installerCost: e.target.value,
                                      });
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="expensedesc"
                              className="col-sm-2 col-form-label"
                            >
                              Expense Description
                            </label>
                            <div className="col-10">
                              <textarea
                                className="form-control"
                                type="textarea"
                                style={{ resize: 'none' }}
                                placeholder="expense description goes here"
                                id="expensedesc"
                                value={ExpenseDesc}
                                rows="4"
                                cols="90"
                                onChange={(e) => {
                                  this.setState({
                                    ExpenseDesc: e.target.value,
                                  });
                                }}
                              />
                              <span id="err">{ExpenseDescErr}</span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="workorder"
                              className="col-sm-2 col-form-label"
                            >
                              Work Order #{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-3">
                              <select
                                className="form-control"
                                value={purchaseorderid}
                                onChange={(e) => {
                                  this.setState({
                                    purchaseorderid: e.target.value,
                                  });
                                  this.getWorkOrderDetailsById(e.target.value);
                                }}
                              >
                                <option value="">Select Order</option>
                                {workOrderData &&
                                  workOrderData.map((name) => (
                                    <option
                                      value={name.ID}
                                    >{`${name.WorkOrder}`}</option>
                                  ))}
                              </select>
                              <span id="err">{WorkOrderErr}</span>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label
                              htmlFor="homerntycode"
                              className="col-sm-2 col-form-label"
                            >
                              Home Warranty Code{' '}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-4">
                              <select
                                className="form-control"
                                value={code}
                                onChange={(e) => {
                                  this.setState({ code: e.target.value });
                                }}
                                disabled="true"
                              >
                                <option value="">Select Code</option>
                                {wrntyCode &&
                                  wrntyCode.map((name) => (
                                    <option
                                      value={name.code}
                                    >{`${name.code}`}</option>
                                  ))}
                              </select>
                            </div>
                            <label
                              htmlFor="pieceofequip"
                              className="col-sm-2 col-form-label"
                            >
                              Pieces Of Equip Install ?
                            </label>
                            <div className="col-sm-4">
                              <input
                                className="form-control"
                                value={pieceEquipInst}
                                type="text"
                                placeholder="pieces of equip install ?"
                                id="pieceofequ"
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      pieceEquipInst: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="technician"
                              className="col-sm-2 col-form-label"
                            >
                              Technician <span className="text-danger">*</span>
                            </label>
                            <div className="col-sm-10">
                              <select
                                className="form-control"
                                value={technician}
                                onChange={(e) => {
                                  this.setState({ technician: e.target.value });
                                }}
                              >
                                <option value="">Select Technician</option>
                                {technicianList &&
                                  technicianList.map((name) => (
                                    <option
                                      value={name.id}
                                    >{`${name.name}`}</option>
                                  ))}
                              </select>
                              <span id="err">{technicianErr}</span>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="installerid"
                              className="col-sm-2 col-form-label"
                            >
                              Installer Id
                            </label>
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                value={installerId}
                                type="text"
                                placeholder="installer id"
                                id="installerid"
                                onChange={(e) => {
                                  const re = /^[0-9\b]+$/;
                                  if (
                                    e.target.value === '' ||
                                    re.test(e.target.value)
                                  ) {
                                    this.setState({
                                      installerId: e.target.value,
                                    });
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="purchasedesc"
                              className="col-sm-2 col-form-label"
                            >
                              Description of Purchase
                            </label>
                            <div className="col-10">
                              <textarea
                                className="form-control"
                                type="textarea"
                                placeholder="purchase description goes here"
                                id="purchasedescription"
                                value={purchaseDesc}
                                rows="4"
                                cols="90"
                                onChange={(e) => {
                                  this.setState({
                                    purchaseDesc: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label
                              htmlFor="supplyhouse"
                              className="col-sm-2 col-form-label"
                            >
                              Supply House
                            </label>
                            <div className="col-sm-10">
                              <input
                                className="form-control"
                                value={supplyHouse}
                                type="text"
                                placeholder="supply house"
                                id="supplyhouse"
                                onChange={(e) => {
                                  this.setState({
                                    supplyHouse: e.target.value,
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="form-group row"></div>
                          <br></br>
                          <div className="form-group row d-flex justify-content-center">
                            <div className="col-sm-3 col-md-6">
                              <button
                                type="submit"
                                onClick={() => this.validatePurchaseOrder()}
                                className="btn btn-primary waves-effect waves-light"
                              >
                                Submit
                              </button>
                              <button
                                type="reset"
                                onClick={() => {
                                  this.flushAllData();
                                  this.props.history.goBack();
                                }}
                                className="btn btn-secondary waves-effect m-l-10"
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <EditMainAccount
          getMainAccountDataCallback={() => {
            this.getMainAccountData();
          }}
          getSubAccountDataCallback={(id) => {
            this.getSubAccountData(id);
          }}
        />
        <EditSubAccountData />
      </AUX>
    );
  }
}

export default withRouter(AddExpense);
