import React, { useEffect, useState, useRef } from 'react';
import { Bar } from 'react-chartjs-2';
import Api from '../../../helper/api';
import AuthApi from '../../../helper/authApi';
import html2pdf from 'html2pdf.js';

export default function ChartsExample() {
  const [expenseData, setExpenseData] = useState([]);
  const [incomeData, setIncomeData] = useState([]);

  useEffect(() => {
    getExpenseData(1);
  }, []);

  const getExpenseData = async () => {
    const year = new Date().getFullYear();
    let endPoint = `${Api.actualIncomeExpense}?year=${year}`;
    const { data } = await AuthApi.getDataFromServer(endPoint);

    let actualExpense = [];
    let actualIncome = [];

    const expensedata =
      data &&
      data.data['Actual Expense'].map((expense) => {
        actualExpense.push(expense.expense_amount);
      });

    actualExpense.pop();

    const incomedata =
      data &&
      data.data['Actual Income'].map((income) => {
        actualIncome.push(income.expense_amount);
      });
    actualIncome.pop();

    if (data && data) {
      setExpenseData(actualExpense);
      setIncomeData(actualIncome);
    }
  };

  const options = {
    responsive: true,
    legend: {
      // display: false,
    },
    type: 'bar',
    scales: {
      xAxes: [
        {
          // stacked: true
          beginAtZero: true,
        },
      ],
      yAxes: [
        {
          beginAtZero: true,
        },
      ],
    },
  };

  const data = {
    labels: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    datasets: [
      {
        label: 'Income',
        backgroundColor: '#67a8e4',
        borderColor: '#67a8e4',
        borderWidth: 1,
        hoverBackgroundColor: '#67a8e4',
        hoverBorderColor: '#67a8e4',
        data: incomeData,
      },
      {
        label: 'Expense',
        backgroundColor: '#343a40',
        borderColor: '#343a40',
        borderWidth: 1,
        hoverBackgroundColor: '#343a40',
        hoverBorderColor: '#343a40',
        data: expenseData,
      },
    ],
  };

  const onClickDownloadPDF = async (id) => {
    var element = document.getElementById(id);
    const componentWidth = element.offsetWidth;
    const componentHeight = element.offsetHeight;

    const orientation = componentWidth >= componentHeight ? 'l' : 'p';

    var opt = {
      margin: 2,
      filename: 'download.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 2,
        bottom: 20,
      },
      // pagebreak: { mode: "avoid-all", after: ".targetDiv"  },
      jsPDF: {
        unit: 'mm',
        format: 'a4',
        orientation,
      },
    };
    // New Promise-based usage:
    html2pdf().from(element).set(opt).save();
  };

  return (
    <>
      <div className="row">
        <div className="col mt-3 mr-3">
          <button
            className="btn btn-primary float-right"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (data) {
                onClickDownloadPDF('graphPdfDownload');
              }
            }}
          >
            Download PDF
          </button>
        </div>
      </div>
      <div id="graphPdfDownload" className="targetDiv">
        <Bar data={data} width={null} height={null} options={options} />
      </div>
    </>
  );
}
