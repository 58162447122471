import React, { Component } from 'react';
import AUX from '../../../hoc/Aux_';
import { withRouter } from 'react-router-dom';
import AuthApi from '../../../helper/authApi';
import Api from '../../../helper/api';
import { MDBDataTable } from 'mdbreact';
import Pagination from '../../../components/Pagination';
import SweetAlert from 'react-bootstrap-sweetalert';
import customFunctions from '../../../helper/customFunctions';
import swal from 'sweetalert';
import AssignContract from './assignContract';

const columns = [
  {
    label: 'Sr. No.',
    field: 'index',
    sort: 'disabled',
    width: 50,
  },
  {
    label: 'Contract Name',
    field: 'contractName',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Status',
    field: 'status',
    sort: 'disabled',
    width: 150,
  },
  {
    label: 'Action',
    field: 'actions',
    sort: 'disabled',
    width: 150,
  },
];

class TechnicianContracts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contracts: [],
      rows: [],
      contractId: null,
      skip: 0,
      page: 1,
      limit: 10,
      totalCount: 5,
      currentPage: 1,
      isWriteAllowed: false,
      showAssignContract: false,
      roleId: null,
      technicianId: null,
    };
  }

  componentDidMount = async () => {
    var userdata = await customFunctions.getDataFromLocalStorage(
      'userdata',
      true
    );
    this.setState(
      {
        roleId: userdata.role_id,
        technicianId: this.props.technicianId,
      },
      () => {
        this.getContracts();
      }
    );

    // let isAllowed = await customFunctions.isAllowedRoute(
    //   'submodule',
    //   'master-employmentcontract'
    // );
    // if (!isAllowed) {
    //   this.props.history.push('/dashboard');
    //   return;
    // }
    // let writeAllowed = await customFunctions.isAllowedRoute(
    //   'submodule',
    //   'master-employmentcontract',
    //   'write'
    // );
    // if (writeAllowed) {
    //   this.setState({ isWriteAllowed: true });
    // }
  };

  getContracts = async (currentPage) => {
    this.setState({ currentPage });
    const { limit, technicianId } = this.state;
    const page = currentPage || 1;
    const skip = page * limit - limit;
    let endPoint = `${Api.employmentUrl}?skip=${skip}&limit=${limit}`;
    if (technicianId) {
      endPoint += `&technician_id=${technicianId}`;
    }

    const { data, message } = await AuthApi.getDataFromServer(endPoint);
    if (data && data.data && data.data.data) {
      this.setState(
        {
          contracts: data.data.data,
          totalCount: data.data.total_count,
        },
        () => {
          this.setTableData();
          this.forceUpdate();
        }
      );
    } else {
      swal(message, '', 'error');
    }
  };

  setTableData = () => {
    const { contracts, limit, currentPage, roleId } = this.state;
    const page = currentPage || 1;
    const rows = [];
    contracts &&
      contracts.length > 0 &&
      contracts.map((cont, i) => {
        rows.push({
          index: (page - 1) * limit + (i + 1),
          contractName: cont.contract_name ? cont.contract_name : '-',
          status: cont.status ? cont.status : '-',
          actions: (
            <React.Fragment>
              <a
                href="/"
                style={{ cursor: 'pointer' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.props.history.push(
                    `/approve-employment-contract/${cont.contractid}`,
                    {
                      contractDetails: cont,
                      type: 'cont',
                    }
                  );
                }}
              >
                <p>
                  {roleId === 5 && cont.status === 'pending'
                    ? 'Approve'
                    : 'View'}
                </p>
              </a>
              {/* <a
                href="/"
                style={{ pointerEvents: isWriteAllowed ? '' : 'none' }}
                className=""
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  this.setState({ showDelete: true, contractId: cont.id });
                }}
              >
                <i
                  className="mdi mdi-delete text-danger"
                  title="Delete Contract"
                ></i>
              </a> */}
            </React.Fragment>
          ),
        });
        return cont;
      });
    this.setState({ rows });
    this.forceUpdate();
  };

  deleteContract = async () => {
    const { contractId } = this.state;
    const { data, message } = await AuthApi.deleteDataFromServer(
      `${Api.getContractItem}?contract_id=${contractId}`
    );
    if (data) {
      this.setState({ showDelete: false, contractId: null }, () => {
        this.getContracts();
      });
      swal('Contract deleted', '', 'success');
    } else {
      swal(message, '', 'error');
    }
  };

  render() {
    const {
      rows,
      limit,
      totalCount,
      currentPage,
      showDelete,
      isWriteAllowed,
      showAssignContract,
      roleId,
    } = this.state;

    return (
      <AUX>
        <div style={{ margin: '0px -15px' }}>
          <div className="container-fluid">
            {showDelete && (
              <SweetAlert
                title="Are you sure, do you want to delete the contract ?"
                error
                showCancel
                confirmBtnBsStyle="success"
                cancelBtnBsStyle="danger"
                onConfirm={() => {
                  this.deleteContract();
                }}
                onCancel={() => {
                  this.setState({ showDelete: false, contractId: null });
                }}
              >
                You won't be able to revert this!
              </SweetAlert>
            )}
            <div className="row">
              <div className="col-12">
                <div className="card m-b-20">
                  <div className="card-body">
                    <div className="table-header">
                      <div className="left-section"></div>
                      {roleId === 1 && (
                        <div className="right-section pb-2">
                          <button
                            type="submit"
                            className="btn btn-primary waves-effect waves-light"
                            onClick={() => {
                              this.setState({ showAssignContract: true });
                            }}
                          >
                            Assign Employment Contract
                          </button>
                        </div>
                      )}
                    </div>
                    <MDBDataTable
                      noBottomColumns={true}
                      paging={false}
                      bordered
                      hover
                      data={{ columns, rows }}
                      searching={false}
                      noRecordsFoundLabel="No records found"
                    />
                    {totalCount > 10 && (
                      <div className="pagination">
                        <Pagination
                          totalCount={totalCount}
                          currentPage={currentPage}
                          limit={limit}
                          getData={this.getContracts}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showAssignContract && roleId === 1 && (
          <AssignContract
            showAssignContract={showAssignContract}
            handleClose={() => this.setState({ showAssignContract: false })}
            handleCallback={() => this.getContracts()}
            technicianId={this.props.technicianId}
          />
        )}
      </AUX>
    );
  }
}
export default withRouter(TechnicianContracts);
