import React from 'react';
import Technicians from '../Technicians';

const Dispatchers = () => {
  return (
    <div>
      <Technicians />
    </div>
  );
};

export default Dispatchers;
